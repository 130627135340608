/**
 * Removes null or undefined values from the first level of an object
 */
const filterOutNullOrUndefined = <T = object>(inObject: T) => {
  return Object.fromEntries(
    Object.entries(inObject).filter(
      // using != instead of !== because .entries to 'null' or 'undefined'
      // eslint-disable-next-line eqeqeq
      ([, value]) => value != undefined || value != null
    )
  );
};

export { filterOutNullOrUndefined };
