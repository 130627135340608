export interface Typography {
  fontFamily: { [key: string]: string };
  mobile: {
    fontSize: { [key: string]: number };
    lineHeight: { [key: string]: number };
  };
  desktop: {
    fontSize: { [key: string]: number };
    lineHeight: { [key: string]: number };
  };
}

export const auText: Typography = {
  fontFamily: {
    heading: 'focoBold700',
    bodyRegular: 'museoSansLight300',
    bodyCaption: 'museoSansLight300Italic',
    bodyBold: 'museoSansMedium500',
    bodyExtraBold: 'museoSansBold700',
  },
  // XS to SM Headings (320 - 719)
  mobile: {
    fontSize: {
      xSmall: 16,
      small: 18,
      medium: 21,
      large: 24,
      xLarge: 36,
    },
    lineHeight: {
      xSmall: 20,
      small: 23,
      medium: 26,
      large: 30,
      xLarge: 45,
    },
  },
  // MD to XL Headings (720 - 1440)
  desktop: {
    fontSize: {
      xSmall: 16,
      small: 18,
      medium: 23,
      large: 28,
      xLarge: 46,
    },
    lineHeight: {
      xSmall: 20,
      small: 23,
      medium: 29,
      large: 35,
      xLarge: 58,
    },
  },
};
