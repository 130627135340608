import { ApolloError } from '@apollo/client';
import { InsertProfile } from 'types/generated/InsertProfile';
import { UpdateProfile } from 'types/generated/UpdateProfile';
import {
  useInsertProfileMutation,
  useUpdateProfileMutation,
} from '../../hooks';
import { Values as UserDetailsFormValues } from '../UserDetailsForm';
import { useStaff } from '../../context/StaffContext';

export interface ContactDetailsPanel {
  handleSubmit: (values: UserDetailsFormValues) => void;
  insertProfileData?: InsertProfile | null;
  insertProfileError?: ApolloError;
  insertProfileLoading: boolean;
  updateProfileData?: UpdateProfile | null;
  updateProfileError?: ApolloError;
  updateProfileLoading: boolean;
}

const useContactDetailsPanel = (type: string): ContactDetailsPanel => {
  const {
    state: { selectedCustomer },
  } = useStaff();

  // create user
  const [
    insertProfile,
    {
      data: insertProfileData,
      error: insertProfileError,
      loading: insertProfileLoading,
    },
  ] = useInsertProfileMutation();

  // edit user
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileError,
      loading: updateProfileLoading,
    },
  ] = useUpdateProfileMutation();

  const handleSubmit = ({
    givenName,
    familyName,
    preferredName,
    mobileNumber,
    emailAddress,
    onboardedBranch,
  }: UserDetailsFormValues) => {
    const trimmedGivenName = givenName.trim();
    const trimmedFamilyName = familyName.trim();

    const userProfileWithoutEmail = {
      familyName: trimmedFamilyName,
      fullName: `${trimmedGivenName} ${trimmedFamilyName}`,
      givenName: trimmedGivenName,
      mobilePhone: '+61' + mobileNumber.slice(1),
      onboardedBranchCode: onboardedBranch.trim(),
      preferredName: preferredName.trim(),
    };

    if (type === 'create') {
      insertProfile({
        variables: { ...userProfileWithoutEmail, email: emailAddress.trim() },
      });
    } else {
      updateProfile({
        variables: {
          ...userProfileWithoutEmail,
          customerId: selectedCustomer?.customerId as string,
        },
      });
    }
  };

  return {
    handleSubmit,
    insertProfileData,
    insertProfileError,
    insertProfileLoading,
    updateProfileData,
    updateProfileError,
    updateProfileLoading,
  };
};

export default useContactDetailsPanel;
