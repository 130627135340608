import { Text, useDeviceType, useStyles } from '@nutrien/bonsai-core';
import React from 'react';
import { View } from 'react-native';
import { Transaction } from '../Transaction/transactions.types';

interface GroupedTransactionItemProps {
  item: Transaction;
}

const DetailsRow = ({ item }: GroupedTransactionItemProps): JSX.Element => {
  const { category, description, price } = item;
  const styles = useStyles((theme) => {
    return {
      row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomColor: theme.auColors.neutral[500],
        borderBottomWidth: 1,
        paddingVertical: theme.spacing(1),
        paddingHorizontal: theme.spacing(2),
      },
      leftColumn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      category: {
        width: 130,
        marginRight: theme.spacing(2),
      },
    };
  });
  return (
    <View style={styles.row}>
      <View style={styles.leftColumn}>
        <Text style={styles.category}>{category.value}</Text>
        <Text>{description.value}</Text>
      </View>
      <Text>{price.displayValue}</Text>
    </View>
  );
};

const CompactDetailsRow = ({ item }: GroupedTransactionItemProps) => {
  const { category, description, price } = item;
  const styles = useStyles((theme) => {
    return {
      row: {
        borderBottomColor: theme.auColors.neutral[500],
        borderBottomWidth: 1,
        paddingVertical: theme.spacing(1),
        paddingHorizontal: theme.spacing(2),
      },
      leftColumn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      category: {
        width: 130,
        marginRight: theme.spacing(2),
      },
    };
  });
  return (
    <View style={styles.row}>
      <Text>{description.value}</Text>
      <View style={styles.leftColumn}>
        <Text>{category.value}</Text>
        <Text>{price.displayValue}</Text>
      </View>
    </View>
  );
};

// TODO: When NG-2721-livestock-sale-lot-details feature flag is ready,
// this component is safe to delete as it is replaced with `TransactionItem`.
const GroupedTransactionItem = (
  props: GroupedTransactionItemProps
): JSX.Element => {
  const { isDesktop } = useDeviceType();
  return isDesktop ? (
    <DetailsRow {...props} />
  ) : (
    <CompactDetailsRow {...props} />
  );
};

export default GroupedTransactionItem;
