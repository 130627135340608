import { useReactiveVar } from '@apollo/client';
import { Box } from '@nutrien/bonsai-core';
import React from 'react';
import {
  getLotDetailItems,
  TransactionGroup,
} from '../../hooks/useTransactions/transaction.helper';
import {
  accountTransactionsVar,
  selectedAccountVar,
} from '../../reactiveVariables';
import { DownloadDocumentFn } from '../Transaction/TabScene';
import TransactionFooter from './TransactionFooter';
import TransactionHeader from './TransactionHeader';
import TransactionItem from './TransactionItem';

export interface TransactionAccordionProps {
  group: TransactionGroup;
  isExpanded: boolean;
  onToggleExpand: () => void;
  onDownload: DownloadDocumentFn;
  onReloadLotDetails?: () => void;
}

const TransactionAccordion = ({
  group,
  isExpanded,
  onToggleExpand,
  onDownload,
  onReloadLotDetails,
}: TransactionAccordionProps): JSX.Element => {
  const {
    documentId,
    title,
    total,
    issueDate,
    dueDate,
    items,
    hasPdf,
    documentAccountType,
  } = group;
  const { account_id } = useReactiveVar(selectedAccountVar);
  const lotDetailsList = useReactiveVar(accountTransactionsVar)[
    account_id as string
  ]?.lotDetailsList;

  const { lotDetailsResponse, lotDetailsLoading } =
    lotDetailsList?.[documentId ?? ''] ?? {};

  const lotDataItems =
    typeof lotDetailsResponse === 'object' && 'result' in lotDetailsResponse
      ? getLotDetailItems(lotDetailsResponse.result)
      : undefined;

  const errorLoadingLotDetails = typeof lotDetailsResponse?.error === 'string';

  return (
    <>
      <TransactionHeader
        title={title}
        total={total}
        issueDate={issueDate}
        dueDate={dueDate}
        isExpanded={isExpanded}
        onToggleExpand={onToggleExpand}
        isLoading={lotDetailsLoading}
        lotDetailsError={errorLoadingLotDetails}
      />
      <Box
        testID={`transaction-accordion-${
          isExpanded ? 'expanded' : 'collapsed'
        }`}
      >
        {items.map((item, index) => (
          <TransactionItem
            key={index}
            item={item}
            itemLotData={lotDataItems}
            isExpanded={isExpanded}
            isLast={index === items.length - 1}
            lotDetailsLoading={lotDetailsLoading}
          />
        ))}
      </Box>
      {hasPdf && (
        <TransactionFooter
          isLoading={lotDetailsLoading}
          onDownload={() => onDownload(documentId, title, documentAccountType)}
          lotDetailsError={errorLoadingLotDetails}
          onReloadLotDetails={onReloadLotDetails}
          invoiceNumber={title}
        />
      )}
    </>
  );
};

export default TransactionAccordion;
