import { ReactiveVar } from '@apollo/client';
import { BreadCrumb, breadCrumbVar } from '../variables/breadCrumbsVar';

const editBreadCrumb = (breadCrumbVariable: ReactiveVar<BreadCrumb>) => {
  return (newState: Partial<BreadCrumb>) => {
    const state = breadCrumbVariable();
    const editedState = {
      ...state,
      ...newState,
    };
    breadCrumbVariable(editedState);
  };
};

interface BreadCrumbMutations {
  editBreadCrumb: (props: Partial<BreadCrumb>) => void;
}

export const breadCrumbMutations: BreadCrumbMutations = {
  editBreadCrumb: editBreadCrumb(breadCrumbVar),
};
