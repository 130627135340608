import React from 'react';
import {
  Text,
  useStyles,
  useDeviceType,
  Box,
  Icon,
  useTheme,
} from '@nutrien/bonsai-core';
import { useFormatters } from '../../hooks';
import { TransactionWrapper } from './TransactionWrapper';
import { ViewStyle } from 'react-native';

export interface TransactionHeaderProps {
  isLoading?: boolean;
  title: string;
  total: number;
  issueDate: string;
  dueDate: string;
  isExpanded?: boolean;
  lotDetailsError?: boolean;
  onToggleExpand?: () => void;
}

const TransactionHeader = ({
  isLoading,
  title,
  total,
  issueDate,
  dueDate,
  isExpanded,
  lotDetailsError,
  onToggleExpand,
}: TransactionHeaderProps): JSX.Element => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const { formatCurrency } = useFormatters();
  const styles = useStyles(() => {
    return {
      headingRow: {
        backgroundColor: theme.auColors.neutral[200],
      },
      cursorStyle: {
        cursor: isLoading || lotDetailsError ? 'not-allowed' : 'default',
      } as ViewStyle,
      title: {
        color:
          onToggleExpand !== undefined
            ? theme.auColors.primary.default
            : theme.auColors.neutral[900],
      },
      issueDate: {
        fontSize: theme.typography.fontSize.small,
        lineHeight: theme.typography.fontSize.small * 1.5,
        marginRight: theme.spacing(4),
      },
      dueDate: {
        fontSize: theme.typography.fontSize.small,
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
      alignRight: {
        marginRight: 0,
        marginLeft: 'auto',
      },
      totalAmount: {
        fontSize: theme.typography.fontSize.small,
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
      chevron: {
        cursor: isLoading || lotDetailsError ? 'not-allowed' : 'default',
        backgroundColor: theme.auColors.neutral[100],
        borderColor: theme.auColors.neutral[400],
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,
        marginRight: isDesktop ? theme.spacing(1) : 0,
        transform: isExpanded ? [{ rotate: '180deg' }] : [{ rotate: '0deg' }],
      },
    };
  });
  const showDueDate = dueDate && dueDate !== issueDate;

  return (
    <Box
      flexDir={isDesktop ? 'row' : 'column'}
      justifyContent={isDesktop ? 'space-between' : 'center'}
      paddingX={2}
      paddingY={1}
      style={styles.headingRow}
    >
      <TransactionWrapper
        onPress={!isLoading && !lotDetailsError ? onToggleExpand : undefined}
        isLast={false}
        tableStyle={false}
        testId={`transaction-header-${title}`}
        isExpanded={isExpanded}
      >
        <Box
          flexDir={isDesktop ? 'row' : 'row-reverse'}
          justifyContent={isDesktop ? 'flex-start' : 'space-between'}
          width={isDesktop ? '50%' : '100%'}
          style={styles.cursorStyle}
        >
          {onToggleExpand && (
            <Icon
              style={styles.chevron}
              name="expand-more"
              color={theme.auColors.neutral[600]}
              testID="icon-chevron"
            />
          )}
          <Box flexGrow={1}>
            <Text style={styles.title} bodyBold>
              {title}
            </Text>
          </Box>
        </Box>
      </TransactionWrapper>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
        width={isDesktop ? '50%' : '100%'}
        mt={isDesktop ? 0 : 1}
      >
        {isDesktop ? (
          <>
            <Text style={styles.issueDate}>Issued {issueDate}</Text>
            {showDueDate && <Text style={styles.dueDate}>Due {dueDate}</Text>}
          </>
        ) : (
          <>
            <Box>
              <Text style={styles.issueDate}>Issued </Text>
              <Text style={styles.issueDate}>{issueDate}</Text>
            </Box>
            {showDueDate && (
              <Box>
                <Text style={styles.dueDate}>Due </Text>
                <Text style={styles.dueDate}>{dueDate}</Text>
              </Box>
            )}
          </>
        )}
        <Box style={styles.alignRight}>
          <Text style={styles.totalAmount} bodyBold>
            {formatCurrency(total)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionHeader;
