import React from 'react';
import { Text, Box, useTheme, useDeviceType } from '@nutrien/bonsai-core';
import { useLogin } from '../../context/LoginContext';
import {
  useCustomerProfileByCustomerIdQuery,
  usePersonDetailsByIdQuery,
} from '../../hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default (): JSX.Element | null => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const {
    state: { customerDomainId: customerId },
  } = useLogin();
  const flags = useFlags();

  let preferred_name: string | null | undefined;
  let given_name: string | null | undefined;

  if (flags['NG-2644-party-model']) {
    const [data, _loading, _error, _refetch] = usePersonDetailsByIdQuery({
      customerId,
    });
    preferred_name = data?.party_vw_get_person_details[0]?.preferred_name;
    given_name = data?.party_vw_get_person_details[0]?.given_name;
  } else {
    const [data, _loading, _error, _refetch] =
      useCustomerProfileByCustomerIdQuery({
        customerId,
      });

    preferred_name = data?.customer_profile[0]?.preferred_name;
    given_name = data?.customer_profile[0]?.given_name;
  }

  const styles = {
    headingLarge:
      theme.auTypography[isDesktop ? 'desktop' : 'mobile'].headingLarge,
  };

  return (
    <Box maxWidth={990} width="100%" marginX="auto" py={3} px={2}>
      <Text testID="welcome-message" style={styles.headingLarge} h1>
        Welcome back, {preferred_name || given_name}
      </Text>
    </Box>
  );
};
