import { Platform } from 'react-native';
import queryString from 'query-string';
import { useRoutes } from '../../hooks';

export interface AccountOption {
  value: string;
  type: string;
  label: string;
}

export type CustomerId = string | (string | null)[] | null | undefined;
export interface UseAccount {
  selectedAccountId: string;
  customerId: CustomerId;
  accountType: string;
}

const useAccount = (): UseAccount => {
  const { routeParams } = useRoutes();
  const selectedAccountId = routeParams.id || '';
  const accountType = routeParams.type || '';

  let customerId = null;
  if (Platform.OS === 'web') {
    customerId = queryString.parse(window.location.search)?.customerid;
  } else {
    // We can't get query string from native, so use routeParams to check
    customerId = routeParams?.customerId;
  }

  return {
    selectedAccountId,
    customerId,
    accountType,
  };
};

export default useAccount;
