import Sentry from './Sentry';

const DEV_ENVIRONMENT = ['development', 'dev'];

interface APP_MANIFEST {
  version: string;
}

const environment = process.env.ENVIRONMENT || 'dev';
const dsn = process.env.SENTRY_DSN || '';
const debug = DEV_ENVIRONMENT.includes(environment.toLowerCase());
const release = `au-digital-hub@${
  (process.env.APP_MANIFEST as APP_MANIFEST | undefined)?.version || '1.0.0'
}`;

const config = {
  dsn,
  enableInExpoDevelopment: true,
  debug,
  environment,
  release,
};

const init = (): Promise<void> => {
  try {
    return Sentry.init(config);
  } catch (err) {
    throw new Error(err);
  }
};

export { init, Sentry };
