import {
  ApolloError,
  gql,
  useLazyQuery,
  OperationVariables,
  LazyQueryResult,
  QueryFunctionOptions,
} from '@apollo/client';
import {
  ValidateABNPerson,
  ValidateABNPersonVariables,
} from 'types/generated/ValidateABNPerson';

export const VALIDATE_ABN_PERSON = gql`
  query ValidateABNPerson($abn: String!, $email: String!) {
    validateABNPerson(payload: { abn: $abn, email: $email }) {
      account_numbers {
        account_id
        account_type
        name1
      }
    }
  }
`;

type ValidateABNPersonFunction = (
  options?: QueryFunctionOptions<ValidateABNPerson, ValidateABNPersonVariables>
) => Promise<LazyQueryResult<ValidateABNPerson, OperationVariables>>;

const useValidateABNPersonQuery = (): [
  ValidateABNPersonFunction,
  {
    data?: ValidateABNPerson | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [verifyABNPerson, { loading, error, data }] = useLazyQuery<
    ValidateABNPerson,
    ValidateABNPersonVariables
  >(VALIDATE_ABN_PERSON, {
    fetchPolicy: 'network-only',
  });

  return [verifyABNPerson, { data, loading, error }];
};

export default useValidateABNPersonQuery;
