import {
  ApolloError,
  gql,
  QueryFunctionOptions,
  useQuery,
} from '@apollo/client';
import { CustomerAccount } from 'types/generated/CustomerAccount';
import { CustomerAccountsByIds } from 'types/generated/CustomerAccountsByIds';
import { CustomerAccountsByNominatedAccountId } from 'types/generated/CustomerAccountsByNominatedAccountId';
import { CustomerAccountSummary } from 'types/generated/CustomerAccountSummary';
import { CustomerAccountWithFinance } from 'types/generated/CustomerAccountWithFinance';
import { SearchCustomerAccounts } from 'types/generated/SearchCustomerAccounts';

export const CUSTOMER_ACCOUNT = gql`
  query CustomerAccount($account_id: String!) {
    sords_customer_account(where: { account_id: { _eq: $account_id } }) {
      customer_guid
      abn
      account_id
      account_id_trimmed
      nominated_account_id
      account_type
      address_number
      city
      country
      domicile_branch_code
      domicile_branch_name
      name1
      name2
      po_box
      postal_code
      update_dtm
      telephone
      street
      region
    }
  }
`;

export const CUSTOMER_ACCOUNTS_BY_IDS = gql`
  query CustomerAccountsByIds($account_ids: [String!]) {
    sords_customer_account(where: { account_id: { _in: $account_ids } }) {
      customer_guid
      abn
      account_id
      account_id_trimmed
      nominated_account_id
      account_type
      address_number
      city
      country
      domicile_branch_code
      domicile_branch_name
      name1
      name2
      po_box
      postal_code
      update_dtm
      telephone
      street
      region
    }
  }
`;

export const CUSTOMER_ACCOUNT_SUMMARY = gql`
  query CustomerAccountSummary(
    $account_id: String!
    $nutrien_company_code: String!
  ) {
    getAccountSummary(
      payload: {
        AccountNumber: $account_id
        CompanyCode: $nutrien_company_code
      }
    ) {
      AccountNumber
      CurrentBalance
      RewardAmount
      TransactionAmount
      RuralcoAccNumber
    }
  }
`;

export const CUSTOMER_ACCOUNT_WITH_FINANCE = gql`
  query CustomerAccountWithFinance(
    $account_id: String!
    $nutrien_company_code: String!
  ) {
    sords_customer_account(where: { account_id: { _eq: $account_id } }) {
      customer_guid
      abn
      account_id
      account_id_trimmed
      nominated_account_id
      account_type
      address_number
      city
      country
      domicile_branch_code
      domicile_branch_name
      name1
      name2
      po_box
      postal_code
      update_dtm
      telephone
      street
      region
    }
    sords_customer_account_finance(
      where: { account_id: { _eq: $account_id } }
    ) {
      account_id
      total_receivables
      process_date
      nominated_account_id
      last_payment_date
      last_payment_amount
      credit_limit
      available_credit
    }
    customer_customer_statement(
      where: { account_id: { _eq: $account_id } }
      order_by: { posting_dtm: desc }
    ) {
      account_id
      closing_balance_no_et
      current_due
      extended_term_balance
      opening_balance
      overdue_balance
      posting_dtm
      subtotal_merch_fert
      total_account_balance
      total_balance_on_account
      total_due
      total_proceeds
      total_extended_term_balance
    }
    getAccountSummary(
      payload: {
        AccountNumber: $account_id
        CompanyCode: $nutrien_company_code
      }
    ) {
      AccountNumber
      CurrentBalance
      RewardAmount
      TransactionAmount
      RuralcoAccNumber
    }
  }
`;

export const SEARCH_CUSTOMER_ACCOUNTS = gql`
  query SearchCustomerAccounts($searchTerm: String!) {
    sords_customer_account(
      where: {
        _or: [
          { account_id: { _ilike: $searchTerm } }
          { name1: { _ilike: $searchTerm } }
          { name2: { _ilike: $searchTerm } }
          { telephone: { _ilike: $searchTerm } }
          { account_email: { _ilike: $searchTerm } }
          { abn: { _ilike: $searchTerm } }
        ]
      }
      limit: 10
    ) {
      customer_guid
      abn
      account_id
      account_id_trimmed
      nominated_account_id
      account_email
      account_type
      address_number
      city
      country
      domicile_branch_code
      domicile_branch_name
      name1
      name2
      po_box
      postal_code
      update_dtm
      telephone
      street
      region
    }
  }
`;

export const CUSTOMER_ACCOUNTS_BY_NOMINATED_ACCOUNT_ID = gql`
  query CustomerAccountsByNominatedAccountId($nominated_account_id: String!) {
    sords_customer_account(
      where: { nominated_account_id: { _eq: $nominated_account_id } }
    ) {
      customer_guid
      abn
      account_id
      account_id_trimmed
      nominated_account_id
      account_type
      address_number
      city
      country
      domicile_branch_code
      domicile_branch_name
      name1
      name2
      po_box
      postal_code
      update_dtm
      telephone
      street
      region
      account_finances {
        available_credit
        change_date
        credit_limit
        interest_calculation
        is_finance_account
        is_livestock_account
        is_nominated_account
        is_prepay_account
        last_payment_amount
        last_payment_date
        limit_exceeded_date
        total_receivables
      }
    }
  }
`;

const useCustomerAccountSummaryQuery = ({
  account_id,
  skip,
  onCompleted,
}: {
  account_id: string;
  skip?: boolean;
  onCompleted?: (data: CustomerAccountSummary) => void;
}): [
  CustomerAccountSummary | undefined,
  boolean,
  ApolloError | undefined,
  () => void
] => {
  const { loading, error, data, refetch } = useQuery(CUSTOMER_ACCOUNT_SUMMARY, {
    skip,
    onCompleted,
    variables: { account_id, nutrien_company_code: '8200' },
  });

  return [data, loading, error, refetch];
};

const useCustomerAccountWithFinanceQuery = ({
  account_id,
  skip,
  onCompleted,
}: {
  account_id: string;
  skip?: boolean;
  onCompleted: (data: CustomerAccountWithFinance) => void;
}): [
  CustomerAccountWithFinance | undefined,
  boolean,
  ApolloError | undefined,
  () => void
] => {
  const { loading, error, data, refetch } = useQuery(
    CUSTOMER_ACCOUNT_WITH_FINANCE,
    {
      skip,
      onCompleted,
      variables: { account_id, nutrien_company_code: '8200' },
    }
  );

  return [data, loading, error, refetch];
};

const useCustomerAccountQuery = ({
  account_id,
  skip,
}: {
  account_id: string;
  skip?: boolean;
}): [
  CustomerAccount | undefined,
  boolean,
  ApolloError | undefined,
  () => void
] => {
  const { loading, error, data, refetch } = useQuery(CUSTOMER_ACCOUNT, {
    skip,
    variables: { account_id },
  });

  return [data, loading, error, refetch];
};

const useCustomerAccountsQuery = ({
  account_ids,
  skip,
  onCompleted,
}: {
  account_ids: string[];
  skip?: boolean;
  onCompleted?: QueryFunctionOptions['onCompleted'];
}): [
  CustomerAccountsByIds | undefined,
  boolean,
  ApolloError | undefined,
  () => void
] => {
  const { loading, error, data, refetch } = useQuery(CUSTOMER_ACCOUNTS_BY_IDS, {
    skip,
    variables: { account_ids },
    onCompleted,
  });

  return [data, loading, error, refetch];
};

const useSearchCustomerAccountsQuery = ({
  searchTerm,
  skip,
  onCompleted,
}: {
  searchTerm: string;
  skip: boolean;
  onCompleted?: QueryFunctionOptions['onCompleted'];
}): {
  data: SearchCustomerAccounts | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, error, loading } = useQuery<SearchCustomerAccounts>(
    SEARCH_CUSTOMER_ACCOUNTS,
    {
      variables: { searchTerm },
      skip,
      onCompleted,
    }
  );

  return { data, loading, error };
};

const useCustomerAccountsByNominatedAccountIdQuery = ({
  nominated_account_id,
  skip,
  onCompleted,
}: {
  nominated_account_id: string;
  skip?: boolean;
  onCompleted?: QueryFunctionOptions['onCompleted'];
}): {
  data: CustomerAccountsByNominatedAccountId | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
} => {
  const { loading, error, data, refetch } = useQuery(
    CUSTOMER_ACCOUNTS_BY_NOMINATED_ACCOUNT_ID,
    {
      skip,
      variables: { nominated_account_id },
      onCompleted,
    }
  );

  return { data, loading, error, refetch };
};

export {
  useCustomerAccountsQuery,
  useCustomerAccountWithFinanceQuery,
  useSearchCustomerAccountsQuery,
  useCustomerAccountsByNominatedAccountIdQuery,
  useCustomerAccountSummaryQuery,
};

export default useCustomerAccountQuery;
