import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { ValidateABNPerson_validateABNPerson_account_numbers } from '../types/generated/ValidateABNPerson';

interface State {
  abn: string;
  givenName: string;
  familyName: string;
  email: string;
  mobileNumber: string;
  accountNumbers:
    | (ValidateABNPerson_validateABNPerson_account_numbers | null)[]
    | null;
}

type SetAbn = {
  type: 'setAbn';
  value: string;
};
type SetGivenName = {
  type: 'setGivenName';
  value: string;
};
type SetFamilyName = {
  type: 'setFamilyName';
  value: string;
};

type SetEmail = {
  type: 'setEmail';
  value: string;
};

type SetMobileNumber = {
  type: 'setMobileNumber';
  value: string;
};

type SetAccountNumbers = {
  type: 'setAccountNumbers';
  value: (ValidateABNPerson_validateABNPerson_account_numbers | null)[] | null;
};

type Action =
  | SetAbn
  | SetGivenName
  | SetFamilyName
  | SetEmail
  | SetMobileNumber
  | SetAccountNumbers;

type UserProviderProps = {
  children: ReactNode;
};

type Dispatch = (action: Action) => void;

interface UserProviderValue {
  state: State;
  dispatch: Dispatch;
  setAbn: (value: string) => void;
  setGivenName: (value: string) => void;
  setFamilyName: (value: string) => void;
  setEmail: (value: string) => void;
  setMobileNumber: (value: string) => void;
  setAccountNumbers: (
    value: [ValidateABNPerson_validateABNPerson_account_numbers | null] | null
  ) => void;
}

const UserContext = createContext<UserProviderValue | undefined>(undefined);

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setAbn': {
      return { ...state, abn: action.value };
    }
    case 'setGivenName': {
      return { ...state, givenName: action.value };
    }
    case 'setFamilyName': {
      return { ...state, familyName: action.value };
    }
    case 'setEmail': {
      return { ...state, email: action.value };
    }
    case 'setMobileNumber': {
      return { ...state, mobileNumber: action.value };
    }
    case 'setAccountNumbers': {
      return { ...state, accountNumbers: action.value };
    }
    default: {
      throw new Error(`Unhandled action type ${(action as Action).type}`);
    }
  }
}

function UserProvider({ children }: UserProviderProps): JSX.Element {
  const resetState = {
    abn: '',
    familyName: '',
    givenName: '',
    email: '',
    mobileNumber: '',
    accountNumbers: null,
  };
  const [state, dispatch] = useReducer(
    userReducer as React.Reducer<State, Action>,
    resetState
  );
  const setAbn = (value: string) => {
    dispatch({ type: 'setAbn', value });
  };

  const setGivenName = (value: string) => {
    dispatch({ type: 'setGivenName', value });
  };

  const setFamilyName = (value: string) => {
    dispatch({ type: 'setFamilyName', value });
  };

  const setEmail = (value: string) => {
    dispatch({ type: 'setEmail', value });
  };

  const setMobileNumber = (value: string) => {
    dispatch({ type: 'setMobileNumber', value });
  };

  const setAccountNumbers = (
    value: (ValidateABNPerson_validateABNPerson_account_numbers | null)[] | null
  ) => {
    dispatch({ type: 'setAccountNumbers', value });
  };

  const value = {
    state,
    dispatch,
    setAbn,
    setGivenName,
    setFamilyName,
    setEmail,
    setMobileNumber,
    setAccountNumbers,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser(): UserProviderValue {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
