import React from 'react';
import { IBoxProps } from 'native-base';
import { Box, useDeviceType } from '@nutrien/bonsai-core';
import FinanceOverview from './FinanceOverview';
import PrePayOverview from './PrePayOverview';
import TradingOverview from './TradingOverview';
import {
  CustomerAccountWithFinance_customer_customer_statement as StatementData,
  CustomerAccountWithFinance_getAccountSummary as AccountSummaryData,
  CustomerAccountWithFinance_sords_customer_account_finance as FinanceData,
} from '../../types/generated/CustomerAccountWithFinance';
import { AccountType } from './useAccountOverview';
import { trackEvent } from '../../constants/segment';
import { useNavigation, useSegment } from '../../hooks';
import { routeConfig } from '../../routes/shared';
import { Button } from '../Button';

export interface FinancialOverviewProps extends FinanceData {
  account_type: AccountType;
  statement: StatementData;
  summary: AccountSummaryData | null;
}

interface AccountOverviewBodyProps {
  account_description?: string;
  account_type: AccountType;
  financials: FinanceData;
  statement: StatementData;
  summary: AccountSummaryData | null;
  account_name: string | null;
  hideDetailsButton?: boolean;
  account_id: string;
  customerId?: string | null;
}

interface ViewDetailsButtonProps {
  account_description?: string;
  account_type: AccountType;
  account_id: string;
  customerId?: string | null;
}

const FinancialOverview = (props: FinancialOverviewProps) => {
  const ComponentTypeMap: Record<AccountType, JSX.Element> = {
    trading: <TradingOverview {...props} />,
    finance: <FinanceOverview {...props} />,
    prepay: <PrePayOverview {...props} />,
  };

  return ComponentTypeMap[props.account_type] || null;
};

const ViewDetailsButton = ({
  account_description,
  account_type,
  account_id,
  customerId,
}: ViewDetailsButtonProps) => {
  const device = useDeviceType();

  const segment = useSegment();
  const { navigate } = useNavigation();

  const handleClickViewDetails = () => {
    segment?.track(trackEvent.ACCOUNT_SELECTED, {
      account_id,
      account_type,
      display_account_type: account_description,
    });
    navigate(
      routeConfig.Account.getNavigateProps({
        type: account_type,
        id: account_id,
        customerId,
      })
    );
  };

  return (
    <Button
      accessibilityLabel={`View ${account_type} Details`}
      testID={`view-details-${account_type}`}
      title="View Details"
      fullWidth={device.isHandset}
      onPress={handleClickViewDetails}
    />
  );
};

export const AccountOverviewBody = ({
  account_description,
  account_type,
  financials,
  statement,
  summary,
  hideDetailsButton,
  account_id,
  customerId,
}: AccountOverviewBodyProps) => {
  const device = useDeviceType();

  const wrapperProps = device.isHandset
    ? {}
    : ({
        flexDirection: 'row',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
      } as IBoxProps);

  return (
    <Box {...wrapperProps}>
      <Box flexGrow={1}>
        <FinancialOverview
          {...{
            account_type: account_type.toLowerCase() as AccountType,
            ...financials,
            statement,
            summary,
          }}
        />
      </Box>
      {!hideDetailsButton && (
        <ViewDetailsButton
          account_description={account_description}
          account_type={account_type}
          account_id={account_id}
          customerId={customerId}
        />
      )}
    </Box>
  );
};
