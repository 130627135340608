import { useParams, useLocation } from 'react-router-dom';
import { UseRoutes } from './shared';

const useRoute = (): UseRoutes => {
  const params = useParams();
  const { pathname, key } = useLocation();
  const routeParams = params as UseRoutes['routeParams'];

  return { routeParams, pathName: pathname, routeName: key };
};

export default useRoute;
