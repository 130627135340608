import React, { useState, useRef, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { Box, useStyles, useDeviceType, Card } from '@nutrien/bonsai-core';
import { selectedAccountMutations } from '../../reactiveVariables';
import { useTransaction } from '../../context/TransactionContext';
import ButtonGroup from '../ButtonGroup';
import TransactionChipFilters from './TransactionChipFilters';
import TransactionDateRange from './TransactionDateRange';
import Tabs from '../Tabs/Tabs';
import { DateViewTabs } from 'reactiveVariables/variables/selectedAccountVar';

interface FilterProps {
  showTabs?: boolean;
}

interface FilterWrapperProps {
  children: JSX.Element;
  type: 'card' | 'flat';
}

export const FilterWrapper = ({
  children,
  type,
}: FilterWrapperProps): JSX.Element => {
  const styles = useStyles((theme) => ({
    filter: {
      marginBottom: theme.spacing(2),
    },
  }));
  return type === 'card' ? (
    <Card style={styles.filter} testID="transaction-filters">
      {children}
    </Card>
  ) : (
    <Box style={styles.filter} testID="transaction-filters">
      {children}
    </Box>
  );
};

const TransactionFilters = ({ showTabs = true }: FilterProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const slidesRef = useRef<ScrollView>(null);
  const {
    state: { queryTypeIndex },
    setQueryTypeIndex,
  } = useTransaction();
  const { editAccountView } = selectedAccountMutations;
  const { isDesktop } = useDeviceType();
  const tabs: DateViewTabs[] = ['Statement date', 'Date range'];

  useEffect(() => {
    slidesRef.current?.scrollTo({
      x: currentIndex * cardWidth,
      y: 0,
      animated: true,
    });
  }, [currentIndex, cardWidth]);

  useEffect(() => {
    editAccountView({
      date_view: currentIndex === 0 ? 'Statement date' : 'Date range',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const styles = useStyles((theme) => ({
    filter: {
      marginBottom: theme.spacing(2),
    },
    filterContent: {
      alignItems: 'center',
      justifyContent: isDesktop ? 'flex-end' : 'center',
    },
    filterContentDesktop: {
      flexDirection: 'row',
    },
    filterContentMobile: {
      flexDirection: 'column-reverse',
    },
    pressable: {
      backgroundColor: 'transparent',
    },
    carousel: {
      flex: 1,
    },
    selectedButton: {
      borderBottomColor: theme.auColors.primary.default,
      borderBottomWidth: 3,
    },
    carouselPillPicker: {
      width: cardWidth,
      justifyContent: 'center',
      marginVertical: isDesktop ? theme.spacing(1) : 0,
      paddingHorizontal: 0,
    },
    carouselDatePicker: isDesktop
      ? {
          alignItems: showTabs ? 'stretch' : 'center',
          justifyContent: 'flex-start',
          marginVertical: theme.spacing(2),
          marginHorizontal: theme.spacing(1),
        }
      : {
          justifyContent: 'flex-start',
          marginTop: theme.spacing(2),
          marginHorizontal: theme.spacing(1),
        },
    buttonGroupLabel: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3.5),
    },
    carouselControllerContainer: {
      flexDirection: 'row',
      paddingHorizontal: theme.spacing(2),
    },
    carouselControl: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      borderBottomWidth: 2,
      borderBottomColor: theme.auColors.neutral[500],
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    filterContainer: {
      width: '100%',
    },
  }));

  return (
    <FilterWrapper type={showTabs ? 'card' : 'flat'}>
      <View
        style={[
          styles.filterContent,
          isDesktop ? styles.filterContentDesktop : styles.filterContentMobile,
        ]}
      >
        {showTabs && (
          <Box width={isDesktop ? '50%' : '100%'}>
            <Box mx={2} mb={2} justifyContent="center" flexGrow={1}>
              <ButtonGroup
                onPress={setQueryTypeIndex}
                selectedIndex={queryTypeIndex}
                buttons={[
                  { text: 'Items' },
                  { text: 'Invoices' },
                  { text: 'Statements' },
                ]}
                label={'Display'}
              />
            </Box>
          </Box>
        )}
        <Box style={styles.filterContainer}>
          <Box
            style={styles.carousel}
            onLayout={({ nativeEvent: { layout } }) =>
              setCardWidth(layout.width)
            }
          >
            <Tabs
              containerStyle={styles.filterContainer}
              testID={currentIndex === 0 ? 'Statement date' : 'Date range'}
              onPress={setCurrentIndex}
              selectedIndex={currentIndex}
              tabs={tabs}
            />
            {currentIndex === 0 ? (
              <ScrollView
                ref={slidesRef}
                scrollEnabled={false}
                horizontal
                showsHorizontalScrollIndicator={false}
                bounces={false}
                nestedScrollEnabled // Needed so nested scrollview scrolls on android
              >
                <Box style={styles.carouselPillPicker}>
                  <TransactionChipFilters navRightXOffset={440} />
                </Box>
              </ScrollView>
            ) : (
              <Box style={styles.carouselDatePicker}>
                <Box flexDirection="row" mb={isDesktop ? 0 : 1}>
                  <TransactionDateRange showTabs={showTabs} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </View>
    </FilterWrapper>
  );
};

export default TransactionFilters;
