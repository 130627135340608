import { ApolloError, useReactiveVar } from '@apollo/client';
import formatDate from 'date-fns/format';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { Transaction } from '../../components/Transaction/transactions.types';
import { TRANSACTIONS_FILTER, TxSortOptions } from '../../constants';
import { useTransaction } from '../../context/TransactionContext';
import {
  accountTransactionsMutations,
  accountTransactionsVar,
} from '../../reactiveVariables';
import useTransactionsQuery from '../gql/queries/useTransactionsQuery';
import {
  calcExpensesAndCredits,
  filterTransactions,
  formatTransactions,
  formatTransactionsWithLivestock,
  groupTransactions,
  sortTransactions,
  TransactionGroup,
} from './transaction.helper';
import {
  useTransactionFormatter,
  useTransactionWithLivestockFormatter,
} from './useTransactionFormatter';

export interface UseTransactionProps {
  accountNumber: string;
  sortValue: TxSortOptions;
  transactionsFilter?: TRANSACTIONS_FILTER;
  ruralcoAccNumber?: string | null;
}

export type GroupedTransactionsType = Map<string, TransactionGroup> | undefined;

export interface HasPdfs {
  numberOfPdfs?: number;
  documentNumbers: string[];
}
interface UseTransactionResponse {
  loading: boolean;
  error?: ApolloError;
  lineItems: Transaction[];
  refetch: () => void;
  groupedTransactions: GroupedTransactionsType;
  hasPdfs?: HasPdfs;
}

const DATE_FORMAT = 'yyyy-MM-dd';

const useTransactions = ({
  accountNumber,
  sortValue,
  transactionsFilter,
  ruralcoAccNumber,
}: UseTransactionProps): UseTransactionResponse => {
  const flags = useFlags();
  const isLotDetailsFeatureOn = flags['NG-3135-plasma-lot-details'];

  // TODO: Remove it after removing flag NG-3135-plasma-lot-details
  const { formatTransaction } = useTransactionFormatter();

  // Flatted transactions line items and add additional livestock info with transaction lines
  const formatTransactionWithLivestock = useTransactionWithLivestockFormatter();

  const {
    state: { fromDate, toDate },
  } = useTransaction();

  const [transactionsData, loading, error, refetch] = useTransactionsQuery({
    accountNumber,
    fromDate: formatDate(fromDate, DATE_FORMAT),
    toDate: formatDate(toDate, DATE_FORMAT),
    ruralcoAccNumber,
  });

  const { editParsedTransactions } = accountTransactionsMutations;

  const { itemList, groupedTransactions } =
    useReactiveVar(accountTransactionsVar)[accountNumber as string] ?? {};

  useEffect(() => {
    editParsedTransactions(accountNumber, {
      transactionsLoading: loading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    // Flatten, filter and format transaction line items.
    // `itemList` here is flattened for the table view.
    const filteredTransactions = filterTransactions(
      transactionsData,
      transactionsFilter
    );

    const formattedTransactions = isLotDetailsFeatureOn
      ? formatTransactionsWithLivestock(
          filteredTransactions,
          formatTransactionWithLivestock
        )
      : formatTransactions(filteredTransactions, formatTransaction);

    const { credits, expenses } = calcExpensesAndCredits(filteredTransactions);

    // update transactions in reactive variable
    editParsedTransactions(accountNumber, {
      itemList: formattedTransactions,
      credits,
      expenses,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsData, transactionsFilter]);

  const lineItems = useMemo(() => {
    // Sort transactions
    return sortTransactions(sortValue, itemList);
  }, [itemList, sortValue]);

  useEffect(() => {
    // `groupedTransactions` in the shape of a Map object is for the invoice view.
    editParsedTransactions(accountNumber, {
      groupedTransactions: groupTransactions(lineItems),
    });
  }, [accountNumber, editParsedTransactions, lineItems]);

  const hasPdfs = useMemo(() => {
    if (groupedTransactions) {
      const groupedTransactionsArray: TransactionGroup[] = [
        ...groupedTransactions.values(),
      ];
      return {
        numberOfPdfs: groupedTransactionsArray.filter(
          (item) => item.hasPdf === true
        ).length,
        documentNumbers: groupedTransactionsArray.flatMap(
          ({ hasPdf, invoiceNumber }) =>
            hasPdf && invoiceNumber ? [invoiceNumber.toString()] : []
        ),
      };
    }
  }, [groupedTransactions]);

  return {
    loading,
    error,
    refetch,
    lineItems,
    groupedTransactions,
    hasPdfs,
  };
};

export default useTransactions;
