import { CacheAccountIdDocumendId } from '../../hooks/gql/cache/useCachePdfZipIds';

interface DownloadBannerContent {
  title: string;
  body: string;
}

export type DownloadBannerType = 'success' | 'error' | 'popup_blocked';

const bannerContents = (
  documentInCache: CacheAccountIdDocumendId
): Record<DownloadBannerType, DownloadBannerContent> => {
  const { accountDescription, trimmedAccountId, accountId, fromDate, toDate } =
    documentInCache;

  // download success banner
  const downloadSuccessBody = `Started downloading invoices for ${accountDescription} #${
    trimmedAccountId ?? accountId
  } from ${fromDate} to ${toDate}.\nCheck your downloads folder.`;

  // download error banner
  const downloadErrorBody = `Unable to download invoices for ${accountDescription} #${
    trimmedAccountId ?? accountId
  } from ${fromDate} to ${toDate}.`;

  return {
    success: {
      title: 'Download started',
      body: downloadSuccessBody,
    },
    error: {
      title: 'Unable to download',
      body: downloadErrorBody,
    },
    popup_blocked: {
      title: 'Unable to download ZIP file',
      body: 'ZIP file download prevented by browser pop-up blocker. Please allow in your browser settings.',
    },
  };
};

export const getDownloadBannerContent = (
  type: DownloadBannerType,
  documentInCache: CacheAccountIdDocumendId
): DownloadBannerContent => {
  const content = bannerContents(documentInCache);
  return content[type as DownloadBannerType];
};
