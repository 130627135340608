import React from 'react';
import { TextStyle, useWindowDimensions } from 'react-native';
import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { ContentPage, ContentPageButton } from '../ContentPage';
import Link from '../Link';
import Warning from '../SVG/Warning';
import { NUTRIEN_URL, SUPPORT_PHONE } from '../../constants';

const ABNValidationFails = () => {
  const { isDesktop } = useDeviceType();
  const layout = useWindowDimensions();

  const navigateToNutrienHomePage = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(NUTRIEN_URL, '_self');
  };
  const theme = useTheme();

  // 48 is the size of the header on mobile, and 200 is the size of the footer on mobile
  const MOBILE_HEADER_HEIGHT = 48;
  const MOBILE_FOOTER_HEIGHT = 200;
  const pageHeight = isDesktop
    ? 'auto'
    : layout.height - MOBILE_HEADER_HEIGHT - MOBILE_FOOTER_HEIGHT;

  const contentPageTitle =
    'Thanks for your interest in registering online for digital access. Unfortunately this is a closed online trial.';
  const closeButton: ContentPageButton = {
    text: 'Close',
    onPress: () => {
      navigateToNutrienHomePage();
    },
    type: 'solid',
    testID: 'close-button',
  };
  const buttonList = [closeButton];
  const styles = {
    mobileNumber: {
      fontWeight: '500',
      color: theme.auColors.brand.twilight[300],
    } as TextStyle,
  };

  const MOBILE_NUMBER = (
    <Text style={styles.mobileNumber}>{SUPPORT_PHONE}</Text>
  );

  return (
    <Box
      testID="customer-self-registration-ABN-validation-fails"
      width="100%"
      height="100%"
      maxWidth={isDesktop ? 1136 : 'unset'}
      maxHeight={isDesktop ? 699 : 'unset'}
      minHeight={pageHeight}
      justifyContent={isDesktop ? 'center' : 'unset'}
      alignItems="center"
      marginLeft="auto"
      marginRight="auto"
      marginY={8}
    >
      <ContentPage
        title={contentPageTitle}
        icon={<Warning width={isDesktop ? 360 : 260} />}
        iconTestID={'warning-testID'}
        contentPageButtons={buttonList}
      >
        <Box testID="warning-body">
          <Box marginBottom={3}>
            <Text>
              You can register for access to your Nutrien account by contacting
              your local branch.
            </Text>
          </Box>
          <Box marginBottom={3}>
            <Text>
              If you have any issues trying to register please call{' '}
              {MOBILE_NUMBER} Monday to Friday (except public holidays), 9am to
              5pm (AEST).
            </Text>
          </Box>
          <Text>Clicking close will take you to</Text>
          <Box marginLeft="auto" marginRight="auto">
            <Link
              text={NUTRIEN_URL}
              hasUnderline={true}
              testID="test-nutrien-homepage-link"
              onPress={navigateToNutrienHomePage}
              type="twilight"
            />
          </Box>
        </Box>
      </ContentPage>
    </Box>
  );
};

export default ABNValidationFails;
