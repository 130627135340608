import React from 'react';
import { Box, useDeviceType } from '@nutrien/bonsai-core';
import isempty from 'lodash.isempty';
import Banner from '../Banner';
import { useFormatters } from '../../hooks';
import theme from '../../theme/theme';
import { errorSummaryMessage } from '../../utils/formValidation';
import { Errors } from '.';

const ErrorSummaryBanner = ({
  errorSummary,
}: {
  errorSummary: Errors | null;
}) => {
  const { formatCamelCaseToTitleCase } = useFormatters();
  const { isHandset } = useDeviceType();

  if (isempty(errorSummary)) {
    return null;
  }

  const styles = {
    errorSummaryBanner: {
      marginBottom: isHandset ? theme.spacing(2) : theme.spacing(7),
      marginTop: isHandset ? -theme.spacing(2) : theme.spacing(1),
    },
  };

  return (
    <Banner
      location="section"
      type="errorLight"
      visible={!isempty(errorSummary)}
      testID="error-summary-banner"
      title="Oops! We need some more information to create the user profile"
      style={styles.errorSummaryBanner}
    >
      <Box>
        {Object.entries(errorSummary as Errors).map(([fieldName, error]) => {
          const formattedFieldName = formatCamelCaseToTitleCase(fieldName);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line security/detect-object-injection
          const errorSummaryMessageFunc = errorSummaryMessage[error];

          return (
            <Box key={fieldName} testID={`${fieldName}-error-summary`}>
              {errorSummaryMessageFunc(formattedFieldName)}
            </Box>
          );
        })}
      </Box>
    </Banner>
  );
};

export default ErrorSummaryBanner;
