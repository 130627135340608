import React from 'react';
import { useStaff } from '../../context/StaffContext';
import { errorBannerMessage } from '../../constants';
import GlobalAlert from '.';

const SystemOutageAlert = (): JSX.Element | null => {
  const {
    state: { showSystemOutageAlert },
    dispatch,
  } = useStaff();

  const onCloseSystemOutageAlert = () =>
    dispatch({
      type: 'setShowSystemOutageAlert',
      value: false,
    });

  return (
    <GlobalAlert
      showAlert={showSystemOutageAlert}
      testID="system-outage-alert-banner"
      onCloseAlert={onCloseSystemOutageAlert}
      message={errorBannerMessage.outageAlert}
    />
  );
};

export default SystemOutageAlert;
