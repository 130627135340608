import {
  getMonth,
  getYear,
  isAfter,
  isBefore,
  addMonths,
  format,
  differenceInSeconds,
  minutesToSeconds,
  lastDayOfMonth,
} from 'date-fns';

export const isBetween1stAnd25th = (date: Date) => {
  const currentMonth = getMonth(date);
  const currentYear = getYear(date);

  return isBefore(
    new Date(date.toDateString()),
    new Date(currentYear, currentMonth, 26)
  );
};

export const isBetween26thAndEndOfMonth = (date: Date) => {
  const currentMonth = getMonth(date);
  const currentYear = getYear(date);

  return isAfter(
    new Date(date.toDateString()),
    new Date(currentYear, currentMonth, 25)
  );
};

export const getNextInvoiceDueDate = (date: Date) => {
  const currentMonth = getMonth(date);
  const currentYear = getYear(date);

  const thisMonth25 = new Date(currentYear, currentMonth, 25);

  if (isBetween26thAndEndOfMonth(date)) {
    return {
      date: addMonths(thisMonth25, 1),
      month: format(addMonths(thisMonth25, 1), 'LLLL'),
    };
  }

  return {
    date: thisMonth25,
    month: format(thisMonth25, 'LLLL'),
  };
};

export const getCreditsExpensesLastDay = (date: Date) => {
  return isBetween26thAndEndOfMonth(date)
    ? lastDayOfMonth(date)
    : addMonths(lastDayOfMonth(date), -1);
};

export const getFormattedMonth = (date: Date) => {
  return format(date, 'LLLL');
};

export const getCurrentAndNextMonthDueDate = () => {
  const today = new Date();
  const currentMonth = getMonth(today);
  const currentYear = getYear(today);

  const currentMonthDueDate = new Date(currentYear, currentMonth, 25);
  const nextMonthDueDate = addMonths(currentMonthDueDate, 1);

  return {
    currentMonthDueDate: format(currentMonthDueDate, 'yyyy-MM-dd'),
    nextMonthDueDate: format(nextMonthDueDate, 'yyyy-MM-dd'),
  };
};

export const isDueNextMonth = (dueDay: number): boolean => {
  return new Date().getDate() > dueDay;
};

export const secondsToMilliseconds = (seconds: number): number =>
  seconds * 1000;

/**
 * Checks if {currentDate} is within minutes of the SUM OF: {dateToCompareInMs} - {minutes}
 * @param currentDate
 * @param dateToCompareInMs
 * @param minutes
 * @returns
 */
export const isDateWithinMinutesOfDate = (
  currentDate: number,
  dateToCompareInMs: number,
  minutes: number
) => {
  return (
    differenceInSeconds(dateToCompareInMs, currentDate) <
    minutesToSeconds(minutes)
  );
};
