import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Text,
  useDeviceType,
  ActivityIndicator,
} from '@nutrien/bonsai-core';
import { useTransaction } from '../../context/TransactionContext';
import { HasPdfs } from '../../hooks/useTransactions';
import useAccount from '../../screens/Account/useAccount';
import { selectedAccountVar } from '../../reactiveVariables';
import {
  useGetPdfZip,
  useCachePdfZipIds,
  useCacheAccountSummary,
  useSegment,
} from '../../hooks';
import { trackEvent } from '../../constants/segment';
import { CacheAccountIdDocumendId } from '../../hooks/gql/cache/useCachePdfZipIds';
import useAccountOverview from '../AccountOverview/useAccountOverview';
import DownloadButton from './DownloadButton';
import DownloadBanner from './DownloadBanner';
import Banner from '../Banner';

export interface DownloadZipProps {
  dataLoading: boolean;
  hasPdfs?: HasPdfs;
}

const renderDownload = ({
  dataLoading,
  handleGetPdfZip,
  numberOfPdfs,
  documentInCache,
}: {
  dataLoading: boolean;
  handleGetPdfZip: () => void;
  numberOfPdfs?: number;
  documentInCache?: CacheAccountIdDocumendId;
}) => {
  if (numberOfPdfs == null) return <></>;
  if (dataLoading && numberOfPdfs === 0) {
    return (
      <Box alignItems="flex-end" mr={1}>
        <ActivityIndicator testID="transactions-loading" />
      </Box>
    );
  }
  if (numberOfPdfs === 0) {
    return (
      <Box minWidth="270">
        <Banner
          location="inline"
          type="info"
          visible={true}
          testID="inline-banner-no-invoices"
        >
          <Text>No invoices to download</Text>
        </Banner>
      </Box>
    );
  }
  if (numberOfPdfs > 0) {
    return (
      <DownloadButton
        isPending={documentInCache?.isPending}
        numberOfPdfsTriggered={documentInCache?.numberOfPdfs}
        numberOfPdfs={numberOfPdfs}
        handleGetPdfZip={handleGetPdfZip}
      />
    );
  }
};

const DownloadZip = ({
  dataLoading,
  hasPdfs,
}: DownloadZipProps): JSX.Element => {
  const { isHandset } = useDeviceType();
  // Zipped PDF ID from mutation.
  const [getPdfZip, { data }] = useGetPdfZip();
  // Zipped PDFs in cache.
  const { selectFromCache, writeToCache, deleteFromCache } =
    useCachePdfZipIds();
  const {
    state: { fromDate, toDate },
  } = useTransaction();
  const { selectedAccountId } = useAccount();
  const fromDateFormat = {
    readable: format(fromDate, 'dd MMM yy'),
    standard: format(fromDate, 'yyyy-MM-dd'),
  };
  const toDateFormat = {
    readable: format(toDate, 'dd MMM yy'),
    standard: format(toDate, 'yyyy-MM-dd'),
  };
  const cacheAccountSummary = useCacheAccountSummary({
    account_id: selectedAccountId,
  });
  const documentInCache = selectFromCache({ accountId: selectedAccountId });
  const { getAccountTypeDescription, accountWithFinance } = useAccountOverview({
    account_id: selectedAccountId,
  });
  const accountDescription =
    accountWithFinance?.accountData &&
    getAccountTypeDescription(accountWithFinance.accountData);
  const [inlineBanner, setInlineBanner] = useState(false);

  const segment = useSegment();
  const { account_id, account_type, account_description } =
    useReactiveVar(selectedAccountVar);

  const handleGetPdfZip = () => {
    setInlineBanner(false);
    const segmentProperties = {
      account_id,
      account_type,
      display_account_type: account_description,
      date_from: fromDateFormat.standard,
      date_to: toDateFormat.standard,
      document_count: hasPdfs?.numberOfPdfs,
      document_type: 'Invoice',
      try_again: false,
    };
    segment?.track(trackEvent.BULK_DOWNLOAD_STARTED, segmentProperties);
    getPdfZip({
      variables: {
        accountNumber: selectedAccountId,
        fromDate: fromDateFormat.standard,
        toDate: toDateFormat.standard,
        ruralcoAccNumber:
          cacheAccountSummary?.getAccountSummary?.RuralcoAccNumber?.toString(),
        documentNumbers: hasPdfs?.documentNumbers ?? [],
      },
    });
  };

  useEffect(() => {
    if (data) {
      const { getPdfZip: documentId } = data;
      const now: number = Date.now();
      writeToCache([
        {
          accountId: selectedAccountId,
          accountType: account_type,
          documentId,
          isPending: true,
          numberOfPdfs: hasPdfs?.numberOfPdfs,
          fromDate: fromDateFormat.readable,
          toDate: toDateFormat.readable,
          trimmedAccountId:
            cacheAccountSummary?.getAccountSummary?.AccountNumber?.toString(),
          accountDescription,
          variables: {
            accountNumber: selectedAccountId,
            fromDate: fromDateFormat.standard,
            toDate: toDateFormat.standard,
            ruralcoAccNumber:
              cacheAccountSummary?.getAccountSummary?.RuralcoAccNumber?.toString(),
            documentNumbers: hasPdfs?.documentNumbers ?? [],
          },
          timestamp: now,
          isTryAgain: false,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (documentInCache?.results || documentInCache?.isBlockedByPopup) {
      setInlineBanner(true);
    }
  }, [documentInCache?.results, documentInCache?.isBlockedByPopup]);

  const handleClose = () => {
    setInlineBanner(false);
    deleteFromCache({ id: documentInCache?.documentId });
  };

  const downloadStatus = documentInCache?.results?.output?.url
    ? 'success'
    : 'error';

  const isBlocked = !!documentInCache?.isBlockedByPopup;
  const bannerType = isBlocked ? 'popup_blocked' : downloadStatus;

  return (
    <>
      <Box
        margin={0}
        mb={2}
        width={isHandset ? '100%' : 'auto'}
        testID="download-zip"
      >
        {renderDownload({
          dataLoading,
          handleGetPdfZip,
          numberOfPdfs: hasPdfs?.numberOfPdfs,
          documentInCache,
        })}
      </Box>
      {inlineBanner && documentInCache && (
        <Box margin={0} mb={2} width="100%">
          <DownloadBanner
            type={bannerType}
            documentInCache={documentInCache}
            onClose={handleClose}
            location="inline"
            isVisible={true}
            testID={`inline-banner-download-${bannerType}`}
          />
        </Box>
      )}
    </>
  );
};

export default DownloadZip;
