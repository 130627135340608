import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  UpdateProfile,
  UpdateProfileVariables,
} from 'types/generated/UpdateProfile';

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $familyName: String!
    $fullName: String!
    $givenName: String!
    $mobilePhone: String!
    $onboardedBranchCode: String!
    $preferredName: String!
    $customerId: String!
  ) {
    updateProfile(
      payload: {
        id: $customerId
        family_name: $familyName
        full_name: $fullName
        given_name: $givenName
        mobile_phone: $mobilePhone
        onboarded_branch_code: $onboardedBranchCode
        preferred_branch_code: $onboardedBranchCode
        preferred_name: $preferredName
      }
    ) {
      id
      onboarded_branch_code
      full_name
    }
  }
`;

type UpdateProfileFunction = (
  options?: MutationFunctionOptions<UpdateProfile, UpdateProfileVariables>
) => Promise<unknown>;

const useUpdateProfileMutation = (): [
  UpdateProfileFunction,
  {
    data?: UpdateProfile | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [mutateUpdateProfile, { data, error, loading }] = useMutation<
    UpdateProfile,
    UpdateProfileVariables
  >(UPDATE_PROFILE);

  return [mutateUpdateProfile, { data, error, loading }];
};

export default useUpdateProfileMutation;
