import { ReactElement } from 'react';

export { default as CustomerList } from './CustomerList';
export { default as useCustomerFormatter } from './useCustomerFormatter';

interface CustomerInfoElementCell {
  value: string;
  displayValue: JSX.Element | null;
}

export interface CustomerInfo {
  'Given Name': CustomerInfoElementCell;
  'Family Name': CustomerInfoElementCell;
  'Email Address': CustomerInfoElementCell;
  'List of Accounts': ReactElement;
  onboardedBranchCode: string | null;
  customerId: string;
}

export type HandleRowPress = (customerId: string) => void;

export const PAGE_SIZE = 25;
