import {
  useCustomerProfileByCustomerIdQuery,
  usePersonDetailsByIdQuery,
} from '../../hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface CustomerProfile {
  onboardedBranchCode: string | null;
  customerProfileLoading: boolean;
}

const useCustomerProfile = (customerDomainId: string): CustomerProfile => {
  let onboardedBranchCode: string | null = null,
    customerProfileLoading = false;
  const flags = useFlags();

  if (customerDomainId) {
    if (flags['NG-2644-party-model']) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [customerProfileData, loading] = usePersonDetailsByIdQuery({
        customerId: customerDomainId,
      });
      customerProfileLoading = loading;
      onboardedBranchCode =
        customerProfileData &&
        customerProfileData.party_vw_get_person_details &&
        customerProfileData.party_vw_get_person_details.length > 0
          ? customerProfileData.party_vw_get_person_details[0]
              ?.onboarded_by_branch_code
          : null;
    } else {
      const [customerProfileData, loading] =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCustomerProfileByCustomerIdQuery({
          customerId: customerDomainId,
        });
      customerProfileLoading = loading;
      onboardedBranchCode =
        customerProfileData &&
        customerProfileData.customer_profile &&
        customerProfileData.customer_profile.length > 0
          ? customerProfileData.customer_profile[0]?.onboarded_branch_code
          : null;
    }
  }

  return {
    onboardedBranchCode,
    customerProfileLoading,
  };
};

export default useCustomerProfile;
