import React from 'react';
import { Modal } from 'native-base';
import { ActivityIndicator, Snackbar } from '@nutrien/bonsai-core';

interface ModalProps {
  isOpen: boolean;
  loading: boolean;
  hasError: boolean;
  onErrorClose: () => void;
}

const FullScreenModalOverlay = ({
  isOpen,
  loading,
  hasError,
  onErrorClose,
}: ModalProps): JSX.Element => (
  <Modal backgroundColor="backdrop" isOpen={isOpen}>
    {loading && <ActivityIndicator />}
    <Snackbar
      visible={hasError}
      message="Something went wrong, please try again."
      actionTitle="Ok"
      onActionPress={onErrorClose}
    />
  </Modal>
);

export default FullScreenModalOverlay;
