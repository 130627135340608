import React from 'react';
import {
  useTheme,
  Box,
  Text,
  ListItem,
  ListItemProps,
} from '@nutrien/bonsai-core';
import { SearchBranch_sords_branch } from 'types/generated/SearchBranch';
import { UseUserDetailsFormProps } from './index';
import { Platform } from 'react-native';

interface Props {
  item: SearchBranch_sords_branch;
  handleSearchResultClick: UseUserDetailsFormProps['handleSearchResultClick'];
  isHighlighted?: boolean;
}

const BranchRow = ({
  item,
  handleSearchResultClick,
  isHighlighted,
}: Props): JSX.Element => {
  const theme = useTheme();
  const styles = {
    row: { marginRight: theme.spacing(1) },
    content: isHighlighted && { color: theme.auColors.neutral[200] },
    containerStyle: { padding: theme.spacing(2), cursor: 'pointer' },
  };

  const { branch_name, branch_code } = item;

  const renderRow = () => (
    <Box style={styles.row} testID={`branch-${branch_name}`}>
      <Text ellipsizeMode="tail" numberOfLines={1} style={styles.content}>
        {branch_name}
      </Text>
    </Box>
  );

  if (Platform.OS === 'web') {
    return <Box style={styles.containerStyle}>{renderRow()}</Box>;
  }

  const listProps = {
    onPress: () => handleSearchResultClick(item),
  } as unknown as ListItemProps;

  return (
    <ListItem key={branch_code} {...listProps}>
      {renderRow()}
    </ListItem>
  );
};

export default BranchRow;
