import React, { useState } from 'react';
import { Icon, Box, Text } from '@nutrien/bonsai-core';
import theme from '../../theme/theme';
import { ViewStyle, Pressable } from 'react-native';

interface IconProps {
  name: string;
  testID: string;
  size: number;
  color?: string;
}

export interface ActionListProps {
  title: string;
  onPress?: () => void;
  iconLeft: IconProps;
  iconRight?: IconProps | null;
  selected?: boolean;
  disabled?: boolean;
  testID?: string;
}

const ActionList = ({
  title,
  onPress,
  iconLeft,
  iconRight,
  selected = false,
  disabled = false,
  testID,
}: ActionListProps) => {
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const itemColor = () => {
    if (pressed || selected) {
      return theme.auColors.neutral[800];
    }
    if (hovered) {
      return theme.auColors.primary[400];
    }
    return theme.auColors.primary.default;
  };

  const bgColor = () => {
    if (selected) {
      return theme.auColors.primary[100];
    }
    if (pressed) {
      return theme.auColors.neutral[300];
    }
    if (hovered) {
      return theme.auColors.neutral[200];
    }
    return 'transparent';
  };

  const styles = {
    textStyle: {
      fontFamily: theme.typography.fontFamily.regular,
      color: itemColor(),
      marginLeft: theme.spacing(1),
    },
    link: {
      textDecorationLine: hovered ? 'underline' : 'none',
      outline: 'none',
    } as ViewStyle,
    boxShadow: {
      boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.dark}`,
      borderRadius: 4,
    },
  };

  return (
    <Pressable
      testID={testID}
      style={[styles.link, focused && styles.boxShadow]}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - web only
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={onPress}
      disabled={disabled}
    >
      <Box
        flexDirection="row"
        alignItems="center"
        px={1.5}
        py={1}
        justifyContent="space-between"
        bg={bgColor()}
        borderRadius={4}
        testID="action-list-container"
      >
        <Box flexDirection="row" alignItems="center">
          <Icon
            name={iconLeft.name}
            testID={iconLeft.testID}
            color={itemColor()}
            size={iconLeft.size}
          />
          <Text style={styles.textStyle}>{title}</Text>
        </Box>
        {iconRight && (
          <Icon
            name={iconRight.name}
            testID={iconRight.testID}
            color={iconRight.color}
            size={iconRight.size}
          />
        )}
      </Box>
    </Pressable>
  );
};

export default ActionList;
