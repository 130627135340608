import { Box } from '@nutrien/bonsai-core';
import React from 'react';
import { TransactionGroup } from '../../hooks/useTransactions/transaction.helper';
import { DownloadDocumentFn } from '../Transaction/TabScene';
import TransactionFooter from './TransactionFooter';
import TransactionHeader from './TransactionHeader';
import TransactionItem from './TransactionItem';

export interface TransactionDefaultProps {
  group: TransactionGroup;
  onDownload: DownloadDocumentFn;
}

const TransactionDefault = ({
  group,
  onDownload,
}: TransactionDefaultProps): JSX.Element => {
  const {
    documentId,
    title,
    total,
    issueDate,
    dueDate,
    items,
    hasPdf,
    documentAccountType,
  } = group;

  return (
    <>
      <TransactionHeader
        title={title}
        total={total}
        issueDate={issueDate}
        dueDate={dueDate}
      />
      <Box testID="transaction-default">
        {items.map((item, index) => (
          <TransactionItem
            key={index}
            item={item}
            isLast={index === group.items.length - 1}
          />
        ))}
      </Box>
      {hasPdf && (
        <TransactionFooter
          onDownload={() => onDownload(documentId, title, documentAccountType)}
          invoiceNumber={title}
        />
      )}
    </>
  );
};

export default TransactionDefault;
