import {
  Box,
  Text,
  useDeviceType,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import { Modal } from 'native-base';
import React, { useRef } from 'react';
import { AccessibilityRole } from 'react-native';
import { Button } from '../Button';
import Link from '../Link';
import { browserDetect, isIOS } from '../../utils/browserDetect';

export interface DrawerProps {
  placement?: 'left' | 'right';
  width?: number;
  heading?: string;
  children?: JSX.Element | JSX.Element[];
  testID?: string;
  isOpen: boolean;
  onClose: (closeMethod: string) => void;
}

const Drawer = ({
  placement = 'right',
  width = 375,
  heading,
  children,
  testID,
  isOpen,
  onClose,
}: DrawerProps): JSX.Element => {
  const initialRef = useRef(null);
  const { isDesktop, isHandset } = useDeviceType();
  const theme = useTheme();
  const styles = useStyles(() => ({
    overlay: {
      backgroundColor: theme.auColors.functional.overlay.light,
    },
    left: {
      borderRadius: 0,
      height: '100vh',
      maxHeight: 'none',
      marginLeft: 0,
      marginRight: 'auto',
    },
    right: {
      borderRadius: 0,
      height: '100vh',
      maxHeight: 'none',
      marginLeft: 'auto',
      marginRight: 0,
    },
    headingSmall:
      theme.auTypography[isDesktop ? 'desktop' : 'mobile'].headingSmall,
  }));

  // This is to detect all browsers that using safari as its engine.
  // eg: Safari on desktop. all browsers in ios devices including iphone/ipad
  const isSafariEngine = isIOS() || browserDetect() === 'safari';

  return (
    <Modal
      initialFocusRef={initialRef}
      style={styles.overlay}
      onClose={() => onClose('ClickAway')}
      isOpen={isOpen}
      testID={testID ? `drawer-${testID}` : 'drawer'}
      aria-hidden={!isOpen}
      // disable slide animation in Safari as native-base not able to handle initFocus together with slide animation
      animationPreset={isSafariEngine ? undefined : 'slide'}
      _slide={{ placement }}
    >
      <Modal.Content
        accessibilityRole={'dialog' as AccessibilityRole}
        backgroundColor={theme.auColors.neutral[100]}
        width={isHandset ? 'calc(100vw - 40px)' : width}
        maxWidth={width}
        // eslint-disable-next-line security/detect-object-injection
        style={styles[placement]}
      >
        <Modal.Header padding={2} backgroundColor={theme.auColors.neutral[100]}>
          <Box flexDir="row" justifyContent="space-between" alignItems="center">
            {heading && (
              <Text h3 style={styles.headingSmall}>
                {heading}
              </Text>
            )}
            <Button
              ref={initialRef}
              title="Close"
              type="outline"
              onPress={() => onClose('HeaderClose')}
              testID="drawer-header-close-button"
              disabled={!isOpen}
            />
          </Box>
        </Modal.Header>
        {children && <Modal.Body padding={2}>{isOpen && children}</Modal.Body>}
        <Modal.Footer
          padding={2}
          justifyContent="center"
          backgroundColor={theme.auColors.neutral[200]}
        >
          <Link
            hasUnderline={true}
            text="Close"
            onPress={() => onClose('FooterClose')}
            testID="drawer-footer-close-link"
            disabled={!isOpen}
            isEmphasis={true}
          />
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default Drawer;
