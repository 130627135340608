import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { openURL } from 'expo-linking';
import { useReactiveVar } from '@apollo/client';
import { useStyles } from '@nutrien/bonsai-core';

import useAccount from '../../screens/Account/useAccount';
import { selectedAccountVar } from '../../reactiveVariables';
import { Sentry } from '../../utils/sentry';
import {
  useSubscribeGetPdfZip,
  useCachePdfZipIds,
  useGetPdfZip,
  useSegment,
} from '../../hooks';
import { trackEvent } from '../../constants/segment';
import DownloadBanner from '../DownloadZip/DownloadBanner';

interface SubscribeDownloadProps {
  documentId: string;
}

const SubscribeDownload = ({
  documentId,
}: SubscribeDownloadProps): JSX.Element => {
  const styles = useStyles((theme) => ({
    download: {
      marginTop: theme.spacing(1),
    },
  }));
  const [subscriptionData, _loading, _error] = useSubscribeGetPdfZip({
    id: documentId,
    skip: !documentId,
  });
  const { selectFromCache, writeToCache, deleteFromCache } =
    useCachePdfZipIds();
  const [getPdfZip, { data }] = useGetPdfZip();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [responseId, setResponseId] = useState<string>();
  const documentInCache = selectFromCache({ documentId });
  // Selected account id from route.
  const { selectedAccountId } = useAccount();

  const segment = useSegment();
  const { account_id, account_type, account_description } =
    useReactiveVar(selectedAccountVar);

  const segmentProperties = {
    account_id: account_id || documentInCache?.accountId,
    account_type: account_type || documentInCache?.accountType,
    display_account_type: account_description,
    date_from: documentInCache?.variables?.fromDate,
    date_to: documentInCache?.variables?.toDate,
    document_count: documentInCache?.numberOfPdfs,
    document_type: 'Invoice',
  };

  const openZipUrl = (url: string): boolean => {
    const windowOpen =
      Platform.OS === 'web' // eslint-disable-next-line security/detect-non-literal-fs-filename
        ? window.open(url, '_blank')
        : openURL(url);

    return windowOpen === null || windowOpen === undefined;
  };

  useEffect(() => {
    const url = subscriptionData?.getPdfZip?.output?.url;
    const fileSize = subscriptionData?.getPdfZip?.output?.fileSize;

    // URL is returned from subscription.
    if (url && documentInCache) {
      // only track segment event once, if not already tracked
      if (!documentInCache.isTracked) {
        segment?.track(trackEvent.BULK_DOWNLOAD_FINISHED, {
          ...segmentProperties,
          download_successful: true,
          try_again: documentInCache.isTryAgain,
          export_size_kb: fileSize
            ? Number((fileSize / 1024).toFixed(2))
            : undefined,
        });
      }
      writeToCache([
        {
          ...documentInCache,
          isPending: false,
          isTriggered: true,
          isTracked: true,
          results: subscriptionData?.getPdfZip,
        },
      ]);
      setIsVisible(!!url);
      setResponseId(subscriptionData?.getPdfZip?.id);
      if (!documentInCache.isTriggered) {
        const isBlocked = openZipUrl(url);
        if (isBlocked) {
          writeToCache([
            {
              ...documentInCache,
              isBlockedByPopup: true,
              isPending: false,
            },
          ]);
        }
      }
    }
    // Error is returned from subscription.
    if (subscriptionData?.getPdfZip?.errors && documentInCache) {
      // only track segment event once, if not already tracked
      if (!documentInCache.isTracked) {
        segment?.track(trackEvent.BULK_DOWNLOAD_FINISHED, {
          ...segmentProperties,
          download_successful: false,
          try_again: documentInCache.isTryAgain,
        });
      }
      const { errors } = subscriptionData.getPdfZip;
      writeToCache([
        {
          ...documentInCache,
          isPending: false,
          isTriggered: false,
          isTracked: true,
          results: subscriptionData?.getPdfZip,
        },
      ]);
      setIsVisible(true);
      setResponseId(subscriptionData?.getPdfZip?.id);
      Sentry.captureException(
        new Error(
          `Error downloading invoices for ${documentInCache.accountId}: ${errors?.error}`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  useEffect(() => {
    // If data is returned from trying again, we write to cache with new document id.
    if (data && documentInCache) {
      const { getPdfZip: newDocumentId } = data;
      const now: number = Date.now();
      writeToCache([
        {
          ...documentInCache,
          documentId: newDocumentId,
          isPending: true,
          isTriggered: false,
          timestamp: now,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClose = () => {
    setIsVisible(false);
    deleteFromCache({ id: responseId });
  };

  const handleTryAgain = () => {
    if (documentInCache) {
      // only track segment event once, if not already tracked
      segment?.track(trackEvent.BULK_DOWNLOAD_STARTED, {
        ...segmentProperties,
        try_again: true,
      });

      writeToCache([
        {
          ...documentInCache,
          isTryAgain: true,
          isTracked: false,
          isBlockedByPopup: false,
        },
      ]);

      const { variables } = documentInCache;
      getPdfZip({
        variables,
      });
    }
  };

  // We don't show the global banners when the route's selected
  // account id is the same as the account id in cache.
  if (selectedAccountId === documentInCache?.accountId) return <></>;

  if (subscriptionData?.getPdfZip?.errors && documentInCache) {
    return (
      <View style={styles.download}>
        <DownloadBanner
          type="error"
          location="global"
          isVisible={isVisible}
          documentInCache={documentInCache}
          onClose={handleClose}
          onTryAgain={handleTryAgain}
          testID="subscribe-download-error"
        />
      </View>
    );
  }

  if (documentInCache && documentInCache.isBlockedByPopup) {
    return (
      <View style={styles.download}>
        <DownloadBanner
          type="popup_blocked"
          location="global"
          isVisible={true}
          documentInCache={documentInCache}
          onClose={handleClose}
          onTryAgain={handleTryAgain}
          testID="subscribe-download-url-blocked"
        />
      </View>
    );
  }

  if (subscriptionData?.getPdfZip?.output?.url && documentInCache) {
    return (
      <View style={styles.download}>
        <DownloadBanner
          type="success"
          location="global"
          isVisible={isVisible}
          documentInCache={documentInCache}
          onClose={handleClose}
          testID="subscribe-download-success"
        />
      </View>
    );
  }

  return <></>;
};

export default SubscribeDownload;
