import { isAfter } from 'date-fns';
import {
  FarmVisitRecommendations,
  FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge,
} from '../../types/generated/FarmVisitRecommendations';
import { CustomerInfo } from '../../components/CustomerList';
import { SelectOptions } from '../../components/Select';
import _ from 'lodash';

interface UseSort {
  sortCustomersByName: (customers: CustomerInfo[]) => CustomerInfo[];
  sortBranchList: (branchList: SelectOptions[]) => SelectOptions[];
  sortFarmVisitsByDueDate: (
    recommendationList: FarmVisitRecommendations
  ) => FarmVisitRecommendations;
  sortFarmVisitByDueDateAndType: (
    recommendations: FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge[],
    recommendationType: string
  ) => FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge[];
}

const useSort = (): UseSort => {
  const sortCustomersByName = (customers: CustomerInfo[]) =>
    customers.sort((a, b) => {
      const firstNameCompare = a['Given Name'].value.localeCompare(
        b['Given Name'].value
      );
      if (firstNameCompare !== 0) {
        return firstNameCompare;
      } else {
        return a['Family Name'].value.localeCompare(b['Family Name'].value);
      }
    });

  const sortBranchList = (branchList: SelectOptions[]) =>
    branchList.sort((a, b) => a.label.localeCompare(b.label));

  const sortFarmVisitsByDueDate = (
    recommendationList: FarmVisitRecommendations
  ): FarmVisitRecommendations => {
    const sortedList =
      recommendationList.farm_farm_visit_summary[0]?.farm_visit_summary_reco_bridge
        .slice()
        .sort((a, b) => {
          return isAfter(
            new Date(
              a.farm_visit_reco_bridge_reco_activity?.rec_due_dtm as string
            ),
            new Date(
              b.farm_visit_reco_bridge_reco_activity?.rec_due_dtm as string
            )
          )
            ? 1
            : -1;
        });

    const visitSummary = [...recommendationList.farm_farm_visit_summary];
    visitSummary[0] = {
      ...visitSummary[0],
      farm_visit_summary_reco_bridge: sortedList,
    };
    return {
      ...recommendationList,
      farm_farm_visit_summary: [...visitSummary],
    };
  };

  const sortFarmVisitByDueDateAndType = (
    recommendations: FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge[],
    recommendationType: string
  ) => {
    const sorted = recommendations.slice().sort((a, b) => {
      const { farm_visit_reco_bridge_reco_activity: aFarmRecoBridge } =
        Object(a);
      const { farm_visit_reco_bridge_reco_activity: bFarmRecoBridge } =
        Object(b);

      const aDate =
        aFarmRecoBridge[recommendationType as keyof typeof aFarmRecoBridge] ||
        '';

      const bDate =
        bFarmRecoBridge[recommendationType as keyof typeof bFarmRecoBridge] ||
        '';

      if (Date.parse(aDate) && Date.parse(bDate)) {
        return isAfter(new Date(aDate as string), new Date(bDate as string))
          ? 1
          : -1;
      }
      // check if the date is undefined, move undefined to the bottom.
      return Date.parse(aDate) ? -1 : 0;
    });

    return sorted;
  };

  return {
    sortCustomersByName,
    sortBranchList,
    sortFarmVisitsByDueDate,
    sortFarmVisitByDueDateAndType,
  };
};

export default useSort;
