import { useEffect, useState, useCallback } from 'react';
import { Platform } from 'react-native';
import fetch from 'cross-fetch';
import {
  ApolloClient,
  NormalizedCacheObject,
  HttpLink,
  ApolloError,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import {
  onError,
  ErrorHandler,
  ErrorResponse,
} from '@apollo/client/link/error';
import {
  CachePersistor,
  LocalStorageWrapper,
  AsyncStorageWrapper,
} from 'apollo3-cache-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAuth from '../../auth/hooks';
import { navigate } from '../../components/Navigation/RootNavigation';
import { SESSION_TIMEOUT } from '../../constants';
import { cache } from './cache';
import { defaultOptions } from './defaultOptions';
import { useSegment } from '../../hooks';
import { trackEvent, gqlErrorMessage } from '../../constants/segment';
import { SegmentProps } from '../../hooks/useSegment/shared';
import { routeConfig } from '../../routes/shared';

export const handleError = (
  error: ApolloError,
  logoutFn: () => void | Promise<void>
): void | Promise<void> => {
  if (error.networkError?.message === 'Login required') {
    void logoutFn();
  }
};

export const onErrorCallBack = (
  logoutFn: () => void | Promise<void>,
  segment: SegmentProps | null
): ErrorHandler => {
  const errorHandler = (error: ErrorResponse) => {
    const graphQLErrorMessage = error?.graphQLErrors?.[0]?.message;
    if (graphQLErrorMessage && segment) {
      void segment.track(trackEvent.ERROR, {
        error_message: gqlErrorMessage,
      });
    }
    return handleError(error as unknown as ApolloError, logoutFn);
  };
  return errorHandler as unknown as ErrorHandler;
};

const useApolloClientWithPersist = () => {
  const { getIdTokenClaims, logout, getAccessTokenSilently, isAuthenticated } =
    useAuth();

  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject>>();
  const segment = useSegment();

  const clearCache = useCallback(async () => {
    if (!persistor) {
      return;
    }
    await persistor.purge();
  }, [persistor]);

  const webLogout = async () => {
    await clearCache();
    logout({
      returnTo: `${window.location.origin}/?status=${SESSION_TIMEOUT}`,
    });
  };

  const nativeLogout = async () => {
    await clearCache();
    logout();
    navigate(routeConfig.Login.getNavigateProps({ status: SESSION_TIMEOUT }));
  };

  const logoutFn = Platform.OS === 'web' ? webLogout : nativeLogout;

  const endpoint = `${process.env.API_BASE_URL}/v1/graphql`;
  const httpLink = new HttpLink({
    uri: endpoint,
    fetch: fetch,
  });

  const wsLink =
    Platform.OS === 'web'
      ? new WebSocketLink({
          uri: `${process.env.API_BASE_WS_URL}/v1/graphql`,
          options: {
            reconnect: true,
            lazy: true,
            connectionParams: async () => {
              const claims = await getClaims();
              return {
                headers: {
                  ...(claims && { Authorization: `Bearer ${claims.__raw}` }),
                },
              };
            },
          },
        })
      : null;

  const getClaims = async () => {
    try {
      let claims = await getIdTokenClaims();
      if (!claims) {
        // No claims.. ID Token expired. Call token endpoint with refresh_token grant
        await getAccessTokenSilently();
        // ID Token will now be available to get claims from
        claims = await getIdTokenClaims();
      }
      return claims;
    } catch (err) {
      const error = err as Error;
      throw new Error(error.message);
    }
  };

  const authLink = setContext(async (_, { headers }) => {
    const claims = await getClaims();
    return {
      headers: {
        ...headers,
        ...(claims && { Authorization: `Bearer ${claims.__raw}` }),
        'Access-Control-Allow-Headers': 'newrelic',
      },
    };
  });

  const logoutLink = onError(
    onErrorCallBack(logoutFn, segment) as ErrorHandler
  );

  const getStorage = () =>
    Platform.OS === 'web'
      ? new LocalStorageWrapper(window.localStorage)
      : new AsyncStorageWrapper(AsyncStorage);

  useEffect(() => {
    const init = async () => {
      const newPersistor = new CachePersistor({
        cache,
        storage: getStorage(),
        debug: process.env.APOLLO_CLIENT_DEV_MODE === 'true',
        trigger: 'write',
      });
      await newPersistor.restore();
      setPersistor(newPersistor);
    };

    init().catch((err) => {
      throw new Error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Platform.OS === 'web' ? null : isAuthenticated]);

  const splitLink = wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

  return {
    client: new ApolloClient({
      cache,
      connectToDevTools: process.env.APOLLO_CLIENT_DEV_MODE === 'true',
      defaultOptions,
      link: logoutLink.concat(authLink?.concat(splitLink)),
    }),
    clearCache,
  };
};

export default useApolloClientWithPersist;
