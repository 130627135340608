import React, { useEffect } from 'react';
import {
  View,
  Animated,
  Easing,
  ViewStyle,
  StyleProp,
  Dimensions,
  TextStyle,
} from 'react-native';

import { Surface, useTheme } from 'react-native-paper';
import { Icon, Text, Box, Pressable } from '@nutrien/bonsai-core';
import theme from '../../theme/theme';
import { BannerType } from '.';

const BANNER_SHOW_DURATION = 240;

interface ActionItems {
  text: string;
  icon?: React.ReactNode;
  onPress: () => void;
  disabled?: boolean;
}

interface BannerProps {
  location: 'global' | 'section' | 'inline';
  type: BannerType;
  visible: boolean;
  children: React.ReactNode;
  onDismissPress?: () => void;
  contentStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  title?: string;
  actionItems?: ActionItems[];
  hideLeftBorder?: boolean;
}

type NativeEvent = {
  nativeEvent: {
    layout: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  };
};

type BannerTypeRecord = Record<BannerType, string>;

const IconNameMapping: BannerTypeRecord = {
  successLight: 'check-circle',
  success: 'check-circle',
  error: 'error-outline',
  errorLight: 'error-outline',
  info: 'info',
  warning: 'warning',
};

const IconOrTitleColorMapping: BannerTypeRecord = {
  successLight: theme.auColors.functional.success.dark,
  success: theme.auColors.functional.success.dark,
  error: theme.auColors.functional.error.dark,
  errorLight: theme.auColors.functional.error.dark,
  info: theme.auColors.functional.info.dark,
  warning: theme.auColors.functional.warning.dark,
};

const BackgroundColorMapping: BannerTypeRecord = {
  success: theme.auColors.functional.success.light,
  error: theme.auColors.functional.error.light,
  info: theme.auColors.functional.info.light,
  warning: theme.auColors.functional.warning.light,
  errorLight: 'transparent',
  successLight: 'transparent',
};

export const Banner = ({
  type,
  visible,
  children,
  contentStyle,
  onDismissPress,
  style,
  title,
  actionItems,
  location,
  hideLeftBorder = false,
  ...otherProps
}: BannerProps): JSX.Element => {
  const { current: position } = React.useRef<Animated.Value>(
    new Animated.Value(visible ? 1 : 0)
  );

  const containerWidth = Dimensions.get('window').width - theme.spacing(4);
  const [layout, setLayout] = React.useState<{
    height: number;
    measured: boolean;
  }>({
    height: 0,
    measured: false,
  });

  const { scale } = useTheme().animation;

  useEffect(() => {
    if (visible) {
      // show
      Animated.timing(position, {
        duration: BANNER_SHOW_DURATION * scale,
        toValue: 1,
        easing: Easing.out(Easing.exp),
        useNativeDriver: false,
      }).start();
    } else {
      // hide
      Animated.timing(position, {
        duration: BANNER_SHOW_DURATION * scale,
        toValue: 0,
        easing: Easing.out(Easing.exp),
        useNativeDriver: false,
      }).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, position]);

  const handleLayout = ({ nativeEvent }: NativeEvent) => {
    setLayout({ height: nativeEvent.layout.height, measured: true });
  };

  const height = Animated.multiply(position, layout.height);

  const translateY = Animated.multiply(
    Animated.add(position, -1),
    layout.height
  );

  const isGlobalBanner = location === 'global';

  const styles = {
    absolute: {
      position: 'absolute',
      top: 0,
      width: '100%',
    },
    container: {
      zIndex: 5,
      backgroundColor: BackgroundColorMapping[type as BannerType],
      elevation: 0,
      borderLeftWidth: hideLeftBorder ? 0 : theme.spacing(0.25),
      borderColor: IconOrTitleColorMapping[type as BannerType],
    },
    iconWrapper: {
      marginTop: 2,
      marginRight: theme.spacing(1),
    },
    message: {
      marginRight: theme.spacing(1),
      maxWidth: isGlobalBanner ? 'unset' : containerWidth - theme.spacing(15),
    },
    transparent: { opacity: 0 },
    wrapper: {
      overflow: 'hidden',
      alignSelf: 'center',
      width: '100%',
    },
    title: {
      color: IconOrTitleColorMapping[type as BannerType],
      marginBottom: theme.spacing(2),
      marginTop: -3,
      fontFamily: theme.typography.fontFamily.semiBold,
    },
    actionItem: {
      backgroundColor: 'transparent',
      marginLeft: theme.spacing(1),
    },
    firstActionItem: {
      backgroundColor: 'transparent',
      marginLeft: 0,
    },
    actionItemText: {
      color: IconOrTitleColorMapping[type as BannerType],
      textDecorationLine: 'underline',
    },
  };

  return (
    <Surface
      testID="core-banner"
      {...otherProps}
      style={[styles.container, style]}
    >
      <View style={[styles.wrapper, contentStyle] as ViewStyle}>
        <Animated.View style={{ height }} />
        <Animated.View
          onLayout={handleLayout}
          style={
            [
              layout.measured || !visible
                ? // If we have measured banner's height or it's invisible,
                  // Position it absolutely, the layout will be taken care of the spacer
                  [styles.absolute, { transform: [{ translateY }] }]
                : // Otherwise position it normally
                  null,
              !layout.measured && !visible
                ? // If we haven't measured banner's height yet and it's invisible,
                  // hide it with opacity: 0 so user doesn't see it
                  styles.transparent
                : null,
            ] as ViewStyle
          }
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection="row"
            flexWrap="wrap"
            margin={2}
          >
            <Box
              flexDirection="row"
              justifyContent="flex-start"
              maxWidth={containerWidth}
              width={location === 'section' ? '100%' : 'auto'}
            >
              <View style={styles.iconWrapper}>
                <Icon
                  name={IconNameMapping[type as BannerType]}
                  size={20}
                  color={IconOrTitleColorMapping[type as BannerType]}
                  testID={`${type}-banner-icon`}
                />
              </View>
              <Box
                width={
                  isGlobalBanner
                    ? containerWidth - 76
                    : location === 'section'
                    ? '100%'
                    : 'auto'
                }
              >
                {title && (
                  <Text h3 style={styles.title}>
                    {title}
                  </Text>
                )}
                <Text style={styles.message}>{children}</Text>
              </Box>
            </Box>
            {onDismissPress && (
              <View style={styles.iconWrapper}>
                <Icon
                  name="close"
                  onPress={onDismissPress}
                  size={20}
                  testID="banner-close-button"
                />
              </View>
            )}
            {actionItems && (
              <Box
                flexDir="row"
                justifyContent="flex-end"
                width="100%"
                testID="action-items"
              >
                {actionItems.map(({ text, onPress, disabled, icon }, index) => (
                  <Pressable
                    key={`${text}_${index}`}
                    focusable={true}
                    style={
                      index === 0 ? styles.firstActionItem : styles.actionItem
                    }
                    focusedStyle={
                      index === 0 ? styles.firstActionItem : styles.actionItem
                    }
                    hoveredStyle={
                      index === 0 ? styles.firstActionItem : styles.actionItem
                    }
                    onPress={onPress}
                    disabled={disabled}
                    testID={
                      disabled
                        ? `action-item-${index}-disabled`
                        : `action-item-${index}`
                    }
                  >
                    <Box flexDirection="row">
                      {icon}
                      <Text style={styles.actionItemText as TextStyle}>
                        {text}
                      </Text>
                    </Box>
                  </Pressable>
                ))}
              </Box>
            )}
          </Box>
        </Animated.View>
      </View>
    </Surface>
  );
};

export default Banner;
