import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../auth/context';
import RedirectingIndicator from '../components/RedirectingIndicator';

/**
 * A simple solution to route guest users away from the wrapped screen.
 * It was done to take away any major architectural changes from the
 * existing router -> since it doesn't support this & if you try force it,
 * the result is ugly.
 */
const withGuestRouteCheck = <Props extends object>(
  WrappedComponent: React.FC<Props>,
  ifLoggedInRouteTo = 'dashboard'
) => {
  return (props: Props) => (
    <AuthContext.Consumer>
      {({ isLoading, isAuthenticated }) => {
        if (isLoading) {
          return <RedirectingIndicator />;
        }

        if (isAuthenticated) {
          return <Navigate replace to={ifLoggedInRouteTo} />;
        }

        return <WrappedComponent {...props} />;
      }}
    </AuthContext.Consumer>
  );
};

export default withGuestRouteCheck;
