import React from 'react';
import { useStaff } from '../../context/StaffContext';
import { errorBannerMessage } from '../../constants';
import GlobalAlert from '.';

const LivestockInvoiceAlert = (): JSX.Element | null => {
  const {
    state: { showLivestockInvoiceAlert },
    dispatch,
  } = useStaff();

  const onCloseLivestockInvoiceAlert = () =>
    dispatch({
      type: 'setShowLivestockInvoiceAlert',
      value: false,
    });

  return (
    <GlobalAlert
      showAlert={showLivestockInvoiceAlert}
      testID="livestock-invoice-alert-banner"
      onCloseAlert={onCloseLivestockInvoiceAlert}
      message={errorBannerMessage.livestockInvoiceAlert}
    />
  );
};

export default LivestockInvoiceAlert;
