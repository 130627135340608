import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  InsertCustomerAccounts,
  InsertCustomerAccountsVariables,
} from 'types/generated/InsertCustomerAccounts';

export const INSERT_CUSTOMER_ACCOUNTS = gql`
  mutation InsertCustomerAccounts($accountIds: [String!]!, $customerId: uuid!) {
    insertCustomerAccounts(
      payload: { account_ids: $accountIds, customer_id: $customerId }
    ) {
      account_id
    }
  }
`;

type InsertCustomerAccountsFunction = (
  options?: MutationFunctionOptions<
    InsertCustomerAccounts,
    InsertCustomerAccountsVariables
  >
) => Promise<unknown>;

const useInsertCustomerAccountsMutation = (): [
  InsertCustomerAccountsFunction,
  {
    data?: InsertCustomerAccounts | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [mutateInsertCustomerAccounts, { data, error, loading }] = useMutation<
    InsertCustomerAccounts,
    InsertCustomerAccountsVariables
  >(INSERT_CUSTOMER_ACCOUNTS);

  return [mutateInsertCustomerAccounts, { data, error, loading }];
};

export default useInsertCustomerAccountsMutation;
