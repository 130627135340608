import { Box, Text, useTheme } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { TextStyle } from 'react-native';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../types/generated/CustomerAccountsSummary';
import FinanceOneSectionSummary from './FinanceOneSectionSummary';
import FinanceTwoSectionsSummary from './FinanceTwoSectionsSummary';
import PrepaySummary from './PrepaySummary';
import TradingSummary from './TradingSummary';
import { TileType } from './useAccountOverview';

export interface AccountSummaryProps {
  accountId?: string | null;
  tileType: TileType;
  summary?: AccountSummaryData | null;
  handleClickViewDetails: (param?: {
    isLivestock?: boolean;
    hasLivestock?: boolean;
  }) => void;
}

const AccountSummary: FC<AccountSummaryProps> = ({
  accountId,
  tileType,
  summary,
  handleClickViewDetails,
}) => {
  const theme = useTheme();
  const summaryStyles = {
    label: {
      minHeight: theme.spacing(3),
    },
    labelText: {
      color: theme.auColors.neutral[700],
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    error: {
      color: theme.auColors.functional.error.dark,
      marginY: theme.spacing(2),
    },
    errorBold: {
      color: theme.auColors.functional.error.dark,
      fontWeight: '600',
    } as TextStyle,
    infoHeading: {
      fontWeight: '600',
      color: theme.auColors.neutral[700],
    } as TextStyle,
    info: {
      color: theme.auColors.neutral[700],
    },
    description: {
      marginTop: theme.spacing(1),
    },
  };

  const hasLivestock = summary?.AccountType === 'L';

  switch (tileType) {
    case 'FinanceOneSection':
      return (
        <FinanceOneSectionSummary
          accountDisplayName="Finance"
          summary={summary}
          handleClickViewDetails={handleClickViewDetails}
          summaryStyles={summaryStyles}
          testID={accountId ? `${tileType}-${accountId}` : tileType}
        />
      );
    case 'FinanceTwoSections':
      return (
        <>
          <FinanceTwoSectionsSummary
            accountDisplayName="Merchandise"
            summary={summary}
            isLivestock={false}
            testID={
              accountId
                ? `${tileType}-${accountId}-merchandise`
                : `${tileType}-merchandise`
            }
            handleClickViewDetails={() =>
              handleClickViewDetails({
                isLivestock: false,
                hasLivestock,
              })
            }
            summaryStyles={summaryStyles}
          />
          {hasLivestock && (
            <Box mt={2}>
              <FinanceTwoSectionsSummary
                accountDisplayName="Livestock"
                summary={summary}
                isLivestock={true}
                testID={
                  accountId
                    ? `${tileType}-${accountId}-livestock`
                    : `${tileType}-livestock`
                }
                handleClickViewDetails={() =>
                  handleClickViewDetails({
                    isLivestock: true,
                    hasLivestock,
                  })
                }
                summaryStyles={summaryStyles}
              />
            </Box>
          )}
        </>
      );
    case 'Trading':
      return (
        <>
          <TradingSummary
            accountDisplayName={'Merchandise'}
            isLivestock={false}
            summary={summary}
            handleClickViewDetails={() =>
              handleClickViewDetails({
                isLivestock: false,
                hasLivestock,
              })
            }
            summaryStyles={summaryStyles}
            testID={
              accountId
                ? `${tileType}-${accountId}-merchandise`
                : `${tileType}-merchandise`
            }
          />
          {hasLivestock && (
            <Box mt={2}>
              <TradingSummary
                accountDisplayName="Livestock"
                isLivestock={true}
                summary={summary}
                handleClickViewDetails={() =>
                  handleClickViewDetails({
                    isLivestock: true,
                    hasLivestock,
                  })
                }
                summaryStyles={summaryStyles}
                testID={
                  accountId
                    ? `${tileType}-${accountId}-livestock`
                    : `${tileType}-livestock`
                }
              />
            </Box>
          )}
        </>
      );
    case 'Prepay':
      return (
        <PrepaySummary
          accountDisplayName="Pre-Pay"
          summary={summary}
          handleClickViewDetails={handleClickViewDetails}
          summaryStyles={summaryStyles}
          testID={accountId ? `${tileType}-${accountId}` : tileType}
        />
      );
    default:
      return <Text>This account type is not currently supported.</Text>;
  }
};

export default AccountSummary;
