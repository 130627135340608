import { useReactiveVar } from '@apollo/client';
import { Box, NamedStyles, Text, useTheme } from '@nutrien/bonsai-core';
import React from 'react';
import Tabs from '../../../components/Tabs';
import { useAccountSummary } from '../../../hooks';
import { accountTransactionsVar } from '../../../reactiveVariables';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummary } from 'types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUp } from 'types/generated/CustomerAccountsSummarySplitUp';
import WhitePanelWrapper from '../WhitePanelWrapper';
import LivestockPanel from './LivestockPanel';
import TradingCurrentBalancePanel from './TradingCurrentBalancePanel';
import TradingNextDuePanel from './TradingNextDuePanel';
import TradingOverduePanel from './TradingOverduePanel';

export interface TradingProps {
  accountId: string;
  accountSummary: AccountSummary | null;
  accountSummarySplitUp: AccountSummarySplitUp | null;
  accountSummaryLoading: boolean;
  tabs?: {
    filterIndex: number;
    tabs: string[];
    onTabChange: (index: number) => void;
  };
  panelStyles: NamedStyles<Record<string, undefined>>;
}

const Trading = ({
  accountId,
  accountSummary,
  accountSummarySplitUp,
  accountSummaryLoading,
  tabs,
  panelStyles,
}: TradingProps): JSX.Element => {
  const { overdue } = useAccountSummary(accountSummary);
  const accountTransactions = useReactiveVar(accountTransactionsVar);
  const { credits, expenses, transactionsLoading } = accountTransactions[
    accountId as string
  ] || {
    credits: null,
    expenses: null,
    transactionsLoading: false,
  };
  const theme = useTheme();
  const onLivestockTab = tabs?.filterIndex === 1;
  return (
    <WhitePanelWrapper testID="account-details-trading-panel">
      <>
        {tabs && (
          <Tabs
            containerStyle={{ paddingBottom: theme.spacing(2) }}
            testID="transactions-filter"
            onPress={tabs.onTabChange}
            selectedIndex={tabs.filterIndex}
            tabs={tabs.tabs}
          />
        )}
      </>
      {onLivestockTab ? (
        <LivestockPanel
          summary={accountSummary}
          sharedStyles={panelStyles}
          summarySplitUp={accountSummarySplitUp}
        />
      ) : (
        <>
          {!tabs && (
            <Box mb={2}>
              <Text h2 testID="merchandise-title">
                Merchandise
              </Text>
            </Box>
          )}
          {overdue.amount ? (
            <Box mb={3}>
              <TradingOverduePanel
                summary={accountSummary}
                summarySplitUp={accountSummarySplitUp}
                overdueAmount={overdue.amount}
                panelStyles={panelStyles}
              />
            </Box>
          ) : undefined}
          <Box mb={3}>
            <TradingNextDuePanel
              summary={accountSummary}
              summarySplitUp={accountSummarySplitUp}
              loading={transactionsLoading || accountSummaryLoading}
              creditAmount={credits}
              expensesAmount={expenses}
              panelStyles={panelStyles}
            />
          </Box>
          <Box>
            <TradingCurrentBalancePanel
              summary={accountSummary}
              summarySplitUp={accountSummarySplitUp}
              panelStyles={panelStyles}
            />
          </Box>
        </>
      )}
    </WhitePanelWrapper>
  );
};

export default Trading;
