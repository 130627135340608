import React, { FC } from 'react';
import {
  Card,
  Box,
  Text,
  useStyles,
  TabularData,
  Column,
  useDeviceType,
} from '@nutrien/bonsai-core';
import { FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_field } from '../../types/generated/FarmRecommendation';

type props = {
  fields?: FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_field[];
};

const FieldTable: FC<props> = ({ fields }) => {
  const { isDesktop } = useDeviceType();
  const styles = useStyles((theme) => ({
    fieldCard: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    tableHeader: {
      color: theme.auColors.neutral[600],
      fontSize: isDesktop
        ? theme.typography.fontSize.medium
        : theme.typography.fontSize.small,
      fontWeight: '400',
    },
    tableHeaderStyle: {
      paddingHorizontal: 0,
      borderBottomColor: theme.auColors.neutral[500],
      borderBottomWidth: 0.5,
    },
    row: {
      paddingHorizontal: 0,
      borderBottomWidth: 0,
      paddingVertical: 0,
    },
  }));

  // Do not return a table if there is no data to display
  if (!fields?.length) return <></>;

  const ellipsizeText = (title: string) => (
    <Text numberOfLines={1}>{title}</Text>
  );

  const formattedFieldInformation = fields.map((field) => ({
    field_name: ellipsizeText(field.field_name || ''),
    activity_area_desc: ellipsizeText(field.activity_area_desc || ''),
    activity_crop_name: ellipsizeText(field.activity_crop_name || ''),
    activity_variety_name: ellipsizeText(field.activity_variety_name || ''),
  }));

  const customHeader = (title: string) => (
    <Text numberOfLines={1} style={styles.tableHeader}>
      {title}
    </Text>
  );

  const tableColumns = [
    {
      Header: customHeader('Name'),
      accessor: 'field_name',
    },
    {
      Header: customHeader('Act. - ha'),
      accessor: 'activity_area_desc',
      maxWidth: 150,
    },
    {
      Header: customHeader('Crop Type'),
      accessor: 'activity_crop_name',
    },
    {
      Header: customHeader('Variety'),
      accessor: 'activity_variety_name',
    },
  ] as Column[];

  return (
    <Card style={styles.fieldCard} testID="recommendation-table">
      <Box flexDirection="row" justifyContent="space-between" mb={1}>
        <Text bodyBold>Fields</Text>
      </Box>
      <TabularData
        type={'table'}
        tableProps={{
          sortable: formattedFieldInformation?.length > 1, // No point being able to sort a single row
          headerStyle: styles.tableHeaderStyle,
          rowStyle: styles.row,
          pagination: false,
        }}
        columns={tableColumns}
        data={formattedFieldInformation as unknown as Record<string, unknown>[]}
      />
    </Card>
  );
};

export default FieldTable;
