import { useReactiveVar } from '@apollo/client';
import {
  ActivityIndicator,
  Box,
  Text,
  useDeviceType,
  useStyles,
} from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { TxSortOptions } from '../../constants';
import { useTransaction } from '../../context/TransactionContext';
import { HasPdfs } from '../../hooks/useTransactions';
import { TabSceneType } from '../../reactiveVariables/variables/selectedAccountVar';
import {
  selectedAccountVar,
  accountTransactionsVar,
} from '../../reactiveVariables';
import { Button } from '../Button';
import { DownloadZip } from '../DownloadZip';
import TransactionSortOptions from './TransactionSortOptions';
import { ExpandCollapseAllLink } from './ExpandCollapseAllLink';
import { LivestockLoadErrorBanner } from './LivestockLoadErrorBanner';

interface TransactionActionProps {
  sortValue: string;
  onSort: (value: TxSortOptions) => void;
  enableExport: boolean;
  transactionsLoading: boolean;
  selectedTab: TabSceneType;
  hasPdfs?: HasPdfs;
  hasLivestockTransactions?: boolean;
  onReloadLotDetails: (ids: string[]) => void;
}

interface ExportButtonProps {
  onPress: () => void;
  disabled: boolean;
  isPending: boolean;
}

const GstText = (): JSX.Element => {
  const { isDesktop } = useDeviceType();

  const styles = useStyles((theme) => ({
    gstText: {
      fontStyle: 'italic',
      color: theme.auColors.neutral[600],
      marginTop: isDesktop ? 0 : theme.spacing(1),
    },
  }));

  return (
    <Text style={styles.gstText} testID="gst-text">
      All prices are GST-inclusive
    </Text>
  );
};

const ExportCSVButton = ({
  onPress,
  disabled = false,
  isPending,
}: ExportButtonProps): JSX.Element => {
  const { isHandset } = useDeviceType();
  if (isPending) {
    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        mb={2}
        width={isHandset ? '100%' : 'auto'}
      >
        <Button
          accessibilityLabel="Preparing CSV file"
          fullWidth={true}
          title={'Preparing CSV file'}
          iconLeft={<ActivityIndicator testID="preparing-csv-icon" />}
          disabled={true}
        />
      </Box>
    );
  }
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      mb={2}
      width={isHandset ? '100%' : 'auto'}
    >
      <Button
        accessibilityLabel="Export to CSV"
        testID={disabled ? 'export-csv-disabled' : 'export-csv'}
        title="Export to .CSV"
        onPress={onPress}
        disabled={disabled}
        fullWidth={true}
      />
    </Box>
  );
};

export const TransactionActions = (
  props: TransactionActionProps
): JSX.Element => {
  const {
    transactionsLoading,
    enableExport,
    selectedTab,
    hasPdfs,
    hasLivestockTransactions,
    onReloadLotDetails,
  } = props;

  const [documentIdsWithLotDetailErrors, setDocumentIdsWithLotDetailErrors] =
    useState<string[]>([]);

  const { isDesktop, isHandset } = useDeviceType();
  const flags = useFlags();
  const {
    state: { csvDataLoading },
    exportCSV,
  } = useTransaction();

  const { account_id } = useReactiveVar(selectedAccountVar);
  const lotDetailsList = useReactiveVar(accountTransactionsVar)[
    account_id as string
  ]?.lotDetailsList;
  const [lotDetailsLoadingGlobally, setLotDetailsLoadingGlobally] =
    useState(false);

  useEffect(() => {
    if (lotDetailsList) {
      const lotData = Object.values(lotDetailsList);
      setLotDetailsLoadingGlobally(
        lotData.some((item) => item.lotDetailsLoading === true)
      );

      const errors: string[] = [];
      lotData.forEach(({ lotDetailsResponse }) => {
        if (lotDetailsResponse?.documentNumber && lotDetailsResponse?.error) {
          errors.push(lotDetailsResponse.documentNumber);
        }
      });
      setDocumentIdsWithLotDetailErrors(errors);
    }
  }, [lotDetailsList]);

  const styles = useStyles((theme) => ({
    wrapper: {
      borderTopWidth: 1,
      borderTopColor: theme.auColors.neutral[500],
      paddingTop: theme.spacing(3),
      paddingHorizontal: isDesktop ? 0 : theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }));

  const showExpandCollapseAll =
    hasLivestockTransactions &&
    selectedTab === 'Invoice view' &&
    flags['NG-2721-livestock-sale-lot-details'];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      style={styles.wrapper}
      testID="transaction-actions"
    >
      <>
        {selectedTab === 'Invoice view' &&
          Platform.OS === 'web' &&
          flags['NG-3088-bulk-download-invoice-pdf'] && (
            <DownloadZip dataLoading={transactionsLoading} hasPdfs={hasPdfs} />
          )}
        {enableExport && (
          <ExportCSVButton
            onPress={exportCSV}
            disabled={transactionsLoading}
            isPending={csvDataLoading}
          />
        )}
      </>
      <Box
        display="flex"
        flexDirection={isHandset ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="flex-end"
        width="100%"
      >
        <TransactionSortOptions {...props} />
        <GstText />
      </Box>
      {documentIdsWithLotDetailErrors.length > 1 && (
        <LivestockLoadErrorBanner
          visible={true}
          onReload={() => onReloadLotDetails(documentIdsWithLotDetailErrors)}
          reloading={lotDetailsLoadingGlobally}
        />
      )}
      {showExpandCollapseAll && (
        <ExpandCollapseAllLink
          disabled={
            transactionsLoading ||
            documentIdsWithLotDetailErrors.length > 0 ||
            lotDetailsLoadingGlobally
          }
        />
      )}
    </Box>
  );
};
