import React, { useEffect, useMemo } from 'react';
import { Box } from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AccountOverview from '../../components/AccountOverview';
import AccountDetailTabs from '../../components/AccountDetailTabs';
import { useCustomerAccountSummaryQuery } from '../../hooks/gql/queries/useCustomerAccountsQuery';
import { TableViewProps } from '../../components/Transaction/TransactionTabs';
import AccountHeader from './AccountHeader';
import useAccount from './useAccount';

const Account = (): JSX.Element => {
  const flags = useFlags();
  const { selectedAccountId, customerId, accountType } = useAccount();
  const [accountSummary] = useCustomerAccountSummaryQuery({
    account_id: selectedAccountId,
  });

  const rowProps = { mb: 2 };
  const documentListProps: TableViewProps = useMemo(() => {
    return {
      accountNumber: selectedAccountId,
    };
  }, [selectedAccountId]);

  useEffect(() => {
    const ruralCoAccNumber =
      accountSummary?.getAccountSummary?.RuralcoAccNumber;
    if (ruralCoAccNumber) {
      documentListProps.ruralcoAccNumber = ruralCoAccNumber.toString();
    }
  }, [accountSummary, documentListProps]);

  const newAccountOverview =
    accountType &&
    flags['ng-2585-wdio-account-summary'] &&
    flags['ng-2585-wdio-account-summary'].includes(accountType.toLowerCase());

  return (
    <>
      <AccountHeader />
      {newAccountOverview ? (
        <AccountOverview
          account_id={selectedAccountId}
          customerId={customerId as string}
          hideDetailsButton
          isAccountDetailsView={true}
        />
      ) : (
        <Box {...rowProps}>
          <AccountOverview
            account_id={selectedAccountId}
            customerId={customerId as string}
            hideDetailsButton
            isAccountDetailsView={true}
          />
        </Box>
      )}
      <Box {...rowProps} testID="categorized-transactions-view">
        <AccountDetailTabs {...documentListProps} />
      </Box>
    </>
  );
};

export default Account;
