import React, { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import useApolloClientWithPersist from '../../hooks/useApolloClientWithPersist';

interface Props {
  children: ReactNode;
}

const ApolloClientProvider = ({ children }: Props): JSX.Element => {
  const { client } = useApolloClientWithPersist();

  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }
  return <></>;
};

export default ApolloClientProvider;
