import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  CreateCustomerLogin,
  ResendCustomerVerification,
  CreateCustomerLoginVariables,
  ResendCustomerVerificationVariables,
} from 'types/generated/CreateCustomerLogin';

export const CREATE_CUSTOMER_LOGIN = gql`
  mutation SendCustomerInvite($customer_id: uuid!) {
    createCustomerLogin(payload: { customer_id: $customer_id }) {
      created_at
      updated_at
      email
      user_metadata
      logins_count
      last_login
      email_verified
    }
  }
`;

export const RESEND_CUSTOMER_VERIFICATION = gql`
  mutation ResendVerificationEmail($customer_id: uuid!) {
    resendCustomerVerification(payload: { customer_id: $customer_id }) {
      created_at
      id
      status
      type
    }
  }
`;

type CreateCustomerLoginFunction = (
  options?: MutationFunctionOptions<
    CreateCustomerLogin,
    CreateCustomerLoginVariables
  >
) => Promise<unknown>;

type ResendCustomerVerificationFunction = (
  options?: MutationFunctionOptions<
    ResendCustomerVerification,
    ResendCustomerVerificationVariables
  >
) => Promise<unknown>;

const useCreateCustomerLoginMutation = (): [
  CreateCustomerLoginFunction,
  {
    data?: CreateCustomerLogin | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [mutateCustomerLogin, { data, error, loading }] = useMutation<
    CreateCustomerLogin,
    CreateCustomerLoginVariables
  >(CREATE_CUSTOMER_LOGIN);

  return [mutateCustomerLogin, { data, error, loading }];
};

const useResendCustomerVerificationMutation = (): [
  ResendCustomerVerificationFunction,
  {
    data?: ResendCustomerVerification | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [mutateCustomerLogin, { data, error, loading }] = useMutation<
    ResendCustomerVerification,
    ResendCustomerVerificationVariables
  >(RESEND_CUSTOMER_VERIFICATION);

  return [mutateCustomerLogin, { data, error, loading }];
};

export default useCreateCustomerLoginMutation;
export { useResendCustomerVerificationMutation };
