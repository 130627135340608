import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  GetSendInvite,
  GetSendInviteVariables,
} from 'types/generated/GetSendInvite';

export const SEND_INVITE_QUERY = gql`
  mutation GetSendInvite($customer_id: uuid!) {
    getSendInvite(payload: { customer_id: $customer_id }) {
      status
      created_at
    }
  }
`;

type SendCustomerInviteFunction = (
  options?: MutationFunctionOptions<GetSendInvite, GetSendInviteVariables>
) => Promise<unknown>;

const useSendCustomerInviteMutation = (): [
  SendCustomerInviteFunction,
  {
    data?: GetSendInvite | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [sendInvite, { data, error, loading }] = useMutation<
    GetSendInvite,
    GetSendInviteVariables
  >(SEND_INVITE_QUERY);

  return [sendInvite, { data, loading, error }];
};

export { useSendCustomerInviteMutation };
