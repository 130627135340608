import { datadogRum } from '@datadog/browser-rum';

interface APP_MANIFEST {
  version: string;
}

const version =
  (process.env.APP_MANIFEST as APP_MANIFEST | undefined)?.version || '1.0.0';
const environment = process.env.ENVIRONMENT || 'dev';
const service = `au-digital-hub-${environment}`;
const applicationId = process.env.DATADOG_APPLICATION_ID || '';
const clientToken = process.env.DATADOG_CLIENT_TOKEN || '';
const traced_url_list = [
  process.env.API_BASE_URL,
  process.env.AUTH0_DOMAIN,
] || [/https:\/\/.*\.com.*/];
const sampleRate = 100;

export default (flags: Record<string, string>) => {
  const isDataDogEnabled = flags['enable-datadog-rum'];

  datadogRum.init({
    applicationId: isDataDogEnabled ? applicationId : '',
    clientToken: isDataDogEnabled ? clientToken : '',
    site: 'datadoghq.com',
    service: service,
    env: environment,
    version: version,
    sampleRate: isDataDogEnabled ? sampleRate : 0,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: traced_url_list,
  });

  return datadogRum;
};
