import React, { FC } from 'react';
import { Box, Text, useDeviceType, useStyles } from '@nutrien/bonsai-core';
import { useFormatters } from '../../hooks';
import { CustomerAccountWithFinance_sords_customer_account_finance as FinanceData } from '../../types/generated/CustomerAccountWithFinance';
import { AccountType } from './useAccountOverview';
import overviewCopy from './copy.json';

const copy = overviewCopy.financeOverview;

interface Props extends FinanceData {
  account_type: AccountType;
}

const FinanceOverview: FC<Props> = ({
  credit_limit,
  total_receivables,
}): JSX.Element => {
  const { formatCurrency } = useFormatters();
  const { isHandset } = useDeviceType();
  const styles = useStyles((theme) => ({
    balanceValue: {
      fontSize: theme.typography.fontSize.large,
      lineHeight: theme.typography.fontSize.large * 1.5,
    },
    value: {
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
    label: {
      color: theme.auColors.neutral[600],
      fontSize: theme.typography.fontSize.medium,
      lineHeight: theme.typography.fontSize.medium * 1.5,
      marginTop: theme.spacing(1),
    },
  }));

  return (
    <Box flexGrow={1} flexDirection={isHandset ? 'column' : 'row'}>
      <Box
        flexGrow={1}
        justifyContent="space-between"
        testID="account-balance"
        minWidth={'40%'}
      >
        <Text style={styles.balanceValue} subHeaderBold>
          {formatCurrency(total_receivables)}
        </Text>
        <Text style={styles.label}>{copy.balance}</Text>
      </Box>
      <Box
        flexGrow={2}
        justifyContent="space-between"
        testID="account-limit"
        py={isHandset ? 4 : 0}
      >
        <Text style={styles.value} captionBold>
          {formatCurrency(credit_limit)}
        </Text>
        <Text style={styles.label}>{copy.credits}</Text>
      </Box>
    </Box>
  );
};

export default FinanceOverview;
