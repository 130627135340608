import React, { FC } from 'react';
import {
  Text,
  Icon,
  useTheme,
  Box,
  NamedStyles,
  useStyles,
} from '@nutrien/bonsai-core';
import { useAccountSummary, useFormatters } from '../../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../../SummaryCard';
import PanelDrawer from '../PanelDrawer/PanelDrawer';
import useDrawer from '../../../hooks/useDrawer';
import { DrawerLink } from '../DrawerLink';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from '../../../types/generated/CustomerAccountsSummarySplitUp';

export interface TradingCurrentBalancePanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp?: AccountSummarySplitUpData | null;
  panelStyles?: NamedStyles<Record<string, undefined>>;
}

const TradingCurrentBalancePanel: FC<TradingCurrentBalancePanelProps> = ({
  summary,
  panelStyles,
  summarySplitUp,
}) => {
  const { renderNoneIfZero, formatDate } = useFormatters();
  const theme = useTheme();
  const styles = useStyles(panelStyles ?? {});
  const { overdue, nextDue, currentBalance, showMoreLinks } =
    useAccountSummary(summary);
  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      <SummaryCard
        heading="Current balance"
        summaryRow={[
          ...(overdue.amount
            ? [
                {
                  label: (
                    <Box
                      style={styles.label}
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Icon
                        name="error-outline"
                        color={theme.auColors.functional.error.dark}
                        size={24}
                        style={styles.icon}
                      />
                      <Text style={styles.errorBold}>Overdue</Text>
                    </Box>
                  ),
                  content: (
                    <Text style={styles.overdueAmount} bodyBold>
                      {renderNoneIfZero(overdue.amount)}
                    </Text>
                  ),
                  description: (
                    <Box marginTop={0.5}>
                      <Text style={styles.error}>incurring interest</Text>
                    </Box>
                  ),
                  testID: 'overdue-column',
                },
              ]
            : []),
          ...(nextDue.toBeFinalised
            ? [
                {
                  label: (
                    <Box
                      style={styles.label}
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Text style={styles.labelText}>
                        Next due{' '}
                        {nextDue.invoiceDate &&
                          formatDate(
                            nextDue.invoiceDate.toISOString(),
                            'dd MMM yyyy'
                          )}
                      </Text>
                    </Box>
                  ),
                  description: (
                    <Text style={styles.info}>
                      To be finalised at end of {nextDue.toBeFinalisedMonth}
                    </Text>
                  ),
                  testID: 'next-due-column',
                },
              ]
            : [
                {
                  label: (
                    <Box
                      style={styles.label}
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Text style={styles.labelText}>
                        Next due{' '}
                        {nextDue.invoiceDate &&
                          formatDate(
                            nextDue.invoiceDate.toISOString(),
                            'dd MMM yyyy'
                          )}
                      </Text>
                    </Box>
                  ),
                  content: renderNoneIfZero(nextDue.amount),
                  testID: 'next-due-column',
                },
              ]),
          {
            label: (
              <Box
                style={styles.label}
                flexDirection="row"
                alignItems="flex-start"
              >
                <Text style={styles.labelText}>Extended terms</Text>
              </Box>
            ),
            content: renderNoneIfZero(summary?.ExtendedTermAmount),
            button: (
              <DrawerLink
                enabled={showMoreLinks}
                drawerInfo={{
                  name: 'Extended terms',
                  data: { amount: summary?.ExtendedTermAmount, summarySplitUp },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: 'Merchandise',
                }}
              />
            ),
            testID: 'extended-terms-column',
          },
          {
            label: (
              <Box
                style={styles.label}
                flexDirection="row"
                alignItems="flex-start"
              >
                <Text style={styles.labelText}>Current balance</Text>
              </Box>
            ),
            content: renderNoneIfZero(currentBalance),
            testID: 'current-balance-column',
          },
        ]}
        testID={'account-details-trading-current-balance-panel'}
      />
      <PanelDrawer
        onClose={(closeMethod: string) =>
          onDrawerClose(closeMethod, {
            tab_view: 'Merchandise',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};

export default TradingCurrentBalancePanel;
