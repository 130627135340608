import React from 'react';
import { ApolloError } from '@apollo/client';
import { TransactionTableView } from '../TransactionsTableView';
import { FullScreenModalOverlay } from '../FullScreenModalOverlay';
import { TransactionGroupedView } from '../TransactionsGroupedView';
import { Transaction } from './transactions.types';
import { GroupedTransactionsType } from '../../hooks/useTransactions';
import { TabSceneType } from '../../reactiveVariables/variables/selectedAccountVar';
import { DocumentAccountType } from '../../types/generated/globalTypes';

export type DownloadDocumentFn = (
  documentId: string | null,
  documentNumber?: string,
  accountType?: DocumentAccountType
) => void;
export interface TabSceneProps {
  type: TabSceneType;
  onPress: DownloadDocumentFn;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  items: Transaction[];
  groupedItems: GroupedTransactionsType;
  showOverlay: boolean;
  documentLoading: boolean;
  documentError: boolean;
  onErrorClose: () => void;
  lotDetailErrorDocumentIds?: string[];
}

export const TabScene = ({
  type,
  onPress,
  loading,
  error,
  refetch,
  items,
  showOverlay,
  documentLoading,
  documentError,
  onErrorClose,
  groupedItems,
  lotDetailErrorDocumentIds,
}: TabSceneProps): JSX.Element => {
  return (
    <>
      {type === 'Invoice view' ? (
        <TransactionGroupedView
          onPress={onPress}
          loading={loading}
          error={error}
          groupedItems={groupedItems}
          refetch={refetch}
          lotDetailErrorDocumentIds={lotDetailErrorDocumentIds}
        />
      ) : (
        <TransactionTableView
          onPress={onPress}
          loading={loading}
          error={error}
          items={items}
          refetch={refetch}
        />
      )}
      <FullScreenModalOverlay
        isOpen={showOverlay}
        loading={documentLoading}
        hasError={documentError}
        onErrorClose={onErrorClose}
      />
    </>
  );
};
