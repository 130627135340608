import React from 'react';
import { ViewStyle, Platform } from 'react-native';
import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { CustomerProfilesWithAccounts_customer_profile } from 'types/generated/CustomerProfilesWithAccounts';
import { CustomerProfile } from 'types/generated/RegistrationStatus';
import { CustomerInfo } from '.';
import { useFormatters } from '../../hooks';

const useCustomerFormatter = (): {
  formatCustomer: (props: CustomerProfile) => CustomerInfo;
} => {
  const theme = useTheme();
  const { formatAccounts, formatAccountId } = useFormatters();
  const { isHandset, isDesktop } = useDeviceType();
  const formatCustomer = (
    props: CustomerProfile | CustomerProfilesWithAccounts_customer_profile
  ) => {
    let numberOfMoreAccounts;
    const {
      given_name: firstName,
      family_name: lastName,
      email,
      onboarded_branch_code: onboardedBranchCode,
      id: customerId,
    } = props;

    const accountNumbers = (props as CustomerProfile).account_numbers;
    const accounts = accountNumbers
      ? accountNumbers.split(',').map((accountId) => formatAccountId(accountId))
      : [];

    if (accounts.length > 3) {
      numberOfMoreAccounts = accounts.length - 3;
    }

    const styles = {
      nameStyle: {
        maxWidth: 140,
      },
      emailStyle: {
        maxWidth: 254,
      },
      accounts: {
        marginRight: theme.spacing(1),
        fontSize: isDesktop
          ? theme.typography.fontSize.medium
          : theme.typography.fontSize.small,
      },
      numberOfMoreAccounts: {
        fontSize: theme.typography.fontSize.small,
        color: theme.auColors.neutral[600],
      },
    };

    return {
      'Given Name': {
        value: firstName || '',
        displayValue: (
          <Text style={styles.nameStyle as ViewStyle}>{firstName || ''}</Text>
        ),
      },
      'Family Name': {
        value: lastName || '',
        displayValue: (
          <Text style={styles.nameStyle as ViewStyle}>{lastName || ''}</Text>
        ),
      },
      'Email Address': {
        value: email,
        displayValue: (
          <Text style={styles.emailStyle as ViewStyle}>{email}</Text>
        ),
      },
      'List of Accounts': (
        <Box
          display="flex"
          flexDirection={
            isHandset && Platform.OS !== 'android' ? 'column' : 'row'
          }
          width="100%"
        >
          <Text style={styles.accounts}>{formatAccounts(accounts)}</Text>
          {numberOfMoreAccounts && (
            <Text
              style={styles.numberOfMoreAccounts}
              testID="number-of-more-accounts"
            >
              +{numberOfMoreAccounts} more
            </Text>
          )}
        </Box>
      ),
      onboardedBranchCode: onboardedBranchCode,
      customerId,
    };
  };

  return { formatCustomer };
};

export default useCustomerFormatter;
