import React, { FC } from 'react';
import { Box, Card, useStyles } from '@nutrien/bonsai-core';
import EmptyState from '../EmptyState';

type props = {
  alert: string;
  message: string;
};

const NotFoundMessage: FC<props> = ({ alert, message }) => {
  const styles = useStyles((theme) => ({
    noRecMessage: {
      padding: theme.spacing(2),
    },
  }));

  return (
    <Box
      alignItems="center"
      mt={1}
      mb={3}
      mx={1}
      padding={2}
      flex={1}
      testID="no-recommendation-found"
    >
      <Card style={styles.noRecMessage}>
        <EmptyState type="no-documents" alert={alert} message={message} />
      </Card>
    </Box>
  );
};

export default NotFoundMessage;
