import { Box, NamedStyles, Text, useStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useFormatters } from '../../../hooks';
import SummaryCard from '../../SummaryCard';

export interface DrawerData {
  Label: string;
  Amount: number;
  LabelDescription?: string | null;
  ClearingDocNumber?: string | null;
  Description?: string | null;
  DueDate?: string | null;
  DocumentNumber?: string | null;
}

export interface DrawerContentProps {
  drawerStyles?: NamedStyles<Record<string, undefined>>;
  drawerData?: DrawerData[];
  testID?: string | null;
  heading?: string;
}

const DrawerContent: FC<DrawerContentProps> = ({
  drawerStyles,
  drawerData,
  testID,
  heading,
}) => {
  const styles = useStyles(drawerStyles ?? {});

  const { renderNoneIfZero } = useFormatters();

  return (
    <Box mt={2}>
      <SummaryCard
        vertical={true}
        heading={heading}
        summaryRow={
          drawerData &&
          drawerData.map(
            ({ Label, Amount, Description, LabelDescription }) => ({
              label: <Text style={styles.labelText}>{Label}</Text>,
              labelDescription: (
                <Text style={styles.labelText}>{LabelDescription}</Text>
              ),
              content: (
                <Box my={0.5}>
                  <Text bodyBold>{renderNoneIfZero(Amount)}</Text>
                </Box>
              ),
              description: <Text style={styles.labelText}>{Description}</Text>,
              width: '100%',
              testID: `${Label.replace(/ /g, '-').toLowerCase()}-column`,
            })
          )
        }
        testID={`${testID}-panel-drawer-content`}
      />
    </Box>
  );
};

export default DrawerContent;
