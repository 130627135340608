import React, { useState } from 'react';
import { GestureResponderEvent, View, ViewStyle } from 'react-native';
import {
  Text,
  Box,
  useStyles,
  useDeviceType,
  Pressable,
} from '@nutrien/bonsai-core';

interface ButtonProps {
  text: string;
  isDisabled?: boolean;
}

interface ButtonGroupProps {
  buttons: ButtonProps[];
  selectedIndex: number;
  onPress: (index: number) => void;
  buttonGroupContainerStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  pressedStyle?: ViewStyle;
  testID?: string;
  label?: string;
  fullWidthButton?: boolean;
}

interface ButtonGroupButtonProps {
  title: string;
  isFirst: boolean;
  isLast: boolean;
  isActive: boolean;
  isDisabled?: boolean;
  isDividerActive: boolean;
  handlePress: (event: GestureResponderEvent) => void;
  width: string;
  testID: string;
}

const ButtonGroupButton = ({
  title,
  isFirst,
  isLast,
  isActive,
  isDisabled,
  isDividerActive,
  handlePress,
  width,
  testID,
}: ButtonGroupButtonProps) => {
  const [isPressing, setIsPressing] = useState(false);

  const styles = useStyles((theme) => ({
    container: {
      width,
    },
    button: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: theme.spacing(1.4),
      borderColor: theme.auColors.neutral[500],
    },
    buttonFirst: {
      borderLeftWidth: 1,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderRightWidth: isDisabled ? 1.5 : 0,
      borderRightColor: isDisabled && theme.auColors.neutral[100],
    },
    buttonLast: {
      borderRightWidth: 1,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    buttonActive: {
      borderColor: theme.auColors.primary.default,
      backgroundColor: theme.auColors.primary.default,
      borderTopRightRadius: isLast ? theme.shape.borderRadius : 0,
      borderBottomRightRadius: isLast ? theme.shape.borderRadius : 0,
      borderTopLeftRadius: isFirst ? theme.shape.borderRadius : 0,
      borderBottomLeftRadius: isFirst ? theme.shape.borderRadius : 0,
    },

    divider: {
      borderColor: theme.auColors.neutral[500],
      borderRightWidth: 0,
    },
    dividerActive: {
      borderRightWidth: 1,
      borderColor: theme.auColors.primary.default,
    },
    text: {
      color:
        isPressing || isActive || isDisabled
          ? theme.auColors.neutral[100]
          : theme.auColors.neutral[600],
      fontWeight: 'bold',
    },

    pressedStyle: {
      backgroundColor: theme.auColors.primary[400],
      shadowColor: theme.auColors.primary.default,
      shadowOffset: { width: 0, height: 0 },
      borderColor: theme.auColors.primary[400],
    },
    disabledStyle: {
      backgroundColor: theme.auColors.functional.disabled.light,
      borderColor: theme.auColors.functional.disabled.light,
      borderTopRightRadius: isLast ? theme.shape.borderRadius : 0,
      borderBottomRightRadius: isLast ? theme.shape.borderRadius : 0,
    },
    midButton: {
      borderWidth: isActive ? 0 : 1,
      borderRightColor: isDisabled
        ? theme.auColors.neutral[100]
        : theme.auColors.neutral[500],
    },
    focusStyle: {
      shadowOpacity: 10,
      shadowRadius: 10,
      shadowColor: isActive
        ? theme.auColors.primary[400]
        : theme.auColors.functional.focused.dark,
      shadowOffset: { width: 0, height: 0 },
      borderColor: isActive
        ? theme.auColors.primary[400]
        : theme.auColors.neutral[500],
    },
  }));

  return (
    <View style={styles.container} key={title}>
      {!isFirst && (
        <View
          style={[styles.divider, isDividerActive && styles.dividerActive]}
        />
      )}
      <Pressable
        disabled={isDisabled}
        testID={testID}
        onPress={handlePress}
        onPressIn={() => setIsPressing(!isPressing)}
        onPressOut={() => setIsPressing(!isPressing)}
        containerStyle={[
          isActive && styles.buttonActive,
          isDisabled && styles.disabledStyle,
        ]}
        pressedStyle={styles.pressedStyle}
        focusedStyle={[styles.focusStyle]}
        disabledStyle={styles.disabledStyle}
        accessibilityState={{ selected: isActive }}
        accessibilityRole="button"
        style={[
          styles.button,
          isFirst && styles.buttonFirst,
          isLast && styles.buttonLast,
          !isFirst && !isLast && styles.midButton,
        ]}
      >
        <Text bodyBold={isActive} style={styles.text}>
          {title}
        </Text>
      </Pressable>
    </View>
  );
};

const ButtonGroup = ({
  buttons,
  onPress,
  selectedIndex,
  containerStyle = {},
  buttonGroupContainerStyle = {},
  testID = 'button-group',
  label,
  fullWidthButton,
}: ButtonGroupProps): JSX.Element => {
  const { isDesktop } = useDeviceType();

  const styles = useStyles((theme) => ({
    container: {
      flexGrow: 1,
      flexDirection: 'column',
      paddingTop: theme.spacing(4),
    },
    buttonGroupLabel: { marginBottom: theme.spacing(0.5) },
  }));
  return (
    <View style={[styles.container, buttonGroupContainerStyle]}>
      {isDesktop && label && (
        <Text style={styles.buttonGroupLabel} bodyBold>
          {label}
        </Text>
      )}
      <Box
        testID={testID}
        flexDirection="row"
        flexGrow={1}
        style={containerStyle}
      >
        {buttons.map(({ text, isDisabled }, i) => {
          const handlePress = () => onPress(i);
          const isFirst = i === 0;
          const isLast = i === buttons.length - 1;
          const isActive = selectedIndex === i;
          const isDividerActive = isActive || selectedIndex === i - 1;
          const width = fullWidthButton ? '100%' : `${100 / buttons.length}%`;

          return (
            <ButtonGroupButton
              testID={`${text.replace(' ', '-').toLowerCase()}-button`}
              width={width}
              key={`${text}_${i}`}
              title={text}
              isFirst={isFirst}
              isLast={isLast}
              isActive={isActive}
              isDividerActive={isDividerActive}
              handlePress={handlePress}
              isDisabled={isDisabled}
            />
          );
        })}
      </Box>
    </View>
  );
};

export default ButtonGroup;
