import React, { ComponentType } from 'react';
import { ActivityIndicator, Card, Box } from '@nutrien/bonsai-core';
import { ApolloError } from '@apollo/client';
import EmptyState from '../EmptyState';

interface WrapperProps {
  children: JSX.Element | JSX.Element[];
}
interface QueryCardProps extends WrapperProps {
  loading?: boolean;
  error?: ApolloError;
  handleReload?: () => void;
  wrapper?: ComponentType;
}

const Wrapper = ({ children }: WrapperProps) => {
  const cardContainerStyle = { flexGrow: 1 };
  const style = { minHeight: 115 };

  return (
    <Card containerStyle={cardContainerStyle} style={style}>
      {children}
    </Card>
  );
};

const QueryCard = ({
  loading,
  error,
  children,
  handleReload,
  wrapper,
}: QueryCardProps): JSX.Element => {
  const WrapperComponent = wrapper || Wrapper;

  if (loading) {
    return (
      <WrapperComponent>
        <Box justifyContent="center" alignItems="center" flexGrow={1}>
          <ActivityIndicator testID="query-loading" />
        </Box>
      </WrapperComponent>
    );
  }

  if (error) {
    return (
      <WrapperComponent>
        <Box
          testID="query-error"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          py={2}
        >
          <EmptyState
            type={'error'}
            alert={'Error!'}
            onPress={handleReload}
            message="Something went wrong!"
            action="Try Again"
          />
        </Box>
      </WrapperComponent>
    );
  }

  return <WrapperComponent>{children}</WrapperComponent>;
};

export default QueryCard;
