import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Box,
  useDeviceType,
  Checkbox,
  Table,
  Pressable,
} from '@nutrien/bonsai-core';
import QueryCard from '../QueryCard';
import {
  ColumnsInterface,
  IconTablePropsInterface,
  TableWithPrefixComponent,
} from '.';
import { useStaff } from '../../context/StaffContext';

const validateAcessorData = (
  tableColumns: ColumnsInterface[],
  columnsData: { [k: string]: unknown }[]
): void => {
  if (tableColumns.length < 1) throw new Error('Columns not found');

  if (columnsData.length < 1) return;

  const acessors: string[] = tableColumns.map((column) => column.accessor);

  columnsData.map((data) => {
    const everyKey = Object.keys(data).every((key) => acessors.includes(key));
    if (!everyKey) {
      throw new Error(
        `There are missing keys in your data ${JSON.stringify(
          data
        )} acessors ${acessors}`
      );
    }
    return true;
  });
};

const IconTable = ({
  tableColumns,
  columnsData = [],
  icon,
  cellStyle,
  headerStyle = {},
  subRowStyle = {},
  rowStyle = {},
  style = {},
  initialSort,
  sortable,
  checkable,
  testID,
  onPressIcon,
  loading,
  initialState,
  handleRowSelect,
}: IconTablePropsInterface): JSX.Element => {
  // The type of items in selectedRows may change later
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedAllRows, setSelectedAllRows] = useState(false);

  const { isHandset } = useDeviceType();
  validateAcessorData(tableColumns, columnsData);
  const { dispatch } = useStaff();

  useEffect(() => {
    if (selectedRows.length === columnsData.length) {
      setSelectedAllRows(true);
    } else {
      setSelectedAllRows(false);
    }
    handleRowSelect && handleRowSelect(selectedRows);
  }, [selectedRows, columnsData, handleRowSelect]);

  useEffect(() => {
    if (selectedRows.length !== 0) {
      dispatch({ type: 'setChangesInForm', value: true });
    } else {
      dispatch({ type: 'setChangesInForm', value: false });
    }
  }, [selectedRows, dispatch]);

  const tableWithCheckbox: TableWithPrefixComponent = {
    columns: [
      {
        Header: (
          <Pressable
            onPress={() => {
              setSelectedAllRows((prevState) => {
                if (prevState) {
                  setSelectedRows([]);
                } else {
                  setSelectedRows(
                    columnsData.map((item) => item.accountId) as string[]
                  );
                }
                return !prevState;
              });
            }}
          >
            <Checkbox
              checked={selectedAllRows}
              hasTVPreferredFocus={false}
              tvParallaxProperties={false}
              indeterminate={!selectedAllRows && selectedRows.length > 0}
              testID="select-all-checkbox"
            />
          </Pressable>
        ) as unknown as Element,
        accessor: 'checkbox',
        justifyContent: 'center',
        maxWidth: 70,
        minWidth: 70,
      },
      ...tableColumns,
    ],
    data: columnsData.map((item) => {
      const accountId = item.accountId;
      const isAccountSelected = selectedRows.includes(accountId as string);
      return {
        ...item,
        checkbox: (
          <Checkbox
            checked={isAccountSelected}
            hasTVPreferredFocus={false}
            tvParallaxProperties={false}
            onPress={() => {
              if (isAccountSelected) {
                setSelectedRows((prevState) =>
                  prevState.filter((account) => account !== accountId)
                );
              } else {
                setSelectedRows(
                  (prevState) => [...prevState, accountId] as string[]
                );
              }
            }}
            testID={`checkbox-${item.accountId}`}
          />
        ),
      };
    }),
  };

  const tableWithIcon: TableWithPrefixComponent = {
    columns: [
      ...[
        {
          Header: '',
          accessor: 'icon',
          justifyContent: 'center',
          maxWidth: isHandset ? 70 : 50,
          minWidth: 30,
        },
      ],
      ...tableColumns,
    ],
    data: columnsData.map((item, index) => {
      return {
        ...item,
        icon: (
          <IconButton
            key={`icon_${index}`}
            density={-5}
            icon={icon ?? 'done'}
            type="default"
            testID={`${icon}-icon-${item.accountId}`}
            onPress={() => onPressIcon && onPressIcon(item)}
          />
        ),
      };
    }),
  };

  const data = checkable ? tableWithCheckbox.data : tableWithIcon.data;
  const columns = checkable ? tableWithCheckbox.columns : tableWithIcon.columns;

  return (
    <QueryCard loading={loading} wrapper={Box}>
      <Table
        cellStyle={cellStyle}
        columns={columns as []}
        data={data}
        containerStyle={{}}
        currentPageIndex={0}
        headerStyle={headerStyle}
        initialSort={initialSort}
        paginationSelectLabel=""
        paginationTemplate=""
        rowStyle={rowStyle}
        style={style}
        sortable={sortable}
        subRowCellStyle={{}}
        subRowStyle={subRowStyle}
        testID={testID}
        initialState={initialState}
      />
    </QueryCard>
  );
};

export default IconTable;
