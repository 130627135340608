import React from 'react';
import {
  ActivityIndicator,
  Box,
  Icon,
  Text,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import Banner from '../Banner';

export const LivestockLoadErrorBanner = ({
  visible,
  onReload,
  reloading,
}: {
  visible: boolean;
  onReload?: () => void;
  reloading?: boolean;
}) => {
  const theme = useTheme();
  const styles = useStyles(() => ({
    errorText: {
      color: theme.auColors.functional.error.dark,
    },
    banner: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    bannerActionItemIcon: {
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(0.1),
    },
  }));

  return (
    <Banner
      location={'inline'}
      type={'error'}
      style={styles.banner}
      visible={visible}
      testID={'lot-details-error'}
      actionItems={
        onReload && [
          {
            text: reloading
              ? 'Loading livestock details'
              : 'Reload failed livestock details',
            icon: reloading ? (
              <ActivityIndicator
                color={theme.auColors.functional.error.dark}
                style={styles.bannerActionItemIcon}
              />
            ) : (
              <Icon
                name={'refresh'}
                color={theme.auColors.functional.error.dark}
                style={styles.bannerActionItemIcon}
                size={24}
              />
            ),
            onPress: onReload,
            disabled: reloading,
          },
        ]
      }
    >
      <Box flexDir="column">
        <Text style={styles.errorText} bodyBold>
          {"Some livestock invoice details didn't load"}
        </Text>
      </Box>
    </Banner>
  );
};
