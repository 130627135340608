import React, { FC } from 'react';
import {
  Card,
  Box,
  Text,
  useStyles,
  useDeviceType,
} from '@nutrien/bonsai-core';
import { Button } from '../../components/Button';
import { shortDate } from '../../utils/recommendationUtils';

type Farm = {
  farmTitle: string;
  agronomist: string | null;
  company: string | null;
  dueDate?: string | null;
  recommendationTitle: string;
};

type FarmInfoField = {
  title: string;
  value: string | null;
};

type props = {
  recommendationId: string;
  updateIndex: (index: number) => void;
  farm: Farm;
};

const FarmCard: FC<props> = ({
  recommendationId,
  updateIndex,
  farm: { farmTitle, agronomist, company, dueDate, recommendationTitle },
}) => {
  const styles = useStyles((theme) => ({
    farmCard: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    farmCardTitle: {
      fontWeight: '700',
    },
    farmCardSubTitle: {
      fontWeight: '600',
    },
    ctaButton: {
      marginTop: theme.spacing(2),
    },
  }));

  const recommendationDueDate = dueDate ? shortDate(dueDate, true) : '';
  const { isHandset } = useDeviceType();

  const farmInfoFields: FarmInfoField[] = [
    {
      title: 'Created by',
      value: agronomist,
    },
    {
      title: 'Grower',
      value: company,
    },
    {
      title: 'Due',
      value: recommendationDueDate || '',
    },
  ];

  return (
    <Card style={styles.farmCard} testID="farm-card">
      <Box mb={2} flexDirection="row" justifyContent="space-between">
        <Text
          bodyBold
          style={styles.farmCardTitle}
          ellipsizeMode="tail"
          numberOfLines={1}
          testID="farm-title"
        >{`Farm ${farmTitle}`}</Text>
        <Text
          bodyBold
          style={styles.farmCardSubTitle}
          ellipsizeMode="tail"
          numberOfLines={1}
          testID="recommendation-name"
        >
          {recommendationTitle}
        </Text>
      </Box>
      <>
        {farmInfoFields.map((farmInfoField: FarmInfoField, index) => (
          <Box
            key={index}
            flexDirection="row"
            testID={`farm-card-${farmInfoField.title.replace(' ', '-')}`}
          >
            <Box width="100px">
              <Text ellipsizeMode="tail" numberOfLines={1}>
                {farmInfoField.title}
              </Text>
            </Box>
            <Box flex={1} ml={1}>
              <Text ellipsizeMode="tail" numberOfLines={1}>
                {farmInfoField.value}
              </Text>
            </Box>
          </Box>
        ))}
      </>
      <Box width={isHandset ? '100%' : 240}>
        <Button
          type="solid"
          accessibilityLabel={`View recommendation ${recommendationId} Product List`}
          title="View Product List"
          buttonStyle={styles.ctaButton}
          testID="recommendation-product-list-button"
          onPress={() => updateIndex(1)}
        />
      </Box>
    </Card>
  );
};

export default FarmCard;
