import React from 'react';
import { ImageBackground } from 'react-native';
import { useDeviceType } from '@nutrien/bonsai-core';

const SubHeaderImageBackground = ({
  testID,
}: {
  testID: string;
}): JSX.Element | null => {
  const { isHandset } = useDeviceType();
  const styles = {
    imageStyle: { height: isHandset ? 65 : 93 },
  };

  return (
    <ImageBackground
      resizeMode="cover"
      style={styles.imageStyle}
      source={require('../../../assets/subHeaderBg.jpg')}
      height={93}
      width={1200}
      testID={testID}
    />
  );
};

export default SubHeaderImageBackground;
