/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { SearchBranch_sords_branch } from 'types/generated/SearchBranch';
import { useStaff } from '../../context/StaffContext';
import { useSearchBranchQuery } from '../../hooks';
import { NO_RESULTS_FOUND } from '../../constants';
import { UseUserDetailsFormProps, SetFieldValue } from '.';
import type { ContactDetailsPanelType } from '../ContactDetailsPanel';

const useUserDetailsForm = (
  type: ContactDetailsPanelType,
  setFieldValue: SetFieldValue
): UseUserDetailsFormProps => {
  const {
    state: { selectedCustomer },
  } = useStaff();
  const [searchTerm, setSearchTerm] = useState(
    type === 'edit' ? selectedCustomer?.onboardedBranch || '' : ''
  );
  const [hideSearchResults, setHideSearchResults] = useState(true);
  const [searchResults, setSearchResults] = useState<
    SearchBranch_sords_branch[]
  >([]);

  const {
    getBranchByName,
    loading: searchBranchLoading,
    data: searchBranchData,
    error: searchBranchError,
  } = useSearchBranchQuery();

  const handleSearchChange = (val: string) => {
    setSearchTerm(val);
    setHideSearchResults(false);
  };

  const debouncedGetBranchByName = useCallback(
    debounce((props) => {
      getBranchByName(props);
    }, 300),
    []
  );

  useEffect(() => {
    if (searchTerm && !hideSearchResults) {
      debouncedGetBranchByName({
        variables: {
          searchTerm: `%${searchTerm}%`,
        },
      });
    }
    const isValidBranchName = searchResults.find(
      ({ branch_name }) => branch_name === searchTerm
    );
    if (!searchTerm || (!isValidBranchName && !hideSearchResults)) {
      setFieldValue('onboardedBranch', '');
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      if (searchBranchData?.sords_branch.length === 0) {
        setSearchResults([
          { branch_name: NO_RESULTS_FOUND } as SearchBranch_sords_branch,
        ]);
      } else {
        setSearchResults(searchBranchData?.sords_branch || []);
      }
    }
  }, [searchBranchData]);

  const handleSearchResultClick = (val: SearchBranch_sords_branch) => {
    if (val?.branch_name !== NO_RESULTS_FOUND) {
      setHideSearchResults(true);
      setSearchTerm(val?.branch_name || '');
      setFieldValue('onboardedBranch', val.branch_code || '');
    }
  };

  const sortedSearchResults = [...searchResults].sort(
    (a: SearchBranch_sords_branch, b: SearchBranch_sords_branch) =>
      (a.branch_name || '').localeCompare(b.branch_name || '')
  );

  return {
    searchTerm,
    hideSearchResults,
    setHideSearchResults,
    handleSearchChange,
    handleSearchResultClick,
    sortedSearchResults,
    searchBranchLoading,
    searchBranchError,
  };
};

export default useUserDetailsForm;
