import React from 'react';
import { Text, Box, useStyles, useDeviceType } from '@nutrien/bonsai-core';
import Link from '../Link';

interface SummaryRowProps {
  width?: string;
  label?: React.ReactElement | string;
  labelDescription?: React.ReactElement | string;
  content?: React.ReactElement | string;
  description?: React.ReactElement | string;
  button?: React.ReactElement;
  testID?: string;
}

export interface SummaryCardProps {
  heading?: string;
  button?: {
    label: string;
    testID?: string;
    onPress: () => void;
  };
  summaryRow?: SummaryRowProps[];
  vertical?: boolean;
  footer?: React.ReactElement;
  testID?: string;
}

const SummaryCard = ({
  heading,
  button,
  summaryRow,
  vertical = false,
  footer,
  testID,
}: SummaryCardProps): JSX.Element => {
  const { isDesktop } = useDeviceType();
  const renderHorizontally = isDesktop && !vertical;
  const styles = useStyles((theme) => ({
    container: {
      backgroundColor: theme.auColors.neutral[200],
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
    row: {
      backgroundColor: theme.auColors.neutral[100],
    },
    column: {
      borderRightColor: theme.auColors.neutral[400],
      borderRightWidth: 1,
    },
    lastColumn: {
      borderRightWidth: 0,
    },
    stackedColumn: {
      borderBottomColor: theme.auColors.neutral[400],
      borderBottomWidth: 1,
    },
    stackedLastColumn: {
      borderBottomWidth: 0,
    },
    columnContent: {
      color: theme.auColors.neutral[800],
      fontWeight: '600',
    },
    headingSmall:
      theme.auTypography[renderHorizontally ? 'desktop' : 'mobile']
        .headingSmall,
  }));

  return (
    <Box
      style={styles.container}
      testID={testID ? `summary-card-${testID}` : 'summary-card'}
    >
      {(heading || button) && (
        <Box flexDir="row" justifyContent="space-between" mb={2}>
          {heading && (
            <Text h3 style={styles.headingSmall}>
              {heading}
            </Text>
          )}
          {button && (
            <Link
              text={button.label}
              iconRight={{}}
              testID={button.testID}
              onPress={button.onPress}
              isEmphasis={true}
            />
          )}
        </Box>
      )}
      {summaryRow && summaryRow.length > 0 && (
        <Box
          style={styles.row}
          flexDir={renderHorizontally ? 'row' : 'column'}
          justifyContent="space-between"
          paddingY={renderHorizontally ? 2 : 0}
          paddingX={renderHorizontally ? 0 : 2}
          testID="summary-row"
        >
          {summaryRow.map((column, index) => {
            const isLastColumn = index + 1 === summaryRow.length;
            return (
              <Box
                width={
                  renderHorizontally
                    ? column.width
                      ? column.width
                      : `${100 / summaryRow.length}%`
                    : '100%'
                }
                paddingX={renderHorizontally ? 2 : 0}
                paddingY={renderHorizontally ? 0 : 2}
                key={index}
                style={
                  renderHorizontally
                    ? [styles.column, isLastColumn && styles.lastColumn]
                    : [
                        styles.stackedColumn,
                        isLastColumn && styles.stackedLastColumn,
                      ]
                }
                testID={column.testID || `summary-column-${index + 1}`}
              >
                {column?.label && <Text>{column.label}</Text>}
                {column?.labelDescription && (
                  <Text>{column.labelDescription}</Text>
                )}
                {column?.content && (
                  <Text bodyBold style={styles.columnContent}>
                    {column.content}
                  </Text>
                )}
                {column?.description && <Text>{column.description}</Text>}
                {column?.button && <Box mt={2}>{column.button}</Box>}
              </Box>
            );
          })}
        </Box>
      )}
      {footer && <Box mt={1}>{footer}</Box>}
    </Box>
  );
};

export default SummaryCard;
