import { DrawerData } from './DrawerContent';

// TODO: replace it when SAP is ready
export const overdueDummyData = [
  {
    Label: 'Invoice 90609482',
    Amount: 125,
    Description: 'Due 24 Jun 2022',
  },
  {
    Label: 'Invoice 90609423',
    Amount: 309.8,
    Description: 'Due 21 Jun 2022',
  },
  {
    Label: 'Invoice 90609483',
    Amount: 127,
    Description: 'Due 23 Jun 2022',
  },
  {
    Label: 'Invoice 90609473',
    Amount: 206.8,
    Description: 'Due 22 Jun 2022',
  },
];

export const creditsDummyData = [
  {
    Label: 'Credit',
    Amount: -505.2,
  },
  {
    Label: 'Reversal',
    Amount: -345.2,
  },
  {
    Label: 'Payment difference',
    Amount: -16.91,
  },
];

export const expensesDummyData = [
  {
    Label: 'Purchase',
    Amount: 2945.6,
  },
  {
    Label: 'Fee and charges',
    Amount: 50,
  },
  {
    Label: 'Other',
    Amount: 40,
  },
];

export const nextDueData = (
  lastDay?: string,
  creditAmount?: number | null,
  debitAmount?: number | null,
  earlyPayment?: number | null
) =>
  [
    {
      Label: `Credits from 01 to ${lastDay}`,
      Amount: creditAmount,
    },
    {
      Label: `Expenses from 01 to ${lastDay}`,
      Amount: debitAmount,
    },
    {
      Label: 'Early payments',
      Amount: earlyPayment,
    },
  ] as DrawerData[];

export const extendedTermsDummyData = [
  {
    Label: 'Invoice 906094803',
    Amount: 4230.2,
    Description: 'Due 25 Sep 2022',
  },
  {
    Label: 'Invoice 906146265',
    Amount: 1470.25,
    Description: 'Due 24 Oct 2022',
  },
];

export const overdueInvoicesDummyData = [
  {
    Label: 'Invoice 90609482',
    LabelDescription: 'CTLX PRIME CATTLE SALE 9.06.2022',
    Amount: 125,
    Description: 'Due 24 Jun 2022',
  },
  {
    Label: 'Invoice 90609423',
    LabelDescription: 'CTLX PRIME CATTLE SALE 9.06.2022',
    Amount: 309.8,
    Description: 'Due 21 Jun 2022',
  },
  {
    Label: 'Invoice 90609483',
    LabelDescription: 'CTLX PRIME CATTLE SALE 9.06.2022',
    Amount: 127,
    Description: 'Due 23 Jun 2022',
  },
  {
    Label: 'Invoice 90609473',
    LabelDescription: 'CTLX PRIME CATTLE SALE 9.06.2022',
    Amount: 206.8,
    Description: 'Due 22 Jun 2022',
  },
];

export const nextDueInvoicesDummyData = [
  {
    Label: 'Invoice 906094803',
    LabelDescription: 'CTLX PRIME CATTLE SALE 9.06.2022',
    Amount: 125,
    Description: 'Due 19 Jun 2022',
  },
  {
    Label: 'Invoice 906146265',
    LabelDescription: 'CTLX SHEEP CATTLE SALE 9.06.2022',
    Amount: 309.8,
    Description: 'Due 19 Jun 2022',
  },
];

export const pendingProceedsDummyData = [
  {
    Label: 'Invoice 906094803',
    LabelDescription: 'CTLX PRIME CATTLE SALE 18.09.2022',
    Amount: 10000,
    Description: 'Scheduled for 24 Oct 2022',
  },
  {
    Label: 'Invoice 906146265',
    LabelDescription: 'CTLX PRIME CATTLE SALE 17.10.2022',
    Amount: 12642.53,
    Description: 'Scheduled for 24 Oct 2022',
  },
];

export const nonInterestBearingBalanceDummyData = [
  {
    Label: 'Expenses',
    Amount: 10000,
  },
  {
    Label: 'Credits',
    Amount: 12642.53,
  },
  {
    Label: 'Invoices',
    Amount: 10200.05,
  },
];

export const interestBearingBalanceDummyData = [
  {
    Label: 'Expenses',
    Amount: 5450,
  },
  {
    Label: 'Invoices',
    Amount: 12050,
  },
];

export const summarySplitUpMockData = {
  AccountNumber: '0070386365',
  Items: [
    {
      AccountSplit: 'Merchandise',
      Field: 'Noninterestbearingexpense',
      SubField: 'Credit',
      Summary: [
        {
          Amount: 0,
          Key: 'Totalrecentpurchases',
          Label: 'Totalrecentpurchases',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalexttermpurchases',
          Label: 'Totalexttermpurchases',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalinvoices',
          Label: 'Totalinvoices',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalpayments',
          Label: 'Totalpayments',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalfees',
          Label: 'Totalfees',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalrewards',
          Label: 'Totalrewards',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: -1265,
          Key: 'Totalreversals',
          Label: 'Totalreversals',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totaladjustments',
          Label: 'Totaladjustments',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalfinancetransfers',
          Label: 'Totalfinancetransfers',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalpaymentdifferences',
          Label: 'Totalpaymentdifferences',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalinterest',
          Label: 'Totalinterest',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalmarshinsurances',
          Label: 'Totalmarshinsurances',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: -27242.6,
          Key: 'Totalothers',
          Label: 'Totalothers',
          __typename: 'SplitUpSummary',
        },
      ],
      Documents: [
        {
          Amount: -121.3,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'OTHERS',
          DocumentNumber: '1800001989',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: -121.3,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'OTHERS',
          DocumentNumber: '1800002019',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: -7000,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'OTHERS',
          DocumentNumber: '0400000012',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: -20000,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'OTHERS',
          DocumentNumber: '0400000013',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: -1045,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900001567',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: -220,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900001568',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
      ],
      __typename: 'SplitUpResponseItem',
    },
    {
      AccountSplit: 'Merchandise',
      Field: 'Noninterestbearingexpense',
      SubField: 'Debit',
      Summary: [
        {
          Amount: 0,
          Key: 'Totalrecentpurchases',
          Label: 'Totalrecentpurchases',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalexttermpurchases',
          Label: 'Totalexttermpurchases',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalinvoices',
          Label: 'Totalinvoices',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalpayments',
          Label: 'Totalpayments',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalfees',
          Label: 'Totalfees',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalrewards',
          Label: 'Totalrewards',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 11730.73,
          Key: 'Totalreversals',
          Label: 'Totalreversals',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totaladjustments',
          Label: 'Totaladjustments',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalfinancetransfers',
          Label: 'Totalfinancetransfers',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalpaymentdifferences',
          Label: 'Totalpaymentdifferences',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalinterest',
          Label: 'Totalinterest',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalmarshinsurances',
          Label: 'Totalmarshinsurances',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalothers',
          Label: 'Totalothers',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalfuturedues',
          Label: 'Extended Terms',
          __typename: 'SplitUpSummary',
        },
      ],
      Documents: [
        {
          Amount: 187.11,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'FutureDues',
          DocumentNumber: '0090000046',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 187.11,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0090000048',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 0.11,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000034',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 723.8,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000035',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 22,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000036',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1082.4,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000109',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1152.8,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000110',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1082.4,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000163',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1040.6,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000703',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 5203,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000706',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1045,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900001382',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 2.2,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900002085',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1.1,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900002086',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1.1,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900002087',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
      ],
      __typename: 'SplitUpResponseItem',
    },
    {
      AccountSplit: 'Merchandise',
      Field: 'Interestbearingexpense',
      SubField: 'Credit',
      Summary: [],
      Documents: [],
      __typename: 'SplitUpResponseItem',
    },
    {
      AccountSplit: 'Merchandise',
      Field: 'Interestbearingexpense',
      SubField: 'Debit',
      Summary: [
        {
          Amount: 0,
          Key: 'Totalrecentpurchases',
          Label: 'Totalrecentpurchases',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalexttermpurchases',
          Label: 'Totalexttermpurchases',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalinvoices',
          Label: 'Totalinvoices',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalpayments',
          Label: 'Totalpayments',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalfees',
          Label: 'Totalfees',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalrewards',
          Label: 'Totalrewards',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalreversals',
          Label: 'Totalreversals',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totaladjustments',
          Label: 'Totaladjustments',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalfinancetransfers',
          Label: 'Totalfinancetransfers',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalpaymentdifferences',
          Label: 'Totalpaymentdifferences',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalinterest',
          Label: 'Totalinterest',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalmarshinsurances',
          Label: 'Totalmarshinsurances',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalothers',
          Label: 'Totalothers',
          __typename: 'SplitUpSummary',
        },
      ],
      Documents: [
        {
          Amount: 187.11,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'PURCHASES',
          DocumentNumber: '0090000046',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 159.5,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'INTEREST',
          DocumentNumber: '0090000048',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
      ],
      __typename: 'SplitUpResponseItem',
    },
    {
      AccountSplit: 'Livestock',
      Field: 'Noninterestbearingexpense',
      SubField: 'Proceeds',
      Summary: [
        {
          Amount: 0,
          Key: 'Totalinvoices',
          Label: 'Totalinvoices',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalreversals',
          Label: 'Totalreversals',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalremittances',
          Label: 'Totalremittances',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalothers',
          Label: 'Totalothers',
          __typename: 'SplitUpSummary',
        },
      ],
      Documents: [
        {
          Amount: 121.3,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'OTHERS',
          DocumentNumber: '1800001989',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
      ],
      __typename: 'SplitUpResponseItem',
    },
    {
      AccountSplit: 'Livestock',
      Field: 'Noninterestbearingexpense',
      SubField: 'Invoices',
      Summary: [
        {
          Amount: 0,
          Key: 'Totalinvoices',
          Label: 'Totalinvoices',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalreversals',
          Label: 'Totalreversals',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalremittances',
          Label: 'Totalremittances',
          __typename: 'SplitUpSummary',
        },
        {
          Amount: 0,
          Key: 'Totalothers',
          Label: 'Totalothers',
          __typename: 'SplitUpSummary',
        },
      ],
      Documents: [],
      __typename: 'SplitUpResponseItem',
    },
    {
      AccountSplit: 'Livestock',
      Field: 'Interestbearingexpense',
      SubField: 'Proceeds',
      Summary: [
        {
          Amount: 123,
          Key: 'Totalexttermpurchases',
          Label: 'Totalexttermpurchases',
          __typename: 'SplitUpSummary',
        },
      ],
      Documents: [],
      __typename: 'SplitUpResponseItem',
    },
    {
      AccountSplit: 'Livestock',
      Field: 'Interestbearingexpense',
      SubField: 'Invoices',
      Summary: [],
      Documents: [
        {
          Amount: 187.11,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'FutureDues',
          DocumentNumber: '0090000046',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 187.11,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0090000048',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 0.11,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000034',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 723.8,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000035',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 22,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000036',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1082.4,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000109',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1152.8,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000110',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1082.4,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000163',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1040.6,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000703',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 5203,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900000706',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1045,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900001382',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 2.2,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900002085',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1.1,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900002086',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
        {
          Amount: 1.1,
          ClearingDocNumber: '123',
          Currency: 'AUD',
          DocDate: '2021-05-27',
          DocDescription: 'REVERSALS',
          DocumentNumber: '0900002087',
          DueDate: '2021-05-27',
          __typename: 'SplitUpDocument',
        },
      ],
      __typename: 'SplitUpResponseItem',
    },
  ],
};
