import React, { FC } from 'react';
import { ViewStyle } from 'react-native';
import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { Button } from '../Button';

export interface ContentPageButton {
  text: string;
  onPress: () => void;
  type?: 'solid' | 'outline' | 'clear';
  buttonLoading?: boolean;
  testID?: string;
  buttonStyle?: ViewStyle;
}

type props = {
  title: string;
  icon?: React.ReactNode;
  contentPageButtons: ContentPageButton[];
  iconTestID?: string;
  footer?: React.ReactNode;
  maxWidth?: string;
};

const ContentPage: FC<props> = ({
  title,
  icon,
  children,
  contentPageButtons,
  iconTestID,
  footer,
  maxWidth,
}) => {
  const device = useDeviceType();
  const theme = useTheme();
  const hasButtons = contentPageButtons && contentPageButtons.length > 0;

  const styles = {
    headingLarge:
      theme.auTypography[device.isDesktop ? 'desktop' : 'mobile'].headingLarge,
    titleStyles: {
      textAlign: 'center',
    },
  };

  return (
    <Box
      height="100%"
      width="100%"
      padding={2}
      backgroundColor={theme.auColors.neutral[100]}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        maxWidth={maxWidth || '485'}
        justifyContent="center"
        alignItems="center"
      >
        <Box justifyContent="center" alignItems="center">
          <Box mt={2} mb={1}>
            <Text
              h1
              testID={'content-page-title'}
              accessibilityRole="header"
              accessibilityLabel={title}
              style={[styles.headingLarge, styles.titleStyles]}
            >
              {title}
            </Text>
          </Box>
          {icon && (
            <Box my={2} testID={iconTestID || 'icon-testID'}>
              {icon}
            </Box>
          )}
          <Box textAlign={'center'} mb={hasButtons ? 4 : 2}>
            {children}
          </Box>
          <Box
            flexDirection={device.isHandset ? 'column' : 'row'}
            width="100%"
            justifyContent={'center'}
            flexWrap={'wrap'}
            ml={device.isHandset ? 0 : 1.5}
          >
            {hasButtons &&
              contentPageButtons.map(
                (
                  { text, onPress, type, testID, buttonLoading, buttonStyle },
                  index
                ) => {
                  return (
                    <Box
                      marginBottom={2}
                      key={`${text}_${index}`}
                      alignItems="center"
                      marginRight={device.isHandset ? undefined : 1.5}
                    >
                      <Button
                        title={text}
                        type={type ?? 'outline'}
                        onPress={onPress}
                        testID={testID}
                        fullWidth={device.isHandset}
                        accessibilityRole="button"
                        accessibilityLabel={text}
                        loading={buttonLoading ?? false}
                        buttonStyle={buttonStyle}
                      />
                    </Box>
                  );
                }
              )}
          </Box>
          {footer && (
            <Box textAlign="center" testID="content-page-footer">
              {footer}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContentPage;
