import React from 'react';
import { NamedStyles } from '@nutrien/bonsai-core';
import { CustomerAccountsSummary_getAccountsSummary } from '../../../types/generated/CustomerAccountsSummary';
import WhitePanelWrapper from '../WhitePanelWrapper';
import PrepayPanel from './PrepayPanel';

interface FinanceOneSectionProps {
  accountSummary: CustomerAccountsSummary_getAccountsSummary | null;
  panelStyles: NamedStyles<Record<string, undefined>>;
}

const Prepay = ({
  accountSummary,
  panelStyles,
}: FinanceOneSectionProps): JSX.Element => {
  return (
    <WhitePanelWrapper>
      <PrepayPanel summary={accountSummary} panelStyles={panelStyles} />
    </WhitePanelWrapper>
  );
};

export default Prepay;
