import React, { FC, useEffect } from 'react';
import { Linking, View, FlatList, TextStyle, ViewStyle } from 'react-native';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Text, Box, useTheme } from '@nutrien/bonsai-core';
import { TextAlign } from './';
import Link from '../../components/Link';
import { Button } from '../../components/Button';
import { ValidateABNPerson_validateABNPerson_account_numbers } from '../../types/generated/ValidateABNPerson';
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  CUSTOMER_SELF_REGISTRATION_STEPS,
} from '../../constants';
import { useUser } from '../../context/UserContext';
import useCustomerSelfRegistration from './useSelfRegistration';
import useAccountOverview from '../AccountOverview/useAccountOverview';

type props = {
  onNavigateToStep: (setIndex: number) => void;
  onCancel: (showConfirmation: boolean) => void;
  onErrors: (setError: boolean) => void;
  onShowEmailNotification: (setEmail: boolean) => void;
};

const ConfirmCustomerDetailsForm: FC<props> = ({
  onCancel,
  onNavigateToStep,
  onErrors,
  onShowEmailNotification,
}) => {
  const theme = useTheme();
  const flags = useFlags();
  const {
    state: { givenName, accountNumbers, email, familyName, mobileNumber, abn },
  } = useUser();
  const { getTileType } = useAccountOverview({ account_id: '' });
  const {
    verifyCustomerDetails,
    verifyCustomerDetailsData,
    verifyCustomerDetailsError,
  } = useCustomerSelfRegistration();
  const styles = {
    titleText: {
      color: theme.auColors.neutral[800],
      fontSize: theme.typography.fontSize.large,
      lineHeight: theme.typography.fontSize.large * 1.5,
      textAlign: 'center' as TextAlign,
      marginBottom: theme.spacing(3),
    },
    cancelButton: {
      marginTop: theme.spacing(2),
    },
    accountList: {
      borderBottom: 1,
      borderStyle: 'solid',
      marginBottom: theme.spacing(1),
      borderBottomColor: theme.auColors.neutral[400],
    } as ViewStyle,
    accountHeader: {
      fontSize: theme.typography.fontSize.medium,
      fontWeight: '400',
    } as TextStyle,
  };
  const dialNumber = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Linking.openURL(`tel:${SUPPORT_PHONE}`);
  };
  const openEmail = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Linking.openURL(`mailto:${SUPPORT_EMAIL}`);
  };
  const onConfirmation = async () => {
    await verifyCustomerDetails(
      email,
      givenName,
      familyName,
      mobileNumber,
      abn
    );
  };
  useEffect(() => {
    if (verifyCustomerDetailsData?.createCustomerSelfRegisterLogin?.email) {
      onNavigateToStep(
        CUSTOMER_SELF_REGISTRATION_STEPS.DISPLAY_EMAIL_NOTIFICATION_STEP
      );
      onShowEmailNotification(true);
    }
  }, [verifyCustomerDetailsData, onNavigateToStep, onShowEmailNotification]);

  useEffect(() => {
    if (verifyCustomerDetailsError) {
      onErrors(true);
    }
  }, [verifyCustomerDetailsError, onErrors]);

  const displayAccountType = (
    item: ValidateABNPerson_validateABNPerson_account_numbers | null
  ) => {
    return ['FinanceOneSection', 'FinanceTwoSections'].includes(
      getTileType(item?.name1, item?.account_type)
    )
      ? 'Finance'
      : getTileType(item?.name1, item?.account_type);
  };

  const renderItems = ({
    item,
    index,
  }: {
    item: ValidateABNPerson_validateABNPerson_account_numbers | null;
    index: number;
  }) => {
    return (
      <Box style={styles.accountList} pt={index > 0 ? 2 : 0} pb={2}>
        <Box
          flexGrow={1}
          testID={`account-numbers-${index}`}
          flexDirection="row"
        >
          <Box>
            <Text subHeaderBold style={styles.accountHeader}>
              Account number: #{item?.account_id}
            </Text>
          </Box>
        </Box>
        <Box
          flexGrow={1}
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
        >
          <Box>
            <Text>Account type: </Text>
          </Box>
          <Box>
            <Text testID={`customer-account-type-${index}`}>
              {displayAccountType(item)}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const upperCaseGivenName =
    givenName[0].toUpperCase() + givenName.substring(1);

  return (
    <>
      <Box mb={1} testID="details-confirm-form-lookup">
        <Text h1 bodyBold style={styles.titleText} testID="welcome-header">
          Hi {upperCaseGivenName} , you&apos;re almost ready to go
        </Text>
      </Box>
      <Box>
        <Text>
          We&apos;re about to create your profile, so that you can access the
          digital hub to view your Nutrien account online.
        </Text>
      </Box>
      <Box mt={3}>
        <Text>
          Please check and confirm that your account number is correct.
        </Text>
      </Box>
      {flags['NG-3932-multi-account-support'] ? (
        <Box my={3}>
          <View testID="customer-account-number">
            <FlatList
              accessible
              data={accountNumbers}
              renderItem={renderItems}
            />
          </View>
        </Box>
      ) : (
        <Box my={3}>
          <Text>Customer account</Text>
          <Text bodyBold testID="customer-account-number">
            {accountNumbers && accountNumbers[0]
              ? `#${accountNumbers[0].account_id}`
              : ''}
          </Text>
        </Box>
      )}

      <Button
        title="Yes, this is correct"
        testID="submit-button"
        accessibilityLabel="Submit Form Button"
        onPress={onConfirmation}
      />
      <Button
        title="Cancel"
        type="outline"
        testID="cancel-button"
        accessibilityLabel="Cancel Button"
        onPress={() => {
          onCancel(true);
        }}
        containerStyle={styles.cancelButton}
      />
      <Box mt={3}>
        <Text>
          If your account details aren’t correct, then contact Customer Support
        </Text>
      </Box>
      <Box mt={3} mb={2}>
        <Text bodyBold>Phone</Text>
      </Box>
      <Box>
        <Text bodyBold>
          <Link
            text={SUPPORT_PHONE}
            testID="support-mobile-number"
            onPress={dialNumber}
          />
        </Text>
        <Text>
          Monday to Friday, 9am to 5pm (AEST){'\n'}(except public holidays)
        </Text>
      </Box>
      <Box mt={3} mb={2}>
        <Text bodyBold>Email</Text>
      </Box>
      <Box>
        <Text bodyBold>
          <Link
            text={SUPPORT_EMAIL}
            testID="support-email"
            onPress={openEmail}
            type="twilight"
          />
        </Text>
        <Text>We aim to respond within 2 working days</Text>
      </Box>
    </>
  );
};

export default ConfirmCustomerDetailsForm;
