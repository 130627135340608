import React from 'react';
import {
  Text,
  useStyles,
  Icon,
  useDeviceType,
  Box,
  useTheme,
} from '@nutrien/bonsai-core';
import { TransactionGroup } from '../../hooks/useTransactions/transaction.helper';
import { useFormatters } from '../../hooks';
import { TransactionWrapper } from './TransactionWrapper';
import { DownloadDocumentFn } from '../Transaction/TabScene';

export interface GroupTransactionProps extends TransactionGroup {
  onPress: DownloadDocumentFn;
}

const HeaderRow = ({
  title,
  total,
  issueDate,
  dueDate,
  hasPdf,
}: GroupTransactionProps): JSX.Element => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const { formatCurrency } = useFormatters();

  const styles = useStyles(() => {
    return {
      headingRow: {
        backgroundColor: theme.auColors.neutral[300],
        flexDirection: isDesktop ? 'row' : 'column',
        justifyContent: isDesktop ? 'space-between' : 'center',
        paddingHorizontal: theme.spacing(2),
        paddingVertical: theme.spacing(1),
      },
      leftColumn: {
        flexDirection: 'row',
        justifyContent: isDesktop ? 'space-between' : 'flex-start',
      },
      pdfIcon: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0.5),
      },
      title: {
        color: theme.auColors.primary.default,
      },
      rightColumn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: isDesktop ? 0 : theme.spacing(1),
      },
      issueDate: {
        fontSize: theme.typography.fontSize.small,
        marginRight: theme.spacing(4),
      },
      dueDate: {
        fontSize: theme.typography.fontSize.small,
      },
      totalAmount: {
        textAlign: 'right',
        alignSelf: 'flex-end',
        width: 135,
      },
      dates: {
        flexDirection: 'row',
        justifyContent: isDesktop ? 'flex-start' : 'space-between',
        width: 240,
      },
    };
  });

  return (
    <Box style={styles.headingRow}>
      <Box style={styles.leftColumn}>
        {hasPdf && (
          <Icon
            style={styles.pdfIcon}
            name="picture-as-pdf"
            color={theme.auColors.primary.default}
            size={18}
            testID={'download-pdf'}
          />
        )}
        <Text style={styles.title} bodyBold>
          {title}
        </Text>
      </Box>
      <Box style={styles.rightColumn}>
        <Box style={styles.dates}>
          {isDesktop ? (
            <Text style={styles.issueDate}>Issued {issueDate}</Text>
          ) : (
            <Box>
              <Text>Issued </Text>
              <Text>{issueDate}</Text>
            </Box>
          )}
          {dueDate &&
            dueDate !== issueDate &&
            (isDesktop ? (
              <Text style={styles.dueDate}>Due {dueDate}</Text>
            ) : (
              <Box>
                <Text>Due </Text>
                <Text>{dueDate}</Text>
              </Box>
            ))}
        </Box>
        <Text style={styles.totalAmount} bodyBold>
          {formatCurrency(total)}
        </Text>
      </Box>
    </Box>
  );
};

// TODO: When NG-2721-livestock-sale-lot-details feature flag is ready,
// this component is safe to delete as it is replaced with `TransactionHeader`.
const GroupHeader = (props: GroupTransactionProps): JSX.Element => {
  const { hasPdf, title, onPress, documentId, documentAccountType } = props;
  return (
    <TransactionWrapper
      onPress={
        hasPdf
          ? () => onPress(documentId, title, documentAccountType)
          : undefined
      }
      isLast={false}
      tableStyle={false}
      testId={`grouped-header-${title}`}
    >
      <HeaderRow {...props} />
    </TransactionWrapper>
  );
};

export default GroupHeader;
