import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useStyles, Text, Box, useDeviceType } from '@nutrien/bonsai-core';
import { TRANSACTION_SORT_OPTIONS, TxSortOptions } from '../../constants';
import { trackEvent } from '../../constants/segment';
import { selectedAccountVar } from '../../reactiveVariables';
import { useSegment } from '../../hooks';
import Select, { SelectOptions } from '../Select';

export interface TransactionSortOptionsProps {
  sortValue: string;
  onSort: (value: TxSortOptions) => void;
}

const TransactionSortOptions = ({
  sortValue,
  onSort,
}: TransactionSortOptionsProps): JSX.Element => {
  const segment = useSegment();
  const { isHandset } = useDeviceType();
  const { account_id, account_view } = useReactiveVar(selectedAccountVar);
  const styles = useStyles((theme) => ({
    selectLabel: { paddingRight: theme.spacing(1) },
    selectContainer: { width: '100%' },
  }));

  return (
    <Box width={isHandset ? '100%' : 'auto'}>
      <Text style={styles.selectLabel} bodyBold>
        Sort by:
      </Text>
      <Select
        containerStyle={styles.selectContainer}
        name="sort-invoice"
        handleSelect={(val) => {
          onSort(val as TxSortOptions);
          const selectedLabel = TRANSACTION_SORT_OPTIONS.find(
            (option) => option.value === val
          )?.label;
          segment?.track(trackEvent.SORT_BY_SET, {
            account_id,
            tab_header: account_view.selected_tab,
            tab_label: account_view.display_view,
            sort_value: selectedLabel,
          });
        }}
        value={sortValue}
        options={TRANSACTION_SORT_OPTIONS as SelectOptions[]}
      />
    </Box>
  );
};

export default TransactionSortOptions;
