import React, { FC } from 'react';
import { Box, Card, Text, useStyles, useTheme } from '@nutrien/bonsai-core';
import { useLogin } from '../../context/LoginContext';
import {
  sortRecoActivityInput,
  groupFieldByFarmName,
} from '../../utils/recommendationUtils';
import Disclaimer from '../../components/FarmVisit/Disclaimer';
import ItemCard from './ItemCard';
import FarmCard from './FarmCard';
import FieldTable from './FieldTable';
import { FarmRecommendation_farm_recommendation_account } from '../../types/generated/FarmRecommendation';
import Breadcrumbs from '../../components/Breadcrumbs';
import { breadcrumbConstants } from '../../constants';
import { useRoutes } from '../../hooks';

type props = {
  id: string;
  recoAccessData: FarmRecommendation_farm_recommendation_account | undefined;
  updateIndex: (index: number) => void;
};

const RecommendationList: FC<props> = ({ id, recoAccessData, updateIndex }) => {
  const {
    state: { roles },
  } = useLogin();
  const { routeParams } = useRoutes();
  const { visitId: routeID } = Object(routeParams) || '';
  const theme = useTheme();

  const styles = useStyles(() => {
    return {
      cardStyle: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      titleText: {
        fontSize: theme.typography.fontSize.large,
        fontWeight: '600',
      },
      ctaButton: {
        marginBottom: theme.spacing(2),
      },
      inputsTitle: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
      },
      returnText: {
        marginLeft: theme.spacing(2),
        marginVertical: theme.spacing(2),
      },
    };
  });

  const { reco_activity_input, reco_activity_field } = {
    ...recoAccessData?.reco_account_activity,
  };

  const groupedFieldsByFarm = groupFieldByFarmName(reco_activity_field);

  const orderedActivityInputList = sortRecoActivityInput(reco_activity_input);

  return (
    <Box testID={`reco-${roles.join('-')}`}>
      {routeID && (
        <Breadcrumbs
          items={[
            breadcrumbConstants.farmVisitRecommendations(`${routeID}`),
            { title: 'Recommendation' },
          ]}
        />
      )}
      <Card style={styles.cardStyle}>
        <Text style={styles.titleText} bodyBold testID="recommendation-title">
          Recommendation Summary
        </Text>
      </Card>
      {groupedFieldsByFarm &&
        Object.entries(groupedFieldsByFarm).map(([key, value]) => (
          <React.Fragment key={key}>
            <FarmCard
              recommendationId={id}
              updateIndex={updateIndex}
              farm={{
                farmTitle: key,
                agronomist:
                  recoAccessData?.reco_account_activity?.agronomist_name || '',
                company:
                  recoAccessData?.reco_account_activity?.grower_org_name || '',
                dueDate: recoAccessData?.reco_account_activity?.rec_due_dtm,
                recommendationTitle:
                  recoAccessData?.reco_account_activity?.recommendation_title ||
                  '',
              }}
            />
            <FieldTable fields={value} />
          </React.Fragment>
        ))}
      {orderedActivityInputList && orderedActivityInputList.length > 0 ? (
        <Text bodyBold style={styles.inputsTitle}>
          Products
        </Text>
      ) : null}
      {orderedActivityInputList?.map((rec, index) => (
        <ItemCard key={index} data={rec} />
      ))}

      <Disclaimer />
    </Box>
  );
};

export default RecommendationList;
