import { BreadcrumbsItemProps } from '../components/Breadcrumbs';
import { routeConfig } from '../routes/shared';

export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';

// eslint-disable-next-line no-shadow
export enum TxSortOptions {
  ISSUED_DESC = 'issued:DESC',
  ISSUED_ASC = 'issued:ASC',
  DUE_DESC = 'due:DESC',
  DUE_ASC = 'due:ASC',
}

interface SortOption {
  label: string;
  value: TxSortOptions;
}

export const TRANSACTION_SORT_OPTIONS: SortOption[] = [
  {
    label: 'Date issued, newest at top',
    value: TxSortOptions.ISSUED_DESC,
  },
  {
    label: 'Date issued, oldest at top',
    value: TxSortOptions.ISSUED_ASC,
  },
  {
    label: 'Date due, newest at top',
    value: TxSortOptions.DUE_DESC,
  },
  {
    label: 'Date due, oldest at top',
    value: TxSortOptions.DUE_ASC,
  },
];

export const breadcrumbConstants = {
  home: {
    title: 'Dashboard',
    url: routeConfig.Home.getNavigateProps(),
  },
  userSummary: (customerId: string): BreadcrumbsItemProps => ({
    title: 'User Summary',
    url: routeConfig.UserSummary.getNavigateProps({
      customerId,
    }),
  }),
  accountSummary: (nominatedAccountId: string): BreadcrumbsItemProps => ({
    title: 'Account Summary',
    url: routeConfig.Accounts.getNavigateProps({ id: nominatedAccountId }),
  }),
  farmVisitSummary: (farmVisitId: string): BreadcrumbsItemProps => ({
    title: 'Farm Visit Summary',
    url: routeConfig.FarmVisit.getNavigateProps({ id: farmVisitId }),
  }),
  farmVisitRecommendations: (farmVisitId: string): BreadcrumbsItemProps => ({
    title: 'Farm Visit Recommendations',
    url: routeConfig.FarmVisitRecommendations.getNavigateProps({
      id: farmVisitId,
    }),
  }),
  farmRecommendation: (recommendationId: string): BreadcrumbsItemProps => ({
    title: 'Recommendation',
    url: routeConfig.FarmRecommendation.getNavigateProps({
      id: recommendationId,
    }),
  }),
};

// eslint-disable-next-line no-shadow
export enum ChipType {
  SUCCESS = 'success',
  INFO = 'info',
  DANGER = 'danger',
  WARN = 'warn',
}

// eslint-disable-next-line no-shadow
export enum RECOMMENDATION_STATUS {
  DISCARDED = 'rec_updated_dtm',
  DELETED = 'deleted_dtm',
  DUE = 'rec_due_dtm',
}

export const RECOMMENDATION_JOB_STATUS = {
  NOT_READY: 'Not ready',
  READY_TO_COLLECT: 'Ready to collect',
  SUBMITTED_TO_LAB: 'Submitted to lab',
  LAB_IMPORT_FAILED: 'Lab import failed',
  LAB_ANALYSIS_COMPLETE: 'Lab analysis complete',
  SUBMITTED_FOR_SURFACING: 'Submitted for surfacing',
  SURFACING_COMPLETE: 'Surfacing complete',
  COMPLETED: 'Completed',
  DISCARDED: 'discarded',
};

export const errorBannerMessage = {
  getRegistrationStatus:
    'There was an error fetching the user’s registration status. Please refresh your browser or try again later.',
  sendInvitation:
    'The registration invite failed to send due to an error. Please try again.',
  outageAlert:
    'We are currently experiencing some difficulties so some features may not work as expected. We’re sorry for the inconvenience, but we’ll be back to 100% soon.',
  livestockInvoiceAlert:
    'Please note that the upload of livestock invoices dated from 5 May 2022 onwards has been delayed. We are working on a resolution and will advise once available. For copies of any urgently required documents please contact your local Nutrien branch. We apologise for the inconvenience.',
};

export const customerListConstants = {
  columns: [
    {
      accessor: 'Given Name',
      maxWidth: 155,
    },
    {
      accessor: 'Family Name',
      maxWidth: 155,
    },
    {
      accessor: 'Email Address',
      maxWidth: 290,
    },
    {
      accessor: 'List of Accounts',
      maxWidth: 350,
    },
    // This is to get customerId data rather than displaying, and have been added in hiddenColumns
    {
      accessor: 'customerId',
      maxWidth: 1,
    },
  ],
  registrationStatusFilters: [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'invite_sent',
      label: 'Invite sent',
    },
    {
      value: 'invite_not_sent',
      label: 'Invite not sent',
    },
  ],
};

export const csvExportMessages = {
  success: 'Your transactions have been exported successfully.',
  error:
    'We are unable to export your transactions at this time. Please try again later.',
  info: '',
  warning: '',
  errorLight: '',
  successLight: '',
};

export const loginPageName = 'Login';

export const NO_RESULTS_FOUND = 'No results found';

// eslint-disable-next-line no-shadow
export enum TRANSACTIONS_FILTER {
  MERCHANDISE = 'merchandise',
  LIVESTOCK = 'livestock',
}

export const NUTRIEN_URL = 'https://www.nutrienagsolutions.com.au/';

export const DIGITAL_FEEDBACK_EMAIL = 'digital-feedback@nutrien.com.au';

export const PRIVACY_POLICY_URL =
  'https://www.nutrienagsolutions.com.au/sites/default/files/2020-03/documents/Nutrien%20Privacy%20Policy%20New%2020200227_final2.pdf';

// eslint-disable-next-line no-shadow
export enum CUSTOMER_SELF_REGISTRATION_STEPS {
  ABN_LOOKUP_STEP = 1,
  CUSTOMER_DETAILS_STEP = 2,
  CONFIRM_CUSTOMER_DETAILS_STEP = 3,
  DISPLAY_EMAIL_NOTIFICATION_STEP = 4,
}

export const SUPPORT_EMAIL = 'digital-support@nutrien.com.au';
export const FEEDBACK_EMAIL = 'digital-feedback@nutrien.com.au';
export const SUPPORT_PHONE = '1800 311 811';
