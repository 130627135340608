import React, { FC } from 'react';
import { Text, Box, NamedStyles, useStyles } from '@nutrien/bonsai-core';

import { useFormatters, useAccountSummary } from '../../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from 'types/generated/CustomerAccountsSummarySplitUp';
import SummaryCard from '../../SummaryCard';
import PanelDrawer from '../PanelDrawer/PanelDrawer';
import useDrawer from '../../../hooks/useDrawer';
import { DrawerLink } from '../DrawerLink';

export interface FinancePanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp?: AccountSummarySplitUpData | null;
  panelStyles?: NamedStyles<Record<string, undefined>>;
}

export const FinancePanel: FC<FinancePanelProps> = ({
  summary,
  panelStyles,
  summarySplitUp,
}) => {
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(panelStyles ?? {});
  const { finance, showMoreLinks } = useAccountSummary(summary);
  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      <SummaryCard
        heading={'Finance'}
        summaryRow={[
          {
            label: (
              <Box
                style={styles.label}
                flexDirection="row"
                alignItems="flex-start"
              >
                <Text style={styles.labelText}>Interest bearing balance</Text>
              </Box>
            ),
            content: renderNoneIfZero(finance.interestBearingBalance),
            button: (
              <DrawerLink
                enabled={showMoreLinks}
                drawerInfo={{
                  name: 'Interest bearing balance',
                  data: {
                    amount: finance.interestBearingBalance,
                    summarySplitUp,
                  },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: 'Finance',
                }}
              />
            ),
            testID: 'interest-bearing-balance-column',
          },
          {
            label: (
              <Box
                style={styles.label}
                flexDirection="row"
                alignItems="flex-start"
              >
                <Text style={styles.labelText}>
                  Non-interest bearing balance
                </Text>
              </Box>
            ),
            content: renderNoneIfZero(finance.nonInterestBearingBalance),
            button: (
              <DrawerLink
                enabled={showMoreLinks}
                drawerInfo={{
                  name: 'Non-interest bearing balance',
                  data: {
                    amount: finance.nonInterestBearingBalance,
                    summarySplitUp,
                  },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: 'Finance',
                }}
              />
            ),
            testID: 'non-interest-bearing-balance-column',
          },
          {
            label: (
              <Box
                style={styles.label}
                flexDirection="row"
                alignItems="flex-start"
              >
                <Text style={styles.labelText}>Total current balance</Text>
              </Box>
            ),
            content: renderNoneIfZero(summary?.CurrentBalance),
            testID: 'total-current-balance-column',
          },
        ]}
        testID={'account-details-finance-panel'}
      />
      <PanelDrawer
        onClose={(closeMethod: string) =>
          onDrawerClose(closeMethod, {
            tab_view: 'Finance',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};
