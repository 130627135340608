import { gql, useApolloClient } from '@apollo/client';
import { CustomerAccountWithFinance_getAccountSummary as AccountSummaryData } from 'types/generated/CustomerAccountWithFinance';

interface CacheAccountSummary {
  getAccountSummary: AccountSummaryData | null;
}

const ACCOUNT_SUMMARY = gql`
  query AccountSummary($account_id: String!, $nutrien_company_code: String!) {
    getAccountSummary(
      payload: {
        AccountNumber: $account_id
        CompanyCode: $nutrien_company_code
      }
    ) {
      AccountNumber
      CurrentBalance
      RewardAmount
      TransactionAmount
      RuralcoAccNumber
    }
  }
`;

const useCacheAccountSummary = ({
  account_id,
}: {
  account_id: string;
}): CacheAccountSummary | null => {
  const client = useApolloClient();
  const cacheData = client.readQuery({
    query: ACCOUNT_SUMMARY,
    variables: {
      account_id,
      nutrien_company_code: '8200',
    },
  });
  return cacheData;
};

export default useCacheAccountSummary;
