import {
  ActivityIndicator,
  Box,
  Icon,
  Pressable,
  Text,
  useDeviceType,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import React from 'react';
import { Button } from '../Button';
import { SkeletonPill } from '../Skeleton';

export interface TransactionFooterProps {
  isLoading?: boolean;
  onDownload: () => void;
  onReloadLotDetails?: () => void;
  lotDetailsError?: boolean;
  invoiceNumber: string;
}

const DownloadButton = ({
  isLoading,
  onDownload,
  invoiceNumber,
}: TransactionFooterProps) => {
  const { isDesktop } = useDeviceType();
  const styles = useStyles((theme) => ({
    row: {
      backgroundColor: theme.auColors.neutral[200],
    },
  }));
  return (
    <Box
      flexDir="row"
      justifyContent="flex-end"
      style={styles.row}
      paddingY={1.5}
      paddingX={2}
      testID="transaction-footer"
    >
      {isLoading ? (
        <SkeletonPill
          width={isDesktop ? '194' : '100%'}
          height="40"
          title="Loading download invoice button"
        />
      ) : (
        <Button
          type="outline"
          accessibilityLabel={`Download invoice ${invoiceNumber}`}
          testID={`download-invoice-${invoiceNumber}`}
          title="Download invoice"
          fullWidth={!isDesktop}
          iconLeft={
            <Icon
              name="file-download"
              type="material"
              testID="download-invoice-icon"
            />
          }
          onPress={() => onDownload()}
        />
      )}
    </Box>
  );
};

const LotDetailsInLineError = ({
  onReloadLotDetails,
  isLoading,
}: TransactionFooterProps) => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();

  const styles = useStyles(() => ({
    row: {
      backgroundColor: theme.auColors.neutral[200],
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    errorMessage: {
      marginRight: theme.spacing(1),
      color: theme.auColors.functional.error.dark,
    },
    reloadMessage: {
      color: theme.auColors.primary.default,
      fontSize: 16,
      fontWeight: '400',
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    loader: {
      marginRight: theme.spacing(1),
    },
  }));
  return (
    <Box
      flexDir={isDesktop ? 'row' : 'column'}
      justifyContent="space-between"
      style={styles.row}
      paddingY={1.5}
      paddingX={2}
      testID="transaction-footer"
    >
      <Box alignItems="center" flexDirection="row">
        <Icon
          name={'error-outline'}
          size={20}
          color={theme.auColors.functional.error.dark}
          testID={'error-livestock-details-icon'}
          style={styles.icon}
        />
        <Text style={styles.errorMessage}>
          {"This invoice livestock details didn't load"}
        </Text>
      </Box>
      {isLoading ? (
        <Box alignItems="center" flexDirection="row">
          <ActivityIndicator style={styles.loader} />
          <Text style={styles.reloadMessage} bodyBold>
            Loading livestock details
          </Text>
        </Box>
      ) : (
        <Pressable
          style={styles.transparent}
          focusable={true}
          focusedStyle={styles.transparent}
          hoveredStyle={styles.transparent}
          onPress={onReloadLotDetails}
        >
          <Box
            flexDirection="row"
            justifyContent="center"
            pt={isDesktop ? 0 : 3}
            pb={isDesktop ? 0 : 1}
          >
            <Icon
              name={'refresh'}
              size={20}
              color={theme.auColors.primary['400']}
              testID={'reload-livestock-details-icon'}
              style={styles.icon}
            />
            <Text style={styles.reloadMessage} bodyBold>
              Reload livestock details
            </Text>
          </Box>
        </Pressable>
      )}
    </Box>
  );
};

const TransactionFooter = (props: TransactionFooterProps): JSX.Element => {
  return props.lotDetailsError ? (
    <LotDetailsInLineError {...props} />
  ) : (
    <DownloadButton {...props} />
  );
};

export default TransactionFooter;
