import React, { useState, useEffect } from 'react';
import { Linking, Platform } from 'react-native';
import { Box, Text, useDeviceType } from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAuth from '../../auth/hooks';
import { ContentPage } from '../../components/ContentPage';
import Warning from '../../components/SVG/Warning';
import Link from '../../components/Link';
import { FEEDBACK_EMAIL, SUPPORT_PHONE } from '../../constants';

const Error = (): JSX.Element | null => {
  const { loginWithRedirect, isLoading } = useAuth();
  const { isDesktop, isHandset } = useDeviceType();
  const [loginLoading, setLoginLoading] = useState(false);
  const flags = useFlags();

  useEffect(() => {
    // polyfill for Safari login screen loading state issue NG-3110.
    // Safari would not unmount the login component when navigate away from it,
    // hence loading state would remain true when using back button from auth-0 page.
    const resetLoadingState = () => setLoginLoading(false);
    if (Platform.OS === 'web' && (window as any).safari) {
      window.addEventListener('popstate', resetLoadingState);
    }
    return () => {
      window.removeEventListener('popstate', resetLoadingState);
    };
  }, []);

  const _onPress = () => {
    if (flags['NG-3587-login-new-screen']) {
      if (flags['NG-3830-accept-new-tou']) {
        loginWithRedirect({ fragment: 'newscreen_tou' });
      } else {
        loginWithRedirect({ fragment: 'newscreen' });
      }
    } else {
      loginWithRedirect();
    }
    setLoginLoading(true);
  };

  const dialNumber = () => {
    Linking.openURL(`tel:${SUPPORT_PHONE}`);
  };
  const openEmail = () => {
    Linking.openURL(`mailto:${FEEDBACK_EMAIL}`);
  };

  const styles = {
    button: {
      minWidth: 75,
    },
  };

  // If the page is loading and we are not logging in, do not display the component, display the spinner
  if (isLoading && !loginLoading) return null;

  return (
    <Box
      testID="error-page"
      width="100%"
      height="100%"
      justifyContent={isDesktop ? 'center' : 'unset'}
      alignItems="center"
      marginLeft="auto"
      marginRight="auto"
    >
      <ContentPage
        title="Oops! There seems to be a problem..."
        icon={
          <Warning
            width={isHandset ? 'unset' : 300}
            maxWidth={isHandset ? '90%' : 'unset'}
          />
        }
        iconTestID={'warning-icon'}
        contentPageButtons={[
          {
            text: 'Login',
            onPress: _onPress,
            type: 'solid',
            buttonLoading: loginLoading,
            buttonStyle: styles.button,
            testID: 'login-button',
          },
        ]}
        maxWidth={isHandset ? 'unset' : undefined}
        footer={
          <Text>
            If you need help with registration, please get in touch on{' '}
            <Link
              hasUnderline={true}
              text={SUPPORT_PHONE}
              testID="mobile-link"
              onPress={dialNumber}
            />{' '}
            or at{' '}
            <Link
              hasUnderline={true}
              text={FEEDBACK_EMAIL}
              testID="email-link"
              onPress={openEmail}
            />
            .
          </Text>
        }
      >
        <Box>
          <Text testID="error-page-content">
            Are you trying to access the Digital Hub?
          </Text>
        </Box>
      </ContentPage>
    </Box>
  );
};

export default Error;
