/**
 * custom component to render table columns based on bonsai component
 * https://github.com/Agrium/nutrien-bonsai/blob/8a55a1d6f77b6fe01c09fcce6e034b7593c60558/packages/bonsai-core/src/Table/TablePage.tsx
 *
 */

import { Cell, TableCell, useStyles, Column } from '@nutrien/bonsai-core';
import { View } from 'native-base';
import React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { DataTable } from 'react-native-paper/src';
import { CellValue, Row, UseTableRowProps } from 'react-table';

export interface TablePageProps {
  page: Row<Record<string, unknown>>[];
  style?: ViewStyle;
  onCellPress?: (
    rowValues: UseTableRowProps<Record<string, unknown>>['values']
  ) => void;
  bottomRowBorder?: boolean;
  cellStyle?: StyleProp<ViewStyle>;
  cellProps?: typeof DataTable.Cell;
  hasFixedColumns?: boolean;
  pageSize: number;
  rowStyle?: StyleProp<ViewStyle>;
  rowProps?: typeof DataTable.Row;
  prepareRow: (row: Row<Record<string, unknown>>) => void;
  columnFilter?: (column: Column) => boolean;
}

const parseCell = (
  cell: Cell
): { value: CellValue; displayValue: CellValue } => {
  // a number, string or react element is passed as the cell value
  if (
    typeof cell === 'string' ||
    typeof cell === 'number' ||
    React.isValidElement(cell)
  ) {
    return {
      value: cell,
      displayValue: cell,
    };
  }

  // a number, string or react element is  passed as the value of cell.value
  if (
    typeof cell.value === 'string' ||
    typeof cell.value === 'number' ||
    React.isValidElement(cell.value)
  ) {
    return {
      value: cell.value,
      displayValue: cell.value,
    };
  }

  // The expected pair of value/string display
  return {
    value: cell.value.value,
    displayValue: cell.value.displayValue || cell.value.value?.toString() || '',
  };
};

const TablePage = ({
  bottomRowBorder,
  cellProps,
  cellStyle,
  pageSize,
  onCellPress,
  page,
  prepareRow,
  rowStyle,
  rowProps,
}: TablePageProps): JSX.Element => {
  const styles = useStyles((theme) => ({
    cell: {
      color: theme.auColors.neutral[800],
      paddingRight: theme.spacing(2),
      flex: 1,
      flexDirection: 'row',
      width: 0,
    },
    row: {
      flex: 1,
      paddingVertical: theme.spacing(1),
    },
    pointerCursor: { cursor: 'pointer' } as ViewStyle,
    defaultCursor: { cursor: 'default' } as ViewStyle,
  }));

  return (
    <View>
      {page.map((row, index: number) => {
        if (index < pageSize) {
          prepareRow(row);

          const borderBottomWidth =
            index === pageSize - 1 && !bottomRowBorder ? 0 : 1;
          const computedRowStyle = {
            borderBottomWidth: borderBottomWidth,
          };
          const computedCursorStyle = (hasPdf: boolean): ViewStyle =>
            hasPdf ? styles.pointerCursor : styles.defaultCursor;

          const hasPdf = row.original.hasPdf === true;

          return (
            <React.Fragment key={row.id}>
              <DataTable.Row
                {...rowProps}
                {...row.getRowProps()}
                onPress={
                  hasPdf && onCellPress
                    ? () => onCellPress(row.values)
                    : undefined
                }
                style={StyleSheet.flatten([
                  computedRowStyle,
                  computedCursorStyle(hasPdf),
                  styles.row,
                  rowStyle,
                ])}
                testID={`core-Table--Row-${row.id}`}
              >
                {row.cells.map((cell) => {
                  const { minWidth, maxWidth, width } = cell.column;

                  const rowStyles = StyleSheet.flatten([
                    styles.cell,
                    computedCursorStyle(hasPdf),
                    cellStyle,
                    { minWidth, maxWidth, width },
                  ]);

                  return (
                    <TableCell
                      {...cellProps}
                      {...cell.getCellProps?.()}
                      style={rowStyles}
                      key={cell.row.id + cell.column.id}
                      justifyContent={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        cell.column.justifyContent
                      }
                    >
                      {parseCell(cell).displayValue}
                    </TableCell>
                  );
                })}
              </DataTable.Row>
            </React.Fragment>
          );
        }
        return null;
      })}
    </View>
  );
};

export default TablePage;
