import React, { FC } from 'react';
import { NamedStyles } from '@nutrien/bonsai-core';
import { CustomerAccountsSummary_getAccountsSummary } from '../../../types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUp } from '../../../types/generated/CustomerAccountsSummarySplitUp';
import WhitePanelWrapper from '../WhitePanelWrapper';
import { FinanceEntireAccountPanel } from './FinanceEntireAccountPanel';
import { FinanceMerchandiseLivestockPanel } from './FinanceMerchandiseLivestockPanel';

export interface FinanceTwoSectionsProps {
  accountSummary: CustomerAccountsSummary_getAccountsSummary | null;
  accountSummarySplitUp: AccountSummarySplitUp | null;
  tabs?: {
    filterIndex: number;
    tabs: string[];
    onTabChange: (index: number) => void;
  };
  panelStyles: NamedStyles<Record<string, undefined>>;
}

export const FinanceTwoSections: FC<FinanceTwoSectionsProps> = ({
  accountSummary,
  accountSummarySplitUp,
  tabs,
  panelStyles,
}): JSX.Element => {
  return (
    <>
      <WhitePanelWrapper>
        <FinanceEntireAccountPanel
          summary={accountSummary}
          panelStyles={panelStyles}
        />
      </WhitePanelWrapper>
      <WhitePanelWrapper>
        <FinanceMerchandiseLivestockPanel
          summary={accountSummary}
          summarySplitUp={accountSummarySplitUp}
          tabs={tabs}
          panelStyles={panelStyles}
        />
      </WhitePanelWrapper>
    </>
  );
};
