import React, { createContext, useReducer, useContext, ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Url } from '../components/Breadcrumbs';

export interface CustomerDetail {
  customerName: string;
  customerId: string;
  email: string;
  onboardedBranchCode: string | null;
  onboardedBranch: string | null | undefined;
}

interface NotificationModal {
  showSaveModal: boolean;
  url?: Url;
  feature?: string;
  pageIndex?: number;
}
export interface StaffContextState {
  branchFilter: string;
  selectedCustomer: CustomerDetail | null;
  registrationStatusFilter: string;
  showCustomerLoginError: boolean;
  showSystemOutageAlert: boolean;
  showLivestockInvoiceAlert: boolean;
  registeredUsersPageIndex: number;
  showSaveUserSuccess: boolean;
  setShowSaveUserSuccess: boolean;
  hasChangesInForm?: boolean;
  notificationModal: NotificationModal;
}
type Action = {
  type:
    | 'setBranchFilter'
    | 'setSelectedCustomer'
    | 'setShowCustomerLoginError'
    | 'setShowSystemOutageAlert'
    | 'setShowLivestockInvoiceAlert'
    | 'setRegistrationStatusFilter'
    | 'setRegisteredUsersPageIndex'
    | 'setShowSaveUserSuccess'
    | 'setChangesInForm'
    | 'setNotificationModal';
  value:
    | StaffContextState['branchFilter']
    | StaffContextState['selectedCustomer']
    | StaffContextState['showCustomerLoginError']
    | StaffContextState['showSystemOutageAlert']
    | StaffContextState['showLivestockInvoiceAlert']
    | StaffContextState['registrationStatusFilter']
    | StaffContextState['registeredUsersPageIndex']
    | StaffContextState['showSaveUserSuccess']
    | StaffContextState['hasChangesInForm']
    | StaffContextState['notificationModal'];
};
type Dispatch = (action: Action) => void;
type StaffProviderValue = {
  state: StaffContextState;
  dispatch: Dispatch;
};
type StaffProviderProps = { children: ReactNode };

const StaffContext = createContext<StaffProviderValue | undefined>(undefined);

function staffReducer(state: StaffContextState, action: Action) {
  switch (action.type) {
    case 'setBranchFilter': {
      return { ...state, branchFilter: action.value };
    }
    case 'setRegistrationStatusFilter': {
      return { ...state, registrationStatusFilter: action.value };
    }
    case 'setRegisteredUsersPageIndex': {
      return { ...state, registeredUsersPageIndex: action.value };
    }
    case 'setSelectedCustomer': {
      return {
        ...state,
        selectedCustomer: action.value,
      };
    }
    case 'setShowCustomerLoginError': {
      return {
        ...state,
        showCustomerLoginError: action.value,
      };
    }
    case 'setShowSystemOutageAlert': {
      return {
        ...state,
        showSystemOutageAlert: action.value,
      };
    }
    case 'setShowLivestockInvoiceAlert': {
      return {
        ...state,
        showLivestockInvoiceAlert: action.value,
      };
    }
    case 'setShowSaveUserSuccess': {
      return {
        ...state,
        showSaveUserSuccess: action.value,
      };
    }
    case 'setChangesInForm': {
      return {
        ...state,
        hasChangesInForm: action.value,
      };
    }
    case 'setNotificationModal': {
      return {
        ...state,
        notificationModal: action.value,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
    }
  }
}

function StaffProvider({ children }: StaffProviderProps): JSX.Element {
  const flags = useFlags();
  const [state, dispatch] = useReducer(
    staffReducer as (a: StaffContextState, b: Action) => StaffContextState,
    {
      branchFilter: '',
      registrationStatusFilter: '',
      selectedCustomer: null,
      showCustomerLoginError: false,
      showSystemOutageAlert: flags['system-outage-alert'],
      showLivestockInvoiceAlert: flags['plasma-invoice-issue-alert'],
      registeredUsersPageIndex: 1,
      showSaveUserSuccess: false,
      setShowSaveUserSuccess: false,
      hasChangesInForm: false,
      notificationModal: {
        showSaveModal: false,
        url: undefined,
        feature: undefined, // is used to handle logOut Button
        pageIndex: 0,
      },
    }
  );
  const value = { state, dispatch };

  return (
    <StaffContext.Provider value={value}>{children}</StaffContext.Provider>
  );
}

function useStaff(): {
  state: StaffContextState;
  dispatch: Dispatch;
} {
  const context = useContext(StaffContext);

  if (context === undefined) {
    throw new Error('useStaff must be used within a StaffProvider');
  }

  return context;
}

export { StaffProvider, useStaff };
