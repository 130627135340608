import React, { useEffect } from 'react';
import Constants from 'expo-constants';
import { Dimensions } from 'react-native';
import { Box, Text, useTheme } from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Logo from '../../components/Logo';
import LoginButton from '../../components/LoginButton';
import useAuth from '../../auth/hooks';

const Auth0Login = (): JSX.Element | null => {
  const { loginWithRedirect } = useAuth();
  const flags = useFlags();

  const theme = useTheme();

  useEffect(() => {
    if (flags['NG-3587-login-new-screen']) {
      if (flags['NG-3830-accept-new-tou']) {
        loginWithRedirect({ fragment: 'newscreen_tou' });
      } else {
        loginWithRedirect({ fragment: 'newscreen' });
      }
    } else {
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    versionStyle: {
      fontSize: theme.typography.fontSize.small,
    },
  };

  return (
    <Box
      bg={'white'}
      flex={1}
      alignItems="center"
      justifyContent="center"
      height={Dimensions.get('screen').height}
    >
      <Box mb={3}>
        <Logo dark />
      </Box>
      <LoginButton isLoading={true} />
      <Box position="absolute" bottom={3}>
        <Text style={styles.versionStyle}>v{Constants.manifest.version}</Text>
      </Box>
    </Box>
  );
};

export default Auth0Login;
