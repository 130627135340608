import { ReactiveVar } from '@apollo/client';
import {
  selectedAccountVar,
  SelectedAccount,
  AccountView,
  selectedAccountInitialValue,
} from '../variables/selectedAccountVar';

const editSelectedAccount = (
  selectedAccountVariable: ReactiveVar<SelectedAccount>
) => {
  return (newState: Partial<SelectedAccount>) => {
    const state = selectedAccountVariable();
    const editedState = {
      ...state,
      ...newState,
    };
    selectedAccountVariable(editedState);
  };
};

const editAccountView = (
  selectedAccountVariable: ReactiveVar<SelectedAccount>
) => {
  return (newState: Partial<AccountView>) => {
    const state = selectedAccountVariable();
    const editedState = {
      ...state,
      account_view: {
        ...state.account_view,
        ...newState,
      },
    };
    selectedAccountVariable(editedState);
  };
};

const resetSelectedAccount = (
  selectedAccountVariable: ReactiveVar<SelectedAccount>
) => {
  return () => {
    selectedAccountVariable(selectedAccountInitialValue);
  };
};

interface SelectedAccountMutations {
  editSelectedAccount: (props: Partial<SelectedAccount>) => void;
  editAccountView: (props: Partial<AccountView>) => void;
  resetSelectedAccount: () => void;
}

export const selectedAccountMutations: SelectedAccountMutations = {
  editSelectedAccount: editSelectedAccount(selectedAccountVar),
  editAccountView: editAccountView(selectedAccountVar),
  resetSelectedAccount: resetSelectedAccount(selectedAccountVar),
};
