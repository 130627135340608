/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DocumentAccountType {
  PLASMA = 'PLASMA',
  STOREDOCS = 'STOREDOCS',
}

export enum DocumentType {
  invoice = 'invoice',
  statement = 'statement',
}

export interface AccountSummarySplitUpPayload {
  AccountNumber: string;
  AccountType: string;
  CompanyCode: string;
}

export interface GetAccountSummaryPayload {
  AccountNumber: string;
  CompanyCode: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
