import { DrawerData } from 'components/AccountDetailPanels/PanelDrawer/DrawerContent';
import {
  CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData,
  CustomerAccountsSummarySplitUp_getAccountSummarySplitUp_Items as SplitUpItems,
} from '../../../types/generated/CustomerAccountsSummarySplitUp';
import useFormatters from '../../../hooks/useFormatters';

type AccountSplit = 'Merchandise' | 'Livestock';
type Field = 'Noninterestbearingexpense' | 'Interestbearingexpense';
type SubField = 'Credit' | 'Debit' | 'Proceeds' | 'Invoices';
type DataType = 'Summary' | 'Documents';

interface DrawerDataParam {
  accountSplit: AccountSplit;
  field: Field;
  subfield: SubField;
  description?: string;
  dataType?: DataType;
}

interface UseDrawerData {
  getDrawerData: (dataProps: DrawerDataParam) => DrawerData[];
  getDrawerAmountData: (
    dataProps: DrawerDataParam
  ) => number | null | undefined;
  getDrawerEarlyPayments: () => number | null | undefined;
  getDrawerExtendedTermsData: () => DrawerData[] | undefined;
  getDrawerInvoiceData: (
    dataProps: DrawerDataParam
  ) => DrawerData[] | undefined;
}

const useDrawerData = (
  summarySplitUp: AccountSummarySplitUpData
): UseDrawerData => {
  const { formatAccountId, formatDate } = useFormatters();

  const getDrawerData = ({
    accountSplit,
    field,
    subfield,
    dataType,
  }: DrawerDataParam) => {
    const splitData = summarySplitUp?.Items?.find(
      (itm) =>
        itm?.AccountSplit === accountSplit &&
        itm?.Field === field &&
        itm?.SubField === subfield
    );

    const items =
      (splitData && splitData[dataType as keyof SplitUpItems]) || [];
    if (dataType === 'Documents') {
      return (items as SplitUpItems['Documents'])?.map((doc) => ({
        Amount: doc?.Amount || 0,
        Label: doc?.DocDescription || '',
        ClearingDocNumber: doc?.ClearingDocNumber,
        DueDate: doc?.DueDate,
        DocumentNumber: doc?.DocumentNumber,
      })) as DrawerData[];
    }
    return items as DrawerData[];
  };

  const getDrawerAmountData = (
    dataProps: DrawerDataParam
  ): number | null | undefined => {
    return getDrawerData(dataProps)
      .map((itm) => itm?.Amount ?? 0)
      .reduce((rec, acc) => rec + acc, 0);
  };

  const getDrawerEarlyPayments = (): number | null | undefined => {
    return getDrawerData({
      accountSplit: 'Merchandise',
      field: 'Noninterestbearingexpense',
      subfield: 'Credit',
      dataType: 'Documents',
    })
      .filter((doc) => !!doc?.ClearingDocNumber && doc.Label === 'Payment')
      .map((itm) => itm?.Amount ?? 0)
      .reduce((rec, acc) => rec + acc, 0);
  };

  const getDrawerExtendedTermsData = (): DrawerData[] | undefined => {
    return getDrawerData({
      accountSplit: 'Merchandise',
      field: 'Noninterestbearingexpense',
      subfield: 'Debit',
      dataType: 'Documents',
    }).filter((doc) => doc.Label.toLowerCase() === 'FutureDues'.toLowerCase());
  };

  const getDrawerInvoiceData = (dataProps: DrawerDataParam): DrawerData[] => {
    return getDrawerData({
      accountSplit: dataProps.accountSplit,
      field: dataProps.field,
      subfield: dataProps.subfield,
      dataType: 'Documents',
    }).map((doc) => ({
      Amount: doc?.Amount || 0,
      Label: `Invoice ${formatAccountId(doc?.DocumentNumber as string)}` || '',
      Description:
        `${dataProps.description} ${formatDate(
          doc?.DueDate as string,
          'dd MMM yyyy'
        )}` || '',
    })) as DrawerData[];
  };

  return {
    getDrawerData,
    getDrawerAmountData,
    getDrawerEarlyPayments,
    getDrawerExtendedTermsData,
    getDrawerInvoiceData,
  };
};

export default useDrawerData;
