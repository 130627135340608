import React, { FC } from 'react';
import {
  Box,
  Icon,
  NamedStyles,
  Text,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormatters } from '../../../hooks';
import SummaryCard from '../../SummaryCard';
import PanelDrawer from '../PanelDrawer/PanelDrawer';
import { Button } from '../../Button';
import useDrawer from '../../../hooks/useDrawer';
import { DrawerLink } from '../DrawerLink';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from 'types/generated/CustomerAccountsSummarySplitUp';

export interface TradingOverduePanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp?: AccountSummarySplitUpData | null;
  overdueAmount: number;
  panelStyles?: NamedStyles<Record<string, undefined>>;
}

const TradingOverduePanel: FC<TradingOverduePanelProps> = ({
  summary,
  summarySplitUp,
  overdueAmount,
  panelStyles,
}) => {
  const { renderNoneIfZero } = useFormatters();
  const theme = useTheme();
  const styles = useStyles(panelStyles ?? {});

  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const flags = useFlags();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      <SummaryCard
        heading="Overdue"
        summaryRow={[
          {
            label: (
              <Box
                style={styles.label}
                flexDirection="row"
                alignItems="flex-start"
              >
                <Icon
                  name="error-outline"
                  color={theme.auColors.functional.error.dark}
                  size={24}
                  style={styles.icon}
                />
                <Text style={styles.errorBold}>Overdue</Text>
              </Box>
            ),
            content: (
              <Text style={styles.overdueAmountEmphasis} bodyBold>
                {renderNoneIfZero(overdueAmount)}
              </Text>
            ),
            description: (
              <Box marginTop={0.5}>
                <Text style={styles.error}>incurring interest</Text>
              </Box>
            ),
          },
        ]}
        footer={
          flags['ng-3601-wdio-show-more-links'] ? (
            <Box
              flexDirection={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <DrawerLink
                enabled={flags['ng-3601-wdio-show-more-links']}
                drawerInfo={{
                  name: 'Overdue',
                  data: { amount: overdueAmount, summarySplitUp },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: 'Merchandise',
                }}
              />
              <Button
                containerStyle={{
                  marginLeft: 32,
                }}
                disabled={true}
                title="Pay now"
              />
            </Box>
          ) : undefined
        }
        testID={'account-details-trading-overdue-panel'}
      />
      <PanelDrawer
        onClose={(closeMethod: string) =>
          onDrawerClose(closeMethod, {
            tab_view: 'Merchandise',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};

export default TradingOverduePanel;
