import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import React, { ReactNode, useState, useEffect } from 'react';
import { CustomerProfileByCustomerId_customer_profile } from 'types/generated/CustomerProfileByCustomerId';
import { SearchCustomerAccounts_sords_customer_account } from 'types/generated/SearchCustomerAccounts';
import Autocomplete from '../../components/Autocomplete';
import { NO_RESULTS_FOUND } from '../../constants';
import AccountRow from '../../screens/StaffDashboard/AccountRow';
import SearchInput from '../../screens/StaffDashboard/SearchInput';
import useStaffDashboard from '../../screens/StaffDashboard/useStaffDashboard';
import { Button } from '../Button';
import Banner from '../Banner';
import AccountTableLinkAcc from './AccountTableLinkAcc';
import { useInsertCustomerAccountsMutation } from '../../hooks';
import { PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details } from '../../types/generated/PartyGetPersonDetailsWithAccountsById';
import { useStaff } from '../../context/StaffContext';

interface AddAccountPanelProps {
  customer?:
    | CustomerProfileByCustomerId_customer_profile
    | PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details
    | undefined;
  customerProfileByCustomerIdLoading: boolean;
  handleLinkAccountsSuccess: () => void;
}

type OwnProps = {
  visibleStateFunction: (visible: boolean) => void;
  informationVisibleStateFunc: (visible: boolean) => void;
};

type props = AddAccountPanelProps & OwnProps;

const AddAccountPanel = ({
  customer,
  visibleStateFunction: isVisible,
  informationVisibleStateFunc: isInformationVisible,
  customerProfileByCustomerIdLoading,
  handleLinkAccountsSuccess,
}: props): JSX.Element => {
  const { isDesktop, isHandset } = useDeviceType();
  const [showResults, setShowResults] = useState(false);
  const [nominatedAccountId, setNominatedAccountId] = useState('');
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [showNoSelectedRowError, setShowNoSelectedRowError] = useState(false);

  const theme = useTheme();

  const styles = {
    searchContainer: {
      borderWidth: 0,
      paddingLeft: theme.spacing(2),
      width: theme.spacing(2),
    },
    buttonContainer: {
      marginRight: isHandset ? 0 : theme.spacing(2),
      marginTop: isHandset ? theme.spacing(1) : theme.spacing(-6.5),
    },
    tableHeader: {
      height: theme.spacing(8),
      paddingLeft: theme.spacing(0),
      borderBottomWidth: 1,
      borderBottomColor: theme.auColors.neutral[400],
      backgroundColor: theme.auColors.neutral[200],
    },
    searchContainerMessage: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    bannerContainer: {
      marginTop: isHandset ? 0 : theme.spacing(2),
      marginBottom: isHandset ? 0 : theme.spacing(4),
    },
    insertCustomerAccountsErrorBanner: {
      color: theme.auColors.functional.error.dark,
    },
  };
  // Copied from Staff Dashboard screen till refactor and add a share place
  const {
    searchTerm,
    hideSearchResults,
    searchResults,
    handleSearchChange,
    searchLoading,
    searchError,
    setHideSearchResults,
  } = useStaffDashboard();

  const [
    insertCustomerAccounts,
    {
      data: insertCustomerAccountsData,
      error: insertCustomerAccountsError,
      loading: insertCustomerAccountsLoading,
    },
  ] = useInsertCustomerAccountsMutation();

  const { dispatch } = useStaff();

  useEffect(() => {
    if (
      insertCustomerAccountsData?.insertCustomerAccounts &&
      insertCustomerAccountsData?.insertCustomerAccounts.length > 0
    ) {
      handleLinkAccountsSuccess();
    }
  }, [insertCustomerAccountsData, handleLinkAccountsSuccess]);

  const handleSearchResultClick = (
    selectedCustomer: SearchCustomerAccounts_sords_customer_account
  ): void => {
    if (selectedCustomer?.name1 !== NO_RESULTS_FOUND) {
      setHideSearchResults(true);
      setNominatedAccountId(selectedCustomer.nominated_account_id || '');
      setShowResults(true);
    }
  };

  const renderSearchResult = ({
    item,
    isHighlighted,
  }: {
    item: unknown;
    isHighlighted?: boolean;
  }) => {
    const customerAccount =
      item as SearchCustomerAccounts_sords_customer_account;

    return (
      <AccountRow
        key={customerAccount.customer_guid}
        customerAccount={customerAccount}
        handleSearchResultClick={handleSearchResultClick}
        isHighlighted={isHighlighted}
      />
    );
  };
  const renderSearchInput = ({ onKeyDown }: { onKeyDown?: () => void }) => (
    <SearchInput
      {...{
        handleSearchChange,
        searchLoading,
        searchError,
        setHideSearchResults,
        onKeyDown,
      }}
    />
  );

  const handleSubmitForm = () => {
    dispatch({ type: 'setChangesInForm', value: false });
    if (selectedAccountIds.length === 0) {
      setShowNoSelectedRowError(true);
    } else {
      setShowNoSelectedRowError(false);
      insertCustomerAccounts({
        variables: {
          customerId:
            customer &&
            ('party_id' in customer ? customer.party_id : customer?.id),
          accountIds: selectedAccountIds,
        },
      });
    }
  };

  return (
    <Box
      mt={2}
      zIndex={2}
      px={isDesktop ? 3 : 2}
      py={3}
      bg={'white'}
      borderRadius={4}
      pl={4}
    >
      <Text
        h3
        testID="add-accounts-title"
        style={{ marginBottom: theme.spacing(1) }}
      >
        Add Account
      </Text>
      {(showNoSelectedRowError || insertCustomerAccountsError) && (
        <Banner
          location="inline"
          type="error"
          visible={true}
          testID="insert-customer-accounts-banner-error"
          style={styles.bannerContainer}
        >
          <Text bodyBold style={styles.insertCustomerAccountsErrorBanner}>
            {showNoSelectedRowError
              ? 'Please select an account to link.'
              : 'There was an error linking the accounts. Please try again'}
          </Text>
        </Banner>
      )}
      <Text
        testID="add-account-search-accounts-title"
        style={{ paddingBottom: theme.spacing(1) }}
      >
        Search for an account to link
      </Text>
      <Box zIndex={2} py={4} mt={-3} mb={-1}>
        <Autocomplete
          hideResults={hideSearchResults}
          data={searchResults}
          onBlur={() => setHideSearchResults(true)}
          value={searchTerm}
          flatListProps={{
            keyExtractor: (_, i) => i.toString(),
            renderItem: renderSearchResult,
            keyboardShouldPersistTaps: 'always',
            listKey: 'customerGuid',
          }}
          inputContainerStyle={styles.searchContainer}
          renderTextInput={renderSearchInput as () => ReactNode}
          onSelectionChange={handleSearchResultClick as unknown as () => void}
          onFocus={() => setHideSearchResults(false)}
        />
      </Box>
      {showResults && (
        <AccountTableLinkAcc
          nominated_account_id={nominatedAccountId}
          setSelectedAccountIds={
            setSelectedAccountIds as (value: string[]) => void
          }
          insertCustomerAccountsLoading={insertCustomerAccountsLoading}
          customerProfileByCustomerIdLoading={
            customerProfileByCustomerIdLoading
          }
        />
      )}
      <Box
        flexDirection={isHandset ? 'column-reverse' : 'row'}
        justifyContent="flex-end"
        mt={10}
      >
        <Button
          type="outline"
          onPress={() => {
            isVisible(false);
            isInformationVisible(true);
          }}
          title="Cancel"
          containerStyle={styles.buttonContainer}
          testID="add-account-cancel-button"
        />
        {showResults && (
          <Button
            onPress={handleSubmitForm}
            title="Save changes"
            testID="add-account-submit-button"
            containerStyle={styles.buttonContainer}
          />
        )}
      </Box>
    </Box>
  );
};

export default AddAccountPanel;
