import React from 'react';
import {
  useStyles,
  Text,
  Box,
  useDeviceType,
  Icon,
  useTheme,
  Pressable,
} from '@nutrien/bonsai-core';
import { selectedAccountMutations } from '../../reactiveVariables';

interface ExpandCollapseLinkProps {
  type: 'expand' | 'collapse';
  text: string;
  onPress: () => void;
  disabled: boolean;
}

const ExpandCollapseLink = ({
  type,
  text,
  onPress,
  disabled,
}: ExpandCollapseLinkProps) => {
  const theme = useTheme();
  const styles = useStyles(() => {
    return {
      title: {
        color: theme.auColors.primary.default,
      },
      chevron: {
        backgroundColor: theme.auColors.neutral[100],
        marginLeft: theme.spacing(0.5),
        transform: [{ rotate: type === 'expand' ? '0deg' : '180deg' }],
      },
      transparent: {
        backgroundColor: 'transparent',
      },
      disabled: {
        color: theme.auColors.functional.disabled.light,
      },
    };
  });
  return (
    <Pressable
      style={styles.transparent}
      focusable={true}
      focusedStyle={styles.transparent}
      hoveredStyle={styles.transparent}
      onPress={onPress}
      disabled={disabled}
    >
      <Box
        mr={4}
        display="flex"
        flexDirection={'row'}
        justifyContent={'center'}
      >
        <Text style={disabled ? styles.disabled : styles.title} bodyBold>
          {text}
        </Text>
        <Icon
          style={styles.chevron}
          name="expand-more"
          color={
            disabled
              ? theme.auColors.functional.disabled.light
              : theme.auColors.neutral[600]
          }
          testID={`${type}-all-chevron`}
        />
      </Box>
    </Pressable>
  );
};

export const ExpandCollapseAllLink = ({ disabled }: { disabled: boolean }) => {
  const { editSelectedAccount } = selectedAccountMutations;

  const onToggle = (expanded: boolean) => {
    editSelectedAccount({
      lot_details_expanded_all: expanded,
    });
  };

  const { isHandset } = useDeviceType();

  return (
    <Box
      mt={2}
      mr={2}
      display="flex"
      flexDirection={'row'}
      justifyContent={isHandset ? 'center' : 'flex-end'}
      width="100%"
      testID={disabled ? 'expand-collapse-all-disabled' : 'expand-collapse-all'}
    >
      <ExpandCollapseLink
        type="expand"
        text="Expand all"
        onPress={() => onToggle(true)}
        disabled={disabled}
      />
      <ExpandCollapseLink
        type="collapse"
        text="Collapse all"
        onPress={() => onToggle(false)}
        disabled={disabled}
      />
    </Box>
  );
};
