import React from 'react';
import { ActivityIndicator, Box } from '@nutrien/bonsai-core';

const RedirectingIndicator = (): JSX.Element => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="600"
    testID="redirecting-indicator"
  >
    <ActivityIndicator size="large" />
  </Box>
);

export default RedirectingIndicator;
