import { useTheme } from '@nutrien/bonsai-core';
import React from 'react';
import { TextStyle } from 'react-native';
import { useFormatters } from '../../../hooks';
import { Drawer } from '../../Drawer';
import TabsDrawerContent from './TabsDrawerContent';
import { DrawerInfo } from '../../../hooks/useDrawer';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from '../../../types/generated/CustomerAccountsSummarySplitUp';
import DrawerContent from './DrawerContent';
import { nextDueData, nextDueInvoicesDummyData } from './drawerContent.data';
import DrawerHeader from './DrawerHeader';
import useDrawerData from './useDrawerData';

export interface PanelDrawerProps {
  onClose: (closeMethod: string) => void;
  drawerInfo: DrawerInfo;
}

const PanelDrawer = ({
  onClose,
  drawerInfo,
}: PanelDrawerProps): JSX.Element => {
  const { name, data } = drawerInfo;
  const amount = data?.amount as number;
  const summarySplitUp = data?.summarySplitUp as AccountSummarySplitUpData;
  const theme = useTheme();
  const styles = {
    label: {
      minHeight: theme.spacing(3),
    },
    labelText: {
      color: theme.auColors.neutral[700],
    },
    subHeader: {
      marginBottom: 8,
      fontWeight: '300',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    errorContainer: {
      backgroundColor: theme.auColors.functional.error.light,
    },
    error: {
      color: theme.auColors.functional.error.dark,
      marginY: theme.spacing(2),
    },
    overdueAmount: {
      color: theme.auColors.functional.error.dark,
      fontWeight: '700',
    } as TextStyle,
    overdueAmountEmphasis: {
      ...theme.auTypography.desktop.headingMedium,
      color: theme.auColors.functional.error.dark,
      fontWeight: '700',
      fontSize: 23,
      marginTop: 8,
      marginBottom: 4,
    } as TextStyle,
    errorBold: {
      color: theme.auColors.functional.error.dark,
      fontWeight: '600',
    } as TextStyle,
    headerDescription: {
      fontWeight: 300,
      marginTop: 4,
    },
  };

  const { formatDate } = useFormatters();
  const {
    getDrawerData,
    getDrawerAmountData,
    getDrawerEarlyPayments,
    getDrawerExtendedTermsData,
    getDrawerInvoiceData,
  } = useDrawerData(summarySplitUp);

  const nextDueCredit = getDrawerAmountData({
    accountSplit: 'Merchandise',
    field: 'Noninterestbearingexpense',
    subfield: 'Credit',
    dataType: 'Summary',
  });

  const nextDueDebits = getDrawerAmountData({
    accountSplit: 'Merchandise',
    field: 'Noninterestbearingexpense',
    subfield: 'Debit',
    dataType: 'Summary',
  });

  const nextDueEarlyPayments = getDrawerEarlyPayments();
  const renderDrawerContent = () => {
    switch (name) {
      case 'Credits':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={`Credits from 01 to ${data?.lastDay}`}
            />
            <DrawerContent
              drawerStyles={styles}
              drawerData={getDrawerData({
                accountSplit: 'Merchandise',
                field: 'Noninterestbearingexpense',
                subfield: 'Credit',
                dataType: 'Summary',
              })}
              testID={'credits'}
              heading={'Credits'}
            />
          </>
        );
      case 'Expenses':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={`Expenses from 01 to ${data?.lastDay}`}
            />
            <DrawerContent
              drawerStyles={styles}
              drawerData={getDrawerData({
                accountSplit: 'Merchandise',
                field: 'Noninterestbearingexpense',
                subfield: 'Debit',
                dataType: 'Summary',
              })?.filter((item) => item?.Label !== 'Extended Terms')}
              testID={'expenses'}
              heading={'Expenses'}
            />
          </>
        );
      case 'Next due':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={`Next due ${
                data?.invoiceDate &&
                formatDate(
                  (data?.invoiceDate as Date).toISOString(),
                  'dd MMM yyyy'
                )
              }`}
            />
            <TabsDrawerContent
              drawerStyles={styles}
              drawerData={nextDueData(
                data?.lastDay as string,
                nextDueCredit,
                nextDueDebits,
                nextDueEarlyPayments
              )}
              testID={'next-due'}
              heading={'Next due'}
            />
          </>
        );
      case 'Next due invoices':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={'Next due invoices'}
            />
            <TabsDrawerContent
              drawerStyles={styles}
              drawerData={nextDueInvoicesDummyData}
              testID={'next-due-invoices'}
              heading={'Next due invoices'}
            />
          </>
        );
      case 'Extended terms':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={'Extended terms'}
            />
            <TabsDrawerContent
              drawerStyles={styles}
              drawerData={getDrawerExtendedTermsData()}
              testID={'extended-terms'}
              heading={'Extended terms'}
            />
          </>
        );
      case 'Overdue':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={true}
              label={'Overdue'}
              description={'incurring interest'}
            />
            <TabsDrawerContent
              drawerStyles={styles}
              drawerData={getDrawerInvoiceData({
                accountSplit: 'Merchandise',
                field: 'Interestbearingexpense',
                subfield: 'Debit',
                description: 'Due',
              })}
              testID={'overdue'}
              heading={'Overdue'}
            />
          </>
        );
      case 'Overdue invoices':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={true}
              label={'Overdue invoices'}
              description="incurring interest"
            />
            <TabsDrawerContent
              drawerStyles={styles}
              drawerData={getDrawerInvoiceData({
                accountSplit: 'Livestock',
                field: 'Interestbearingexpense',
                subfield: 'Invoices',
                description: 'Due',
              })}
              testID={'overdue-invoices'}
              heading={'Overdue invoices'}
            />
          </>
        );
      case 'Pending proceeds':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={'Pending proceeds'}
              description={'This will not reduce any amount due'}
            />
            <DrawerContent
              drawerStyles={styles}
              drawerData={getDrawerInvoiceData({
                accountSplit: 'Livestock',
                field: 'Noninterestbearingexpense',
                subfield: 'Proceeds',
                description: 'Scheduled for',
              })}
              testID={'pending-proceeds'}
              heading={'Pending proceeds'}
            />
          </>
        );
      case 'Non-interest bearing balance':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={'Non-interest bearing balance'}
            />
            <DrawerContent
              drawerStyles={styles}
              drawerData={getDrawerData({
                accountSplit: data?.onLivestockTab
                  ? 'Livestock'
                  : 'Merchandise',
                field: 'Noninterestbearingexpense',
                subfield: data?.onLivestockTab ? 'Proceeds' : 'Debit',
                dataType: 'Summary',
              })}
              testID={'non-interest-bearing-balance'}
            />
          </>
        );
      case 'Interest bearing balance':
        return (
          <>
            <DrawerHeader
              styles={styles}
              content={amount}
              isEmphasis={false}
              label={'Interest bearing balance'}
            />
            <DrawerContent
              drawerStyles={styles}
              drawerData={getDrawerData({
                accountSplit: data?.isLivestock ? 'Livestock' : 'Merchandise',
                field: 'Interestbearingexpense',
                subfield: data?.isLivestock ? 'Proceeds' : 'Debit',
                dataType: 'Summary',
              })}
              testID={'interest-bearing-balance'}
            />
          </>
        );
      default:
        return undefined;
    }
  };

  return (
    <Drawer
      placement="right"
      heading={name}
      isOpen={!!name}
      onClose={onClose}
      testID={name?.replace(/ /g, '-').toLowerCase()}
    >
      {renderDrawerContent()}
    </Drawer>
  );
};

export default PanelDrawer;
