import React, { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import useGuestM2mApolloClient, {
  M2mApolloClientOptions,
} from '../../hooks/useGuestM2mApolloClient';

interface Props {
  children: ReactNode;
  guestM2mClientOptions: M2mApolloClientOptions;
}

const ApolloGuestClientProvider = ({
  children,
  guestM2mClientOptions,
}: Props): JSX.Element => {
  const { client } = useGuestM2mApolloClient(guestM2mClientOptions);
  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }

  return <></>;
};

export default ApolloGuestClientProvider;
