import { ReactiveVar } from '@apollo/client';
import {
  AccountTransactions,
  accountTransactionsVar,
  LotDetailsItem,
  ParsedTransactions,
} from '../variables/accountTransactionsVar';

const editParsedTransactions = (
  accountTransactionsVariable: ReactiveVar<AccountTransactions>
) => {
  return (accountNumber: string, newState: Partial<ParsedTransactions>) => {
    const state = accountTransactionsVariable();
    const editedState = {
      ...state,
      [accountNumber]: {
        ...state?.[accountNumber as string],
        ...newState,
      },
    };
    accountTransactionsVariable(editedState);
  };
};

const editLotDetailsList = (
  accountTransactionsVariable: ReactiveVar<AccountTransactions>
) => {
  return (
    accountNumber: string,
    documentId: string,
    newState: Partial<LotDetailsItem>
  ) => {
    const state = accountTransactionsVariable();
    const editedState = {
      ...state,
      [accountNumber]: {
        ...state?.[accountNumber as string],
        lotDetailsList: {
          ...state?.[accountNumber as string]?.lotDetailsList,
          [documentId]: {
            ...state?.[accountNumber as string]?.lotDetailsList?.[
              documentId as string
            ],
            ...newState,
          },
        },
      },
    };
    accountTransactionsVariable(editedState);
  };
};

interface AccountTransactionsMutations {
  editParsedTransactions: (
    accountNumber: string,
    newState: Partial<ParsedTransactions>
  ) => void;
  editLotDetailsList: (
    accountNumber: string,
    documentId: string,
    newState: Partial<LotDetailsItem>
  ) => void;
}

export const accountTransactionsMutations: AccountTransactionsMutations = {
  editParsedTransactions: editParsedTransactions(accountTransactionsVar),
  editLotDetailsList: editLotDetailsList(accountTransactionsVar),
};
