import React, { FC } from 'react';

type props = {
  width: number;
};

const Email: FC<props> = ({ width }) => {
  return (
    <svg
      viewBox="0 0 360 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="auto"
      width={width}
    >
      <g clipPath="url(#clip0_6403_102034)">
        <path
          fill="#E9FFC2"
          fillRule="evenodd"
          d="M128.818 147.333H240.96c5.223 0 9.458-4.248 9.458-9.489 0-5.24-4.235-9.488-9.458-9.488 0 0-8.107-4.249-8.107-9.489 0-5.241 5.339-9.489 11.925-9.489h13.746c5.224 0 9.458-4.249 9.458-9.49 0-5.24-4.234-9.488-9.458-9.488H228.8c5.223 0 9.458-4.248 9.458-9.489 0-5.24-4.235-9.489-9.458-9.489h54.044c5.224 0 9.458-4.248 9.458-9.489 0-5.24-4.234-9.489-9.458-9.489H150.435c-5.223 0-9.457 4.249-9.457 9.49 0 5.24 4.234 9.488 9.457 9.488H96.391c-5.223 0-9.458 4.249-9.458 9.49 0 5.24 4.235 9.488 9.458 9.488h33.778c5.223 0 9.458 4.248 9.458 9.489 0 5.24-4.235 9.489-9.458 9.489H76.124c-5.223 0-9.457 4.248-9.457 9.489 0 5.24 4.234 9.489 9.457 9.489h52.694c-5.224 0-9.458 4.248-9.458 9.488 0 5.241 4.234 9.489 9.458 9.489zm144.568-47.444c0 5.24 4.235 9.489 9.458 9.489s9.458-4.249 9.458-9.49c0-5.24-4.235-9.488-9.458-9.488s-9.458 4.248-9.458 9.489z"
          clipRule="evenodd"
        />
        <path
          fill="#88D600"
          d="M262.219 79.296c1.728 0 3.128 1.45 3.128 3.24 0 1.698-1.264 3.092-2.872 3.227l-.256.01h-12.514c-1.727 0-3.128-1.45-3.128-3.238 0-1.7 1.264-3.093 2.872-3.228l.256-.01h12.514zM273.839 64.49c1.728 0 3.128 1.45 3.128 3.239 0 1.7-1.264 3.093-2.871 3.228l-.257.01h-30.704c-1.728 0-3.128-1.45-3.128-3.238 0-1.7 1.264-3.093 2.872-3.228l.256-.01h30.704zM285.458 62.64c1.728 0 3.129 1.45 3.129 3.239 0 1.699-1.264 3.093-2.872 3.228l-.257.01h-3.575c-1.728 0-3.128-1.45-3.128-3.238 0-1.7 1.264-3.093 2.872-3.228l.256-.01h3.575zM109.723 121.052c1.684 0 3.05 1.424 3.05 3.181 0 1.668-1.233 3.037-2.8 3.17l-.25.01H94.037c-1.684 0-3.05-1.424-3.05-3.18 0-1.669 1.233-3.038 2.8-3.171l.25-.01h15.686zM100.089 101.967c1.685 0 3.05 1.424 3.05 3.181 0 1.669-1.232 3.037-2.8 3.17l-.25.01H68.491c-1.684 0-3.05-1.424-3.05-3.18 0-1.669 1.233-3.038 2.8-3.171l.25-.01h31.598zM59.878 101.967c1.685 0 3.05 1.424 3.05 3.181 0 1.669-1.232 3.037-2.8 3.17l-.25.01H51.05c-1.684 0-3.05-1.424-3.05-3.18 0-1.669 1.232-3.038 2.8-3.171l.25-.01h8.828z"
        />
        <path
          fill="#465F70"
          d="M73.948 60.327c1.573 0 2.848 1.259 2.848 2.811 0 1.553-1.275 2.812-2.848 2.812-1.572 0-2.847-1.259-2.847-2.812 0-1.552 1.275-2.811 2.847-2.811zM310.585 121.624c-1.573 0-2.848-1.259-2.848-2.812s1.275-2.812 2.848-2.812 2.848 1.259 2.848 2.812-1.275 2.812-2.848 2.812zM101.471 73.258l.011.193.027 4.234 4.235.027a1.797 1.797 0 011.78 1.78 1.743 1.743 0 01-1.565 1.748l-.193.009-4.235-.028.028 4.235a1.742 1.742 0 01-1.757 1.758 1.797 1.797 0 01-1.769-1.588l-.011-.192-.027-4.234-4.235-.028a1.796 1.796 0 01-1.78-1.78 1.741 1.741 0 011.565-1.748l.193-.009 4.234.027-.027-4.234a1.742 1.742 0 011.757-1.757 1.798 1.798 0 011.737 1.4l.032.187zM282.05 23.32l.012.192.026 4.235 4.235.027a1.796 1.796 0 011.78 1.78 1.74 1.74 0 01-1.565 1.748l-.192.009-4.235-.028.028 4.235a1.742 1.742 0 01-1.758 1.758 1.798 1.798 0 01-1.768-1.588l-.012-.192-.027-4.235-4.234-.027a1.796 1.796 0 01-1.78-1.78 1.74 1.74 0 011.565-1.748l.192-.009 4.235.027-.027-4.234a1.74 1.74 0 011.757-1.757 1.798 1.798 0 011.737 1.4l.031.187zM302.533 104.226l-.192.011-4.235.027-.027 4.235a1.795 1.795 0 01-1.78 1.779 1.74 1.74 0 01-1.748-1.564l-.009-.193.028-4.235-4.235.028a1.743 1.743 0 01-1.758-1.757 1.798 1.798 0 011.588-1.769l.192-.011 4.235-.027.027-4.235a1.796 1.796 0 011.78-1.78 1.742 1.742 0 011.748 1.565l.009.193-.027 4.234 4.234-.027a1.741 1.741 0 011.757 1.757 1.797 1.797 0 01-1.4 1.737l-.187.032z"
        />
        <ellipse cx="63.272" cy="80.049" fill="#465F70" rx="4.272" ry="4.218" />
        <ellipse
          cx="266.07"
          cy="54.588"
          fill="#465F70"
          rx="4.272"
          ry="4.218"
          transform="rotate(-59.196 266.07 54.588)"
        />
        <ellipse
          cx="281.26"
          cy="120.218"
          fill="#465F70"
          rx="4.272"
          ry="4.218"
          transform="rotate(180 281.26 120.218)"
        />
        <path
          fill="#465F70"
          d="M93.617 151.814c.036-1.573 1.325-2.818 2.877-2.782 1.552.036 2.781 1.34 2.745 2.912-.037 1.572-1.325 2.818-2.877 2.782-1.553-.036-2.782-1.34-2.745-2.912zM111.197 144.168l-.007-.193.072-4.235-4.233-.124a1.797 1.797 0 01-1.738-1.821 1.741 1.741 0 011.605-1.712l.192-.004 4.233.126.071-4.235a1.742 1.742 0 011.798-1.716c.911.027 1.65.739 1.731 1.628l.007.193-.071 4.234 4.232.125a1.795 1.795 0 011.738 1.821 1.74 1.74 0 01-1.604 1.711l-.193.005-4.233-.125-.071 4.233a1.74 1.74 0 01-1.798 1.716 1.796 1.796 0 01-1.703-1.44l-.028-.187z"
        />
        <rect
          width="115"
          height="78.857"
          x="122"
          y="83.357"
          fill="#fff"
          stroke="#465F70"
          strokeLinejoin="round"
          strokeWidth="5.2"
          rx="6"
        />
        <path
          fill="#CAD6DE"
          stroke="#465F70"
          strokeLinejoin="round"
          strokeWidth="5.2"
          d="M181.633 135.17l53.359-47.56a2 2 0 00-.284-3.196l-54.161-33.27a2 2 0 00-2.094 0l-54.127 33.249a2 2 0 00-.263 3.216l54.93 47.58a2 2 0 002.64-.019z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M217.569 103.15l-35.959 31.056a2 2 0 01-2.593.018l-35.74-29.998-1.121-59.15a4 4 0 014-4.076h68.514a4 4 0 013.999 4.076l-1.1 58.074z"
          clipRule="evenodd"
        />
        <path
          fill="#465F70"
          d="M181.61 134.206l1.569 1.816-1.569-1.816zm35.959-31.056l1.569 1.816a2.398 2.398 0 00.831-1.771l-2.4-.045zm-38.552 31.074l1.543-1.838-1.543 1.838zm-35.74-29.998l-2.4.046c.013.693.326 1.347.857 1.793l1.543-1.839zm-1.121-59.15l-2.399.045 2.399-.045zm76.513 0l-2.4-.046 2.4.046zm-35.49 90.946l35.959-31.056-3.138-3.632-35.959 31.055 3.138 3.633zm-5.705.04a4.4 4.4 0 005.705-.04l-3.138-3.633a.401.401 0 01.519-.003l-3.086 3.676zm-35.74-29.997l35.74 29.997 3.086-3.676-35.74-29.998-3.086 3.677zm3.942-1.884l-1.12-59.15-4.799.09 1.12 59.151 4.799-.091zm-1.12-59.15a1.6 1.6 0 011.6-1.631v-4.8a6.4 6.4 0 00-6.399 6.521l4.799-.09zm1.6-1.631h68.514v-4.8h-68.514v4.8zm68.514 0a1.6 1.6 0 011.599 1.63l4.799.091a6.4 6.4 0 00-6.398-6.521v4.8zm1.599 1.63l-1.099 58.074 4.799.091 1.099-58.074-4.799-.09z"
        />
        <path
          fill="#fff"
          stroke="#465F70"
          strokeLinejoin="round"
          strokeWidth="5.2"
          d="M231.413 162.214H127.712c-1.892 0-2.726-2.383-1.248-3.563l52.592-41.974a2 2 0 012.517.017l51.11 41.975c1.451 1.191.608 3.545-1.27 3.545z"
        />
        <path
          fill="#465F70"
          d="M185.5 75.615h4.061l-2.75 9.325c-.215.759-.373 1.324-.473 1.697a4.113 4.113 0 00-.129.945c0 .43.143.838.43 1.225.286.372.744.558 1.375.558 1.231 0 2.463-.802 3.695-2.406 1.246-1.618 1.869-3.76 1.869-6.424 0-3.939-1.69-6.767-5.07-8.486-2.12-1.089-4.505-1.633-7.154-1.633-4.87 0-8.78 1.49-11.731 4.469-2.664 2.692-3.996 5.922-3.996 9.69 0 4.181 1.561 7.497 4.684 9.947 2.764 2.162 6.137 3.244 10.119 3.244 2.721 0 5.263-.502 7.627-1.504 1.289-.53 2.621-1.282 3.996-2.256l.515-.365 1.569 2.406a23.3 23.3 0 01-6.617 3.61 22.405 22.405 0 01-7.37 1.224c-5.944 0-10.548-1.848-13.814-5.543-2.707-3.065-4.061-6.696-4.061-10.893 0-4.698 1.69-8.68 5.071-11.945 3.552-3.452 8.099-5.178 13.642-5.178 4.512 0 8.25 1.182 11.215 3.545 3.123 2.492 4.684 5.801 4.684 9.926 0 3.251-.989 6.044-2.965 8.379-1.977 2.32-4.233 3.48-6.768 3.48-1.317 0-2.334-.372-3.05-1.117-.702-.745-1.053-1.51-1.053-2.299v-.322l.043-.408c-.545 1.031-1.153 1.855-1.826 2.47-1.218 1.103-2.679 1.655-4.383 1.655-1.733 0-3.18-.623-4.34-1.87-1.146-1.245-1.719-2.986-1.719-5.22 0-2.592.881-5.02 2.643-7.283 1.761-2.263 3.924-3.395 6.488-3.395 1.504 0 2.671.416 3.502 1.246a4.874 4.874 0 011.268 2.213l.773-2.707zm-9.26 12.89c.473.645 1.146.968 2.02.968 1.532 0 2.786-1.16 3.76-3.48.988-2.335 1.482-4.169 1.482-5.5 0-.76-.229-1.397-.688-1.913-.458-.516-1.074-.773-1.847-.773-1.49 0-2.772.93-3.846 2.793-1.074 1.847-1.611 3.638-1.611 5.37 0 1.046.243 1.891.73 2.536z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6403_102034">
          <path fill="#fff" d="M0 0H360V202H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Email;
