import { useReactiveVar } from '@apollo/client';
import { ActivityIndicator, Box, Snackbar } from '@nutrien/bonsai-core';
import { format, isAfter } from 'date-fns'; // todo
import { openURL } from 'expo-linking';
import { Modal } from 'native-base';
import React, { ReactNode, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { CustomerAccountWithFinance } from 'types/generated/CustomerAccountWithFinance';
import { SearchInvoices_searchDocuments } from 'types/generated/SearchInvoices';
import { SearchStatements_searchDocuments } from 'types/generated/SearchStatements';
import { trackEvent } from '../../constants/segment';
import { useTransaction } from '../../context/TransactionContext';
import { useGetDocumentByIdQuery, useSegment } from '../../hooks';
import { useCustomerAccountWithFinanceQuery } from '../../hooks/gql/queries/useCustomerAccountsQuery';
import { useSearchStatementsQuery } from '../../hooks/gql/queries/useSearchDocumentsQuery';
import { selectedAccountVar } from '../../reactiveVariables';
import {
  DocumentAccountType,
  DocumentType,
} from '../../types/generated/globalTypes';
import QueryCard from '../QueryCard';
import NoTransactions from '../TransactionsTableView/NoTransactions';
import DocumentTile from './DocumentTile';

type Document =
  | SearchStatements_searchDocuments
  | SearchInvoices_searchDocuments
  | null;

const sortFunction = (a: Document, b: Document) =>
  isAfter(new Date(b?.issueDate as string), new Date(a?.issueDate as string))
    ? 1
    : -1;

const Wrapper = ({ children }: { children?: ReactNode }) => (
  <Box minHeight={115} py={2}>
    {children}
  </Box>
);

const DocumentList = ({
  accountNumber,
}: {
  accountNumber: string;
}): JSX.Element => {
  const { state } = useTransaction();
  const [ruralcoAccNumber, setRuralcoAccNumber] = useState<string>();
  const [selectedIssuedDate, setSelectedIssuedDate] = useState<
    string | undefined
  >();

  const onAccountSummarySuccess = (financeData: CustomerAccountWithFinance) => {
    const { getAccountSummary } = financeData;
    setRuralcoAccNumber(getAccountSummary?.RuralcoAccNumber?.toString());
  };

  const [searchStatements, documentsData, documentsLoading, documentsError] =
    useSearchStatementsQuery();

  const loadStatements = () => {
    searchStatements({
      variables: {
        fromDate: format(state.fromDate, 'yyyy-MM-dd'),
        toDate: format(state.toDate, 'yyyy-MM-dd'),
        accountNumber,
        ruralcoAccNumber,
        documentType: DocumentType.statement,
      },
    });
  };

  useEffect(() => {
    loadStatements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruralcoAccNumber, state.fromDate, state.toDate, accountNumber]);

  const [_data, summaryLoading, summaryError] =
    useCustomerAccountWithFinanceQuery({
      account_id: accountNumber,
      onCompleted: onAccountSummarySuccess,
    });

  const [
    documentUrlData,
    documentUrlLoading,
    documentUrlError,
    getDocumentUrl,
    clearDocumentUrlError,
  ] = useGetDocumentByIdQuery();

  const segment = useSegment();
  const { account_id, account_type } = useReactiveVar(selectedAccountVar);

  const trackStatementViewEvent = (downloadSuccessful: boolean) => {
    segment?.track(trackEvent.STATEMENT_VIEWED, {
      account_id,
      account_type,
      statement_date: selectedIssuedDate,
      download_successful: downloadSuccessful,
    });
  };

  const handlePress = (
    id: string,
    accountType: DocumentAccountType,
    issueDate: SearchStatements_searchDocuments['issueDate']
  ) => {
    getDocumentUrl({
      variables: {
        accountNumber,
        documentId: id,
        accountType,
      },
    });
    setSelectedIssuedDate(
      issueDate ? format(new Date(issueDate), 'yyyy-MM-dd') : undefined
    );
  };

  useEffect(() => {
    if (documentUrlData?.getDocumentById?.url) {
      trackStatementViewEvent(true);
      Platform.OS === 'web'
        ? // eslint-disable-next-line security/detect-non-literal-fs-filename
          window.open(documentUrlData.getDocumentById.url, '_blank')
        : openURL(documentUrlData.getDocumentById.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUrlData]);

  useEffect(() => {
    if (documentUrlError) {
      trackStatementViewEvent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUrlError]);

  return (
    <>
      <QueryCard
        loading={summaryLoading || documentsLoading}
        error={summaryError || documentsError}
        wrapper={Wrapper}
      >
        {documentsData?.searchDocuments &&
        documentsData?.searchDocuments.length ? (
          [...documentsData.searchDocuments]
            .sort(sortFunction)
            .map((item) =>
              item ? (
                <DocumentTile
                  handlePress={handlePress}
                  key={item.documentId}
                  documentId={item.documentId}
                  issueDate={item.issueDate}
                  accountType={item.accountType as DocumentAccountType}
                  accountNumber={accountNumber}
                />
              ) : (
                <></>
              )
            )
        ) : (
          <NoTransactions />
        )}
      </QueryCard>
      <Modal
        backgroundColor="backdrop"
        isOpen={documentUrlLoading || documentUrlError}
      >
        {documentUrlLoading && <ActivityIndicator />}
        <Snackbar
          visible={!!documentUrlError}
          message="Something went wrong, please try again."
          actionTitle="Ok"
          onActionPress={() => clearDocumentUrlError()}
        />
      </Modal>
    </>
  );
};

export default DocumentList;
