import React, { ReactElement } from 'react';
import { View, ViewStyle, StyleSheet } from 'react-native';
import {
  useStyles,
  Text,
  Icon,
  useTheme,
  ActivityIndicator,
  Pressable,
  useDeviceType,
} from '@nutrien/bonsai-core';

interface Props {
  containerStyle?: ViewStyle;
  children?: ReactElement[] | ReactElement;
  error?: string;
  loading?: boolean;
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  borderStyle?: ViewStyle;
}
const SelectWrapper = ({
  children,
  error,
  containerStyle = {},
  loading,
  name,
  disabled,
  readOnly,
  borderStyle,
}: Props): JSX.Element => {
  const { isDesktop } = useDeviceType();
  const isDisabled = readOnly || disabled;
  const theme = useTheme();
  const styles = useStyles(() => ({
    wrapper: {
      backgroundColor: theme.auColors.neutral[100],
      borderRadius: theme.shape.borderRadius,
      alignSelf: 'flex-start',
    },
    error: {
      marginTop: 2,
    },
    hovered: {
      borderColor: theme.auColors.secondary[400],
      backgroundColor: theme.auColors.neutral[300],
      borderWidth: 1,
    },
    disabled: {
      backgroundColor: readOnly ? theme.auColors.neutral[200] : 'transparent',
      borderColor: theme.auColors.neutral[400],
      borderWidth: readOnly ? 0 : 1,
    },
    errorMessage: {
      color: theme.auColors.functional.error.dark,
      fontSize: theme.typography.fontSize.medium,
      marginTop: isDesktop ? 6 : 8,
    },
    iconContainer: {
      position: 'absolute',
      right: 0,
      padding: theme.spacing(0.5),
      zIndex: -1,
      alignItems: 'flex-end',
      top: 4,
    },
    loading: { height: 40, alignItems: 'flex-start' },
  }));

  if (loading) {
    return <ActivityIndicator style={styles.loading} />;
  }

  // TODO: incorporarte label into this component and implement accessibilityLabelledBy

  return (
    <>
      <View
        style={StyleSheet.flatten([
          styles.wrapper,
          containerStyle,
          error ? styles.error : {},
        ])}
      >
        <Pressable
          focusable={false}
          hoveredStyle={styles.hovered}
          style={borderStyle}
          disabled={isDisabled}
          disabledStyle={styles.disabled}
        >
          {children}
          {!readOnly && (
            <Icon
              name="expand-more"
              containerStyle={styles.iconContainer}
              color={
                disabled
                  ? theme.auColors.neutral[400]
                  : theme.auColors.neutral[600]
              }
            />
          )}
        </Pressable>
        {!!error && (
          <Text
            caption
            nativeID={`${name}-error`}
            accessibilityRole="alert"
            style={styles.errorMessage}
          >
            {error}
          </Text>
        )}
      </View>
    </>
  );
};

export default SelectWrapper;
