import { ApolloError, gql, useSubscription } from '@apollo/client';
import {
  SubscribeGetPdfZip,
  SubscribeGetPdfZipVariables,
} from 'types/generated/SubscribeGetPdfZip';

export const SUBSCRIBE_GET_PDF_ZIP = gql`
  subscription SubscribeGetPdfZip($id: uuid!) {
    getPdfZip(id: $id) {
      id
      errors
      output {
        url
        fileSize
      }
    }
  }
`;

const useSubscribeGetPdfZip = ({
  id,
  skip,
}: {
  id?: string;
  skip: boolean;
}): [SubscribeGetPdfZip | undefined, boolean, ApolloError | undefined] => {
  const { data, loading, error } = useSubscription<
    SubscribeGetPdfZip,
    SubscribeGetPdfZipVariables
  >(SUBSCRIBE_GET_PDF_ZIP, {
    variables: { id: id ?? '' },
    skip,
  });

  return [data, loading, error];
};

export default useSubscribeGetPdfZip;
