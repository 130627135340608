import React, {
  ForwardedRef,
  ForwardRefExoticComponent,
  MemoExoticComponent,
} from 'react';

import Svg, { SvgProps } from 'react-native-svg';

export interface SvgIconProps extends SvgProps {
  size?: number;
  viewBox?: string;
  color?: string;
}

export type BonsaiIcon = MemoExoticComponent<
  ForwardRefExoticComponent<SvgIconProps & React.RefAttributes<null>>
>;

// eslint-disable-next-line security/detect-unsafe-regex
const hexRegEx = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
const applyFillColor = (child: React.ReactElement, color?: string) => {
  const { fill } = child.props;
  return fill && hexRegEx.test(fill)
    ? React.cloneElement(child, { fill: color })
    : child;
};

const updateSvgPathFill = (path: React.ReactElement, color?: string) => {
  return React.Children.count(path.props.children)
    ? React.Children.map(path.props.children, (child) => {
        return React.isValidElement(child)
          ? applyFillColor(child, color)
          : child;
      })
    : applyFillColor(path, color);
};

export default function createSvgIcon(
  path: React.ReactElement,
  displayName: string,
  viewBoxDimensions = '0 0 24 24'
): BonsaiIcon {
  const Component = (props: SvgIconProps, ref: ForwardedRef<null>) => {
    const { size = 24, viewBox = viewBoxDimensions, color, ...other } = props;

    const children = color ? updateSvgPathFill(path, color) : path;

    return (
      <Svg
        testID={`${displayName}Icon`}
        viewBox={viewBox}
        height={size}
        width={size}
        ref={ref}
        {...other}
      >
        {children}
      </Svg>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  return React.memo(React.forwardRef(Component));
}
