import { Box, useDeviceType } from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC } from 'react';
import AccountOverview, {
  NewAccountOverview,
} from '../../components/AccountOverview';
import { getAccountType } from '../../hooks/useTransactions/transaction.helper';
import useAccountOverview from './useAccountOverview';

interface AccountOverviewWrapperProps {
  account_ids?: string[];
  account_id: string;
  customer_id?: string;
}

const AccountOverviewWrapper: FC<AccountOverviewWrapperProps> = ({
  account_ids,
  account_id,
  customer_id,
}) => {
  const flags = useFlags();
  const { isDesktop } = useDeviceType();
  const { accountWithFinance } = useAccountOverview({
    account_id,
  });

  const accountType = getAccountType(accountWithFinance?.accountData);
  const newAccountOverview =
    accountType &&
    flags['ng-2585-wdio-account-summary'] &&
    flags['ng-2585-wdio-account-summary'].includes(accountType.toLowerCase());

  return newAccountOverview ? (
    <Box
      mb={isDesktop ? 4 : 3}
      key={account_id}
      testID={`wdio-account-overview-${account_id}`}
    >
      <NewAccountOverview
        accountIds={account_ids}
        accountId={account_id}
        customerId={customer_id}
      />
    </Box>
  ) : (
    <Box mb={isDesktop ? 4 : 3} key={account_id} testID={`${account_id}`}>
      <AccountOverview account_id={account_id} customerId={customer_id} />
    </Box>
  );
};

export default AccountOverviewWrapper;
