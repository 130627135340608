import React, { ReactNode } from 'react';
import { View } from 'react-native';
import { useReactiveVar } from '@apollo/client';
import {
  Box,
  RowValues,
  TablePagination,
  TabularData,
  useDeviceType,
  useStyles,
} from '@nutrien/bonsai-core';
import { TabSceneProps } from 'components/Transaction/TabScene';
import { trackEvent } from '../../constants/segment';
import { useSegment } from '../../hooks';
import usePagination from '../../hooks/usePagination';
import { selectedAccountVar } from '../../reactiveVariables';
import QueryCard from '../QueryCard';
import { Transaction } from '../Transaction/transactions.types';
import NoTransactions from './NoTransactions';
import { tableColumns } from './tableColumns';
import TablePage, { TablePageProps } from './TablePage';
import TransactionTile from './TransactionTile';
import { DocumentAccountType } from '../../types/generated/globalTypes';

type TransactionViewProps = Pick<
  TabSceneProps,
  'loading' | 'error' | 'items' | 'refetch' | 'onPress'
>;

const Wrapper = ({ children }: { children?: ReactNode }) => (
  <Box minHeight={115}>{children}</Box>
);

const TransactionTableView = ({
  onPress,
  loading,
  error,
  refetch,
  items,
}: TransactionViewProps): JSX.Element => {
  const { isDesktop } = useDeviceType();
  const { account_id, account_view } = useReactiveVar(selectedAccountVar);
  const {
    pageSize,
    pageIndex,
    setPageIndex,
    onSetPageSize,
    onNextPage,
    onPrevPage,
    numberOfPages,
    filterItems,
  } = usePagination(items);
  const segmentProperties = {
    account_id,
    tab_header: account_view.selected_tab,
    tab_label: account_view.display_view,
  };
  const segment = useSegment();

  const style = useStyles((theme) => ({
    header: {
      backgroundColor: theme.auColors.neutral[300],
      borderBottomWidth: 0,
    },
  }));

  const renderCards = (data: Transaction[]) => () => {
    return items.length ? (
      <View testID="transaction-tiles">
        {data.map((props, i, { length }) => {
          const { hasPdf, documentId, invoiceNumber, documentAccountType } =
            props;
          const pdfHandler = hasPdf
            ? () =>
                onPress(
                  documentId,
                  invoiceNumber?.toString(),
                  documentAccountType
                )
            : undefined;
          return (
            <TransactionTile
              key={i}
              {...props}
              last={i === length - 1}
              onPress={pdfHandler}
              testId={`transaction-tile-${i}`}
            />
          );
        })}
      </View>
    ) : (
      <NoTransactions />
    );
  };

  const filteredItems = items.filter((_, i) => filterItems(i));

  return (
    <QueryCard
      loading={loading}
      error={error}
      handleReload={refetch}
      wrapper={Wrapper}
    >
      {items && (
        <>
          <TabularData
            columns={tableColumns}
            EmptyComponent={NoTransactions}
            onSort={(sortItem) => {
              segment?.track(trackEvent.COLUMN_SORT_SET, {
                ...segmentProperties,
                sort_column: sortItem.sortBy,
                sort_direction: sortItem.desc ? 'Descending' : 'Ascending',
              });
            }}
            type={isDesktop ? 'table' : 'cards'}
            renderCards={renderCards(items)}
            data={filteredItems as unknown as Record<string, unknown>[]}
            tableProps={{
              sortable: true,
              onCellPress: ({
                invoiceNumber,
                documentId,
                documentAccountType,
              }: RowValues) =>
                onPress(
                  documentId?.toString(),
                  invoiceNumber?.toString(),
                  documentAccountType as DocumentAccountType
                ),
              headerStyle: style.header,
              renderPage: (props) => (
                <TablePage {...(props as unknown as TablePageProps)} />
              ),
              pagination: false,
              stateReducer: (newState, action) => {
                if (action.type === 'toggleSortBy') {
                  setPageIndex(0);
                }

                return newState;
              },
              initialState: {
                hiddenColumns: ['documentId', 'documentAccountType'],
              },
            }}
          />
          {isDesktop && items.length > 10 && (
            <TablePagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRecords={items.length}
              canNextPage={pageIndex + 1 < numberOfPages}
              canPreviousPage={pageIndex > 0}
              setPageSize={(size) => onSetPageSize(size, segmentProperties)}
              previousPage={() => onPrevPage(segmentProperties)}
              nextPage={() => onNextPage(segmentProperties)}
            />
          )}
        </>
      )}
    </QueryCard>
  );
};

export default TransactionTableView;
