import { ActivityIndicator, Box, useTheme } from '@nutrien/bonsai-core';
import React, { useEffect, useState } from 'react';
import { TextStyle } from 'react-native';
import { TRANSACTIONS_FILTER } from '../../constants';
import { useNavigation, useRoutes } from '../../hooks';
import useAccountDetailPanels from '../../hooks/useAccountDetailPanels';
import { routeConfig } from '../../routes/shared';
import { AccountType, TileType } from '../AccountOverview/useAccountOverview';
import { FinanceOneSection } from './FinancePanels/FinanceOneSection';
import { FinanceTwoSections } from './FinancePanels/FinanceTwoSections';
import Prepay from './PrepayPanels/Prepay';
import Trading from './TradingPanels/Trading';

export interface AccountDetailPanelsProps {
  accountId: string;
  accountType?: string | null;
  tileType?: TileType;
  isFilterAvailable: boolean;
  customerId?: string | null;
}

export type FilterTabs = 'Merchandise' | 'Livestock';

export const AccountDetailPanels = ({
  accountId,
  accountType,
  tileType,
  isFilterAvailable,
  customerId,
}: AccountDetailPanelsProps) => {
  const { routeParams } = useRoutes();
  const { navigate } = useNavigation();
  const tabs: FilterTabs[] = ['Merchandise', 'Livestock'];
  const [filterIndex, setFilterIndex] = useState<number>(
    routeParams.transactionsFilter === TRANSACTIONS_FILTER.LIVESTOCK ? 1 : 0
  );

  const {
    accountSummary,
    loadingAccountsSummary,
    accountSummarySplitUp,
    loadingAccountsSummarySplitUp,
  } = useAccountDetailPanels(accountId, accountType);

  const onTabChange = (index: number): void => {
    navigate(
      routeConfig.AccountWithFilter.getNavigateProps({
        type: accountType as AccountType,
        id: accountId,
        transactionsFilter: Object.values(TRANSACTIONS_FILTER)[index as number],
        customerId,
      })
    );
  };

  useEffect(() => {
    setFilterIndex(
      routeParams.transactionsFilter === TRANSACTIONS_FILTER.LIVESTOCK ? 1 : 0
    );
  }, [routeParams.transactionsFilter]);

  const theme = useTheme();
  // ## TODO: Map font-size 23 to a theme size,
  // at the moment it is not available.
  const panelStyles = {
    label: {
      minHeight: theme.spacing(3),
    },
    labelText: {
      color: theme.auColors.neutral[700],
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    error: {
      color: theme.auColors.functional.error.dark,
      marginY: theme.spacing(2),
    },
    overdueAmount: {
      color: theme.auColors.functional.error.dark,
      fontWeight: '700',
    } as TextStyle,
    overdueAmountEmphasis: {
      color: theme.auColors.functional.error.dark,
      fontWeight: '700',
      fontSize: 23,
    } as TextStyle,
    errorBold: {
      color: theme.auColors.functional.error.dark,
      fontWeight: '600',
    } as TextStyle,
    infoHeading: {
      fontWeight: '600',
      color: theme.auColors.neutral[700],
    } as TextStyle,
    info: {
      color: theme.auColors.neutral[700],
    },
    description: {
      marginTop: theme.spacing(1),
    },
    emphasis: {
      fontSize: 23,
    },
  };

  const drawerDataLoading =
    loadingAccountsSummary || loadingAccountsSummarySplitUp;

  const renderDetailPanels = () => {
    switch (tileType) {
      case 'FinanceTwoSections':
        return (
          <FinanceTwoSections
            accountSummary={accountSummary}
            accountSummarySplitUp={accountSummarySplitUp}
            tabs={
              isFilterAvailable
                ? {
                    onTabChange,
                    filterIndex,
                    tabs,
                  }
                : undefined
            }
            panelStyles={panelStyles}
          />
        );
      case 'FinanceOneSection':
        return (
          <FinanceOneSection
            accountSummary={accountSummary}
            accountSummarySplitUp={accountSummarySplitUp}
            panelStyles={panelStyles}
          />
        );
      case 'Trading':
        return (
          <Trading
            accountId={accountId}
            accountSummary={accountSummary}
            accountSummaryLoading={drawerDataLoading}
            accountSummarySplitUp={accountSummarySplitUp}
            tabs={
              isFilterAvailable
                ? {
                    onTabChange,
                    filterIndex,
                    tabs,
                  }
                : undefined
            }
            panelStyles={panelStyles}
          />
        );
      case 'Prepay':
        return (
          <Prepay accountSummary={accountSummary} panelStyles={panelStyles} />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box testID="panel-container">
      {drawerDataLoading ? (
        <ActivityIndicator testID="panel-data-loading" />
      ) : (
        renderDetailPanels()
      )}
    </Box>
  );
};
