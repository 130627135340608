import React from 'react';
import { Box, Text } from '@nutrien/bonsai-core';
import Banner from '../Banner';
import { CacheAccountIdDocumendId } from '../../hooks/gql/cache/useCachePdfZipIds';
import { DownloadBannerType, getDownloadBannerContent } from './banner.helper';

export interface DownloadBannerProps {
  location: 'inline' | 'global';
  type: DownloadBannerType;
  documentInCache: CacheAccountIdDocumendId;
  isVisible: boolean;
  testID: string;
  onClose: () => void;
  onTryAgain?: () => void;
}

const DownloadBanner = ({
  location,
  type,
  isVisible,
  documentInCache,
  onClose,
  onTryAgain,
  testID,
}: DownloadBannerProps): JSX.Element => {
  const closeAction = {
    text: 'Dismiss',
    onPress: onClose,
  };

  const tryAgainAction = onTryAgain && {
    text: 'Try again',
    onPress: onTryAgain,
  };

  const actionItems = tryAgainAction
    ? [closeAction, tryAgainAction]
    : [closeAction];

  const content = getDownloadBannerContent(type, documentInCache);
  const bannerType = type === 'popup_blocked' ? 'error' : type;
  return (
    <Banner
      location={location}
      type={bannerType}
      visible={isVisible}
      testID={testID}
      actionItems={actionItems}
    >
      <Box flexDir="column">
        <Text bodyBold>{content.title}</Text>
        <Text>{content.body}</Text>
      </Box>
    </Banner>
  );
};

export default DownloadBanner;
