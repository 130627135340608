import React from 'react';
import { DrawerInfo, DrawerSegmentInfo } from '../../hooks/useDrawer';
import Link from '../Link';

export interface DrawerLinkProps {
  enabled: boolean;
  text?: string;
  drawerInfo: DrawerInfo;
  onDrawerOpen: (data: DrawerInfo, segmentInfo: DrawerSegmentInfo) => void;
  segmentInfo: DrawerSegmentInfo;
}

export const DrawerLink = (props: DrawerLinkProps) => {
  const { drawerInfo, text, onDrawerOpen, segmentInfo } = props;
  const { name, data } = drawerInfo;
  if (!props.enabled || !data?.amount) return null;

  const linkText = text || `Show ${name?.toLowerCase()}`;
  return (
    <Link
      hasUnderline={true}
      text={linkText}
      testID={`${linkText.toLowerCase().replace(/ /g, '-')}-link`}
      onPress={() => onDrawerOpen(drawerInfo, segmentInfo)}
    />
  );
};
