import React, { FC } from 'react';
import { Box, Text, useStyles } from '@nutrien/bonsai-core';

type props = {
  index: number;
  maxIndex: number;
};

const FormStepper: FC<props> = ({ index, maxIndex }) => {
  const styles = useStyles((theme) => ({
    textStyles: {
      color: theme.auColors.neutral[900],
      fontWeight: '300',
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
  }));

  return (
    <Box testID="form-stepper">
      <Text style={styles.textStyles}>{`Step ${index} of ${maxIndex}`}</Text>
    </Box>
  );
};

export default FormStepper;
