import { makeVar, ReactiveVar } from '@apollo/client';

export type AccountTabs = 'Transactions' | 'Statements';
export type TabSceneType = 'Table view' | 'Invoice view';
export type DateViewTabs = 'Statement date' | 'Date range';
/**
 * Account view information.
 */
export interface AccountView {
  selected_tab?: AccountTabs;
  display_view?: TabSceneType;
  date_view?: DateViewTabs;
}

/**
 * Selected account information.
 */
export interface SelectedAccount {
  account_id: string;
  account_type: string;
  account_description?: string;
  account_name?: string | null;
  account_view: AccountView;
  lot_details_expanded_all?: boolean;
}

/**
 * Selected account initial values.
 */
export const selectedAccountInitialValue: SelectedAccount = {
  account_id: '',
  account_type: '',
  account_view: {},
};

export const selectedAccountVar: ReactiveVar<SelectedAccount> =
  makeVar<SelectedAccount>(selectedAccountInitialValue);
