import React from 'react';
import { Box, Icon, ActivityIndicator, useTheme } from '@nutrien/bonsai-core';

interface LoadingSearchIconProps {
  loading: boolean;
}

const LoadingSearchIcon = ({
  loading,
}: LoadingSearchIconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
    >
      {loading && (
        <Box mr={1}>
          <ActivityIndicator testID="search-loading" />
        </Box>
      )}
      <Box
        backgroundColor="brand.denim"
        width={'40px'}
        height={'40px'}
        display="flex"
        justifyContent="center"
        mr={-1}
      >
        <Icon
          name="search"
          iconProps={{
            name: 'search',
            color: theme.auColors.neutral[100],
          }}
        />
      </Box>
    </Box>
  );
};

export default LoadingSearchIcon;
