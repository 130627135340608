import { ApolloError } from '@apollo/client';
import {
  useCustomerAccountsByNominatedAccountIdQuery,
  useRoutes,
} from '../../hooks';

interface UseAccounts {
  selectedAccountId: string;
  accountIds: (string | null)[];
  customerAccountsLoading: boolean;
  customerAccountsError?: ApolloError;
}

const useAccounts = (): UseAccounts => {
  const { routeParams } = useRoutes();
  const selectedAccountId = routeParams.id as string;
  const {
    data: customerAccountsData,
    loading: customerAccountsLoading,
    error: customerAccountsError,
  } = useCustomerAccountsByNominatedAccountIdQuery({
    nominated_account_id: selectedAccountId,
  });

  const accountIds = customerAccountsData
    ? customerAccountsData.sords_customer_account.map(
        ({ account_id }) => account_id
      )
    : [];

  return {
    selectedAccountId,
    accountIds,
    customerAccountsLoading,
    customerAccountsError,
  };
};

export default useAccounts;
