import React from 'react';
import { Theme, useStyles } from '@nutrien/bonsai-core';
import { Chip as PaperChip } from 'react-native-paper';
import { ChipType } from '../../constants';

export type ChipProps = {
  title: string;
  variant?: ChipType;
};

const getStylesByType = (
  theme: Theme,
  variant: ChipType
): { textColor: string; chipColor: string } =>
  ({
    info: {
      textColor: theme.auColors.functional.info.dark,
      chipColor: theme.auColors.functional.info.light,
    },
    danger: {
      textColor: theme.auColors.functional.error.dark,
      chipColor: theme.auColors.functional.error.light,
    },
    success: {
      textColor: theme.auColors.functional.success.dark,
      chipColor: theme.auColors.functional.success.light,
    },
    warn: {
      textColor: theme.auColors.functional.warningAlt.dark,
      chipColor: theme.auColors.functional.warningAlt.light,
    },
  }[variant || 'info']);

const Chip = ({ title, variant = ChipType.INFO }: ChipProps): JSX.Element => {
  const styles = useStyles((theme) => {
    const variantTheme = getStylesByType(theme, variant);

    /**
     * @todo add support for outlined variant.
     * For further customisation, pass margins as props to further customise the testTtyle
     */
    return {
      chip: {
        backgroundColor: variantTheme.chipColor,
      },
      chipText: {
        marginVertical: 0, // Controls margin above and below chip text within chip
        color: variantTheme.textColor,
      },
    };
  });

  return (
    <PaperChip style={styles.chip} textStyle={styles.chipText}>
      {title}
    </PaperChip>
  );
};

export default Chip;
