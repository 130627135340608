import React from 'react';

interface NavigationProps {
  name: string;
  params?: Record<string, string>;
}

export interface NavigateHTMLElement {
  navigate: (name: string, params?: unknown) => void;
}

export const navigationRef: React.RefObject<NavigateHTMLElement> =
  React.createRef();

export const navigate = ({ name, params }: NavigationProps): void => {
  navigationRef.current?.navigate(name, params);
};
