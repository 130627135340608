import { Box } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { TRANSACTIONS_FILTER } from '../../constants';
import { trackEvent } from '../../constants/segment';
import { useFormatters, useNavigation, useSegment } from '../../hooks';
import { selectedAccountMutations } from '../../reactiveVariables';
import { routeConfig } from '../../routes/shared';
import QueryCard from '../QueryCard';
import AccountOverviewCard from './AccountOverviewCard';
import AccountSummary from './AccountSummary';
import useAccountOverview, { AccountType } from './useAccountOverview';

export interface NewAccountOverviewProps {
  accountIds?: string[];
  accountId: string;
  customerId?: string;
}

/**
 *
 * @param accountId accountId has leading "00"
 */
const NewAccountOverview: FC<NewAccountOverviewProps> = ({
  accountIds,
  accountId,
  customerId = null,
}) => {
  const segment = useSegment();
  const { navigate } = useNavigation();
  const {
    loading,
    error,
    handleReload,
    getAccountTypeDescription,
    accountWithFinance,
    accountsSummary,
  } = useAccountOverview({
    account_id: accountId,
    account_ids: accountIds,
    newAccountOverview: true,
  });

  const { formatCapitalize } = useFormatters();
  const { editSelectedAccount } = selectedAccountMutations;

  const accountDescription =
    accountWithFinance?.accountData &&
    getAccountTypeDescription(accountWithFinance.accountData);

  const accountOverviewHeading = `${
    accountWithFinance?.accountData?.name1 || ''
  } #${accountWithFinance?.accountData?.account_id_trimmed || ''}`;

  const handleClickViewDetails = ({
    account_type,
    isLivestock,
    account_description,
    hasLivestock,
  }: {
    account_type: AccountType;
    isLivestock?: boolean;
    account_description?: string;
    hasLivestock?: boolean;
  }) => {
    const accountViewed = isLivestock
      ? `${formatCapitalize(TRANSACTIONS_FILTER.LIVESTOCK)} View`
      : typeof isLivestock !== 'undefined'
      ? `${formatCapitalize(TRANSACTIONS_FILTER.MERCHANDISE)} View`
      : `${account_type} View`;

    segment?.track(trackEvent.ACCOUNT_SELECTED, {
      account_id: accountId,
      account_type,
      display_account_view: accountViewed,
    });

    editSelectedAccount({
      account_id: accountId,
      account_type,
      account_description,
    });

    const navParams = {
      type: account_type,
      id: accountId,
      customerId,
    };

    if (hasLivestock) {
      navigate(
        routeConfig.AccountWithFilter.getNavigateProps({
          ...navParams,
          transactionsFilter: isLivestock
            ? TRANSACTIONS_FILTER.LIVESTOCK
            : TRANSACTIONS_FILTER.MERCHANDISE,
        })
      );
    } else {
      navigate(routeConfig.Account.getNavigateProps(navParams));
    }
  };

  return (
    <QueryCard error={error} loading={loading} handleReload={handleReload}>
      <>
        {accountWithFinance && accountWithFinance.accountData ? (
          <AccountOverviewCard heading={accountOverviewHeading}>
            <Box
              marginTop={2}
              key={accountId}
              testID={`account-${accountWithFinance.accountData?.account_type}`}
            >
              <AccountSummary
                tileType={accountWithFinance.accountView.tileType}
                accountId={accountWithFinance.accountData.account_id_trimmed}
                summary={
                  accountsSummary?.find((summary) =>
                    summary?.AccountNumber
                      ? parseInt(accountId, 10) ===
                        Number(summary?.AccountNumber)
                      : false
                  ) ?? null
                }
                handleClickViewDetails={(param) => {
                  handleClickViewDetails({
                    account_type: accountWithFinance.accountData
                      ?.account_type as AccountType,
                    account_description: accountDescription,
                    isLivestock: param?.isLivestock,
                    hasLivestock: param?.hasLivestock,
                  });
                }}
              />
            </Box>
          </AccountOverviewCard>
        ) : null}
      </>
    </QueryCard>
  );
};

export default NewAccountOverview;
