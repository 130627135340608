import React from 'react';
import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { Button } from '../Button';
import Logo from '../SVG/Logo';

const Footer = () => {
  const { isHandset } = useDeviceType();
  const theme = useTheme();
  const footerHeight = isHandset ? 200 : 168;

  const styles = {
    buttonTermsOfUse: {
      marginRight: theme.spacing(1),
    },
    copywright: {
      fontSize: theme.typography.fontSize.medium,
    },
  };

  const termsOfUseURL =
    'https://www.nutrienagsolutions.com.au/sites/default/files/2021-08/documents/Website%20Terms%20of%20use.pdf';

  const privacyPolicyUrl =
    'https://www.nutrienagsolutions.com.au/sites/default/files/2020-03/documents/Nutrien%20Privacy%20Policy%20New%2020200227_final2.pdf';

  return (
    <Box
      height={footerHeight}
      justifyContent="center"
      alignItems="center"
      borderTopWidth={1}
      borderTopColor={theme.auColors.neutral[300]}
      testID="customer-self-registration-footer"
    >
      <Box
        width="100%"
        height="100%"
        maxWidth="990"
        paddingY={4}
        paddingX={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          flexDirection={isHandset ? 'column' : 'row'}
          alignItems={isHandset ? 'flex-start' : 'center'}
          justifyContent="space-between"
          width="100%"
          mb={2}
        >
          <Box mb={isHandset ? 2 : 0}>
            <Logo width={100} id="footerLogo" />
          </Box>
          <Box
            flexDirection="row"
            ml={isHandset ? -1 : 0}
            mr={isHandset ? 0 : -1}
          >
            <Button
              type="clear"
              onPress={() => {
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                window.open(termsOfUseURL, '_blank');
              }}
              title="Terms Of Use"
              containerStyle={styles.buttonTermsOfUse}
              testID="terms-of-use"
              accessibilityLabel="Terms Of Use Button"
            />
            <Button
              type="clear"
              onPress={() => {
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                window.open(privacyPolicyUrl, '_blank');
              }}
              title="Privacy Policy"
              testID="privacy-policy"
              accessibilityLabel="Privacy Policy Button"
            />
          </Box>
        </Box>
        <Box
          width="100%"
          alignItems={isHandset ? 'flex-start' : 'flex-end'}
          justifyContent="center"
        >
          <Text style={styles.copywright}>
            &copy; 2022 Nutrien Ag Solutions Limited
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
