import {
  ApolloError,
  gql,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';
import {
  GetLotDetails,
  GetLotDetailsVariables,
} from 'types/generated/GetLotDetails';

export const GET_LOT_DETAILS = gql`
  query GetLotDetails($accountNumber: String!, $documentNumbers: [String!]) {
    getLotDetails(
      payload: {
        accountNumber: $accountNumber
        documentNumbers: $documentNumbers
      }
    ) {
      documentNumber
      result {
        LotDetails {
          Category
          Fee
          GSTAmount
          GroupTitle
          LotAmountExcGST
          LotId
          LotNumber
          LotType
          PerHeadRateExcGST
          PerHeadRateIncGST
          PerKgRateExcGST
          PerKgRateIncGST
          TotalHeads
          TotalWeight
        }
        LotFeeDetails {
          Category
          Fee
          FeeAmountExcGST
          FeeCode
          GSTOnFee
          GroupTitle
        }
      }
      success
      error
    }
  }
`;

const useGetLotDetailsLazyQuery = (): [
  (
    options: QueryLazyOptions<GetLotDetailsVariables>
  ) => Promise<LazyQueryResult<GetLotDetails, OperationVariables>>,
  GetLotDetails | undefined,
  boolean,
  ApolloError | undefined
] => {
  const [getLotDetails, { data, loading, error }] = useLazyQuery<
    GetLotDetails,
    GetLotDetailsVariables
  >(GET_LOT_DETAILS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  return [getLotDetails, data, loading, error];
};

export default useGetLotDetailsLazyQuery;
