import {
  Box,
  Icon,
  NamedStyles,
  Text,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary, useFormatters } from '../../../hooks';
import useDrawer from '../../../hooks/useDrawer';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from 'types/generated/CustomerAccountsSummarySplitUp';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import { Button } from '../../Button';
import SummaryCard from '../../SummaryCard';
import { DrawerLink } from '../DrawerLink';
import PanelDrawer from '../PanelDrawer/PanelDrawer';

export interface LivestockOverduePanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp?: AccountSummarySplitUpData | null;
  sharedStyles?: NamedStyles<Record<string, undefined>>;
}

const LivestockOverduePanel: FC<LivestockOverduePanelProps> = ({
  summary,
  summarySplitUp,
  sharedStyles,
}) => {
  const { overdue, showMoreLinks } = useAccountSummary(summary, true);
  const { renderNoneIfZero } = useFormatters();
  const theme = useTheme();
  const styles = useStyles(sharedStyles ?? {});
  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      <SummaryCard
        heading="Livestock overdue invoices"
        summaryRow={[
          {
            label: (
              <Box
                style={styles.label}
                flexDirection="row"
                alignItems="flex-start"
              >
                <Icon
                  name="error-outline"
                  color={theme.auColors.functional.error.dark}
                  size={24}
                  style={styles.icon}
                />
                <Text style={styles.errorBold}>Overdue invoices</Text>
              </Box>
            ),
            content: (
              <Text style={styles.overdueAmountEmphasis} bodyBold>
                {renderNoneIfZero(overdue.amount)}
              </Text>
            ),
            description: (
              <Box marginTop={0.5}>
                <Text style={styles.error}>incurring interest</Text>
              </Box>
            ),
            testID: 'overdue-column',
          },
        ]}
        footer={
          showMoreLinks ? (
            <Box
              flexDirection={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <DrawerLink
                enabled={showMoreLinks}
                drawerInfo={{
                  name: 'Overdue invoices',
                  data: { amount: overdue.amount, summarySplitUp },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: 'Livestock',
                }}
              />
              <Button
                containerStyle={{
                  marginLeft: 32,
                }}
                disabled={true}
                title="Pay now"
              />
            </Box>
          ) : undefined
        }
        testID="account-details-livestock-overdue-panel"
      />
      <PanelDrawer
        onClose={(closeMethod: string) =>
          onDrawerClose(closeMethod, {
            tab_view: 'Livestock',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};

export default LivestockOverduePanel;
