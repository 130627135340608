import { ApolloError, gql, useQuery } from '@apollo/client';
import {
  CustomerAccountsSummary,
  CustomerAccountsSummaryVariables,
} from 'types/generated/CustomerAccountsSummary';
import useImperativeQuery from './useImperativeQuery';

export const CUSTOMER_ACCOUNTS_SUMMARY = gql`
  query CustomerAccountsSummary(
    $accountSummaryPayloads: [GetAccountSummaryPayload!]
  ) {
    getAccountsSummary(payload: { SearchTerms: $accountSummaryPayloads }) {
      AccountNumber
      CurrentBalance
      RewardAmount
      TransactionAmount
      RuralcoAccNumber
      MerchandiseOverDue
      MerchandiseCurrentDue
      LivestockOverDue
      LivestockCurrentDue
      ProceedsPending
      ProceedsOverDue
      UnallocatedPayments
      ExtendedTermAmount
      AccountType
      PaymentAdvice
      InterestRate
      CreditLimit
      MerchInterestBearingCurrent
      MerchNonInterestBearingCurrent
      LivestockInterestBearingCurrent
      LivestockNonInterestBearingCurrent
      __typename
    }
  }
`;

const useCustomerAccountsSummaryQuery = ({
  account_ids,
  skip,
  onCompleted,
}: {
  account_ids: string[];
  skip: boolean;
  onCompleted?: (data: CustomerAccountsSummary) => void;
}): [
  CustomerAccountsSummary | undefined,
  boolean,
  ApolloError | undefined,
  () => void
] => {
  const { loading, error, data, refetch } = useQuery(
    CUSTOMER_ACCOUNTS_SUMMARY,
    {
      skip,
      variables: {
        accountSummaryPayloads: account_ids.map((account_id) => ({
          AccountNumber: account_id,
          CompanyCode: '8200',
        })),
      },
      onCompleted,
    }
  );

  return [data, loading, error, refetch];
};

const useImperativeCustomerAccountsSummaryQuery = () => {
  return useImperativeQuery<
    CustomerAccountsSummary,
    CustomerAccountsSummaryVariables
  >(CUSTOMER_ACCOUNTS_SUMMARY);
};

export {
  useCustomerAccountsSummaryQuery,
  useImperativeCustomerAccountsSummaryQuery,
};

export default useCustomerAccountsSummaryQuery;
