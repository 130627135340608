import { Box, Text, useStyles, useDeviceType } from '@nutrien/bonsai-core';
import React from 'react';
import {
  GetLotDetails_getLotDetails_result_LotDetails as LotDetailsType,
  GetLotDetails_getLotDetails_result_LotFeeDetails as LotFeeDetailsType,
} from 'types/generated/GetLotDetails';
import useFormatters from '../../hooks/useFormatters';

export interface TransactionSubItemProps {
  subItem: LotDetailsType | LotFeeDetailsType;
}

const TransactionSubItem = ({
  subItem,
}: TransactionSubItemProps): JSX.Element => {
  const { GroupTitle, Category, Fee } = subItem;
  const category =
    Category && 'LotNumber' in subItem
      ? `${Category} from Lot ${subItem.LotNumber}`
      : null;
  const { formatCurrency } = useFormatters();
  const { isDesktop } = useDeviceType();
  const styles = useStyles((theme) => ({
    row: {
      borderLeftColor: theme.auColors.accent.default,
      borderLeftWidth: 1,
      marginLeft: isDesktop ? theme.spacing(1.5) : 0,
    },
    description: {
      fontSize: theme.typography.fontSize.small,
      fontWeight: '600',
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
    category: {
      color: theme.auColors.neutral[600],
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
    price: {
      textAlign: 'right',
      fontSize: theme.typography.fontSize.small,
      fontWeight: '600',
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
  }));

  return (
    <Box flexDirection="row" paddingY={1} style={styles.row}>
      <Box
        width={isDesktop ? '80%' : '70%'}
        flexDir={isDesktop ? 'row' : 'column'}
        pl={2}
      >
        <Box width={isDesktop ? '61%' : '100%'}>
          <Text style={styles.description}>{GroupTitle}</Text>
        </Box>
        <Box width={isDesktop ? '39%' : '100%'}>
          <Text style={styles.category}>{category ?? ''}</Text>
        </Box>
      </Box>
      <Box width={isDesktop ? '20%' : '30%'}>
        <Text style={styles.price}>{`${formatCurrency(Number(Fee))}`}</Text>
      </Box>
    </Box>
  );
};
export default TransactionSubItem;
