import React, { FC } from 'react';

type props = {
  width: number | string;
  maxWidth?: string;
};

const Warning: FC<props> = ({ width, maxWidth }) => {
  return (
    <svg
      viewBox="0 0 360 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="auto"
      width={width}
      style={{ maxWidth }}
    >
      <path
        fill="#E9FFC2"
        fillRule="evenodd"
        d="M129.42 149.452h110.823c5.162 0 9.346-4.185 9.346-9.347 0-5.163-4.184-9.348-9.346-9.348 0 0-8.011-4.185-8.011-9.348 0-5.162 5.276-9.347 11.784-9.347h13.585c5.162 0 9.346-4.185 9.346-9.348 0-5.163-4.184-9.348-9.346-9.348h-29.375c5.162 0 9.346-4.185 9.346-9.347 0-5.163-4.184-9.348-9.346-9.348h53.408a9.347 9.347 0 009.347-9.348 9.347 9.347 0 00-9.347-9.347H150.783c-5.162 0-9.346 4.185-9.346 9.347 0 5.163 4.184 9.348 9.346 9.348H97.375c-5.162 0-9.347 4.185-9.347 9.348 0 5.162 4.185 9.347 9.347 9.347h33.38a9.347 9.347 0 019.347 9.348 9.347 9.347 0 01-9.347 9.348H77.347A9.347 9.347 0 0068 121.409c0 5.163 4.185 9.348 9.347 9.348h52.073a9.347 9.347 0 00-9.347 9.348 9.347 9.347 0 009.347 9.347zm142.868-46.738a9.347 9.347 0 1018.693 0 9.347 9.347 0 10-18.693 0z"
        clipRule="evenodd"
      />
      <path
        fill="#465F70"
        fillRule="evenodd"
        d="M206.511 27.189c-2.986 0-5.407 2.407-5.407 5.376 0 2.97 2.421 5.377 5.407 5.377s5.407-2.407 5.407-5.377c0-2.97-2.421-5.376-5.407-5.376zm0 2.688a2.696 2.696 0 012.703 2.688 2.696 2.696 0 01-2.703 2.689 2.697 2.697 0 01-2.704-2.689 2.696 2.696 0 012.704-2.688zm11.543 15.838l.138.122 4.54 4.515 4.54-4.515a1.697 1.697 0 012.39 0c.616.613.657 1.58.123 2.24l-.123.137-4.541 4.514 4.369 4.345c.66.656.66 1.72 0 2.376a1.696 1.696 0 01-2.252.122l-.138-.122-4.368-4.344-4.369 4.344a1.696 1.696 0 01-2.389 0 1.673 1.673 0 01-.123-2.24l.123-.136 4.368-4.345-4.54-4.514a1.673 1.673 0 010-2.377 1.698 1.698 0 012.106-.227l.146.105zm30.361 12.39c2.24 0 4.056-1.805 4.056-4.032 0-2.228-1.816-4.033-4.056-4.033-2.239 0-4.055 1.805-4.055 4.032 0 2.228 1.816 4.033 4.055 4.033zm-140.582 4.033c2.24 0 4.055-1.805 4.055-4.032 0-2.228-1.815-4.033-4.055-4.033-2.24 0-4.055 1.805-4.055 4.033 0 2.227 1.815 4.032 4.055 4.032zm-17.184 7.678l-.137-.122-.147-.105a1.698 1.698 0 00-2.105.227c-.66.657-.66 1.72 0 2.377l2.86 2.844-2.86 2.845-.124.136a1.674 1.674 0 00.124 2.24c.66.656 1.73.656 2.39 0l2.86-2.844 2.86 2.844.137.123c.664.53 1.637.49 2.253-.123.66-.656.66-1.72 0-2.376l-2.862-2.845 2.862-2.844.123-.137a1.674 1.674 0 00-.123-2.24 1.696 1.696 0 00-2.39 0l-2.86 2.846-2.86-2.846zm46.438 87.591l-.137-.122-.147-.105a1.697 1.697 0 00-2.106.227c-.66.656-.66 1.72 0 2.376l4.54 4.515-4.368 4.344-.123.137a1.673 1.673 0 00.123 2.24c.66.656 1.73.656 2.39 0l4.368-4.344 4.369 4.344.137.122c.663.531 1.636.49 2.252-.122.66-.657.66-1.72 0-2.377l-4.368-4.344 4.54-4.515.123-.136a1.673 1.673 0 00-.123-2.24 1.697 1.697 0 00-2.39 0l-4.54 4.515-4.54-4.515zm28.871 18.989c0 2.227-1.815 4.033-4.055 4.033-2.24 0-4.055-1.806-4.055-4.033 0-2.227 1.815-4.032 4.055-4.032 2.24 0 4.055 1.805 4.055 4.032z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke="#465F70"
        strokeLinejoin="round"
        strokeWidth="5.5"
        d="M171.744 42.906c1.953-3.169 6.559-3.169 8.512 0l59.461 96.471c2.053 3.331-.343 7.623-4.256 7.623H116.539c-3.913 0-6.309-4.292-4.256-7.623l59.461-96.471z"
      />
      <path
        fill="#CAD6DE"
        fillRule="evenodd"
        d="M172.464 60.672a2.75 2.75 0 01.864 3.791l-3.333 5.303a2.75 2.75 0 01-4.657-2.926l3.334-5.304a2.75 2.75 0 013.792-.864zm-11.334 18.03a2.75 2.75 0 01.865 3.792L155.328 93.1a2.75 2.75 0 11-4.656-2.927l6.666-10.606a2.75 2.75 0 013.792-.865zm-14.666 23.333a2.75 2.75 0 01.864 3.792l-6.666 10.606a2.75 2.75 0 01-4.657-2.927l6.667-10.606a2.75 2.75 0 013.792-.865z"
        clipRule="evenodd"
      />
      <path
        stroke="#465F70"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.5"
        d="M176 115V77"
      />
      <circle cx="176" cy="126" r="4" fill="#465F70" />
      <path
        fill="#88D600"
        d="M253.219 92.364c1.728 0 3.129 1.445 3.129 3.228 0 1.694-1.264 3.084-2.872 3.218l-.257.01h-12.513c-1.728 0-3.128-1.444-3.128-3.228 0-1.693 1.264-3.083 2.871-3.217l.257-.011h12.513zM264.839 77.605c1.728 0 3.128 1.445 3.128 3.228 0 1.694-1.264 3.083-2.871 3.218l-.257.01h-30.704c-1.728 0-3.128-1.445-3.128-3.228 0-1.694 1.264-3.083 2.872-3.218l.256-.01h30.704zM276.459 75.76c1.728 0 3.128 1.446 3.128 3.229 0 1.694-1.264 3.083-2.871 3.218l-.257.01h-3.575c-1.728 0-3.129-1.445-3.129-3.228 0-1.694 1.264-3.083 2.872-3.218l.257-.01h3.575zM100.723 133.988c1.684 0 3.05 1.419 3.05 3.17 0 1.664-1.233 3.028-2.8 3.161l-.25.01H85.037c-1.684 0-3.05-1.419-3.05-3.171 0-1.663 1.233-3.028 2.8-3.16l.25-.01h15.686zM91.09 114.963c1.683 0 3.05 1.42 3.05 3.171 0 1.663-1.233 3.028-2.8 3.16l-.25.011H59.49c-1.684 0-3.05-1.42-3.05-3.171 0-1.664 1.233-3.028 2.8-3.161l.25-.01H91.09zM50.878 114.963c1.685 0 3.05 1.42 3.05 3.171 0 1.663-1.232 3.028-2.8 3.16l-.25.011H42.05c-1.684 0-3.05-1.42-3.05-3.171 0-1.664 1.232-3.028 2.8-3.161l.25-.01h8.828z"
      />
    </svg>
  );
};

export default Warning;
