import React from 'react';
import { TextStyle } from 'react-native';
import { Text } from '@nutrien/bonsai-core';
import * as Yup from 'yup';

// field error
const requiredErrorMessage = 'This field is required';
const mobileFormatErrorMessage = 'Please enter a 10 digit Australian mobile';
const emailFormatErrorMessage = 'Please enter a valid email address';
const onboardedBranchErrorMessage = 'Please select a valid branch';

const userDetailsFormValidationSchema = Yup.object().shape({
  givenName: Yup.string().required(requiredErrorMessage),
  familyName: Yup.string().required(requiredErrorMessage),
  mobileNumber: Yup.string()
    .required(requiredErrorMessage)
    .matches(/^04[0-9]{8}$/, mobileFormatErrorMessage),
  emailAddress: Yup.string()
    .required(requiredErrorMessage)
    .matches(/^.+@.+\..+$/, emailFormatErrorMessage),
  onboardedBranch: Yup.string().required(onboardedBranchErrorMessage),
});

const userDetailsEditFormValidationSchema = Yup.object().shape({
  givenName: Yup.string().required(requiredErrorMessage),
  familyName: Yup.string().required(requiredErrorMessage),
  mobileNumber: Yup.string()
    .required(requiredErrorMessage)
    .matches(/^04[0-9]{8}$/, mobileFormatErrorMessage),
  emailAddress: Yup.string()
    .required(requiredErrorMessage)
    .matches(/^.+@.+\..+$/, emailFormatErrorMessage),
  onboardedBranch: Yup.string().optional().nullable(),
});

// error summary
const errorSummaryFieldNameStyle: TextStyle = { fontWeight: '600' };

const errorSummaryMessage = {
  [requiredErrorMessage]: (fieldName: string) => (
    <Text>
      <Text style={errorSummaryFieldNameStyle}>{fieldName}</Text> - this field
      is required
    </Text>
  ),
  [emailFormatErrorMessage]: (fieldName: string) => (
    <Text>
      <Text style={errorSummaryFieldNameStyle}>{fieldName}</Text>- please enter
      a valid email address
    </Text>
  ),
  [mobileFormatErrorMessage]: (fieldName: string) => (
    <Text>
      <Text style={errorSummaryFieldNameStyle}>{fieldName}</Text>- please enter
      a 10 digit Australian mobile
    </Text>
  ),
  [onboardedBranchErrorMessage]: (fieldName: string) => (
    <Text>
      <Text style={errorSummaryFieldNameStyle}>{fieldName}</Text> - please
      select a valid branch
    </Text>
  ),
};

export {
  userDetailsFormValidationSchema,
  userDetailsEditFormValidationSchema,
  errorSummaryMessage,
};
