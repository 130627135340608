import { useReactiveVar } from '@apollo/client';
import { format } from 'date-fns';
import sub from 'date-fns/sub';
import csvDownload from 'json-to-csv-export';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { BannerType } from '../components/Banner';
import { Transaction } from '../components/Transaction/transactions.types';
import { TxSortOptions } from '../constants';
import { trackEvent } from '../constants/segment';
import { useGetLotDetailsLazyQuery, useSegment } from '../hooks';
import { sortTransactions } from '../hooks/useTransactions/transaction.helper';
import {
  csvFormatTransactions,
  useLotDetailFormatter,
} from '../hooks/useTransactions/useTransactionFormatter';
import {
  accountTransactionsMutations,
  accountTransactionsVar,
  selectedAccountVar,
} from '../reactiveVariables';
import { LotDetailsList } from '../reactiveVariables/variables/accountTransactionsVar';
import { DocumentAccountType } from '../types/generated/globalTypes';
import { Sentry } from '../utils/sentry';

interface DateObject {
  date?: Date;
  startDate?: Date;
  endDate?: Date;
}

interface RangeObject {
  toDate?: Date;
  fromDate?: Date;
}

interface State {
  toDate: Date;
  fromDate: Date;
  queryTypeIndex: number;
  csvExportStatus: BannerType | undefined;
  csvDataLoading: boolean;
}

type SetFromDate = {
  type: 'setFromDate';
  value: DateObject;
};

type SetToDate = {
  type: 'setToDate';
  value: DateObject;
};

type SetRange = {
  type: 'setDateRange';
  value: RangeObject;
};

type SetQueryTypeIndex = {
  type: 'setQueryTypeIndex';
  value: number;
};

type ResetFilters = {
  type: 'resetFilters';
  value: RangeObject;
};

type SetCSVExportStatus = {
  type: 'setCSVExportStatus';
  value: BannerType | undefined;
};

type SetCSVDataLoading = {
  type: 'setCSVDataLoading';
  value: boolean;
};

type Actions =
  | SetFromDate
  | SetToDate
  | SetRange
  | SetQueryTypeIndex
  | ResetFilters
  | SetCSVExportStatus
  | SetCSVDataLoading;

interface TransactionProviderValue {
  state: State;
  setToDate: (value: DateObject) => void;
  setFromDate: (value: DateObject) => void;
  setDateRange: (value: RangeObject) => void;
  setQueryTypeIndex: (value: number) => void;
  resetFilters: () => void;
  setCSVExportStatus: (value: BannerType | undefined) => void;
  setCSVDataLoading: (value: boolean) => void;
  exportCSV: () => void;
}

type TransactionProviderProps = {
  children: ReactNode;
};

const TransactionContext = createContext<TransactionProviderValue | undefined>(
  undefined
);

function transactionReducer(state: State, action: Actions) {
  switch (action.type) {
    case 'setToDate': {
      return { ...state, toDate: action.value.date };
    }
    case 'setFromDate': {
      return { ...state, fromDate: action.value.date };
    }
    case 'resetFilters':
    case 'setDateRange': {
      return {
        ...state,
        toDate: action.value.toDate,
        fromDate: action.value.fromDate,
      };
    }
    case 'setQueryTypeIndex': {
      return {
        ...state,
        queryTypeIndex: action.value,
      };
    }
    case 'setCSVExportStatus': {
      return {
        ...state,
        csvExportStatus: action.value,
      };
    }
    case 'setCSVDataLoading': {
      return {
        ...state,
        csvDataLoading: action.value,
      };
    }
    default: {
      throw new Error(`Unhandled action type ${(action as Actions).type}`);
    }
  }
}

function TransactionProvider({
  children,
}: TransactionProviderProps): JSX.Element {
  const now = new Date();
  const resetState = {
    toDate: now,
    fromDate: sub(now, { years: 1 }),
    queryTypeIndex: 0,
    accountNumber: '',
    sortValue: TxSortOptions.ISSUED_DESC,
    csvExportStatus: undefined,
    csvDataLoading: false,
  };
  const [state, dispatch] = useReducer(
    transactionReducer as React.Reducer<State, Actions>,
    resetState
  );

  const setToDate = (value: DateObject) => {
    dispatch({ type: 'setToDate', value });
  };

  const setFromDate = (value: DateObject) => {
    dispatch({ type: 'setFromDate', value });
  };

  const setDateRange = (value: RangeObject) => {
    dispatch({ type: 'setDateRange', value });
  };

  const setQueryTypeIndex = (value: number) => {
    dispatch({ type: 'setQueryTypeIndex', value });
  };

  const resetFilters = () => {
    dispatch({ type: 'resetFilters', value: resetState });
  };

  const setCSVExportStatus = (value: BannerType | undefined) => {
    dispatch({ type: 'setCSVExportStatus', value });
  };

  const setCSVDataLoading = (value: boolean) => {
    dispatch({ type: 'setCSVDataLoading', value });
  };

  const segment = useSegment();
  const flags = useFlags();
  const isLotDetailsFeatureOn = flags['NG-3135-plasma-lot-details'];

  const { account_id, account_type, account_description, account_name } =
    useReactiveVar(selectedAccountVar);
  const {
    itemList = [],
    lotDetailsList = {},
    groupedTransactions,
  } = useReactiveVar(accountTransactionsVar)[account_id as string] ?? {};

  const { editLotDetailsList } = accountTransactionsMutations;

  const [getLotDetails] = useGetLotDetailsLazyQuery();
  // Format lot details to be able to export in CSV
  const formatLotDetails = useLotDetailFormatter();

  const filterPlasmaRecord = ({
    documentAccountType,
    hasPdf,
  }: Transaction): boolean => {
    return (
      documentAccountType === DocumentAccountType.PLASMA && hasPdf === true
    );
  };

  const getDocumentNumbers = () =>
    itemList.filter(filterPlasmaRecord).reduce((documentIds, item) => {
      if (item.documentId !== null) {
        if (
          !Object.keys(lotDetailsList).includes(item.documentId) ||
          lotDetailsList?.[item.documentId]?.lotDetailsResponse?.success ===
            false
        ) {
          return [...documentIds, item.documentId];
        }
      }
      return documentIds;
    }, [] as string[]);

  const exportCSV = async () => {
    const formattedDate = {
      fromDate: format(state.fromDate, 'yyyy-MM-dd'),
      toDate: format(state.toDate, 'yyyy-MM-dd'),
    };
    const segmentProperties = {
      account_id,
      account_type,
      display_account_type: account_description,
      date_from: formattedDate.fromDate,
      date_to: formattedDate.toDate,
      document_type: 'Invoice',
      export_transaction_count: itemList.length,
      document_count: groupedTransactions
        ? [...groupedTransactions.values()].filter(
            (item) => item.hasPdf === true
          ).length
        : undefined,
    };

    try {
      setCSVDataLoading(true);
      const exportingAccountId = account_id;
      const sortedTransactionList = sortTransactions(
        TxSortOptions.ISSUED_DESC,
        itemList
      );
      // Get a copy of exist transactions and lot details list from reactive variable to
      // enable non block UI
      let exportingLotDetails: LotDetailsList = { ...lotDetailsList };

      const documentNumbers = getDocumentNumbers();

      if (documentNumbers.length > 0) {
        const lotDetailsResponse = await getLotDetails({
          variables: {
            accountNumber: exportingAccountId,
            documentNumbers,
          },
        });

        lotDetailsResponse?.data?.getLotDetails?.forEach((lotDetails) => {
          if (lotDetails.documentNumber) {
            editLotDetailsList(account_id, lotDetails.documentNumber, {
              lotDetailsResponse: lotDetails,
            }); // Update reactive variable(local state)
            exportingLotDetails = {
              ...exportingLotDetails,
              [lotDetails.documentNumber]: {
                ...exportingLotDetails?.[lotDetails.documentNumber],
                ...{ lotDetailsResponse: lotDetails },
              },
            };
          }
        });
      }

      const csvData = csvFormatTransactions(sortedTransactionList, {
        formatter: formatLotDetails,
        isLotDetailsFeatureOn,
        lotDetailsList: exportingLotDetails,
        sapAccountName: account_name,
      });

      const today = format(new Date(), 'yyyyMMdd-HHmm');
      const filename = `Nutrien Transactions ${exportingAccountId}-${today}.csv`;

      csvDownload(csvData, filename);
      setCSVExportStatus('success');

      segment?.track(trackEvent.EXPORT_TO_CSV_SELECTED, {
        ...segmentProperties,
        export_line_count: csvData.length,
        download_successful: true,
      });
    } catch (err) {
      setCSVExportStatus('error');
      segment?.track(trackEvent.EXPORT_TO_CSV_SELECTED, {
        ...segmentProperties,
        download_successful: false,
      });
      Sentry.captureException(new Error(`Error exporting CSV file: ${err}`));
    } finally {
      setCSVDataLoading(false);
    }
  };

  const value = {
    state,
    setToDate,
    setFromDate,
    setDateRange,
    setQueryTypeIndex,
    resetFilters,
    setCSVExportStatus,
    setCSVDataLoading,
    exportCSV,
  };

  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  );
}

function useTransaction(): TransactionProviderValue {
  const context = useContext(TransactionContext);

  if (context === undefined) {
    throw new Error('useTransaction must be used within a TransactionProvider');
  }

  return context;
}

export { TransactionProvider, useTransaction };
