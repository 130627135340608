import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import {
  useCustomerProfileByCustomerIdQuery,
  usePersonDetailsByIdQuery,
  RefetchByCustomerId,
  RefetchByPersonId,
} from '../../hooks';
import { useStaff } from '../../context/StaffContext';
import { CustomerProfileByCustomerId_customer_profile } from 'types/generated/CustomerProfileByCustomerId';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details } from '../../types/generated/PartyGetPersonDetailsWithAccountsById';

export interface CustomerProfileByCustomerId {
  customer:
    | CustomerProfileByCustomerId_customer_profile
    | PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details
    | undefined;
  customerAccounts: string[];
  customerProfileByCustomerIdLoading: boolean;
  customerProfileByCustomerIdError: ApolloError | undefined;
  customerProfileByCustomerIdRefetch: RefetchByCustomerId | RefetchByPersonId;
}

const useCustomerProfileByCustomerId = (
  customerId: string
): CustomerProfileByCustomerId => {
  const {
    state: { selectedCustomer },
    dispatch,
  } = useStaff();
  const flags = useFlags();
  if (flags['NG-2644-party-model']) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [data, loading, error, refetch] = usePersonDetailsByIdQuery({
      customerId,
    });

    const customer =
      data && data.party_vw_get_person_details.length > 0
        ? data.party_vw_get_person_details[0]
        : undefined;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (customer && customer.party_id !== selectedCustomer?.customerId) {
        const {
          email,
          family_name: familyName,
          given_name: givenName,
          party_id,
          onboarded_by_branch_code: onboardedBranchCode,
        } = customer;
        const customerName = `${givenName || ''} ${familyName || ''}`;

        dispatch({
          type: 'setSelectedCustomer',
          value: {
            customerName: customerName.trim(),
            customerId: party_id,
            email: email || '',
            onboardedBranchCode: onboardedBranchCode,
            onboardedBranch: null,
          },
        });
      }
    }, [customer, dispatch, selectedCustomer]);

    const customerAccounts = customer
      ? customer.accounts.map(({ account_id }) => account_id || '')
      : [];

    return {
      customer,
      customerAccounts,
      customerProfileByCustomerIdLoading: loading,
      customerProfileByCustomerIdError: error,
      customerProfileByCustomerIdRefetch: refetch,
    };
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [data, loading, error, refetch] = useCustomerProfileByCustomerIdQuery(
      {
        customerId,
      }
    );

    const customer =
      data && data.customer_profile.length > 0
        ? data.customer_profile[0]
        : undefined;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (customer && customer.id !== selectedCustomer?.customerId) {
        const {
          email,
          family_name: familyName,
          given_name: givenName,
          id,
          onboarded_branch_code: onboardedBranchCode,
          onboarded_branch_info,
        } = customer;
        const customerName = `${givenName || ''} ${familyName || ''}`;

        dispatch({
          type: 'setSelectedCustomer',
          value: {
            customerName: customerName.trim(),
            customerId: id,
            email,
            onboardedBranchCode: onboardedBranchCode,
            onboardedBranch: onboarded_branch_info?.branch_name,
          },
        });
      }
    }, [customer, dispatch, selectedCustomer]);

    const customerAccounts = customer
      ? customer.customer_accounts.map(({ account_id }) => account_id)
      : [];

    return {
      customer,
      customerAccounts,
      customerProfileByCustomerIdLoading: loading,
      customerProfileByCustomerIdError: error,
      customerProfileByCustomerIdRefetch: refetch,
    };
  }
};

export default useCustomerProfileByCustomerId;
