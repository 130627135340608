import React from 'react';
import { Text } from '@nutrien/bonsai-core';

import Banner from '../Banner';
import { useRoutes } from '../../hooks';

interface GlobalAlertProps {
  showAlert: boolean;
  onCloseAlert: () => void;
  message: string;
  testID?: string;
}

const GlobalAlert = ({
  showAlert,
  onCloseAlert,
  message,
  testID,
}: GlobalAlertProps): JSX.Element | null => {
  const { routeName, pathName } = useRoutes();

  const shouldDisplay =
    ['Home', 'Accounts', 'Account', 'UserSummary'].includes(`${routeName}`) ||
    pathName === '/dashboard' ||
    pathName?.includes('/accounts') ||
    pathName?.includes('/customer-account') ||
    pathName?.includes('/user-summary');

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Banner
      location="global"
      type="error"
      visible={showAlert}
      testID={testID}
      onDismissPress={onCloseAlert}
      hideLeftBorder
    >
      <Text>{message}</Text>
    </Banner>
  );
};

export default GlobalAlert;
