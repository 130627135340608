import React from 'react';
import { Box, useStyles } from '@nutrien/bonsai-core';
import { TouchableOpacity } from 'react-native';

export interface TransactionWrapperProps {
  onPress?: () => void;
  children: JSX.Element;
  tableStyle: boolean;
  isLast: boolean;
  testId: string;
  isExpanded?: boolean;
}

export const TransactionWrapper = ({
  children,
  onPress,
  tableStyle,
  isLast = false,
  testId,
  isExpanded,
}: TransactionWrapperProps): JSX.Element => {
  const styles = useStyles((theme) => {
    return {
      touchable: {
        paddingHorizontal: theme.spacing(2),
        paddingVertical: theme.spacing(1),
        borderBottomColor: theme.auColors.neutral[500],
        borderBottomWidth: isLast ? 0 : 1,
      },
    };
  });
  return onPress ? (
    <TouchableOpacity
      onPress={onPress}
      style={tableStyle ? styles.touchable : {}}
      testID={testId}
      aria-expanded={isExpanded}
    >
      {children}
    </TouchableOpacity>
  ) : (
    <Box testID={testId}>{children}</Box>
  );
};
