import React, { useEffect, useState } from 'react';
import { Pressable, ViewStyle } from 'react-native';
import { Box, Icon, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { AccountType } from 'components/AccountOverview/useAccountOverview';
import { Button } from '../Button';
import IconTable from '../IconTableList/IconTableList';
import AccBoxInformationMobile from './AccBoxInformationMobile';
import AccountTableBalance from './AccountTableBalance';
import Banner from '../Banner';
import { useDelinkAccountsMutation, useFormatters } from '../../hooks';
import { ScreenType, AccountInformationPanelProps, TableData } from '.';
import DelinkConfirmationModal from './DelinkConfirmationModal';
import AddAccountPanel from './AddAccountPanel';
import { ColumnsDataProps } from '../IconTableList';
import AccountTypeRender from '../AccountTypeRender';
import { CustomerProfileByCustomerId_customer_profile } from '../../types/generated/CustomerProfileByCustomerId';
import { PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details } from '../../types/generated/PartyGetPersonDetailsWithAccountsById';

export const tableColumns = (isDesktop: boolean) => {
  return isDesktop
    ? [
        {
          Header: 'Account',
          accessor: 'account',
          minWidth: 10,
          maxWidth: 90,
        },
        {
          Header: 'Type',
          accessor: 'type',
          minWidth: 10,
          maxWidth: 150,
        },
        {
          Header: 'Name',
          accessor: 'name',
          minWidth: 10,
          maxWidth: 210,
        },
        {
          Header: 'Balance',
          accessor: 'balance',
          minWidth: 10,
          maxWidth: 140,
        },
        // This is to get accountId data rather than displaying, and have been added in hiddenColumns
        {
          accessor: 'accountId',
          maxWidth: 1,
        },
      ]
    : [
        {
          Header: 'Account',
          accessor: 'account',
          minWidth: 60,
          maxWidth: 280,
        },
        // This is to get accountId data rather than displaying, and have been added in hiddenColumns
        {
          accessor: 'accountId',
          maxWidth: 1,
        },
      ];
};

const AccountInformationPanel = ({
  customer,
  customerProfileByCustomerIdLoading,
  customerProfileByCustomerIdRefetch,
  handleClickUserSummary,
}: AccountInformationPanelProps): JSX.Element => {
  const theme = useTheme();
  const [showAddAccountPanel, setShowAddAccountPanel] = useState(false);
  const [isDelinkAccountSuccess, setIsDelinkAccountSuccess] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [showAccountInfoPanel, setShowAccountInfoPanel] = useState(true);
  const [isInsertCustomerAccountsSuccess, setIsInsertCustomerAccountsSuccess] =
    useState(false);

  const { isDesktop, isHandset } = useDeviceType();
  const { formatAccountId } = useFormatters();

  const [
    delinkAccounts,
    {
      data: delinkAccountsData,
      error: delinkAcountsError,
      loading: delinkAcountsLoading,
    },
  ] = useDelinkAccountsMutation();

  useEffect(() => {
    if (
      delinkAccountsData?.deleteCustomerAccounts &&
      delinkAccountsData?.deleteCustomerAccounts.length > 0
    ) {
      customerProfileByCustomerIdRefetch();
      setIsDelinkAccountSuccess(true);
      setIsInsertCustomerAccountsSuccess(false);
    }
  }, [delinkAccountsData, customerProfileByCustomerIdRefetch]);

  const styles = {
    card: {
      flex: 1,
    },
    header: {
      paddingLeft: theme.spacing(0),
      marginBottom: isHandset ? theme.spacing(1) : theme.spacing(3),
    },
    subHeading: {
      marginTop: isHandset ? theme.spacing(2) : theme.spacing(4),
      marginBottom: isHandset ? theme.spacing(3) : theme.spacing(2),
    },
    addAccountButtonStyle: {
      width: isHandset ? '100%' : 160,
      marginTop: isHandset ? theme.spacing(5) : theme.spacing(-2),
      left: 10,
      height: 40,
      borderColor: theme.auColors.neutral[500],
      backgroundColor: theme.auColors.neutral[200],
    },
    tableHeader: {
      borderBottomWidth: 1,
      borderBottomColor: theme.auColors.neutral[400],
      backgroundColor: theme.auColors.neutral[200],
    },
    row: {
      borderBottomWidth: 1,
      borderBottomColor: theme.auColors.neutral[500],
    },
    insertCustomerAccountsSuccessBanner: {
      color: theme.auColors.functional.success.dark,
    },
    viewProfileLink: {
      textDecorationLine: 'underline',
      color: theme.auColors.functional.success.dark,
    },
  };

  const buildTableData = (): TableData[] => {
    const isPersonDetails =
      customer?.__typename === 'party_vw_get_person_details';

    if (isPersonDetails) {
      return (
        (
          customer as PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details
        ).accounts.map((account): TableData => {
          const accType =
            account.sords_customer_account?.account_type?.toLowerCase() as AccountType;

          const accTypeProp = {
            accType: accType,
            accName: account.sords_customer_account?.name1 || '-',
          };

          return isDesktop
            ? {
                account:
                  account.sords_customer_account?.account_id_trimmed || '-',
                accountId: account.account_id || '',
                type: <AccountTypeRender {...accTypeProp} />,
                name: (
                  <Text
                    testID="accBoxInfo-name"
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {account.sords_customer_account?.name1 || '-'}
                  </Text>
                ),
                balance: <AccountTableBalance customer_account={account} />,
              }
            : {
                account: (
                  <AccBoxInformationMobile
                    account={
                      account.sords_customer_account?.account_id_trimmed || '-'
                    }
                    type={<AccountTypeRender {...accTypeProp} />}
                    name={account?.sords_customer_account?.name1}
                  />
                ),
                accountId: account.account_id || '',
              };
        }) ?? []
      );
    } else {
      return (
        (
          customer as CustomerProfileByCustomerId_customer_profile
        ).customer_accounts.map((account): TableData => {
          const accType =
            account.sords_customer_account?.account_type?.toLowerCase() as AccountType;

          const accTypeProp = {
            accType: accType,
            accName: account.sords_customer_account?.name1 || '-',
          };

          return isDesktop
            ? {
                account:
                  account.sords_customer_account?.account_id_trimmed || '-',
                accountId: account.account_id,
                type: <AccountTypeRender {...accTypeProp} />,
                name: (
                  <Text
                    testID="accBoxInfo-name"
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {account.sords_customer_account?.name1 || '-'}
                  </Text>
                ),
                balance: <AccountTableBalance customer_account={account} />,
              }
            : {
                account: (
                  <AccBoxInformationMobile
                    account={
                      account.sords_customer_account?.account_id_trimmed || '-'
                    }
                    type={<AccountTypeRender {...accTypeProp} />}
                    name={account?.sords_customer_account?.name1}
                  />
                ),
                accountId: account.account_id,
              };
        }) ?? []
      );
    }
  };

  const renderBanner = () => {
    const isVisible = !!(
      isDelinkAccountSuccess ||
      delinkAcountsError ||
      isInsertCustomerAccountsSuccess
    );

    const getType = () => {
      if (delinkAcountsError) {
        return 'error';
      }
      return 'success';
    };

    const getTestID = () => {
      if (isInsertCustomerAccountsSuccess) {
        return 'insert-customer-accounts-banner-success';
      }
      return `delink-account-banner-${
        delinkAcountsError ? 'error' : 'success'
      }`;
    };

    const getContent = () => {
      if (delinkAcountsError) {
        return 'An error occurred and your changes have not been saved.';
      }
      if (isDelinkAccountSuccess) {
        return 'Your changes have been saved.';
      }
      if (isInsertCustomerAccountsSuccess) {
        return (
          <Box>
            <Text bodyBold style={styles.insertCustomerAccountsSuccessBanner}>
              Accounts have been successfully linked
            </Text>
            <Pressable onPress={handleClickUserSummary}>
              <Text style={styles.viewProfileLink as ViewStyle}>
                View profile and send invite
              </Text>
            </Pressable>
          </Box>
        );
      }
    };

    return (
      <Banner
        location="inline"
        type={getType()}
        visible={isVisible}
        testID={getTestID()}
      >
        <Text>{getContent()}</Text>
      </Banner>
    );
  };

  const onCloseDelinkModal = () => setSelectedAccountId('');
  const onConfirmDelinkAccount = () => {
    setSelectedAccountId('');
    delinkAccounts({
      variables: {
        customerId:
          customer &&
          ('party_id' in customer ? customer.party_id : customer?.id),
        accountIds: [selectedAccountId],
      },
    });
  };

  const handleLinkAccountsSuccess = () => {
    setShowAddAccountPanel(false);
    setShowAccountInfoPanel(true);
    setIsDelinkAccountSuccess(false);
    customerProfileByCustomerIdRefetch();
    setIsInsertCustomerAccountsSuccess(true);
  };

  const accounts =
    customer &&
    ('customer_accounts' in customer
      ? customer.customer_accounts
      : customer?.accounts);
  return (
    <Box
      style={styles.card}
      pl={isDesktop ? '4' : '0'}
      pt={isDesktop ? '0' : '4'}
    >
      {showAccountInfoPanel && (
        <Box style={styles.card} p={4} background={theme.auColors.neutral[100]}>
          <Text h2 testID="account-user-heading" style={styles.header}>
            Account Information
          </Text>
          {renderBanner()}
          <>
            {accounts && accounts.length > 0 ? (
              <>
                <Text
                  h3
                  testID="linked-accounts-title"
                  style={styles.subHeading}
                >
                  Linked Accounts
                </Text>
                <IconTable
                  tableColumns={tableColumns(isDesktop)}
                  columnsData={buildTableData() as unknown as ColumnsDataProps}
                  checkable={false}
                  headerStyle={styles.tableHeader}
                  cellStyle={{ height: theme.spacing(1) }}
                  rowStyle={styles.row}
                  initialState={{ hiddenColumns: ['accountId'] }}
                  testID="icon-table-accounts-edit-user-id"
                  icon="delete"
                  onPressIcon={(item) => {
                    setSelectedAccountId(item.accountId as string);
                  }}
                  loading={
                    delinkAcountsLoading || customerProfileByCustomerIdLoading
                  }
                />
                <DelinkConfirmationModal
                  onConfirmDelinkAccount={onConfirmDelinkAccount}
                  onCloseDelinkModal={onCloseDelinkModal}
                  selectedAccountId={formatAccountId(selectedAccountId)}
                />
              </>
            ) : (
              <Box
                pt="4"
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={isHandset ? 'column-reverse' : 'row'}
              >
                <Text testID="no-linked-accounts" style={styles.subHeading}>
                  There are no linked accounts
                </Text>
              </Box>
            )}

            {ScreenType.edit && !showAddAccountPanel && (
              <Box
                position={isHandset ? 'relative' : 'absolute'}
                top={isHandset ? 0 : 6}
                right={isHandset ? 0 : 6}
              >
                <Button
                  type="outline"
                  title="Add account"
                  testID="add-account-button"
                  iconLeft={
                    <Icon
                      name="add"
                      testID="add-account-icon"
                      type="material"
                    />
                  }
                  buttonStyle={styles.addAccountButtonStyle}
                  onPress={() => {
                    setShowAddAccountPanel(true);
                    setShowAccountInfoPanel(isDesktop);
                  }}
                />
              </Box>
            )}
          </>
        </Box>
      )}
      {showAddAccountPanel && (
        <AddAccountPanel
          customer={customer}
          visibleStateFunction={setShowAddAccountPanel}
          informationVisibleStateFunc={setShowAccountInfoPanel}
          customerProfileByCustomerIdLoading={
            customerProfileByCustomerIdLoading
          }
          handleLinkAccountsSuccess={handleLinkAccountsSuccess}
        />
      )}
    </Box>
  );
};

export default AccountInformationPanel;
