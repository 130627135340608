import React from 'react';
import { View, Pressable, Platform, ViewStyle } from 'react-native';
import { Text, useStyles, Box } from '@nutrien/bonsai-core';
import { CustomerInfo, HandleRowPress } from '.';

interface CustomerTileProps extends CustomerInfo {
  handleRowPress: HandleRowPress;
}

const CustomerTile = ({
  'Given Name': firstName,
  'Family Name': lastName,
  'Email Address': email,
  'List of Accounts': accounts,
  customerId,
  handleRowPress,
}: CustomerTileProps): JSX.Element => {
  const styles = useStyles((theme) => {
    return {
      row: {
        borderBottomWidth: 1,
        borderBottomColor: theme.auColors.neutral[500],
      },
      name: {
        marginBottom: theme.spacing(1),
      },
      subTitle: {
        width: 70,
        fontSize: theme.typography.fontSize.small,
      },
      extraPaddingRight: {
        paddingRight: 30,
      },
      smallFontSize: {
        fontSize: theme.typography.fontSize.small,
      },
      container: {
        padding: theme.spacing(2),
      },
    };
  });

  return (
    <Pressable
      onPress={() => {
        handleRowPress(customerId as string);
      }}
      style={styles.row}
    >
      <View style={styles.container}>
        <Text style={[styles.name, styles.smallFontSize]} bodyBold>
          {firstName.value} {lastName.value}
        </Text>
        <Box display="flex" flexDirection="row" pr={1}>
          <Text style={[styles.subTitle, styles.extraPaddingRight]}>
            Email:
          </Text>
          <Text
            style={
              [
                styles.smallFontSize,
                Platform.OS === 'web'
                  ? // have different css for web and native as certain css can only be used in one OS
                    {
                      wordBreak: 'break-word',
                    }
                  : {
                      flex: 1,
                      flexWrap: 'wrap',
                    },
              ] as ViewStyle
            }
          >
            {email.value}
          </Text>
        </Box>
        <Box display="flex" flexDirection="row" mt={1} pr={1}>
          <Text style={styles.subTitle}>Accounts:</Text>
          <Text style={styles.smallFontSize}>{accounts}</Text>
        </Box>
      </View>
    </Pressable>
  );
};

export default CustomerTile;
