import { Box, Text, TextLink, useStyles } from '@nutrien/bonsai-core';
import React from 'react';
import { useFormatters } from '../../hooks';
import { useCustomerAccountSummaryQuery } from '../../hooks/gql/queries/useCustomerAccountsQuery';
import { CustomerProfileByCustomerId_customer_profile_customer_accounts } from '../../types/generated/CustomerProfileByCustomerId';
import QueryCard from '../QueryCard';
import { PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details_accounts } from '../../types/generated/PartyGetPersonDetailsWithAccountsById';

type props = {
  customer_account:
    | CustomerProfileByCustomerId_customer_profile_customer_accounts
    | PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details_accounts;
};

const AccountTableBalance = ({ customer_account }: props): JSX.Element => {
  const { account_id } = customer_account || '';
  const { formattedAmount } = useFormatters();

  const [customerData, loading, error, refetch] =
    useCustomerAccountSummaryQuery({
      account_id: account_id || '',
    });

  const balance = customer_account?.sords_customer_account?.account_finances
    ?.is_finance_account
    ? customer_account?.sords_customer_account?.account_finances
        ?.total_receivables
    : customerData?.getAccountSummary?.CurrentBalance;

  const styles = useStyles((theme) => ({
    containerStyle: {
      color: theme.auColors.primary.default,
      fontWeight: '600',
    },
  }));

  return (
    <QueryCard loading={loading} handleReload={() => refetch()} wrapper={Box}>
      {error ? (
        <Text testID={'account-table-data-error-text'}>
          <TextLink
            onPress={() => refetch()}
            containerStyle={styles.containerStyle}
          >
            Try Again
          </TextLink>
        </Text>
      ) : (
        <Box testID={`account-table-data-${account_id}`}>
          <Text>{formattedAmount(balance)}</Text>
        </Box>
      )}
    </QueryCard>
  );
};

export default AccountTableBalance;
