import React, { useEffect, useState } from 'react';
import { Icon, Box, Text, useDeviceType } from '@nutrien/bonsai-core';
import theme from '../../theme/theme';
import { routeConfig } from '../../routes/shared';
import { useNavigation, useSegment } from '../../hooks';
import { ViewStyle, Pressable } from 'react-native';
import { useStaff } from '../../context/StaffContext';
import { useReactiveVar } from '@apollo/client';
import {
  selectedAccountVar,
  breadCrumbVar,
  breadCrumbMutations,
} from '../../reactiveVariables';
import { trackEvent } from '../../constants/segment';

export interface Url {
  params?: Record<string, string> | undefined;
  name: string;
  path: string;
}
export interface BreadcrumbsItemProps {
  title: string;
  url?: Url;
}

const BreadCrumbItem = (item: BreadcrumbsItemProps) => {
  const { navigate } = useNavigation();
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const segment = useSegment();

  let segmentOriginBreadcrumb = '';
  let segmentDestBreadcrumb = '';

  const { account_id } = useReactiveVar(selectedAccountVar);
  const { breadCrumbPath } = useReactiveVar(breadCrumbVar);

  const {
    state: { hasChangesInForm },
    dispatch,
  } = useStaff();

  const itemColor = pressed
    ? theme.auColors.primary[200]
    : theme.auColors.primary.default;

  const styles = {
    defaultText: {
      fontFamily: theme.typography.fontFamily.semiBold,
    },
    text: {
      color: theme.auColors.neutral[600],
    },
    linkText: {
      color: itemColor,
    },
    link: {
      textDecorationLine: hovered ? 'none' : 'underline',
      outline: 'none',
    } as ViewStyle,
    boxShadow: {
      textDecorationLine: 'none',
      boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.dark}`,
      borderRadius: 4,
    },
    cursor: {
      cursor: 'text',
    },
  };

  if (item.url) {
    const { name, path, params } = item.url;

    const handleNavigation = () => {
      /**
       * We treat Home route differently to ensure Segment page call triggered correctly on
       * native when clicking Dashboard breadcrumbs.
       */

      if (hasChangesInForm) {
        dispatch({
          type: 'setNotificationModal',
          value: { showSaveModal: true, url: item.url },
        });
      } else {
        if (name === routeConfig.Home.name) {
          navigate({
            ...routeConfig.Home.getNavigateProps(),
            usePush: true,
          });
          segmentDestBreadcrumb = 'Dashboard->';

          breadCrumbPath.destBreadcrumb.forEach((title) => {
            return (segmentOriginBreadcrumb = `${segmentOriginBreadcrumb}${title}->`);
          });
        } else {
          navigate({ name, path, params });

          /**
           * SEGMENT TRACKING:
           * Given:
           * Users previous path: Home->Dashboard->User Summary->Account Details
           * Users next path: Home->Dashboard->User Summary
           * .
           * Since the originBreadcrumb will always be the path that the user navigated to (next path)
           * we allocate this path (Home->Dashboard->User Summary) to segmentDestBreadcrumb.
           * .
           * Since the destBreadcrumb will always be the previous path (because destBreadcrumb global variable has not yet been changed from useEffect()).
           * we allocate this path (Home->Dashboard->User Summary->Account Details) to segmentOriginBreadcrumb.
           */

          breadCrumbPath.originBreadcrumb.forEach((title) => {
            return (segmentDestBreadcrumb = `${segmentDestBreadcrumb}${title}->`);
          });

          breadCrumbPath.destBreadcrumb.forEach((title) => {
            return (segmentOriginBreadcrumb = `${segmentOriginBreadcrumb}${title}->`);
          });
        }
        segment?.track(trackEvent.BREADCRUMBS_SELECTED, {
          origin_breadcrumb: segmentOriginBreadcrumb.slice(0, -2),
          destination_breadcrumb: segmentDestBreadcrumb.slice(0, -2),
          account_id,
        });
      }
    };

    return (
      <Box mt={1} testID={`${item.title}-breadcrumb`}>
        <Pressable
          style={[styles.link, focused && styles.boxShadow]}
          onPressIn={() => setPressed(true)}
          onPressOut={() => setPressed(false)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - web only
          onHoverIn={() => setHovered(true)}
          onHoverOut={() => setHovered(false)}
          onPress={handleNavigation}
          accessibilityRole="button"
          accessibilityLabel={`Go to ${item.title}`}
        >
          <Box flexDirection="row" alignItems="center" pr={2}>
            <Icon name="chevron-left" color={itemColor} />
            <Text style={[styles.defaultText, styles.linkText]}>
              {item.title}
            </Text>
          </Box>
        </Pressable>
      </Box>
    );
  }

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      mr={1}
      mt={1}
      testID={`unclickable-${item.title}-breadcrumb`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - web only
      accessibilityCurrent="page"
      accessibilityLabel={item.title}
      style={styles.cursor as ViewStyle}
    >
      <Icon name="chevron-left" color={theme.auColors.neutral[600]} />
      <Text style={[styles.defaultText, styles.text]}>{item.title}</Text>
    </Box>
  );
};

const Breadcrumbs = ({
  items,
}: {
  containerStyle?: ViewStyle;
  items: (BreadcrumbsItemProps | null)[];
}): JSX.Element | null => {
  const filteredItems = items.filter(Boolean);
  const { isHandset } = useDeviceType();
  const BreadCrumbsContainerStyle = { flexWrap: 'wrap' };

  const { breadCrumbPath } = useReactiveVar(breadCrumbVar);
  const { editBreadCrumb } = breadCrumbMutations;

  useEffect(() => {
    let originPath: string[] = [];
    const destinationPath: string[] = [];

    /**
     * SEGMENT TRACKING:
     * Given:
     * Users previous path: Home->Dashboard->User Summary->Account Details
     * Users next path: Home->Dashboard->User Summary
     * .
     * We want to maintain the previous and next breadcrumb paths.
     * destBreadcrumb global variable will always be allocated with the previous path BEFORE changing to the next path
     * This is why we extract the destination path BEFORE it changes, and allocate it to originBreadcrumb
     * With this cycle, we will always have a previous and next breadcrumb path.
     */
    originPath = breadCrumbPath.destBreadcrumb;
    (breadcrumbsItems as BreadcrumbsItemProps[]).map(({ title }) =>
      destinationPath.push(title)
    );

    editBreadCrumb({
      breadCrumbPath: {
        destBreadcrumb: destinationPath,
        originBreadcrumb: originPath,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (filteredItems.length < 2) {
    return null;
  }
  const breadcrumbsItems = isHandset
    ? [filteredItems[filteredItems.length - 2]]
    : filteredItems;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      mb={3}
      ml={-1}
      style={BreadCrumbsContainerStyle as ViewStyle}
      accessibilityLabel="Breadcrumb"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - web only
      accessibilityRole="navigation"
    >
      {(breadcrumbsItems as BreadcrumbsItemProps[]).map(
        ({ title, url, ...restProps }, i) => (
          <BreadCrumbItem
            title={title}
            url={url}
            key={`${title}-${i}`}
            {...restProps}
          />
        )
      )}
    </Box>
  );
};

export default Breadcrumbs;
