import React from 'react';
import { NutrienLogo } from '@nutrien/bonsai-icons';
import { useTheme } from '@nutrien/bonsai-core';
import { Platform, Image } from 'react-native';

const Logo = ({
  width,
  height,
  dark = false,
}: {
  width: number;
  height: number;
  dark?: boolean;
}): JSX.Element => {
  const theme = useTheme();
  /**
   * the current version of react-native-svg caused an a11y issue on web
   * https://github.com/react-native-svg/react-native-svg/issues/1471
   * once resolved the image for web can be removed.
   */

  const imageProps = {
    style: { width, height },
    accessibilityLabel: 'Nutrien Ag Solutions',
  };

  return Platform.OS === 'web' ? (
    dark ? (
      <Image
        {...imageProps}
        testID="nutrien-logo"
        source={require('../../../assets/logo.dark.png')}
      />
    ) : (
      <Image
        {...imageProps}
        testID="nutrien-logo"
        source={require('../../../assets/logo.light.png')}
      />
    )
  ) : (
    <NutrienLogo
      testID="nutrien-logo"
      color={dark ? theme.auColors.neutral[900] : theme.auColors.neutral[100]}
      width={width}
      height={height}
    />
  );
};

Logo.defaultProps = {
  width: 80,
  height: 80,
};

export default Logo;
