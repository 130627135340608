import React from 'react';
import { NotificationBase, Box, useDeviceType } from '@nutrien/bonsai-core';
import { useLogin } from '../../context/LoginContext';
import AccountOverviewWrapper from '../../components/AccountOverview/AccountOverviewWrapper';
import AgronomyNavigationTest from '../../screens/AgronomyNavigationTest';

const CustomerDashboard = (): JSX.Element => {
  const {
    state: { verified, firstName, preferredName, accountIds },
  } = useLogin();
  const { isDesktop } = useDeviceType();
  const message = `Welcome ${
    preferredName || firstName
  }, Your account is unverified.`;

  if (!verified) {
    return (
      <Box flexGrow={1} alignItems="center" justifyContent="center">
        <NotificationBase
          testID="verification-state"
          type={'error'}
          message={message}
        />
      </Box>
    );
  }
  return (
    <Box testID="accounts" flexGrow={1} my={isDesktop ? 5 : 2}>
      {accountIds.map((id) => (
        <AccountOverviewWrapper
          account_ids={accountIds}
          account_id={id}
          key={id}
        />
      ))}
      <AgronomyNavigationTest />
    </Box>
  );
};

export default CustomerDashboard;
