import {
  Box,
  NamedStyles,
  Text,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import React, { FC, useState } from 'react';
import { useFormatters } from '../../../hooks';
import ButtonGroup from '../../ButtonGroup';
import SummaryCard from '../../SummaryCard';
import { DrawerData } from './DrawerContent';

export interface TabsDrawerContentProps {
  drawerStyles?: NamedStyles<Record<string, undefined>>;
  drawerData?: DrawerData[];
  testID?: string | null;
  heading: string;
}

type DrawerTabs = 'Invoices' | 'Payment options';

const TabsDrawerContent: FC<TabsDrawerContentProps> = ({
  drawerStyles,
  drawerData,
  testID,
  heading,
}) => {
  const theme = useTheme();
  const styles = useStyles(drawerStyles ?? {});
  const [viewIndex, setViewIndex] = useState(0);

  const { renderNoneIfZero } = useFormatters();

  const tabs: { text: DrawerTabs; isDisabled: boolean; id: string }[] = [
    { text: 'Invoices', isDisabled: false, id: 'invoice' },
    { text: 'Payment options', isDisabled: true, id: 'payment' },
  ];

  const selectedTab = tabs[viewIndex as number];
  const onTabChange = (index: number): void => {
    setViewIndex(index);
  };

  return (
    <>
      <Box mb={2}>
        <ButtonGroup
          testID={`${testID}-drawer-${selectedTab.id}-tab`}
          onPress={onTabChange}
          selectedIndex={viewIndex}
          buttons={tabs}
          buttonGroupContainerStyle={{ paddingTop: theme.spacing(2) }}
        />
      </Box>
      {viewIndex === 0 && (
        <SummaryCard
          vertical={true}
          heading={heading}
          summaryRow={
            drawerData &&
            drawerData.map(
              ({ Label, Amount, Description, LabelDescription }) => ({
                label: <Text style={styles.labelText}>{Label}</Text>,
                labelDescription: (
                  <Text style={styles.labelText}>{LabelDescription}</Text>
                ),
                content: (
                  <Box my={0.5}>
                    <Text bodyBold>{renderNoneIfZero(Amount)}</Text>
                  </Box>
                ),
                description: (
                  <Text style={styles.labelText}>{Description}</Text>
                ),
                width: '100%',
                testID: `${Label.replace(/ /g, '-').toLowerCase()}-column`,
              })
            )
          }
          testID={`${testID}-panel-drawer-content`}
        />
      )}
    </>
  );
};

export default TabsDrawerContent;
