import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Card, Box, Text, useStyles } from '@nutrien/bonsai-core';
import useNavigation from '../../hooks/useNavigation';
import { routeConfig } from '../../routes/shared';

const AgronomyNavigationTest = (): JSX.Element => {
  const flags = useFlags();
  const { navigate } = useNavigation();

  const styles = useStyles((theme) => ({
    launchDarklyTestCard: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
  }));

  return flags['FD-257-agronomy-navigation-test'] ? (
    <Card style={styles.launchDarklyTestCard}>
      <Box testID="hello-feature-flags">
        <Text
          onPress={() =>
            navigate(
              routeConfig.FarmVisit.getNavigateProps({
                id: '018c4d55-0843-4980-93c3-6c53b9b472a4',
              })
            )
          }
        >
          Launchdarkly controlled component
        </Text>
      </Box>
    </Card>
  ) : (
    <></>
  );
};

export default AgronomyNavigationTest;
