import React, { FC, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Text, useTheme } from '@nutrien/bonsai-core';
import { TextStyle } from 'react-native';
import { Formik, FormikHelpers } from 'formik';
import { useFormatters } from '../../hooks';
import { Button } from '../../components/Button';
import InputField from '../InputField';
import { CUSTOMER_SELF_REGISTRATION_STEPS } from '../../constants';
import CustomerInfoBannner from './CustomerInfoBannner';
import { useUser } from '../../context/UserContext';
import { TextAlign } from './';
import {
  ValidateAbn,
  ValidateAbnVariables,
  ValidateAbn_validateABN,
} from '../../types/generated/ValidateAbn';

export const VALIDATE_ABN = gql`
  query ValidateAbn($abn: String!) {
    validateABN(payload: { abn: $abn }) {
      verified
    }
  }
`;
interface FormErrors {
  abn?: string;
}

interface FormFields {
  abn: string;
}

interface FormFieldsTouched {
  abn: boolean;
}

type props = {
  onNavigateToStep: (setIndex: number) => void;
  onErrors: (setErrors: boolean) => void;
  onCancel: (showConfirmation: boolean) => void;
  onABNValidationErrors: (setErrors: boolean) => void;
};

const AbnLookupForm: FC<props> = ({
  onNavigateToStep,
  onErrors,
  onABNValidationErrors,
  onCancel,
}) => {
  const theme = useTheme();
  const { dispatch } = useUser();

  const [abn, setAbn] = useState('');
  const [errorSummary, setErrorSummary] = useState<FormErrors | null>(null);
  const [fieldsTouched, setFieldsTouched] = useState<FormFieldsTouched>({
    abn: false,
  });
  const { formatABN } = useFormatters();
  const formattedABN = formatABN(abn);
  const [verifyAbn, { loading: isVerifyingAbn }] = useLazyQuery<
    ValidateAbn,
    ValidateAbnVariables
  >(VALIDATE_ABN);

  const styles = {
    titleText: {
      color: theme.auColors.neutral[800],
      fontSize: theme.typography.fontSize.large,
      lineHeight: theme.typography.fontSize.large * 1.5,
      textAlign: 'center' as TextAlign,
      marginBottom: theme.spacing(3),
    },
    requiredInformation: {
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
    registerButton: {
      marginBottom: theme.spacing(2),
    },
    inputFieldStyles: {
      width: '100%',
    },
    abnHint: {
      color: theme.auColors.neutral[700],
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
      fontWeight: '300',
    } as TextStyle,
  };

  /**
   * Validates form values, sets error summary state, and returns
   * whether the form has errors.
   */
  const validateFormValues = (): boolean => {
    const abnErrorMessage =
      abn.length !== 11
        ? 'Enter an ABN with 11 digits, like\n73 008 743 218'
        : '';

    setErrorSummary({
      abn: abnErrorMessage,
    });

    return abnErrorMessage !== '';
  };

  const onDataVerified = (data: ValidateAbn_validateABN) => {
    /**
     * Success condition
     * If the ABN is verified, we will continue to next step
     */
    if (data.verified) {
      dispatch({
        type: 'setAbn',
        value: abn,
      });
      onNavigateToStep(CUSTOMER_SELF_REGISTRATION_STEPS.CUSTOMER_DETAILS_STEP);
      return;
    }
    /**
     * ABN Validation failed either ABN not whitelisted.
     */
    onABNValidationErrors(true);
  };

  const _onSubmit = async (
    values: FormFields,
    helper: FormikHelpers<FormFields>
  ) => {
    // When we submit the form, we want to set all fields as touched to display errors
    setFieldsTouched({
      abn: true,
    });
    const hasError = validateFormValues();

    /** Ensure the local validation is passed before going to backend. */
    if (hasError) {
      // this is how we enable / disable the buttons
      helper.setSubmitting(false);
      return;
    }

    try {
      /** Verify ABN & handle the response. */
      const { data, error } = await verifyAbn({
        variables: {
          abn,
        },
      });
      helper.setSubmitting(false);
      if (error) {
        onErrors(true);
        return;
      }
      if (data?.validateABN) {
        // Ensure we re-set the server error to false (in case it was true previously)
        onErrors(false);
        onDataVerified(data?.validateABN);
      }
    } catch (err) {
      onErrors(true);
    }
  };

  return (
    <>
      <Box marginBottom={1} testID="abn-form-lookup">
        <Text h1 bodyBold style={styles.titleText}>
          Let&#39;s start by setting up your account
        </Text>
        <Box marginBottom={3}>
          <Text>
            Register securely and easily in a few minutes with 4 easy steps.
          </Text>
        </Box>
        <Box marginBottom={2}>
          <Text style={styles.requiredInformation}>* Required information</Text>
        </Box>
        <Formik
          initialValues={{
            abn: '',
          }}
          onSubmit={(values, helper) => _onSubmit(values, helper)}
        >
          {({ submitForm, isSubmitting, setStatus, setTouched }) => (
            <Box testID="abn-form">
              <Box mb={3}>
                <InputField
                  label="ABN*"
                  onChangeText={(value) => {
                    setTouched({ abn: true });
                    setAbn(value.toString().replace(/[^0-9]/g, ''));
                  }}
                  onBlur={() => {
                    setFieldsTouched((prevState) => ({
                      ...prevState,
                      abn: true,
                    }));
                    validateFormValues();
                  }}
                  value={formattedABN}
                  errorMessage={fieldsTouched.abn && errorSummary?.abn}
                  // maxLength should be 11, but leave 3 places for spaces, example 73 008 743 218
                  maxLength={14}
                  fieldName="abn"
                  keyboardType="numeric"
                  testID="abn-input"
                  customInputStyle={styles.inputFieldStyles}
                  loading={isSubmitting}
                  readOnly={isSubmitting}
                  containerStyle={styles.inputFieldStyles}
                />
                {!(fieldsTouched.abn && errorSummary?.abn) && (
                  <Text style={styles.abnHint}>
                    Enter an ABN with 11 digits, like 73 008 743 218
                  </Text>
                )}
              </Box>
              <CustomerInfoBannner />
              <Button
                title="Register for digital access"
                testID="submit-button"
                accessibilityLabel="Submit Form Button"
                onPress={() => {
                  setStatus({ submitting: true });
                  submitForm();
                }}
                loading={isVerifyingAbn}
                containerStyle={styles.registerButton}
              />
              <Button
                title="Cancel"
                type="outline"
                testID="cancel-button"
                accessibilityLabel="Cancel Button"
                onPress={() => {
                  onCancel(true);
                }}
              />
            </Box>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default AbnLookupForm;
