import { Box, Text, useTheme } from '@nutrien/bonsai-core';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { TextStyle } from 'react-native';
import { Button } from '../../components/Button';
import {
  CUSTOMER_SELF_REGISTRATION_STEPS,
  PRIVACY_POLICY_URL,
} from '../../constants';
import { useUser } from '../../context/UserContext';
import { useFormatters } from '../../hooks';
import {
  EMAIL_REGEX,
  MOBILE_REGEX,
} from '../../utils/formValidation/registration';
import InputField from '../InputField';
import Link from '../Link';
import CustomerInfoBannner from './CustomerInfoBannner';
import useCustomerSelfRegistration from './useSelfRegistration';

interface FormFields {
  givenName: string;
  familyName: string;
  email: string;
  mobileNumber: string;
}
interface FormErrors {
  givenName?: string;
  familyName?: string;
  email?: string;
  mobileNumber?: string;
}
interface FormFieldsTouched {
  givenName: boolean;
  familyName: boolean;
  email: boolean;
  mobileNumber: boolean;
}
type props = {
  onNavigateToStep: (setIndex: number) => void;
  onErrors: (setErrors: boolean) => void;
  onCancel: (showConfirmation: boolean) => void;
  onABNValidationErrors: (setErrors: boolean) => void;
};

type TextAlign = 'center' | 'auto' | 'left' | 'right' | 'justify' | undefined;
const lableFontWeight: TextStyle = { fontWeight: '300' };

const CustomerDetailsForm: FC<props> = ({
  onNavigateToStep,
  onABNValidationErrors,
  onErrors,
  onCancel,
}) => {
  const theme = useTheme();
  const {
    state: { abn },
    dispatch,
  } = useUser();

  const {
    handleCustomerDetailsSubmit,
    verifyABNPersonData,
    validateABNPersonLoading,
    validateABNPersonError,
  } = useCustomerSelfRegistration();
  const { formatMobile } = useFormatters();
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [errorSummary, setErrorSummary] = useState<FormErrors | null>(null);
  const [fieldsTouched, setFieldsTouched] = useState<FormFieldsTouched>({
    givenName: false,
    familyName: false,
    email: false,
    mobileNumber: false,
  });
  const styles = {
    titleText: {
      color: theme.auColors.neutral[800],
      fontSize: theme.typography.fontSize.large,
      lineHeight: theme.typography.fontSize.large * 1.5,
      textAlign: 'center' as TextAlign,
      marginBottom: theme.spacing(3),
    },
    requiredInformation: {
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
    registerButton: {
      marginBottom: theme.spacing(2),
    },
    inputFieldStyles: {
      width: '100%',
    },
    hint: {
      color: theme.auColors.neutral[700],
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
    infoBanner: {
      marginBottom: theme.spacing(2),
    },
    infoBannerText: {
      color: theme.auColors.functional.info.dark,
      fontSize: theme.typography.fontSize.small,
    },
  };
  const formattedMobile = formatMobile(mobileNumber);

  const _onSubmit = (values: FormFields, helper: FormikHelpers<FormFields>) => {
    setFieldsTouched({
      givenName: true,
      familyName: true,
      email: true,
      mobileNumber: true,
    });
    /**
     * Do not handle submit form to backend unless all validation is passing
     */
    const isNoValidationErrors = validateFormValues();
    helper.setSubmitting(false);
    if (isNoValidationErrors) {
      handleCustomerDetailsSubmit(email, abn);
    }
  };

  /**
   * Handles errors such as graphql errors (i.e. offline)
   */
  useEffect(() => {
    if (!validateABNPersonLoading) {
      onErrors(validateABNPersonError !== undefined);
    }
  }, [validateABNPersonError, onErrors, validateABNPersonLoading]);

  useEffect(() => {
    // TODO : Show next Page for valid User
    // ABN and email is valid
    if (verifyABNPersonData) {
      if (
        verifyABNPersonData?.validateABNPerson &&
        verifyABNPersonData?.validateABNPerson?.account_numbers &&
        verifyABNPersonData?.validateABNPerson?.account_numbers?.length > 0
      ) {
        const accountNumbers =
          verifyABNPersonData && verifyABNPersonData.validateABNPerson
            ? verifyABNPersonData?.validateABNPerson.account_numbers
            : null;
        dispatch({
          type: 'setGivenName',
          value: givenName,
        });
        dispatch({
          type: 'setFamilyName',
          value: familyName,
        });
        dispatch({
          type: 'setEmail',
          value: email,
        });
        dispatch({
          type: 'setMobileNumber',
          value: mobileNumber,
        });
        dispatch({
          type: 'setAccountNumbers',
          value: accountNumbers,
        });

        onNavigateToStep(
          CUSTOMER_SELF_REGISTRATION_STEPS.CONFIRM_CUSTOMER_DETAILS_STEP
        );
      } else {
        /**
         * ABN Validation failed either ABN not whitelisted and or email already registered.
         */
        onABNValidationErrors(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyABNPersonData, onNavigateToStep, onABNValidationErrors]);

  const validateFormValues = (): boolean => {
    const givenNameErrorMessage =
      givenName === '' || givenName.length > 250 ? 'Enter your given name' : '';

    const familyNameErrorMessage =
      familyName === '' || familyName.length > 250
        ? 'Enter your family name'
        : '';

    const emailErrorMessage =
      !email || !EMAIL_REGEX.test(email)
        ? 'Enter an email address, like name@example.com'
        : '';

    const mobileNumberErrorMessage =
      !mobileNumber || !MOBILE_REGEX.test(mobileNumber)
        ? 'Enter an Australian mobile number, like 0419 123 456'
        : '';

    setErrorSummary({
      givenName: givenNameErrorMessage,
      familyName: familyNameErrorMessage,
      email: emailErrorMessage,
      mobileNumber: mobileNumberErrorMessage,
    });

    return (
      !givenNameErrorMessage &&
      !familyNameErrorMessage &&
      !emailErrorMessage &&
      !mobileNumberErrorMessage
    );
  };

  const navToPrivacyPolicy = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(PRIVACY_POLICY_URL, '_blank');
  };

  return (
    <Box marginBottom={1}>
      <Text h1 bodyBold style={styles.titleText}>
        Tell us about yourself
      </Text>
      <Box marginBottom={3}>
        <Text>
          We&#39;ll use these details to set up your profile and keep your
          account secure.
        </Text>
      </Box>
      <Box marginBottom={2}>
        <Text style={styles.requiredInformation}>* Required information</Text>
      </Box>
      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobileNumber: '',
        }}
        onSubmit={(values, helper) => _onSubmit(values, helper)}
      >
        {({ submitForm, setStatus, setTouched }) => (
          <Box>
            <Box mb={1}>
              <InputField
                label="Given name*"
                onChangeText={(value) => {
                  setTouched({ givenName: true });
                  setGivenName(value.toString());
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFieldsTouched((prevState) => ({
                      ...prevState,
                      givenName: true,
                    }));
                    validateFormValues();
                  }, 300);
                }}
                value={givenName}
                errorMessage={
                  fieldsTouched.givenName && errorSummary?.givenName
                }
                fieldName="givenName"
                loading={validateABNPersonLoading}
                readOnly={validateABNPersonLoading}
                testID="given-name-input"
                customInputStyle={styles.inputFieldStyles}
              />
            </Box>
            <Box mb={3}>
              <InputField
                label="Family name*"
                onChangeText={(value) => {
                  setTouched({ familyName: true });
                  setFamilyName(value.toString());
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFieldsTouched((prevState) => ({
                      ...prevState,
                      familyName: true,
                    }));
                    validateFormValues();
                  }, 300);
                }}
                value={familyName}
                errorMessage={
                  fieldsTouched.familyName && errorSummary?.familyName
                }
                maxLength={11}
                fieldName="familyName"
                testID="family-name-input"
                customInputStyle={styles.inputFieldStyles}
                loading={validateABNPersonLoading}
                readOnly={validateABNPersonLoading}
                containerStyle={styles.inputFieldStyles}
              />
            </Box>
            <Box mb={3}>
              <InputField
                label="Email*"
                onChangeText={(value) => {
                  setTouched({ email: true });
                  setEmail(value.toString());
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFieldsTouched((prevState) => ({
                      ...prevState,
                      email: true,
                    }));
                    validateFormValues();
                  }, 300);
                }}
                value={email}
                errorMessage={fieldsTouched.email && errorSummary?.email}
                maxLength={50}
                fieldName="email"
                testID="email-input"
                customInputStyle={styles.inputFieldStyles}
                loading={validateABNPersonLoading}
                readOnly={validateABNPersonLoading}
                containerStyle={styles.inputFieldStyles}
              />
              {!(fieldsTouched.email && errorSummary?.email) && (
                <Text style={[styles.hint, lableFontWeight]}>
                  Enter an email address, like name@example.com
                </Text>
              )}
            </Box>
            <Box mb={3}>
              <InputField
                label="Mobile number*"
                onChangeText={(value) => {
                  setTouched({ mobileNumber: true });
                  setMobileNumber(value.toString());
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFieldsTouched((prevState) => ({
                      ...prevState,
                      mobileNumber: true,
                    }));
                    validateFormValues();
                  }, 300);
                }}
                value={formattedMobile}
                errorMessage={
                  fieldsTouched.mobileNumber && errorSummary?.mobileNumber
                }
                maxLength={12}
                keyboardType="numeric"
                fieldName="mobileNumber"
                testID="mobile-number-input"
                customInputStyle={styles.inputFieldStyles}
                loading={validateABNPersonLoading}
                readOnly={validateABNPersonLoading}
                containerStyle={styles.inputFieldStyles}
              />
              {!(fieldsTouched.mobileNumber && errorSummary?.mobileNumber) && (
                <Text style={[styles.hint, lableFontWeight]}>
                  Enter an Australian mobile number, like 0419 123 456
                </Text>
              )}
            </Box>
            <CustomerInfoBannner />
            <Button
              title="Register for digital access"
              testID="submit-button"
              accessibilityLabel="Submit Form Button"
              loading={validateABNPersonLoading}
              disabled={validateABNPersonLoading}
              onPress={() => {
                setStatus({ submitting: true });
                submitForm();
              }}
              containerStyle={styles.registerButton}
            />
            <Button
              title="Cancel"
              type="outline"
              testID="cancel-button"
              accessibilityLabel="Cancel Button"
              onPress={() => {
                onCancel(true);
              }}
            />
          </Box>
        )}
      </Formik>
      <Box marginY="16px">
        <Text>
          We are collecting your personal information to assist with setting up
          your account and complying with our regulatory obligations. This
          information will help us verify your identity, send you important
          information and keep your account secure. For more information about
          how we collect and handle personal information, see our{' '}
          <Link
            text="Privacy Policy"
            testID="test-privacy-policy"
            onPress={navToPrivacyPolicy}
            hasUnderline={true}
          />
        </Text>
      </Box>
    </Box>
  );
};

export default CustomerDetailsForm;
