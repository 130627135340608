import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  SearchTransactions,
  SearchTransactionsVariables,
} from 'types/generated/SearchTransactions';

export const TRANSACTIONS = gql`
  query SearchTransactions(
    $accountNumber: String!
    $fromDate: String!
    $toDate: String!
    $ruralcoAccNumber: String
    $isLotDetailsOn: Boolean!
    $isNewSummaryOn: Boolean!
  ) {
    getLineItems(
      payload: {
        AccountNumber: $accountNumber
        FromDate: $fromDate
        ToDate: $toDate
        CompanyCode: "8200"
        RuralcoAccNumber: $ruralcoAccNumber
      }
    ) {
      AccountNumber
      Amount
      Segment
      Location
      Items
      DueDate
      DocumentId
      DocumentNumber
      DocumentId @include(if: $isLotDetailsOn)
      Description
      Date
      PostingKey
      DocumentType
      RuralcoAccNumber
      HasPdf
      SaleDate
      GroupBy
      GroupTitle
      Category
      AccountName @include(if: $isLotDetailsOn)
      GSTAmount @include(if: $isLotDetailsOn)
      AmountExcGST @include(if: $isLotDetailsOn)
      ClearingDocNo @include(if: $isNewSummaryOn)
    }
  }
`;

type Refetch = (
  variables?: Partial<SearchTransactionsVariables> | undefined
) => Promise<ApolloQueryResult<SearchTransactions>>;

const useTransactionsQuery = ({
  accountNumber,
  fromDate,
  toDate,
  ruralcoAccNumber,
}: {
  accountNumber: string;
  fromDate: string;
  toDate: string;
  ruralcoAccNumber?: string | null;
}): [
  SearchTransactions | undefined,
  boolean,
  ApolloError | undefined,
  Refetch
] => {
  const flags = useFlags();
  const { data, loading, error, refetch } = useQuery<
    SearchTransactions,
    SearchTransactionsVariables
  >(TRANSACTIONS, {
    variables: {
      accountNumber,
      fromDate,
      toDate,
      ruralcoAccNumber,
      isLotDetailsOn: flags['NG-3135-plasma-lot-details'] ?? false,
      isNewSummaryOn: flags['ng-2585-wdio-account-summary']
        ? flags['ng-2585-wdio-account-summary'].length > 0
        : false,
    },
    notifyOnNetworkStatusChange: true,
  });

  return [data, loading, error, refetch];
};

export default useTransactionsQuery;
