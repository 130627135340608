import React, { ReactNode } from 'react';
import { Box, Text, useTheme, useDeviceType } from '@nutrien/bonsai-core';
import Autocomplete from '../../components/Autocomplete';
import { SearchCustomerAccounts_sords_customer_account } from 'types/generated/SearchCustomerAccounts';
import SearchInput from './SearchInput';
import useStaffDashboard from './useStaffDashboard';
import AccountRow from './AccountRow';
import { CustomerList } from '../../components/CustomerList';
import copy from './copy.json';

const StaffDashboard = (): JSX.Element => {
  const {
    searchTerm,
    hideSearchResults,
    searchResults,
    handleSearchChange,
    searchLoading,
    searchError,
    handleSearchResultClick,
    setHideSearchResults,
  } = useStaffDashboard();

  const theme = useTheme();
  const { isDesktop } = useDeviceType();

  const styles = {
    pageTitle: {
      marginTop: isDesktop ? theme.spacing(4) : theme.spacing(3),
      marginBottom: isDesktop ? theme.spacing(4) : theme.spacing(3),
    },
    cardContainer: {
      flexGrow: 1,
    },
    defaultHeader: {
      paddingBottom: isDesktop ? theme.spacing(2) : theme.spacing(1),
    },
    registeredUserHeader: {
      paddingBottom: theme.spacing(1),
      paddingHorizontal: theme.spacing(2),
    },
    searchContainer: { borderWidth: 0 },
    headingLarge:
      theme.auTypography[isDesktop ? 'desktop' : 'mobile'].headingLarge,
    headingMedium:
      theme.auTypography[isDesktop ? 'desktop' : 'mobile'].headingMedium,
  };

  const renderSearchInput = ({ onKeyDown }: { onKeyDown?: () => void }) => (
    <SearchInput
      {...{
        handleSearchChange,
        searchLoading,
        searchError,
        setHideSearchResults,
        onKeyDown,
      }}
    />
  );

  const renderSearchResult = ({
    item,
    isHighlighted,
  }: {
    item: unknown;
    isHighlighted?: boolean;
  }) => {
    const customerAccount =
      item as SearchCustomerAccounts_sords_customer_account;

    return (
      <AccountRow
        key={customerAccount.customer_guid}
        customerAccount={customerAccount}
        handleSearchResultClick={handleSearchResultClick}
        isHighlighted={isHighlighted}
      />
    );
  };

  return (
    <Box testID="staff-dashboard" style={styles.cardContainer}>
      <Text style={[styles.headingLarge, styles.pageTitle]} h1>
        {copy.dashboard.heading}
      </Text>
      <Box
        zIndex={2}
        pt={isDesktop ? 3 : 2}
        pb={4}
        px={2}
        bg={'white'}
        borderRadius={4}
      >
        <Text
          h2
          style={[styles.headingMedium, styles.defaultHeader]}
          testID="account-search-heading"
        >
          {copy.customerAccountSearch.heading}
        </Text>
        <Box zIndex={2}>
          <Autocomplete
            hideResults={hideSearchResults}
            data={searchResults}
            onBlur={() => setHideSearchResults(true)}
            value={searchTerm}
            flatListProps={{
              keyExtractor: (_, i) => i.toString(),
              renderItem: renderSearchResult,
              keyboardShouldPersistTaps: 'always',
              listKey: 'customerGuid',
            }}
            inputContainerStyle={styles.searchContainer}
            renderTextInput={renderSearchInput as () => ReactNode}
            onSelectionChange={handleSearchResultClick as () => void}
          />
        </Box>
      </Box>
      <Box mt={2} pt={isDesktop ? 3 : 2} pb={4} bg={'white'} borderRadius={4}>
        <Text
          h2
          style={[
            styles.headingMedium,
            styles.defaultHeader,
            styles.registeredUserHeader,
          ]}
          testID="customer-list-heading"
        >
          {copy.customerList.heading}
        </Text>
        <Box>
          <CustomerList />
        </Box>
      </Box>
    </Box>
  );
};

export default StaffDashboard;
