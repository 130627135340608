import { Box, NamedStyles, Text, useStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary, useFormatters } from '../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../SummaryCard';

export interface FinanceTwoSectionsSummaryProps {
  accountDisplayName: string;
  isLivestock?: boolean;
  summary?: AccountSummaryData | null;
  summaryStyles?: NamedStyles<Record<string, undefined>>;
  testID?: string;
  handleClickViewDetails: () => void;
}

const FinanceTwoSectionsSummary: FC<FinanceTwoSectionsSummaryProps> = ({
  accountDisplayName,
  isLivestock,
  summary,
  summaryStyles,
  testID,
  handleClickViewDetails,
}) => {
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(summaryStyles ?? {});
  const {
    proceedsPending,
    finance: { interestBearingBalance, nonInterestBearingBalance },
  } = useAccountSummary(summary, isLivestock);

  return (
    <SummaryCard
      heading={accountDisplayName}
      button={{
        label: `View ${accountDisplayName.toLowerCase()}`,
        testID: `view-details-${testID}`,
        onPress: handleClickViewDetails,
      }}
      summaryRow={[
        {
          label: (
            <Box style={styles.label}>
              <Text style={styles.labelText}>Interest bearing balance</Text>
            </Box>
          ),
          content: renderNoneIfZero(interestBearingBalance),
        },
        {
          label: (
            <Box style={styles.label}>
              <Text style={styles.labelText}>Non-interest bearing balance</Text>
            </Box>
          ),
          content: renderNoneIfZero(nonInterestBearingBalance),
        },
        ...(isLivestock
          ? [
              {
                label: (
                  <Box style={styles.label}>
                    <Text style={styles.labelText}>Pending proceeds</Text>
                  </Box>
                ),
                content: renderNoneIfZero(proceedsPending),
                ...(typeof proceedsPending === 'number' && proceedsPending < 0
                  ? {
                      description: (
                        <Box marginTop={0.5}>
                          <Text style={styles.info}>
                            This will not reduce any amount due
                          </Text>
                        </Box>
                      ),
                    }
                  : undefined),
              },
            ]
          : []),
      ]}
      testID={testID}
    />
  );
};

export default FinanceTwoSectionsSummary;
