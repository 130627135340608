import React from 'react';
import SubHeaderImageBackground from './SubheaderImageBackground';
import useSubHeaderType from './useSubHeaderType';

const SubHeaderBanner = (): JSX.Element | null => {
  const { isCustomerDashboard, isUserSummary, isBranchDashboardOrCreateUser } =
    useSubHeaderType();

  if (isBranchDashboardOrCreateUser || isCustomerDashboard || isUserSummary) {
    return <SubHeaderImageBackground testID="subheader-background" />;
  }

  return <></>;
};

export default SubHeaderBanner;
