import React from 'react';
import { AccountType } from 'components/AccountOverview/useAccountOverview';
import { Text } from '@nutrien/bonsai-core';
import { AccountTypeToDisplayNameMap } from '../../screens/Account/useAccountOptions';
import useFormatters from '../../hooks/useFormatters';

interface Props {
  accType?: AccountType | null;
  accName: string | null;
}

const AccountTypeRender = ({ accType, accName }: Props): JSX.Element => {
  const { formatCapitalize } = useFormatters();

  if (!accType) {
    return (
      <Text testID="accBoxInfo-type" numberOfLines={2} ellipsizeMode="tail">
        {'-'}
      </Text>
    );
  }

  let name;

  const displayName =
    AccountTypeToDisplayNameMap[
      accType as keyof typeof AccountTypeToDisplayNameMap
    ];

  if (displayName) {
    name = `${displayName} Account`;
  }

  const isSeasonal = accType === 'finance' && accName?.includes('SCF');

  if (isSeasonal) {
    name = 'Seasonal Crop Finance Account';
  }

  if (!name) {
    name = `${formatCapitalize(accType) || ''} Account`;
  }

  return (
    <Text testID="accBoxInfo-type" numberOfLines={2} ellipsizeMode="tail">
      {name}
    </Text>
  );
};

export default AccountTypeRender;
