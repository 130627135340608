import { Box, Grid, Text, useTheme } from '@nutrien/bonsai-core';
import { Avatar } from 'native-base';
import React, { useState } from 'react';
import AccountInformationPanel, {
  ScreenType,
} from '../../components/AccountInformationPanel';
import ActionList from '../../components/ActionList';
import Breadcrumbs from '../../components/Breadcrumbs';
import ContactDetailsPanel from '../../components/ContactDetailsPanel';
import useBranchByCode from '../../components/SubHeader/useBranchByCode';
import { breadcrumbConstants } from '../../constants';
import { useStaff } from '../../context/StaffContext';
import { useNavigation, useRoutes } from '../../hooks';
import useCustomerProfileByCustomerId from '../../hooks/useCustomerProfileByCustomerId';
import { routeConfig } from '../../routes/shared';
import UnsavedModal from '../../components/UnsavedModal/UnsavedModal';

const EditUser = (): JSX.Element => {
  const { routeParams } = useRoutes();
  const [pageIndex, setPageIndex] = useState(0);

  const { navigate } = useNavigation();
  const {
    state: { hasChangesInForm },
    dispatch,
  } = useStaff();
  const theme = useTheme();

  const customerId = routeParams.customerId as string;

  const {
    customer,
    customerProfileByCustomerIdLoading,
    customerProfileByCustomerIdError,
    customerProfileByCustomerIdRefetch,
  } = useCustomerProfileByCustomerId(customerId);
  const { branchByCodeLoading, branchByCodeError, branchByCodeRefetch } =
    useBranchByCode();

  const givenName = customer?.given_name;
  const familyName = customer?.family_name;
  const userInitials =
    givenName &&
    familyName &&
    (givenName.slice(0, 1) + familyName.slice(0, 1)).toUpperCase();

  const styles = {
    header: {
      marginBottom: theme.spacing(1),
    },
    grid: {
      flex: 1,
    },
    avatar: {
      width: 120,
      height: 120,
      borderRadius: 60,
    },
    pressable: {
      backgroundColor: 'transparent',
      textDecorationLine: 'underline',
    },
  };

  const handleRefetch = () => {
    customerProfileByCustomerIdRefetch();
    branchByCodeRefetch();
  };

  const showComponent = (): JSX.Element => {
    const component = {
      0: (
        <ContactDetailsPanel
          loading={customerProfileByCustomerIdLoading || branchByCodeLoading}
          error={customerProfileByCustomerIdError || branchByCodeError}
          customer={customer}
          type="edit"
          customerProfileByCustomerIdRefetch={
            customerProfileByCustomerIdRefetch
          }
          handleReload={handleRefetch}
        />
      ),
      1: (
        <AccountInformationPanel
          customer={customer}
          type={ScreenType.edit}
          customerProfileByCustomerIdRefetch={
            customerProfileByCustomerIdRefetch
          }
          customerProfileByCustomerIdLoading={
            customerProfileByCustomerIdLoading
          }
          handleClickUserSummary={handleClickUserSummary}
        />
      ),
    };

    return component[pageIndex as keyof typeof component] || component[0];
  };

  const hasLinkedAccounts =
    customer &&
    ('customer_accounts' in customer
      ? customer?.customer_accounts && customer?.customer_accounts.length > 0
      : customer?.accounts && customer?.accounts.length > 0);

  const handleClickUserSummary = () => {
    if (hasChangesInForm) {
      dispatch({
        type: 'setNotificationModal',
        value: {
          showSaveModal: true,
          url: routeConfig.UserSummary.getNavigateProps({
            customerId,
          }),
        },
      });
    } else {
      navigate(
        routeConfig.UserSummary.getNavigateProps({
          customerId,
        })
      );
    }
  };

  const handleClickMenuPanel = (index: number) => {
    if (hasChangesInForm) {
      dispatch({
        type: 'setNotificationModal',
        value: { showSaveModal: true, feature: 'EditUser', pageIndex: index },
      });
    } else {
      setPageIndex(index);
    }
  };

  return (
    <>
      <Grid item style={styles.grid}>
        <Grid item xs={12}>
          <Breadcrumbs
            items={[
              breadcrumbConstants.home,
              breadcrumbConstants.userSummary(customerId as string),
              { title: 'Edit User' },
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box flex={1} p={2} background={theme.auColors.neutral[100]}>
            <Box alignItems="center">
              <Text h2 style={styles.header} testID="user-profile-heading">
                User Profile
              </Text>
              <Text>{`${givenName} ${familyName}`}</Text>
            </Box>
            <Box p={2} justifyContent="center" alignItems="center">
              <Avatar
                _text={{
                  color: theme.auColors.neutral[800],
                  fontSize: 44,
                  fontFamily: theme.typography.fontFamily.semiBold,
                }}
                testID="edit-user-avatar"
                size={96}
                backgroundColor="brand.leaf1"
              >
                {userInitials}
              </Avatar>
            </Box>
            <Box mb={1}>
              <Text bodyBold>Main Menu</Text>
            </Box>
            <Box mb={1}>
              <ActionList
                title="Contact Details"
                testID="edit-contact-details"
                iconLeft={{
                  name: 'phone',
                  testID: 'contact-details-phone-icon',
                  size: 24,
                }}
                iconRight={{
                  name: 'check-circle',
                  testID: 'contact-details-success-icon',
                  size: 16,
                  color: theme.auColors.functional.success.dark,
                }}
                onPress={() => {
                  handleClickMenuPanel(0);
                }}
                selected={pageIndex === 0}
              />
            </Box>
            <Box>
              <ActionList
                title="Accounts"
                testID="edit-accounts-details"
                iconLeft={{
                  name: 'account-balance',
                  testID: 'accounts-icon',
                  size: 24,
                }}
                iconRight={
                  hasLinkedAccounts
                    ? {
                        name: 'check-circle',
                        testID: 'accounts-success-icon',
                        size: 16,
                        color: theme.auColors.functional.success.dark,
                      }
                    : null
                }
                onPress={() => {
                  handleClickMenuPanel(1);
                }}
                selected={pageIndex === 1}
              />
            </Box>
            <Box>
              <ActionList
                title="User Summary"
                testID="user-summary-nav"
                iconLeft={{
                  name: 'person',
                  testID: 'user-summary-nav-icon',
                  size: 24,
                }}
                iconRight={{
                  name: 'chevron-right',
                  testID: 'chevron-right-icon',
                  size: 16,
                  color: theme.auColors.primary.default,
                }}
                onPress={handleClickUserSummary}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
          {showComponent()}
        </Grid>
      </Grid>
      <UnsavedModal
        title="Unsaved changes"
        message="You have unsaved changes on this page. Do you wish to continue?"
        changePageIndex={setPageIndex}
      />
    </>
  );
};

export default EditUser;
