import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  DelinkAccounts,
  DelinkAccountsVariables,
} from 'types/generated/DelinkAccounts';

export const DELINK_ACCOUNTS = gql`
  mutation DelinkAccounts($accountIds: [String!]!, $customerId: uuid!) {
    deleteCustomerAccounts(
      payload: { account_ids: $accountIds, customer_id: $customerId }
    ) {
      account_id
    }
  }
`;

type DelinkAccountsFunction = (
  options?: MutationFunctionOptions<DelinkAccounts, DelinkAccountsVariables>
) => Promise<unknown>;

const useDelinkAccountsMutation = (): [
  DelinkAccountsFunction,
  {
    data?: DelinkAccounts | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [mutateDelinkAccounts, { data, error, loading }] = useMutation<
    DelinkAccounts,
    DelinkAccountsVariables
  >(DELINK_ACCOUNTS);

  return [mutateDelinkAccounts, { data, error, loading }];
};

export default useDelinkAccountsMutation;
