import React, { useState, ForwardedRef, forwardRef } from 'react';
import { ActivityIndicator, Box, Text, useTheme } from '@nutrien/bonsai-core';
import {
  Pressable,
  StyleSheet,
  PressableProps,
  ViewStyle,
  TextStyle,
  View,
} from 'react-native';

interface ButtonProps extends PressableProps {
  title?: string;
  type?: 'solid' | 'outline' | 'clear';
  containerStyle?: ViewStyle;
  buttonStyle?: ViewStyle;
  titleStyle?: TextStyle;
  loading?: boolean;
  icon?: JSX.Element;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  fullWidth?: boolean;
}

const Button = (
  {
    title,
    disabled,
    loading,
    icon,
    iconLeft,
    iconRight,
    type = 'solid',
    containerStyle,
    buttonStyle,
    titleStyle,
    fullWidth,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<null>
): JSX.Element => {
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const theme = useTheme();
  const hasIcon = iconRight || iconLeft;

  const styles = {
    fullWidth: {
      width: '100%',
    },
    buttonBase: {
      flexDirection: 'row' as const,
      alignItems: 'center' as const,
      justifyContent: 'center' as const,
      height: 40,
      borderRadius: theme.shape.borderRadius,
    },
    textBase: {
      paddingHorizontal: theme.spacing(1),
    },
    buttonContainer: {
      borderRadius: theme.shape.borderRadius,
    },
    solid: {
      default: {
        borderColor: theme.auColors.brand.denim[300],
        backgroundColor: theme.auColors.brand.denim[300],
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[100],
        },
      },
      hover: {
        borderColor: theme.auColors.brand.denim[400],
        backgroundColor: theme.auColors.brand.denim[400],
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[100],
        },
      },
      pressed: {
        borderColor: theme.auColors.brand.denim[200],
        backgroundColor: theme.auColors.brand.denim[200],
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[100],
        },
      },
      disabled: {
        borderColor: theme.auColors.neutral[400],
        backgroundColor: theme.auColors.neutral[400],
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[100],
        },
      },
      focused: {
        borderColor: theme.auColors.functional.focused.light,
        backgroundColor: theme.auColors.brand.denim[400],
        boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.dark}`,
        borderRadius: 4,
        outline: 'none', // remove white border around button
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[100],
        },
      },
      loading: {
        borderColor: theme.auColors.brand.denim[100],
        backgroundColor: theme.auColors.brand.denim[100],
        borderWidth: 1,
        text: {
          color: theme.auColors.brand.denim[400],
        },
      },
    },
    clear: {
      default: {
        borderColor: 'transparent',
        borderWidth: 1,
        text: {
          color: theme.auColors.brand.denim[300],
          borderBottomColor: !hasIcon
            ? theme.auColors.brand.denim[300]
            : 'transparent',
          borderBottomWidth: 1,
          paddingHorizontal: theme.spacing(0),
        },
      },
      hover: {
        borderColor: 'transparent',
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[800],
          borderBottomColor: hasIcon
            ? theme.auColors.neutral[800]
            : 'transparent',
          borderBottomWidth: 1,
          paddingHorizontal: theme.spacing(0),
        },
      },
      pressed: {
        borderColor: 'transparent',
        borderWidth: 1,
        text: {
          color: theme.auColors.brand.denim[200],
          borderBottomColor: 'transparent',
          borderBottomWidth: 1,
          paddingHorizontal: theme.spacing(0),
        },
      },
      disabled: {
        borderColor: 'transparent',
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[400],
          borderBottomColor: theme.auColors.neutral[400],
          borderBottomWidth: 1,
          paddingHorizontal: theme.spacing(0),
        },
        underline: {
          borderBottomColor: theme.auColors.neutral[400],
          borderBottomWidth: 1,
        },
      },
      focused: {
        borderColor: theme.auColors.functional.focused.light,
        boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.dark}`,
        borderRadius: 4,
        outline: 'none', // remove white border around button
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[800],
          borderBottomColor: 'transparent',
          borderBottomWidth: 1,
          paddingHorizontal: theme.spacing(0),
        },
        underline: {
          borderBottomColor: 'transparent',
          borderBottomWidth: 1,
        },
      },
      loading: {
        borderColor: 'transparent',
        borderWidth: 1,
        text: {
          color: theme.auColors.brand.denim[300],
          borderBottomColor: 'transparent',
          borderBottomWidth: 1,
          paddingHorizontal: theme.spacing(0),
        },
      },
    },
    outline: {
      default: {
        borderColor: theme.auColors.neutral[500],
        backgroundColor: theme.auColors.neutral[200],
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[600],
        },
      },
      hover: {
        borderColor: theme.auColors.neutral[800],
        backgroundColor: theme.auColors.neutral[300],
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[800],
        },
      },
      pressed: {
        borderColor: theme.auColors.brand.denim[200],
        backgroundColor: theme.auColors.neutral[200],
        borderWidth: 1,
        text: {
          color: theme.auColors.brand.denim[200],
        },
      },
      disabled: {
        borderColor: theme.auColors.neutral[400],
        backgroundColor: theme.auColors.neutral[200],
        borderWidth: 1,
        text: {
          color: theme.auColors.neutral[400],
        },
      },
      focused: {
        borderColor: theme.auColors.functional.focused.light,
        boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.dark}`,
        borderRadius: 4,
        borderWidth: 1,
        outline: 'none', // remove white border around button
        text: {
          color: theme.auColors.neutral[800],
        },
      },
      loading: {
        borderColor: theme.auColors.neutral[300],
        backgroundColor: theme.auColors.neutral[200],
        borderWidth: 1,
        text: {
          color: theme.auColors.brand.denim[400],
        },
      },
    },
  };

  const getButtonStyles = () => {
    // Based on the type of button, we use a different key in the styles object
    // eslint-disable-next-line security/detect-object-injection
    const buttonTypeStyle = styles[type];
    // we often disable a button while loading
    if (loading && disabled) {
      return buttonTypeStyle.loading;
    }

    if (disabled) {
      return buttonTypeStyle.disabled;
    }

    if (loading) {
      return buttonTypeStyle.loading;
    }

    if (pressed) {
      return buttonTypeStyle.pressed;
    }

    if (focused) {
      return buttonTypeStyle.focused;
    }

    if (hovered) {
      return buttonTypeStyle.hover;
    }
    return buttonTypeStyle.default;
  };

  const defaultIcon = iconLeft || icon;

  return (
    <View
      style={StyleSheet.flatten([
        containerStyle,
        fullWidth && styles.fullWidth,
      ])}
    >
      <Pressable
        ref={ref}
        disabled={disabled}
        onPressIn={() => setPressed(true)}
        onPressOut={() => setPressed(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - web only
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        style={StyleSheet.flatten([
          styles.buttonBase,
          getButtonStyles(),
          buttonStyle,
        ])}
        {...props}
        accessible
        accessibilityRole={'button'}
        accessibilityState={{ disabled: disabled ?? undefined }}
      >
        {loading ? (
          <ActivityIndicator
            testID="button-loading"
            color={getButtonStyles().text.color}
          />
        ) : (
          <Box
            style={styles.buttonContainer}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            paddingX={1}
            width="100%"
            height="100%"
          >
            {defaultIcon &&
              React.cloneElement(defaultIcon, {
                ...defaultIcon.props,
                color: getButtonStyles().text.color,
              })}
            {title && (
              <Text
                bodyBold
                style={StyleSheet.flatten([
                  styles.textBase,
                  getButtonStyles().text,
                  titleStyle,
                ])}
              >
                {title}
              </Text>
            )}
            {iconRight &&
              React.cloneElement(iconRight, {
                ...iconRight.props,
                color: getButtonStyles().text.color,
              })}
          </Box>
        )}
      </Pressable>
    </View>
  );
};
export default forwardRef(Button);
