import React from 'react';
import { useWindowDimensions, ScrollView } from 'react-native';
import {
  Box,
  Card,
  Text,
  useStyles,
  useDeviceType,
  Pressable,
} from '@nutrien/bonsai-core';
import { Button } from '../../components/Button';
import QueryCard from '../../components/QueryCard';
import Disclaimer from '../../components/FarmVisit/Disclaimer';
import theme from '../../theme/theme';
import Breadcrumbs from '../../components/Breadcrumbs';
import NotFoundMessage from '../../components/FarmVisit/NoFoundMessage';
import {
  breadcrumbConstants,
  RECOMMENDATION_JOB_STATUS,
  RECOMMENDATION_STATUS,
} from '../../constants';
import { useRoutes, useNavigation, useSort } from '../../hooks';
import useGetFarmVisitQuery from '../../hooks/gql/queries/useGetFarmVisitQuery';
import { routeConfig } from '../../routes/shared';
import RecommendationStatusChip from './RecommendationStatusChip';
import { FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge } from 'types/generated/FarmVisitRecommendations';

const FarmVisitRecommendations = (): JSX.Element => {
  const device = useDeviceType();
  const layout = useWindowDimensions();
  const { navigate } = useNavigation();
  const { routeParams } = useRoutes();

  const headerHeight = device.isHandset ? 96 : 72;
  const scrollHeight = layout.height - headerHeight - theme.spacing(7);

  const styles = useStyles(() => ({
    containerDesktop: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    },
    heightDesktop: {
      minHeight: layout.height - headerHeight - theme.spacing(6),
    },
    heightMobile: {
      height: scrollHeight,
    },
    header: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    title: {
      color: theme.auColors.neutral[800],
      fontSize: theme.typography.fontSize.large,
      fontWeight: '600',
      padding: theme.spacing(2),
    },
    productTitle: {
      paddingVertical: theme.spacing(1),
    },
    pressable: {
      backgroundColor: 'transparent',
    },
    noRecMessage: {
      padding: theme.spacing(2),
    },
    mobileButton: {
      marginHorizontal: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    mobileButtonWrap: {
      width: layout.width,
      backgroundColor: 'white',
      position: 'absolute',
      top: theme.spacing(2),
    },
    default: {
      width: 240,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
    outerLayer: {
      height: layout.height - headerHeight - scrollHeight,
      backgroundColor: 'white',
      width: layout.width,
      right: theme.spacing(2),
    },
  }));

  const { id: routeID } = Object(routeParams) || '';

  const [recommendationList, loading, error, refetch] = useGetFarmVisitQuery({
    farmVisitId: routeID,
  });

  const { sortFarmVisitByDueDateAndType } = useSort();

  const { farm_farm_visit_summary } = recommendationList || {};

  const farmVisits = Array.isArray(farm_farm_visit_summary)
    ? farm_farm_visit_summary[0]?.farm_visit_summary_reco_bridge
    : [];

  if ((!farmVisits || farmVisits.length < 1) && !loading) {
    return (
      <NotFoundMessage
        alert={'No recommendations found'}
        message="Please try a different farm visit."
      />
    );
  }

  const recommendationNavigation = (recId: string) => {
    navigate(
      routeConfig.FarmVisitRecommendation.getNavigateProps({
        id: recId,
        visitId: routeID,
      })
    );
  };

  // We split up the different recommendation types due to sorting them by a different date
  const dueFarmVisits: FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge[] =
    [];
  const discardedVisits: FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge[] =
    [];
  const deletedVisits: FarmVisitRecommendations_farm_farm_visit_summary_farm_visit_summary_reco_bridge[] =
    [];

  [...farmVisits]?.forEach((visit) => {
    if (visit.farm_visit_reco_bridge_reco_activity?.deleted_dtm) {
      deletedVisits.push(visit);
    } else if (
      visit?.farm_visit_reco_bridge_reco_activity?.job_status ===
      RECOMMENDATION_JOB_STATUS.DISCARDED
    ) {
      discardedVisits.push(visit);
    } else {
      dueFarmVisits.push(visit);
    }
  });

  const sortedDueFarmVisits = sortFarmVisitByDueDateAndType(
    dueFarmVisits,
    RECOMMENDATION_STATUS.DUE
  );

  const sortedDeletedFarmVisits = sortFarmVisitByDueDateAndType(
    deletedVisits,
    RECOMMENDATION_STATUS.DELETED
  );

  const sortedDiscardedFarmVisits = sortFarmVisitByDueDateAndType(
    discardedVisits,
    RECOMMENDATION_STATUS.DISCARDED
  );

  const sortedRecommendations = [
    ...sortedDueFarmVisits,
    ...sortedDeletedFarmVisits,
    ...sortedDiscardedFarmVisits,
  ];

  const recommendationsList = () => {
    return (
      <Box>
        {sortedRecommendations?.map((rec, index) => (
          <Box key={index} mb={1}>
            <Pressable
              focusable={true}
              style={styles.pressable}
              testID={`farm-recommendations-${index}`}
              onPress={() => recommendationNavigation(rec.recommendation_uuid)}
            >
              <Box
                backgroundColor={theme.auColors.neutral[300]}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                pl={2}
                pr={1}
              >
                <Text style={styles.productTitle} numberOfLines={1}>
                  {rec?.farm_visit_reco_bridge_reco_activity
                    ?.recommendation_title ?? ''}
                </Text>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  my={1}
                >
                  <RecommendationStatusChip
                    jobStatus={
                      rec?.farm_visit_reco_bridge_reco_activity?.job_status ||
                      ''
                    }
                    recUpdatedDtm={
                      rec?.farm_visit_reco_bridge_reco_activity
                        ?.rec_updated_dtm || ''
                    }
                    recDueDtm={
                      rec?.farm_visit_reco_bridge_reco_activity?.rec_due_dtm ||
                      ''
                    }
                    recDeletedDtm={
                      rec?.farm_visit_reco_bridge_reco_activity?.deleted_dtm ||
                      ''
                    }
                  />
                </Box>
              </Box>
            </Pressable>
          </Box>
        ))}
      </Box>
    );
  };

  const productButton = () => {
    return (
      <Box style={device.isHandset ? styles.outerLayer : styles.default}>
        <Box style={device.isHandset && styles.mobileButtonWrap}>
          <Button
            type="solid"
            buttonStyle={device.isHandset ? styles.mobileButton : undefined}
            title="View Product List"
            accessibilityLabel="View Product"
            testID="farm-recommendations-cta"
            onPress={() =>
              navigate(
                routeConfig.FarmVisit.getNavigateProps({
                  id: routeID,
                })
              )
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <QueryCard
      loading={loading}
      error={error}
      handleReload={() => refetch()}
      wrapper={Box}
    >
      <>
        <ScrollView
          testID="farm-visit-recommendations"
          style={device.isHandset ? styles.heightMobile : styles.heightDesktop}
          contentContainerStyle={device.isHandset && styles.containerDesktop}
        >
          <Box>
            {routeID && (
              <Breadcrumbs
                items={[
                  breadcrumbConstants.farmVisitSummary(`${routeID}`),
                  { title: 'Farm Vist Recommendations' },
                ]}
              />
            )}
            <Card style={styles.header}>
              <Text
                bodyBold
                style={styles.title}
                testID="farm-visit-recommendations-title"
              >
                Recommendations
              </Text>
              <Box>{recommendationsList()}</Box>
            </Card>
            {!device.isHandset && productButton()}
          </Box>
          <Box alignSelf="flex-end" width="100%" mb={2}>
            <Disclaimer />
          </Box>
        </ScrollView>
        {device.isHandset && productButton()}
      </>
    </QueryCard>
  );
};

export default FarmVisitRecommendations;
