import React, { FC } from 'react';
import { Box, Text, useDeviceType, useStyles } from '@nutrien/bonsai-core';
import { useFormatters } from '../../hooks';
import overviewCopy from './copy.json';
import { FinancialOverviewProps } from './AccountOverviewBody';

const copy = overviewCopy.prePayOverview;

const PrePayOverview: FC<FinancialOverviewProps> = ({
  summary,
}): JSX.Element => {
  const { CurrentBalance, RewardAmount, TransactionAmount } = summary || {
    CurrentBalance: undefined,
    RewardAmount: undefined,
    TransactionAmount: undefined,
  };
  const { formattedAmount } = useFormatters();
  const { isHandset } = useDeviceType();
  const styles = useStyles((theme) => ({
    balanceValue: {
      fontSize: theme.typography.fontSize.large,
      lineHeight: theme.typography.fontSize.large * 1.5,
    },
    value: {
      fontSize: theme.typography.fontSize.small,
      lineHeight: theme.typography.fontSize.small * 1.5,
    },
    label: {
      color: theme.auColors.neutral[600],
      fontSize: theme.typography.fontSize.medium,
      lineHeight: theme.typography.fontSize.medium * 1.5,
      marginTop: theme.spacing(1),
    },
  }));

  return (
    <Box flexGrow={1} flexDirection={isHandset ? 'column' : 'row'}>
      <Box flexGrow={1} testID="prepay-account-balance" minWidth={'40%'}>
        <Text style={styles.balanceValue} subHeaderBold>
          {formattedAmount(CurrentBalance)}
        </Text>
        <Text style={styles.label}>{copy.balance}</Text>
      </Box>
      <Box
        flexGrow={1}
        testID="prepay-reward"
        justifyContent="space-between"
        py={isHandset ? 4 : 0}
      >
        <Text style={styles.value} captionBold>
          {formattedAmount(RewardAmount)}
        </Text>
        <Text style={styles.label}>{copy.reward}</Text>
      </Box>
      <Box
        flexGrow={1}
        testID="prepay-recent-transactions"
        justifyContent="space-between"
        mb={isHandset ? 4 : 0}
      >
        <Text style={styles.value} captionBold>
          {formattedAmount(TransactionAmount)}
        </Text>
        <Text style={styles.label}>{copy.transactions}</Text>
      </Box>
    </Box>
  );
};

export default PrePayOverview;
