import { useEffect, useState, SetStateAction } from 'react';
import { GetCustomerLatestVerification_getCustomerLatestVerification } from 'types/generated/GetCustomerLatestVerification';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useCreateCustomerLoginMutation,
  useResendCustomerVerificationMutation,
  useCustomerLoginQuery,
  useCustomerLatestVerificationQuery,
  useRoutes,
  useFormatters,
  useSegment,
} from '../../hooks';
import { CustomerDetail, useStaff } from '../../context/StaffContext';
import useLogin from '../Login/useLogin';
import { trackEvent, emailStatus } from '../../constants/segment';
import { errorBannerMessage } from '../../constants';
import useSegmentInviteSent from './useSegmentInviteSent';
import { useSendCustomerInviteMutation } from '../../hooks/gql/mutations/useCustomerSendInviteMutation';
import { GetCustomerLogin_getCustomerLogin } from 'types/generated/GetCustomerLogin';

export interface UserInformation {
  isSendInviteModalOpen: boolean;
  setIsSendInviteModalOpen: (value: SetStateAction<boolean>) => void;
  isUserDataLoading: boolean;
  customerCreatedOrResendJobCreatedDate: string | null;
  createOrResendCustomerLoginLoading: boolean;
  onCloseSendInviteModal: () => void;
  onConfirmSendInvite: () => void;
  lastLoginDate: string | null;
  fetchCustomerLoginLoading: boolean;
  hasGetCustomerLoginError: boolean;
  emailSentDate: string | null;
  verificationLinkExpirationDate: string | null;
  isCustomerVerified?: boolean | null;
  isCustomerInAuth0: boolean;
  errorMessage: string;
}

const DATE_FORMAT = 'dd-MMM-yy';

const useUserInformation = (
  selectedCustomer: CustomerDetail | null
): UserInformation => {
  const [isSendInviteModalOpen, setIsSendInviteModalOpen] = useState(false);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);

  const {
    dispatch,
    state: { showCustomerLoginError },
  } = useStaff();
  const {
    userState: { employeeId },
  } = useLogin();
  const { routeParams } = useRoutes();
  const { formatDate } = useFormatters();
  const segment = useSegment();
  const customerIdFromRoute = routeParams.customerId as string;

  const getEmailSentDate = (
    verificationType: string | null,
    latestVerification:
      | GetCustomerLatestVerification_getCustomerLatestVerification
      | null
      | undefined
  ) => {
    const hasDate = ['sv', 'svr', 'fn', 'ss'].includes(
      verificationType as string
    );

    if (hasDate && latestVerification?.date) {
      return formatDate(latestVerification.date as string, DATE_FORMAT);
    }

    return null;
  };

  const flags = useFlags();

  const isEmailVerified = ({
    updated_at,
    created_at,
    logins_count,
    email_verified,
  }: Partial<GetCustomerLogin_getCustomerLogin>) => {
    const useMSGraph = flags['NG-3365-microsoft-graph-email-provider'];
    if (useMSGraph) {
      const logins = logins_count ?? 0;
      return (
        email_verified &&
        (logins > 0 || (updated_at != created_at && logins === 0))
      );
    }
    return email_verified ?? undefined;
  };

  // getCustomerLogin
  const [getCustomerLoginData, getCustomerLoginLoading, getCustomerLoginError] =
    useCustomerLoginQuery({
      customer_id: customerIdFromRoute,
    });
  const getCustomerLogin = getCustomerLoginData?.getCustomerLogin;
  const lastLoginDate = getCustomerLogin?.last_login
    ? formatDate(getCustomerLogin.last_login as string, DATE_FORMAT)
    : null;
  const isCustomerInAuth0 = !!getCustomerLogin?.email;
  const isCustomerVerified =
    isCustomerInAuth0 && getCustomerLogin
      ? isEmailVerified(getCustomerLogin)
      : false;

  // getCustomerLatestVerification
  const [
    CustomerLatestVerificationData,
    CustomerLatestVerificationLoading,
    CustomerLatestVerificationError,
  ] = useCustomerLatestVerificationQuery({
    customer_id: customerIdFromRoute,
    skip: !isCustomerInAuth0 || !!isCustomerVerified,
  });
  const getCustomerLatestVerification =
    CustomerLatestVerificationData?.getCustomerLatestVerification;
  const verificationType = getCustomerLatestVerification?.type ?? null;
  const emailSentDate = getEmailSentDate(
    verificationType,
    getCustomerLatestVerification
  );
  const verificationLinkIsExpired =
    getCustomerLatestVerification?.verificationLinkIsExpired;
  const verificationLinkExpirationDate =
    verificationLinkIsExpired === true &&
    getCustomerLatestVerification?.verificationLinkExpiration
      ? formatDate(
          getCustomerLatestVerification.verificationLinkExpiration as string,
          DATE_FORMAT
        )
      : null;

  const hasGetCustomerLoginError = !!(
    getCustomerLoginError || CustomerLatestVerificationError
  );

  // createCustomerLogin
  const [
    createCustomerLoginMutation,
    {
      data: createCustomerLoginData,
      error: createCustomerLoginError,
      loading: createCustomerLoginLoading,
    },
  ] = useCreateCustomerLoginMutation();

  // resendCustomerVerification
  const [
    resendCustomerVerificationMutation,
    {
      data: resendCustomerVerificationData,
      error: resendCustomerVerificationError,
      loading: resendCustomerVerificationLoading,
    },
  ] = useResendCustomerVerificationMutation();

  const [
    sendInvite,
    {
      data: _sendCustomerInviteData,
      loading: sendCustomerInviteLoading,
      error: sendCustomerInviteError,
    },
  ] = useSendCustomerInviteMutation();
  const hasSendInviteError = !!(
    createCustomerLoginError ||
    resendCustomerVerificationError ||
    sendCustomerInviteError
  );

  useEffect(() => {
    if (customerIdFromRoute === selectedCustomer?.customerId) {
      setIsUserDataLoading(false);
    }
  }, [selectedCustomer, customerIdFromRoute]);

  useEffect(() => {
    if (hasGetCustomerLoginError || hasSendInviteError) {
      dispatch({
        type: 'setShowCustomerLoginError',
        value: true,
      });
    } else {
      dispatch({
        type: 'setShowCustomerLoginError',
        value: false,
      });
    }
  }, [hasGetCustomerLoginError, hasSendInviteError, dispatch]);

  const createCustomerLogin = createCustomerLoginData?.createCustomerLogin;
  const customerCreatedDate = createCustomerLogin
    ? formatDate(
        (createCustomerLogin.created_at ||
          createCustomerLogin.updated_at) as string,
        DATE_FORMAT
      )
    : null;

  const resendCustomerVerification =
    resendCustomerVerificationData?.resendCustomerVerification;
  const resendJobCreatedDate = resendCustomerVerification?.created_at
    ? formatDate(resendCustomerVerification.created_at as string, DATE_FORMAT)
    : null;

  const onCloseSendInviteModal = () => setIsSendInviteModalOpen(false);

  // Confirm Send Invite and Segment track
  const { isSegmentInviteSentFinished, segmentInviteSentTrack } =
    useSegmentInviteSent(selectedCustomer, customerIdFromRoute, employeeId);

  const onConfirmSendInvite = async () => {
    try {
      let segmentEmailStatus;
      const useMSGraph = flags['NG-3365-microsoft-graph-email-provider'];

      if (isCustomerInAuth0 || customerCreatedDate) {
        if (!useMSGraph) {
          await resendCustomerVerificationMutation({
            variables: { customer_id: selectedCustomer?.customerId as string },
          });
        }
        segmentEmailStatus = emailStatus.RESEND;
      } else {
        await createCustomerLoginMutation({
          variables: { customer_id: selectedCustomer?.customerId as string },
        });
        segmentEmailStatus = emailStatus.SEND;
      }

      // if useMSGraph - call the send invite endpoint
      if (useMSGraph) {
        await sendInvite({
          variables: { customer_id: selectedCustomer?.customerId as string },
        });
      }

      segmentInviteSentTrack(segmentEmailStatus);
    } finally {
      // always ensure the modal is close even on error, as the error displays outside the model.
      setIsSendInviteModalOpen(false);
    }
  };

  const errorMessage = hasGetCustomerLoginError
    ? errorBannerMessage.getRegistrationStatus
    : errorBannerMessage.sendInvitation;

  useEffect(() => {
    const hasGetInvitationStatusError =
      hasGetCustomerLoginError && showCustomerLoginError;
    const hasSentInvitationError =
      (hasSendInviteError || showCustomerLoginError) &&
      isSegmentInviteSentFinished;

    if (hasGetInvitationStatusError || hasSentInvitationError) {
      if (segment) {
        segment.track(trackEvent.ERROR, {
          error_message: errorMessage,
          screen_name: 'UserSummary',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSegmentInviteSentFinished,
    hasGetCustomerLoginError,
    hasSendInviteError,
  ]);

  return {
    isSendInviteModalOpen,
    setIsSendInviteModalOpen,
    isUserDataLoading,
    customerCreatedOrResendJobCreatedDate:
      customerCreatedDate || resendJobCreatedDate,
    createOrResendCustomerLoginLoading:
      createCustomerLoginLoading ||
      resendCustomerVerificationLoading ||
      sendCustomerInviteLoading,
    onCloseSendInviteModal,
    onConfirmSendInvite,
    lastLoginDate,
    fetchCustomerLoginLoading:
      getCustomerLoginLoading || CustomerLatestVerificationLoading,
    hasGetCustomerLoginError,
    emailSentDate,
    verificationLinkExpirationDate: !resendJobCreatedDate
      ? verificationLinkExpirationDate
      : null,
    isCustomerInAuth0,
    isCustomerVerified,
    errorMessage,
  };
};

export default useUserInformation;
