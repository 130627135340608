import { PartyGetPersonDetailsWithAccounts } from '../../../types/generated/PartyGetPersonDetailsWithAccounts';
import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import {
  PartyGetPersonDetailsWithAccountsById,
  PartyGetPersonDetailsWithAccountsByIdVariables,
} from '../../../types/generated/PartyGetPersonDetailsWithAccountsById';

export const PERSON_DETAILS_WITH_ACCOUNTS = gql`
  query PartyGetPersonDetailsWithAccounts {
    party_vw_staff_dashboard {
      account_id_list
      person_party_id
      auth_id
      email
      family_name
      given_name
      onboarded_by_branch_code
    }
  }
`;

export const PERSON_DETAILS_WITH_ACCOUNTS_BY_ID = gql`
  query PartyGetPersonDetailsWithAccountsById($customerId: uuid) {
    party_vw_get_person_details(where: { party_id: { _eq: $customerId } }) {
      auth_id
      party_id
      family_name
      given_name
      preferred_name
      email
      onboarded_by_branch_code
      mobile_number
      accounts {
        account_id
        sords_customer_account {
          account_type
          name1
          name2
          account_id_trimmed
          account_finances {
            available_credit
            change_date
            credit_limit
            interest_calculation
            is_finance_account
            is_livestock_account
            is_nominated_account
            is_prepay_account
            last_payment_amount
            last_payment_date
            limit_exceeded_date
            total_receivables
          }
        }
      }
    }
  }
`;

export type Refetch = () => Promise<
  ApolloQueryResult<PartyGetPersonDetailsWithAccounts>
>;

export type RefetchByPersonId = (
  variables?:
    | Partial<PartyGetPersonDetailsWithAccountsByIdVariables>
    | undefined
) => Promise<ApolloQueryResult<PartyGetPersonDetailsWithAccountsById>>;

const usePersonDetailsWithAccountsQuery = ({
  skip = false,
}): [
  PartyGetPersonDetailsWithAccounts | undefined,
  boolean,
  ApolloError | undefined,
  Refetch
] => {
  const { loading, error, data, refetch } =
    useQuery<PartyGetPersonDetailsWithAccounts>(PERSON_DETAILS_WITH_ACCOUNTS, {
      skip,
      notifyOnNetworkStatusChange: true,
    });
  return [data, loading, error, refetch];
};

const usePersonDetailsByIdQuery = ({
  customerId,
  skip,
}: {
  customerId: string;
  skip?: boolean;
}): [
  PartyGetPersonDetailsWithAccountsById | undefined,
  boolean,
  ApolloError | undefined,
  RefetchByPersonId
] => {
  const { loading, error, data, refetch } = useQuery<
    PartyGetPersonDetailsWithAccountsById,
    PartyGetPersonDetailsWithAccountsByIdVariables
  >(PERSON_DETAILS_WITH_ACCOUNTS_BY_ID, {
    skip,
    variables: { customerId },
  });
  return [data, loading, error, refetch];
};

export { usePersonDetailsWithAccountsQuery, usePersonDetailsByIdQuery };
