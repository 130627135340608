import React, { FC } from 'react';
import { Text, Box, NamedStyles, useStyles } from '@nutrien/bonsai-core';

import { useFormatters } from '../../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../../SummaryCard';

export interface PrepayPanelProps {
  summary?: AccountSummaryData | null;
  panelStyles?: NamedStyles<Record<string, undefined>>;
}

const PrepayPanel: FC<PrepayPanelProps> = ({ summary, panelStyles }) => {
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(panelStyles ?? {});

  return (
    <SummaryCard
      heading="Pre-Pay"
      summaryRow={[
        {
          label: (
            <Box
              style={styles.label}
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text style={styles.labelText}>Current balance</Text>
            </Box>
          ),
          content: renderNoneIfZero(summary?.CurrentBalance),
          testID: 'current-balance-column',
        },
        {
          label: (
            <Box
              style={styles.label}
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text style={styles.labelText}>Latest monthly reward</Text>
            </Box>
          ),
          content: renderNoneIfZero(summary?.RewardAmount),
          testID: 'reward-column',
        },
        {
          label: (
            <Box
              style={styles.label}
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text style={styles.labelText}>
                Transactions in the past 30 days
              </Text>
            </Box>
          ),
          content: renderNoneIfZero(summary?.TransactionAmount),
          testID: 'transactions-column',
        },
      ]}
      testID="account-details-prepay-panel"
    />
  );
};

export default PrepayPanel;
