import React, { useEffect, useState } from 'react';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import { useReactiveVar } from '@apollo/client';
import {
  InputLabel,
  DatePicker,
  useDeviceType,
  useStyles,
} from '@nutrien/bonsai-core';
import { useTransaction } from '../../context/TransactionContext';
import { selectedAccountVar } from '../../reactiveVariables';
import { trackEvent } from '../../constants/segment';
import { useSegment } from '../../hooks';

const now = new Date();

interface TransactionDateRangeProps {
  showTabs: boolean;
}

export type OnConfirmParams = {
  date: Date;
};

const TransactionDateRange = ({
  showTabs,
}: TransactionDateRangeProps): JSX.Element => {
  const segment = useSegment();
  const {
    state: { fromDate, toDate },
    setToDate,
    setFromDate,
  } = useTransaction();
  const { isDesktop } = useDeviceType();
  const { account_id, account_view } = useReactiveVar(selectedAccountVar);
  const [confirmed, setConfirmed] = useState(false);
  const formattedDate = {
    fromDate: format(fromDate, 'yyyy-MM-dd'),
    toDate: format(toDate, 'yyyy-MM-dd'),
  };
  const styles = useStyles((theme) => ({
    dateInput: {
      flexShrink: 1,
      flexGrow: 1,
      marginHorizontal: theme.spacing(showTabs ? 2 : 0.5),
    },
    dateInputDesktop: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  }));
  const commonInputProps = {
    inputStyle: isDesktop ? { width: 85 } : {},
    containerStyle: isDesktop ? styles.dateInputDesktop : {},
  };
  const commonDatePickerProps = {
    containerStyle: styles.dateInput,
    dateFormat: 'dd/MM/yyyy',
    locale: 'en-AU',
  };

  useEffect(() => {
    if (confirmed) {
      segment?.track(trackEvent.DATE_RANGE_SET, {
        account_id,
        tab_header: account_view.selected_tab,
        tab_label: account_view.display_view,
        search_type: account_view.date_view,
        date_from: formattedDate.fromDate,
        date_to: formattedDate.toDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, confirmed]);

  const onConfirmFromDate = (date: OnConfirmParams) => {
    setFromDate(date);
    const fromDateWithoutTime = new Date(date.date.toDateString());
    const toDateWithoutTime = new Date(toDate.toDateString());
    const oneYearBeforeToDate = sub(toDateWithoutTime, { years: 1 });
    // Check if the set `fromDate` is before the expected one year range from `toDate`.
    if (isBefore(fromDateWithoutTime, oneYearBeforeToDate)) {
      // If yes, then set `toDate` as exactly one year after `fromDate`.
      setToDate({
        date: add(date.date, { years: 1 }),
      });
    }
    setConfirmed(true);
  };

  const onConfirmToDate = (date: OnConfirmParams) => {
    setToDate(date);
    const toDateWithoutTime = new Date(date.date.toDateString());
    const fromDateWithoutTime = new Date(fromDate.toDateString());
    const oneYearAfterFromDate = add(fromDateWithoutTime, { years: 1 });
    // Check if the set `toDate` is after the expected one year range from `fromDate`.
    if (isBefore(oneYearAfterFromDate, toDateWithoutTime)) {
      // If yes, then set `fromDate` as exactly one year before `toDate`.
      setFromDate({
        date: sub(date.date, { years: 1 }),
      });
    }
    setConfirmed(true);
  };

  return (
    <>
      <DatePicker
        validRange={{
          startDate: sub(now, { years: 3 }),
          endDate: now,
        }}
        inputProps={{
          ...commonInputProps,
          testID: 'datepicker-from-date',
          label: <InputLabel label="From" />,
        }}
        onConfirm={(date) => onConfirmFromDate(date as OnConfirmParams)}
        date={fromDate}
        {...commonDatePickerProps}
      />
      <DatePicker
        validRange={{
          startDate: fromDate,
          endDate: now,
        }}
        inputProps={{
          ...commonInputProps,
          testID: 'datepicker-to-date',
          label: <InputLabel label="To" />,
        }}
        onConfirm={(date) => onConfirmToDate(date as OnConfirmParams)}
        date={toDate}
        {...commonDatePickerProps}
      />
    </>
  );
};

export default TransactionDateRange;
