import React from 'react';
import {
  Auth0Context,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react';

export const AuthContext = Auth0Context;

const AuthProvider = (props: Auth0ProviderOptions): JSX.Element => {
  const redirectUri = window.location.origin;

  return (
    <Auth0Provider redirectUri={redirectUri} {...props} useRefreshTokens>
      {props.children}
    </Auth0Provider>
  );
};

export default AuthProvider;
