import { TextStyle } from 'react-native';
import { Theme, createTheme } from '@nutrien/bonsai-core';
import { auPalette } from './palette';
import { auText } from './typography';

export const auColors = {
  functional: {
    error: {
      dark: auPalette.brand.barn[300],
      light: auPalette.brand.barn[100],
    },
    warning: {
      dark: auPalette.brand.harvest[400],
      light: auPalette.brand.harvest[100],
    },
    warningAlt: {
      dark: auPalette.brand.flaxen[300],
      light: auPalette.brand.flaxen[100],
    },
    info: {
      dark: auPalette.brand.twilight[400],
      light: auPalette.brand.twilight[100],
    },
    success: {
      dark: auPalette.brand.sky[300],
      light: auPalette.brand.sky[100],
    },
    disabled: {
      dark: auPalette.neutral[800],
      light: auPalette.neutral[400],
    },
    focused: {
      dark: auPalette.brand.twilight[300],
      light: auPalette.brand.twilight[200],
    },
    overlay: {
      dark: 'rgba(0, 0, 0, 0.66)',
      light: 'rgba(0, 0, 0, 0.33)',
    },
  },
  primary: {
    default: auPalette.brand.denim[300],
    ...auPalette.brand.denim,
  },
  secondary: {},
  accent: {
    default: auPalette.brand.leaf1[200],
    dark: {
      ...auPalette.brand.leaf2,
    },
    light: {
      ...auPalette.brand.leaf1,
    },
  },
  neutral: auPalette.neutral,
  brand: auPalette.brand,
};

const colors = {
  primary: auColors.primary.default,
  backdrop: 'rgba(0,0,0,0.75)',
};

const shape = {
  borderRadius: 4,
  borderRadiusMedium: 8,
  borderRadiusLarge: 16,
  borderRadiusXLarge: 30,
};

const typography = {
  fontFamily: {
    regular: 'museoSansLight300',
    semiBold: 'museoSansMedium500',
    bold: 'focoBold700',
  },
};

const components = {
  Button: {
    defaultProps: {
      titleStyle: {
        fontFamily: typography.fontFamily.semiBold,
      },
    },
  },
};

const Text = {
  desktop: {
    body: { fontFamily: typography.fontFamily.regular },
    bodyBold: { fontFamily: typography.fontFamily.semiBold },
    caption: { fontFamily: typography.fontFamily.regular, fontSize: 14 },
    captionBold: { fontFamily: typography.fontFamily.semiBold, fontSize: 14 },
    subHeader: { fontFamily: typography.fontFamily.regular, fontSize: 18 },
    subHeaderBold: { fontFamily: typography.fontFamily.bold, fontSize: 18 },
    h1: { fontFamily: typography.fontFamily.bold, fontSize: 28 },
    h2: { fontFamily: typography.fontFamily.bold, fontSize: 23 },
    h3: { fontFamily: typography.fontFamily.bold, fontSize: 18 },
    h4: { fontFamily: typography.fontFamily.bold, fontSize: 16 },
    h5: { fontFamily: typography.fontFamily.bold },
    hero: { fontFamily: typography.fontFamily.bold, fontSize: 46 },
  },
  mobile: {
    body: { fontFamily: typography.fontFamily.regular },
    bodyBold: { fontFamily: typography.fontFamily.semiBold },
    caption: { fontFamily: typography.fontFamily.regular, fontSize: 14 },
    captionBold: { fontFamily: typography.fontFamily.semiBold, fontSize: 14 },
    subHeader: { fontFamily: typography.fontFamily.regular, fontSize: 18 },
    subHeaderBold: { fontFamily: typography.fontFamily.semiBold, fontSize: 18 },
    h1: { fontFamily: typography.fontFamily.bold, fontSize: 24 },
    h2: { fontFamily: typography.fontFamily.bold, fontSize: 21 },
    h3: { fontFamily: typography.fontFamily.bold, fontSize: 18 },
    h4: { fontFamily: typography.fontFamily.bold, fontSize: 16 },
    h5: { fontFamily: typography.fontFamily.bold },
    hero: { fontFamily: typography.fontFamily.bold, fontSize: 36 },
  },
};

/**
 * au-digital-hub typography styles from The Design system
 * https://www.figma.com/file/8ZeSX3xjPr9fboYrfUXWSu/Design-system-concept?node-id=1732%3A7744
 */

/**
 * Body regular: Museo Sans 300 Size: 16px. Leading: 24px
 */
const body: TextStyle = {
  color: auColors.neutral[800],
  lineHeight: 24,
  fontFamily: auText.fontFamily.body,
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: '300',
};
/**
 * Body caption: Museo Sans 300 Italic Size: 14px. Leading: 21px
 */
const bodyCaption: TextStyle = {
  ...body,
  lineHeight: 21,
  fontFamily: auText.fontFamily.bodyCaption,
  fontSize: 14,
  fontStyle: 'italic',
};
/**
 * Body bold: Museo Sans 500 Size: 16px. Leading: 24px
 */
const bodyBold: TextStyle = {
  ...body,
  fontFamily: auText.fontFamily.bodyBold,
  fontWeight: '500',
};
/**
 * Body extra bold: Museo Sans 700 Size: 16px. Leading: 24px
 */
const bodyExtraBold: TextStyle = {
  ...body,
  fontFamily: auText.fontFamily.bodyExtraBold,
  fontWeight: '700',
};
/**
 * Body extra bold: Museo Sans 700 Size: 18px. Leading: 23px
 */
const bodyEmphasis: TextStyle = {
  ...body,
  lineHeight: 23,
  fontFamily: auText.fontFamily.bodyExtraBold,
  fontSize: 18,
  fontWeight: '700',
};
/**
 * Common header styles
 */
const headerTextStyles: TextStyle = {
  ...body,
  fontFamily: auText.fontFamily.heading,
  fontWeight: '700',
};
const mobileStyles: Theme['auTextStyles'] = {
  /**
   * Heading extra small: Foco Bold Size: 16px. Leading: 20px
   */
  headingExtraSmall: {
    ...headerTextStyles,
    fontSize: auText.mobile.fontSize.xSmall,
    lineHeight: auText.mobile.lineHeight.xSmall,
  },
  /**
   * Heading small: Foco Bold Size: 18px. Leading: 23px
   */
  headingSmall: {
    ...headerTextStyles,
    fontSize: auText.mobile.fontSize.small,
    lineHeight: auText.mobile.lineHeight.small,
  },
  /**
   * Heading medium: Foco Bold Size: 21px. Leading: 26px
   */
  headingMedium: {
    ...headerTextStyles,
    fontSize: auText.mobile.fontSize.medium,
    lineHeight: auText.mobile.lineHeight.medium,
  },
  /**
   * Heading large: Foco Bold Size: 24px. Leading: 30px
   */
  headingLarge: {
    ...headerTextStyles,
    fontSize: auText.mobile.fontSize.large,
    lineHeight: auText.mobile.lineHeight.large,
  },
  /**
   * Heading extra large: Foco Bold Size: 36px. Leading: 45px
   */
  headingExtraLarge: {
    ...headerTextStyles,
    fontSize: auText.mobile.fontSize.xLarge,
    lineHeight: auText.mobile.lineHeight.xLarge,
  },
};

const desktopStyles: Theme['auTextStyles'] = {
  ...mobileStyles,
  /** Note: xSmall and Small are the same across viewports. */
  /**
   * Heading medium: Foco Bold Size: 23px. Leading: 29px
   */
  headingMedium: {
    ...mobileStyles.headingMedium,
    fontSize: auText.desktop.fontSize.medium,
    lineHeight: auText.desktop.lineHeight.medium,
  },
  /**
   * Heading large: Foco Bold Size: 28px. Leading: 35px
   */
  headingLarge: {
    ...mobileStyles.headingLarge,
    fontSize: auText.desktop.fontSize.large,
    lineHeight: auText.desktop.lineHeight.large,
  },
  /**
   * Heading extra large: Foco Bold Size: 46px. Leading: 58px
   */
  headingExtraLarge: {
    ...mobileStyles.headingExtraLarge,
    fontSize: auText.desktop.fontSize.xLarge,
    lineHeight: auText.desktop.lineHeight.xLarge,
  },
};

const auTypography: Theme['auTypography'] = {
  mobile: {
    body: body,
    bodyCaption: bodyCaption,
    bodyBold: bodyBold,
    bodyExtraBold: bodyExtraBold,
    bodyEmphasis: bodyEmphasis,
    ...mobileStyles,
  },
  desktop: {
    body: body,
    bodyCaption: bodyCaption,
    bodyBold: bodyBold,
    bodyExtraBold: bodyExtraBold,
    bodyEmphasis: bodyEmphasis,
    ...desktopStyles,
  },
};

export default createTheme({
  auColors,
  colors,
  shape,
  typography,
  Text,
  auTypography,
  components,
} as unknown as Theme);
