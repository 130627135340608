import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Box } from '@nutrien/bonsai-core';
import HeaderLogo from './HeaderLogo';
import HeaderRight from './HeaderRight';

const Header = ({
  isSimpleHeader,
}: {
  isSimpleHeader?: boolean;
}): JSX.Element => {
  const isSimpleHeaderNotOnWeb = isSimpleHeader && Platform.OS !== 'web';
  const isSimpleHeaderOnAndroid = isSimpleHeader && Platform.OS === 'android';

  const contentProps = {
    flexDirection: 'row' as const,
    alignItems: isSimpleHeaderNotOnWeb ? 'flex-end' : 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    maxWidth: 990,
    // adjust styling on android
    ...(isSimpleHeaderOnAndroid && { paddingBottom: 2 }),
  };

  const wrapperProps = {
    bg: 'white',
    flexDirection: 'row' as const,
    testID: 'header',
    height: isSimpleHeaderNotOnWeb ? '90px' : 'auto',
    justifyContent: 'center',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: 'grey.300',
  };

  return (
    <Box {...wrapperProps}>
      <Box {...contentProps}>
        <HeaderLogo />
        {!isSimpleHeader && <HeaderRight />}
      </Box>
    </Box>
  );
};

export default Header;
