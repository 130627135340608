import { ImageURISource } from 'react-native';

interface Image {
  name: string;
  imagePathArray: ImageURISource[];
  defaultImageSource: ImageURISource;
}
/**
 * maps app section name to image assets as an array of type ImageURISource.
 * images are arranged from smallest to largest.
 */
const imageSourceArray: Image[] = [
  {
    name: 'CustomerSelfRegistration',
    imagePathArray: [
      {
        uri: require('../../../assets/selfRegistration/md.webp'),
        width: 2592,
        height: 1728,
      },
      {
        uri: require('../../../assets/selfRegistration/xl.webp'),
        width: 3456,
        height: 2304,
      },
    ],
    defaultImageSource: {
      uri: require('../../../assets/selfRegistration/blurred.webp'),
    },
  },
];

/**
 * Supply in image name and receive image sources as an array of type ImageURISource.
 */
const getImagePathArray = (imageName: string) => {
  const returnSource = imageSourceArray.filter((imageArray: Image) => {
    return imageArray.name === imageName;
  });
  return returnSource[0].imagePathArray;
};

/**
 * Given the current window width and supplied image array return the most appropriate
 * sized image. Note: In native we simply have to provide the array of sources and the device
 * will decide which image to use.
 */
const determineImageSizeForWeb = (
  windowWidth: number,
  imageArray: ImageURISource[]
) => {
  if (imageArray.length < 2) {
    return imageArray[0];
  }
  /** Iterate backwards through imageArray (from largest to smallest)
   * until the closet image size found else return the smallest image.
   */
  const findCorrectImageSource = imageArray
    .reverse()
    .filter((item: ImageURISource, index: number) => {
      return item.width
        ? item.width <= windowWidth || index === imageArray.length - 1
        : index === imageArray.length - 1;
    });
  return findCorrectImageSource[0];
};

/**
 * Supply in image name and receive the default image sources
 * a blurred low resolution version of the image to be used
 * in the defaultSource property which is "A static image to display while loading the image source"
 */
const getDefaultImageSource = (imageName: string) => {
  const returnSource = imageSourceArray.filter((imageArray: Image) => {
    return imageArray.name === imageName;
  });
  return returnSource[0].defaultImageSource;
};

export { getImagePathArray, determineImageSizeForWeb, getDefaultImageSource };
