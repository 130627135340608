import React, { useState, useEffect } from 'react';
import { Avatar } from 'native-base';
import { Grid, Box, Text, useTheme, Icon, Dialog } from '@nutrien/bonsai-core';
import ContactDetailsPanel from '../../components/ContactDetailsPanel';
import { useStaff } from '../../context/StaffContext';
import { breadcrumbConstants } from '../../constants';
import Breadcrumbs from '../../components/Breadcrumbs';
import ActionList from '../../components/ActionList';
import { Button } from '../../components/Button';
import UnsavedModal from '../../components/UnsavedModal/UnsavedModal';

const CreateUser = (): JSX.Element => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dispatch } = useStaff();

  useEffect(() => {
    dispatch({
      type: 'setShowSaveUserSuccess',
      value: false,
    });
    dispatch({
      type: 'setSelectedCustomer',
      value: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);
  const styles = {
    header: {
      marginBottom: theme.spacing(1),
    },
    grid: {
      flex: 1,
    },
  };

  return (
    <>
      <Grid item style={styles.grid}>
        <Grid item xs={12}>
          <Breadcrumbs
            items={[breadcrumbConstants.home, { title: 'Create User' }]}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box flex={1} p={2} background={theme.auColors.neutral[100]}>
            <Box alignItems="center">
              <Text h2 style={styles.header} testID="user-profile-heading">
                User Profile
              </Text>
              <Text>New User</Text>
            </Box>
            <Box p={2} justifyContent="center" alignItems="center">
              <Avatar
                testID="new-user-avatar"
                size={96}
                backgroundColor="brand.leaf1"
              >
                <Icon
                  testID="person-icon"
                  name="person"
                  size={60}
                  color={theme.auColors.neutral[800]}
                />
              </Avatar>
            </Box>
            <Box mb={1}>
              <Text bodyBold>Main Menu</Text>
            </Box>
            <Box mb={1}>
              <ActionList
                title="Contact Details"
                testID="add-contact-details"
                iconLeft={{
                  name: 'phone',
                  testID: 'contact-details-phone-icon',
                  size: 24,
                }}
                selected
              />
            </Box>
            <Box mb={1}>
              <ActionList
                title="Accounts"
                testID="accounts-details"
                iconLeft={{
                  name: 'account-balance',
                  testID: 'accounts-icon',
                  size: 24,
                }}
                onPress={openModal}
              />
            </Box>
            <Box mb={1}>
              <ActionList
                title="User Summary"
                testID="user-summary-nav"
                iconLeft={{
                  name: 'person',
                  testID: 'user-summary-nav-icon',
                  size: 24,
                }}
                iconRight={{
                  name: 'chevron-right',
                  testID: 'chevron-right-icon',
                  size: 16,
                  color: theme.auColors.primary.default,
                }}
                onPress={openModal}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
          <ContactDetailsPanel type="create" />
        </Grid>
      </Grid>
      <Dialog visible={isModalOpen} onDismiss={closeModal}>
        <Dialog.Title title="Create user first" />
        <Dialog.Content>
          <Text testID="notification-modal-content">
            Please create the user before proceeding
          </Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            title="Okay"
            type="outline"
            onPress={closeModal}
            testID="notification-modal-button"
          />
        </Dialog.Actions>
      </Dialog>
      <UnsavedModal
        title="Unsaved changes"
        message="You have unsaved changes on this page. Do you wish to continue?"
      />
    </>
  );
};

export default CreateUser;
