import { Column } from '@nutrien/bonsai-core';

const baseColumns = [
  {
    accessor: 'issued',
    maxWidth: 140,
    minWidth: 100,
  },
  {
    accessor: 'due',
    maxWidth: 140,
    minWidth: 100,
  },
  {
    accessor: 'invoiceNumber',
    Header: 'invoice #',
    justifyContent: 'center',
    maxWidth: 140,
    minWidth: 120,
  },
  {
    accessor: 'category',
    maxWidth: 140,
    minWidth: 140,
  },
  {
    accessor: 'description',
    maxWidth: 250,
    minWidth: 250,
  },
  {
    accessor: 'documentId',
    render: () => null,
  },
  {
    accessor: 'documentAccountType',
    render: () => null,
  },
] as Column[];

export const tableColumns = [
  ...baseColumns,
  {
    accessor: 'proceeds',
    Header: 'Proceeds',
    maxWidth: 100,
    minWidth: 100,
  },
  {
    accessor: 'purchases',
    Header: 'Purchases',
    maxWidth: 100,
    minWidth: 100,
    justifyContent: 'flex-end',
  },
] as Column[];
