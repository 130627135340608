import React from 'react';
import {
  useTheme,
  useDeviceType,
  Box,
  Text,
  ListItem,
  ListItemProps,
} from '@nutrien/bonsai-core';
import { SearchCustomerAccounts_sords_customer_account } from 'types/generated/SearchCustomerAccounts';
import { Platform } from 'react-native';
import { UseStaffDashboard } from './useStaffDashboard';

interface Props {
  customerAccount: SearchCustomerAccounts_sords_customer_account;
  handleSearchResultClick: UseStaffDashboard['handleSearchResultClick'];
  isHighlighted?: boolean;
}

interface Styles {
  smallRightMargin: {
    marginRight: number;
  };
  rowDefaullt: {
    flex: number;
  };
  content:
    | false
    | {
        color: string;
      }
    | undefined;
  containerStyle: {
    padding: number;
    cursor: string;
  };
}

interface DetailProps {
  detail: string | null;
  testID: string;
  rowStyle?: { flex?: number };
  styles: Styles;
}

interface AccountRowDetailProps {
  isDesktop: boolean;
  telephone: string | null;
  name1: string | null;
  name2: string | null;
  account_email: string | null;
  abn: string | null;
  styles: Styles;
}

const Detail = ({ detail, testID, rowStyle, styles }: DetailProps) => (
  <Box
    style={[styles.rowDefaullt, styles.smallRightMargin, rowStyle]}
    testID={testID}
  >
    <Text ellipsizeMode="tail" numberOfLines={1} style={styles.content}>
      {detail}
    </Text>
  </Box>
);

const AccountRowDetail = ({
  isDesktop,
  telephone,
  name1,
  name2,
  account_email,
  abn,
  styles,
}: AccountRowDetailProps) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    width="100%"
    testID={`account-row-${name1}`}
  >
    <Box
      style={[styles.rowDefaullt, styles.smallRightMargin]}
      testID="account-name"
    >
      <Text ellipsizeMode="tail" numberOfLines={1} style={styles.content}>
        {name1}
      </Text>
    </Box>
    {isDesktop && (
      <>
        <Detail {...{ detail: name2, testID: 'name', styles }} />
        <Detail
          {...{
            detail: telephone,
            testID: 'phone-number',
            rowStyle: { flex: 2 },
            styles,
          }}
        />
        <Detail
          {...{ detail: account_email, testID: 'account-email', styles }}
        />
        <Detail {...{ detail: abn && `ABN: ${abn}`, testID: 'abn', styles }} />
      </>
    )}
  </Box>
);

const AccountRow = ({
  customerAccount,
  handleSearchResultClick,
  isHighlighted,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const styles = {
    smallRightMargin: { marginRight: theme.spacing(1) },
    rowDefaullt: { flex: 3 },
    content: isHighlighted && { color: theme.auColors.neutral[200] },
    containerStyle: { padding: theme.spacing(2), cursor: 'pointer' },
  };

  const { customer_guid, name1, name2, telephone, account_email, abn } =
    customerAccount;

  if (Platform.OS === 'web') {
    return (
      <Box style={styles.containerStyle}>
        <AccountRowDetail
          {...{
            isDesktop,
            telephone,
            name1,
            name2,
            account_email,
            abn,
            styles,
          }}
        />
      </Box>
    );
  }

  const listProps = {
    onPress: () => handleSearchResultClick(customerAccount),
  } as unknown as ListItemProps;

  return (
    <ListItem key={customer_guid || name1} {...listProps}>
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Box
          style={[styles.rowDefaullt, styles.smallRightMargin]}
          testID="account-name"
        >
          <Text ellipsizeMode="tail" numberOfLines={1} style={styles.content}>
            {name1}
          </Text>
        </Box>
        {isDesktop && (
          <AccountRowDetail
            {...{
              isDesktop,
              telephone,
              name1,
              name2,
              account_email,
              abn,
              styles,
            }}
          />
        )}
      </Box>
    </ListItem>
  );
};

export default AccountRow;
