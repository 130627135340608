import React, { useRef, useEffect } from 'react';
import { Text, useDeviceType, useStyles, Box } from '@nutrien/bonsai-core';
import { View, Animated } from 'react-native';
import {
  GetLotDetails_getLotDetails_result_LotDetails as LotDetailsType,
  GetLotDetails_getLotDetails_result_LotFeeDetails as LotFeeDetailsType,
} from 'types/generated/GetLotDetails';
import {
  SubItems,
  LotDetailRow,
} from '../../hooks/useTransactions/transaction.helper';
import { useFormatters } from '../../hooks';
import { SkeletonPill } from '../Skeleton';
import { Transaction } from '../Transaction/transactions.types';
import TransactionSubItem from './TransactionSubItem';

interface TransactionWithLotItems {
  subItems?: SubItems;
  category: { value: string | null };
  description: { value: string };
  price: { displayValue: string };
}
export interface TransactionItemProps {
  item: Transaction;
  isLast: boolean;
  itemLotData?: LotDetailRow;
  isExpanded?: boolean;
  lotDetailsLoading?: boolean;
}

interface ItemRow {
  item: TransactionWithLotItems;
  isLast: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
}

interface SubItemRow {
  subItems: SubItems;
  isExpanded?: boolean;
}

const SubItemRows = ({ subItems, isExpanded }: SubItemRow) => {
  const styles = useStyles(() => {
    return {
      content: {
        overflow: 'hidden',
      },
    };
  });

  // Height animation of content.
  const heightAnimation = useRef<Animated.Value>(new Animated.Value(0)).current;
  const maxHeight = heightAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 9999],
  });
  useEffect(() => {
    if (isExpanded) {
      Animated.timing(heightAnimation, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(heightAnimation, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  }, [heightAnimation, isExpanded]);

  return (
    <Box style={styles.content} aria-hidden={!isExpanded} testID="sub-item">
      <Animated.View style={{ maxHeight }}>
        <Box marginY={2}>
          {(subItems.list as LotDetailsType[]).map(
            (subItem: LotDetailsType | LotFeeDetailsType, index: number) => (
              <TransactionSubItem key={index} subItem={subItem} />
            )
          )}
        </Box>
      </Animated.View>
    </Box>
  );
};

const DetailsRow = ({
  item: { category, description, price, subItems },
  isExpanded,
  isLast,
  isLoading,
}: ItemRow): JSX.Element => {
  const displayPrice = price.displayValue;
  const styles = useStyles((theme) => {
    return {
      row: {
        paddingVertical: theme.spacing(1),
        paddingHorizontal: theme.spacing(2),
        ...(!isLast && {
          borderBottomColor: theme.auColors.neutral[400],
          borderBottomWidth: 1,
        }),
      },
      alignRight: {
        marginRight: 0,
        marginLeft: 'auto',
      },
      description: {
        color: theme.auColors.neutral[800],
        fontSize: theme.typography.fontSize.small,
        fontWeight: '600',
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
      category: {
        color: theme.auColors.neutral[600],
        fontSize: theme.typography.fontSize.small,
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
      price: {
        fontSize: theme.typography.fontSize.small,
        fontWeight: '600',
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
    };
  });

  return (
    <View style={styles.row} testID="details-row">
      <Box flexDirection="row">
        <Box width="50%" pr={1}>
          <Text style={styles.description}>{description?.value}</Text>
        </Box>
        <Box width="30%">
          <Text style={styles.category}>{category?.value}</Text>
        </Box>
        <Box width="20%" alignSelf="center">
          {isLoading ? (
            <View style={styles.alignRight}>
              <SkeletonPill width="111" height="14" title="Loading subtotal" />
            </View>
          ) : (
            <View style={styles.alignRight}>
              <Text style={styles.price}>{displayPrice}</Text>
            </View>
          )}
        </Box>
      </Box>
      {subItems && <SubItemRows subItems={subItems} isExpanded={isExpanded} />}
    </View>
  );
};

const CompactDetailsRow = ({
  item: { category, description, price, subItems },
  isExpanded,
  isLast,
  isLoading,
}: ItemRow) => {
  const displayPrice = price.displayValue;
  const styles = useStyles((theme) => {
    return {
      row: {
        paddingVertical: theme.spacing(1),
        paddingHorizontal: theme.spacing(2),
        ...(!isLast && {
          borderBottomColor: theme.auColors.neutral[400],
          borderBottomWidth: 1,
        }),
      },
      alignRight: {
        textAlign: 'right',
        marginRight: 0,
        marginLeft: 'auto',
      },
      description: {
        color: theme.auColors.neutral[800],
        fontSize: theme.typography.fontSize.small,
        fontWeight: '600',
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
      category: {
        color: theme.auColors.neutral[600],
        fontSize: theme.typography.fontSize.small,
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
      price: {
        fontSize: theme.typography.fontSize.small,
        fontWeight: '600',
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
    };
  });

  return (
    <View style={styles.row} testID="compact-details-row">
      <Box flexDirection="row">
        <Box flexDirection="column" width="70%" pr={1}>
          <Text style={styles.description}>{description?.value}</Text>
          <Text style={styles.category}>{category?.value}</Text>
        </Box>
        <Box width="30%">
          {isLoading ? (
            <View style={styles.alignRight}>
              <SkeletonPill width="111" height="14" title="Loading subtotal" />
            </View>
          ) : (
            <View style={styles.alignRight}>
              <Text style={styles.price}>{displayPrice}</Text>
            </View>
          )}
        </Box>
      </Box>
      {subItems && <SubItemRows subItems={subItems} isExpanded={isExpanded} />}
    </View>
  );
};

const TransactionItem = ({
  item,
  isExpanded,
  isLast,
  itemLotData,
  lotDetailsLoading,
}: TransactionItemProps): JSX.Element => {
  const { formatCurrency } = useFormatters();
  const { isDesktop } = useDeviceType();
  const { category, description, price } = item;

  const itemObject = {
    category,
    description,
    price,
    subItems: itemLotData?.lotDetails?.subItems,
  };

  const lotItems = itemLotData
    ? itemLotData.lotFeeDetails
      ? [itemObject, itemLotData.lotFeeDetails]
      : [itemObject]
    : [];

  if (lotItems.length) {
    return (
      <>
        {lotItems.map((rowItem, index) => {
          const props: ItemRow = {
            item: {
              ...(rowItem as TransactionWithLotItems),
              price: {
                displayValue: rowItem?.subItems?.total
                  ? formatCurrency(rowItem.subItems.total)
                  : '',
              },
            },
            isLast: index === lotItems.length - 1,
            isExpanded,
            isLoading: lotDetailsLoading,
          };
          return isDesktop ? (
            <DetailsRow key={index} {...props} />
          ) : (
            <CompactDetailsRow key={index} {...props} />
          );
        })}
      </>
    );
  }

  return (
    <>
      {isDesktop ? (
        <DetailsRow item={item} isExpanded={isExpanded} isLast={isLast} />
      ) : (
        <CompactDetailsRow
          item={item}
          isExpanded={isExpanded}
          isLast={isLast}
        />
      )}
    </>
  );
};

export default TransactionItem;
