import React, { useEffect, useState } from 'react';
import { LogBox, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import AppLoading from 'expo-app-loading';
import { BonsaiProvider, useCustomFonts } from '@nutrien/bonsai-core';
import theme from './theme/theme';
import { StaffProvider } from './context/StaffContext';
import { LoginProvider } from './context/LoginContext';
import { TransactionProvider } from './context/TransactionContext';
import Navigation from './components/Navigation';
import ApolloClientProvider from './components/ApolloClientProvider';
import { AuthProvider } from './auth';
import LaunchDarkly from './utils/launchdarkly';
import '@expo/match-media';
import '@expo/browser-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import { init as initSentry } from './utils/sentry';
import { segmentReactNativeInit } from './utils/segment';

const clientId = process.env.AUTH0_CLIENT_ID || '';
const domain = process.env.AUTH0_DOMAIN || '';
const audienceDomain = process.env.AUTH0_AUDIENCE_DOMAIN || '';

// Mute multiple known warnings to display on device.
LogBox.ignoreLogs(['NativeBase: The contrast ratio of']);
initSentry();

const App = (): JSX.Element => {
  const [customFontsLoaded] = useCustomFonts({
    focoBold700: require('../assets/Foco_W_Bd.ttf'),
    museoSansLight300: require('../assets/MuseoSans_300.otf'),
    museoSansLight300Italic: require('../assets/MuseoSans_300_Italic.otf'),
    museoSansMedium500: require('../assets/MuseoSans_500.otf'),
    museoSansBold700: require('../assets/MuseoSans_700.otf'),
  });

  const [isReady, setIsReady] = useState(false);

  // Initialise the feature flags
  useEffect(() => {
    setIsReady(false);
    LaunchDarkly.init().then(() => setIsReady(true));

    const isWeb = Platform.OS.toLowerCase() === 'web';

    // load pollyfill for smooth horizontal scrolling on safari
    if (isWeb && typeof window !== 'undefined') {
      smoothscroll.polyfill();
    }
  }, []);

  if (!customFontsLoaded || !isReady) {
    return <AppLoading />;
  }

  if (Platform.OS !== 'web') {
    segmentReactNativeInit();
  }

  return (
    <AuthProvider
      audience={`https://${audienceDomain}/api/web`}
      clientId={clientId}
      domain={domain}
      useRefreshTokens={true}
      scope={'offline_access'}
    >
      <ApolloClientProvider>
        <BonsaiProvider theme={theme}>
          <StatusBar style="dark" />
          <LaunchDarkly.Provider>
            <LoginProvider>
              <StaffProvider>
                <TransactionProvider>
                  <Navigation />
                </TransactionProvider>
              </StaffProvider>
            </LoginProvider>
          </LaunchDarkly.Provider>
        </BonsaiProvider>
      </ApolloClientProvider>
    </AuthProvider>
  );
};

export default App;
