import React, { useEffect, useState } from 'react';
import Constants from 'expo-constants';
import { Dimensions, Platform } from 'react-native';
import { Box, Text, useTheme } from '@nutrien/bonsai-core';
import queryString from 'query-string';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Logo from '../Logo';
import LoginButton from '../LoginButton';
import useAuth from '../../auth/hooks';
import useNavigation from '../../hooks/useNavigation/';
import { useRoutes, useSegment } from '../../hooks';
import useApolloClientWithPersist from '../../hooks/useApolloClientWithPersist';
import useLogin from './useLogin';
import { SESSION_TIMEOUT, loginPageName } from '../../constants';
import { trackEvent } from '../../constants/segment';
import { routeConfig } from '../../routes/shared';
import { State } from '../../context/LoginContext';
import { SegmentTraits } from '../../types/Segment.types';

const Login = (): JSX.Element | null => {
  let isSessionTimeout;
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth();
  const { userState, setUserState, clearUserState, onboardedBranchCode } =
    useLogin();
  const { navigate } = useNavigation();
  const { routeParams } = useRoutes();
  const segment = useSegment();
  const { clearCache } = useApolloClientWithPersist();
  const [loginLoading, setLoginLoading] = useState(false);
  const flags = useFlags();

  if (Platform.OS === 'web') {
    isSessionTimeout =
      queryString.parse(window.location.search)?.status === SESSION_TIMEOUT;
  } else {
    // We can't get query string from native, so use routeParams to check
    isSessionTimeout = routeParams?.status === SESSION_TIMEOUT;
  }

  const theme = useTheme();

  const segmentIdentify = async ({ employeeId, customerDomainId }: State) => {
    const userId = customerDomainId || employeeId || '';
    const customerTrait: SegmentTraits = {
      // this will be replaced later when we move to Party Domain
      userId,
      party_type: 'person',
      user_type: 'customer',
      onboarded_branch_code: onboardedBranchCode || '',
    };

    const employeeTrait: SegmentTraits = {
      // this will be replaced later when we move to Party Domain
      userId,
      party_type: 'person',
      user_type: 'Nutrien employee',
    };

    const trait = customerDomainId ? customerTrait : employeeTrait;

    if (segment && userId) {
      try {
        await segment.identify(userId, trait);
        segment.track(trackEvent.LOGIN);
      } catch (err) {
        throw new Error(err as string | undefined);
      }
    }
  };

  const navigateToHome = async () => {
    await segmentIdentify(userState);
    navigate(routeConfig.Home.getNavigateProps());
  };

  useEffect(() => {
    if (!userState?.email && !isAuthenticated && segment) {
      segment.page(loginPageName, {
        pageName: loginPageName,
      });
    }
  }, [userState, isAuthenticated, segment]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!userState.email) {
        setUserState();
      } else {
        navigateToHome();
      }
    } else {
      if (segment) {
        segment.reset();
      }
      const clearApolloCache = async () => {
        await clearCache();
      };
      clearApolloCache();
      clearUserState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, isAuthenticated, navigate, setUserState, clearUserState]);

  useEffect(() => {
    // polyfill for Safari login screen loading state issue NG-3110.
    // Safari would not unmount the login component when navigate away from it,
    // hence loading state would remain true when using back button from auth-0 page.
    const resetLoadingState = () => setLoginLoading(false);
    if (Platform.OS === 'web' && (window as any).safari) {
      window.addEventListener('popstate', resetLoadingState);
    }
    return () => {
      window.removeEventListener('popstate', resetLoadingState);
    };
  }, []);

  type TextAlign = 'center' | 'auto' | 'left' | 'right' | 'justify' | undefined;

  const styles = {
    versionStyle: {
      fontSize: theme.typography.fontSize.small,
    },
    sessionTimeoutStyle: {
      maxWidth: 500,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      textAlign: 'center' as TextAlign,
      paddingHorizontal: theme.spacing(1),
    },
  };
  const _onPress = () => {
    if (flags['NG-3587-login-new-screen']) {
      if (flags['NG-3830-accept-new-tou']) {
        loginWithRedirect({ fragment: 'newscreen_tou' });
      } else {
        loginWithRedirect({ fragment: 'newscreen' });
      }
    } else {
      loginWithRedirect();
    }
    setLoginLoading(true);
  };

  // If the page is loading and we are not logging in, do not display the component, display the spinner
  if (isLoading && !loginLoading) return null;

  return (
    <Box
      bg={'white'}
      flex={1}
      alignItems="center"
      justifyContent="center"
      height={Dimensions.get('screen').height}
    >
      <Box mb={3}>
        <Logo dark />
      </Box>
      {isSessionTimeout && (
        <Box>
          <Text
            style={styles.sessionTimeoutStyle}
            testID="session-timeout-message"
          >
            For your security, you have been logged out due to inactivity.
            Please log in again to continue.
          </Text>
        </Box>
      )}
      <LoginButton isLoading={loginLoading} onPress={_onPress} />
      <Box position="absolute" bottom={3}>
        <Text style={styles.versionStyle}>v{Constants.manifest.version}</Text>
      </Box>
    </Box>
  );
};

export default Login;
