import { useFormatters } from '..';
import {
  Transaction,
  TransactionLineItem,
} from '../../components/Transaction/transactions.types';
import { LotDetailsList } from '../../reactiveVariables/variables/accountTransactionsVar';
import { SearchTransactions_getLineItems } from '../../types/generated/SearchTransactions';
import { roundToDecimalPlaces } from '../useFormatters';
import { getDocumentAccountType } from './transaction.helper';

interface FormatTransactionProps {
  transaction: SearchTransactions_getLineItems;
  item?: Partial<TransactionLineItem>;
}

type LotDetailsParams = {
  formatter: (
    transactions: Transaction[],
    lotDetailsList: LotDetailsList | undefined
  ) => Transaction[];
  isLotDetailsFeatureOn: boolean;
  lotDetailsList: LotDetailsList | undefined;
  sapAccountName?: string | null;
};

export interface CsvTransaction {
  'Account Number': string;
  Issued: string;
  'Sale Date': string;
  Due: string;
  'Invoice #': string;
  Category: string;
  Description: string;
  Quantity: string;
  'Unit of measure': string;
  'Unit price without gst': string;
  'Unit price with gst': string;
  'Unit tax amount': string;
  'Total tax amount': string;
  'Total amount without GST': string;
  'Total amount with GST': string;
}

// TODO: combine with CsvTransaction after removing `NG-3135-plasma-lot-details`
export interface CsvLotDetails {
  Account: string;
  'Lot Number': string;
  'Lot amount(GST exclusive)': string;
  'Lot amount(GST inclusive)': string;
  'Lot GST amount': string;
  'Fee code': string;
  'Fee amount(GST exclusive)': string;
  'Fee amount(GST inclusive)': string;
  'GST on fee': string;
}

export const useTransactionWithLivestockFormatter = () => {
  const { formatTransaction } = useTransactionFormatter();

  /**
   * Format single transaction with additional livestock information
   *
   * @param transaction one transaction record.
   * @returns flatted transactions for table view and CSV export
   */
  const formatTransactionWithLivestock = (
    transaction: SearchTransactions_getLineItems
  ): Transaction[] => {
    const { Items, AccountName, GSTAmount, AmountExcGST } = transaction;

    const flattedTransactions = [] as Transaction[];

    const livestockInfo = {
      accountName: AccountName,
      totalTaxAmount: GSTAmount ? Number(GSTAmount) : undefined,
      totalAmountWithoutGst: AmountExcGST ? Number(AmountExcGST) : undefined,
    };
    // Clean undefined fields to prevent overwriting SAP records
    Object.keys(livestockInfo).forEach(
      (key) =>
        livestockInfo[key as keyof typeof livestockInfo] === undefined &&
        delete livestockInfo[key as keyof typeof livestockInfo]
    );

    if (Items && Items.length !== 0) {
      return flattedTransactions.concat(
        Items.map((item: TransactionLineItem) => ({
          ...formatTransaction({ transaction, item }),
          ...livestockInfo,
        }))
      );
    }

    flattedTransactions.push({
      ...formatTransaction({ transaction }),
      ...livestockInfo,
    });
    return flattedTransactions;
  };

  return formatTransactionWithLivestock;
};

export const useTransactionFormatter = () => {
  const { formatDate, formatExtraSpaces, formatCurrency, formatSentenceCase } =
    useFormatters();

  const formatTransaction = ({
    transaction,
    item = {},
  }: FormatTransactionProps): Transaction => {
    const {
      AccountNumber,
      Description,
      DocumentId,
      DocumentType,
      PostingKey,
      Date: TransactionDate,
      Amount,
      DueDate,
      DocumentNumber,
      RuralcoAccNumber,
      SaleDate,
      HasPdf,
      GroupBy,
      GroupTitle,
      Category: TransactionCategory,
    } = transaction;
    const {
      Category: ItemCategory,
      MaterialDescription,
      Quantity,
      UnitOfMeasure,
      UnitPriceWithOutGST,
      UnitPriceWithGST,
      UnitTaxAmount,
      TotalTaxAmount,
      TotalAmountWithOutGST,
      TotalAmountWithGST,
    } = item;
    const category = ItemCategory
      ? formatSentenceCase(ItemCategory)
      : TransactionCategory;
    const formattedCategory = category ?? '';
    const description = MaterialDescription || Description;
    const formattedDescription = description
      ? formatExtraSpaces(description)
      : '';
    return {
      accountNumber: AccountNumber,
      groupBy: GroupBy,
      groupTitle: GroupTitle,
      category: {
        value: formattedCategory,
        // TODO: NG-2949. Remove displayValue, don't think it is being used.
        displayValue: formattedCategory,
      },
      description: {
        value: formattedDescription,
        // TODO: NG-2949. Remove displayValue, don't think it is being used.
        displayValue: formattedDescription,
      },
      issued: {
        value: TransactionDate || '',
        displayValue: TransactionDate
          ? formatDate(TransactionDate, 'dd MMM yy')
          : '',
      },
      price: {
        value: TotalAmountWithGST || Amount || 0,
        displayValue: formatCurrency(TotalAmountWithGST || Amount || 0),
      },
      due: {
        value: DueDate || '',
        displayValue: DueDate ? formatDate(DueDate, 'dd MMM yy') : '',
      },
      postingKey: PostingKey,
      documentId: DocumentId,
      documentType: DocumentType,
      totalAmount: {
        value: Amount || 0,
        displayValue: formatCurrency(Amount || 0),
      },
      invoiceNumber: HasPdf ? DocumentNumber : '-',
      ruralcoAccNumber: RuralcoAccNumber,
      saleDate: SaleDate,
      quantity: Quantity,
      unitOfMeasure: UnitOfMeasure,
      unitPriceWithoutGst: UnitPriceWithOutGST,
      unitPriceWithGst: UnitPriceWithGST,
      unitTaxAmount: UnitTaxAmount,
      totalTaxAmount: TotalTaxAmount,
      totalAmountWithoutGst: TotalAmountWithOutGST,
      proceeds: {
        value: getProceedValue(transaction, item) ?? '',
        displayValue: formatCurrency(getProceedValue(transaction, item), true),
      },
      purchases: {
        value: getPurchaseValue(transaction, item) ?? '',
        displayValue: formatCurrency(getPurchaseValue(transaction, item), true),
      },
      hasPdf: HasPdf,
      documentAccountType: getDocumentAccountType(DocumentType),
    };
  };

  return { formatTransaction };
};

const getProceedValue = (
  transaction: SearchTransactions_getLineItems,
  item?: Partial<TransactionLineItem>
): number | undefined => {
  if (item?.TotalAmountWithGST && item.TotalAmountWithGST < 0) {
    return item.TotalAmountWithGST;
  }
  if (transaction?.Amount && transaction.Amount < 0) {
    return transaction.Amount;
  }
  return undefined;
};

const getPurchaseValue = (
  transaction: SearchTransactions_getLineItems,
  item?: Partial<TransactionLineItem>
): number | undefined => {
  if (item?.TotalAmountWithGST && item.TotalAmountWithGST > 0) {
    return item.TotalAmountWithGST;
  }
  if (transaction?.Amount && transaction.Amount > 0) {
    return transaction.Amount;
  }
  return undefined;
};

const getAmountForCSV = (
  amount: number | undefined | null,
  decimalPlaces: number
) => roundToDecimalPlaces(amount || undefined, decimalPlaces)?.toString() ?? '';

// CSV format transaction data
export const csvFormatTransactions = (
  transactions: Transaction[],
  {
    formatter,
    isLotDetailsFeatureOn,
    lotDetailsList,
    sapAccountName,
  }: LotDetailsParams
): (CsvTransaction & Partial<CsvLotDetails>)[] => {
  if (transactions && transactions.length) {
    // TODO: remove flag when removing NG-3135-plasma-lot-details
    // Format data for CSV export including lot details, if NG-3135-plasma-lot-details=true
    if (isLotDetailsFeatureOn) {
      const transactionsWithLotDetail = formatter(transactions, lotDetailsList);
      return transactionsWithLotDetail.map((tx) => ({
        'Account Number': tx.accountNumber?.toString() ?? '',
        Account: tx.accountName ?? sapAccountName ?? '',
        Issued: tx.issued.displayValue,
        'Sale Date': tx.saleDate ?? '',
        Due: tx.due.displayValue,
        'Invoice #': tx.invoiceNumber ? tx.invoiceNumber.toString() : '',
        Category: tx.category.value,
        Description: tx.description.value,
        Quantity: tx.quantity ?? '',
        'Unit of measure': tx.unitOfMeasure ?? '',
        'Unit price without gst': getAmountForCSV(tx.unitPriceWithoutGst, 2),
        'Unit price with gst': getAmountForCSV(tx.unitPriceWithGst, 2),
        'Unit tax amount': getAmountForCSV(tx.unitTaxAmount, 2),
        'Lot Number': tx.lotNumber ?? '',
        'Lot amount(GST exclusive)': getAmountForCSV(
          tx.lotTotalAmountWithoutGst,
          2
        ),
        'Lot amount(GST inclusive)': getAmountForCSV(tx.lotTotalAmount, 2),
        'Lot GST amount': getAmountForCSV(tx.lotTotalTaxAmount, 2),
        'Fee code': tx.feeCode ?? '',
        'Fee description': tx.feeDescription ?? '',
        'Fee amount(GST exclusive)': getAmountForCSV(tx.feeAmountWithoutGst, 2),
        'Fee amount(GST inclusive)': getAmountForCSV(tx.feeAmount, 2),
        'Fee GST amount': getAmountForCSV(tx.feeTaxAmount, 2),
        'Total tax amount': getAmountForCSV(tx.totalTaxAmount, 2),
        'Total amount without GST': getAmountForCSV(
          tx.totalAmountWithoutGst,
          2
        ),
        'Total amount with GST': getAmountForCSV(
          tx.price.value ? Number(tx.price.value) : undefined,
          2
        ),
      }));
    }

    return transactions.map((tx) => ({
      'Account Number': tx.accountNumber?.toString() ?? '',
      Issued: tx.issued.displayValue,
      'Sale Date': tx.saleDate ?? '',
      Due: tx.due.displayValue,
      'Invoice #': tx.invoiceNumber ? tx.invoiceNumber.toString() : '',
      Category: tx.category.value,
      Description: tx.description.value,
      Quantity: tx.quantity ?? '',
      'Unit of measure': tx.unitOfMeasure ?? '',
      'Unit price without gst': getAmountForCSV(tx.unitPriceWithoutGst, 2),
      'Unit price with gst': getAmountForCSV(tx.unitPriceWithGst, 2),
      'Unit tax amount': getAmountForCSV(tx.unitTaxAmount, 2),
      'Total tax amount': getAmountForCSV(tx.totalTaxAmount, 2),
      'Total amount without GST': getAmountForCSV(tx.totalAmountWithoutGst, 2),
      'Total amount with GST': getAmountForCSV(Number(tx.price.value), 2),
    }));
  }
  return [];
};

export const useLotDetailFormatter = () => {
  const { formatExtraSpaces, formatSentenceCase } = useFormatters();

  /**
   * flatted lotDetails and lotFeeDetails for CSV exporting
   * @remarks
   * `Items` in livestock transactions with documentType `Z8` only include
   * `Category` and Items.length === 1, so livestock transactions will be
   * flatted to a single line. `flattedTransactions[0]` will be the Header of
   * one livestock transaction in CSV export. Refer to
   * {@link https://uiaus.sharepoint.com/:x:/r/sites/NutrienAustraliaDigital/_layouts/15/Doc.aspx?sourcedoc=%7BC7A5C702-9AD3-4677-A011-C296B65A110D%7D&file=Data%20Dictionary.xlsx&action=default&mobileredirect=true&cid=76c771f6-ec8d-498c-a033-45acd24f90eb}
   *
   * @param transactions transactions with flatted Items
   * @return transactions with flatted lotDetails and lotFeeDetails
   */
  const formatLotDetails = (
    transactions: Transaction[],
    lotDetailsList: LotDetailsList | undefined
  ): Transaction[] => {
    const cleanDisplayCell = {
      value: '',
      displayValue: '',
    };

    return transactions.flatMap((transaction) => {
      const flattedTransaction = [{ ...transaction }];

      if (transaction.documentId) {
        const { LotDetails, LotFeeDetails } =
          lotDetailsList?.[transaction.documentId]?.lotDetailsResponse
            ?.result ?? {};
        let sumOfHeads = 0;

        if (LotDetails && LotDetails.length !== 0) {
          LotDetails.forEach((lotDetail) => {
            const {
              Category,
              Fee,
              GSTAmount,
              GroupTitle,
              LotAmountExcGST,
              LotNumber,
              LotType,
              PerHeadRateExcGST,
              PerHeadRateIncGST,
              PerKgRateExcGST,
              PerKgRateIncGST,
              TotalHeads,
              TotalWeight,
            } = lotDetail;

            const formattedCategory = Category
              ? formatSentenceCase(Category)
              : '';
            const formattedDescription = GroupTitle
              ? formatExtraSpaces(GroupTitle)
              : '';

            // get total Sum of Quantity of heads for all lots
            sumOfHeads += Number(TotalHeads);

            flattedTransaction.push({
              ...transaction,
              category: {
                value: formattedCategory,
                displayValue: formattedCategory,
              },
              description: {
                value: formattedDescription,
                displayValue: formattedDescription,
              },
              issued: cleanDisplayCell,
              saleDate: undefined,
              due: cleanDisplayCell,
              price: cleanDisplayCell,
              proceeds: cleanDisplayCell,
              purchases: cleanDisplayCell,
              totalAmount: cleanDisplayCell,
              totalTaxAmount: undefined,
              totalAmountWithoutGst: undefined,
              quantity: LotType === 'c/kg' ? TotalWeight : TotalHeads,
              unitOfMeasure: LotType,
              unitPriceWithoutGst:
                LotType === 'c/kg'
                  ? Number(PerKgRateExcGST)
                  : Number(PerHeadRateExcGST),
              unitPriceWithGst:
                LotType === 'c/kg'
                  ? Number(PerKgRateIncGST)
                  : Number(PerHeadRateIncGST),
              lotNumber: LotNumber,
              lotTotalAmount: Fee,
              lotTotalAmountWithoutGst: LotAmountExcGST,
              lotTotalTaxAmount: GSTAmount,
            });
          });
        }

        if (LotFeeDetails && LotFeeDetails.length !== 0) {
          LotFeeDetails.forEach((lotFeeDetail) => {
            const {
              Category,
              Fee,
              FeeAmountExcGST,
              FeeCode,
              GSTOnFee,
              GroupTitle,
            } = lotFeeDetail;

            const formattedCategory = Category
              ? formatSentenceCase(Category)
              : '';
            const formattedDescription = GroupTitle
              ? formatExtraSpaces(GroupTitle)
              : '';

            flattedTransaction.push({
              ...transaction,
              category: {
                value: formattedCategory,
                displayValue: formattedCategory,
              },
              description: cleanDisplayCell,
              issued: cleanDisplayCell,
              saleDate: undefined,
              due: cleanDisplayCell,
              price: cleanDisplayCell,
              proceeds: cleanDisplayCell,
              purchases: cleanDisplayCell,
              totalAmount: cleanDisplayCell,
              totalTaxAmount: undefined,
              totalAmountWithoutGst: undefined,
              feeCode: FeeCode,
              feeDescription: formattedDescription,
              feeAmount: Fee,
              feeAmountWithoutGst: FeeAmountExcGST,
              feeTaxAmount: GSTOnFee,
            });
          });
        }
        // export sum of Heads in Header
        flattedTransaction[0].quantity =
          sumOfHeads === 0 ? '' : `${sumOfHeads}`;
      }

      return flattedTransaction;
    });
  };
  return formatLotDetails;
};
