import React, { FC } from 'react';
import { Box, Text, Card, useStyles } from '@nutrien/bonsai-core';
import { FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_input } from '../../types/generated/FarmRecommendation';

type props = {
  data: FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_input;
  totalArea?: string | null;
};

const ItemCard: FC<props> = ({ data, totalArea }) => {
  const { input_name, input_rate, total_qty } = data;
  const styles = useStyles((theme) => {
    return {
      cardStyle: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      itemTextStyle: {
        color: theme.auColors.neutral[600],
      },
    };
  });

  return (
    <Card style={styles.cardStyle} testID="input-item-card">
      <Box
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        pb={1}
      >
        <Text
          bodyBold
          ellipsizeMode="tail"
          numberOfLines={1}
          testID="recommendation-item-card-name"
        >
          {input_name}
        </Text>
      </Box>
      <Box
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Text
          style={styles.itemTextStyle}
          ellipsizeMode="tail"
          numberOfLines={1}
          testID="recommendation-item-card-area"
        >
          {totalArea ? `Total Area ${totalArea}` : `Rate ${input_rate}`}
        </Text>
        <Text
          style={styles.itemTextStyle}
          testID="recommendation-item-card-qty"
        >
          Total {totalArea ? '' : 'Qty'} {total_qty}
        </Text>
      </Box>
    </Card>
  );
};

export default ItemCard;
