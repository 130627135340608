/* eslint-disable security/detect-non-literal-fs-filename */
import { useReactiveVar } from '@apollo/client';
import { Snackbar, useDeviceType, useStyles } from '@nutrien/bonsai-core';
import { openURL } from 'expo-linking';
import { Modal } from 'native-base';
import React, { useState } from 'react';
import { Platform, Text, View } from 'react-native';
import {
  csvExportMessages,
  TRANSACTIONS_FILTER,
  TxSortOptions,
} from '../../constants';
import { trackEvent } from '../../constants/segment';
import { useTransaction } from '../../context/TransactionContext';
import { useGetDocumentByIdQuery, useRoutes, useSegment } from '../../hooks';
import useTransactions from '../../hooks/useTransactions';
import { isLivestock } from '../../hooks/useTransactions/transaction.helper';
import {
  selectedAccountMutations,
  selectedAccountVar,
} from '../../reactiveVariables';
import { TabSceneType } from '../../reactiveVariables/variables/selectedAccountVar';
import { DocumentAccountType } from '../../types/generated/globalTypes';
import Banner, { BannerType } from '../Banner';
import ButtonGroup from '../ButtonGroup';
import { TabScene } from './TabScene';
import { TransactionActions } from './TransactionActions';
import TransactionFilters from './TransactionFilters';

export interface TableViewProps {
  accountNumber: string;
  ruralcoAccNumber?: string | null;
}

const TransactionTabs = ({
  accountNumber,
  ruralcoAccNumber,
}: TableViewProps): JSX.Element => {
  const { routeParams } = useRoutes();
  const { isDesktop } = useDeviceType();
  const [sortValue, setSortValue] = useState<TxSortOptions>(
    TxSortOptions.ISSUED_DESC
  );
  const [viewIndex, setViewIndex] = useState(0);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [lotDetailErrorDocumentIds, setLotDetailErrorDocumentIds] = useState<
    string[]
  >([]);

  const segment = useSegment();
  const { account_id, account_type, account_view } =
    useReactiveVar(selectedAccountVar);
  const { editAccountView } = selectedAccountMutations;
  const tabs: { text: TabSceneType }[] = [
    { text: 'Invoice view' },
    { text: 'Table view' },
  ];

  if (!account_view.display_view) {
    editAccountView({
      display_view: tabs[viewIndex as number].text,
    });
  }

  const selectedTab = tabs[viewIndex as number].text;

  const {
    state: { csvExportStatus },
    setCSVExportStatus,
  } = useTransaction();

  const transactionsFilter = Object.values(TRANSACTIONS_FILTER).some(
    (filter) => filter === routeParams?.transactionsFilter
  )
    ? (routeParams.transactionsFilter as TRANSACTIONS_FILTER)
    : undefined;

  const { lineItems, loading, error, refetch, groupedTransactions, hasPdfs } =
    useTransactions({
      accountNumber,
      sortValue,
      ruralcoAccNumber,
      transactionsFilter,
    });

  const hasTransactions: boolean =
    groupedTransactions?.size != null && groupedTransactions.size > 0;

  const groupedItemsArray = Array.from(groupedTransactions || []);
  const hasLiveStockTransactions: boolean =
    groupedItemsArray.filter((group) => isLivestock(group[1])).length > 0;

  const [
    _docUrlData,
    docUrlLoading,
    docUrlError,
    getDocumentUrl,
    clearDocError,
  ] = useGetDocumentByIdQuery();

  // track invoice viewed success/failure events
  const trackPdfDownloadEvent = (
    downloadSuccessful: boolean,
    invoiceNumber?: string
  ) => {
    segment?.track(trackEvent.INVOICE_VIEWED, {
      account_id,
      account_type,
      tab_label: account_view.display_view,
      download_successful: downloadSuccessful,
      invoice_number: invoiceNumber,
    });
  };

  const openUrl = (url?: string) => {
    if (url) {
      Platform.OS === 'web'
        ? window.open(url as string, '_blank')
        : openURL(url);
    }
  };

  const download = async (
    documentId: string | null,
    invoiceNumber?: string,
    accountType?: DocumentAccountType
  ): Promise<void> => {
    const documentNumber = invoiceNumber?.toString();
    try {
      if (documentId && documentNumber && accountType) {
        setIsDocumentLoading(true);
        const variables = {
          accountNumber,
          documentId,
          documentNumber,
          accountType,
        };
        // Go make a call to get the URL with the matched document.
        const docUrlRes = await getDocumentUrl({ variables });
        const docUrl = docUrlRes.data?.getDocumentById?.url;
        if (docUrl) {
          openUrl(docUrl);
          trackPdfDownloadEvent(true, documentNumber);
        } else {
          trackPdfDownloadEvent(false, documentNumber);
          setErrorSnackbar(true);
        }
      } else {
        trackPdfDownloadEvent(false, documentNumber);
        setErrorSnackbar(true);
      }
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const onTabChange = (index: number) => {
    setViewIndex(index);

    // track segment event only when switching to different tab
    if (index !== viewIndex) {
      editAccountView({
        display_view: tabs[index as number].text,
      });

      segment?.track(trackEvent.TAB_CHANGED, {
        origin_tab: tabs[viewIndex as number].text,
        destination_tab: tabs[index as number].text,
        account_id,
      });
    }
  };

  const styles = useStyles((theme) => ({
    buttonGroup: {
      paddingHorizontal: isDesktop ? 0 : theme.spacing(2),
      width: isDesktop ? '50%' : 'auto',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    container: { paddingTop: theme.spacing(2) },
    tabsAndFiltersContainer: {
      flexDirection: isDesktop ? 'row' : 'column',
    },
    filterWrapper: {
      width: isDesktop ? '50%' : 'auto',
    },
    filterContainerStyle: {
      width: isDesktop ? '80%' : '100%',
      alignSelf: 'flex-end',
    },
    bannerMargin: { marginBottom: theme.spacing(2) },
  }));

  return (
    <View style={styles.container}>
      <View style={styles.tabsAndFiltersContainer}>
        <ButtonGroup
          testID={
            selectedTab === 'Invoice view'
              ? 'grouped-view-tab'
              : 'table-view-tab'
          }
          containerStyle={styles.buttonGroup}
          onPress={onTabChange}
          selectedIndex={viewIndex}
          buttons={tabs}
          label={'Display'}
          fullWidthButton={isDesktop}
        />
        <View style={styles.filterWrapper}>
          <View style={styles.filterContainerStyle}>
            <TransactionFilters showTabs={false} />
          </View>
        </View>
      </View>
      {hasTransactions && (
        <TransactionActions
          selectedTab={selectedTab}
          onSort={setSortValue}
          sortValue={sortValue}
          enableExport={Platform.OS === 'web' && selectedTab === 'Table view'}
          transactionsLoading={loading}
          hasPdfs={hasPdfs}
          hasLivestockTransactions={hasLiveStockTransactions}
          onReloadLotDetails={(documentIdsWithError: string[]) => {
            setLotDetailErrorDocumentIds(documentIdsWithError);
          }}
        />
      )}
      {csvExportStatus && (
        <Banner
          location="inline"
          type={csvExportStatus}
          visible={true}
          testID={`csv-export-banner-${csvExportStatus}`}
          onDismissPress={() => setCSVExportStatus(undefined)}
          style={styles.bannerMargin}
        >
          <Text>{csvExportMessages[csvExportStatus as BannerType]}</Text>
        </Banner>
      )}
      <TabScene
        onPress={download}
        type={selectedTab}
        loading={loading}
        error={error}
        refetch={refetch}
        items={lineItems}
        groupedItems={groupedTransactions}
        showOverlay={isDocumentLoading || docUrlLoading}
        documentLoading={isDocumentLoading || docUrlLoading}
        documentError={docUrlError}
        onErrorClose={clearDocError}
        lotDetailErrorDocumentIds={lotDetailErrorDocumentIds}
      />
      <Modal backgroundColor="backdrop" isOpen={errorSnackbar}>
        <Snackbar
          visible={errorSnackbar}
          message="Something went wrong, please try again."
          actionTitle="Ok"
          onActionPress={() => setErrorSnackbar(false)}
        />
      </Modal>
    </View>
  );
};

export default TransactionTabs;
