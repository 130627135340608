import { isValid, format, parseISO, addDays, isAfter } from 'date-fns';

import {
  FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_field,
  FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_input,
} from '../../types/generated/FarmRecommendation';

const sortRecoActivityInput = (
  list?: FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_input[]
) => {
  if (!list) {
    return undefined;
  }
  return [...list]
    .filter((item) => item?.input_type_desc !== 'CostActivity')
    .sort((first, second) => {
      if (first?.input_sequence_number && second?.input_sequence_number) {
        return first?.input_sequence_number > second?.input_sequence_number
          ? 1
          : -1;
      } else return 0;
    });
};

const groupFieldByFarmName = (
  data?: FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_field[]
) => {
  if (!data) {
    return undefined;
  }
  const result: {
    [
      key: string
    ]: FarmRecommendation_farm_recommendation_account_reco_account_activity_reco_activity_field[];
  } = {};
  data.forEach((field) => {
    const farmName = field?.farm_name || '';
    !(farmName in result) && (result[farmName as keyof typeof result] = []);

    result[farmName as keyof typeof result].push({ ...field });
  });
  return result;
};

/**
 * Return true when
 * - valid expiryDate and expiryDate + bufferDate > today
 * @param expiryDate?
 */
const isRecoExpired = (expiryDate?: string, bufferDay?: number): boolean => {
  if (expiryDate == null) {
    return true;
  }
  const expiryObj = parseISO(expiryDate);
  if (!isValid(expiryObj)) {
    return true;
  }
  const today = new Date();
  return !isAfter(addDays(expiryObj, bufferDay || 0), today);
};

/**
 * Format recognised date string to short date dd MMM yy
 * @param rawDate
 */
const shortDate = (
  rawDate?: string,
  includeYear?: boolean
): string | undefined => {
  if (rawDate == null) {
    return undefined;
  }
  const dateObj = parseISO(rawDate);
  if (isValid(dateObj)) {
    if (includeYear) {
      return format(dateObj, 'dd MMM yy');
    } else {
      return format(dateObj, 'dd MMM');
    }
  }
  return undefined;
};

export {
  isRecoExpired,
  sortRecoActivityInput,
  groupFieldByFarmName,
  shortDate,
};
