import { gql, useQuery } from '@apollo/client';
import unionBy from 'lodash.unionby';
import { GetPdfZipVariables } from 'types/generated/GetPdfZip';
import { SubscribeGetPdfZip_getPdfZip } from 'types/generated/SubscribeGetPdfZip';
import { cache } from '../../useApolloClientWithPersist/cache';

export interface CacheAccountIdDocumendId {
  accountId: string;
  accountType: string;
  documentId: string;
  timestamp: number;
  isPending?: boolean;
  numberOfPdfs?: number;
  fromDate?: string;
  toDate?: string;
  trimmedAccountId?: string;
  accountDescription?: string;
  isTriggered?: boolean;
  variables?: GetPdfZipVariables;
  results?: SubscribeGetPdfZip_getPdfZip | null;
  isTracked?: boolean;
  isTryAgain?: boolean;
  isBlockedByPopup?: boolean;
}

interface SelectFromCacheParams {
  accountId?: string;
  documentId?: string;
}

interface CachePdfZipIds {
  cacheData: {
    pdfZipIds: CacheAccountIdDocumendId[];
  };
  selectFromCache: (
    data: SelectFromCacheParams
  ) => CacheAccountIdDocumendId | undefined;
  writeToCache: (data: CacheAccountIdDocumendId[]) => void;
  deleteFromCache: (data: { id?: string }) => void;
}

const PDF_ZIP_IDS = gql`
  query PdfZipIds {
    pdfZipIds @client
  }
`;

const useCachePdfZipIds = (): CachePdfZipIds => {
  const { data: cacheData } = useQuery(PDF_ZIP_IDS);

  const selectFromCache = ({
    accountId,
    documentId,
  }: SelectFromCacheParams) => {
    if (!accountId && !documentId) return undefined;
    if (accountId) {
      return cacheData?.pdfZipIds.find(
        (item: CacheAccountIdDocumendId) => item.accountId === accountId
      );
    }
    if (documentId) {
      return cacheData?.pdfZipIds.find(
        (item: CacheAccountIdDocumendId) => item.documentId === documentId
      );
    }
  };

  const writeToCache = (pdfZipIds: CacheAccountIdDocumendId[]) => {
    cache.writeQuery({
      query: PDF_ZIP_IDS,
      data: {
        pdfZipIds: unionBy(pdfZipIds, cacheData?.pdfZipIds, 'accountId'),
      },
    });
  };

  const deleteFromCache = ({ id }: { id?: string }) => {
    if (!id) return;
    cache.writeQuery({
      query: PDF_ZIP_IDS,
      data: {
        pdfZipIds: cacheData.pdfZipIds.filter(
          (data: CacheAccountIdDocumendId) => {
            return data.documentId !== id;
          }
        ),
      },
    });
  };

  return {
    cacheData,
    selectFromCache,
    writeToCache,
    deleteFromCache,
  };
};

export default useCachePdfZipIds;
