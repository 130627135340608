import React from 'react';
import { ViewStyle, View } from 'react-native';
import { Box, useStyles } from '@nutrien/bonsai-core';
import { useCachePdfZipIds } from '../../hooks';
import { CacheAccountIdDocumendId } from '../../hooks/gql/cache/useCachePdfZipIds';
import SubscribeDownload from './SubscribeDownload';

const SubscribeDownloads = (): JSX.Element => {
  const styles = useStyles((theme) => ({
    downloads: {
      marginBottom: theme.spacing(1),
    } as ViewStyle,
    content: {
      width: '100%',
      maxWidth: 990,
    },
    contentWrapper: {
      alignItems: 'center',
    },
  }));
  const { cacheData } = useCachePdfZipIds();
  const sortedPdfZipIds = cacheData?.pdfZipIds
    ? [...cacheData.pdfZipIds].sort(
        (first: CacheAccountIdDocumendId, second: CacheAccountIdDocumendId) =>
          first.timestamp - second.timestamp
      )
    : [];
  const downloads = sortedPdfZipIds.map(({ accountId, documentId }) => (
    <SubscribeDownload
      key={`${accountId}_${documentId}`}
      documentId={documentId}
    />
  ));

  if (cacheData?.pdfZipIds && cacheData?.pdfZipIds.length > 0) {
    return (
      <View style={styles.contentWrapper}>
        <View style={styles.content}>
          <Box flexDirection="column" style={styles.downloads}>
            {downloads}
          </Box>
        </View>
      </View>
    );
  }

  return <></>;
};

export default SubscribeDownloads;
