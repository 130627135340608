import { Box, Icon, NamedStyles, Text, useTheme } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useFormatters } from '../../../hooks';

export interface DrawerHeaderProps {
  styles: NamedStyles<Record<string, undefined>>;
  isEmphasis: boolean;
  label: string;
  content?: number | null;
  description?: string;
}

const DrawerHeader: FC<DrawerHeaderProps> = ({
  styles,
  isEmphasis,
  label,
  content,
  description,
}) => {
  const theme = useTheme();
  const { renderNoneIfZero } = useFormatters();

  return (
    <Box mt={1} testID="drawer-subheader">
      {isEmphasis ? (
        <Box
          style={styles.errorContainer}
          paddingX={2}
          paddingY={1.5}
          testID="drawer-subheader-emphasised"
        >
          <Box style={styles.label} flexDirection="row" alignItems="flex-start">
            <Icon
              name="error-outline"
              color={theme.auColors.functional.error.dark}
              size={24}
              style={styles.icon}
            />
            <Text style={styles.errorBold}>{label}</Text>
          </Box>
          <Text style={styles.overdueAmountEmphasis}>
            {renderNoneIfZero(content)}
          </Text>
          <Text style={styles.error}>{description}</Text>
        </Box>
      ) : (
        <>
          <Text style={styles.subHeader}>{label}</Text>
          <Text style={theme.auTypography.desktop.headingMedium}>
            {renderNoneIfZero(content)}
          </Text>
          {description && (
            <Text style={styles.headerDescription}>{description}</Text>
          )}
        </>
      )}
    </Box>
  );
};

export default DrawerHeader;
