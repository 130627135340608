import { useState } from 'react';
import { useSegment } from '../../hooks';
import { trackEvent } from '../../constants/segment';

export type DrawerName =
  | 'Credits'
  | 'Expenses'
  | 'Next due'
  | 'Overdue'
  | 'Extended terms'
  | 'Pending proceeds'
  | 'Next due invoices'
  | 'Overdue invoices'
  | 'Non-interest bearing balance'
  | 'Interest bearing balance';

export type DrawerInfo = {
  name?: DrawerName;
  data?: Record<string, unknown>;
};

export type DrawerSegmentInfo = {
  tab_view?: 'Merchandise' | 'Livestock' | 'Finance' | '';
  account_id: string;
  drawerName?: DrawerName;
  close_method?: string;
};

type DrawerNameRecord = Record<DrawerName, string>;

export const drawerNameMapping: DrawerNameRecord = {
  Credits: 'ShowCredits',
  Expenses: 'ShowExpenses',
  'Next due': 'ShowNextDue',
  Overdue: 'ShowOverdue',
  'Extended terms': 'ShowExtendedTerms',
  'Pending proceeds': 'ShowPendingProceeds',
  'Next due invoices': 'ShowNextDueInvoices',
  'Overdue invoices': 'ShowOverdueInvoices',
  'Interest bearing balance': 'ShowInterestBearingBalance',
  'Non-interest bearing balance': 'ShowNonInterestBearingBalance',
};

const useDrawer = () => {
  const [drawerInfo, setDrawerInfo] = useState<DrawerInfo>({
    name: undefined,
    data: undefined,
  });
  const segment = useSegment();

  const onDrawerOpen = (data: DrawerInfo, segmentInfo: DrawerSegmentInfo) => {
    const { tab_view, account_id } = segmentInfo;
    segment?.track(trackEvent.SHOW_MORE_CLICKED, {
      tab_view: tab_view || '',
      account_id: account_id,
      drawer_name: drawerNameMapping[data.name as DrawerName],
    });
    setDrawerInfo(data);
  };

  const onDrawerClose = (
    closeMethod: string,
    segmentInfo: DrawerSegmentInfo
  ) => {
    const { tab_view, account_id } = segmentInfo;
    segment?.track(trackEvent.SHOW_MORE_CLOSED, {
      tab_view: tab_view || '',
      close_method: closeMethod,
      account_id: account_id,
      drawer_name: drawerNameMapping[drawerInfo.name as DrawerName],
    });
    setDrawerInfo({ name: undefined });
  };

  return {
    drawerInfo,
    onDrawerOpen,
    onDrawerClose,
  };
};

export default useDrawer;
