import { Box } from '@nutrien/bonsai-core';
import React from 'react';
import { TransactionGroup } from '../../hooks/useTransactions/transaction.helper';
import { DownloadDocumentFn } from '../Transaction/TabScene';
import TransactionAccordion from './TransactionAccordion';
import TransactionDefault from './TransactionDefault';

export interface TransactionsProps {
  group: TransactionGroup;
  isAccordion: boolean;
  isExpanded: boolean;
  onToggleExpand: () => void;
  onDownload: DownloadDocumentFn;
  onReloadLotDetails?: () => void;
}

const Transaction = ({
  group,
  isAccordion,
  isExpanded,
  onToggleExpand,
  onDownload,
  onReloadLotDetails,
}: TransactionsProps): JSX.Element => {
  if (isAccordion) {
    return (
      <Box mb={2}>
        <TransactionAccordion
          group={group}
          isExpanded={isExpanded}
          onToggleExpand={onToggleExpand}
          onDownload={onDownload}
          onReloadLotDetails={onReloadLotDetails}
        />
      </Box>
    );
  }
  return (
    <Box mb={2}>
      <TransactionDefault group={group} onDownload={onDownload} />
    </Box>
  );
};

export default Transaction;
