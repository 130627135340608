import {
  gql,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';
import { useState } from 'react';
import {
  GetDocumentById,
  GetDocumentByIdVariables,
} from 'types/generated/GetDocumentById';

export const DOC_URL = gql`
  query GetDocumentById(
    $accountNumber: String!
    $documentId: String!
    $accountType: DocumentAccountType!
    $documentNumber: String
  ) {
    getDocumentById(
      payload: {
        accountNumber: $accountNumber
        documentId: $documentId
        accountType: $accountType
        documentNumber: $documentNumber
      }
    ) {
      url
    }
  }
`;

const useGetDocumentByIdQuery = (): [
  GetDocumentById | undefined,
  boolean,
  boolean,
  (
    options: QueryLazyOptions<GetDocumentByIdVariables>
  ) => Promise<LazyQueryResult<GetDocumentById, OperationVariables>>,
  () => void
] => {
  const [error, setError] = useState(false);
  const clearError = () => setError(false);
  const [getDocumentById, { data, loading }] = useLazyQuery<GetDocumentById>(
    DOC_URL,
    {
      fetchPolicy: 'network-only',
      onError: () => setError(true),
    }
  );

  return [data, loading, error, getDocumentById, clearError];
};

export default useGetDocumentByIdQuery;
