import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import {
  FarmVisitRecommendationsVariables,
  FarmVisitRecommendations,
} from 'types/generated/FarmVisitRecommendations';

export const FARM_VISIT_SUMMARY = gql`
  query FarmVisitRecommendations($farmVisitId: uuid!) {
    farm_farm_visit_summary(where: { farm_visit_id: { _eq: $farmVisitId } }) {
      agronomist_id
      agronomist_name
      agronomist_org_name
      created_date
      farm_visit_id
      farm_visit_summary_reco_bridge {
        recommendation_uuid
        created_dtm
        farm_visit_reco_bridge_reco_activity {
          agronomist_name
          agronomist_org_name
          grower_org_name
          rec_created_dtm
          rec_updated_dtm
          rec_due_dtm
          deleted_dtm
          rec_expiry_dtm
          reco_activity_field {
            activity_area_desc
            activity_area_estimated_qty
            activity_crop_name
            farm_name
            field_area_desc
            field_name
            recommendation_id
            activity_variety_name
            activity_crop_stage_desc
          }
          reco_activity_input {
            input_name
            input_cost_area_rate
            input_rate
            input_sequence_number
            input_total_cost_amount
            input_type_desc
            input_unit_cost_rate
            total_qty
            recommendation_id
          }
          recommendation_title
          recommendation_id
          recommendation_uuid
          job_status
          total_activity_area
          recommendation_valid_to_dtm
          total_application_rate_desc
          total_cost_amount
          total_field_area
          total_volume
        }
        farm_visit_summary {
          agronomist_id
          agronomist_name
          agronomist_org_name
          created_date
          farm_visit_id
        }
      }
    }
  }
`;

type Refetch = (
  variables?: Partial<{ farmVisitId: string }> | undefined
) => Promise<ApolloQueryResult<FarmVisitRecommendations>>;

const useGetFarmVisitQuery = ({
  farmVisitId,
}: {
  farmVisitId: string;
}): [
  FarmVisitRecommendations | undefined,
  boolean,
  ApolloError | undefined,
  Refetch
] => {
  // fallBackIfNotAuthenticated(isAuthenticated);
  const { loading, error, data, refetch } = useQuery<
    FarmVisitRecommendations,
    FarmVisitRecommendationsVariables
  >(FARM_VISIT_SUMMARY, {
    variables: { farmVisitId },
  });

  return [data, loading, error, refetch];
};

export default useGetFarmVisitQuery;
