import React, { FC } from 'react';
import { Text, useStyles, Box, NamedStyles } from '@nutrien/bonsai-core';
import { useFormatters } from '../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../SummaryCard';

export interface PrepaySummaryProps {
  accountDisplayName: string;
  summary?: AccountSummaryData | null;
  summaryStyles?: NamedStyles<Record<string, undefined>>;
  testID?: string;
  handleClickViewDetails: () => void;
}

const PrepaySummary: FC<PrepaySummaryProps> = ({
  accountDisplayName,
  summary,
  summaryStyles,
  testID,
  handleClickViewDetails,
}) => {
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(summaryStyles ?? {});

  const { CurrentBalance, RewardAmount, TransactionAmount } = summary || {
    CurrentBalance: undefined,
    RewardAmount: undefined,
    TransactionAmount: undefined,
  };

  return (
    <SummaryCard
      heading={accountDisplayName}
      button={{
        label: 'View pre-pay',
        testID: `view-details-${testID}`,
        onPress: handleClickViewDetails,
      }}
      summaryRow={[
        {
          label: (
            <Box style={styles.label}>
              <Text style={styles.labelText}>Current balance</Text>
            </Box>
          ),
          content: renderNoneIfZero(CurrentBalance),
          testID: 'current-balance-column',
        },
        {
          label: (
            <Box
              style={styles.label}
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text style={styles.labelText}>Latest monthly reward</Text>
            </Box>
          ),
          content: renderNoneIfZero(RewardAmount),
          testID: 'reward-column',
        },
        {
          label: (
            <Box style={styles.label}>
              <Text style={styles.labelText}>
                Transactions in the past 30 days
              </Text>
            </Box>
          ),
          content: renderNoneIfZero(TransactionAmount),
          testID: 'transactions-past-30-days-column',
        },
      ]}
      testID={testID}
    />
  );
};

export default PrepaySummary;
