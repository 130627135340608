import React from 'react';
import { Box, Text, useDeviceType, useStyles } from '@nutrien/bonsai-core';
import { useLogin } from '../../context/LoginContext';
import Breadcrumbs from '../../components/Breadcrumbs';
import { breadcrumbConstants } from '../../constants';
import Select from '../../components/Select';
import useAccount from './useAccount';
import useAccountOptions from './useAccountOptions';

const AccountHeader = (): JSX.Element => {
  const { selectedAccountId, customerId } = useAccount();
  const {
    state: { roles },
  } = useLogin();
  const {
    accountOptions,
    accountOptionsLoading,
    accountOptionsError,
    handleSelectOption,
    nominatedAccountId,
  } = useAccountOptions(customerId);
  const { isDesktop } = useDeviceType();
  const styles = useStyles((theme) => ({
    heading: { marginBottom: theme.spacing(0.5) },
    headingMedium:
      theme.auTypography[isDesktop ? 'desktop' : 'mobile'].headingMedium,
  }));

  return (
    <>
      <Breadcrumbs
        items={[
          breadcrumbConstants.home,
          roles.includes('customer')
            ? null
            : customerId
            ? breadcrumbConstants.userSummary(customerId as string)
            : breadcrumbConstants.accountSummary(nominatedAccountId as string),
          {
            title: 'Account Details',
          },
        ]}
      />
      <Box mb={2}>
        <Text style={[styles.headingMedium, styles.heading]} h1>
          Account
        </Text>
        <Select
          name="account-options"
          loading={accountOptionsLoading}
          error={accountOptionsError ? 'Error loading options' : ''}
          handleSelect={handleSelectOption}
          value={selectedAccountId}
          options={accountOptions}
          testID="select-account-options"
        />
      </Box>
    </>
  );
};

export default AccountHeader;
