const trackEvent = {
  ACCOUNT_SELECTED: 'Account_Selected',
  BREADCRUMBS_SELECTED: 'Breadcrumbs_Selected',
  BULK_DOWNLOAD_STARTED: 'Bulk_Download_Started',
  BULK_DOWNLOAD_FINISHED: 'Bulk_Download_Finished',
  COLUMN_SORT_SET: 'Column_Sort_Set',
  DATE_RANGE_SET: 'Date_Range_Set',
  DATE_NAVIGATION_CLICKED: 'Date_Navigation_Clicked',
  ERROR: 'Error',
  EXPORT_TO_CSV_SELECTED: 'ExportToCSV_Selected',
  INVITE_SENT: 'Invite_Sent',
  INVOICE_VIEWED: 'Invoice_Viewed',
  LOGIN: 'Login',
  LOGOUT_SELECTED: 'Logout_Selected',
  PAGINATION_CLICKED: 'Pagination_Clicked',
  ROW_DISPLAY_SET: 'Rows_Display_Set',
  SORT_BY_SET: 'Sort_By_Set',
  STATEMENT_VIEWED: 'Statement_Viewed',
  SWITCH_ACCOUNT: 'Switch_Account',
  TAB_CHANGED: 'Tab_Changed',
  USER_CREATED: 'User_Created',
  SHOW_MORE_CLICKED: 'ShowMore_Clicked',
  SHOW_MORE_CLOSED: 'ShowMore_Closed',
  REGISTRATION_STEP1_LOAD: 'Registration_Step1_Load',
  REGISTRATION_STEP2_LOAD: 'Registration_Step2_Load',
  REGISTRATION_STEP3_LOAD: 'Registration_Step3_Load',
  REGISTRATION_STEP4_LOAD: 'Registration_Step4_Load',
};

const emailStatus = {
  SEND: 'send',
  RESEND: 'resend',
};

const gqlErrorMessage = 'GraphQL error';

export { trackEvent, emailStatus, gqlErrorMessage };
