import React from 'react';
import { Text, useStyles } from '@nutrien/bonsai-core';
import { View } from 'react-native';
import { Transaction } from '../Transaction/transactions.types';
import { TransactionWrapper } from '../TransactionsGroupedView/TransactionWrapper';

interface TransactionTileProps extends Transaction {
  onPress?: () => void;
  testId: string;
}

const TransactionDetails = ({
  issued,
  invoiceNumber,
  description,
  due,
  price: total,
  testId,
}: TransactionTileProps): JSX.Element => {
  const styles = useStyles((theme) => {
    return {
      description: {
        flex: 1,
        flexWrap: 'wrap',
        paddingRight: theme.spacing(2),
      },
      meta: { color: theme.auColors.neutral[600] },
      content: { flexDirection: 'row', justifyContent: 'space-between' },
      row: {
        borderBottomWidth: 1,
        borderBottomColor: theme.auColors.neutral[500],
        paddingHorizontal: theme.spacing(2),
        paddingVertical: theme.spacing(1),
      },
    };
  });

  return (
    <View style={styles.row} testID={testId}>
      <Text style={styles.meta}>Invoice {invoiceNumber}</Text>
      <View style={styles.content}>
        <Text style={styles.description} bodyBold>
          {description.value}
        </Text>
        <Text>{total.displayValue}</Text>
      </View>
      <View style={styles.content}>
        <Text style={styles.meta}>Issued {issued.displayValue}</Text>
        <Text style={styles.meta}>Due {due.displayValue}</Text>
      </View>
    </View>
  );
};

const TransactionTile = (props: TransactionTileProps): JSX.Element => {
  const { onPress, last } = props;

  return (
    <TransactionWrapper
      onPress={onPress}
      tableStyle={true}
      isLast={!!last}
      testId="table-line-item"
    >
      <TransactionDetails {...props} />
    </TransactionWrapper>
  );
};

export default TransactionTile;
