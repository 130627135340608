import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import {
  CustomerProfile,
  CustomerProfileVariables,
} from 'types/generated/CustomerProfile';
import {
  CustomerProfileByCustomerIdVariables,
  CustomerProfileByCustomerId,
} from 'types/generated/CustomerProfileByCustomerId';
import { CustomerProfiles } from 'types/generated/CustomerProfiles';

export const CUSTOMER_PROFILES_WITH_ACCOUNTS = gql`
  query CustomerProfilesWithAccounts {
    customer_profile {
      onboarded_branch_code
      given_name
      family_name
      id
      email
      customer_accounts(where: { is_deleted: { _eq: false } }) {
        account_id
      }
    }
  }
`;

export const CUSTOMER_PROFILE_BY_CUSTOMER_ID = gql`
  query CustomerProfileByCustomerId($customerId: uuid) {
    customer_profile(where: { id: { _eq: $customerId } }) {
      id
      preferred_name
      given_name
      family_name
      email
      onboarded_branch_code
      mobile_phone
      onboarded_branch_info {
        branch_name
      }
      customer_accounts(where: { is_deleted: { _eq: false } }) {
        account_id
        sords_customer_account {
          account_type
          name1
          account_id_trimmed
          account_finances {
            is_finance_account
            total_receivables
          }
        }
      }
    }
  }
`;

export type Refetch = (
  variables?: Partial<CustomerProfileVariables> | undefined
) => Promise<ApolloQueryResult<CustomerProfile>>;

export type RefetchByCustomerId = (
  variables?: Partial<CustomerProfileByCustomerIdVariables> | undefined
) => Promise<ApolloQueryResult<CustomerProfileByCustomerId>>;

const useCustomerProfileByCustomerIdQuery = ({
  customerId,
  skip,
}: {
  customerId: string;
  skip?: boolean;
}): [
  CustomerProfileByCustomerId | undefined,
  boolean,
  ApolloError | undefined,
  RefetchByCustomerId
] => {
  const { loading, error, data, refetch } = useQuery<
    CustomerProfileByCustomerId,
    CustomerProfileByCustomerIdVariables
  >(CUSTOMER_PROFILE_BY_CUSTOMER_ID, {
    skip,
    variables: { customerId },
  });

  return [data, loading, error, refetch];
};

const useCustomerProfilesWithAccountsQuery = (
  skip = false
): [
  CustomerProfiles | undefined,
  boolean,
  ApolloError | undefined,
  Refetch
] => {
  const { loading, error, data, refetch } = useQuery<
    CustomerProfiles,
    CustomerProfileVariables
  >(CUSTOMER_PROFILES_WITH_ACCOUNTS, {
    skip,
    notifyOnNetworkStatusChange: true,
  });

  return [data, loading, error, refetch];
};

export {
  useCustomerProfilesWithAccountsQuery,
  useCustomerProfileByCustomerIdQuery,
};
