import React from 'react';
import { TRANSACTIONS_FILTER } from '../constants';

export interface UserRouteProps {
  name: string;
  path: string;
  icon?: string;
  View: React.FunctionComponent;
  isProtected?: boolean;
  /**
   * The user must have one of these roles to
   * visit this route.
   */
  allowedRoles?: string[];
  featureEnabled: boolean;
}

/**
 * All routes should be configured in here. If there are any params or qs which are required
 * for the route, they should be defined in the `getNavigateProps` function. The getNavigateProps
 * func should be utilised when navigating.
 *
 * @note: I have left the routeConfig object un-typed as typing it removes the useful
 * auto-suggest intellisense.
 */
const routeConfig = {
  FarmVisitRecommendations: {
    path: '/farm/visits/:id/recommendations',
    name: 'FarmVisitRecommendations',
    getNavigateProps: function ({ id }: { id: string }) {
      return {
        params: {
          id,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  FarmVisit: {
    path: '/farm/visits/:id',
    name: 'FarmVisitSummary',
    getNavigateProps: function ({ id }: { id: string }) {
      return {
        params: {
          id,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  FarmRecommendation: {
    path: '/farm/recommendations/:id',
    name: 'Recommendation',
    getNavigateProps: function ({ id }: { id: string }) {
      return {
        params: {
          id,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  FarmVisitRecommendation: {
    path: '/farm/visits/:visitId/recommendation/:id',
    name: 'VisitRecommendation',
    getNavigateProps: function ({
      id,
      visitId,
    }: {
      id: string;
      visitId: string;
    }) {
      return {
        params: {
          id,
          visitId,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  OldFarmRecommendation: {
    path: '/recommendation/:id',
    name: 'OldRecommendation',
    getNavigateProps: function ({ id }: { id: string }) {
      return {
        params: {
          id,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  Login: {
    name: 'Login',
    path: '/',
    getNavigateProps: function ({ status = '' }: { status?: string }) {
      return {
        name: this.name,
        path: this.path,
        params: {
          status,
        },
      };
    },
  },
  Auth0Login: {
    name: 'Auth0Login',
    path: '/login',
    getNavigateProps: function () {
      return {
        name: this.name,
        path: this.path,
      };
    },
  },
  Error: {
    name: 'Error',
    path: '/error',
    getNavigateProps: function () {
      return {
        name: this.name,
        path: this.path,
      };
    },
  },
  Home: {
    name: 'Home',
    path: '/dashboard',
    getNavigateProps: function () {
      return {
        name: this.name,
        path: this.path,
      };
    },
  },
  Accounts: {
    name: 'Accounts',
    path: '/customer-account/:id',
    getNavigateProps: function ({ id }: { id: string }) {
      return {
        params: {
          id,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  Account: {
    name: 'Account',
    path: '/accounts/:type/:id',
    getNavigateProps: function ({
      type,
      id,
      customerId,
    }: {
      type: string;
      id: string;
      customerId?: string | null;
    }) {
      return {
        params: {
          type,
          id,
        },
        qs: customerId
          ? {
              customerid: customerId,
            }
          : undefined,
        name: this.name,
        path: this.path,
      };
    },
  },
  AccountWithFilter: {
    name: 'AccountWithFilter',
    path: '/accounts/:type/:id/:transactionsFilter',
    getNavigateProps: function ({
      type,
      id,
      transactionsFilter,
      customerId,
    }: {
      type: string;
      id: string;
      transactionsFilter: TRANSACTIONS_FILTER;
      customerId?: string | null;
    }) {
      return {
        params: {
          type,
          id,
          transactionsFilter,
        },
        qs: customerId
          ? {
              customerid: customerId,
            }
          : undefined,
        name: this.name,
        path: this.path,
      };
    },
  },
  UserSummary: {
    name: 'UserSummary',
    path: '/user-summary/:customerId',
    getNavigateProps: function ({ customerId }: { customerId: string }) {
      return {
        params: {
          customerId,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  AgronomyNavigationTest: {
    name: 'AgronomyNavigationTest',
    path: '/agronomynavigationTest',
    getNavigateProps: function (params?: Record<string, string>) {
      return {
        params,
        name: this.name,
        path: this.path,
      };
    },
  },
  CreateUser: {
    name: 'CreateUser',
    path: '/create-user',
    getNavigateProps: function () {
      return {
        name: this.name,
        path: this.path,
      };
    },
  },
  EditUser: {
    name: 'EditUser',
    path: '/user/:customerId/edit',
    getNavigateProps: function ({ customerId }: { customerId: string }) {
      return {
        params: {
          customerId,
        },
        name: this.name,
        path: this.path,
      };
    },
  },
  CustomerSelfRegistration: {
    name: 'CustomerSelfRegistration',
    path: '/register',
    getNavigateProps: function () {
      return {
        name: this.name,
        path: this.path,
      };
    },
  },
};

export { routeConfig };
