import React from 'react';
import { Button } from '../Button';

const LoginButton = ({
  isLoading,
  onPress,
}: {
  isLoading: boolean;
  onPress?: () => void;
}): JSX.Element => {
  const styles = {
    button: {
      width: 200,
    },
  };

  return (
    <Button
      testID="login"
      loading={isLoading}
      title="Login"
      buttonStyle={styles.button}
      onPress={onPress}
    />
  );
};

export default LoginButton;
