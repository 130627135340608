import React from 'react';
import { Box } from '@nutrien/bonsai-core';
import WelcomeBack from './WelcomeBack';
import UserInformation from './UserInformation';
import useSubHeaderType from './useSubHeaderType';

const SubHeader = (): JSX.Element | null => {
  const { isCustomerDashboard, isUserSummary } = useSubHeaderType();

  if (isCustomerDashboard) {
    return (
      <Box bg="white">
        <WelcomeBack />
      </Box>
    );
  }

  if (isUserSummary) {
    return (
      <Box bg="white">
        <UserInformation />
      </Box>
    );
  }

  return null;
};

export default SubHeader;
