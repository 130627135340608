import { Icon, useDeviceType, useStyles, useTheme } from '@nutrien/bonsai-core';
import { useStaff } from '../../context/StaffContext';
import { Avatar } from 'native-base';
import React from 'react';
import { Platform, View } from 'react-native';
import useAuth from '../../auth/hooks';
import { useNavigation, useSegment } from '../../hooks';
import useApolloClientWithPersist from '../../hooks/useApolloClientWithPersist';
import { Button } from '../Button';
import useLogin from '../Login/useLogin';
import { trackEvent } from '../../constants/segment';

const HeaderRight = (): JSX.Element | null => {
  const {
    userState: { firstName = '', lastName = '' },
  } = useLogin();
  const { logout } = useAuth();
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { clearCache } = useApolloClientWithPersist();
  const segment = useSegment();

  const {
    state: { hasChangesInForm },
    dispatch,
  } = useStaff();

  const modalPayload = {
    showSaveModal: true,
    feature: 'logout',
    url: {
      name: 'Login',
      path: '/',
      params: { status: '' },
    },
  };

  const logoutWeb = async () => {
    if (hasChangesInForm) {
      dispatch({
        type: 'setNotificationModal',
        value: modalPayload,
      });
    } else {
      await clearCache();
      segment?.track(trackEvent.LOGOUT_SELECTED);
      logout({ returnTo: window.location.origin });
    }
  };

  const logoutNative = async () => {
    if (hasChangesInForm) {
      dispatch({
        type: 'setNotificationModal',
        value: modalPayload,
      });
    } else {
      await clearCache();
      segment?.track(trackEvent.LOGOUT_SELECTED);
      logout();
      navigate({
        name: 'Login',
        path: '/',
        params: { status: '' },
      });
    }
  };

  const logoutFn = Platform.OS === 'web' ? logoutWeb : logoutNative;

  const { isHandset } = useDeviceType();
  const userInitials = (
    firstName.slice(0, 1) + lastName.slice(0, 1)
  ).toUpperCase();

  const avatarSize = isHandset ? 36 : 40;
  const iconSize = isHandset ? 42 : 46;
  const iconOffset = iconSize / 2 - iconSize;

  const styles = useStyles(() => {
    return {
      headerRightContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        marginRight: theme.spacing(1),
      },
      buttonContainerStyle: {
        marginRight: theme.spacing(1),
      },
      iconContainerStyle: {
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,
        paddingTop: theme.spacing(isHandset ? 1 : 1.25),
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconStyle: {
        position: 'absolute',
        left: iconOffset,
      },
    };
  });

  return (
    <View style={styles.headerRightContainer}>
      <Button
        type="clear"
        accessibilityLabel="logout"
        onPress={logoutFn}
        icon={<Icon name="exit-to-app" size={24} />}
        testID="logout-button"
        containerStyle={styles.buttonContainerStyle}
      />
      <Avatar
        _text={{
          color: theme.auColors.neutral[800],
          fontSize: 18,
          fontFamily: theme.typography.fontFamily.semiBold,
        }}
        testID="header-avatar"
        size={`${avatarSize}px`}
        backgroundColor="brand.leaf1"
      >
        {userInitials || (
          <Icon
            testID="avatar-fallback"
            containerStyle={styles.iconContainerStyle}
            iconStyle={styles.iconStyle}
            size={iconSize}
            name="person"
          />
        )}
      </Avatar>
    </View>
  );
};

export default HeaderRight;
