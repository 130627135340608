import { Box, NotificationBase } from '@nutrien/bonsai-core';

import React from 'react';
import { useLogin } from '../../context/LoginContext';

const EmptyDashboard = (): JSX.Element => {
  const {
    state: { verified, firstName, preferredName },
  } = useLogin();
  const message = `Welcome ${
    preferredName || firstName
  }, Your account is unverified.`;
  if (!verified) {
    return (
      <Box flexGrow={1} alignItems="center" justifyContent="center">
        <NotificationBase
          testID="verification-state"
          type={'error'}
          message={message}
        />
      </Box>
    );
  }
  return <Box testID="emptyDashboard" flexGrow={1} />;
};

export default EmptyDashboard;
