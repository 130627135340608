import React, { ChangeEvent, useState } from 'react';
import {
  useTheme,
  useDeviceType,
  Text,
  Input,
  Icon,
  Box,
} from '@nutrien/bonsai-core';
import {
  Pressable,
  StyleSheet,
  ViewStyle,
  KeyboardTypeOptions,
} from 'react-native';

export interface InputFieldProps {
  label: string;
  fieldName: string;
  value: string;
  maxLength?: number;
  errorMessage?: string | boolean;
  onChangeText: (e: string | ChangeEvent<Element>) => void;
  keyboardType?: KeyboardTypeOptions;
  testID?: string;
  customInputStyle?: object;
  containerStyle?: object;
  loading?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  right?: JSX.Element;
}

const InputField = ({
  label,
  fieldName,
  value,
  maxLength,
  errorMessage,
  onChangeText,
  keyboardType,
  testID,
  customInputStyle,
  containerStyle,
  loading,
  readOnly,
  onBlur,
  onFocus,
  disabled,
  right,
  ...otherProps
}: InputFieldProps): JSX.Element => {
  const theme = useTheme();
  const { isHandset } = useDeviceType();
  const [hovered, setHovered] = useState(false);
  const [focused, setFocussed] = useState(false);

  const isDisabled = loading || readOnly || disabled;

  const styles = {
    labelStyle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      fontSize: theme.typography.fontSize.small,
      fontFamily: theme.typography.fontFamily.regular,
      fontWeight: 600,
      color: loading
        ? theme.auColors.neutral[500]
        : theme.auColors.secondary[400],
    } as ViewStyle,
    inputStyle: {
      width: isHandset ? '100%' : customInputStyle || 300,
      minHeight: 40,
      borderColor: theme.auColors.neutral[500],
      borderRadius: 4,
      fontFamily: theme.typography.fontFamily.regular,
      fontSize: theme.typography.fontSize.medium,
      outline: 'none',
      color: theme.auColors.neutral[800],
    },
    errorMessage: {
      color: theme.auColors.functional.error.dark,
      fontSize: theme.typography.fontSize.medium,
      paddingLeft: theme.spacing(1),
    },
    hoveredContainerStyle: {
      borderColor: theme.auColors.secondary[400],
      backgroundColor: theme.auColors.neutral[300],
      borderWidth: 1,
      borderRadius: 4,
    },
    focused: {
      backgroundColor: 'white',
      outline: 'none',
      borderRadius: 4,
      boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.light}`,
    },
    disabledContainerStyle: {
      borderColor: theme.auColors.neutral[400],
      borderRadius: 4,
      borderWidth: 1,
    },
    disabledFontStyle: {
      color: theme.auColors.neutral[500],
    },
    readOnlyContainerStyle: {
      backgroundColor: theme.auColors.neutral[200],
      borderRadius: 4,
      borderWidth: 0,
    },
    readOnlyFontStyle: {
      color: theme.auColors.secondary[400],
      opacity: 1,
    },
    error: {
      borderWidth: 1,
      borderRadius: 4,
      borderColor: theme.auColors.functional.error.dark,
      minHeight: 40,
    },
  };

  const pressableStyleChoice = () => {
    if (focused && errorMessage) {
      return [styles.focused, styles.error];
    }
    if (focused) {
      return [styles.focused];
    }
    return {};
  };

  const inputContainerStyleChoice = () => {
    if (hovered) {
      return styles.hoveredContainerStyle;
    }

    if (readOnly) {
      return styles.readOnlyContainerStyle;
    }

    if (loading) {
      return styles.disabledContainerStyle;
    }

    if (errorMessage) {
      return styles.error;
    }

    return {};
  };

  return (
    <Box style={containerStyle}>
      <Text
        testID={`${fieldName}-field-label`}
        style={styles.labelStyle}
        accessibilityLabel={`Input Label ${fieldName}`}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - web only
        accessibilityRole={'label'}
      >
        {label}
      </Text>
      <Pressable
        focusable={false}
        disabled={isDisabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - web only
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        style={pressableStyleChoice()}
      >
        <Input
          accessibilityLabel={`Input field ${fieldName}`}
          accessibilityRole={'keyboardkey'}
          onFocus={() => {
            setFocussed(true);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setFocussed(false);
            onBlur && onBlur();
          }}
          keyboardType={keyboardType}
          maxLength={maxLength}
          onChangeText={onChangeText}
          value={value}
          disabled={isDisabled}
          testID={testID}
          style={StyleSheet.flatten(
            customInputStyle
              ? [customInputStyle, styles.inputStyle]
              : [styles.inputStyle]
          )}
          inputContainerStyle={inputContainerStyleChoice()}
          disabledInputStyle={
            readOnly ? styles.readOnlyFontStyle : styles.disabledFontStyle
          }
          autoCompleteType={true}
          right={right}
          {...otherProps}
        />
      </Pressable>
      {errorMessage && (
        <Box
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="row"
          marginTop={0.5}
        >
          <Icon
            name="error-outline"
            size={24}
            testID={`error-icon-${fieldName}`}
            color={theme.auColors.brand.barn[300]}
          />
          <Text
            testID={`${fieldName}-field-error`}
            accessibilityRole="alert"
            style={styles.errorMessage}
          >
            {errorMessage}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default InputField;
