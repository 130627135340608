import React from 'react';
import { ViewStyle } from 'react-native';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useDeviceType,
  Text,
  Box,
  Icon,
  Dialog,
  IconButton,
  useTheme,
} from '@nutrien/bonsai-core';
import { useNavigation } from '../../hooks';
import QueryCard from '../../components/QueryCard';
import IconText from '../IconText';
import Breadcrumbs from '../Breadcrumbs';
import { Button } from '../Button';
import { breadcrumbConstants } from '../../constants';
import useUserInformation from './useUserInformation';
import { useStaff } from '../../context/StaffContext';
import useBranchByCode from './useBranchByCode';
import { routeConfig } from '../../routes/shared';

const UserInformation = (): JSX.Element | null => {
  const {
    state: { selectedCustomer },
    dispatch,
  } = useStaff();

  const {
    onboardedBranch,
    branchByCodeLoading,
    branchByCodeError,
    branchByCodeRefetch,
  } = useBranchByCode();

  const {
    isSendInviteModalOpen,
    setIsSendInviteModalOpen,
    isUserDataLoading,
    customerCreatedOrResendJobCreatedDate,
    createOrResendCustomerLoginLoading,
    onCloseSendInviteModal,
    onConfirmSendInvite,
    lastLoginDate,
    fetchCustomerLoginLoading,
    hasGetCustomerLoginError,
    emailSentDate,
    verificationLinkExpirationDate,
    isCustomerInAuth0,
    isCustomerVerified,
  } = useUserInformation(selectedCustomer);

  const theme = useTheme();
  const { isHandset, isDesktop } = useDeviceType();
  const flags = useFlags();
  const { navigate } = useNavigation();

  const email = selectedCustomer?.email;
  const inviteSentDate = customerCreatedOrResendJobCreatedDate || emailSentDate;

  const styles = {
    imageStyle: { height: isHandset ? 65 : 93 },
    statusTextStyle: {
      color: theme.auColors.neutral[800],
      marginTop: theme.spacing(1),
      textAlign: isHandset ? 'left' : 'right',
    },
    activeStyle: {
      borderLeftWidth: theme.spacing(0.5),
      borderColor: theme.auColors.functional.success.dark,
      padding: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
    iconStyle: {
      marginLeft: theme.spacing(1),
      color: theme.auColors.neutral[600],
    },
    addUserButtonStyle: {
      paddingRight: 0,
      borderColor: theme.auColors.neutral[500],
      height: 42, // this might be changed later
      backgroundColor: theme.auColors.neutral[100], // this might be changed later
    },
    headingLarge:
      theme.auTypography[isDesktop ? 'desktop' : 'mobile'].headingLarge,
  };

  const renderInviteModals = () => (
    <Dialog visible={isSendInviteModalOpen} onDismiss={onCloseSendInviteModal}>
      <Dialog.Title title="Registration Invite" />
      <Dialog.Content>
        <Text testID="send-invite-modal-content">
          Send registration invite to {email}
        </Text>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          title="Cancel"
          type="outline"
          onPress={onCloseSendInviteModal}
          testID="cancel-send-invite-button"
        />
        <Box ml={1.5}>
          <Button
            title="Confirm"
            onPress={onConfirmSendInvite}
            testID="confirm-send-invite-button"
          />
        </Box>
      </Dialog.Actions>
    </Dialog>
  );

  const renderRegistration = (): JSX.Element => {
    const showStatusText =
      isCustomerInAuth0 ||
      hasGetCustomerLoginError ||
      customerCreatedOrResendJobCreatedDate;
    const getStatusText = (
      verificationExpirationDate: string | null,
      invitationSentDate: string | null,
      hasError: boolean
    ) => {
      if (hasError) {
        return 'Last login: unavailable';
      }
      if (verificationExpirationDate) {
        return `Expired: ${verificationExpirationDate}`;
      }

      if (invitationSentDate) {
        return `Sent: ${invitationSentDate}`;
      }

      return 'Sent: expired without login';
    };

    return (
      <Box
        position={isHandset ? 'relative' : 'absolute'}
        right={0}
        mb={isDesktop ? 1 : 3}
        mt={isHandset ? 0 : -5}
      >
        {!isCustomerVerified && (
          <>
            <Button
              type="outline"
              accessibilityLabel={`Send invite to email ${email}`}
              iconRight={
                <Icon
                  name="email-outline"
                  type="material-community"
                  testID="send-invite-email-icon"
                />
              }
              testID="send-invite-button"
              title={`${
                (isCustomerInAuth0 && !hasGetCustomerLoginError) ||
                inviteSentDate
                  ? 'Re-send'
                  : 'Send'
              } invite`}
              fullWidth={isHandset}
              onPress={() => setIsSendInviteModalOpen(true)}
              loading={createOrResendCustomerLoginLoading}
              disabled={hasGetCustomerLoginError}
            />
            {showStatusText && (
              <Text
                style={styles.statusTextStyle as ViewStyle}
                testID="status-text"
              >
                {getStatusText(
                  verificationLinkExpirationDate,
                  inviteSentDate,
                  hasGetCustomerLoginError
                )}
              </Text>
            )}
          </>
        )}
        {isCustomerVerified && (
          <>
            <Box
              flexDirection="row"
              alignItems="center"
              testID="active-user-status"
              bg={theme.auColors.functional.success.light}
              style={styles.activeStyle}
              width="154px"
              alignSelf={isHandset ? 'auto' : 'end'}
            >
              <Icon
                name="done-all"
                color={theme.auColors.functional.success.dark}
              />
              <Text>Active user</Text>
            </Box>
            <Text
              style={styles.statusTextStyle as ViewStyle}
              testID="status-text"
            >
              Last login: {lastLoginDate || 'user has not logged in'}
            </Text>
          </>
        )}
      </Box>
    );
  };

  const handleClickEditUser = () => {
    dispatch({
      type: 'setShowSaveUserSuccess',
      value: false,
    });
    navigate(
      routeConfig.EditUser.getNavigateProps({
        customerId: selectedCustomer?.customerId as string,
      })
    );
  };

  return (
    <>
      <Box
        maxWidth={990}
        width="100%"
        marginX="auto"
        py={3}
        px={2}
        position="relative"
      >
        <Breadcrumbs
          items={[breadcrumbConstants.home, { title: 'User Summary' }]}
        />
        <Box
          flexDirection="row"
          alignItems="center"
          mr={isDesktop ? 11 : 0}
          mt={2}
          maxWidth={450}
        >
          <Text
            style={styles.headingLarge}
            testID="user-information-heading"
            h1
          >
            User Information
          </Text>
          {flags['ng-2812-staff-edits-user'] && (
            <IconButton
              icon="edit"
              type="default"
              testID="edit-icon"
              onPress={handleClickEditUser}
            />
          )}
        </Box>
        <QueryCard
          wrapper={Box}
          loading={
            branchByCodeLoading ||
            isUserDataLoading ||
            fetchCustomerLoginLoading
          }
          error={branchByCodeError}
          handleReload={() => branchByCodeRefetch()}
        >
          <Box mb={2}>
            <IconText
              text={selectedCustomer?.customerName || '-'}
              iconName="people"
              testID="user-name"
              boldText
              isDesktop={isDesktop}
            />
          </Box>
          {renderRegistration()}
          <Box
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
            mb={isDesktop ? 2 : 0}
          >
            <IconText
              text={email || '-'}
              iconName="email"
              testID="user-email"
              isDesktop={isDesktop}
            />
            <IconText
              text={
                onboardedBranch ? `Onboarded Branch: ${onboardedBranch}` : '-'
              }
              iconName="login"
              testID="onboarded-branch"
              isDesktop={isDesktop}
            />
          </Box>
        </QueryCard>
      </Box>
      {renderInviteModals()}
    </>
  );
};

export default UserInformation;
