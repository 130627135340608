import { ApolloError, gql, useQuery } from '@apollo/client';
import {
  CustomerAccountsSummarySplitUp,
  CustomerAccountsSummarySplitUpVariables,
} from 'types/generated/CustomerAccountsSummarySplitUp';

export const CUSTOMER_ACCOUNTS_SUMMARY_SPLIT_UP = gql`
  query CustomerAccountsSummarySplitUp(
    $payload: [AccountSummarySplitUpPayload!]
  ) {
    getAccountSummarySplitUp(payload: { Accounts: $payload }) {
      AccountNumber
      Items {
        AccountSplit
        Field
        SubField
        Summary {
          Amount
          Key
          Label
        }
        Documents {
          Amount
          ClearingDocNumber
          Currency
          DocDate
          DocDescription
          DocumentNumber
          DueDate
        }
        __typename
      }
    }
  }
`;

const useCustomerAccountsSummarySplitUpQuery = ({
  accounts,
  skip,
  onCompleted,
}: {
  accounts: Array<{
    accountNumber: string;
    accountType: string;
  }>;
  skip: boolean;
  onCompleted?: (data: CustomerAccountsSummarySplitUp) => void;
}): [
  CustomerAccountsSummarySplitUp | undefined,
  boolean,
  ApolloError | undefined,
  () => void
] => {
  const { loading, error, data, refetch } = useQuery<
    CustomerAccountsSummarySplitUp,
    CustomerAccountsSummarySplitUpVariables
  >(CUSTOMER_ACCOUNTS_SUMMARY_SPLIT_UP, {
    skip,
    variables: {
      payload: accounts.map(({ accountNumber, accountType }) => ({
        AccountNumber: accountNumber,
        AccountType: accountType,
        CompanyCode: '8200',
      })),
    },
    onCompleted,
  });

  return [data, loading, error, refetch];
};

export default useCustomerAccountsSummarySplitUpQuery;
