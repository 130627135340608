import React, { ReactElement } from 'react';
import { Box, useTheme } from '@nutrien/bonsai-core';
import { AutocompleteProps } from 'react-native-autocomplete-input';
import Downshift from 'downshift';
import { ListRenderItemInfo } from 'react-native';

interface ListRenderItemProps extends ListRenderItemInfo<Item> {
  isHighlighted: boolean;
}

interface Item {
  branch_name?: string | null;
  branch_code?: string | null;
  abn?: string | null;
  customer_guid?: any | null;
  name1?: string | null;
  name2?: string | null;
  telephone?: string | null;
}
// TODO: wrap up and move to bonsai
const Autocomplete = ({
  data,
  renderTextInput,
  hideResults,
  flatListProps,
  onSelectionChange,
  listContainerStyle,
}: AutocompleteProps<Item>): ReactElement => {
  const theme = useTheme();
  const styles = {
    resultsContainer: {
      borderWidth: 1,
      borderColor: theme.auColors.neutral[500],
      borderTopWidth: 0,
      top: 42,
      width: '100%',
    },
  };

  return (
    <Downshift
      onChange={(selection) =>
        onSelectionChange ? onSelectionChange(selection) : null
      }
      itemToString={(item) => (item ? item.name1 : '')}
    >
      {({ getInputProps, getItemProps, highlightedIndex, getRootProps }) => {
        return (
          <Box position="relative">
            {renderTextInput && (
              <Box
                {...getRootProps({ refKey: '' }, { suppressRefError: true })}
              >
                {renderTextInput(getInputProps())}
              </Box>
            )}
            {!hideResults && data.length !== 0 && (
              <Box
                position="absolute"
                style={[styles.resultsContainer, listContainerStyle]}
              >
                {data.map((item, index) => {
                  const isHighlighted = highlightedIndex === index;

                  if (!flatListProps?.renderItem) {
                    return null;
                  }

                  return (
                    <Box
                      {...getItemProps({
                        index,
                        item,
                        style: {
                          backgroundColor:
                            // TODO highlighted background color may need to be changed later
                            isHighlighted
                              ? theme.auColors.primary[400]
                              : theme.auColors.neutral[100],
                        },
                      })}
                      key={
                        item.branch_code
                          ? item.branch_code
                          : item.customer_guid || item.name1
                      }
                    >
                      {flatListProps?.renderItem({
                        item,
                        isHighlighted,
                      } as ListRenderItemProps)}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      }}
    </Downshift>
  );
};

export default Autocomplete;
