import React, { useState, ReactNode, useEffect } from 'react';
import { ListRenderItemInfo, ViewStyle } from 'react-native';
import { Box, useDeviceType, Text } from '@nutrien/bonsai-core';
import isempty from 'lodash.isempty';
import theme from '../../theme/theme';
import { Button } from '../Button';
import { useNavigation, useFormatters } from '../../hooks';
import { UserDetailsFormProps, Errors } from '.';
import ErrorSummaryBanner from './ErrorSummaryBanner';
import SaveChangesBanner from './SaveChangesBanner';
import Autocomplete from '../Autocomplete';
import BranchRow from './BranchRow';
import SearchBranchInput from './SearchBranchInput';
import useUserDetailsForm from './useUserDetailsForm';
import { SearchBranch_sords_branch } from 'types/generated/SearchBranch';

import InputField from '../InputField';
import { useStaff } from '../../context/StaffContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

type RenderItemProps =
  | ((info: ListRenderItemInfo<unknown>) => React.ReactElement | null)
  | null
  | undefined;

const UserDetailsForm = ({
  values,
  errors,
  dirty,
  touched,
  handleChange,
  submitForm,
  setFieldValue,
  saveChangesLoading,
  graphQLError,
  type,
}: UserDetailsFormProps): JSX.Element => {
  const { goBack } = useNavigation();
  const { isHandset } = useDeviceType();
  const { formatMobile } = useFormatters();
  const [errorSummary, setErrorSummary] = useState<Errors | null>(null);
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false);
  const {
    state: { hasChangesInForm },
    dispatch,
  } = useStaff();

  const flags = useFlags();

  useEffect(() => {
    if (dirty && !hasChangesInForm) {
      dispatch({ type: 'setChangesInForm', value: true });
    }
    if (hasChangesInForm && !dirty) {
      dispatch({ type: 'setChangesInForm', value: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const {
    searchTerm,
    hideSearchResults,
    sortedSearchResults,
    handleSearchChange,
    searchBranchLoading,
    searchBranchError,
    handleSearchResultClick,
    setHideSearchResults,
  } = useUserDetailsForm(type, setFieldValue);

  const basicFields = [
    {
      label: 'Given Name*',
      fieldName: 'givenName',
      value: values.givenName,
      errorMessage: errors.givenName,
      isTouched: touched.givenName,
    },
    {
      label: 'Family Name*',
      fieldName: 'familyName',
      value: values.familyName,
      errorMessage: errors.familyName,
      isTouched: touched.familyName,
    },
    {
      label: 'Preferred Name',
      fieldName: 'preferredName',
      value: values.preferredName,
    },
    {
      label: 'Email Address*',
      fieldName: 'emailAddress',
      value: values.emailAddress,
      errorMessage: errors.emailAddress,
      isTouched: touched.emailAddress,
      readOnly: type === 'edit',
    },
  ];

  const formattedMobile = formatMobile(values.mobileNumber);

  const handleSubmitForm = () => {
    dispatch({ type: 'setChangesInForm', value: false });
    submitForm();
    if (errorSummary === null) {
      setHasFormSubmitted(true);
    } else {
      setErrorSummary(errors);
    }
  };

  // This is to handle the issue that error summary is not showing up
  // when user submits the form directly without editing any field
  if (errorSummary === null && !isempty(errors) && hasFormSubmitted) {
    setErrorSummary(errors);
  }

  const hasOnboardedBranchError = touched.onboardedBranch
    ? errors.onboardedBranch
    : undefined;

  const renderSearchInput = ({ onKeyDown }: { onKeyDown?: () => void }) => (
    <SearchBranchInput
      {...{
        handleSearchChange,
        searchBranchLoading,
        setHideSearchResults,
        onKeyDown,
        searchTerm,
        saveChangesLoading,
        searchBranchError,
        errorMessage: hasOnboardedBranchError,
        mandatory: type === 'create',
      }}
    />
  );

  const renderSearchBranchResult = ({
    item,
    isHighlighted,
  }: {
    item: SearchBranch_sords_branch;
    isHighlighted?: boolean;
  }) => {
    return (
      <BranchRow
        key={item.branch_code}
        item={item}
        handleSearchResultClick={handleSearchResultClick}
        isHighlighted={isHighlighted}
      />
    );
  };

  const styles = {
    mobileContainer: {
      width: isHandset ? 136 : 164,
    },
    cancelButtonContainer: {
      marginRight: isHandset ? 0 : theme.spacing(2),
      marginTop: isHandset ? 20 : 0,
    },
    requiredFieldText: {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.fontSize.small,
      fontWeight: 400,
    } as ViewStyle,
    searchContainer: { borderWidth: 0 },
    branchResultListContainer: {
      borderWidth: 1,
      top: theme.spacing(9.5), // Autocomplete + InputField
    },
    containerStyle: {
      marginRight: 0,
      marginBottom: theme.spacing(1.5),
    },
  };

  // Enable restriction in "EDIT" and when flag is enabled
  const restrictOnboardedBranch =
    type === 'edit' && flags['NG-3649-onboard-branch-restrict'];
  return (
    <>
      <SaveChangesBanner
        graphQLError={graphQLError}
        errorSummary={errorSummary}
      />
      <ErrorSummaryBanner errorSummary={errorSummary} />
      <Box mx={isHandset ? 0 : 'auto'}>
        <Text style={styles.requiredFieldText}>*required field</Text>
        <Box display="flex" flexDirection="column">
          {basicFields.map(
            ({
              fieldName,
              label,
              value,
              errorMessage,
              isTouched,
              readOnly,
            }) => (
              <InputField
                key={fieldName}
                fieldName={fieldName}
                label={label}
                onChangeText={handleChange(fieldName)}
                value={value}
                errorMessage={isTouched && errorMessage}
                maxLength={100}
                testID={`${fieldName}-input`}
                loading={saveChangesLoading}
                containerStyle={styles.containerStyle}
                readOnly={readOnly}
              />
            )
          )}
        </Box>
        <Box display="flex" flexDirection="column" mt="0">
          <Box mr={isHandset ? 0 : 'auto'}>
            <InputField
              label="Mobile Number*"
              onChangeText={handleChange('mobileNumber')}
              value={formattedMobile}
              errorMessage={touched.mobileNumber && errors.mobileNumber}
              // maxLength should be 10, but leave 2 places for spaces, example 0400 000 000
              maxLength={12}
              fieldName="mobileNumber"
              keyboardType="numeric"
              testID="mobileNumber-input"
              customInputStyle={styles.mobileContainer}
              loading={saveChangesLoading}
              containerStyle={styles.containerStyle}
            />
          </Box>
          <Box>
            {restrictOnboardedBranch ? (
              <InputField
                fieldName="onboardedBranch"
                label="Onboarded Branch"
                value={searchTerm}
                testID="onboardedBranch-input"
                containerStyle={styles.containerStyle}
                readOnly={true}
                onChangeText={() => undefined}
              />
            ) : (
              <Box>
                <Autocomplete
                  hideResults={hideSearchResults}
                  data={sortedSearchResults}
                  onBlur={() => setHideSearchResults(true)}
                  onFocus={() => setHideSearchResults(false)}
                  value={searchTerm}
                  flatListProps={{
                    keyExtractor: (_, i) => i.toString(),
                    renderItem: renderSearchBranchResult as RenderItemProps,
                    keyboardShouldPersistTaps: 'always',
                    listKey: 'branchCode',
                  }}
                  // We added listContainerStyle and inputContainerStyle here
                  // to fix styling issue on native device
                  inputContainerStyle={styles.searchContainer}
                  listContainerStyle={
                    sortedSearchResults.length > 0
                      ? styles.branchResultListContainer
                      : {}
                  }
                  renderTextInput={renderSearchInput as () => ReactNode}
                  onSelectionChange={handleSearchResultClick as () => void}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        flexDirection={isHandset ? 'column-reverse' : 'row'}
        justifyContent="flex-end"
        mt={6}
        zIndex={-1}
      >
        <Button
          type="outline"
          onPress={goBack}
          title="Cancel"
          containerStyle={styles.cancelButtonContainer}
          testID="cancel-button"
          accessibilityLabel="Cancel Button"
          accessibilityRole={'button'}
        />
        <Button
          onPress={handleSubmitForm}
          title="Save changes"
          testID="submit-button"
          loading={saveChangesLoading}
          accessibilityLabel="Save Button"
          accessibilityRole={'button'}
        />
      </Box>
    </>
  );
};

export default UserDetailsForm;
