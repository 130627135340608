import React, { ReactElement, useEffect } from 'react';
import { useStyles, Box } from '@nutrien/bonsai-core';
import { ScrollView, View } from 'react-native';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Header from '../Header/Header';
import ErrorBoundary from '../ErrorBoundary';
import { SubHeaderBanner, SubHeader } from '../SubHeader';
import SystemOutageAlert from '../GlobalAlert/SystemOutageAlert';
import LivestockInvoiceAlert from '../GlobalAlert/LivestockInvoiceAlert';
import { SubscribeDownloads } from '../SubscribeDownloads';
import useLogin from '../Login/useLogin';
import RegistrationError from './RegistrationError';
import { useRoutes, useSegment } from '../../hooks';
import GetRoutesByRoles from '../../routes';
import { getPathFromPathName } from '../../utils/segment';

const Layout = ({
  children,
}: {
  children: ReactElement[] | ReactElement | null;
}): JSX.Element => {
  const flags = useFlags();
  const styles = useStyles((theme) => ({
    scrollable: {
      maxHeight: '100vh',
      backgroundColor: theme.auColors.neutral[300],
      position: 'relative',
    },
    content: {
      width: '100%',
      maxWidth: 990,
      paddingBottom: theme.spacing(2),
    },
    contentWrapper: {
      alignItems: 'center',
    },
  }));

  const { userState, setUserState } = useLogin();
  const routes = GetRoutesByRoles(userState?.roles);
  const { pathName } = useRoutes();
  const segment = useSegment();

  useEffect(() => {
    if (userState.email && segment) {
      const route = routes.find(
        ({ path }) =>
          getPathFromPathName(path) === getPathFromPathName(pathName as string)
      );
      const pageName = route ? route.name : '';
      segment.page(pageName, {
        pageName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);

  useEffect(() => {
    // setUser state on browser refresh
    if (!userState.email) {
      setUserState();
    }
  }, [userState, setUserState]);

  return (
    <ErrorBoundary>
      <ScrollView style={styles.scrollable} stickyHeaderIndices={[0]}>
        <>
          <Header />
          {flags['system-outage-alert'] && <SystemOutageAlert />}
          {flags['plasma-invoice-issue-alert'] && <LivestockInvoiceAlert />}
          <RegistrationError />
        </>
        <SubHeaderBanner />
        <SubscribeDownloads />
        <SubHeader />
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <Box p={2}>{children}</Box>
          </View>
        </View>
      </ScrollView>
    </ErrorBoundary>
  );
};

export default Layout;
