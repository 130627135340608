import { Box, NamedStyles, Text, useStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useFormatters } from '../../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../../SummaryCard';

export interface FinanceEntireAccountPanelProps {
  summary?: AccountSummaryData | null;
  panelStyles?: NamedStyles<Record<string, undefined>>;
}

export const FinanceEntireAccountPanel: FC<FinanceEntireAccountPanelProps> = ({
  summary,
  panelStyles,
}) => {
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(panelStyles ?? {});

  return (
    <SummaryCard
      heading={'Entire account'}
      summaryRow={[
        {
          label: (
            <Box
              style={styles.label}
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text style={styles.labelText}>Total current balance</Text>
            </Box>
          ),
          content: renderNoneIfZero(summary?.CurrentBalance),
          testID: 'total-current-balance-column',
        },
        {
          label: (
            <Box
              style={styles.label}
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text style={styles.labelText}>Credit limit</Text>
            </Box>
          ),
          content: renderNoneIfZero(summary?.CreditLimit),
          testID: 'credit-limit-column',
        },
      ]}
      testID={'account-details-finance-entire-account-panel'}
    />
  );
};
