import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  CreateCustomerSelfRegisterLoginVariables,
  CreateCustomerSelfRegisterLogin,
} from 'types/generated/CreateCustomerSelfRegisterLogin';

export const CREATE_CUSTOMER_SELF_REG_LOGIN = gql`
  mutation CreateCustomerSelfRegisterLogin(
    $email: String!
    $givenName: String!
    $familyName: String!
    $mobilePhone: String!
    $abn: String!
  ) {
    createCustomerSelfRegisterLogin(
      payload: {
        email: $email
        given_name: $givenName
        family_name: $familyName
        mobile_phone: $mobilePhone
        abn: $abn
      }
    ) {
      email
      given_name
      family_name
      full_name
      mobile_phone
    }
  }
`;

type CreateCustomerSelfRegisterLoginFunction = (
  options?: MutationFunctionOptions<
    CreateCustomerSelfRegisterLogin,
    CreateCustomerSelfRegisterLoginVariables
  >
) => Promise<unknown>;

const useCreateCustomerSelfRegisterLoginMutation = (): [
  CreateCustomerSelfRegisterLoginFunction,
  {
    data?: CreateCustomerSelfRegisterLogin | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [createCustomerSelfRegisterLogin, { data, error, loading }] =
    useMutation<
      CreateCustomerSelfRegisterLogin,
      CreateCustomerSelfRegisterLoginVariables
    >(CREATE_CUSTOMER_SELF_REG_LOGIN);

  return [createCustomerSelfRegisterLogin, { data, error, loading }];
};

export default useCreateCustomerSelfRegisterLoginMutation;
