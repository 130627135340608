import { Box, NamedStyles, Text, useStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary, useFormatters } from '../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../SummaryCard';

export interface FinanceOneSectionSummaryProps {
  accountDisplayName: string;
  summary?: AccountSummaryData | null;
  summaryStyles?: NamedStyles<Record<string, undefined>>;
  testID?: string;
  handleClickViewDetails: () => void;
}

const FinanceOneSectionSummary: FC<FinanceOneSectionSummaryProps> = ({
  accountDisplayName,
  summary,
  summaryStyles,
  testID,
  handleClickViewDetails,
}) => {
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(summaryStyles ?? {});
  const {
    finance: { interestBearingBalance, nonInterestBearingBalance },
  } = useAccountSummary(summary);

  return (
    <SummaryCard
      heading={accountDisplayName}
      button={{
        label: `View ${accountDisplayName.toLowerCase()}`,
        testID: `view-details-${testID}`,
        onPress: handleClickViewDetails,
      }}
      summaryRow={[
        {
          label: (
            <Box style={styles.label}>
              <Text style={styles.labelText}>Interest bearing balance</Text>
            </Box>
          ),
          content: renderNoneIfZero(interestBearingBalance),
        },
        {
          label: (
            <Box
              style={styles.label}
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text style={styles.labelText}>Non-interest bearing balance</Text>
            </Box>
          ),
          content: (
            <Text bodyBold>{renderNoneIfZero(nonInterestBearingBalance)}</Text>
          ),
        },
      ]}
      testID={testID}
    />
  );
};

export default FinanceOneSectionSummary;
