import React from 'react';
import { Box, useDeviceType } from '@nutrien/bonsai-core';
import Logo from '../Logo';

const HeaderLogo = (): JSX.Element => {
  const { isHandset } = useDeviceType();

  const logoSize = isHandset ? 28 : 36;
  const logoProps = {
    width: isHandset ? '48px' : '72px',
    height: isHandset ? '48px' : '72px',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Box testID="logo-wrapper" {...logoProps}>
      <Logo dark width={logoSize} height={logoSize} />
    </Box>
  );
};

export default HeaderLogo;
