import React from 'react';
import { Icon, ActivityIndicator } from '@nutrien/bonsai-core';
import { Button } from '../Button';

export interface DownloadButtonProps {
  isPending?: boolean;
  numberOfPdfsTriggered?: number;
  numberOfPdfs?: number;
  handleGetPdfZip: () => void;
}

const DownloadButton = ({
  isPending,
  numberOfPdfsTriggered,
  numberOfPdfs,
  handleGetPdfZip,
}: DownloadButtonProps): JSX.Element => {
  if (isPending) {
    return (
      <Button
        title={`Preparing ${numberOfPdfsTriggered} invoices .ZIP`}
        disabled={true}
        iconLeft={<ActivityIndicator testID="preparing-invoices-icon" />}
      />
    );
  }
  return (
    <Button
      title={`Download all invoices (${numberOfPdfs})`}
      onPress={handleGetPdfZip}
      iconLeft={
        <Icon
          name="file-download"
          type="material"
          testID="download-all-invoices-icon"
        />
      }
    />
  );
};

export default DownloadButton;
