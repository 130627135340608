import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useBranchByCodeQuery, RefetchBranchQuery } from '../../hooks';
import { useStaff, CustomerDetail } from '../../context/StaffContext';

export interface BranchByCode {
  onboardedBranch: string | null;
  branchByCodeLoading: boolean;
  branchByCodeError: ApolloError | undefined;
  branchByCodeRefetch: RefetchBranchQuery;
}

const useBranchByCode = (): BranchByCode => {
  const {
    state: { selectedCustomer },
    dispatch,
  } = useStaff();
  const [
    branchByCodeData,
    branchByCodeLoading,
    branchByCodeError,
    branchByCodeRefetch,
  ] = useBranchByCodeQuery({
    branch_code: selectedCustomer?.onboardedBranchCode || '',
    skip: !selectedCustomer,
  });

  const onboardedBranch =
    branchByCodeData && branchByCodeData.sords_branch.length > 0
      ? branchByCodeData.sords_branch[0].branch_name
      : null;

  useEffect(() => {
    if (
      onboardedBranch &&
      onboardedBranch !== selectedCustomer?.onboardedBranch
    ) {
      dispatch({
        type: 'setSelectedCustomer',
        value: {
          ...selectedCustomer,
          onboardedBranch,
        } as CustomerDetail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onboardedBranch]);

  return {
    onboardedBranch,
    branchByCodeLoading,
    branchByCodeError,
    branchByCodeRefetch,
  };
};

export default useBranchByCode;
