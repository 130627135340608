const isIOS = () => {
  return ['iPad', 'iPhone', 'iPod'].includes(navigator.platform);
};

const browserDetect = () => {
  const userAgent = navigator.userAgent;

  const browsersList = [
    { browserName: 'chrome', regex: /chrome|chromium|crios/i },
    { browserName: 'firefox', regex: /firefox|fxios/i },
    { browserName: 'safari', regex: /safari/i },
    { browserName: 'opera', regex: /opr\//i },
    { browserName: 'edge', regex: /edg/i },
  ];

  for (const browser of browsersList) {
    const { browserName, regex } = browser;
    if (userAgent.match(regex)) {
      return browserName;
    }
  }
  return 'No browser detection';
};

export { isIOS, browserDetect };
