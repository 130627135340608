import {
  ApolloError,
  ApolloQueryResult,
  gql,
  useQuery,
  useLazyQuery,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
} from '@apollo/client';
import { Branches } from 'types/generated/Branches';
import { BranchByCodeVariables } from 'types/generated/BranchByCode';
import {
  SearchBranchVariables,
  SearchBranch,
} from 'types/generated/SearchBranch';

export const BRANCHES_LIST = gql`
  query Branches {
    sords_branch {
      branch_name
      branch_code
    }
  }
`;

export const BRANCH_BY_CODE = gql`
  query BranchByCode($branch_code: String!) {
    sords_branch(where: { branch_code: { _eq: $branch_code } }) {
      branch_name
      branch_code
    }
  }
`;

export const SEARCH_BRANCH = gql`
  query SearchBranch($searchTerm: String!) {
    sords_branch(where: { branch_name: { _ilike: $searchTerm } }, limit: 5) {
      branch_name
      branch_code
    }
  }
`;

export type Refetch = () => Promise<ApolloQueryResult<Branches>>;

const useBranchesQuery = (
  skip = false
): [Branches | undefined, boolean, ApolloError | undefined, Refetch] => {
  const { loading, error, data, refetch } = useQuery<Branches>(BRANCHES_LIST, {
    skip,
    notifyOnNetworkStatusChange: true,
  });

  return [data, loading, error, refetch];
};

const useBranchByCodeQuery = ({
  branch_code,
  skip,
}: {
  branch_code: string;
  skip?: boolean;
}): [Branches | undefined, boolean, ApolloError | undefined, Refetch] => {
  const { loading, error, data, refetch } = useQuery<
    Branches,
    BranchByCodeVariables
  >(BRANCH_BY_CODE, {
    skip,
    variables: { branch_code },
  });

  return [data, loading, error, refetch];
};

const useSearchBranchQuery = (): {
  getBranchByName: (
    options: QueryLazyOptions<SearchBranchVariables>
  ) => Promise<LazyQueryResult<SearchBranch, OperationVariables>>;
  data: SearchBranch | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [getBranchByName, { loading, error, data }] =
    useLazyQuery<SearchBranch>(SEARCH_BRANCH, {
      fetchPolicy: 'network-only',
    });

  return { getBranchByName, data, loading, error };
};

export default useBranchesQuery;

export { useBranchByCodeQuery, useSearchBranchQuery };
