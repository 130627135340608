import { Box } from '@nutrien/bonsai-core';
import React from 'react';
import { TransactionGroup } from '../../hooks/useTransactions/transaction.helper';
import { DownloadDocumentFn } from '../Transaction/TabScene';
import GroupedTransactionItem from './GroupedTransactionItem';
import GroupHeader from './GroupHeader';

interface GroupedTransactionsProps {
  onPress: DownloadDocumentFn;
  group: TransactionGroup;
}

// TODO: When NG-2721-livestock-sale-lot-details feature flag is ready,
// this component is safe to delete as it is replaced with `Transaction`.
const GroupedTransactions = ({
  group,
  onPress,
}: GroupedTransactionsProps): JSX.Element => {
  const { items } = group;

  return (
    <>
      <GroupHeader {...group} onPress={onPress} />
      <Box testID="grouped-transactions" mb={4}>
        {items.map(
          (item, index) =>
            item.postingKey && (
              <GroupedTransactionItem key={index} item={item} />
            )
        )}
      </Box>
    </>
  );
};

export default GroupedTransactions;
