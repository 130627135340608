import { useLogin } from '../../context/LoginContext';
import { useRoutes } from '../../hooks';

const useSubHeaderType = () => {
  const {
    state: { roles },
  } = useLogin();
  const { routeName, pathName } = useRoutes();

  const isCustomerDashboard =
    roles.includes('customer') &&
    (routeName === 'Home' || pathName === '/dashboard');

  const isUserSummary =
    roles.includes('staff') &&
    (routeName === 'UserSummary' || pathName?.includes('/user-summary'));
  const isBranchDashboardOrCreateUser =
    roles.includes('staff') &&
    (['Home', 'CreateUser'].includes(routeName ?? '') ||
      ['/dashboard', '/create-user'].includes(pathName ?? ''));

  return { isCustomerDashboard, isUserSummary, isBranchDashboardOrCreateUser };
};

export default useSubHeaderType;
