import React, { FC } from 'react';
import { View } from 'react-native';
import { Box, Text, useStyles, useDeviceType } from '@nutrien/bonsai-core';
import { useFormatters } from '../../hooks';
import {
  CustomerAccountWithFinance_sords_customer_account_finance as FinanceData,
  CustomerAccountWithFinance_customer_customer_statement as StatementData,
  CustomerAccountWithFinance_getAccountSummary as AccountSummaryData,
} from '../../types/generated/CustomerAccountWithFinance';
import { AccountType } from './useAccountOverview';
import overviewCopy from './copy.json';

const copy = overviewCopy.tradingOverview;

export interface TradingOverviewProps extends FinanceData {
  account_type: AccountType;
  statement: StatementData;
  summary: AccountSummaryData | null;
}

// TODO: When ng-2585-wdio-account-summary feature flag is ready,
// this component is safe to delete as it is replaced with `TradingSummary`.
const TradingOverview: FC<TradingOverviewProps> = ({
  statement = { total_due: 0, current_due: 0, overdue_balance: 0 },
  summary,
}) => {
  const { isHandset } = useDeviceType();
  const { formatCurrency, formattedAmount } = useFormatters();
  const styles = useStyles((theme) => {
    return {
      balanceValue: {
        fontSize: theme.typography.fontSize.large,
        lineHeight: theme.typography.fontSize.large * 1.5,
      },
      overdueLabel: {
        color: theme.auColors.functional.error.dark,
        backgroundColor: theme.auColors.functional.error.light,
        paddingHorizontal: theme.spacing(1.5),
        borderRadius: theme.shape.borderRadiusLarge,
      },
      overdueChip: {
        alignItems: 'flex-start',
      },
      label: {
        color: theme.auColors.neutral[600],
        fontSize: theme.typography.fontSize.medium,
        lineHeight: theme.typography.fontSize.medium * 1.5,
        marginTop: theme.spacing(1),
      },
      value: {
        fontSize: theme.typography.fontSize.small,
        lineHeight: theme.typography.fontSize.small * 1.5,
      },
    };
  });

  const { current_due, overdue_balance } = statement;

  const isOverdue = overdue_balance > 0;

  return (
    <Box flexDirection={isHandset ? 'column' : 'row'} flexGrow={1}>
      <Box
        testID="account-total"
        flexGrow={1}
        minWidth={'40%'}
        justifyContent="space-between"
      >
        <Text style={styles.balanceValue} subHeaderBold>
          {formattedAmount(summary?.CurrentBalance)}
        </Text>
        <Text style={styles.label}>{copy.due.total}</Text>
      </Box>
      <Box flexDirection="row" py={isHandset ? 4 : 0} flexGrow={2}>
        <Box flexGrow={1} testID="account-due" justifyContent="space-between">
          <Text style={styles.value} captionBold>
            {formatCurrency(current_due)}
          </Text>
          <Text style={styles.label}>{copy.due.current}</Text>
        </Box>
        {isOverdue && (
          <Box
            flexGrow={1}
            testID="account-overdue"
            justifyContent="space-between"
          >
            <Text style={styles.value} captionBold>
              {formatCurrency(overdue_balance)}
            </Text>
            <View style={styles.overdueChip}>
              <View>
                <Text style={styles.overdueLabel}>{copy.due.over}</Text>
              </View>
            </View>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TradingOverview;
