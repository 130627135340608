import AsyncStorage from '@react-native-async-storage/async-storage';

import { getM2mTokenFromAuth0 } from './m2mAuth0Client';
import {
  isDateWithinMinutesOfDate,
  secondsToMilliseconds,
} from '../objectHelpers/date.helper';

export enum M2mTokenType {
  // Represents the Guest M2M application
  GUEST = 'guest',
}

export interface M2MTokenPayload {
  token: string;
  // milliseconds from epoch
  expiresAt: number;
  expiresInSeconds: number;
}

export interface Auth0M2mTokenPayload {
  access_token: string;
  expires_in: number;
}

const getM2MAsyncKey = (type: M2mTokenType) => `M2M-token-${type}`;

const persistM2mToken = (type: M2mTokenType, tokenPayload: M2MTokenPayload) =>
  AsyncStorage.setItem(getM2MAsyncKey(type), JSON.stringify(tokenPayload));

export const getAsyncM2mToken = async (
  type: M2mTokenType
): Promise<M2MTokenPayload> => {
  const rawStoreData = await AsyncStorage.getItem(getM2MAsyncKey(type));

  return rawStoreData ? JSON.parse(rawStoreData) : undefined;
};

/**
 * Gets a valid M2m token. Checks localstorage, if not, goes to Auth0.
 * @param type M2M application identifier
 * @param refreshIfExpiresWithinMins refresh token if it is going to expire within {refreshIfExpiresWithinMins} minutes
 */
export const getM2mToken = async (
  type: M2mTokenType,
  refreshIfExpiresWithinMins = 3
) => {
  let tokenPayload = await getAsyncM2mToken(type);

  /**
   * If the token expiry date is within `refreshIfExpiresWithinMins`,
   * we will request a new token from Auth0 & save it to localstorage.
   */
  if (
    !tokenPayload ||
    isDateWithinMinutesOfDate(
      new Date().getTime(),
      tokenPayload.expiresAt,
      refreshIfExpiresWithinMins
    )
  ) {
    const newTokenPayload = await setupM2MToken(type);
    tokenPayload = newTokenPayload;
  }
  return tokenPayload.token;
};

/**
 * Fetches the m2m token for specified type (i.e. guest m2m), saves
 * it in local storage, and returns the token payload.
 */
const setupM2MToken = async (type: M2mTokenType): Promise<M2MTokenPayload> => {
  const tokenPayload = await getM2mTokenFromAuth0(type);
  if (!tokenPayload || (tokenPayload && !tokenPayload.access_token)) {
    throw Error(
      `M2M token of type ${type} could not be set up. Payload: ${tokenPayload}`
    );
  }

  const tokenData: M2MTokenPayload = {
    token: tokenPayload.access_token,
    expiresAt:
      new Date().getTime() + secondsToMilliseconds(tokenPayload.expires_in),
    expiresInSeconds: tokenPayload.expires_in,
  };

  await persistM2mToken(type, tokenData);

  return tokenData;
};

export { setupM2MToken };
