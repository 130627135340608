import React, { FC } from 'react';
import { Box, Text, useDeviceType } from '@nutrien/bonsai-core';
import { useWindowDimensions } from 'react-native';
import { ContentPage, ContentPageButton } from '../ContentPage';
import Link from '../Link';
import Warning from '../SVG/Warning';
import { NUTRIEN_URL } from '../../constants';

type props = {
  onContinuePress: (setContinue: boolean) => void;
};

const ConfirmCancellation: FC<props> = ({ onContinuePress }) => {
  const { isDesktop } = useDeviceType();
  const layout = useWindowDimensions();
  const navigateToNutrienHomePage = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(NUTRIEN_URL, '_self');
  };
  const headerHeight = 48;
  const footerHeight = 200;
  const pageHeight = isDesktop
    ? 'auto'
    : layout.height - headerHeight - footerHeight;

  const contentPageTitle =
    'Are you sure you want to leave? If you do, then all your details will be lost.';
  const closeButton: ContentPageButton = {
    text: 'Yes, exit form',
    onPress: () => {
      navigateToNutrienHomePage();
    },
    type: 'outline',
    testID: 'exit-button',
  };
  const continueButton: ContentPageButton = {
    text: 'No, I want to continue',
    onPress: () => {
      onContinuePress(false);
    },
    type: 'solid',
    testID: 'continue-button',
  };
  const buttonList = [closeButton, continueButton];

  return (
    <Box
      testID="customer-self-registration-ABN-validation-fails"
      width="100%"
      height="100%"
      maxWidth={isDesktop ? 1136 : 'unset'}
      maxHeight={isDesktop ? 699 : 'unset'}
      minHeight={pageHeight}
      justifyContent={isDesktop ? 'center' : 'unset'}
      alignItems="center"
      marginLeft="auto"
      marginRight="auto"
      marginY={8}
    >
      <ContentPage
        title={contentPageTitle}
        icon={<Warning width={isDesktop ? 360 : 260} />}
        iconTestID={'confirmation-testID'}
        contentPageButtons={buttonList}
      >
        <Box testID="confirmation-body">
          <Box marginBottom={3}>
            <Text testID="confirmation-body-text">
              The details that you have entered so far will be lost and you will
              need to start again to register for digital access to your Nutrien
              account.
            </Text>
          </Box>
          <Text>Clicking to exit the form will take you to</Text>
          <Box marginLeft="auto" marginRight="auto">
            <Link
              hasUnderline={true}
              text="www.nutrienagsolutions.com.au"
              onPress={navigateToNutrienHomePage}
              testID="nutrien-link"
              type="twilight"
            />
          </Box>
        </Box>
      </ContentPage>
    </Box>
  );
};

export default ConfirmCancellation;
