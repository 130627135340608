import React, { useState, FC } from 'react';
import { Dimensions } from 'react-native';
import { Box, Card, Icon, Text, useStyles } from '@nutrien/bonsai-core';
import theme from '../../theme/theme';
import { roundToDecimalPlaces } from '../../hooks/useFormatters';
import {
  FarmVisitRecommendations_farm_farm_visit_summary,
  FarmVisitRecommendations,
} from '../../types/generated/FarmVisitRecommendations';
import EmptyState from '../../components/EmptyState';

type ProductList = { [key: string]: string[] };

type props = {
  recommendationList?: FarmVisitRecommendations;
};

const unitOfMeasureLiquidList = ['mL', 'L', 'kL', 'ML'];
const unitOfMeasureSolidList = ['mg', 'g', 'kg', 't'];

export const totalQuantity = (productList: string[], isLiquid: boolean) => {
  const runningTotal = productList.reduce((acc: number, product: string) => {
    // split 12 L into ['12', 'L'];
    const splitProduct = product.split(' ');
    const value = Number(splitProduct[0]);
    const measureUnit = splitProduct[1];
    let tally = 0;

    if (isLiquid) {
      /**
       * 1 mL = 0.001 liters
       * 1 L = 1 liter
       * 1 kL = 1000 liters
       * 1 ML = 1000000 liters
       */
      if (measureUnit === 'mL') tally += value * 0.001;
      if (measureUnit === 'L') tally += value;
      if (measureUnit === 'kL') tally += value * 1000;
      if (measureUnit === 'ML') tally += value * 1000000;

      return (acc += tally);
    }
    // is a solid
    /**
     * 1 mg = 0.000001 kilogram
     * 1 g = 0.001 kilograms
     * 1 kg = 1 kilogram
     * 1 t = 1000 kilograms
     */
    if (measureUnit === 'mg') tally += value * 0.000001;
    if (measureUnit === 'g') tally += value * 0.001;
    if (measureUnit === 'kg') tally += value;
    if (measureUnit === 't') tally += value * 1000;

    return (acc += tally);
  }, 0);

  if (isLiquid) {
    return `${roundToDecimalPlaces(runningTotal, 2)} L`;
  }

  // change kg to t
  if (runningTotal > 1000) {
    return `${roundToDecimalPlaces(runningTotal / 1000, 2)} t`;
  }

  // return weight in kgs
  return `${roundToDecimalPlaces(runningTotal, 2)} kg`;
};

export const tallyTotalQty = (inputsList: string[]) => {
  if (!inputsList) {
    return '';
  }
  // split out the unit of measure type
  const unitOfMeasure = inputsList[0].split(' ')[1];

  // indexOf rather than includes for older browser support.
  const isLiquid = unitOfMeasureLiquidList.indexOf(unitOfMeasure) !== -1;

  // Now we know uom we are working with, lets check the rest match
  const oddUnits = inputsList.filter((item: string) => {
    if (isLiquid) {
      return unitOfMeasureLiquidList.indexOf(item.split(' ')[1]) === -1;
    }
    return unitOfMeasureSolidList.indexOf(item.split(' ')[1]) === -1;
  });

  // If there is a unit type mismatch, we will just return now before trying to tally up the totals
  if (oddUnits.length) {
    return '';
  }

  return totalQuantity(inputsList, isLiquid);
};

const SummaryProductList: FC<props> = ({ recommendationList }) => {
  const { width } = Dimensions.get('window');

  // Use initial screen width in px to determine if the text
  // initially renders in a wrapped state or not
  const disclaimerTextWrapWidth = 410;
  const [isTextWrapped, setIsTextWrapped] = useState(
    width < disclaimerTextWrapWidth
  );

  const styles = useStyles(() => ({
    summaryHeader: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    summaryHeaderTitle: {
      fontWeight: '600',
      fontSize: theme.typography.fontSize.large,
      color: theme.auColors.neutral[800],
      paddingBottom: theme.spacing(1),
    },
    iconContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
    },
    iconContainerMobile: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
    disclaimer: {
      paddingLeft: theme.spacing(1),
    },
    disclaimerMobile: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    productTitle: {
      color: theme.auColors.primary.default,
    },
    qtyText: {
      color: theme.auColors.neutral[600],
      marginRight: theme.spacing(5),
    },
  }));

  // Todo, replace with actual data

  if (
    !recommendationList ||
    recommendationList.farm_farm_visit_summary.length < 1
  ) {
    return <></>;
  }

  // Data types are loose or could be undefined. Protect against undefined errors
  const recommendationData: FarmVisitRecommendations_farm_farm_visit_summary =
    recommendationList.farm_farm_visit_summary[0];

  if (!recommendationData) return <></>;

  // Lets get just the list of the visits

  const farmVisitRecs = recommendationData.farm_visit_summary_reco_bridge;

  const items: ProductList = {};
  // This little monster is used to get a list of every product, and their associated qty
  // We only want each product as a key once.

  farmVisitRecs?.forEach((rec) => {
    const { job_status, deleted_dtm, reco_activity_input } =
      rec.farm_visit_reco_bridge_reco_activity || {};

    const isDiscardedRecommendation = job_status === 'discarded';

    const isDeletedRecommendation = deleted_dtm !== null;

    if (!isDiscardedRecommendation && !isDeletedRecommendation) {
      reco_activity_input?.forEach(
        ({ input_type_desc, total_qty, input_name }) => {
          if (
            input_type_desc !== 'CostActivity' &&
            input_type_desc &&
            total_qty &&
            input_name
          ) {
            items[input_name as keyof typeof items] = [
              ...(items[input_name as keyof typeof items] || []),
              total_qty,
            ];
          }
        }
      );
    }
  });

  const products = Object.keys(items);

  const disclaimerMessage = 'This product list is not a confirmed order.';

  // The pixels in which the disclaimer text wraps inside the card, as opposed to the total screen width px
  // Required to handle user resizing the screen.
  const textBreakpointPx = 378;

  return (
    <Box>
      <Card
        testID="summary-product-list"
        style={styles.summaryHeader}
        onLayout={({ nativeEvent: { layout } }) => {
          setIsTextWrapped(layout.width < textBreakpointPx);
        }}
      >
        <Text
          bodyBold
          ellipsizeMode="tail"
          numberOfLines={1}
          testID="product-list-title"
          style={styles.summaryHeaderTitle}
        >
          Product List
        </Text>
        <Box
          flexDirection="row"
          alignItems="center"
          py={isTextWrapped ? 0 : 2}
          borderLeftWidth={4}
          // ## TODO: Check this with the design
          borderColor={theme.auColors.functional.focused.dark}
        >
          <Icon
            // ## TODO: Check this with the design
            color={theme.auColors.functional.focused.dark}
            name="info"
            size={25}
            containerStyle={[
              styles.iconContainer,
              isTextWrapped && styles.iconContainerMobile,
            ]}
          />
          <Text
            style={[
              styles.disclaimer,
              isTextWrapped && styles.disclaimerMobile,
            ]}
          >
            {disclaimerMessage}
          </Text>
        </Box>
        {products && products.length > 0 ? (
          <Box
            borderTopWidth={2}
            borderTopColor={theme.auColors.neutral[400]}
            mt={2}
            pt={2}
          >
            <Box mb={2}>
              <Text style={styles.productTitle} bodyBold testID="product-0">
                {products[0]}
              </Text>
            </Box>
            <Text>
              <Text style={styles.qtyText}>Total qty</Text>
              <Text>{tallyTotalQty(items[products[0]])}</Text>
            </Text>
          </Box>
        ) : (
          <EmptyState type="no-documents" alert={'No product'} />
        )}
      </Card>
      <>
        {products.map((item, index) => {
          return (
            index > 0 && (
              <Box mb={2} key={index}>
                <Card>
                  <Box padding={2}>
                    <Box mb={2}>
                      <Text
                        style={styles.productTitle}
                        bodyBold
                        testID={`product-${index}`}
                      >
                        {item}
                      </Text>
                    </Box>
                    <Text>
                      <Text style={styles.qtyText}>Total qty</Text>{' '}
                      <Text>
                        {tallyTotalQty(items[item as keyof typeof items])}
                      </Text>
                    </Text>
                  </Box>
                </Card>
              </Box>
            )
          );
        })}
      </>
    </Box>
  );
};

export default SummaryProductList;
