import { CustomerProfileByCustomerId_customer_profile } from 'types/generated/CustomerProfileByCustomerId';
import { RefetchByCustomerId, RefetchByPersonId } from '../../hooks';
import { PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details } from '../../types/generated/PartyGetPersonDetailsWithAccountsById';

export { default } from './AccountInformationPanel';

// eslint-disable-next-line no-shadow
export enum ScreenType {
  create,
  edit,
}

export interface AccountInformationPanelProps {
  customer?:
    | CustomerProfileByCustomerId_customer_profile
    | PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details;
  type: ScreenType;
  customerProfileByCustomerIdLoading: boolean;
  customerProfileByCustomerIdRefetch: RefetchByCustomerId | RefetchByPersonId;
  handleClickUserSummary: () => void;
}

export interface TableData {
  account: string | JSX.Element;
  type?: string | JSX.Element;
  name?: string | JSX.Element;
  balance?: JSX.Element;
  accountId?: string;
}
