import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import {
  FarmRecommendationVariables,
  FarmRecommendation,
} from 'types/generated/FarmRecommendation';

export const RECOMMENDATION_SUMMARY = gql`
  query FarmRecommendation($recoId: uuid!) {
    farm_recommendation_account(
      where: { recommendation_uuid: { _eq: $recoId } }
    ) {
      reco_account_activity {
        agronomist_name
        agronomist_org_name
        comments_desc
        cost_area_rate
        grower_org_name
        operator_org_name
        rec_created_dtm
        rec_due_dtm
        rec_expiry_dtm
        rec_updated_dtm
        recommendation_title
        total_activity_area
        total_application_rate_desc
        total_cost_amount
        total_field_area
        total_volume
        reco_activity_input {
          input_cost_area_rate
          input_name
          input_rate
          input_sequence_number
          input_total_cost_amount
          input_type_desc
          input_unit_cost_rate
          total_qty
        }
        reco_activity_field {
          activity_area_desc
          activity_area_estimated_qty
          activity_crop_name
          activity_crop_stage_desc
          activity_variety_name
          farm_name
          field_area_desc
          field_name
        }
      }
    }
  }
`;

type Refetch = (
  variables?: Partial<FarmRecommendationVariables> | undefined
) => Promise<ApolloQueryResult<FarmRecommendation>>;

const useGetRecommendationQuery = ({
  recoId,
}: {
  recoId: string;
}): [
  FarmRecommendation | undefined,
  boolean,
  ApolloError | undefined,
  Refetch
] => {
  // fallBackIfNotAuthenticated(isAuthenticated);
  const { loading, error, data, refetch } = useQuery<
    FarmRecommendation,
    FarmRecommendationVariables
  >(RECOMMENDATION_SUMMARY, {
    variables: { recoId },
  });

  return [data, loading, error, refetch];
};

export default useGetRecommendationQuery;
