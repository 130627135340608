import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    GetAccountSummaryResponse: {
      keyFields: (item) =>
        item?.AccountNumber
          ? `GetAccountSummaryResponse:${item.AccountNumber}`
          : false,
    },
  },
});
