import React from 'react';
import { Box } from '@nutrien/bonsai-core';
import Disclaimer from '../../components/FarmVisit/Disclaimer';
import SummaryHeader from './SummaryHeader';
import SummaryProductList from './SummaryProductList';
import useGetFarmVisitQuery from '../../hooks/gql/queries/useGetFarmVisitQuery';
import { useRoutes, useSort } from '../../hooks';
import QueryCard from '../../components/QueryCard';
import NotFoundMessage from '../../components/FarmVisit/NoFoundMessage';

const FarmVisitSummary = (): JSX.Element => {
  const { routeParams } = useRoutes();
  const { id: routeID } = Object(routeParams);
  // Get recommendationList
  const [recommendationList, loading, error, refetch] = useGetFarmVisitQuery({
    farmVisitId: routeID || '',
  });

  const { sortFarmVisitsByDueDate } = useSort();
  const { farm_farm_visit_summary } = Object(recommendationList);

  const sortedFarmVisitList = recommendationList
    ? sortFarmVisitsByDueDate(recommendationList)
    : undefined;

  let agronomist,
    visitDate,
    company = '';

  if (
    Array.isArray(farm_farm_visit_summary) &&
    farm_farm_visit_summary.length > 0
  ) {
    agronomist = farm_farm_visit_summary[0]?.agronomist_name || '';
    visitDate = farm_farm_visit_summary[0]?.created_date || '';
    if (
      Array.isArray(farm_farm_visit_summary[0].farm_visit_summary_reco_bridge)
    ) {
      company =
        farm_farm_visit_summary[0]?.farm_visit_summary_reco_bridge[0]
          ?.farm_visit_reco_bridge_reco_activity?.grower_org_name || '';
    }
  }

  // Return if there is an invalid uuid used
  const invalidUuidError = 'Error: invalid input syntax for type uuid:';
  if (error?.toString().includes(invalidUuidError)) {
    return (
      <NotFoundMessage
        alert="Invalid Farm Visit ID"
        message="Please try a different farm visit id"
      />
    );
  }

  // There are no recommendations to display
  if (
    !loading &&
    recommendationList?.farm_farm_visit_summary &&
    recommendationList?.farm_farm_visit_summary?.length < 1
  ) {
    return (
      <NotFoundMessage
        alert="No Farm Visit Found"
        message="Please try a different farm visit"
      />
    );
  }

  return (
    <QueryCard
      loading={loading}
      error={error}
      handleReload={() => refetch()}
      wrapper={Box}
    >
      <Box testID="farm-visit-summary">
        <SummaryHeader
          agronomist={agronomist}
          company={company}
          visitDate={visitDate}
          navigationId={routeID}
        />
        <SummaryProductList recommendationList={sortedFarmVisitList} />
        <Disclaimer />
      </Box>
    </QueryCard>
  );
};

export default FarmVisitSummary;
