import { ApolloError } from '@apollo/client/errors';
import {
  useValidateABNPersonQuery,
  useCreateCustomerSelfRegisterLoginMutation,
} from '../../hooks';
import { ValidateABNPerson } from '../../types/generated/ValidateABNPerson';
import { CreateCustomerSelfRegisterLogin } from '../../types/generated/CreateCustomerSelfRegisterLogin';

export interface CustomerSelfRegistration {
  handleCustomerDetailsSubmit: (email: string, abn: string) => void;
  verifyABNPersonData?: ValidateABNPerson | null;
  validateABNPersonError?: ApolloError;
  validateABNPersonLoading: boolean;
  verifyCustomerDetails: (
    email: string,
    givenName: string,
    familyName: string,
    mobileNumber: string,
    abn: string
  ) => void;
  verifyCustomerDetailsData?: CreateCustomerSelfRegisterLogin | null;
  verifyCustomerDetailsError?: ApolloError;
  verifyCustomerDetailsLoading?: boolean;
}

const useCustomerSelfRegistration = (): CustomerSelfRegistration => {
  const [
    verifyABNPerson,
    {
      data: verifyABNPersonData,
      error: validateABNPersonError,
      loading: validateABNPersonLoading,
    },
  ] = useValidateABNPersonQuery();
  const [
    createCustomerSelfRegisterLogin,
    {
      data: verifyCustomerDetailsData,
      error: verifyCustomerDetailsError,
      loading: verifyCustomerDetailsLoading,
    },
  ] = useCreateCustomerSelfRegisterLoginMutation();

  const handleCustomerDetailsSubmit = (email: string, abn: string) => {
    verifyABNPerson({
      variables: { email, abn },
    });
  };

  const verifyCustomerDetails = async (
    email: string,
    givenName: string,
    familyName: string,
    mobilePhone: string,
    abn: string
  ) => {
    await createCustomerSelfRegisterLogin({
      variables: {
        email,
        givenName,
        familyName,
        mobilePhone,
        abn,
      },
    });
  };

  return {
    handleCustomerDetailsSubmit,
    verifyABNPersonData,
    validateABNPersonError,
    validateABNPersonLoading,
    verifyCustomerDetails,
    verifyCustomerDetailsData,
    verifyCustomerDetailsError,
    verifyCustomerDetailsLoading,
  };
};

export default useCustomerSelfRegistration;
