import { useEffect, useState } from 'react';
import { trackEvent } from '../../constants/segment';
import { useSegment } from '../../hooks';

type onNavigatePage = (segmentProperties?: Record<string, unknown>) => void;
interface UsePagination {
  pageSize: number;
  pageIndex: number;
  setPageIndex: (index: number) => void;
  numberOfPages: number;
  onNextPage: onNavigatePage;
  onPrevPage: onNavigatePage;
  onSetPageSize: (
    size: number,
    segmentProperties?: Record<string, unknown>
  ) => void;
  filterItems: (index: number) => boolean;
}

const usePagination = <T>(items: T[]): UsePagination => {
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(
    Math.ceil(items.length / pageSize)
  );

  const segment = useSegment();

  const onNextPage = (segmentProperties?: Record<string, unknown>) => {
    setPageIndex(pageIndex + 1);
    segment?.track(trackEvent.PAGINATION_CLICKED, {
      display_page_direction: 'Next',
      ...segmentProperties,
    });
  };

  const onPrevPage = (segmentProperties?: Record<string, unknown>) => {
    setPageIndex(pageIndex - 1);
    segment?.track(trackEvent.PAGINATION_CLICKED, {
      display_page_direction: 'Previous',
      ...segmentProperties,
    });
  };

  const onSetPageSize = (
    size: number,
    segmentProperties?: Record<string, unknown>
  ) => {
    segment?.track(trackEvent.ROW_DISPLAY_SET, {
      ...segmentProperties,
      rows_to_display: size,
    });

    setPageSize(size);
    setPageIndex(0);
  };

  const filterItems = (index: number) => {
    return (
      index + 1 <= pageSize * (pageIndex + 1) &&
      index + 1 > pageSize * pageIndex
    );
  };

  useEffect(() => {
    setNumberOfPages(Math.ceil(items.length / pageSize));
  }, [pageSize, items]);

  useEffect(() => {
    // reset page index if items are updated (i.e sort)
    setPageIndex(0);
  }, [items]);

  return {
    pageSize,
    pageIndex,
    setPageIndex,
    numberOfPages,
    onNextPage,
    onPrevPage,
    onSetPageSize,
    filterItems,
  };
};

export default usePagination;
