import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import {
  GetCustomerLogin,
  GetCustomerLoginVariables,
} from 'types/generated/GetCustomerLogin';
import { GetCustomerLatestVerification } from 'types/generated/GetCustomerLatestVerification';
import { GetJob, GetJobVariables } from 'types/generated/GetJob';

export const GET_CUSTOMER_LOGIN = gql`
  query GetCustomerLogin($customer_id: uuid!) {
    getCustomerLogin(payload: { customer_id: $customer_id }) {
      email
      user_metadata
      last_login
      logins_count
      email_verified
      created_at
      updated_at
    }
  }
`;

export const GET_CUSTOMER_LATEST_VERIFICATION = gql`
  query GetCustomerLatestVerification($customer_id: uuid!) {
    getCustomerLatestVerification(payload: { customer_id: $customer_id }) {
      user_name
      user_id
      type
      date
      verificationLinkExpiration
      verificationLinkIsExpired
    }
  }
`;

export const GET_JOB = gql`
  query GetJob($job_id: String!) {
    getJob(payload: { job_id: $job_id }) {
      created_at
      id
      status
      type
    }
  }
`;

export type RefetchGetCustomerLogin = () => Promise<
  ApolloQueryResult<GetCustomerLogin>
>;
export type RefetchGetCustomerLatestVerification = () => Promise<
  ApolloQueryResult<GetCustomerLatestVerification>
>;
export type RefetchGetJob = () => Promise<ApolloQueryResult<GetJob>>;

const useCustomerLoginQuery = ({
  customer_id,
  skip,
}: {
  customer_id: string;
  skip?: boolean;
}): [
  GetCustomerLogin | undefined,
  boolean,
  ApolloError | undefined,
  RefetchGetCustomerLogin
] => {
  const { loading, error, data, refetch } = useQuery<
    GetCustomerLogin,
    GetCustomerLoginVariables
  >(GET_CUSTOMER_LOGIN, {
    skip,
    variables: { customer_id },
  });

  return [data, loading, error, refetch];
};

const useCustomerLatestVerificationQuery = ({
  customer_id,
  skip,
}: {
  customer_id: string;
  skip?: boolean;
}): [
  GetCustomerLatestVerification | undefined,
  boolean,
  ApolloError | undefined,
  RefetchGetCustomerLatestVerification
] => {
  const { loading, error, data, refetch } = useQuery<
    GetCustomerLatestVerification,
    GetCustomerLoginVariables
  >(GET_CUSTOMER_LATEST_VERIFICATION, {
    skip,
    variables: { customer_id },
  });

  return [data, loading, error, refetch];
};

const useGetJobQuery = ({
  job_id,
  skip,
}: {
  job_id: string;
  skip?: boolean;
}): [GetJob | undefined, boolean, ApolloError | undefined, RefetchGetJob] => {
  const { loading, error, data, refetch } = useQuery<GetJob, GetJobVariables>(
    GET_JOB,
    {
      skip,
      variables: { job_id },
    }
  );

  return [data, loading, error, refetch];
};

export {
  useCustomerLoginQuery,
  useCustomerLatestVerificationQuery,
  useGetJobQuery,
};
