import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { Box, Card, useDeviceType, useTheme, Text } from '@nutrien/bonsai-core';
import Header from '../../components/Header';
import { FullScreenBackgroundImage } from '../../components/FullScreenBackgroundImage';
import FormStepper from '../../components/CustomerSelfRegistration/FormStepper';
import AbnLookupForm from '../../components/CustomerSelfRegistration/AbnLookupForm';
import { getM2mToken, M2mTokenType } from '../../utils/auth/guestM2m';
import { routeConfig } from '../../routes/shared';
import Logo from '../../components/SVG/Logo';
import CustomerDetailsForm from '../../components/CustomerSelfRegistration/CustomerDetailsForm';
import Footer from '../../components/CustomerSelfRegistration/Footer';
import ApolloGuestClientProvider from '../../components/ApolloGuestClientProvider';
import Banner from '../../components/Banner';
import { UserProvider } from '../../context/UserContext';
import ABNValidationFails from '../../components/CustomerSelfRegistration/ABNValidationFails';
import {
  CUSTOMER_SELF_REGISTRATION_STEPS,
  SUPPORT_PHONE,
} from '../../constants';
import ConfirmCancellation from '../../components/CustomerSelfRegistration/ConfirmCancellation';
import ConfirmCustomerDetailsForm from '../../components/CustomerSelfRegistration/ConfirmCustomerDetailsForm';
import EmailNotification from '../../components/CustomerSelfRegistration/EmailNotification';
import withGuestRouteCheck from '../../hocs/withGuestRouteCheck';
import { useSegment } from '../../hooks';
import { trackEvent } from '../../constants/segment';

/**
 * The CustomerSelfRegistration utilises a slimmed down apollo client that
 * uses M2M Auth0 tokens + removes caching.
 *
 * This component fetches a guest M2M token from Auth0 and passes this to Hasura.
 * The token lasts for 10 minutes, however, we do have token refreshing if it is about
 * to expire so that the UX does not cause customers to drop off.
 */
const CustomerSelfRegistration = () => {
  const theme = useTheme();
  const { isHandset } = useDeviceType();
  const layout = useWindowDimensions();
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [showABNValidationFailed, setShowABNValidationFailed] =
    useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(
    CUSTOMER_SELF_REGISTRATION_STEPS.ABN_LOOKUP_STEP
  );
  const [showConfirmCancellation, setShowConfirmCancellation] =
    useState<boolean>(false);
  const [showEmailNotification, setShowEmailNotification] =
    useState<boolean>(false);
  const segment = useSegment();
  const PAGE_NAME = 'CustomerSelfRegistration';
  const PAGE_COMPONENTS = [
    'abn_lookup',
    'customer_details',
    'confirm_customer_details',
    'display_email',
  ];
  const PAGE_LOAD = [
    trackEvent.REGISTRATION_STEP1_LOAD,
    trackEvent.REGISTRATION_STEP2_LOAD,
    trackEvent.REGISTRATION_STEP3_LOAD,
    trackEvent.REGISTRATION_STEP4_LOAD,
  ];
  const segmentTrack = (index: number) => {
    if (segment) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      segment.page(`${PAGE_LOAD.at(pageIndex - 1)}`, {
        name: PAGE_NAME,
        pageName: `${PAGE_NAME}_${PAGE_COMPONENTS.at(index - 1)}`,
        path: '/register',
        referrer: document.referrer,
        search: location.search,
        title: document.title,
        url: location.href,
      });
    }
  };

  useEffect(() => {
    segmentTrack(pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    async function setupGuestM2mAccess() {
      /** @todo: Set Handle Error and show banner */
      /**
       * Every time we come to this page, we check for a token that has atleast 8 minutes of
       * time left, if not, we fetch a new one from auth0.
       */
      await getM2mToken(M2mTokenType.GUEST, 8);
    }
    void setupGuestM2mAccess();
  }, []);

  // 200 is the size of the footer on mobile, and 168 is the desktop height
  const footerHeight = isHandset ? 200 : 168;
  // 48 is the size of the header on mobile, and 73 is the desktop height
  const headerHeight = isHandset ? 48 : 73;
  const pageHeight = layout.height - headerHeight - footerHeight;
  /** Minimum screen size to show the FullScreenBackgroundImage
   *  Show on only Bonsai breakpoints medium, large and xLarge
   */
  const minScreenSize: number = theme.breakpoints.medium.min
    ? theme.breakpoints.medium.min
    : 720;

  const styles = {
    errorBannerText: {
      color: theme.auColors.functional.error.dark,
    },
  };
  const renderDialog = () => {
    if (showABNValidationFailed) {
      return <ABNValidationFails />;
    }
    if (showConfirmCancellation) {
      return (
        <ConfirmCancellation onContinuePress={setShowConfirmCancellation} />
      );
    }
    if (showEmailNotification) {
      return <EmailNotification />;
    }
  };

  return (
    <Box testID="customer-self-registration-screen">
      <Header isSimpleHeader />
      <Box backgroundColor={theme.auColors.neutral[300]} minHeight={pageHeight}>
        <FullScreenBackgroundImage
          imageName="CustomerSelfRegistration"
          testID="customer-self-registration-screen__FullScreenBackgroundImage"
          minScreenSize={minScreenSize}
        >
          <ApolloGuestClientProvider
            guestM2mClientOptions={{
              m2mTokenType: M2mTokenType.GUEST,
              /**
               * If for WHATEVER reason the users token gets out of sync, we route back here for new token
               * generation. Sidenote: The tokens are refreshed so this will be very unlikely to happen.
               * */
              ifUnAuthorizedRouteTo:
                routeConfig.CustomerSelfRegistration.getNavigateProps(),
            }}
          >
            <UserProvider>
              {(showABNValidationFailed && renderDialog()) ||
                (showConfirmCancellation && renderDialog()) ||
                (showEmailNotification && renderDialog())}
              <Box
                width="100%"
                height="100%"
                maxWidth="990"
                padding={2}
                justifyContent="center"
                alignItems="center"
                marginLeft="auto"
                marginRight="auto"
                display={
                  showABNValidationFailed ||
                  showConfirmCancellation ||
                  showEmailNotification
                    ? 'none'
                    : 'flex'
                }
              >
                <Card
                  width="400"
                  maxWidth={layout.width - theme.spacing(2)}
                  borderTopColor={theme.auColors.brand.leaf2[200]}
                  borderTopWidth="8"
                  marginY={4}
                >
                  <Box
                    padding={4}
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Logo width={120} id="bodyLogo" />

                    <Box width="100%" paddingTop={2}>
                      <Banner
                        location="section"
                        type="error"
                        visible={showErrorBanner}
                        testID="self-reg-error"
                        title="Sorry, there is a problem with the service"
                      >
                        <Box>
                          <Text style={styles.errorBannerText}>
                            Try again later
                          </Text>
                        </Box>
                        <Box paddingTop={1}>
                          <Text style={styles.errorBannerText}>
                            Contact the Customer Helpline or phone{' '}
                            {SUPPORT_PHONE} if you would like help to finish
                            registering for digital access to your Nutrien
                            account.
                          </Text>
                        </Box>
                      </Banner>
                    </Box>
                    <Box
                      my={3}
                      display={showConfirmCancellation ? 'none' : 'flex'}
                    >
                      <FormStepper index={pageIndex} maxIndex={4} />
                    </Box>
                    <Box display={showConfirmCancellation ? 'none' : 'flex'}>
                      {pageIndex ===
                        CUSTOMER_SELF_REGISTRATION_STEPS.ABN_LOOKUP_STEP && (
                        <AbnLookupForm
                          onCancel={setShowConfirmCancellation}
                          onNavigateToStep={setPageIndex}
                          onErrors={setShowErrorBanner}
                          onABNValidationErrors={setShowABNValidationFailed}
                        />
                      )}
                      {pageIndex ===
                        CUSTOMER_SELF_REGISTRATION_STEPS.CUSTOMER_DETAILS_STEP && (
                        <CustomerDetailsForm
                          onCancel={setShowConfirmCancellation}
                          onNavigateToStep={setPageIndex}
                          onErrors={setShowErrorBanner}
                          onABNValidationErrors={setShowABNValidationFailed}
                        />
                      )}
                      {pageIndex ===
                        CUSTOMER_SELF_REGISTRATION_STEPS.CONFIRM_CUSTOMER_DETAILS_STEP && (
                        <ConfirmCustomerDetailsForm
                          onCancel={setShowConfirmCancellation}
                          onNavigateToStep={setPageIndex}
                          onErrors={setShowErrorBanner}
                          onShowEmailNotification={setShowEmailNotification}
                        />
                      )}
                    </Box>
                  </Box>
                </Card>
              </Box>
            </UserProvider>
          </ApolloGuestClientProvider>
        </FullScreenBackgroundImage>
      </Box>
      <Footer />
    </Box>
  );
};

export { CustomerSelfRegistration as CustomerSelfRegistrationPure };
export default withGuestRouteCheck(
  CustomerSelfRegistration,
  routeConfig.Home.path
);
