import { Box } from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, ReactNode, useEffect } from 'react';
import { TRANSACTIONS_FILTER } from '../../constants';
import { useRoutes } from '../../hooks';
import { getAccountType } from '../../hooks/useTransactions/transaction.helper';
import { selectedAccountMutations } from '../../reactiveVariables';
import useAccount from '../../screens/Account/useAccount';
import { AccountDetailPanels } from '../AccountDetailPanels/AccountDetailPanels';
import QueryCard from '../QueryCard';
import { AccountOverviewBody } from './AccountOverviewBody';
import { AccountOverviewHeader } from './AccountOverviewHeader';
import useAccountOverview, { AccountType } from './useAccountOverview';

interface AccountOverviewPropsProps {
  account_id: string;
  hideDetailsButton?: boolean;
  customerId?: string;
  isAccountDetailsView?: boolean;
}

// TODO: When ng-2585-wdio-account-summary feature flag is ready, this component
// is safe to delete as it is replaced with `NewAccountOverview`. The logic of
// transactions filter tab need to move to new Account Overview component in
// account details page.
const AccountOverview: FC<AccountOverviewPropsProps> = ({
  account_id,
  hideDetailsButton = false,
  customerId = null,
  isAccountDetailsView,
}) => {
  const { editSelectedAccount, resetSelectedAccount } =
    selectedAccountMutations;

  const {
    loading,
    error,
    handleReload,
    getAccountTypeDescription,
    accountWithFinance,
  } = useAccountOverview({
    account_id,
  });

  const flags = useFlags();
  const { routeParams } = useRoutes();

  const accountDescription =
    accountWithFinance?.accountData &&
    getAccountTypeDescription(accountWithFinance.accountData);

  const isFilterAvailable =
    Object.values(TRANSACTIONS_FILTER).some(
      (filter) => filter === routeParams?.transactionsFilter
    ) && hideDetailsButton;

  const { accountType: accountTypeFromRoute } = useAccount();
  const accountType =
    accountTypeFromRoute ?? getAccountType(accountWithFinance?.accountData);
  const newAccountDetails =
    accountType &&
    flags['ng-2585-wdio-account-summary'] &&
    flags['ng-2585-wdio-account-summary'].includes(accountType.toLowerCase());

  useEffect(() => {
    if (isAccountDetailsView) {
      editSelectedAccount({
        account_id,
        account_type: accountWithFinance?.accountData
          ?.account_type as AccountType,
        account_description: accountDescription,
        account_name: accountWithFinance?.accountData?.name1,
      });
    } else {
      resetSelectedAccount();
    }
  }, [
    accountDescription,
    accountWithFinance?.accountData?.account_type,
    accountWithFinance?.accountData?.name1,
    account_id,
    editSelectedAccount,
    isAccountDetailsView,
    resetSelectedAccount,
  ]);

  const NewAccountDetailsWrapper = ({ children }: { children?: ReactNode }) => (
    <Box>{children}</Box>
  );

  return (
    <QueryCard
      error={error}
      loading={loading}
      handleReload={handleReload}
      wrapper={newAccountDetails ? NewAccountDetailsWrapper : undefined}
    >
      <>
        {newAccountDetails && hideDetailsButton ? (
          <AccountDetailPanels
            accountId={account_id}
            accountType={accountWithFinance?.accountData.account_type}
            tileType={accountWithFinance?.accountView.tileType}
            isFilterAvailable={isFilterAvailable}
            customerId={customerId}
          />
        ) : accountWithFinance && accountWithFinance.accountData ? (
          <Box
            p={2}
            key={account_id}
            testID={`account-${accountWithFinance.accountData?.account_type}`}
          >
            <AccountOverviewHeader
              description={accountDescription}
              accountId={
                accountWithFinance.accountData.account_id_trimmed || ''
              }
            />
            <AccountOverviewBody
              account_description={accountDescription}
              account_type={
                accountWithFinance.accountData.account_type as AccountType
              }
              financials={accountWithFinance.financeData}
              statement={accountWithFinance.statementData}
              summary={accountWithFinance.accountSummary}
              account_name={accountWithFinance.accountData.name1}
              hideDetailsButton={hideDetailsButton}
              account_id={account_id}
              customerId={customerId}
            />
          </Box>
        ) : null}
      </>
    </QueryCard>
  );
};

export default AccountOverview;
