import React from 'react';
import { ViewStyle } from 'react-native';
import { Text, TextProps, Box, Icon, useTheme } from '@nutrien/bonsai-core';

interface IconTextProps extends Omit<TextProps, 'children'> {
  iconName: string;
  text: string;
  boldText?: boolean;
  testID: string;
  isDesktop: boolean;
}

const IconText = ({
  iconName,
  text,
  testID,
  boldText,
  isDesktop,
  ...props
}: IconTextProps): JSX.Element | null => {
  const theme = useTheme();
  const textStyle = {
    color: theme.auColors.neutral[800],
    marginLeft: theme.spacing(2),
    fontWeight: boldText ? 'bold' : 'normal',
    fontSize: boldText
      ? theme.typography.fontSize.large
      : theme.typography.fontSize.medium,
  };

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      mr={isDesktop ? 11 : 0}
      testID={testID}
      mt={2}
      maxWidth={450}
    >
      <Icon name={iconName} color={theme.auColors.neutral[600]} />
      <Text {...props} style={textStyle as ViewStyle}>
        {text}
      </Text>
    </Box>
  );
};

export default IconText;
