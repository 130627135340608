import { Box, NamedStyles, Text, useStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary, useFormatters } from '../../../hooks';
import useDrawer from '../../../hooks/useDrawer';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from 'types/generated/CustomerAccountsSummarySplitUp';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import { Button } from '../../Button';
import SummaryCard from '../../SummaryCard';
import { DrawerLink } from '../DrawerLink';
import PanelDrawer from '../PanelDrawer/PanelDrawer';

export interface LivestockNextDuePanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp?: AccountSummarySplitUpData | null;
  sharedStyles?: NamedStyles<Record<string, undefined>>;
}

const LivestockNextDuePanel: FC<LivestockNextDuePanelProps> = ({
  summary,
  summarySplitUp,
  sharedStyles,
}) => {
  const { showMoreLinks, nextDue } = useAccountSummary(summary, true);
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(sharedStyles ?? {});
  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      <SummaryCard
        heading="Livestock next due invoices"
        summaryRow={[
          {
            label: (
              <Box style={styles.label}>
                <Text style={styles.labelText}>Next due invoices</Text>
              </Box>
            ),
            content: renderNoneIfZero(nextDue.amount),
            testID: 'next-due-column',
          },
        ]}
        footer={
          showMoreLinks ? (
            <Box
              flexDirection={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <DrawerLink
                enabled={showMoreLinks}
                drawerInfo={{
                  name: 'Next due invoices',
                  data: { amount: nextDue.amount, summarySplitUp },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: 'Livestock',
                }}
              />
              <Button
                containerStyle={{
                  marginLeft: 32,
                }}
                disabled={true}
                title="Pay now"
              />
            </Box>
          ) : undefined
        }
        testID="account-details-livestock-next-due-panel"
      />
      <PanelDrawer
        onClose={(closeMethod: string) =>
          onDrawerClose(closeMethod, {
            tab_view: 'Livestock',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};

export default LivestockNextDuePanel;
