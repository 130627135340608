import { Box, NamedStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary } from '../../../hooks';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from 'types/generated/CustomerAccountsSummarySplitUp';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import LivestockOverduePanel from './LivestockOverduePanel';
import LivestockNextDuePendingProceedsPanel from './LivestockNextDuePendingProceedsPanel';
import LivestockPendingProceedsPanel from './LivestockPendingProceedsPanel';
import LivestockNextDuePanel from './LivestockNextDuePanel';

export interface LivestockPanelProps {
  summary?: AccountSummaryData | null;
  sharedStyles?: NamedStyles<Record<string, undefined>>;
  summarySplitUp?: AccountSummarySplitUpData | null;
}

const LivestockPanel: FC<LivestockPanelProps> = ({
  summary,
  sharedStyles,
  summarySplitUp,
}) => {
  const { overdue: overdueLivestock, proceedsPending } = useAccountSummary(
    summary,
    true
  );
  const { overdue: overdueMerchandise } = useAccountSummary(summary, false);

  if (overdueLivestock.amount) {
    return (
      <Box testID="livestock-panel">
        <Box mb={3}>
          <LivestockOverduePanel
            summary={summary}
            summarySplitUp={summarySplitUp}
            sharedStyles={sharedStyles}
          />
        </Box>
        <LivestockNextDuePendingProceedsPanel
          summary={summary}
          summarySplitUp={summarySplitUp}
          sharedStyles={sharedStyles}
        />
      </Box>
    );
  }

  if (overdueMerchandise.amount && proceedsPending && proceedsPending < 0) {
    return (
      <Box testID="livestock-panel">
        <Box mb={3}>
          <LivestockPendingProceedsPanel
            summary={summary}
            summarySplitUp={summarySplitUp}
            sharedStyles={sharedStyles}
          />
        </Box>
        <LivestockNextDuePanel
          summary={summary}
          sharedStyles={sharedStyles}
          summarySplitUp={summarySplitUp}
        />
      </Box>
    );
  }

  return (
    <Box testID="livestock-panel">
      <Box mb={3}>
        <LivestockNextDuePanel
          summary={summary}
          sharedStyles={sharedStyles}
          summarySplitUp={summarySplitUp}
        />
      </Box>
      <LivestockPendingProceedsPanel
        summary={summary}
        summarySplitUp={summarySplitUp}
        sharedStyles={sharedStyles}
      />
    </Box>
  );
};

export default LivestockPanel;
