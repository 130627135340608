import * as Yup from 'yup';

export const MOBILE_REGEX = /^(04)\d{8}$/;
export const EMAIL_REGEX =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Self Registration: Company Details Form
export const companyDetailsSchema = Yup.object().shape({
  companyName: Yup.string().required('Enter a company name'),
  abn: Yup.string().required(
    'Enter an ABN with 11 digits, like 73 008 743 218'
  ),
});

// Self Registration: Customer Details Form
export const customerDetailsSchema = Yup.object().shape({
  givenName: Yup.string().required('Enter your given name'),
  familyName: Yup.string().required('Enter your family name'),
  email: Yup.string()
    .email('Enter an email address, like name@example.com')
    .required('Enter an email address, like name@example.com'),
  mobileNumber: Yup.string()
    .required('Enter an Australian mobile number')
    .matches(MOBILE_REGEX, 'Enter an Australian mobile number'),
});
