import { ApolloError } from '@apollo/client';
import { useState } from 'react';
import { AccountType } from '../../components/AccountOverview/useAccountOverview';
import { trackEvent } from '../../constants/segment';
import {
  useCustomerAccountQuery,
  useCustomerAccountsByNominatedAccountIdQuery,
  useCustomerAccountsQuery,
  useRoutes,
  useSegment,
} from '../../hooks';
import useCustomerProfileByCustomerId from '../../hooks/useCustomerProfileByCustomerId';
import { getAccountType } from '../../hooks/useTransactions/transaction.helper';
import {
  CustomerAccounts,
  CustomerAccounts_sords_customer_account,
} from '../../types/generated/CustomerAccounts';
import { CustomerAccountsByNominatedAccountId } from '../../types/generated/CustomerAccountsByNominatedAccountId';
import { CustomerId } from './useAccount';
import useNavigateToAccount from './useNavigateToAccount';

export interface AccountOption {
  value: string;
  type: string;
  label: string;
}

export const AccountTypeToDisplayNameMap: Partial<Record<AccountType, string>> =
  {
    trading: 'Merchandise',
  };
export interface UseAccountOptions {
  accountOptions: AccountOption[];
  accountOptionsLoading: boolean;
  accountOptionsError: ApolloError | undefined;
  handleSelectOption: (id: string) => void;
  nominatedAccountId: string | null;
}

const formatAccountOption = (
  account: CustomerAccounts_sords_customer_account
) => {
  const type = account.account_type;
  const displayName =
    type && AccountTypeToDisplayNameMap[type.toLowerCase() as AccountType];

  return {
    value: `${account.account_id}`,
    type: `${account.account_type}`,
    label: `${displayName || type} account #${account.account_id_trimmed}`,
  };
};

const getAccountTypeById = (
  accounts: CustomerAccounts | undefined,
  id: string
) => {
  if (!accounts || !id) {
    return '';
  }
  const [account] = accounts.sords_customer_account.filter(
    ({ account_id }) => account_id === id
  );

  return getAccountType(account) || '';
};

const useAccountOptions = (customerId: CustomerId): UseAccountOptions => {
  const [accountOptions, setAccountOptions] = useState<AccountOption[]>([]);
  const { routeParams } = useRoutes();

  const segment = useSegment();

  const selectedAccountId = routeParams.id || '';

  const populateOptions = (
    data: CustomerAccountsByNominatedAccountId | undefined
  ) => {
    const options = data?.sords_customer_account.map((customerAccount) =>
      formatAccountOption(customerAccount)
    );

    setAccountOptions(options || []);
  };

  const { navigateToAccount } = useNavigateToAccount();

  const trackAccountSwitch = (
    fromAccountId: string,
    toAccountId: string,
    accounts: CustomerAccounts | undefined
  ) => {
    segment?.track(trackEvent.SWITCH_ACCOUNT, {
      account_id_from: fromAccountId,
      account_type_from: getAccountTypeById(accounts, fromAccountId),
      account_id_to: toAccountId,
      account_type_to: getAccountTypeById(accounts, toAccountId),
    });
  };

  if (!customerId) {
    const [selectedAccountData, selectedAccountLoading, selectedAccountError] =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCustomerAccountQuery({
        account_id: selectedAccountId,
        skip: !selectedAccountId,
      });

    const nominatedAccountId =
      (selectedAccountData &&
        selectedAccountData.sords_customer_account[0]?.nominated_account_id) ||
      '';

    const {
      data: customerAccountsData,
      loading: accountsByNominatedAccountIdLoading,
      error: accountsByNominatedAccountIdError,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useCustomerAccountsByNominatedAccountIdQuery({
      nominated_account_id: nominatedAccountId,
      skip: !selectedAccountData,
      onCompleted: populateOptions,
    });

    const accountOptionsLoading =
      accountsByNominatedAccountIdLoading || selectedAccountLoading;

    const accountOptionsError =
      accountsByNominatedAccountIdError || selectedAccountError;

    const handleSelectOption = (id: string) => {
      if (!id) {
        return;
      }
      const type = encodeURIComponent(
        getAccountTypeById(customerAccountsData, id)
      );

      // track account switch
      trackAccountSwitch(routeParams.id || '', id, customerAccountsData);

      navigateToAccount(id, type);
    };

    return {
      accountOptions,
      accountOptionsLoading,
      accountOptionsError,
      handleSelectOption,
      nominatedAccountId,
    };
  } else {
    const {
      customerAccounts,
      customerProfileByCustomerIdLoading,
      customerProfileByCustomerIdError,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useCustomerProfileByCustomerId(customerId as string);

    const [
      customerAccountsData,
      accountsByAccountIdsLoading,
      accountsByAccountIdsError,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    ] = useCustomerAccountsQuery({
      account_ids: customerAccounts.length > 0 ? customerAccounts : [],
      skip: customerAccounts.length === 0,
      onCompleted: populateOptions,
    });

    const accountOptionsLoading =
      customerProfileByCustomerIdLoading || accountsByAccountIdsLoading;

    const accountOptionsError =
      customerProfileByCustomerIdError || accountsByAccountIdsError;

    const handleSelectOption = (id: string) => {
      if (!id) {
        return;
      }

      const type = encodeURIComponent(
        getAccountTypeById(customerAccountsData, id)
      );

      // track account switch
      trackAccountSwitch(routeParams.id || '', id, customerAccountsData);

      navigateToAccount(id, type, customerId as string);
    };

    return {
      accountOptions,
      accountOptionsLoading,
      accountOptionsError,
      handleSelectOption,
      nominatedAccountId: null,
    };
  }
};

export default useAccountOptions;
