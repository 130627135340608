import { useNavigate, generatePath } from 'react-router-dom';
import queryString from 'query-string';
import { NavigationProps } from './shared';
import { filterOutNullOrUndefined } from '../../utils/objectHelpers';

type Props = Omit<NavigationProps, 'name'>;

const useNavigation = (): {
  navigate: (route: Props) => void;
  goBack: () => void;
} => {
  const navigateTo = useNavigate();

  /**
   * Given a path (i.e /farm/:id/recommendations) and optionally a params
   * or qs object, navigate to the path with params interpolated
   */
  const navigate = ({ path, params, qs: inQs = null }: Props) => {
    /** If params were passed, map them to path. */
    const route = params ? generatePath(path, params) : path;

    /** Filter out any null or undefined 'qs' values and then stringify the object. */
    const qs = inQs ? filterOutNullOrUndefined(inQs) : undefined;

    /** Generate a valid query string if qs values were passed */
    const stringifyQs = qs ? `?${queryString.stringify(qs)}` : '';

    /** Append the query string (or nothing if one doesn't exist) */
    navigateTo(route.toLowerCase() + stringifyQs);
  };

  const goBack = () => navigateTo(-1);

  return { navigate, goBack };
};

export default useNavigation;
