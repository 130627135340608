import { useFlags } from 'launchdarkly-react-client-sdk';
import useFormatters from '../../hooks/useFormatters';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../types/generated/CustomerAccountsSummary';
import {
  getCreditsExpensesLastDay,
  getFormattedMonth,
  getNextInvoiceDueDate,
  isBetween26thAndEndOfMonth,
} from '../../utils/objectHelpers/date.helper';
import { filterNullable } from '../useTransactions/transaction.helper';

const useAccountSummary = (
  summary?: AccountSummaryData | null,
  isLivestock?: boolean
) => {
  const today = new Date();
  const { formatDate } = useFormatters();
  const overdueAmount = isLivestock
    ? summary?.LivestockOverDue
    : summary?.MerchandiseOverDue;
  const nextDueAmount = isLivestock
    ? summary?.LivestockCurrentDue
    : summary?.MerchandiseCurrentDue;
  const nextDueToBeFinalised: boolean = isBetween26thAndEndOfMonth(today);
  const { date: nextDueInvoices, month: nextDueInvoiceMonth } =
    getNextInvoiceDueDate(today);
  const nextDueToBeFinalisedMonth = getFormattedMonth(today);

  const flags = useFlags();

  const creditsExpensesLastDay = formatDate(
    getCreditsExpensesLastDay(today).toISOString(),
    'd MMM yyyy'
  );

  const currentBalanceFields = [
    summary?.ExtendedTermAmount,
    summary?.MerchandiseOverDue,
    summary?.MerchandiseCurrentDue,
    summary?.UnallocatedPayments,
  ];

  const currentBalance = currentBalanceFields
    .filter(filterNullable)
    .reduce((amount, prev) => amount + prev, 0);

  const interestBearingBalance = isLivestock
    ? summary?.LivestockInterestBearingCurrent
    : typeof isLivestock !== 'undefined'
    ? summary?.MerchInterestBearingCurrent
    : (summary?.LivestockInterestBearingCurrent ?? 0) +
      (summary?.MerchInterestBearingCurrent ?? 0);

  const nonInterestBearingBalance = isLivestock
    ? summary?.LivestockNonInterestBearingCurrent
    : typeof isLivestock !== 'undefined'
    ? summary?.MerchNonInterestBearingCurrent
    : (summary?.LivestockNonInterestBearingCurrent ?? 0) +
      (summary?.MerchNonInterestBearingCurrent ?? 0);
  const proceedsPending = summary?.ProceedsPending;

  return {
    overdue: {
      amount: overdueAmount,
    },
    nextDue: {
      amount: nextDueAmount,
      toBeFinalised: nextDueToBeFinalised,
      toBeFinalisedMonth: nextDueToBeFinalisedMonth,
      invoiceDate: nextDueInvoices,
      invoiceMonth: nextDueInvoiceMonth,
      creditsExpensesLastDay,
    },
    currentBalance,
    proceedsPending,
    finance: {
      interestBearingBalance,
      nonInterestBearingBalance,
    },
    showMoreLinks: flags['ng-3601-wdio-show-more-links'],
  };
};

export default useAccountSummary;
