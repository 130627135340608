import React from 'react';
import { Box, Text } from '@nutrien/bonsai-core';

const BulletText = ({ text }: { text: string }) => {
  const styles = {
    bulletText: {
      paddingLeft: 4,
    },
  };

  return (
    <Box flexDirection="row" pl={1}>
      <Box width={10}>
        <Text testID="bullet-dot">{'\u2022'}</Text>
      </Box>
      <Text style={styles.bulletText} testID="bullet-text-content">
        {text}
      </Text>
    </Box>
  );
};

export default BulletText;
