import React from 'react';
import { Box, Dialog, Text } from '@nutrien/bonsai-core';
import { Button } from '../Button';
import BulletText from '../BulletText';

const DelinkConfirmationModal = ({
  onConfirmDelinkAccount,
  onCloseDelinkModal,
  selectedAccountId,
}: {
  onConfirmDelinkAccount: () => void;
  onCloseDelinkModal: () => void;
  selectedAccountId: string;
}) => {
  return (
    <Dialog visible={!!selectedAccountId} onDismiss={onCloseDelinkModal}>
      <Dialog.Title title="Remove Account" />
      <Dialog.Content>
        <Text testID="delink-confirmation-modal-content">
          Are you sure you want to remove the following account:
        </Text>
        <Box my={2}>
          <BulletText text={selectedAccountId} />
        </Box>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          title="Cancel"
          type="outline"
          onPress={onCloseDelinkModal}
          testID="cancel-delink-accounts-button"
        />
        <Box ml={1.5}>
          <Button
            title="Confirm"
            onPress={onConfirmDelinkAccount}
            testID="confirm-delink-accounts-button"
          />
        </Box>
      </Dialog.Actions>
    </Dialog>
  );
};

export default DelinkConfirmationModal;
