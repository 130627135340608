import { Card, Text, useDeviceType, useStyles } from '@nutrien/bonsai-core';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { DocumentAccountType } from '../../types/generated/globalTypes';
import { SearchStatements_searchDocuments } from 'types/generated/SearchStatements';
import { useFormatters } from '../../hooks';

interface Props {
  accountType: DocumentAccountType;
  accountNumber: string;
  documentId: string;
  issueDate: SearchStatements_searchDocuments['issueDate'];
  handlePress: (
    documentId: string,
    accountType: DocumentAccountType,
    issueDate: SearchStatements_searchDocuments['issueDate']
  ) => void;
}

const DocumentTile = ({
  accountType,
  documentId,
  issueDate,
  handlePress,
}: Props): JSX.Element => {
  const { isHandset } = useDeviceType();
  const flexDirection = isHandset ? 'column' : 'row';
  const styles = useStyles((theme) => {
    return {
      error: {
        color: theme.auColors.functional.error.dark,
      },
      card: {
        marginBottom: theme.spacing(1),
      },
      touchableStyle: {
        padding: theme.spacing(2),
        flexDirection,
        justifyContent: 'space-between',
      },
    };
  });

  const { formatDate } = useFormatters();

  return (
    <Card style={styles.card} key={documentId}>
      <TouchableOpacity
        onPress={() => handlePress(documentId, accountType, issueDate)}
        style={styles.touchableStyle}
      >
        {issueDate && (
          <Text>{`Statement: ${formatDate(issueDate, 'MMMM yyyy')}`}</Text>
        )}
      </TouchableOpacity>
    </Card>
  );
};

export default DocumentTile;
