import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React, { PropsWithChildren } from 'react';
import featureFlags from './featureFlags.json';

const ldConfig = {
  clientSideID: `${process.env.LAUNCH_DARKLY_CLIENT_ID}`,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    fetchGoals: false, // Only needed when we begin A/B testing. Defaults to true
  },
  // Bootstrap flags, we will only make use of a flag if it is located in featuredFlags.json
  flags: featureFlags,
};

interface LaunchDarklyInterface {
  init: () => Promise<void>;
  Provider: ({ children }: PropsWithChildren<unknown>) => JSX.Element | null;
}

const LaunchDarkly: LaunchDarklyInterface = {
  init: async () => {
    const LDProvider = await asyncWithLDProvider(ldConfig);
    LaunchDarkly.Provider = ({ children }) => (
      <LDProvider>
        <>{children}</>
      </LDProvider>
    );
  },
  Provider: () => null,
};

export default LaunchDarkly;
