import { useFlags } from 'launchdarkly-react-client-sdk';
import { TRANSACTIONS_FILTER } from '../../constants';
import { useNavigation } from '../../hooks';
import { useImperativeCustomerAccountsSummaryQuery } from '../../hooks/gql/queries/useGetAccountsSummaryQuery';
import { routeConfig } from '../../routes/shared';

interface UseNavigateToAccount {
  navigateToAccount: (id: string, type: string, customer_id?: string) => void;
}

const useNavigateToAccount = (): UseNavigateToAccount => {
  const flags = useFlags();
  const { navigate } = useNavigation();
  const accountsSummaryQuery = useImperativeCustomerAccountsSummaryQuery();

  /**
   * Navigate to account from dropdown
   * @remarks
   * If there is no Livestock flag for a Merchandise or All-in-one account, then
   * it should not attach `/{TAB}` in the route path.
   * @param id - The account id to navigate to
   *
   * {@link https://agrium.atlassian.net/browse/NG-3614}
   */
  const navigateToAccount = async (
    id: string,
    type: string,
    customer_id?: string
  ) => {
    const newAccountOverview =
      type &&
      flags['ng-2585-wdio-account-summary'] &&
      flags['ng-2585-wdio-account-summary'].includes(type.toLowerCase());

    const navParams = {
      type,
      id,
      customerId: customer_id,
    };

    if (!newAccountOverview) {
      return navigate(routeConfig.Account.getNavigateProps(navParams));
    }

    const { data: accountsSummaryData } = await accountsSummaryQuery({
      accountSummaryPayloads: [
        {
          AccountNumber: id,
          CompanyCode: '8200',
        },
      ],
    });

    const hasLivestock =
      accountsSummaryData?.getAccountsSummary?.find((summary) =>
        summary?.AccountNumber
          ? parseInt(id, 10) === summary?.AccountNumber
          : false
      )?.AccountType === 'L';

    if (newAccountOverview && hasLivestock) {
      navigate(
        routeConfig.AccountWithFilter.getNavigateProps({
          ...navParams,
          transactionsFilter: TRANSACTIONS_FILTER.MERCHANDISE,
        })
      );
    } else {
      navigate(routeConfig.Account.getNavigateProps(navParams));
    }
  };
  return {
    navigateToAccount,
  };
};

export default useNavigateToAccount;
