import React, { Suspense } from 'react';
import { ActivityIndicator, Box } from '@nutrien/bonsai-core';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GetRoutesByRoles from '../../routes';
import { useLogin } from '../../context/LoginContext';
import Layout from '../Layout';
import RedirectingIndicator from '../RedirectingIndicator';
import { UserRouteProps } from '../../routes/shared';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import getDatadogRum from '../../utils/datadog';

const renderRoute = ({ name, path, isProtected, View }: UserRouteProps) => {
  if (isProtected) {
    const ViewWithLayout = (): JSX.Element => (
      <Layout>
        <View />
      </Layout>
    );
    const element = withAuthenticationRequired(
      ViewWithLayout as React.ComponentType,
      {
        onRedirecting: () => (
          <Layout>
            <RedirectingIndicator />
          </Layout>
        ),
      }
    );
    return (
      <Route key={name} path={path} element={React.createElement(element)} />
    );
  }
  return <Route key={name} path={path} element={React.createElement(View)} />;
};

const Navigation = (): JSX.Element => {
  const {
    state: { roles },
  } = useLogin();
  const routes = GetRoutesByRoles(roles);

  const flags = useFlags();
  const datadogRum = getDatadogRum(flags);
  datadogRum.startSessionReplayRecording();

  return (
    <Router>
      <Suspense
        fallback={
          <Box flexGrow={1} alignItems="center" justifyContent="center">
            <ActivityIndicator testID="fallback-loading" />
          </Box>
        }
      >
        <Routes>
          {[
            ...routes.map(renderRoute),
            <Route
              path="*"
              key="unmatched-route"
              element={<Navigate to="/" />}
            />,
          ]}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Navigation;
