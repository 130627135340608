import React from 'react';
import { Box, Text, useTheme, useDeviceType } from '@nutrien/bonsai-core';

import AccountOverviewWrapper from '../../components/AccountOverview/AccountOverviewWrapper';
import QueryCard from '../../components/QueryCard';
import { useRoutes } from '../../hooks';
import useCustomerProfileByCustomerId from '../../hooks/useCustomerProfileByCustomerId';

type TextAlign = 'center' | 'auto' | 'left' | 'right' | 'justify' | undefined;

const UserSummary = (): JSX.Element => {
  const { routeParams } = useRoutes();
  const theme = useTheme();
  const { isDesktop } = useDeviceType();
  const customerId = routeParams.customerId as string;

  const {
    customerAccounts,
    customerProfileByCustomerIdLoading,
    customerProfileByCustomerIdError,
    customerProfileByCustomerIdRefetch,
  } = useCustomerProfileByCustomerId(customerId);

  const styles = {
    noAccountsWrapper: {
      marginTop: theme.spacing(1),
    },
    noAccounts: {
      textAlign: 'center' as TextAlign,
    },
  };

  return (
    <Box my={isDesktop ? 5 : 2}>
      <QueryCard
        wrapper={Box}
        loading={customerProfileByCustomerIdLoading}
        error={customerProfileByCustomerIdError}
        handleReload={() => customerProfileByCustomerIdRefetch()}
      >
        <>
          {customerAccounts.length !== 0 &&
            customerAccounts.map((id) => (
              <AccountOverviewWrapper
                key={id}
                account_ids={customerAccounts}
                account_id={id || ''}
                customer_id={customerId}
              />
            ))}
          {customerAccounts.length === 0 && (
            <Box style={styles.noAccountsWrapper}>
              <Text style={styles.noAccounts}>
                The customer has no accounts linked.
              </Text>
            </Box>
          )}
        </>
      </QueryCard>
    </Box>
  );
};

export default UserSummary;
