import React, { createContext, useReducer, useContext, ReactNode } from 'react';
import { Platform } from 'react-native';
import UserInactivity from 'react-native-user-inactivity';
import { navigate } from '../components/Navigation/RootNavigation';
import { SESSION_TIMEOUT } from '../constants';
import useAuth from '../auth/hooks';
import useApolloClientWithPersist from '../hooks/useApolloClientWithPersist';
import { routeConfig } from '../routes/shared';

export interface State {
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  verified: boolean;
  customerDomainId: string;
  accountIds: string[];
  userAvatar?: string;
  company?: string;
  branch?: string;
  phoneNumber?: string;
  address?: string;
  roles: string[];
  employeeId?: string;
}

export type Action =
  | {
      type: 'setUser';
      value: State;
    }
  | {
      type: 'clearUserState';
    };

type Dispatch = (action: Action) => void;

type LoginProviderValue = {
  state: State;
  dispatch: Dispatch;
};

type LoginProviderProps = { children: ReactNode; defaults?: State };

const LoginContext = createContext<LoginProviderValue | undefined>(undefined);

const defaultState: State = {
  firstName: '',
  lastName: '',
  preferredName: '',
  email: '',
  verified: false,
  customerDomainId: '',
  accountIds: [],
  userAvatar: '',
  company: '',
  branch: '',
  phoneNumber: '',
  address: '',
  roles: [],
  employeeId: '',
};

function loginReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setUser': {
      return action.value;
    }
    case 'clearUserState': {
      return defaultState;
    }
  }
}

const getState = (defaults: State | undefined): State => {
  if (defaults) {
    return defaults;
  }

  return defaultState;
};

function LoginProvider({
  children,
  defaults,
}: LoginProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(loginReducer, getState(defaults));
  const value = { state, dispatch };
  const { logout, isAuthenticated } = useAuth();
  const { clearCache } = useApolloClientWithPersist();

  const webLogout = async () => {
    await clearCache();
    logout({
      returnTo: `${window.location.origin}/?status=${SESSION_TIMEOUT}`,
    });
  };

  const nativeLogout = async () => {
    await clearCache();
    logout();
    navigate(routeConfig.Login.getNavigateProps({ status: SESSION_TIMEOUT }));
  };

  const logoutFn = Platform.OS === 'web' ? webLogout : nativeLogout;

  // default to 1 hour if env var is somehow missing
  const sessionTimeoutMs = process.env.SESSION_TIMEOUT_MS || '3600000';

  // gets triggered on any user action ("idleness" is considered an action here.. odd choice by the lib to not decouple)
  // isActive will be false on an idleness "action" (or lack thereof)
  function handleActivityAction(isActive: boolean) {
    if (!isActive && isAuthenticated) {
      logoutFn();
    }
  }

  return (
    <LoginContext.Provider value={value}>
      <UserInactivity
        timeForInactivity={+sessionTimeoutMs}
        onAction={handleActivityAction}
        key={`${isAuthenticated}`}
      >
        {children}
      </UserInactivity>
    </LoginContext.Provider>
  );
}

function useLogin(): {
  state: State;
  dispatch: Dispatch;
} {
  const context = useContext(LoginContext);

  if (context === undefined) {
    throw new Error('useLogin must be used within a LoginProvider');
  }

  return context;
}

export { LoginProvider, useLogin };
