import React, { ReactNode } from 'react';
import { Box } from '@nutrien/bonsai-core';
import Header from '../Header/Header';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import EmptyState from '../EmptyState';

const FallbackComponent = ({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void;
}) => (
  <>
    <Header isSimpleHeader />
    <Box flexGrow={1} alignItems="center" justifyContent="center">
      <EmptyState
        type={'error'}
        alert={'Error!'}
        onPress={() => resetErrorBoundary()}
        message="Something went wrong!"
        action="Try Again"
      />
    </Box>
  </>
);

const ErrorBoundary = ({
  children,
}: {
  children: ReactNode | null;
}): JSX.Element => {
  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
