import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { Box } from '@nutrien/bonsai-core';
import { useRoutes, useGetRecommendationSummaryQuery } from '../../hooks';
import QueryCard from '../../components/QueryCard';
import NotFoundMessage from '../../components/FarmVisit/NoFoundMessage';
import ProductList from './ProductList';
import RecommendationList from './RecommendationList';
import useRedirect from '../../hooks/useRedirect/index';
import { routeConfig } from '../../routes/shared';

const Recommendation = (): JSX.Element => {
  const { routeParams } = useRoutes();

  useRedirect({
    from: generatePath(routeConfig.OldFarmRecommendation.path, routeParams),
    to: generatePath(routeConfig.FarmRecommendation.path, routeParams),
  });
  const id = routeParams.id || '';
  const [currentIndex, setCurrentIndex] = useState(0);

  // Get recommendation
  const [recommendationSummary, loading, error, refetch] =
    useGetRecommendationSummaryQuery({
      recoId: id,
    });

  // Data types are loose or could be undefined. Protect against undefined errors
  const recommendationData =
    typeof recommendationSummary?.farm_recommendation_account !== 'undefined'
      ? recommendationSummary?.farm_recommendation_account[0]
      : undefined;

  const invalidUuidError = 'Error: invalid input syntax for type uuid:';
  if (error?.toString().includes(invalidUuidError)) {
    return (
      <NotFoundMessage
        alert="Invalid Recommendation ID"
        message="Please try a different recommendation id"
      />
    );
  }

  const recommendationScreen = () => {
    // Missing farm_recommendation_account, no data or no match, return no recommendation
    if (!recommendationData || Object.keys(recommendationData).length === 0) {
      return (
        <NotFoundMessage
          alert="No Recommendation found"
          message="Please try a different recommendation."
        />
      );
    }
    return currentIndex === 0 ? (
      <RecommendationList
        id={id}
        updateIndex={setCurrentIndex}
        recoAccessData={recommendationData}
      />
    ) : (
      <ProductList
        updateIndex={setCurrentIndex}
        recoAccessData={recommendationData}
      />
    );
  };

  return (
    <QueryCard
      loading={loading}
      error={error}
      handleReload={() => refetch()}
      wrapper={Box}
    >
      <Box testID="recommendation-screen">{recommendationScreen()}</Box>
    </QueryCard>
  );
};

export default Recommendation;
