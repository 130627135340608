import { Box, Dialog, Text, useStyles } from '@nutrien/bonsai-core';
import React from 'react';
import { Platform } from 'react-native';
import useAuth from '../../auth/hooks';
import { useStaff } from '../../context/StaffContext';
import { useNavigation, useSegment } from '../../hooks';
import useApolloClientWithPersist from '../../hooks/useApolloClientWithPersist';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../Button';
import { trackEvent } from '../../constants/segment';
import { selectedAccountVar, breadCrumbVar } from '../../reactiveVariables';

interface UnsavedModalProps {
  title: string;
  message: string;
  changePageIndex?: (value: React.SetStateAction<number>) => void;
}

const UnsavedModal = ({
  title,
  message,
  changePageIndex,
}: UnsavedModalProps) => {
  const {
    state: { notificationModal },
    dispatch,
  } = useStaff();

  const { logout } = useAuth();

  const { navigate } = useNavigation();
  const { clearCache } = useApolloClientWithPersist();
  const segment = useSegment();
  const { account_id } = useReactiveVar(selectedAccountVar);
  const { breadCrumbPath } = useReactiveVar(breadCrumbVar);

  const navigateLogin = { name: 'Login', path: '/', params: { status: '' } };

  const styles = useStyles((theme) => ({
    buttonContainer: {
      width: 110,
      height: 50,
      marginRight: theme.spacing(1),
    },
    titleContainer: {
      backgroundColor: theme.auColors.primary[300],
      left: 0,
      marginHorizontal: 0,
      margin: 0,
      marginTop: 0,
      height: 40,
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    title: {
      fontSize: theme.typography.fontSize.large,
      fontWeight: 'bold',
      color: 'white',
    },
    content: {
      paddingTop: theme.spacing(5),
    },
    footer: {
      paddingBottom: 0,
    },
  }));

  const logoutWeb = async () => {
    await clearCache();
    void segment?.track(trackEvent.LOGOUT_SELECTED);
    logout({ returnTo: window.location.origin });
  };

  const logoutNative = async () => {
    await clearCache();
    void segment?.track(trackEvent.LOGOUT_SELECTED);
    logout();
    navigate(navigateLogin);
  };

  const logoutFn = Platform.OS === 'web' ? logoutWeb : logoutNative;

  /*
    Hide notificatioNModal.
    Reset hasChangeInForm in StaffContext
  */
  const hideModal = () => {
    const modalProps = { ...notificationModal, showSaveModal: false };
    dispatch({
      type: 'setNotificationModal',
      value: modalProps,
    });
  };

  /*

    Hide notificatioNModal. Reset hasChangeInForm in StaffContext.
    Reset notificationModal in StaffContext
    Note: Has different logic if "feature" is represent in notificationModal state
   */
  const handleOkayButton = () => {
    let segmentOriginBreadcrumb = '';
    let segmentDestBreadcrumb = '';
    const modalProps = { showSaveModal: false, url: undefined };
    dispatch({ type: 'setNotificationModal', value: modalProps });
    dispatch({ type: 'setChangesInForm', value: false });

    if (notificationModal.feature) {
      const { feature } = notificationModal;
      // If user press Logout button
      if (feature === 'logout') {
        logoutFn();
      }
      if (
        feature === 'EditUser' &&
        changePageIndex &&
        notificationModal.pageIndex !== undefined
      ) {
        changePageIndex(notificationModal.pageIndex);
      }
    } else {
      // Handle breadcrumbs's segment tracking.
      if (notificationModal.url) {
        const { name, path, params } = notificationModal.url;
        if (notificationModal.url.name === 'Home') {
          navigate({ name, path, params });
          segmentDestBreadcrumb = 'Dashboard->';
          breadCrumbPath.destBreadcrumb.forEach((title) => {
            return (segmentOriginBreadcrumb = `${segmentOriginBreadcrumb}${title}->`);
          });
        } else {
          navigate({ name, path, params });
          breadCrumbPath.originBreadcrumb.forEach((breadcrumbTitle) => {
            return (segmentDestBreadcrumb = `${segmentDestBreadcrumb}${breadcrumbTitle}->`);
          });

          breadCrumbPath.destBreadcrumb.forEach((breadcrumbTitle) => {
            return (segmentOriginBreadcrumb = `${segmentOriginBreadcrumb}${breadcrumbTitle}->`);
          });
        }

        segment?.track(trackEvent.BREADCRUMBS_SELECTED, {
          origin_breadcrumb: segmentOriginBreadcrumb.slice(0, -2),
          destination_breadcrumb: segmentDestBreadcrumb.slice(0, -2),
          account_id,
        });
      }
    }
  };

  const renderTitle = () => (
    <Box>
      <Text style={styles.title}>{title}</Text>
    </Box>
  );

  return (
    <Dialog visible={notificationModal.showSaveModal} onDismiss={hideModal}>
      <Dialog.Title title={renderTitle()} style={styles.titleContainer} />
      <Dialog.Content style={styles.content}>
        <Text testID="unsaved">{message}</Text>
      </Dialog.Content>
      <Dialog.Actions style={styles.footer}>
        <Button
          containerStyle={styles.buttonContainer}
          title="Okay"
          type="solid"
          onPress={handleOkayButton}
          testID="unsaved-okay-modal-button"
        />
        <Button
          containerStyle={styles.buttonContainer}
          title="Cancel"
          type="outline"
          onPress={hideModal}
          testID="unsaved-modal-cancel-button"
        />
      </Dialog.Actions>
    </Dialog>
  );
};

export default UnsavedModal;
