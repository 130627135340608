import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  State as UserState,
  useLogin as useLoginConext,
} from '../../context/LoginContext';
import useAuth from '../../auth/hooks';
import { RoleType } from '../../auth/types';
import union from 'lodash.union';
import { User } from '@auth0/auth0-spa-js';
import useCustomerProfile from './useCustomerProfile';
import { datadogRum } from '@datadog/browser-rum';

export interface UseLogin {
  userState: UserState;
  setUserState: () => void;
  clearUserState: () => void;
  getUserStateFromUser: (user: User) => UserState;
  onboardedBranchCode?: string | null;
}

const useLogin = (): UseLogin => {
  const { isAuthenticated, user } = useAuth();
  const { dispatch, state: userState } = useLoginConext();
  const ldClient = useLDClient();

  const auth0Namespace = 'https://www.nutrienagsolutions.com.au';
  const customerId = user && user[`${auth0Namespace}/customerDomainId`];

  const { onboardedBranchCode, customerProfileLoading } =
    useCustomerProfile(customerId);

  const getUserStateFromUser = (_user: User): UserState => {
    const domainIdKey = `${auth0Namespace}/customerDomainId`;
    const accountIdsKey = `${auth0Namespace}/accountIds`;
    const givenNameKey = `${auth0Namespace}/given_name`;
    const familyNameKey = `${auth0Namespace}/family_name`;
    const fullNameKey = `${auth0Namespace}/full_name`;
    const preferredNameKey = `${auth0Namespace}/preferred_name`;
    const rolesKey = `${auth0Namespace}/roles`;

    const {
      email,
      email_verified: verified,
      [domainIdKey]: customerDomainId,
      [accountIdsKey]: accountIds,
      [givenNameKey]: givenName,
      [familyNameKey]: familyName,
      [fullNameKey]: fullName,
      [preferredNameKey]: preferredName,
      [rolesKey]: roles,
      picture,
      company,
      branch,
      phoneNumber,
      address,
      sub,
    } = _user;

    const updatedRoles = getUserRoles(email || '', roles);
    const fullNameArray = fullName && fullName.split(' ');
    // TODO
    // employeeId is used for segment analytics for employee users
    // and will be replaced later with party_id when we move to Party Domain
    const employeeId = sub && sub.includes('waad|') ? sub.split('|')[1] : '';

    return {
      email: email || '',
      verified: verified || false,
      firstName: givenName || (fullNameArray && fullNameArray[0]) || '',
      lastName:
        familyName ||
        (fullNameArray && fullNameArray[fullNameArray.length - 1]) ||
        '',
      preferredName: preferredName || '',
      customerDomainId,
      accountIds,
      userAvatar: picture,
      company,
      branch,
      phoneNumber,
      address,
      roles: updatedRoles,
      employeeId,
    };
  };

  const setUserState = () => {
    if (isAuthenticated && user && !customerProfileLoading) {
      const stateUser = getUserStateFromUser(user);
      if (ldClient) {
        const { email, customerDomainId, employeeId } = stateUser;
        const ldClientUser = {
          key: email,
          email: email,
          custom: {
            userId: customerDomainId || employeeId || '',
          },
        };
        ldClient.identify(ldClientUser);
      }
      dispatch({ type: 'setUser', value: stateUser });

      // Set user attribute for Datadog
      const { email, customerDomainId, employeeId, firstName, lastName } =
        stateUser;
      datadogRum.setUser({
        id: customerDomainId || employeeId || '',
        name: `${firstName} ${lastName}`,
        email: email,
      });
    }
  };

  const clearUserState = () => dispatch({ type: 'clearUserState' });

  return {
    userState,
    setUserState,
    clearUserState,
    getUserStateFromUser: getUserStateFromUser,
    onboardedBranchCode,
  };
};

/**
 * TODO: remove this after all roles are merged from auth0
 * Merge auth0 roles with defaultRole
 *
 * @param email
 * @param roles
 */
export const getUserRoles = (email: string, roles?: string[]): string[] => {
  const defaultRole = email.toLowerCase().includes('@nutrien')
    ? RoleType.staff
    : RoleType.customer;

  return union(roles, [defaultRole]);
};

export default useLogin;
