const m2mConfigs = {
  guest: {
    client_id: process.env.M2M_GUEST_USER_SVC_CLIENT_ID,
    client_secret: process.env.M2M_GUEST_USER_SVC_CLIENT_SECRET,
  },
};

export const getLowTrustAudience = (auth0Audience: string) =>
  auth0Audience?.replace('au.auth0.com', 'low-trust.com');

export interface M2mAuth0Token {
  /**
   * seconds until expiry
   */
  expires_in: number;
  access_token: string;
  scope: string;
  token_type: string;
}
/**
 * Given a m2mApplicationType type, we will go to Auth0 and fetch a new token.
 * This utility should be used for times where there is a need for a temporary
 * short expiry access token. i.e. the Guest M2m which is used in self registration.
 */
const getM2mTokenFromAuth0 = async (
  m2mApplicationType: 'guest' = 'guest'
): Promise<M2mAuth0Token> => {
  const response = await fetch(
    `https://${process.env.AUTH0_DOMAIN}/oauth/token`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        // eslint-disable-next-line security/detect-object-injection
        ...m2mConfigs[m2mApplicationType],
        audience: `https://${getLowTrustAudience(
          process.env.AUTH0_DOMAIN || ''
        )}`,
        grant_type: 'client_credentials',
      }),
    }
  );

  return response.json();
};

export { getM2mTokenFromAuth0 };
