import React from 'react';
import { Text } from '@nutrien/bonsai-core';

import Banner from '../Banner';
import { useStaff } from '../../context/StaffContext';
import { useLogin } from '../../context/LoginContext';
import useUserInformation from '../SubHeader/useUserInformation';
import { useRoutes } from '../../hooks';

const RegistrationError = (): JSX.Element | null => {
  const {
    state: { roles },
  } = useLogin();
  const {
    state: { selectedCustomer, showCustomerLoginError },
    dispatch,
  } = useStaff();
  const { routeName, pathName } = useRoutes();

  const isUserSummary =
    roles.includes('staff') &&
    (routeName === 'UserSummary' || pathName?.includes('/user-summary'));

  if (!isUserSummary) {
    return null;
  }

  const { hasGetCustomerLoginError, errorMessage } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUserInformation(selectedCustomer);

  const onCloseCustomerLoginError = () =>
    dispatch({
      type: 'setShowCustomerLoginError',
      value: false,
    });

  return (
    <Banner
      location="global"
      type="error"
      visible={showCustomerLoginError}
      testID={
        hasGetCustomerLoginError
          ? 'get-registration-status-error'
          : 'send-invite-error-message'
      }
      onDismissPress={onCloseCustomerLoginError}
      hideLeftBorder
    >
      <Text>{errorMessage}</Text>
    </Banner>
  );
};

export default RegistrationError;
