import { Box, NamedStyles, Text, useStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary, useFormatters } from '../../../hooks';
import useDrawer from '../../../hooks/useDrawer';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from 'types/generated/CustomerAccountsSummarySplitUp';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../../SummaryCard';
import { DrawerLink } from '../DrawerLink';
import PanelDrawer from '../PanelDrawer/PanelDrawer';

export interface LivestockPendingProceedsPanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp?: AccountSummarySplitUpData | null;
  sharedStyles?: NamedStyles<Record<string, undefined>>;
}

const LivestockPendingProceedsPanel: FC<LivestockPendingProceedsPanelProps> = ({
  summary,
  summarySplitUp,
  sharedStyles,
}) => {
  const { showMoreLinks, proceedsPending } = useAccountSummary(summary, true);
  const { renderNoneIfZero } = useFormatters();
  const styles = useStyles(sharedStyles ?? {});
  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      <SummaryCard
        heading="Pending proceeds"
        summaryRow={[
          {
            label: (
              <Box style={styles.label}>
                <Text style={styles.labelText}>Pending proceeds</Text>
              </Box>
            ),
            content: renderNoneIfZero(proceedsPending),
            ...(typeof proceedsPending === 'number' && proceedsPending < 0
              ? {
                  description: (
                    <Box marginTop={0.5}>
                      <Text style={styles.info}>
                        This will not reduce any amount due
                      </Text>
                    </Box>
                  ),
                }
              : undefined),
            button: (
              <DrawerLink
                enabled={
                  !!(showMoreLinks && proceedsPending && proceedsPending < 0)
                }
                drawerInfo={{
                  name: 'Pending proceeds',
                  data: { amount: proceedsPending, summarySplitUp },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: 'Livestock',
                }}
              />
            ),
            testID: 'pending-proceeds-column',
          },
        ]}
        testID="account-details-livestock-pending-proceeds-panel"
      />
      <PanelDrawer
        onClose={(closeMethod: string) =>
          onDrawerClose(closeMethod, {
            tab_view: 'Livestock',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};

export default LivestockPendingProceedsPanel;
