import { makeVar, ReactiveVar } from '@apollo/client';
import { Transaction } from '../../components/Transaction/transactions.types';
import { GroupedTransactionsType } from '../../hooks/useTransactions';
import { GetLotDetails_getLotDetails } from '../../types/generated/GetLotDetails';

export interface LotDetailsItem {
  lotDetailsLoading?: boolean;
  lotDetailsResponse?: GetLotDetails_getLotDetails;
}

export type LotDetailsList = Record<string, LotDetailsItem>;

export interface ParsedTransactions {
  itemList: Transaction[];
  groupedTransactions: GroupedTransactionsType;
  lotDetailsList: LotDetailsList;
  credits?: number | null;
  expenses?: number | null;
  transactionsLoading?: boolean;
}

export type AccountTransactions = Record<string, ParsedTransactions>;

export const accountTransactionsVar: ReactiveVar<AccountTransactions> =
  makeVar<AccountTransactions>({});
