import { Platform } from 'react-native';
import fetch from 'cross-fetch';
import { ApolloClient, HttpLink, ApolloError, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import {
  onError,
  ErrorHandler,
  ErrorResponse,
} from '@apollo/client/link/error';
import { navigate } from '../../components/Navigation/RootNavigation';
import { cache } from './cache';
import { defaultOptions } from './defaultOptions';
import { getM2mToken, M2mTokenType } from '../../utils/auth/guestM2m';
import { SegmentProps } from '../useSegment/shared';
import { trackEvent } from '../../constants/segment';
import { useSegment } from '..';

export const unAuthorizedErrors = [
  'Unauthorised role. Please log in with an authorised account',
  'No role has been registered. Please log in with a registered role',
];

/** Handles any graphql error. */
export const handleGraphqlError = (
  error: ApolloError,
  unAuthorizedHandlerFn: () => void
): void => {
  if (unAuthorizedErrors.includes(error.networkError?.message || '')) {
    unAuthorizedHandlerFn();
  }
};

export const onErrorCallBack = (
  unAuthorizedFn: () => void,
  segment: SegmentProps | null
): ErrorHandler => {
  const errorHandler = (error: ErrorResponse) => {
    const graphQLErrorMessage = error?.graphQLErrors?.[0]?.message;
    if (graphQLErrorMessage && segment) {
      segment.track(trackEvent.ERROR, {
        error_message: graphQLErrorMessage,
      });
    }
    return handleGraphqlError(error as unknown as ApolloError, unAuthorizedFn);
  };
  return errorHandler as unknown as ErrorHandler;
};

export interface M2mApolloClientOptions {
  m2mTokenType: M2mTokenType;
  /**
   * If for whatever reason the graphql server returns an unauthorized error.
   * We should route them to the given route. The route should handle either:
   * getting them a new token with the correct role OR routing them to the correct
   * screen that supports the token they are using (probably a login token)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ifUnAuthorizedRouteTo: any;
}

/**
 * This is a barebones client that is used for "Guest" (probably M2M tokens) authentication
 * within the digital hub.
 */
const useGuestM2mApolloClient = ({
  m2mTokenType,
  ifUnAuthorizedRouteTo,
}: M2mApolloClientOptions) => {
  const segment = useSegment();

  const unAuthorizedFn = () => navigate(ifUnAuthorizedRouteTo);

  const endpoint = `${process.env.API_BASE_URL}/v1/graphql`;
  const httpLink = new HttpLink({
    uri: endpoint,
    fetch: fetch,
  });

  const wsLink =
    Platform.OS === 'web'
      ? new WebSocketLink({
          uri: `${process.env.API_BASE_WS_URL}/v1/graphql`,
          options: {
            reconnect: true,
            lazy: true,
            connectionParams: async () => {
              const token = await getToken();
              return {
                headers: {
                  ...(token && {
                    Authorization: `Bearer ${token}`,
                  }),
                },
              };
            },
          },
        })
      : null;

  /**
   * Return a valid M2M token. It will ensure that within X minutes of the token expiring
   * that a new token is requested. This is to ensure a good UX.
   */
  const getToken = async () => await getM2mToken(m2mTokenType, 3);

  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        ...(token && { Authorization: `Bearer ${token}` }),
        'Access-Control-Allow-Headers': 'newrelic',
      },
    };
  });

  /** Currently this client only needs to support unauthorized errors. */
  const errorHandlerLink = onError(
    onErrorCallBack(unAuthorizedFn, segment) as ErrorHandler
  );

  const splitLink = wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

  return {
    client: new ApolloClient({
      cache,
      connectToDevTools: process.env.APOLLO_CLIENT_DEV_MODE === 'true',
      defaultOptions,
      link: errorHandlerLink.concat(authLink?.concat(splitLink)),
    }),
  };
};

export default useGuestM2mApolloClient;
