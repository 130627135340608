import React, { FC } from 'react';
import {
  Box,
  Card,
  Icon,
  Pressable,
  Text,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import { useLogin } from '../../context/LoginContext';
import { sortRecoActivityInput } from '../../utils/recommendationUtils';
import ItemCard from './ItemCard';
import { FarmRecommendation_farm_recommendation_account } from '../../types/generated/FarmRecommendation';

type props = {
  recoAccessData: FarmRecommendation_farm_recommendation_account | undefined;
  updateIndex: (index: number) => void;
};

const ProductList: FC<props> = ({ recoAccessData, updateIndex }) => {
  const {
    state: { roles },
  } = useLogin();
  const theme = useTheme();

  const styles = useStyles(() => {
    return {
      cardStyle: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      titleText: {
        fontSize: theme.typography.fontSize.large,
        fontWeight: '600',
      },
      navigatonText: {
        color: theme.auColors.primary.default,
        fontWeight: '700',
      },
      item: {
        backgroundColor: 'transparent',
      },
      link: {
        color: theme.auColors.primary.default,
        textDecorationLine: 'underline',
      },
      defaultText: {
        fontFamily: theme.typography.fontFamily.semiBold,
      },
    };
  });

  const { reco_activity_input } = { ...recoAccessData?.reco_account_activity };

  const orderedActivityInputList = sortRecoActivityInput(reco_activity_input);

  const handleNavigation = () => {
    updateIndex(0);
  };

  return (
    <Box testID={`product-list-${roles.join('-')}`}>
      <Box flexDirection="row" alignItems="center" mt={1} mb={2} ml={-1}>
        <Icon name="chevron-left" color={theme.auColors.primary.default} />
        <Pressable
          focusable={true}
          focusedStyle={styles.item}
          hoveredStyle={styles.item}
          onPress={handleNavigation}
          testID="recommendation-view-recommendation-list-button"
        >
          <Text style={[styles.defaultText, styles.link]}>
            Recommendation Summary
          </Text>
        </Pressable>
      </Box>
      <Card style={styles.cardStyle}>
        <Text style={styles.titleText} bodyBold testID="product-list-title">
          Product List
        </Text>
      </Card>
      {orderedActivityInputList?.map((rec, index) => (
        <ItemCard
          key={index}
          data={rec}
          totalArea={
            recoAccessData?.reco_account_activity?.total_activity_area || null
          }
        />
      ))}
    </Box>
  );
};

export default ProductList;
