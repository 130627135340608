import React from 'react';
import { Path, Rect } from 'react-native-svg';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
  <>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.61078 17.2558H18.5389C19.0945 17.2558 19.5449 16.8039 19.5449 16.2465C19.5449 15.6891 19.0945 15.2372 18.5389 15.2372C18.5389 15.2372 17.6766 14.7853 17.6766 14.2279C17.6766 13.6705 18.2445 13.2186 18.9451 13.2186H20.4072C20.9628 13.2186 21.4132 12.7667 21.4132 12.2093C21.4132 11.6519 20.9628 11.2 20.4072 11.2H17.2455C17.8011 11.2 18.2515 10.7482 18.2515 10.1907C18.2515 9.63331 17.8011 9.18144 17.2455 9.18144H22.994C23.5496 9.18144 24 8.72956 24 8.17214C24 7.61472 23.5496 7.16284 22.994 7.16284H8.91018C8.35459 7.16284 7.90419 7.61472 7.90419 8.17214C7.90419 8.72956 8.35459 9.18144 8.91018 9.18144H3.16168C2.60608 9.18144 2.15569 9.63331 2.15569 10.1907C2.15569 10.7482 2.60608 11.2 3.16168 11.2H6.75449C7.31008 11.2 7.76048 11.6519 7.76048 12.2093C7.76048 12.7667 7.31008 13.2186 6.75449 13.2186H1.00599C0.450396 13.2186 0 13.6705 0 14.2279C0 14.7853 0.450396 15.2372 1.00599 15.2372H6.61078C6.05519 15.2372 5.60479 15.6891 5.60479 16.2465C5.60479 16.8039 6.05519 17.2558 6.61078 17.2558ZM21.988 12.2094C21.988 12.7668 22.4384 13.2187 22.994 13.2187C23.5496 13.2187 24 12.7668 24 12.2094C24 11.652 23.5496 11.2001 22.994 11.2001C22.4384 11.2001 21.988 11.652 21.988 12.2094Z"
      fill="#F1F0F2"
    />
    <Rect
      x="5.96191"
      y="8.21851"
      width="9.92751"
      height="11.3457"
      transform="rotate(-7 5.96191 8.21851)"
      fill="#F7F7F7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.84801 7.67684L6.1498 7.75512L6.10581 7.76176C5.80626 7.81847 5.59864 8.09934 5.63633 8.40637L6.93628 18.9935L6.94312 19.0394C7.02301 19.493 7.4445 19.8099 7.9064 19.7532L16.3222 18.7198L16.368 18.713C16.821 18.6331 17.1366 18.2106 17.0798 17.7481L16.9593 16.7667L15.618 6.98061L15.6124 6.95089C15.5772 6.80534 15.4367 6.70723 15.2851 6.72801C15.1235 6.75017 15.0104 6.89919 15.0325 7.06085L16.3733 16.8428L16.4932 17.8201L16.4953 17.8488C16.4984 17.9915 16.3936 18.1157 16.2502 18.1333L7.83438 19.1667L7.80589 19.1687C7.66445 19.1716 7.54057 19.0663 7.52279 18.9215L6.22359 8.3413L6.91385 8.26409L6.9437 8.2592C7.09008 8.22757 7.1916 8.08957 7.17455 7.93754C7.15637 7.77538 7.01018 7.65866 6.84801 7.67684ZM8.42598 7.50958L7.76676 7.5835C7.6046 7.60168 7.48788 7.74789 7.50607 7.91005C7.52425 8.07221 7.67045 8.18893 7.83262 8.17074L8.49184 8.09682L8.52169 8.09194C8.66806 8.0603 8.76958 7.9223 8.75253 7.77027C8.73435 7.60811 8.58815 7.49139 8.42598 7.50958Z"
      fill="#CCCCCC"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.93384 5.70906V16.2387L9.35095 16.6631H17.6132L17.9029 15.8823V7.45125L15.8844 5.34656H9.35095L8.93384 5.70906Z"
      fill="#FFFFFF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.543 5C15.7942 5 16.0351 5.09996 16.2125 5.27782L17.9281 6.99809C18.1049 7.17533 18.2042 7.41543 18.2042 7.66575V16.1661C18.2042 16.6883 17.7809 17.1116 17.2587 17.1116H9.52092C8.99874 17.1116 8.57544 16.6883 8.57544 16.1661V5.94548C8.57544 5.4233 8.99874 5 9.52092 5H15.543ZM15.4654 5.59099H9.52088C9.33659 5.59099 9.18513 5.7316 9.16795 5.9114L9.16633 5.94555V16.1662C9.16633 16.3505 9.30694 16.5019 9.48674 16.5191L9.52088 16.5207H17.2587C17.443 16.5207 17.5944 16.3801 17.6116 16.2003L17.6132 16.1662V7.74671L17.6131 7.74671H16.192C15.8049 7.74671 15.4888 7.44343 15.4667 7.06145L15.4654 7.01869V5.59099ZM16.0564 5.95822V7.01869C16.0564 7.08703 16.1057 7.14341 16.1701 7.154L16.192 7.15579H17.2507L16.0564 5.95822Z"
      fill="#CCCCCC"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3321 7.4511C14.3321 7.28792 14.1999 7.15564 14.0367 7.15564H10.3001L10.2699 7.15717C10.121 7.1723 10.0047 7.29812 10.0047 7.4511C10.0047 7.61428 10.137 7.74656 10.3001 7.74656H14.0367L14.0669 7.74504C14.2159 7.72991 14.3321 7.60408 14.3321 7.4511ZM16.4795 8.88579C16.6427 8.88579 16.775 9.01807 16.775 9.18125C16.775 9.33423 16.6587 9.46006 16.5097 9.47519L16.4795 9.47671H10.2999C10.1367 9.47671 10.0044 9.34443 10.0044 9.18125C10.0044 9.02827 10.1207 8.90245 10.2696 8.88732L10.2999 8.88579H16.4795ZM16.775 11.0557C16.775 10.8925 16.6427 10.7602 16.4795 10.7602H10.2999L10.2696 10.7618C10.1207 10.7769 10.0044 10.9027 10.0044 11.0557C10.0044 11.2189 10.1367 11.3512 10.2999 11.3512H16.4795L16.5097 11.3496C16.6587 11.3345 16.775 11.2087 16.775 11.0557ZM16.4795 12.6347C16.6427 12.6347 16.775 12.767 16.775 12.9301C16.775 13.0831 16.6587 13.2089 16.5097 13.2241L16.4795 13.2256H10.2999C10.1367 13.2256 10.0044 13.0933 10.0044 12.9301C10.0044 12.7772 10.1207 12.6513 10.2696 12.6362L10.2999 12.6347H16.4795ZM14.0364 14.5091C14.1996 14.5091 14.3318 14.6414 14.3318 14.8046C14.3318 14.9576 14.2156 15.0834 14.0666 15.0985L14.0364 15.1H10.2999C10.1367 15.1 10.0044 14.9678 10.0044 14.8046C10.0044 14.6516 10.1207 14.5258 10.2696 14.5106L10.2999 14.5091H14.0364Z"
      fill="#CCCCCC"
    />
  </>,
  'NoDocuments'
);
