import React from 'react';
import { Box, Text, useTheme } from '@nutrien/bonsai-core';
import Banner from '../Banner';

const CustomerInfoBannner = () => {
  const theme = useTheme();
  const styles = {
    infoBanner: {
      marginBottom: theme.spacing(2),
    },
    infoBannerText: {
      color: theme.auColors.functional.info.dark,
      fontSize: theme.typography.fontSize.small,
    },
  };
  return (
    <Banner
      location="section"
      type="info"
      visible={true}
      testID="customer-details-info"
      style={styles.infoBanner}
    >
      <Box>
        <Text bodyBold style={styles.infoBannerText}>
          Are your details correct?
        </Text>
        <Text style={styles.infoBannerText}>
          Please check that your details are correct before proceeding.
        </Text>
      </Box>
    </Banner>
  );
};

export default CustomerInfoBannner;
