import React from 'react';
import { Box } from '@nutrien/bonsai-core';
import AccountOverviewWrapper from '../../components/AccountOverview/AccountOverviewWrapper';
import QueryCard from '../../components/QueryCard';
import useAccounts from './useAccounts';
import Breadcrumbs from '../../components/Breadcrumbs';
import { breadcrumbConstants } from '../../constants';

const Accounts = (): JSX.Element => {
  const { accountIds, customerAccountsLoading, customerAccountsError } =
    useAccounts();
  // TODO: refactor AccountOverview to either accept account object or just an id, where it does the lookup
  return (
    <>
      <Breadcrumbs
        items={[
          breadcrumbConstants.home,
          {
            title: 'Account Summary',
          },
        ]}
      />
      <QueryCard
        wrapper={Box}
        loading={customerAccountsLoading}
        error={customerAccountsError}
      >
        {accountIds.map((id) => (
          <AccountOverviewWrapper
            account_ids={
              accountIds.filter((accountId) => !!accountId) as string[]
            }
            account_id={id || ''}
            key={id}
          />
        ))}
      </QueryCard>
    </>
  );
};

export default Accounts;
