import { useState } from 'react';

import { CustomerDetail } from '../../context/StaffContext';
import { trackEvent } from '../../constants/segment';
import { useSegment } from '../../hooks';
import { SegmentTraits } from '../../types/Segment.types';

export interface SegmentInviteSent {
  isSegmentInviteSentFinished: boolean;
  segmentInviteSentTrack: (email_status: string) => void;
}

const useSegmentInviteSent = (
  selectedCustomer: CustomerDetail | null,
  customerId: string,
  employeeId?: string
): SegmentInviteSent => {
  const [isSegmentInviteSentFinished, setIsSegmentInviteSentFinished] =
    useState(false);
  const segment = useSegment();

  const segmentInviteSentTrack = async (email_status: string) => {
    setIsSegmentInviteSentFinished(false);

    const employeeTrackProperty = {
      userId: employeeId,
      person_id: customerId,
      email_status,
    };

    const customerTrackProperty = {
      userId: customerId,
      email_status,
    };

    const customerTrait: SegmentTraits = {
      userId: customerId,
      party_type: 'person',
      user_type: 'customer',
      onboarded_branch_code: selectedCustomer?.onboardedBranchCode,
    };

    const employeeTrait: SegmentTraits = {
      userId: employeeId,
      party_type: 'person',
      user_type: 'Nutrien employee',
    };

    if (segment && employeeId) {
      try {
        // Customer identify
        segment.reset();
        await segment.identify(customerId, customerTrait);
        // Invitation sent - customer user’s analytics
        await segment.track(trackEvent.INVITE_SENT, customerTrackProperty);
        // Employee identify
        segment.reset();
        await segment.identify(employeeId, employeeTrait);
        // Invitation sent - employee analytics
        await segment.track(trackEvent.INVITE_SENT, employeeTrackProperty);
      } catch (err) {
        throw new Error(err as string | undefined);
      }
    }

    setIsSegmentInviteSentFinished(true);
  };

  return {
    isSegmentInviteSentFinished,
    segmentInviteSentTrack,
  };
};

export default useSegmentInviteSent;
