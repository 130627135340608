import { useReactiveVar } from '@apollo/client';
import {
  Box,
  TablePagination,
  useDeviceType,
  useSelections,
} from '@nutrien/bonsai-core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useGetLotDetailsLazyQuery } from '../../hooks';
import usePagination from '../../hooks/usePagination';
import { isLivestock } from '../../hooks/useTransactions/transaction.helper';
import {
  accountTransactionsMutations,
  selectedAccountMutations,
  selectedAccountVar,
} from '../../reactiveVariables';
import QueryCard from '../QueryCard';
import { TabSceneProps } from '../Transaction/TabScene';
import NoTransactions from '../TransactionsTableView/NoTransactions';
import GroupedTransactions from './GroupedTransactions';
import Transaction from './Transaction';

type TransactionGroupViewProps = Pick<
  TabSceneProps,
  | 'loading'
  | 'error'
  | 'groupedItems'
  | 'refetch'
  | 'onPress'
  | 'lotDetailErrorDocumentIds'
>;

const Wrapper = ({ children }: { children?: ReactNode }) => (
  <Box minHeight={115}>{children}</Box>
);

const TransactionGroupView = ({
  onPress,
  loading,
  error,
  refetch,
  groupedItems,
  lotDetailErrorDocumentIds = [],
}: TransactionGroupViewProps): JSX.Element => {
  const { isDesktop } = useDeviceType();
  const { account_id, account_view, lot_details_expanded_all } =
    useReactiveVar(selectedAccountVar);
  const { editSelectedAccount } = selectedAccountMutations;

  const items = useMemo(
    () => (groupedItems && groupedItems.size ? Array.from(groupedItems) : []),
    [groupedItems]
  );
  const segmentProperties = {
    account_id,
    tab_header: account_view.selected_tab,
    tab_label: account_view.display_view,
  };

  const {
    pageSize,
    pageIndex,
    onSetPageSize,
    onNextPage,
    onPrevPage,
    numberOfPages,
    filterItems,
  } = usePagination(items);

  const flags = useFlags();
  const isLotDetailsFeatureOn = flags['NG-2721-livestock-sale-lot-details'];
  const itemGroupByIds = items.map((item) => {
    return item[0];
  });

  const { toggle, isSelected, unSelectAll, selectAll } =
    useSelections(itemGroupByIds);

  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [getLotDetails, lotDetailsData, lotDetailsLoading] =
    useGetLotDetailsLazyQuery();
  const { editLotDetailsList } = accountTransactionsMutations;

  // reload lot details only for failed documentIds via global error banner
  useEffect(() => {
    if (lotDetailErrorDocumentIds.length) {
      setDocumentIds(lotDetailErrorDocumentIds);
    }
  }, [lotDetailErrorDocumentIds]);

  useEffect(() => {
    // TODO: filtered fetched lot details
    const itemsForCurrentPage = items.filter(
      (_, index) => !isDesktop || filterItems(index)
    );

    const liveStockDocumentIds = itemsForCurrentPage
      .filter((group) => isLivestock(group[1]))
      .map((group) => group[1].documentId)
      .filter((id): id is string => id !== null);

    setDocumentIds(liveStockDocumentIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, isDesktop, items]);

  useEffect(() => {
    if (account_id && documentIds.length > 0 && isLotDetailsFeatureOn) {
      getLotDetails({
        variables: {
          accountNumber: account_id,
          documentNumbers: documentIds,
        },
      });
    }
  }, [account_id, isLotDetailsFeatureOn, documentIds, getLotDetails]);

  useEffect(() => {
    if (account_id) {
      documentIds.forEach((documentId) => {
        editLotDetailsList(account_id, documentId, {
          lotDetailsLoading,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLotDetailsList, lotDetailsLoading, documentIds]);

  useEffect(() => {
    if (account_id) {
      lotDetailsData?.getLotDetails?.forEach((lotDetails) => {
        if (lotDetails.documentNumber) {
          editLotDetailsList(account_id, lotDetails.documentNumber, {
            lotDetailsResponse: lotDetails,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLotDetailsList, lotDetailsData?.getLotDetails]);

  useEffect(() => {
    if (lot_details_expanded_all === false) {
      unSelectAll();
    }

    if (lot_details_expanded_all === true) {
      selectAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot_details_expanded_all]);

  // reset lot_details_expanded_all when individual accordions are toggled
  const onAccordionToggle = (itemId: string) => {
    toggle(itemId);
    editSelectedAccount({
      lot_details_expanded_all: undefined,
    });
  };

  return (
    <QueryCard
      loading={loading}
      error={error}
      handleReload={refetch}
      wrapper={Wrapper}
    >
      {items.length ? (
        <>
          {items
            .filter((_, index) => !isDesktop || filterItems(index))
            .map((itemGroup, index) => {
              const itemId = itemGroup[0];
              const item = itemGroup[1];
              if (flags['NG-2721-livestock-sale-lot-details']) {
                return (
                  <Transaction
                    key={`${itemId}_${index}`}
                    group={item}
                    isAccordion={isLivestock(item)}
                    isExpanded={isSelected(itemId)}
                    onToggleExpand={() => onAccordionToggle(itemId)}
                    onDownload={onPress}
                    onReloadLotDetails={() =>
                      // reload lot details for single document
                      setDocumentIds(item.documentId ? [item.documentId] : [])
                    }
                  />
                );
              }
              return (
                <GroupedTransactions
                  key={index}
                  onPress={onPress}
                  group={itemGroup[1]}
                />
              );
            })}
          {isDesktop && items.length > 10 && (
            <TablePagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRecords={items.length}
              canNextPage={pageIndex + 1 < numberOfPages}
              canPreviousPage={pageIndex > 0}
              setPageSize={(size) => onSetPageSize(size, segmentProperties)}
              previousPage={() => onPrevPage(segmentProperties)}
              nextPage={() => onNextPage(segmentProperties)}
            />
          )}
        </>
      ) : (
        <NoTransactions />
      )}
    </QueryCard>
  );
};

export default TransactionGroupView;
