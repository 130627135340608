import { CustomerAccountsSummary_getAccountsSummary as AccountSummary } from 'types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUp } from 'types/generated/CustomerAccountsSummarySplitUp';
import useCustomerAccountsSummaryQuery from '../../hooks/gql/queries/useGetAccountsSummaryQuery';
import useCustomerAccountsSummarySplitUpQuery from '../../hooks/gql/queries/useGetAccountSummarySplitUpQuery';

const useAccountDetailPanels = (
  accountId: string,
  accountType?: string | null
) => {
  const filterByAccountId = (
    data: (AccountSummary | AccountSummarySplitUp | null)[] | null | undefined
  ) => {
    return (
      data?.find((accData) =>
        accData?.AccountNumber
          ? parseInt(accountId, 10) === Number(accData?.AccountNumber)
          : false
      ) ?? null
    );
  };

  const [accountsSummaryData, loadingAccountsSummary] =
    useCustomerAccountsSummaryQuery({
      account_ids: [accountId],
      skip: false,
    });

  const [accountsSummarySplitUpData, loadingAccountsSummarySplitUp] =
    useCustomerAccountsSummarySplitUpQuery({
      accounts: [
        {
          accountNumber: accountId,
          accountType: accountType ?? '',
        },
      ],
      skip: typeof accountType !== 'string',
    });

  return {
    loadingAccountsSummary,
    loadingAccountsSummarySplitUp,
    accountSummary: filterByAccountId(
      accountsSummaryData?.getAccountsSummary
    ) as AccountSummary,
    accountSummarySplitUp: filterByAccountId(
      accountsSummarySplitUpData?.getAccountSummarySplitUp
    ) as AccountSummarySplitUp,
  };
};

export default useAccountDetailPanels;
