import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  InsertProfile,
  InsertProfileVariables,
} from 'types/generated/InsertProfile';

export const INSERT_PROFILE = gql`
  mutation InsertProfile(
    $email: String!
    $familyName: String!
    $fullName: String!
    $givenName: String!
    $mobilePhone: String!
    $onboardedBranchCode: String!
    $preferredName: String!
  ) {
    insertProfile(
      payload: {
        email: $email
        family_name: $familyName
        full_name: $fullName
        given_name: $givenName
        mobile_phone: $mobilePhone
        onboarded_branch_code: $onboardedBranchCode
        preferred_branch_code: $onboardedBranchCode
        preferred_name: $preferredName
      }
    ) {
      id
      onboarded_branch_code
      full_name
      email
    }
  }
`;

type InsertProfileFunction = (
  options?: MutationFunctionOptions<InsertProfile, InsertProfileVariables>
) => Promise<unknown>;

const useInsertProfileMutation = (): [
  InsertProfileFunction,
  {
    data?: InsertProfile | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [mutateInsertProfile, { data, error, loading }] = useMutation<
    InsertProfile,
    InsertProfileVariables
  >(INSERT_PROFILE);

  return [mutateInsertProfile, { data, error, loading }];
};

export default useInsertProfileMutation;
