import { ICalendarYear } from './index';

const formattedCalendarMonths = (): ICalendarYear => {
  const today = new Date();
  const monthNumber = today.getMonth() + 1;

  const months = [
    { title: 'Jan', category: 'month', value: 0, label: 'January' },
    { title: 'Feb', category: 'month', value: 1, label: 'February' },
    { title: 'Mar', category: 'month', value: 2, label: 'March' },
    { title: 'Apr', category: 'month', value: 3, label: 'April' },
    { title: 'May', category: 'month', value: 4, label: 'May' },
    { title: 'Jun', category: 'month', value: 5, label: 'June' },
    { title: 'Jul', category: 'month', value: 6, label: 'July' },
    { title: 'Aug', category: 'month', value: 7, label: 'August' },
    { title: 'Sep', category: 'month', value: 8, label: 'September' },
    { title: 'Oct', category: 'month', value: 9, label: 'October' },
    { title: 'Nov', category: 'month', value: 10, label: 'November' },
    { title: 'Dec', category: 'month', value: 11, label: 'December' },
  ];

  const allMonths = [
    {
      title: 'All',
      category: 'all',
      value: 12,
      label: 'all',
    },
  ];
  const previousYearsMonths = months.slice(monthNumber, months.length);
  const currentYearsMonths = months.slice(0, monthNumber);

  return {
    previousYear: previousYearsMonths,
    currentYear: currentYearsMonths,
    allMonths: allMonths,
  };
};

export default formattedCalendarMonths;
