import React, { FC } from 'react';
import {
  Text,
  Box,
  NamedStyles,
  useTheme,
  useStyles,
} from '@nutrien/bonsai-core';
import { useAccountSummary, useFormatters } from '../../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUp } from '../../../types/generated/CustomerAccountsSummarySplitUp';
import Tabs from '../../Tabs';
import SummaryCard from '../../SummaryCard';
import PanelDrawer from '../PanelDrawer/PanelDrawer';
import useDrawer from '../../../hooks/useDrawer';
import { DrawerLink } from '../DrawerLink';

export interface FinanceMerchandiseLivestockPanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp: AccountSummarySplitUp | null;
  tabs?: {
    filterIndex: number;
    tabs: string[];
    onTabChange: (index: number) => void;
  };
  panelStyles?: NamedStyles<Record<string, undefined>>;
}

export const FinanceMerchandiseLivestockPanel: FC<
  FinanceMerchandiseLivestockPanelProps
> = ({ summary, summarySplitUp, tabs, panelStyles }): JSX.Element => {
  const { renderNoneIfZero } = useFormatters();
  const theme = useTheme();
  const styles = useStyles(panelStyles ?? {});
  const onLivestockTab = tabs && tabs.filterIndex === 1;
  const {
    proceedsPending,
    finance: { interestBearingBalance, nonInterestBearingBalance },
    showMoreLinks,
  } = useAccountSummary(summary, onLivestockTab);
  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      {tabs && (
        <Tabs
          containerStyle={{ paddingBottom: theme.spacing(2) }}
          testID="finance-merchandise-livestock-filter"
          onPress={tabs.onTabChange}
          selectedIndex={tabs.filterIndex}
          tabs={tabs.tabs}
        />
      )}
      <SummaryCard
        heading={onLivestockTab ? 'Livestock' : 'Merchandise'}
        summaryRow={[
          {
            label: (
              <Box style={styles.label}>
                <Text style={styles.labelText}>Interest bearing balance</Text>
              </Box>
            ),
            content: renderNoneIfZero(interestBearingBalance),
            button: (
              <DrawerLink
                enabled={showMoreLinks}
                drawerInfo={{
                  name: 'Interest bearing balance',
                  data: {
                    amount: interestBearingBalance,
                    summarySplitUp,
                    isLivestock: onLivestockTab,
                  },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: onLivestockTab ? 'Livestock' : 'Merchandise',
                }}
              />
            ),
          },
          {
            label: (
              <Box style={styles.label}>
                <Text style={styles.labelText}>
                  Non-interest bearing balance
                </Text>
              </Box>
            ),
            content: renderNoneIfZero(nonInterestBearingBalance),
            button: (
              <DrawerLink
                enabled={showMoreLinks}
                drawerInfo={{
                  name: 'Non-interest bearing balance',
                  data: {
                    amount: nonInterestBearingBalance,
                    onLivestockTab,
                    summarySplitUp,
                  },
                }}
                onDrawerOpen={onDrawerOpen}
                segmentInfo={{
                  account_id: accountId,
                  tab_view: onLivestockTab ? 'Livestock' : 'Merchandise',
                }}
              />
            ),
          },
          ...(onLivestockTab
            ? [
                {
                  label: (
                    <Box style={styles.label}>
                      <Text style={styles.labelText}>Pending proceeds</Text>
                    </Box>
                  ),
                  content: renderNoneIfZero(proceedsPending),
                  ...(typeof proceedsPending === 'number' && proceedsPending < 0
                    ? {
                        description: (
                          <Box marginTop={0.5}>
                            <Text style={styles.info}>
                              This will not reduce any amount due
                            </Text>
                          </Box>
                        ),
                      }
                    : undefined),
                  button: (
                    <DrawerLink
                      enabled={
                        !!(
                          showMoreLinks &&
                          proceedsPending &&
                          proceedsPending < 0
                        )
                      }
                      drawerInfo={{
                        name: 'Pending proceeds',
                        data: { amount: proceedsPending, summarySplitUp },
                      }}
                      onDrawerOpen={onDrawerOpen}
                      segmentInfo={{
                        account_id: accountId,
                        tab_view: 'Livestock',
                      }}
                    />
                  ),
                },
              ]
            : []),
        ]}
        testID="account-details-finance-merchandise-livestock-panel"
      />
      <PanelDrawer
        onClose={(closeMethod: string) =>
          onDrawerClose(closeMethod, {
            tab_view: onLivestockTab ? 'Livestock' : 'Merchandise',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};
