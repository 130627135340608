import React from 'react';
import { Text, useStyles, Box, BoxProps, Button } from '@nutrien/bonsai-core';
import { ViewStyle } from 'react-native';
import ErrorIcon from './Error';
import NoSearchIcon from './NoSearch';
import NoDocumentsIcon from './NoDocuments';
import NoCartItemsIcon from './NoCartItems';

export interface EmptyStateProps extends BoxProps {
  action?: string;
  alert?: string;
  message?: string;
  onPress?: () => void;
  type?: 'no-search' | 'no-documents' | 'no-cart-items' | 'error';
  containerStyle?: ViewStyle;
}
const EmptyState = (
  {
    action,
    alert,
    message,
    onPress,
    type,
    containerStyle,
    ...otherProps
  }: EmptyStateProps = {
    onPress: (): null => null,
    type: 'no-documents',
  }
): JSX.Element => {
  const styles = useStyles((theme) => ({
    button: {
      fontSize: theme.Text.fontSize.medium,
      textAlign: 'center',
    },
    alert: {
      color: theme.colors.grey[600],
      textAlign: 'center',
      paddingBottom: message ? theme.spacing(1.25) : theme.spacing(0.5),
    },
    message: {
      color: theme.colors.grey[600],
      textAlign: 'center',
    },
    view: {
      alignItems: 'center',
      maxWidth: 304,
    },
  }));

  let Icon;
  switch (type) {
    case 'error':
      Icon = ErrorIcon;
      break;
    case 'no-search':
      Icon = NoSearchIcon;
      break;
    case 'no-cart-items':
      Icon = NoCartItemsIcon;
      break;
    case 'no-documents':
      Icon = NoDocumentsIcon;
      break;
    default:
      Icon = undefined;
  }

  // The !! prevents error `Unexpected text node: . A text node cannot be a child of a <View>.
  // https://stackoverflow.com/questions/38327133/react-native-unexpected-view-type-nested-under-text-node

  return (
    <Box style={[styles.view, containerStyle]} {...otherProps}>
      {Icon && <Icon size={100} />}
      {!!alert && (
        <Text bodyBold testID="alert" style={styles.alert}>
          {alert}
        </Text>
      )}
      {!!message && (
        <Text testID="message" style={styles.message}>
          {message}
        </Text>
      )}
      {!!action && (
        <Button
          testID="action"
          style={styles.button}
          variant="ghost"
          title={action}
          onPress={onPress}
        />
      )}
    </Box>
  );
};

export default EmptyState;
