import {
  Box,
  Icon,
  NamedStyles,
  Text,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary, useFormatters } from '../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../types/generated/CustomerAccountsSummary';
import SummaryCard from '../SummaryCard';

export interface TradingSummaryProps {
  accountDisplayName: string;
  isLivestock?: boolean;
  summary?: AccountSummaryData | null;
  summaryStyles?: NamedStyles<Record<string, undefined>>;
  testID?: string;
  handleClickViewDetails: () => void;
}

const TradingSummary: FC<TradingSummaryProps> = ({
  accountDisplayName,
  isLivestock,
  summary,
  summaryStyles,
  testID,
  handleClickViewDetails,
}) => {
  const { renderNoneIfZero, formatDate } = useFormatters();
  const theme = useTheme();
  const styles = useStyles(summaryStyles ?? {});
  const { overdue, nextDue, currentBalance, proceedsPending } =
    useAccountSummary(summary, isLivestock);
  const twoToOneColumnWidth = !overdue.amount && !isLivestock;
  const lastSummaryColumn = isLivestock
    ? {
        label: (
          <Box style={styles.label}>
            <Text style={styles.labelText}>Pending proceeds</Text>
          </Box>
        ),
        content: renderNoneIfZero(proceedsPending),
        ...(typeof proceedsPending === 'number' && proceedsPending < 0
          ? {
              description: (
                <Box marginTop={0.5}>
                  <Text style={styles.info}>
                    This will not reduce any amount due
                  </Text>
                </Box>
              ),
            }
          : undefined),
        testID: 'pending-proceeds-column',
      }
    : {
        label: (
          <Box style={styles.label}>
            <Text style={styles.labelText}>Current balance</Text>
          </Box>
        ),
        content: renderNoneIfZero(currentBalance),
        width: twoToOneColumnWidth ? '33.33%' : undefined,
        testID: 'current-balance-column',
      };

  const nextDueColumn = isLivestock
    ? {
        label: (
          <Box style={styles.label}>
            <Text style={styles.labelText}>Next due invoices</Text>
          </Box>
        ),
        content: renderNoneIfZero(nextDue.amount),
        testID: 'next-due-livestock-column',
      }
    : nextDue.toBeFinalised
    ? {
        label: (
          <Box style={styles.label} flexDirection="row" alignItems="flex-start">
            <Text style={styles.infoHeading}>
              Next due{' '}
              {nextDue.invoiceDate &&
                formatDate(
                  nextDue.invoiceDate.toISOString() ?? '',
                  'dd MMM yyyy'
                )}
            </Text>
          </Box>
        ),
        description: (
          <Text style={styles.info}>
            To be finalised at end of {nextDue.toBeFinalisedMonth}
          </Text>
        ),
        testID: 'next-due-next-month-column',
        width: twoToOneColumnWidth ? '66.67%' : undefined,
      }
    : {
        label: (
          <Box style={styles.label}>
            <Text style={styles.labelText}>
              Next due{' '}
              {nextDue.invoiceDate &&
                formatDate(nextDue.invoiceDate.toISOString(), 'dd MMM yyyy')}
            </Text>
          </Box>
        ),
        content: renderNoneIfZero(nextDue.amount),
        width: twoToOneColumnWidth ? '66.67%' : undefined,
        testID: 'next-due-current-month-column',
      };

  return (
    <SummaryCard
      heading={accountDisplayName}
      button={{
        label: `View ${accountDisplayName.toLowerCase()}`,
        testID: `view-details-${testID}`,
        onPress: handleClickViewDetails,
      }}
      summaryRow={[
        ...(overdue.amount
          ? [
              {
                label: (
                  <Box
                    style={styles.label}
                    flexDirection="row"
                    alignItems="flex-start"
                  >
                    <Icon
                      name="error-outline"
                      color={theme.auColors.functional.error.dark}
                      size={24}
                      style={styles.icon}
                    />
                    <Text style={styles.errorBold}>
                      Overdue
                      {isLivestock && ' invoices'}
                    </Text>
                  </Box>
                ),
                content: (
                  <Text style={styles.error} bodyBold>
                    {renderNoneIfZero(overdue.amount)}
                  </Text>
                ),
                description: (
                  <Box marginTop={0.5}>
                    <Text style={styles.error}>incurring interest</Text>
                  </Box>
                ),
                testID: 'overdue-column',
              },
            ]
          : []),
        { ...nextDueColumn },
        { ...lastSummaryColumn },
      ]}
      testID={testID}
    />
  );
};

export default TradingSummary;
