import { Box, NamedStyles, Text, useStyles } from '@nutrien/bonsai-core';
import React, { FC } from 'react';
import { useAccountSummary, useFormatters } from '../../../hooks';
import { CustomerAccountsSummary_getAccountsSummary as AccountSummaryData } from '../../../types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUpData } from 'types/generated/CustomerAccountsSummarySplitUp';

import { SkeletonPill } from '../../Skeleton';
import SummaryCard from '../../SummaryCard';
import PanelDrawer from '../PanelDrawer/PanelDrawer';
import useDrawer from '../../../hooks/useDrawer';
import { DrawerLink } from '../DrawerLink';

export interface TradingNextDuePanelProps {
  summary?: AccountSummaryData | null;
  summarySplitUp?: AccountSummarySplitUpData | null;
  creditAmount?: number | null;
  expensesAmount?: number | null;
  loading?: boolean;
  panelStyles?: NamedStyles<Record<string, undefined>>;
}

const TradingNextDuePanel: FC<TradingNextDuePanelProps> = ({
  summary,
  summarySplitUp,
  loading,
  creditAmount,
  expensesAmount,
  panelStyles,
}) => {
  const { renderNoneIfZero, formatDate } = useFormatters();
  const styles = useStyles(panelStyles ?? {});
  const { nextDue, showMoreLinks } = useAccountSummary(summary);
  const { drawerInfo, onDrawerOpen, onDrawerClose } = useDrawer();
  const accountId = summary?.AccountNumber?.toString() ?? '';

  return (
    <>
      <SummaryCard
        heading="Next due"
        summaryRow={[
          ...(loading
            ? [
                {
                  label: <SkeletonPill width="100" />,
                  content: <SkeletonPill width="200" title="Loading credits" />,
                  button: showMoreLinks ? (
                    <SkeletonPill width="100" />
                  ) : undefined,
                },
              ]
            : [
                {
                  label: (
                    <Box
                      style={styles.label}
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Text style={styles.labelText}>
                        Credits from 01 to {nextDue.creditsExpensesLastDay}
                      </Text>
                    </Box>
                  ),
                  content: renderNoneIfZero(creditAmount),
                  button: (
                    <DrawerLink
                      enabled={showMoreLinks}
                      drawerInfo={{
                        name: 'Credits',
                        data: {
                          amount: creditAmount,
                          lastDay: nextDue.creditsExpensesLastDay,
                          summarySplitUp,
                        },
                      }}
                      onDrawerOpen={onDrawerOpen}
                      segmentInfo={{
                        account_id: accountId,
                        tab_view: 'Merchandise',
                      }}
                    />
                  ),
                  testID: 'credits-column',
                },
              ]),
          ...(loading
            ? [
                {
                  label: <SkeletonPill width="100" />,
                  content: (
                    <SkeletonPill width="200" title="Loading expenses" />
                  ),
                  button: showMoreLinks ? (
                    <SkeletonPill width="100" />
                  ) : undefined,
                },
              ]
            : [
                {
                  label: (
                    <Box
                      style={styles.label}
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Text style={styles.labelText}>
                        Expenses from 01 to {nextDue.creditsExpensesLastDay}
                      </Text>
                    </Box>
                  ),
                  content: renderNoneIfZero(expensesAmount),
                  button: (
                    <DrawerLink
                      enabled={showMoreLinks}
                      drawerInfo={{
                        name: 'Expenses',
                        data: {
                          amount: expensesAmount,
                          lastDay: nextDue.creditsExpensesLastDay,
                          summarySplitUp,
                        },
                      }}
                      onDrawerOpen={onDrawerOpen}
                      segmentInfo={{
                        account_id: accountId,
                        tab_view: 'Merchandise',
                      }}
                    />
                  ),
                  testID: 'expenses-column',
                },
              ]),
          ...(nextDue.toBeFinalised
            ? [
                {
                  label: (
                    <Box
                      style={styles.label}
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Text style={styles.infoHeading}>
                        Next due{' '}
                        {nextDue.invoiceDate &&
                          formatDate(
                            nextDue.invoiceDate.toISOString() ?? '',
                            'dd MMM yyyy'
                          )}
                      </Text>
                    </Box>
                  ),
                  description: (
                    <Text style={styles.info}>
                      To be finalised at end of {nextDue.toBeFinalisedMonth}
                    </Text>
                  ),
                  testID: 'next-due-next-month-column',
                },
              ]
            : [
                {
                  label: (
                    <Box style={styles.label}>
                      <Text style={styles.labelText}>
                        Next due{' '}
                        {nextDue.invoiceDate &&
                          formatDate(
                            nextDue.invoiceDate.toISOString(),
                            'dd MMM yyyy'
                          )}
                      </Text>
                    </Box>
                  ),
                  content: nextDue.amount ? (
                    <Text style={styles.emphasis} bodyBold>
                      {renderNoneIfZero(nextDue.amount)}
                    </Text>
                  ) : (
                    renderNoneIfZero(nextDue.amount)
                  ),
                  button: (
                    <DrawerLink
                      enabled={showMoreLinks}
                      drawerInfo={{
                        name: 'Next due',
                        data: {
                          amount: nextDue.amount,
                          invoiceDate: nextDue.invoiceDate,
                          lastDay: nextDue.creditsExpensesLastDay,
                          summarySplitUp,
                        },
                      }}
                      onDrawerOpen={onDrawerOpen}
                      segmentInfo={{
                        account_id: accountId,
                        tab_view: 'Merchandise',
                      }}
                    />
                  ),
                  testID: 'next-due-current-month-column',
                },
              ]),
        ]}
        testID="account-details-trading-next-due-panel"
      />
      <PanelDrawer
        onClose={(closeMethod) =>
          onDrawerClose(closeMethod, {
            tab_view: 'Merchandise',
            account_id: accountId,
          })
        }
        drawerInfo={drawerInfo}
      />
    </>
  );
};

export default TradingNextDuePanel;
