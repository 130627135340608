import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import { GetPdfZip, GetPdfZipVariables } from 'types/generated/GetPdfZip';

export const GET_PDF_ZIP = gql`
  mutation GetPdfZip(
    $accountNumber: String!
    $fromDate: String!
    $toDate: String!
    $ruralcoAccNumber: String
    $documentNumbers: [String]!
  ) {
    getPdfZip(
      payload: {
        accountNumber: $accountNumber
        fromDate: $fromDate
        toDate: $toDate
        ruralcoAccNumber: $ruralcoAccNumber
        documentNumbers: $documentNumbers
      }
    )
  }
`;

type GetPdfZipFunction = (
  options?: MutationFunctionOptions<GetPdfZip, GetPdfZipVariables>
) => Promise<unknown>;

const useGetPdfZip = (): [
  GetPdfZipFunction,
  {
    data?: GetPdfZip | null;
    error: ApolloError | undefined;
    loading: boolean;
  }
] => {
  const [getPdfZip, { data, error, loading }] = useMutation<
    GetPdfZip,
    GetPdfZipVariables
  >(GET_PDF_ZIP);

  return [getPdfZip, { data, error, loading }];
};

export default useGetPdfZip;
