import { ApolloError } from '@apollo/client';
import { useCustomerAccountWithFinanceQuery } from '../../hooks/gql/queries/useCustomerAccountsQuery';
import {
  CustomerAccountWithFinance,
  CustomerAccountWithFinance_sords_customer_account as AccountData,
  CustomerAccountWithFinance_sords_customer_account_finance as FinanceData,
  CustomerAccountWithFinance_customer_customer_statement as StatementData,
  CustomerAccountWithFinance_getAccountSummary as AccountSummaryData,
} from '../../types/generated/CustomerAccountWithFinance';
import { CustomerAccountsSummary } from '../../types/generated/CustomerAccountsSummary';
import {
  CustomerAccounts_sords_customer_account as CustomerAccount,
  CustomerAccounts,
} from 'types/generated/CustomerAccounts';
import { useFormatters } from '../../hooks';
import { useState } from 'react';
import { AccountTypeToDisplayNameMap } from '../../screens/Account/useAccountOptions';
import useCustomerAccountsSummaryQuery from '../../hooks/gql/queries/useGetAccountsSummaryQuery';

export type AccountType = 'trading' | 'finance' | 'prepay';

interface UseAccountOverviewProps {
  account_id: string;
  account_ids?: string[];
  newAccountOverview?: boolean;
}

export type TileType =
  | 'FinanceOneSection'
  | 'FinanceTwoSections'
  | 'Prepay'
  | 'Trading'
  | 'N/A';

interface AccountView {
  tileType: TileType;
}

interface AccountWithFinance {
  accountData: AccountData;
  financeData: FinanceData;
  statementData: StatementData;
  accountSummary: AccountSummaryData | null;
  accountView: AccountView;
}

interface UseAccountOverview {
  loading: boolean;
  error?: ApolloError;
  data?: CustomerAccounts;
  handleReload: () => void;
  getAccountTypeDescription: (account: CustomerAccount) => string;
  accountWithFinance: AccountWithFinance | undefined;
  accountsSummary: CustomerAccountsSummary['getAccountsSummary'] | undefined;
  getTileType: (
    name1: string | null | undefined,
    account_type: string | null | undefined
  ) => TileType;
}

const useAccountOverview = ({
  account_id,
  account_ids,
  newAccountOverview = false,
}: UseAccountOverviewProps): UseAccountOverview => {
  const { formatCapitalize } = useFormatters();
  const [accountWithFinance, setAccountWithFinance] = useState<
    AccountWithFinance | undefined
  >();
  const [accountsSummary, setAccountsSummary] = useState<
    CustomerAccountsSummary['getAccountsSummary'] | undefined
  >();

  const getTileType = (
    name1: string | null | undefined,
    account_type: string | null | undefined
  ): TileType => {
    if (
      name1?.startsWith('LDT/LF') ||
      name1?.startsWith('AIO/SCF') ||
      name1?.startsWith('LDT/SCF')
    ) {
      return 'FinanceOneSection';
    }
    if (
      name1?.startsWith('AIO/SF') ||
      name1?.startsWith('AIO/LF') ||
      name1?.startsWith('LDT/SF') ||
      account_type?.toLowerCase() === 'finance'
    ) {
      return 'FinanceTwoSections';
    }
    if (account_type?.toLowerCase() === 'trading') {
      return 'Trading';
    }
    if (account_type?.toLowerCase() === 'prepay') {
      return 'Prepay';
    }
    return 'N/A';
  };

  const transformCustomerAccountWithFinance = (
    data: CustomerAccountWithFinance
  ) => {
    const {
      sords_customer_account,
      sords_customer_account_finance,
      customer_customer_statement,
      getAccountSummary,
    } = data;
    // These should always be 1 to 1 (arrays with 1 item each)
    const [accountData] = sords_customer_account;
    const [financeData] = sords_customer_account_finance;
    // except maybe this one.. although we would still be choosing only 1 (the latest statementData)
    const [statementData] = customer_customer_statement;

    setAccountWithFinance({
      accountData,
      financeData,
      statementData,
      accountSummary: getAccountSummary,
      accountView: {
        tileType: getTileType(accountData?.name1, accountData?.account_type),
      },
    });
  };

  const [data, loading, error, refetch] = useCustomerAccountWithFinanceQuery({
    account_id,
    onCompleted: transformCustomerAccountWithFinance,
  });

  const transformCustomerAccountsWithSummary = (
    accountsSummaryData: CustomerAccountsSummary
  ) => {
    const { getAccountsSummary } = accountsSummaryData;

    if (getAccountsSummary) {
      setAccountsSummary(getAccountsSummary);
    }
  };

  const [_accountsSummaryData, accountsSummaryLoading, accountsSummaryError] =
    useCustomerAccountsSummaryQuery({
      account_ids:
        account_ids && account_ids?.length > 0 ? account_ids : [account_id],
      skip: !newAccountOverview,
      onCompleted: transformCustomerAccountsWithSummary,
    });

  const handleReload = () => refetch();

  const getAccountTypeDescription = (account: CustomerAccount) => {
    const { account_type, name1 } = account;
    const type = account_type?.toLowerCase() as AccountType;

    // eslint-disable-next-line security/detect-object-injection
    const displayName = AccountTypeToDisplayNameMap[type];
    if (displayName) {
      return `${displayName} Account`;
    }

    const isSeasonal = type === 'finance' && name1?.includes('SCF');

    if (isSeasonal) {
      return 'Seasonal Crop Finance Account';
    }

    return `${formatCapitalize(account_type || '')} Account`;
  };

  return {
    loading: newAccountOverview ? loading || accountsSummaryLoading : loading,
    error: newAccountOverview ? error || accountsSummaryError : error,
    data,
    handleReload,
    getAccountTypeDescription,
    accountWithFinance,
    accountsSummary,
    getTileType,
  };
};

export default useAccountOverview;
