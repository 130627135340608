import React from 'react';
import { Box } from '@nutrien/bonsai-core';

import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';
import addMonths from 'date-fns/addMonths';
import isSameDay from 'date-fns/isSameDay';
import differenceInYears from 'date-fns/differenceInYears';
import sub from 'date-fns/sub';

import format from 'date-fns/format';
import { useTransaction } from '../../context/TransactionContext';
import EmptyState from '../EmptyState';

const isSearchingByMonth = (fromDate: Date, toDate: Date) => {
  return (
    isFirstDayOfMonth(fromDate) && isSameDay(addMonths(fromDate, 1), toDate)
  );
};

const NoTransactions = (): JSX.Element => {
  const { state, setDateRange } = useTransaction();
  const { toDate, fromDate } = state;
  const isSearchingByAll = differenceInYears(toDate, fromDate) === 1;
  const now = new Date();

  const messsage = isSearchingByMonth(fromDate, toDate)
    ? `You have no items for ${format(fromDate, 'MMMM yyyy')}`
    : 'You have no items for this period';

  return (
    <Box pt={2} pb={4} alignItems="center">
      <EmptyState
        message={messsage}
        type="no-documents"
        alert={'No items'}
        action={isSearchingByAll ? undefined : 'Remove date selection'}
        onPress={() =>
          setDateRange({ toDate: now, fromDate: sub(now, { years: 1 }) })
        }
      />
    </Box>
  );
};

export default NoTransactions;
