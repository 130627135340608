import React, { ChangeEvent, useState } from 'react';
import { useTheme } from '@nutrien/bonsai-core';
import SelectWrapper from './SelectWrapper';
import { SelectProps } from '.';
import { TxSortOptions } from '../../constants';
import { ViewStyle } from 'react-native';

const Select = ({
  error,
  value,
  handleSelect,
  options,
  containerStyle,
  testID,
  loading,
  name,
  disabled,
  readOnly,
}: SelectProps): JSX.Element => {
  const theme = useTheme();
  const [focused, setFocused] = useState(false);
  const isDisabled = disabled || readOnly;

  const styles = {
    selectStyle: {
      borderColor: theme.auColors.neutral[300],
      borderRadius: 4,
      backgroundColor: 'transparent',
      fontFamily: theme.typography.fontFamily.regular,
      fontSize: theme.typography.fontSize.medium,
      color: disabled
        ? theme.auColors.neutral[500]
        : theme.auColors.neutral[800],
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(5),
      WebkitAppearance: 'none' as const,
      minHeight: 40,
      width: '100%',
      cursor: 'pointer',
      outline: 'none',
    },
    focusedBorderStyle: {
      borderWidth: 1,
      borderColor: error
        ? theme.auColors.functional.error
        : theme.auColors.secondary[400],
      borderRadius: theme.shape.borderRadius,
    } as ViewStyle,
    defaultBorderStyle: {
      borderWidth: 1,
      borderColor: error
        ? theme.auColors.functional.error.dark
        : theme.auColors.neutral[500],
      borderRadius: theme.shape.borderRadius,
      bottom: error ? 2 : 0,
    } as ViewStyle,
    boxShadow: [
      containerStyle,
      {
        boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.light}`,
      },
    ] as ViewStyle,
  };

  const handleSelectWeb = (e: ChangeEvent<HTMLSelectElement>) => {
    handleSelect(e.target.value as TxSortOptions);
  };

  return (
    <SelectWrapper
      name={name}
      loading={loading}
      containerStyle={
        error ? containerStyle : focused ? styles.boxShadow : containerStyle
      }
      error={error}
      disabled={disabled}
      readOnly={readOnly}
      borderStyle={
        focused ? styles.focusedBorderStyle : styles.defaultBorderStyle
      }
    >
      <select
        disabled={isDisabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        id={name}
        name={name}
        style={styles.selectStyle}
        value={value}
        onChange={handleSelectWeb}
        data-testid={testID || name}
        aria-describedby={`${name}-error`}
      >
        {options.map(({ value: val, label }, i) => (
          <option key={i} value={val}>
            {label}
          </option>
        ))}
      </select>
    </SelectWrapper>
  );
};

export default Select;
