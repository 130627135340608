import React, { FC, useState } from 'react';
import { View } from 'react-native';
import {
  Box,
  Card,
  Collapsable,
  Icon,
  Text,
  useStyles,
  useTheme,
} from '@nutrien/bonsai-core';

const Disclaimer: FC = () => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const styles = useStyles(() => {
    return {
      importantNoteText: {
        color: theme.auColors.neutral[800],
        fontWeight: '400',
      },
      disclaimerText: {
        fontSize: 11,
        lineHeight: 12,
      },
      pressable: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      iconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
  });

  return (
    <View>
      <Card onPress={() => setExpanded(!expanded)}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          py={1}
          px={2}
        >
          <Text style={styles.importantNoteText}>Important Note</Text>
          <Icon
            name={expanded ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
            size={30}
            color={theme.auColors.neutral[600]}
            containerStyle={styles.iconContainer}
          />
        </Box>
        <Collapsable expanded={expanded}>
          <Box px={2} pb={2}>
            <Text style={styles.disclaimerText}>
              Our advice has been prepared in reliance upon information provided
              by you and other external sources and we do not accept any
              liability arising from the accuracy of that information. Our
              advice assumes that all environmental conditions will remain
              within average ranges and makes no allowance for adverse weather
              patterns and events or pest infestation. You acknowledge and agree
              that (i) we make no representations with respect to any future
              matters, including the outcome or performance of your crops,
              pastures or animal; (ii) we shall not be liable for any loss or
              damage however caused arising from our advice; (iii) you release
              us from any claims arising from the provision of our advice; and
              (iv) any implied condition, warranty or guarantee that would
              otherwise apply to our advice is excluded to the fullest extent
              permitted by law. Where our liability cannot be excluded, it is
              limited at our option to supplying the relevant services again, or
              paying the cost of that supply.
            </Text>
          </Box>
        </Collapsable>
      </Card>
    </View>
  );
};

export default Disclaimer;
