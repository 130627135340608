import { ApolloError } from '@apollo/client';
import { CustomerProfiles } from 'types/generated/CustomerProfiles';
import {
  RegistrationStatus,
  CustomerProfile,
} from 'types/generated/RegistrationStatus';
import { Branches } from 'types/generated/Branches';
import useCustomerFormatter from './useCustomerFormatter';
import { useStaff } from '../../context/StaffContext';
import {
  useCustomerProfilesWithAccountsQuery,
  useRegistrationStatusQuery,
  useRegistrationStatusTotalItemsQuery,
  useBranchesQuery,
  CustomerProfileQueryRefetch,
  RefetchRegistrationStatus,
  RefetchRegistrationStatusTotalItems,
  useNavigation,
  useSort,
} from '../../hooks';
import { SelectOptions } from '../Select';
import { CustomerInfo, HandleRowPress, PAGE_SIZE } from '.';
import { routeConfig } from '../../routes/shared';

export interface CustomerList {
  preferredBranchesList: SelectOptions[];
  getPreferredBranchesList: (
    customersData: CustomerProfiles | undefined,
    branchesData: Branches | undefined
  ) => SelectOptions[];
  branchesLoading: boolean;
  branchesError: ApolloError | undefined;
  customersLoading: boolean;
  customersError: ApolloError | undefined;
  customersRefetch: CustomerProfileQueryRefetch;
  registeredUsers: CustomerInfo[];
  handleRowPress: HandleRowPress;
  getRegistrationStatusLoading: boolean;
  getRegistrationStatusError: ApolloError | undefined;
  getRegistrationStatusRefetch: RefetchRegistrationStatus;
  totalUsers: number;
  getRegistrationStatusTotalItemsLoading: boolean;
  getRegistrationStatusTotalItemsError: ApolloError | undefined;
  getRegistrationStatusTotalItemsRefetch: RefetchRegistrationStatusTotalItems;
}

type CustomerFormatter = (customer: CustomerProfile) => CustomerInfo;

const useCustomerList = (): CustomerList => {
  const {
    state: { branchFilter, registrationStatusFilter, registeredUsersPageIndex },
  } = useStaff();
  const { navigate } = useNavigation();

  const { formatCustomer } = useCustomerFormatter();
  const { sortBranchList } = useSort();

  const formatCustomers = (
    getRegistrationStatusData: RegistrationStatus | undefined,
    format: CustomerFormatter
  ) => {
    if (
      !getRegistrationStatusData ||
      getRegistrationStatusData.getRegistrationStatus.length === 0
    ) {
      return [];
    }
    const customers = getRegistrationStatusData.getRegistrationStatus.reduce(
      (acc, { customerProfile }) =>
        acc.concat(format(customerProfile as CustomerProfile)),
      [] as CustomerInfo[]
    );

    return customers;
  };

  const getPreferredBranchesList = (
    customersData: CustomerProfiles | undefined,
    branchesData: Branches | undefined
  ): SelectOptions[] => {
    if (!customersData || customersData.customer_profile.length === 0) {
      return [];
    }

    if (!branchesData || branchesData.sords_branch.length === 0) {
      return [];
    }

    const onBoardedBranchCodeArray = customersData.customer_profile.reduce(
      (acc, { onboarded_branch_code }) => {
        if (onboarded_branch_code && !acc.includes(onboarded_branch_code)) {
          return acc.concat(onboarded_branch_code);
        }
        return acc;
      },
      [] as string[]
    );

    const branchesList = onBoardedBranchCodeArray.reduce((acc, branchCode) => {
      const branch = branchesData.sords_branch.find(
        (item) => item.branch_code === branchCode
      );
      if (branch) {
        return acc.concat({
          label: branch?.branch_name || '',
          value: branchCode,
        });
      }
      return acc;
    }, [] as SelectOptions[]);

    const sortedBranchesList = sortBranchList(branchesList);

    return [
      { label: 'All Branches', value: '' },
      ...sortedBranchesList,
    ] as SelectOptions[];
  };

  const [branchesData, branchesLoading, branchesError] = useBranchesQuery();
  const [customersData, customersLoading, customersError, customersRefetch] =
    useCustomerProfilesWithAccountsQuery();

  const [
    getRegistrationStatusData,
    getRegistrationStatusLoading,
    getRegistrationStatusError,
    getRegistrationStatusRefetch,
  ] = useRegistrationStatusQuery({
    direction: 'ASC',
    limit: PAGE_SIZE,
    page: registeredUsersPageIndex,
    sort: ['given_name', 'family_name'],
    branchFilter,
    registrationStatusFilter,
  });

  const preferredBranchesList = getPreferredBranchesList(
    customersData,
    branchesData
  );

  const registeredUsers = formatCustomers(
    getRegistrationStatusData,
    formatCustomer
  );

  const [
    getRegistrationStatusTotalItemsData,
    getRegistrationStatusTotalItemsLoading,
    getRegistrationStatusTotalItemsError,
    getRegistrationStatusTotalItemsRefetch,
  ] = useRegistrationStatusTotalItemsQuery({
    branchFilter,
    registrationStatusFilter,
  });

  const totalUsers =
    getRegistrationStatusTotalItemsData?.getRegistrationStatusTotalItems
      ?.total ?? 0;

  const handleRowPress = (customerId: string) => {
    navigate(
      routeConfig.UserSummary.getNavigateProps({
        customerId,
      })
    );
  };

  return {
    preferredBranchesList,
    getPreferredBranchesList,
    branchesLoading,
    branchesError,
    customersLoading,
    customersError,
    customersRefetch,
    registeredUsers,
    handleRowPress,
    getRegistrationStatusLoading,
    getRegistrationStatusError,
    getRegistrationStatusRefetch,
    totalUsers,
    getRegistrationStatusTotalItemsLoading,
    getRegistrationStatusTotalItemsError,
    getRegistrationStatusTotalItemsRefetch,
  };
};

export default useCustomerList;
