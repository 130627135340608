import React from 'react';
import { Text, useDeviceType, useStyles } from '@nutrien/bonsai-core';

export const AccountOverviewHeader = ({
  accountId,
  description,
}: {
  accountId?: string;
  description?: string;
}) => {
  const device = useDeviceType();

  const styles = useStyles((theme) => {
    return {
      header: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
      },
      tabContainer: {
        padding: theme.spacing(2),
      },
      headingSmall:
        theme.auTypography[device.isDesktop ? 'desktop' : 'mobile']
          .headingSmall,
    };
  });

  return (
    <Text
      style={[styles.headingSmall, styles.header]}
      h2
    >{`${description} (#${accountId})`}</Text>
  );
};
