import React from 'react';
import { ApolloError } from '@apollo/client';
import { Box, Text } from '@nutrien/bonsai-core';
import isempty from 'lodash.isempty';
import { useStaff } from '../../context/StaffContext';
import Banner from '../Banner';
import theme from '../../theme/theme';
import { Errors } from '.';

const SaveChangesBanner = ({
  graphQLError,
  errorSummary,
}: {
  graphQLError?: ApolloError;
  errorSummary: Errors | null;
}) => {
  const {
    state: { showSaveUserSuccess },
  } = useStaff();

  const styles = {
    saveChangesBanner: {
      marginBottom: theme.spacing(4),
    },
  };

  const showSaveChangesBanner = showSaveUserSuccess || !!graphQLError;
  if (!showSaveChangesBanner || !isempty(errorSummary)) {
    return null;
  }

  return (
    <Banner
      location="section"
      type={graphQLError ? 'errorLight' : 'successLight'}
      visible={showSaveChangesBanner}
      testID={
        graphQLError ? 'save-user-error-banner' : 'save-user-success-banner'
      }
      title={
        graphQLError
          ? 'Oops! We need some more information to create the user profile'
          : 'Changes saved'
      }
      style={styles.saveChangesBanner}
    >
      <Box>
        <Text>
          {graphQLError
            ? graphQLError?.message
            : 'Your changes have been successfully saved.'}
        </Text>
      </Box>
    </Banner>
  );
};
export default SaveChangesBanner;
