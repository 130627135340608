import { Box, Text, useTheme } from '@nutrien/bonsai-core';
import React, { useState } from 'react';
import {
  GestureResponderEvent,
  Pressable,
  View,
  ViewStyle,
} from 'react-native';

interface TabsProps {
  tabs: string[];
  selectedIndex: number;
  onPress: (index: number) => void;
  containerStyle?: ViewStyle;
  testID?: string;
}

interface TabProps {
  title: string;
  isActive: boolean;
  handlePress: (event: GestureResponderEvent) => void;
  width: string;
  testID: string;
}

const Tab = ({ title, isActive, handlePress, width, testID }: TabProps) => {
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const theme = useTheme();
  const styles = {
    container: {
      width,
    },
    tab: {
      borderBottomWidth: 1.5,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: theme.auColors.primary.default,
      paddingVertical: theme.spacing(1.4),
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      paddingBottom: 12.7,
      outline: 'none',
      backgroundColor: theme.auColors.neutral[100],
      zIndex: 1,
    } as ViewStyle,
    tabActive: {
      borderColor: theme.auColors.primary.default,
      backgroundColor: theme.auColors.neutral[300],
      borderBottomWidth: 3,
      paddingBottom: 11.2,
    },
    text: {
      color: theme.auColors.neutral[600],
      fontWeight: 'bold',
    },
    textActive: {
      color: theme.auColors.primary.default,
    },
    focusStyle: {
      shadowOpacity: 10,
      shadowRadius: 5,
      shadowColor: theme.auColors.functional.focused.dark,
      backgroundColor: isActive
        ? theme.auColors.neutral[300]
        : theme.auColors.neutral[100],
    },
    hoverStyle: {
      backgroundColor: theme.auColors.neutral[300],
    },
    pressedStyle: {
      backgroundColor: theme.auColors.neutral[200],
      borderBottomWidth: 2,
      paddingBottom: 12.2,
    },
    containerFocused: {
      zIndex: 2,
    },
  };

  return (
    <View
      style={[styles.container, focused && styles.containerFocused]}
      key={title}
    >
      <Pressable
        testID={testID}
        onPress={handlePress}
        onPressIn={() => setPressed(true)}
        onPressOut={() => setPressed(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - web only
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        style={[
          styles.tab,
          isActive && styles.tabActive,
          focused && styles.focusStyle,
          hovered && styles.hoverStyle,
          pressed && styles.pressedStyle,
        ]}
        accessibilityState={{ selected: isActive }}
        accessibilityRole="tab"
      >
        <Text style={[styles.text, isActive && styles.textActive]}>
          {title}
        </Text>
      </Pressable>
    </View>
  );
};

const Tabs = ({
  tabs,
  onPress,
  selectedIndex,
  containerStyle = {},
  testID = 'tabs',
}: TabsProps): JSX.Element => {
  return (
    <Box
      testID={testID}
      flexDirection="row"
      flexGrow={1}
      style={containerStyle}
    >
      {tabs.map((tab, i) => {
        const handlePress = () => onPress(i);
        const isActive = selectedIndex === i;
        const width = `${100 / tabs.length}%`;

        return (
          <Tab
            testID={`${tab.replace(' ', '-').toLowerCase()}-tab${
              isActive ? '-selected' : ''
            }`}
            width={width}
            key={tab}
            title={tab}
            isActive={isActive}
            handlePress={handlePress}
          />
        );
      })}
    </Box>
  );
};

export default Tabs;
