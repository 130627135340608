import React from 'react';
import { Platform } from 'react-native';
import ContentLoader from 'react-content-loader';
import NativeContentLoader, { Rect } from 'react-content-loader/native';
import { useTheme } from '@nutrien/bonsai-core';

export interface SkeletonPillProps {
  animationSpeed?: number;
  width?: string;
  height?: string;
  title?: string;
}

const SkeletonPill = ({
  animationSpeed = 1,
  width = '100',
  height = '16',
  title = 'Loading...',
}: SkeletonPillProps): JSX.Element => {
  const viewBoxCriteria = width.match(/^[0-9]+$/) && height.match(/^[0-9]+$/);
  const theme = useTheme();
  const contentLoaderProps = {
    speed: animationSpeed,
    backgroundColor: theme.auColors.neutral[400],
    foregroundColor: theme.auColors.neutral[200],
    title,
    width,
    height,
    viewBox: viewBoxCriteria ? `0 0 ${width} ${height}` : undefined,
  };
  const rectProps = {
    rx: theme.shape.borderRadiusMedium,
    width,
    height,
  };
  if (Platform.OS === 'web') {
    return (
      <ContentLoader {...contentLoaderProps} data-testid="skeleton-pill">
        <rect {...rectProps} />
      </ContentLoader>
    );
  }
  return (
    <NativeContentLoader {...contentLoaderProps} testID="skeleton-pill">
      <Rect {...rectProps} />
    </NativeContentLoader>
  );
};

export default SkeletonPill;
