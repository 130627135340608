import {
  ApolloError,
  gql,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';
import {
  SearchStatements,
  SearchStatementsVariables,
} from 'types/generated/SearchStatements';

export interface SearchDocumentsProps {
  accountNumber: string;
  documentType: string;
  fromDate: string;
  toDate: string;
  documentNumber?: string;
  ruralcoAccNumber?: string;
}

export const SEARCH_STATEMENTS = gql`
  query SearchStatements(
    $accountNumber: String!
    $ruralcoAccNumber: String
    $documentType: DocumentType!
    $fromDate: String!
    $toDate: String!
  ) {
    searchDocuments(
      payload: {
        accountNumber: $accountNumber
        ruralcoAccNumber: $ruralcoAccNumber
        documentType: $documentType
        fromDate: $fromDate
        toDate: $toDate
      }
    ) {
      documentId
      issueDate
      accountType
    }
  }
`;

const useSearchStatementsQuery = (): [
  (
    options: QueryLazyOptions<SearchStatementsVariables>
  ) => Promise<LazyQueryResult<SearchStatements, OperationVariables>>,
  SearchStatements | undefined,
  boolean,
  ApolloError | undefined
] => {
  const [searchStatements, { data, error, loading }] = useLazyQuery<
    SearchStatements,
    SearchStatementsVariables
  >(SEARCH_STATEMENTS, {
    fetchPolicy: 'network-only',
  });

  return [searchStatements, data, loading, error];
};

export { useSearchStatementsQuery };
