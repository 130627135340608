import React from 'react';
import { Box, Text, useStyles, useDeviceType } from '@nutrien/bonsai-core';

export interface AccountOverviewCardProps {
  children: JSX.Element | null;
  heading: string;
}

const AccountOverviewCard = ({
  children,
  heading,
}: AccountOverviewCardProps) => {
  const { isDesktop } = useDeviceType();
  const styles = useStyles((theme) => ({
    wrapper: {
      borderTopWidth: 6,
      borderColor: theme.auColors.accent.dark['200'],
      borderRadius: theme.shape.borderRadius,
    },
    headingMedium:
      theme.auTypography[isDesktop ? 'desktop' : 'mobile'].headingMedium,
  }));

  return (
    <Box style={styles.wrapper} p={isDesktop ? 3 : 2}>
      <Text h2 style={styles.headingMedium}>
        {heading}
      </Text>
      {children}
    </Box>
  );
};

export default AccountOverviewCard;
