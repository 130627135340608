import React from 'react';
import { Icon } from '@nutrien/bonsai-core';
import theme from '../../theme/theme';
import { Chip } from '../../components/Chip';
import { shortDate } from '../../utils/recommendationUtils';
import { ChipType, RECOMMENDATION_JOB_STATUS } from '../../constants';

const formatDate = (prefix: string, date: string) => {
  const formattedDate = shortDate(date, false);
  return formattedDate ? `${prefix} ${formattedDate}` : 'No due date';
};

interface Props {
  recDeletedDtm?: string;
  recDueDtm: string;
  recUpdatedDtm: string;
  jobStatus?: string;
}

const getChipProps = ({
  recDeletedDtm,
  recDueDtm,
  recUpdatedDtm,
  jobStatus,
}: Props): { title: string; date: string; variant: ChipType } => {
  let title = 'Due';
  let date = recDueDtm;
  let variant = ChipType.INFO;

  /**  If a reco is deleted, it will override the the status */
  if (recDeletedDtm) {
    title = 'Deleted';
    date = recDeletedDtm;
    variant = ChipType.DANGER;
  } else if (jobStatus === RECOMMENDATION_JOB_STATUS.DISCARDED) {
    title = 'Discarded';
    date = recUpdatedDtm;
    variant = ChipType.WARN;
  }

  return {
    title,
    date,
    variant,
  };
};

const RecommendationStatusChip = ({
  recDeletedDtm,
  recDueDtm,
  recUpdatedDtm,
  jobStatus,
}: Props) => {
  const formatDateProps = getChipProps({
    recDeletedDtm,
    recDueDtm,
    recUpdatedDtm,
    jobStatus,
  });

  return (
    <>
      <Chip
        title={formatDate(formatDateProps.title, formatDateProps.date)}
        variant={formatDateProps.variant}
      />
      <Icon
        name="chevron-right"
        size={30}
        color={theme.auColors.neutral[600]}
      />
    </>
  );
};

export default RecommendationStatusChip;
