import React from 'react';
import { Path, Circle } from 'react-native-svg';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
  <>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88089 16.265H18.9379C19.4529 16.265 19.8704 15.8461 19.8704 15.3294C19.8704 14.8127 19.4529 14.3938 18.9379 14.3938C18.9379 14.3938 18.1386 13.975 18.1386 13.4583C18.1386 12.9416 18.665 12.5227 19.3143 12.5227H20.6697C21.1847 12.5227 21.6022 12.1038 21.6022 11.5871C21.6022 11.0704 21.1847 10.6515 20.6697 10.6515H17.7389C18.2539 10.6515 18.6714 10.2326 18.6714 9.71593C18.6714 9.19922 18.2539 8.78035 17.7389 8.78035H23.0676C23.5826 8.78035 24.0001 8.36147 24.0001 7.84476C24.0001 7.32805 23.5826 6.90918 23.0676 6.90918H10.0124C9.49734 6.90918 9.07984 7.32805 9.07984 7.84476C9.07984 8.36147 9.49734 8.78035 10.0124 8.78035H4.68369C4.16868 8.78035 3.75118 9.19922 3.75118 9.71593C3.75118 10.2326 4.16868 10.6515 4.68369 10.6515H8.01411C8.52912 10.6515 8.94662 11.0704 8.94662 11.5871C8.94662 12.1038 8.52912 12.5227 8.01411 12.5227H2.68545C2.17043 12.5227 1.75293 12.9416 1.75293 13.4583C1.75293 13.975 2.17043 14.3938 2.68545 14.3938H7.88089C7.36588 14.3938 6.94838 14.8127 6.94838 15.3294C6.94838 15.8461 7.36588 16.265 7.88089 16.265ZM22.135 11.5872C22.135 12.1039 22.5525 12.5227 23.0676 12.5227C23.5826 12.5227 24.0001 12.1039 24.0001 11.5872C24.0001 11.0705 23.5826 10.6516 23.0676 10.6516C22.5525 10.6516 22.135 11.0705 22.135 11.5872Z"
      fill="#F1F0F2"
    />
    <Circle cx="11.5031" cy="11.5872" r="5.3681" fill="#F1F0F2" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5549 6C8.44062 6 5.91602 8.53276 5.91602 11.6571C5.91602 14.7814 8.44062 17.3142 11.5549 17.3142C13.049 17.3142 14.4074 16.7312 15.4164 15.7796L15.7753 16.1384C15.6186 16.4197 15.6602 16.7822 15.8999 17.0219L17.8047 18.9267C18.094 19.216 18.5623 19.2168 18.8507 18.9284C19.1391 18.64 19.1383 18.1717 18.849 17.8824L16.9442 15.9776C16.7046 15.738 16.3423 15.6963 16.0611 15.8528L15.7003 15.4921C16.6274 14.4839 17.1937 13.1369 17.1937 11.6571C17.1937 8.53276 14.6691 6 11.5549 6ZM11.5547 6.41288C14.4417 6.41288 16.782 8.76077 16.782 11.657C16.782 14.5533 14.4417 16.9012 11.5547 16.9012C8.6678 16.9012 6.32747 14.5533 6.32747 11.657C6.32747 8.76077 8.6678 6.41288 11.5547 6.41288ZM16.6592 16.2624C16.5277 16.1309 16.3149 16.1305 16.1838 16.2616C16.0527 16.3927 16.0531 16.6055 16.1846 16.737L18.0894 18.6419C18.2209 18.7734 18.4338 18.7737 18.5649 18.6427C18.6959 18.5116 18.6956 18.2987 18.5641 18.1672L16.6592 16.2624Z"
      fill="#CCCCCC"
    />
    <Path
      d="M18.6128 18.601C18.4807 18.7332 18.2665 18.7332 18.1344 18.601L16.2205 16.6872C16.0884 16.5551 16.0884 16.3408 16.2205 16.2087C16.3526 16.0766 16.5668 16.0766 16.699 16.2087L18.6128 18.1226C18.7449 18.2547 18.7449 18.4689 18.6128 18.601Z"
      fill="#F1F0F2"
    />
    <Circle cx="11.6126" cy="11.6968" r="4.38212" fill="#F7F7F7" />
    <Path
      d="M11.6125 7.09558C14.1536 7.09558 16.2137 9.15562 16.2137 11.6968C16.2137 14.238 14.1536 16.298 11.611 16.298C11.2972 16.2958 10.9884 16.2667 10.6849 16.2107C10.5702 16.1895 10.4945 16.0795 10.5156 15.9648C10.5368 15.8502 10.6469 15.7744 10.7615 15.7956C11.0406 15.8471 11.3248 15.8739 11.6125 15.8759C13.9205 15.8759 15.7916 14.0049 15.7916 11.6968C15.7916 9.38876 13.9205 7.51771 11.6125 7.51771C11.0307 7.51771 10.4661 7.63651 9.94465 7.86372C9.05869 8.24977 8.33019 8.93529 7.88995 9.79536C7.59126 10.3789 7.43336 11.0263 7.43336 11.6968C7.43336 12.306 7.56367 12.8964 7.81208 13.4378C7.98467 13.8139 8.21255 14.1623 8.48744 14.4716C8.56487 14.5588 8.557 14.6922 8.46987 14.7696C8.38273 14.847 8.24932 14.8392 8.17189 14.752C7.86934 14.4115 7.61848 14.028 7.42842 13.6138C7.15479 13.0175 7.01123 12.3671 7.01123 11.6968C7.01123 10.959 7.1852 10.2457 7.51418 9.60301C7.99885 8.65617 8.8005 7.90181 9.77603 7.47673C10.3504 7.22645 10.9724 7.09558 11.6125 7.09558Z"
      fill="#CCCCCC"
    />
    <Path
      d="M8.57831 14.854C8.63623 14.7587 8.75009 14.7357 8.83264 14.8025C9.16928 15.0751 9.54749 15.2937 9.95375 15.4491C10.0495 15.4858 10.1013 15.605 10.0696 15.7154C10.0378 15.8258 9.93452 15.8857 9.83881 15.849C9.39899 15.6808 8.98881 15.4437 8.62289 15.1474C8.54035 15.0806 8.52039 14.9492 8.57831 14.854Z"
      fill="#CCCCCC"
    />
    <Path
      d="M11.625 7.97192C11.7393 7.97192 11.832 8.06457 11.832 8.17886C11.832 8.29314 11.7393 8.38579 11.625 8.38579C11.3472 8.38579 11.0745 8.42224 10.8116 8.49342C9.46994 8.85673 8.52104 10.0794 8.52104 11.4898C8.52104 11.6041 8.4284 11.6967 8.31411 11.6967C8.19983 11.6967 8.10718 11.6041 8.10718 11.4898C8.10718 9.89129 9.18246 8.5058 10.7034 8.09394C11.0015 8.01323 11.3106 7.97192 11.625 7.97192Z"
      fill="#CCCCCC"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7872 9.59325C22.7872 9.42384 22.6545 9.2865 22.4909 9.2865H22.1522L22.1279 9.28752C21.9757 9.30031 21.8559 9.43231 21.8559 9.59325C21.8559 9.76266 21.9886 9.9 22.1522 9.9H22.4909L22.5152 9.89898C22.6674 9.88618 22.7872 9.75419 22.7872 9.59325ZM21.3902 9.46189C21.5538 9.46189 21.6865 9.59923 21.6865 9.76864C21.6865 9.92958 21.5668 10.0616 21.4145 10.0744L21.3902 10.0754H18.4821C18.3184 10.0754 18.1858 9.93805 18.1858 9.76864C18.1858 9.6077 18.3055 9.47571 18.4578 9.46291L18.4821 9.46189H21.3902ZM20.2896 10.8642C20.4532 10.8642 20.5859 11.0015 20.5859 11.1709C20.5859 11.3319 20.4661 11.4639 20.3139 11.4766L20.2896 11.4777H19.1044C18.9407 11.4777 18.8081 11.3403 18.8081 11.1709C18.8081 11.01 18.9278 10.878 19.0801 10.8652L19.1044 10.8642H20.2896Z"
      fill="#CCCCCC"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.41391 13.3126C1.41391 13.1462 1.28458 13.0114 1.12504 13.0114H0.288876L0.265184 13.0124C0.116724 13.0249 0 13.1546 0 13.3126C0 13.479 0.129334 13.6139 0.288876 13.6139H1.12504L1.14873 13.6129C1.29719 13.6003 1.41391 13.4707 1.41391 13.3126ZM4.93378 13.0114C5.09332 13.0114 5.22266 13.1462 5.22266 13.3126C5.22266 13.4707 5.10593 13.6003 4.95747 13.6129L4.93378 13.6139H1.94103C1.78148 13.6139 1.65215 13.479 1.65215 13.3126C1.65215 13.1546 1.76887 13.0249 1.91733 13.0124L1.94103 13.0114H4.93378ZM5.8461 14.8189C6.00565 14.8189 6.13498 14.9538 6.13498 15.1202C6.13498 15.2782 6.01826 15.4079 5.8698 15.4204L5.8461 15.4214H4.36046C4.20091 15.4214 4.07158 15.2865 4.07158 15.1202C4.07158 14.9621 4.1883 14.8325 4.33676 14.8199L4.36046 14.8189H5.8461Z"
      fill="#CCCCCC"
    />
  </>,
  'NoSearch'
);
