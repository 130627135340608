export const auPalette = {
  neutral: {
    100: '#FFFFFF',
    200: '#F8F8F7',
    300: '#F1F1EF',
    400: '#DADAD7',
    500: '#BABAB5',
    600: '#6D6D64',
    700: '#4A4A45',
    800: '#2E2E29',
    900: '#000000',
  },
  brand: {
    denim: {
      100: '#CAD6DE',
      200: '#94ACBC',
      300: '#465F70',
      400: '#124463',
    },
    barn: {
      100: '#FAEDED',
      200: '#E25F62',
      300: '#951B1E',
    },
    harvest: {
      100: '#FFF0E5',
      200: '#D47024',
      300: '#B35309',
      400: '#7B3D07',
    },
    flaxen: {
      100: '#F5E7C7',
      200: '#E1B95F',
      300: '#816118',
    },
    earth: {
      100: '#EACDBD',
      200: '#C57449',
      300: '#482817',
    },
    leaf1: {
      100: '#E9FFC2',
      200: '#88D600',
      300: '#4E7A00',
    },
    leaf2: {
      100: '#C7FF94',
      200: '#4C9E00',
      300: '#1B5700',
    },
    twilight: {
      100: '#C5DEF2',
      200: '#549DD9',
      300: '#226398',
      400: '#114170',
    },
    sky: {
      100: '#EBFAF7',
      200: '#B8EDE2',
      300: '#165A4E',
    },
  },
};
