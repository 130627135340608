import { ApolloError } from '@apollo/client';
import { Branches } from 'types/generated/Branches';
import useCustomerFormatterNew from './useCustomerFormatterNew';
import { useStaff } from '../../context/StaffContext';
import {
  useRegistrationStatusQueryNew,
  useRegistrationStatusTotalItemsQueryNew,
  useBranchesQuery,
  usePersonDetailsWithAccountsQuery,
  PersonProfileQueryRefetch,
  useNavigation,
  useSort,
} from '../../hooks';
import { SelectOptions } from '../Select';
import { CustomerInfo, HandleRowPress, PAGE_SIZE } from '.';
import { routeConfig } from '../../routes/shared';
import { PartyGetPersonDetailsWithAccounts } from '../../types/generated/PartyGetPersonDetailsWithAccounts';
import {
  RefetchRegistrationStatusItems,
  RefetchRegistrationStatusItemsCount,
} from '../../hooks/gql/queries/useRegistrationStatusQuery';
import {
  GetRegistrationStatusItems,
  GetRegistrationStatusItems_party_vw_staff_dashboard,
} from '../../types/generated/GetRegistrationStatusItems';

export interface CustomerList {
  preferredBranchesList: SelectOptions[];
  getPreferredBranchesList: (
    customersData: PartyGetPersonDetailsWithAccounts | undefined,
    branchesData: Branches | undefined
  ) => SelectOptions[];
  branchesLoading: boolean;
  branchesError: ApolloError | undefined;
  customersLoading: boolean;
  customersError: ApolloError | undefined;
  customersRefetch: PersonProfileQueryRefetch;
  registeredUsers: CustomerInfo[];
  handleRowPress: HandleRowPress;
  getRegistrationStatusLoading: boolean;
  getRegistrationStatusError: ApolloError | undefined;
  getRegistrationStatusRefetch: RefetchRegistrationStatusItems;
  totalUsers: number;
  getRegistrationStatusTotalItemsLoading: boolean;
  getRegistrationStatusTotalItemsError: ApolloError | undefined;
  getRegistrationStatusTotalItemsRefetch: RefetchRegistrationStatusItemsCount;
}

type CustomerFormatter = (
  customer: GetRegistrationStatusItems_party_vw_staff_dashboard
) => CustomerInfo;

const useCustomerList = (): CustomerList => {
  const {
    state: { branchFilter, registrationStatusFilter, registeredUsersPageIndex },
  } = useStaff();
  const { navigate } = useNavigation();

  const { formatCustomer } = useCustomerFormatterNew();
  const { sortBranchList } = useSort();

  const formatCustomers = (
    getRegistrationStatusData: GetRegistrationStatusItems | undefined,
    format: CustomerFormatter
  ): CustomerInfo[] => {
    if (
      !getRegistrationStatusData ||
      getRegistrationStatusData.party_vw_staff_dashboard.length === 0
    ) {
      return [];
    }
    const customers = getRegistrationStatusData.party_vw_staff_dashboard.reduce(
      (acc, customer) => acc.concat(format(customer)),
      [] as CustomerInfo[]
    );

    return customers;
  };

  const getPreferredBranchesList = (
    customersData: PartyGetPersonDetailsWithAccounts | undefined,
    branchesData: Branches | undefined
  ): SelectOptions[] => {
    if (!customersData || customersData.party_vw_staff_dashboard.length === 0) {
      return [];
    }

    if (!branchesData || branchesData.sords_branch.length === 0) {
      return [];
    }

    const onBoardedBranchCodeArray =
      customersData.party_vw_staff_dashboard.reduce(
        (acc, { onboarded_by_branch_code }) => {
          if (
            onboarded_by_branch_code &&
            !acc.includes(onboarded_by_branch_code)
          ) {
            return acc.concat(onboarded_by_branch_code);
          }
          return acc;
        },
        [] as string[]
      );

    const branchesList = onBoardedBranchCodeArray.reduce((acc, branchCode) => {
      const branch = branchesData.sords_branch.find(
        (item) => item.branch_code === branchCode
      );
      if (branch) {
        return acc.concat({
          label: branch?.branch_name || '',
          value: branchCode,
        });
      }
      return acc;
    }, [] as SelectOptions[]);

    const sortedBranchesList = sortBranchList(branchesList);

    return [
      { label: 'All Branches', value: '' },
      ...sortedBranchesList,
    ] as SelectOptions[];
  };

  const [branchesData, branchesLoading, branchesError] = useBranchesQuery();
  const [customersData, customersLoading, customersError, customersRefetch] =
    usePersonDetailsWithAccountsQuery({
      skip: false,
    });

  const [
    getRegistrationStatusData,
    getRegistrationStatusLoading,
    getRegistrationStatusError,
    getRegistrationStatusRefetch,
  ] = useRegistrationStatusQueryNew({
    direction: 'asc',
    limit: PAGE_SIZE,
    offset: PAGE_SIZE * registeredUsersPageIndex - PAGE_SIZE,
    sort: ['given_name', 'family_name'],
    branchFilter,
    registrationStatusFilter,
  });

  const preferredBranchesList = getPreferredBranchesList(
    customersData,
    branchesData
  );

  const registeredUsers = formatCustomers(
    getRegistrationStatusData,
    formatCustomer
  );

  const [
    getRegistrationStatusTotalItemsData,
    getRegistrationStatusTotalItemsLoading,
    getRegistrationStatusTotalItemsError,
    getRegistrationStatusTotalItemsRefetch,
  ] = useRegistrationStatusTotalItemsQueryNew({
    branchFilter,
    registrationStatusFilter,
  });

  const totalUsers =
    getRegistrationStatusTotalItemsData?.party_vw_staff_dashboard_aggregate
      .aggregate?.count ?? 0;

  const handleRowPress = (customerId: string) => {
    navigate(
      routeConfig.UserSummary.getNavigateProps({
        customerId,
      })
    );
  };

  return {
    preferredBranchesList,
    getPreferredBranchesList,
    branchesLoading,
    branchesError,
    customersLoading,
    customersError,
    customersRefetch,
    registeredUsers,
    handleRowPress,
    getRegistrationStatusLoading,
    getRegistrationStatusError,
    getRegistrationStatusRefetch,
    totalUsers,
    getRegistrationStatusTotalItemsLoading,
    getRegistrationStatusTotalItemsError,
    getRegistrationStatusTotalItemsRefetch,
  };
};

export default useCustomerList;
