import React, { useState } from 'react';
import { Pressable } from 'react-native';
import { Text, Box, Icon, useTheme, useStyles } from '@nutrien/bonsai-core';

interface Icon {
  name?: string;
  size?: number;
}

export interface LinkProps {
  text: string;
  iconLeft?: Icon;
  iconRight?: Icon;
  hasUnderline?: boolean;
  testID?: string;
  disabled?: boolean;
  onPress?: () => void;
  type?: 'denim' | 'twilight' | 'white';
  isEmphasis?: boolean;
}

const Link = ({
  text,
  iconLeft,
  iconRight,
  hasUnderline,
  testID,
  disabled,
  type,
  isEmphasis,
  onPress,
}: LinkProps) => {
  const theme = useTheme();
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const textDecorationLine = hasUnderline
    ? hovered
      ? 'none'
      : 'underline'
    : hovered
    ? 'underline'
    : 'none';

  const colorByLinkType = () => {
    switch (type) {
      case 'twilight': {
        return theme.auColors.brand.twilight[300];
      }
      case 'white': {
        return theme.auColors.neutral[100];
      }
      case 'denim':
      default: {
        return theme.auColors.primary.default;
      }
    }
  };

  const styles = useStyles(() => {
    return {
      link: {
        color: colorByLinkType(),
        textDecorationLine,
      },
      linkBoxShadow: {
        textDecorationLine: 'none',
        boxShadow: `0px 0px 6px ${theme.auColors.functional.focused.dark}`,
        borderRadius: 4,
      },
      linkText: {
        fontFamily: theme.typography.fontFamily.regular,
        fontWeight: isEmphasis ? '600' : '300',
        color: pressed ? theme.auColors.primary[200] : colorByLinkType(),
      },
    };
  });

  return (
    <Pressable
      style={[styles.link, focused && styles.linkBoxShadow]}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - web only
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={onPress}
      accessibilityRole="button"
      accessibilityLabel={text}
      testID={testID}
      disabled={disabled}
    >
      <Box flexDirection="row" alignItems="center">
        {iconLeft && (
          <Icon
            name={iconLeft.name ?? 'chevron-left'}
            color={colorByLinkType()}
            testID={
              iconLeft.name
                ? `link-icon-${iconLeft.name}`
                : 'link-icon-chevron-left'
            }
            size={iconLeft.size ?? 24}
          />
        )}
        <Text testID="link-text" style={styles.linkText}>
          {text}
        </Text>
        {iconRight && (
          <Icon
            name={iconRight.name ?? 'chevron-right'}
            color={colorByLinkType()}
            testID={
              iconRight.name
                ? `link-icon-${iconRight.name}`
                : 'link-icon-chevron-right'
            }
            size={iconRight.size ?? 24}
          />
        )}
      </Box>
    </Pressable>
  );
};

export default Link;
