import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, useStyles } from '@nutrien/bonsai-core';
import { getMonth, format } from 'date-fns';
import differenceInYears from 'date-fns/differenceInYears';
import { useReactiveVar } from '@apollo/client';
import useAccount from '../../screens/Account/useAccount';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
} from 'react-native';
import { Chip } from 'react-native-paper';
import { useTransaction } from '../../context/TransactionContext';
import { useSegment } from '../../hooks';
import { selectedAccountVar } from '../../reactiveVariables';
import { trackEvent } from '../../constants/segment';
import { ICalendarMonth } from './index';
import formattedCalendarMonths from './formattedCalendarMonths';

interface FilterChipNavIconProps {
  type: 'left' | 'right';
  onPress: () => void;
  hidden: boolean;
  accessibilityLabel: string;
}
const FilterChipNavIcon = ({
  type,
  onPress,
  hidden,
  accessibilityLabel,
}: FilterChipNavIconProps) => {
  const styles = useStyles((theme) => ({
    navIcon: {
      alignItems: 'center',
      borderColor: theme.auColors.neutral[500],
      borderRadius: theme.shape.borderRadiusXLarge,
      borderWidth: 1,
      height: 40,
      justifyContent: 'center',
      marginVertical: theme.spacing(1),
      width: 40,
    },
    containerStyle: {
      margin: 0,
    },
    containerFocusStyle: {
      backgroundColor: theme.auColors.neutral[300],
      borderWidth: 0,
      boxShadow: 'none',
      margin: 0,
    },
    focusStyle: {
      borderColor: theme.auColors.neutral[500],
      borderRadius: theme.shape.borderRadiusXLarge,
      borderWidth: 1,
      boxShadow: `0px 0px 5px ${theme.auColors.functional.focused.dark}`,
    },
  }));
  return hidden ? null : (
    <IconButton
      type={'default'}
      testID={`nav-${type}-icon`}
      icon={`chevron-${type}`}
      style={styles.navIcon}
      containerStyle={styles.containerStyle}
      focusedStyle={styles.focusStyle}
      _focus={styles.containerFocusStyle}
      onPress={onPress}
      buttonSize={40}
      accessibilityLabel={accessibilityLabel}
      accessibilityHidden={true}
    />
  );
};

const TransactionChipFilters = ({
  pillsViewWidth = 0,
  navRightXOffset = 440, // last pill x offset,
  initialXOffset = 0,
}: {
  pillsViewWidth?: number;
  navRightXOffset?: number;
  initialXOffset?: number;
}): JSX.Element => {
  const {
    setDateRange,
    resetFilters,
    state: { fromDate, toDate, queryTypeIndex },
  } = useTransaction();
  const { account_id, account_view } = useReactiveVar(selectedAccountVar);
  const segment = useSegment();
  const { accountType } = useAccount();
  const formattedDate = {
    fromDate: format(fromDate, 'yyyy-MM-dd'),
    toDate: format(toDate, 'yyyy-MM-dd'),
  };

  const selectedMonth = getMonth(fromDate);
  const [activePill, setActivePill] = useState<number>(selectedMonth || 12);
  const [currentXOffset, setCurrentXOffset] = useState<number>(initialXOffset);
  const [scrollViewWidth, setScrollViewWidth] =
    useState<number>(pillsViewWidth);
  const [pressedPillTitle, setPressedPillTitle] = useState<string>();
  const slidesRef = useRef<ScrollView>(null);
  const now = new Date();

  const segmentProperties = {
    account_id,
    search_type: account_view.date_view,
    tab_header: account_view.selected_tab,
    tab_label: account_view.display_view,
  };

  useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (differenceInYears(toDate, fromDate) === 1) {
      setActivePill(12);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (pressedPillTitle) {
      segment?.track(trackEvent.DATE_RANGE_SET, {
        ...segmentProperties,
        date_from: formattedDate.fromDate,
        date_to: formattedDate.toDate,
        pill_label: pressedPillTitle,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, pressedPillTitle]);

  useEffect(() => {
    // We only want to scroll to the end of the scrollview on the initial load
    slidesRef?.current?.scrollToEnd({ animated: false });
  }, []);

  const styles = useStyles((theme) => ({
    pillContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
    pill: {
      width: 54,
      height: 40,
      borderRadius: theme.shape.borderRadiusXLarge,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: theme.auColors.neutral[500],
      marginVertical: theme.spacing(1),
    },
    pillMarginLeft: {
      marginLeft: theme.spacing(0.4),
    },
    pillMarginRight: {
      marginRight: theme.spacing(0.4),
    },
    activePill: {
      borderColor: theme.auColors.primary.default,
      backgroundColor: theme.auColors.primary.default,
    },
    pillActiveText: {
      color: theme.auColors.neutral[100],
    },
    pillText: {
      fontSize: theme.typography.fontSize.medium,
      fontFamily: theme.typography.fontFamily.regular,
      color: theme.auColors.neutral[600],
      paddingHorizontal: theme.spacing(0.5),
    },
    allPill: {
      borderRadius: theme.shape.borderRadiusXLarge,
      width: 43,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: theme.auColors.neutral[500],
      marginVertical: theme.spacing(1),
    },
  }));

  const { previousYear, currentYear, allMonths } = formattedCalendarMonths();

  const sortedYear = [...previousYear, ...currentYear, ...allMonths];

  const _onPress = (chip: ICalendarMonth, index: number) => {
    // Set the active pill
    const { title, category, value } = chip;
    setPressedPillTitle(title);

    if (index !== activePill) {
      setActivePill(index);
    }

    if (category === 'all') {
      resetFilters();
      return;
    }

    const isPreviousYearMonth = previousYear.some(
      (month) => month.title === title
    );

    const filterYear = isPreviousYearMonth
      ? now.getFullYear() - 1
      : now.getFullYear();
    const month = filterMonth(value, filterYear);

    const firstDay = new Date(filterYear, month, 1);
    const lastDay = new Date(filterYear, month + 1, 0);

    // Feb 2022.
    if (
      accountType === 'finance' &&
      queryTypeIndex === 1 &&
      filterYear === 2022 &&
      value === 1
    ) {
      const febFromDate = new Date(2022, 2);
      const febToDate = new Date(2022, 2, 14);
      setDateRange({
        fromDate: febFromDate,
        toDate: febToDate,
      });
    }
    // Mar 2022.
    else if (
      accountType === 'finance' &&
      queryTypeIndex === 1 &&
      filterYear === 2022 &&
      value === 2
    ) {
      const marFromDate = new Date(2022, 2, 15);
      const marToDate = new Date(2022, 3, 0);
      setDateRange({
        fromDate: marFromDate,
        toDate: marToDate,
      });
    } else {
      setDateRange({
        fromDate: firstDay,
        toDate: lastDay,
      });
    }
  };

  // TODO: this is a fix to address StoreDocs date issue. And this is only for finance accounts
  // TODO: This is only for 2021 Sep to 2022 Feb. Need to remove this after that
  const filterMonth = (value: number, year: number) => {
    return accountType === 'finance' &&
      [2021, 2022].includes(year) &&
      queryTypeIndex === 1 &&
      (value > 7 || value < 1)
      ? value + 1
      : value;
  };

  const dayButtonFilters = sortedYear.map((chip, index) => {
    return (
      <Box key={index} testID={`chip-wrapper-${index}`}>
        <Chip
          testID={`chip-${chip.value}`}
          mode="outlined"
          style={[
            chip.category === 'all' ? styles.allPill : styles.pill,
            activePill === index && styles.activePill,
            index !== 0 && styles.pillMarginLeft,
            index !== sortedYear.length - 1 && styles.pillMarginRight,
          ]}
          textStyle={[
            styles.pillText,
            activePill === index && styles.pillActiveText,
          ]}
          onPress={() => _onPress(chip, index)}
          disabled={false}
          accessibilityLabel={`filter by month ${chip.label}`}
        >
          {chip.title}
        </Chip>
      </Box>
    );
  });

  const navLeft = () => {
    const eachItemOffset = scrollViewWidth / 14; // Divide by number of pills
    const xOffset = currentXOffset - eachItemOffset;
    slidesRef?.current?.scrollTo({ x: xOffset, y: 0, animated: true });

    segment?.track(trackEvent.DATE_NAVIGATION_CLICKED, {
      ...segmentProperties,
      navigate_direction: 'Left',
    });
  };

  const navRight = () => {
    const eachItemOffset = scrollViewWidth / 14; // Divide by number of pills
    const xOffset = currentXOffset + eachItemOffset;
    slidesRef?.current?.scrollTo({ x: xOffset, y: 0, animated: true });

    segment?.track(trackEvent.DATE_NAVIGATION_CLICKED, {
      ...segmentProperties,
      navigate_direction: 'Right',
    });
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    setCurrentXOffset(event.nativeEvent.contentOffset.x);
  };

  const lastPillVisible = currentXOffset > navRightXOffset;
  const firstPillVisible = currentXOffset === 0;

  return (
    <Box style={styles.pillContainer}>
      <FilterChipNavIcon
        type="left"
        onPress={navLeft}
        hidden={firstPillVisible}
        accessibilityLabel="Show previous months"
      />
      <ScrollView
        ref={slidesRef}
        horizontal
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        scrollEventThrottle={16}
        onContentSizeChange={(w) => setScrollViewWidth(w)}
      >
        {dayButtonFilters}
      </ScrollView>
      <FilterChipNavIcon
        type="right"
        onPress={navRight}
        hidden={lastPillVisible}
        accessibilityLabel="Show next months"
      />
    </Box>
  );
};

export default TransactionChipFilters;
