import React from 'react';
import { TextStyle, useWindowDimensions } from 'react-native';
import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { ContentPage, ContentPageButton } from '../ContentPage';
import Link from '../Link';
import Email from '../SVG/Email';
import BulletText from '../BulletText';
import { DIGITAL_FEEDBACK_EMAIL, SUPPORT_PHONE } from '../../constants';
import { useUser } from '../../context/UserContext';

const EmailNotification = () => {
  const {
    state: { email },
  } = useUser();
  const { isDesktop } = useDeviceType();
  const layout = useWindowDimensions();

  const navigateToNutrienHomePage = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open('mailto:' + DIGITAL_FEEDBACK_EMAIL, '_self');
  };
  // 48 is the size of the header on mobile, and 200 is the size of the footer on mobile
  const MOBILE_HEADER_HEIGHT = 48;
  const MOBILE_FOOTER_HEIGHT = 200;
  const pageHeight = isDesktop
    ? 'auto'
    : layout.height - MOBILE_HEADER_HEIGHT - MOBILE_FOOTER_HEIGHT;

  const buttonList: ContentPageButton[] = [];
  const contentPageTitle = 'Check your email';
  const firstEmailLetter = email.charAt(0);
  const atLocation = email.indexOf('@');
  const emailProvider = email.substring(atLocation, email.length);
  const theme = useTheme();
  const styles = {
    labelStyle: {
      fontWeight: '600',
    } as TextStyle,
    mobileNumberStyle: {
      color: theme.auColors.brand.twilight[300],
      fontWeight: '600',
    } as TextStyle,
  };
  return (
    <Box
      testID="email-notification"
      width="100%"
      height="100%"
      maxWidth={isDesktop ? 1136 : 'unset'}
      maxHeight={isDesktop ? 699 : 'unset'}
      minHeight={pageHeight}
      justifyContent={isDesktop ? 'center' : 'unset'}
      alignItems="center"
      marginLeft="auto"
      marginRight="auto"
      marginY={8}
    >
      <ContentPage
        title={contentPageTitle}
        icon={<Email width={isDesktop ? 360 : 260} />}
        iconTestID={'email-testID'}
        contentPageButtons={buttonList}
      >
        <Box>
          <Box marginBottom={3}>
            <Text testID="userInformText">
              We{"'"}ve just sent an email to
              {' ' + firstEmailLetter + '******' + emailProvider + '. '}
              Clicking the button in the email confirms that you have received
              the invitation and activates your account.
            </Text>
          </Box>
          <Box marginBottom={3}>
            <Text>Didn{"'"}t receive an email?</Text>
            <BulletText text="It's possible your email may be in your Junk or Spam folder" />
            <Box marginLeft={isDesktop ? 6 : 0}>
              <BulletText text="If you need help, contact Customer Support" />
            </Box>
          </Box>
          <Box marginBottom={3}>
            <Box marginBottom={isDesktop ? 0 : 3}>
              <Text style={styles.labelStyle}>Phone</Text>
            </Box>
            <Text style={styles.mobileNumberStyle}>{SUPPORT_PHONE}</Text>
            <Text>
              Monday to Friday {'(except public holidays)'},
              {isDesktop ? ' ' : '\n'}
              9am to 5pm
              {' (AEST)'}
            </Text>
          </Box>
          <Box marginBottom={isDesktop ? 0 : 3}>
            <Text style={styles.labelStyle}>Email</Text>
          </Box>
          <Box marginLeft={'auto'} marginRight={'auto'}>
            <Link
              hasUnderline={true}
              text={DIGITAL_FEEDBACK_EMAIL}
              testID="digital-feedback-email-testID"
              onPress={navigateToNutrienHomePage}
              type="twilight"
            />
          </Box>
          <Text>We aim to respond within 2 working days</Text>
        </Box>
      </ContentPage>
    </Box>
  );
};
export default EmailNotification;
