import { useState, useCallback, SetStateAction } from 'react';
import debounce from 'lodash.debounce';
import {
  SearchCustomerAccounts,
  SearchCustomerAccounts_sords_customer_account,
} from 'types/generated/SearchCustomerAccounts';
import { useNavigation, useSearchCustomerAccountsQuery } from '../../hooks';
import { ApolloError } from '@apollo/client';
import { routeConfig } from '../../routes/shared';
import { NO_RESULTS_FOUND } from '../../constants';

export interface UseStaffDashboard {
  searchTerm: string;
  hideSearchResults: boolean;
  setHideSearchResults: (value: SetStateAction<boolean>) => void;
  handleSearchChange: (val: string) => void;
  handleSearchResultClick: (
    selectedCustomer: SearchCustomerAccounts_sords_customer_account
  ) => void;
  searchResults: SearchCustomerAccounts_sords_customer_account[];
  searchLoading: boolean;
  searchError: ApolloError | undefined;
  onKeyDown?: () => void;
}

const useStaffDashboard = (): UseStaffDashboard => {
  const [searchTerm, setSearchTerm] = useState('');
  const [hideSearchResults, setHideSearchResults] = useState(true);
  const [searchResults, setSearchResults] = useState<
    SearchCustomerAccounts_sords_customer_account[]
  >([]);
  const { navigate } = useNavigation();

  const filterCustomerAccounts = (allAccounts: SearchCustomerAccounts) => {
    if (allAccounts.sords_customer_account.length === 0) {
      setSearchResults([
        {
          name1: NO_RESULTS_FOUND,
        } as SearchCustomerAccounts_sords_customer_account,
      ]);
    } else {
      setSearchResults(allAccounts.sords_customer_account);
    }
  };

  const { loading: searchLoading, error: searchError } =
    useSearchCustomerAccountsQuery({
      searchTerm: `%${searchTerm}%`,
      onCompleted: filterCustomerAccounts,
      skip: searchTerm.length < 3,
    });

  const debouncedSetSearchTerm = debounce((val) => setSearchTerm(val), 300);

  const memoizedSetSearchTerm = useCallback(
    (val) => debouncedSetSearchTerm(val),
    [debouncedSetSearchTerm]
  );

  const handleSearchChange = (val: string) => {
    memoizedSetSearchTerm(val);
    setHideSearchResults(false);
  };

  const handleSearchResultClick = (
    selectedCustomer: SearchCustomerAccounts_sords_customer_account
  ) => {
    if (selectedCustomer?.name1 !== NO_RESULTS_FOUND) {
      setHideSearchResults(true);
      navigate(
        routeConfig.Accounts.getNavigateProps({
          id: selectedCustomer.nominated_account_id || '',
        })
      );
    }
  };

  return {
    searchTerm,
    hideSearchResults,
    setHideSearchResults,
    handleSearchChange,
    handleSearchResultClick,
    searchResults,
    searchLoading,
    searchError,
  };
};

export default useStaffDashboard;
