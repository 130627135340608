import React, { useState, useEffect } from 'react';
import {
  ImageBackground,
  useWindowDimensions,
  Platform,
  ImageURISource,
} from 'react-native';
import { Box, useTheme } from '@nutrien/bonsai-core';
import {
  getImagePathArray,
  determineImageSizeForWeb,
  getDefaultImageSource,
} from '../../utils/imageHelpers';

export interface FullScreenBackgroundImageProps {
  /**
   * Name associated with collection of image sources.
   */
  imageName: string;
  /**
   * The test id to render on the component for automated testing use.
   */
  testID: string;
  /**
   * The minimum screen width size (in px) that the fullscreen background image will display.
   * @default 720 // Show on only Bonsai breakpoints medium, large and xLarge by default
   */
  minScreenSize?: number;
  /**
   * The children passed to the component.
   */
  children: JSX.Element;
}

/**
 * FullScreenBackgroundImage component
 *
 * ```js
 * import React from 'react';
 * import { useWindowDimensions } from 'react-native';
 * import { Box, Text } from '@nutrien/bonsai-core';
 * import { FullScreenBackgroundImage } from '../../components/FullScreenBackgroundImage';
 *
 * export default function MyComponent(): JSX.Element {
 *   const layout = useWindowDimensions();
 *   const fullScreenHeight = layout.height;
 *
 *   return (
 *     <Box testID="section-test-id">
 *       <Box minHeight={fullScreenHeight}>
 *         <FullScreenBackgroundImage
 *          imageName="CustomerSelfRegistration"
 *          testID="section-test-id__FullScreenBackgroundImage"
 *         >
 *           <Text h1>
 *             Child to render
 *           </Text>
 *         </FullScreenBackgroundImage>
 *       </Box>
 *     </Box>
 *   );
 * };
 * ```
 */
const FullScreenBackgroundImage = ({
  imageName,
  testID,
  minScreenSize = 720, // Show on only Bonsai breakpoints medium, large and xLarge by default
  children,
}: FullScreenBackgroundImageProps): JSX.Element => {
  // can store the image src in a state
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<
    ImageURISource | ImageURISource[]
  >([]);

  useEffect(() => {
    const imageSourceArray =
      getImagePathArray(imageName) ??
      '../../../assets/selfRegistration/xl.webp';
    const imageSource = isWeb
      ? determineImageSizeForWeb(layout.width, imageSourceArray)
      : imageSourceArray;

    setBackgroundImageSrc(imageSource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // empty to only update once

  const theme = useTheme();
  const layout = useWindowDimensions();
  const isWeb: boolean = Platform.OS.toLowerCase() === 'web';
  const hideBackgroundImage: boolean = layout.width < minScreenSize;
  const defaultImageSource = getDefaultImageSource(imageName);
  const styles = {
    container: {
      flex: 1,
    },
    image: {
      flex: 1,
      width: 'null',
      height: '100%',
    },
  };
  return (
    <Box backgroundColor={theme.auColors.neutral[300]} style={styles.container}>
      <ImageBackground
        style={styles.image}
        defaultSource={hideBackgroundImage ? undefined : defaultImageSource}
        source={hideBackgroundImage ? [] : backgroundImageSrc}
        resizeMode="cover"
        testID={testID}
      >
        <Box flexGrow={1} alignItems="center" justifyContent="center">
          {children}
        </Box>
      </ImageBackground>
    </Box>
  );
};

export default FullScreenBackgroundImage;
