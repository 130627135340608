import React, { FC } from 'react';
import {
  Card,
  Box,
  Text,
  useDeviceType,
  useStyles,
} from '@nutrien/bonsai-core';
import { Button } from '../../components/Button';
import { shortDate } from '../../utils/recommendationUtils';
import { useNavigation } from '../../hooks';
import { routeConfig } from '../../routes/shared';

type props = {
  agronomist: string;
  company: string;
  visitDate: string;
  navigationId: string;
};

type FarmInfoField = {
  title: string;
  value: string;
};

const SummaryHeader: FC<props> = ({
  agronomist,
  company,
  visitDate,
  navigationId,
}) => {
  const { navigate } = useNavigation();
  const device = useDeviceType();
  const styles = useStyles((theme) => ({
    summaryHeader: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    summaryHeaderTitle: {
      fontWeight: '600',
      fontSize: theme.typography.fontSize.large,
      color: theme.auColors.neutral[800],
    },
    fieldTitle: {
      fontWeight: '400',
      color: theme.auColors.neutral[600],
    },
    fieldSubtitle: {
      fontWeight: '400',
      color: theme.auColors.neutral[800],
    },
    ctaButton: {
      marginTop: theme.spacing(2),
    },
  }));

  const farmVisitDate = visitDate ? shortDate(visitDate, true) : '';

  const farmInfoFields: FarmInfoField[] = [
    {
      title: 'Created by',
      value: agronomist,
    },
    {
      title: 'Grower',
      value: company,
    },
    {
      title: 'Visit date',
      value: farmVisitDate || '',
    },
  ];

  return (
    <Card style={styles.summaryHeader} testID="farm-card">
      <Box mb={2} flexDirection="row" justifyContent="space-between">
        <Text
          bodyBold
          ellipsizeMode="tail"
          numberOfLines={1}
          testID="summary-title"
          style={styles.summaryHeaderTitle}
        >
          Farm Visit Summary
        </Text>
      </Box>
      <Box>
        {farmInfoFields.map(({ title, value }: FarmInfoField, index) => (
          <Box
            key={index}
            flexDirection="row"
            testID={`summary-header-${title.replace(' ', '-')}`}
          >
            <Box width="100px">
              <Text
                ellipsizeMode="tail"
                numberOfLines={1}
                style={styles.fieldTitle}
              >
                {title}
              </Text>
            </Box>
            <Box flex={1} ml={1}>
              <Text
                ellipsizeMode="tail"
                numberOfLines={1}
                style={styles.fieldSubtitle}
              >
                {value}
              </Text>
            </Box>
          </Box>
        ))}
        <Box width={device.isHandset ? '100%' : 240}>
          <Button
            type="solid"
            buttonStyle={styles.ctaButton}
            title="View Recommendations"
            accessibilityLabel="View Recommendations"
            testID="farm-visit-cta"
            onPress={() =>
              navigate(
                routeConfig.FarmVisitRecommendations.getNavigateProps({
                  id: navigationId,
                })
              )
            }
          />
        </Box>
      </Box>
    </Card>
  );
};

export default SummaryHeader;
