import format from 'date-fns/format';

interface UseFormatters {
  formatCurrency: (currency?: number, displayBlank?: boolean) => string;
  formattedAmount: (amount?: number | null) => string;
  formatCapitalize: (text: string) => string;
  formatSentenceCase: (text: string) => string;
  formatExtraSpaces: (text: string) => string;
  formatDate: (date: string | number, dateFormat?: string) => string;
  formatAccounts: (accounts: string[]) => string;
  formatAccountId: (accountId: string) => string;
  sortAccounts: (accounts: string[]) => string[];
  formatMobile: (mobileNumber: string) => string;
  formatCamelCaseToTitleCase: (text: string) => string;
  renderNoneIfZero: (amount?: number | null) => string;
  formatABN: (abn: string) => string;
}

const useFormatters = (): UseFormatters => {
  const formatCurrency = (value = 0, displayBlank = false) => {
    const emptyValue = displayBlank ? '' : 0;
    const v = value || emptyValue; // handle nulls

    const formatted = (
      typeof v === 'number' ? roundToDecimalPlaces(v, 2) ?? v : v
    ).toLocaleString('en', {
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'USD',
    });

    const creditString = displayBlank ? '' : 'CR ';

    return value < 0 ? `${formatted.replace('-', creditString)}` : formatted;
  };

  const formattedAmount = (amount?: number | null): string => {
    return typeof amount === 'number' ? formatCurrency(amount) : '-';
  };

  const formatDate = (date: string | number, dateFormat = 'dd/MM/yyy') => {
    return format(new Date(date), dateFormat);
  };

  const formatExtraSpaces = (text: string) => {
    return text.trim().replace(/\s+/g, ' ');
  };

  const formatCapitalize = (text: string) => {
    return formatExtraSpaces(text)
      .toLowerCase()
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' ');
  };

  const formatSentenceCase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  };

  // TODO this may change in the future when we use account_id_trimmed directly
  const formatAccountId = (accountId: string) => accountId.replace(/^0+/, '');

  const sortAccounts = (accounts: string[]) =>
    accounts.sort((a: string, b: string) =>
      a.localeCompare(b, 'en', { numeric: true })
    );

  const formatAccounts = (accounts: string[]) => {
    const sortedAccounts = sortAccounts(accounts);

    if (sortedAccounts.length <= 3) {
      return sortedAccounts.join(', ');
    }

    return sortedAccounts.slice(0, 3).join(', ');
  };

  const formatMobile = (mobileNumber: string) => {
    if (mobileNumber.length < 7) {
      return mobileNumber.replace(/^(.{4})(.*)$/, '$1 $2').trim();
    } else {
      return mobileNumber.replace(/^(.{4})(.{3})(.*)$/, '$1 $2 $3').trim();
    }
  };

  const formatCamelCaseToTitleCase = (text: string) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const renderNoneIfZero = (amount?: number | null) => {
    return amount ? formattedAmount(amount) : 'None';
  };

  const formatABN = (abn: string) => {
    return abn.replace(/^(.{2})(.{3})(.{3})(.*)$/, '$1 $2 $3 $4').trim();
  };

  return {
    formatCurrency,
    formattedAmount,
    formatCapitalize,
    formatSentenceCase,
    formatDate,
    formatExtraSpaces,
    formatAccounts,
    formatAccountId,
    sortAccounts,
    formatMobile,
    formatCamelCaseToTitleCase,
    renderNoneIfZero,
    formatABN,
  };
};

/**
 * use this function to round number to required decimal places
 * @param num number to be rounded
 * @param decimalPlaces number of decimal places to be rounded to, eg. 2 means round to 2 decimal points
 * @returns rounded number
 */
export const roundToDecimalPlaces = (
  num?: number,
  decimalPlaces = 0
): number | undefined => {
  if (typeof num === 'undefined') {
    return;
  }
  const place = Math.pow(10, decimalPlaces);
  return Math.round(num * place * (1 + Number.EPSILON)) / place;
};

export default useFormatters;
