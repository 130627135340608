import React from 'react';
import { ViewStyle, Platform } from 'react-native';
import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { CustomerProfile } from 'types/generated/RegistrationStatus';
import { CustomerInfo } from '.';
import { useFormatters } from '../../hooks';
import { GetRegistrationStatusItems_party_vw_staff_dashboard } from '../../types/generated/GetRegistrationStatusItems';

const useCustomerFormatter = (): {
  formatCustomer: (
    props: GetRegistrationStatusItems_party_vw_staff_dashboard
  ) => CustomerInfo;
} => {
  const theme = useTheme();
  const { formatAccounts, formatAccountId } = useFormatters();
  const { isHandset, isDesktop } = useDeviceType();
  const formatCustomer = (
    props: CustomerProfile | GetRegistrationStatusItems_party_vw_staff_dashboard
  ) => {
    let accounts, numberOfMoreAccounts, onboardedBranchCode, customerId;
    const { given_name: firstName, family_name: lastName, email } = props;
    const isCustomerProfileType = !('person_party_id' in props);
    if (isCustomerProfileType) {
      const castProps = props as CustomerProfile;
      onboardedBranchCode = castProps.onboarded_branch_code;
      customerId = castProps.id;
    } else {
      const castProps =
        props as GetRegistrationStatusItems_party_vw_staff_dashboard;
      onboardedBranchCode = castProps.onboarded_by_branch_code;
      customerId = castProps.person_party_id;
    }

    if (isCustomerProfileType) {
      const accountNumbers = (props as CustomerProfile).account_numbers;
      accounts = accountNumbers
        ? accountNumbers
            .split(',')
            .map((accountId) => formatAccountId(accountId))
        : [];
    } else {
      accounts =
        (
          props as GetRegistrationStatusItems_party_vw_staff_dashboard
        ).account_id_list?.split(',') ?? [];
    }

    if (accounts.length > 3) {
      numberOfMoreAccounts = accounts.length - 3;
    }

    const styles = {
      nameStyle: {
        maxWidth: 140,
      },
      emailStyle: {
        maxWidth: 254,
      },
      accounts: {
        marginRight: theme.spacing(1),
        fontSize: isDesktop
          ? theme.typography.fontSize.medium
          : theme.typography.fontSize.small,
      },
      numberOfMoreAccounts: {
        fontSize: theme.typography.fontSize.small,
        color: theme.auColors.neutral[600],
      },
    };

    return {
      'Given Name': {
        value: firstName || '',
        displayValue: (
          <Text style={styles.nameStyle as ViewStyle}>{firstName || ''}</Text>
        ),
      },
      'Family Name': {
        value: lastName || '',
        displayValue: (
          <Text style={styles.nameStyle as ViewStyle}>{lastName || ''}</Text>
        ),
      },
      'Email Address': {
        value: email || '',
        displayValue: (
          <Text style={styles.emailStyle as ViewStyle}>{email}</Text>
        ),
      },
      'List of Accounts': (
        <Box
          display="flex"
          flexDirection={
            isHandset && Platform.OS !== 'android' ? 'column' : 'row'
          }
          width="100%"
        >
          <Text style={styles.accounts}>{formatAccounts(accounts)}</Text>
          {numberOfMoreAccounts && (
            <Text
              style={styles.numberOfMoreAccounts}
              testID="number-of-more-accounts"
            >
              +{numberOfMoreAccounts} more
            </Text>
          )}
        </Box>
      ),
      onboardedBranchCode: onboardedBranchCode,
      customerId,
    };
  };

  return { formatCustomer };
};

export default useCustomerFormatter;
