import React from 'react';
import { Input } from '@nutrien/bonsai-core';
import LoadingSearchIcon from './LoadingSearchIcon';
import { UseStaffDashboard } from './useStaffDashboard';
import copy from './copy.json';

const SearchInput = ({
  handleSearchChange,
  searchLoading,
  searchError,
  setHideSearchResults,
  onKeyDown,
}: Partial<UseStaffDashboard>): JSX.Element => {
  const handleInputFocus = () => {
    setHideSearchResults && setHideSearchResults(false);
  };
  const handleInputBlur = () => {
    setHideSearchResults && setHideSearchResults(true);
  };

  return (
    <Input
      autoCompleteType={undefined}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      testID="header-customer-selector"
      onChangeText={handleSearchChange}
      placeholder={copy.customerAccountSearch.placeholder}
      errorMessage={searchError?.message}
      right={<LoadingSearchIcon loading={searchLoading || false} />}
      {...(onKeyDown && { onKeyPress: onKeyDown })}
    />
  );
};

export default SearchInput;
