import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import {
  RegistrationStatus,
  RegistrationStatusVariables,
  RegistrationStatusTotalItems,
  RegistrationStatusTotalItemsVariables,
} from 'types/generated/RegistrationStatus';
import {
  GetRegistrationStatusItemsCount,
  GetRegistrationStatusItemsCountVariables,
} from '../../../types/generated/GetRegistrationStatusItemsCount';
import {
  GetRegistrationStatusItems,
  GetRegistrationStatusItemsVariables,
} from '../../../types/generated/GetRegistrationStatusItems';

export interface GetRegistrationStatusItemsCountQueryVariables
  extends GetRegistrationStatusItemsCountVariables {
  skip?: boolean;
  branchFilter?: string;
}
export interface GetRegistrationStatusItemsQueryVariables
  extends GetRegistrationStatusItemsVariables {
  sort?: (string | null)[];
  registrationStatusFilter?: string;
  direction?: string | null;
  skip?: boolean;
  branchFilter?: string | null;
}

export const GET_REGISTRATION_STATUS = gql`
  query GetRegistrationStatus(
    $direction: String!
    $limit: Int!
    $page: Int!
    $sort: [String]!
    $branchFilter: String!
    $registrationStatusFilter: String!
  ) {
    getRegistrationStatus(
      payload: {
        direction: $direction
        limit: $limit
        page: $page
        sort: $sort
        filters: {
          onboarded_branch_code: $branchFilter
          status: $registrationStatusFilter
        }
      }
    ) {
      customerProfile
      registrationStatus
    }
  }
`;

export const GET_REGISTRATION_STATUS_TOTAL_ITEMS = gql`
  query GetRegistrationStatusTotalItems(
    $branchFilter: String!
    $registrationStatusFilter: String!
  ) {
    getRegistrationStatusTotalItems(
      payload: {
        filters: {
          onboarded_branch_code: $branchFilter
          status: $registrationStatusFilter
        }
      }
    ) {
      total
    }
  }
`;

export type Refetch = () => Promise<ApolloQueryResult<RegistrationStatus>>;
export type RefetchRegistrationStatusTotalItems = () => Promise<
  ApolloQueryResult<RegistrationStatusTotalItems>
>;
export type RefetchRegistrationStatusItems = () => Promise<
  ApolloQueryResult<GetRegistrationStatusItems>
>;
export type RefetchRegistrationStatusItemsCount = () => Promise<
  ApolloQueryResult<GetRegistrationStatusItemsCount>
>;

const useRegistrationStatusQuery = ({
  direction,
  limit,
  page,
  sort,
  branchFilter,
  registrationStatusFilter,
  skip = false,
}: RegistrationStatusVariables): [
  RegistrationStatus | undefined,
  boolean,
  ApolloError | undefined,
  Refetch
] => {
  const { loading, error, data, refetch } = useQuery<
    RegistrationStatus,
    RegistrationStatusVariables
  >(GET_REGISTRATION_STATUS, {
    skip,
    variables: {
      direction,
      limit,
      page,
      sort,
      branchFilter,
      registrationStatusFilter,
    },
  });
  return [data, loading, error, refetch];
};

const useRegistrationStatusTotalItemsQuery = ({
  branchFilter,
  registrationStatusFilter,
  skip = false,
}: RegistrationStatusTotalItemsVariables): [
  RegistrationStatusTotalItems | undefined,
  boolean,
  ApolloError | undefined,
  RefetchRegistrationStatusTotalItems
] => {
  const { loading, error, data, refetch } = useQuery<
    RegistrationStatusTotalItems,
    RegistrationStatusTotalItemsVariables
  >(GET_REGISTRATION_STATUS_TOTAL_ITEMS, {
    skip,
    variables: {
      branchFilter,
      registrationStatusFilter,
    },
  });

  return [data, loading, error, refetch];
};

const generateRegistrationStatusQuery = ({
  branchFilter,
  registrationStatusFilter,
  sort = ['preferred_name'],
  direction = 'desc',
}: GetRegistrationStatusItemsQueryVariables) => {
  let filterQuery = '';
  if (branchFilter || registrationStatusFilter) {
    filterQuery = 'where: {\n';
    if (branchFilter) {
      filterQuery += ' onboarded_by_branch_code: { _eq: $branchFilter }\n';
    }
    if (
      registrationStatusFilter &&
      registrationStatusFilter === 'invite_sent'
    ) {
      filterQuery += ' auth_id: {_is_null: false}\n';
    }
    if (
      registrationStatusFilter &&
      registrationStatusFilter === 'invite_not_sent'
    ) {
      filterQuery += ' auth_id: {_is_null: true}\n';
    }
    filterQuery += '}\n';
  }
  let orderbyQuery = '';
  if (sort && direction) {
    orderbyQuery += 'order_by: { ';
    sort.forEach((field) => (orderbyQuery += `${field}: ${direction}, `));
    orderbyQuery = orderbyQuery.slice(0, -2);
    orderbyQuery += ' }';
  }

  const query = gql`
    query GetRegistrationStatusItems(
      $limit: Int!
      $offset: Int!
      $branchFilter: String
    ) {
      party_vw_staff_dashboard(
        limit: $limit
        offset: $offset
        ${filterQuery}
        ${orderbyQuery}
      ) {
        person_party_id
        account_id_list
        auth_id
        email
        family_name
        given_name
        onboarded_by_branch_code
      }
    }
  `;
  return query;
};

const generateRegistrationStatusTotalItemsQuery = ({
  branchFilter,
  registrationStatusFilter,
}: GetRegistrationStatusItemsCountVariables) => {
  let filterQuery = '';
  if (branchFilter || registrationStatusFilter) {
    filterQuery = ' where: {\n';
    if (branchFilter) {
      filterQuery += ' onboarded_by_branch_code: { _eq: $branchFilter }\n';
    }
    if (
      registrationStatusFilter &&
      registrationStatusFilter === 'invite_sent'
    ) {
      filterQuery += ' auth_id: {_is_null: false}\n';
    }
    if (
      registrationStatusFilter &&
      registrationStatusFilter === 'invite_not_sent'
    ) {
      filterQuery += ' auth_id: {_is_null: true}\n';
    }
    filterQuery += '}\n';
  }
  const query = gql`
    query GetRegistrationStatusItemsCount ($branchFilter: String, $registrationStatusFilter: String) {
      party_vw_staff_dashboard_aggregate(
        ${filterQuery}
        distinct_on: person_party_id
      )
       {
        aggregate {
          count
        }
      }
    }
  `;
  return query;
};

const useRegistrationStatusTotalItemsQueryNew = ({
  branchFilter,
  registrationStatusFilter,
  skip = false,
}: GetRegistrationStatusItemsCountQueryVariables): [
  GetRegistrationStatusItemsCount | undefined,
  boolean,
  ApolloError | undefined,
  RefetchRegistrationStatusItemsCount
] => {
  const query = generateRegistrationStatusTotalItemsQuery({
    branchFilter,
    registrationStatusFilter,
  });
  const { loading, error, data, refetch } = useQuery<
    GetRegistrationStatusItemsCount,
    GetRegistrationStatusItemsCountVariables
  >(query, {
    skip,
    variables: {
      branchFilter,
      registrationStatusFilter,
    },
  });
  return [data, loading, error, refetch];
};

const useRegistrationStatusQueryNew = ({
  limit,
  offset,
  sort,
  branchFilter,
  registrationStatusFilter,
  direction,
  skip = false,
}: GetRegistrationStatusItemsQueryVariables): [
  GetRegistrationStatusItems | undefined,
  boolean,
  ApolloError | undefined,
  RefetchRegistrationStatusItems
] => {
  const query = generateRegistrationStatusQuery({
    limit,
    offset,
    sort,
    branchFilter,
    registrationStatusFilter,
    direction,
  });
  const { loading, error, data, refetch } = useQuery<
    GetRegistrationStatusItems,
    GetRegistrationStatusItemsQueryVariables
  >(query, {
    skip,
    variables: {
      limit,
      offset,
      branchFilter,
      registrationStatusFilter,
    },
  });
  return [data, loading, error, refetch];
};

export default useRegistrationStatusQuery;

export {
  useRegistrationStatusTotalItemsQuery,
  useRegistrationStatusTotalItemsQueryNew,
  useRegistrationStatusQueryNew,
  generateRegistrationStatusQuery,
  generateRegistrationStatusTotalItemsQuery,
};
