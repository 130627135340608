import React, {
  memo,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { View, FlatList, ViewStyle, Dimensions } from 'react-native';
import theme from '../../theme/theme';
import {
  useDeviceType,
  TabularData,
  Box,
  TablePagination,
} from '@nutrien/bonsai-core';
import QueryCard from '../QueryCard';
import CustomerTile from './CustomerTile';
import { CustomerInfo, HandleRowPress, PAGE_SIZE } from '.';
import EmptyState from '../EmptyState';
import useCustomerList from './useCustomerList';
import useCustomerListNew from './useCustomerListNew';
import { useStaff } from '../../context/StaffContext';
import { useSegment } from '../../hooks';
import { trackEvent } from '../../constants/segment';
import { customerListConstants } from '../../constants';

const Wrapper = ({ children }: { children?: ReactNode }) => {
  const { isDesktop } = useDeviceType();
  return (
    <Box minHeight={115} px={isDesktop ? 2 : 0}>
      {children}
    </Box>
  );
};

const NoResults = () => {
  const noResultsStyle = {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
  };

  return (
    <View style={noResultsStyle as ViewStyle} testID="no-results">
      <EmptyState type="no-search" alert={'No results found'} />
    </View>
  );
};

const renderItem =
  (handleRowPress: HandleRowPress) =>
  ({ item, index }: { item: CustomerInfo; index: number }) => {
    return (
      <View testID={`customer-tiles-${index}`}>
        <CustomerTile {...item} handleRowPress={handleRowPress} />
      </View>
    );
  };

const RegisteredUsersTable = (): JSX.Element => {
  const flags = useFlags();
  const isPartyModelFlagOn = flags['NG-2644-party-model'];

  const {
    customersLoading,
    customersError,
    customersRefetch,
    registeredUsers,
    handleRowPress,
    totalUsers,
    getRegistrationStatusLoading,
    getRegistrationStatusError,
    getRegistrationStatusRefetch,
    getRegistrationStatusTotalItemsLoading,
    getRegistrationStatusTotalItemsError,
    getRegistrationStatusTotalItemsRefetch,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = isPartyModelFlagOn ? useCustomerListNew() : useCustomerList();

  const segment = useSegment();
  const {
    state: { branchFilter, registrationStatusFilter, registeredUsersPageIndex },
    dispatch,
  } = useStaff();
  const { isDesktop } = useDeviceType();

  // manage pagination
  const [numberOfPages, setNumberOfPages] = useState(
    Math.ceil(totalUsers / PAGE_SIZE)
  );
  useEffect(() => {
    setNumberOfPages(Math.ceil(totalUsers / PAGE_SIZE));
  }, [totalUsers]);

  // manage users shown in mobile and tablet
  const [registeredUsersNoPagination, setRegisteredUsersNoPagination] =
    useState(registeredUsers);

  useEffect(() => {
    dispatch({
      type: 'setRegisteredUsersPageIndex',
      value: 1,
    });
    setRegisteredUsersNoPagination([]);
  }, [branchFilter, registrationStatusFilter, dispatch]);

  useEffect(() => {
    if (
      registeredUsers.length > 0 &&
      registeredUsers[registeredUsers.length - 1]?.customerId !==
        registeredUsersNoPagination[registeredUsersNoPagination.length - 1]
          ?.customerId
    ) {
      setRegisteredUsersNoPagination((prev) => [...prev, ...registeredUsers]);
    }
  }, [registeredUsers, registeredUsersNoPagination, segment]);

  const isLoading = () => {
    if (registeredUsersPageIndex !== 1 && !isDesktop) {
      return false;
    }

    return (
      customersLoading ||
      getRegistrationStatusLoading ||
      getRegistrationStatusTotalItemsLoading
    );
  };

  const goToNextPage = useCallback(() => {
    if (registeredUsersPageIndex < numberOfPages) {
      dispatch({
        type: 'setRegisteredUsersPageIndex',
        value: registeredUsersPageIndex + 1,
      });
    }
  }, [registeredUsersPageIndex, numberOfPages, dispatch]);

  const { height } = Dimensions.get('window');

  const styles = {
    tableHeader: {
      height: theme.spacing(8),
      paddingLeft: theme.spacing(1),
      borderBottomWidth: 1,
      borderBottomColor: theme.auColors.neutral[400],
      backgroundColor: theme.auColors.neutral[200],
    },
    row: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      maxHeight: 49,
    },
    cardsContainer: {
      flexGrow: 1,
      borderTopWidth: 1,
      borderTopColor: theme.auColors.neutral[500],
      height,
    },
  };

  const renderRegisteredUsers = () => (
    <>
      <TabularData
        columns={customerListConstants.columns}
        EmptyComponent={NoResults}
        type={isDesktop ? 'table' : 'cards'}
        renderCards={() =>
          registeredUsersNoPagination.length > 0 ? (
            <View style={styles.cardsContainer}>
              <FlatList
                accessible
                data={registeredUsersNoPagination}
                renderItem={renderItem(handleRowPress)}
                extraData={{ columns: customerListConstants.columns }}
                initialNumToRender={25}
                keyExtractor={(item) => item.customerId}
                removeClippedSubviews={true}
                windowSize={5}
                onEndReachedThreshold={0.01}
                onEndReached={goToNextPage}
              />
            </View>
          ) : (
            <NoResults />
          )
        }
        data={registeredUsers as unknown as Record<string, unknown>[]}
        tableProps={{
          sortable: false,
          rowStyle: styles.row,
          headerStyle: styles.tableHeader,
          initialState: { hiddenColumns: ['customerId'] },
          onCellPress: ({ customerId }) => {
            handleRowPress(customerId as string);
          },
          pagination: false,
        }}
      />
      {isDesktop && (
        <TablePagination
          selectablePageSize={false}
          pageIndex={registeredUsersPageIndex - 1}
          pageSize={PAGE_SIZE}
          totalRecords={totalUsers}
          canNextPage={registeredUsersPageIndex < numberOfPages}
          canPreviousPage={registeredUsersPageIndex > 1}
          setPageSize={() => null}
          previousPage={() => {
            dispatch({
              type: 'setRegisteredUsersPageIndex',
              value: registeredUsersPageIndex - 1,
            });
            segment?.track(trackEvent.PAGINATION_CLICKED, {
              display_page_direction: 'Previous',
            });
          }}
          nextPage={() => {
            goToNextPage();
            segment?.track(trackEvent.PAGINATION_CLICKED, {
              display_page_direction: 'Next',
            });
          }}
        />
      )}
    </>
  );

  return (
    <QueryCard
      loading={isLoading()}
      error={
        customersError ||
        getRegistrationStatusError ||
        getRegistrationStatusTotalItemsError
      }
      handleReload={() => {
        customersRefetch();
        getRegistrationStatusRefetch();
        getRegistrationStatusTotalItemsRefetch();
      }}
      wrapper={Wrapper}
    >
      {registeredUsers && renderRegisteredUsers()}
    </QueryCard>
  );
};

export default memo(RegisteredUsersTable);
