import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { lazy } from 'react';
import { RoleType } from '../auth/types';
import { routeConfig, UserRouteProps } from './shared';

const CustomerDashboard = lazy(() => import('../screens/CustomerDashboard'));
const StaffDashboard = lazy(() => import('../screens/StaffDashboard'));
const EmptyDashboard = lazy(() => import('../screens/EmptyDashboard'));
const Account = lazy(() => import('../screens/Account'));
const Accounts = lazy(() => import('../screens/Accounts'));
const Login = lazy(() => import('../components/Login'));
const Auth0Error = lazy(() => import('../screens/Error'));
const Auth0Login = lazy(() => import('../screens/Auth0Login'));
const UserSummary = lazy(() => import('../screens/UserSummary'));
const CreateUser = lazy(() => import('../screens/CreateUser'));
const EditUser = lazy(() => import('../screens/EditUser'));
const Recommendation = lazy(() => import('../screens/Recommendation'));
const CustomerSelfRegistration = lazy(
  () => import('../screens/CustomerSelfRegistration')
);
const AgronomyNavigationTest = lazy(
  () => import('../screens/AgronomyNavigationTest')
);
const FarmVisitSummary = lazy(() => import('../screens/FarmVisitSummary'));
const FarmVisitRecommendations = lazy(
  () => import('../screens/FarmVisitRecommendations')
);
const RedirectingIndicator = lazy(
  () => import('../components/RedirectingIndicator')
);

export const isNoLandingPageRoles = (userRoles: string[]): boolean => {
  const noLandingPageRoles: string[] = [
    RoleType.sales_rep,
    RoleType.grower,
    RoleType.agronomist,
  ];
  return !userRoles.some((r) => !noLandingPageRoles.includes(r));
};

const GetRoutesByRoles = (roles: string[]): UserRouteProps[] => {
  let rolesDashboard;
  const flags: LDFlagSet = useFlags();

  const defaultAllowedRoles = [RoleType.customer, RoleType.staff];

  if (roles.length <= 0) {
    rolesDashboard = RedirectingIndicator;
  } else {
    rolesDashboard = roles.includes(RoleType.staff)
      ? StaffDashboard
      : isNoLandingPageRoles(roles)
      ? EmptyDashboard
      : CustomerDashboard;
  }

  const routes: UserRouteProps[] = [
    {
      name: routeConfig.Login.name,
      path: routeConfig.Login.path,
      View: Login,
      isProtected: false,
      featureEnabled: true,
    },
    {
      name: routeConfig.Auth0Login.name,
      path: routeConfig.Auth0Login.path,
      View: Auth0Login,
      isProtected: false,
      featureEnabled: true,
    },
    {
      name: routeConfig.Error.name,
      path: routeConfig.Error.path,
      View: Auth0Error,
      isProtected: false,
      featureEnabled: true,
    },
    {
      name: routeConfig.Home.name,
      path: routeConfig.Home.path,
      View: rolesDashboard,
      isProtected: true,
      featureEnabled: true,
    },
    {
      name: routeConfig.Accounts.name,
      path: routeConfig.Accounts.path,
      View: Accounts,
      isProtected: true,
      allowedRoles: defaultAllowedRoles,
      featureEnabled: true,
    },
    {
      name: routeConfig.Account.name,
      path: routeConfig.Account.path,
      View: Account,
      isProtected: true,
      allowedRoles: defaultAllowedRoles,
      featureEnabled: true,
    },
    {
      name: routeConfig.AccountWithFilter.name,
      path: routeConfig.AccountWithFilter.path,
      View: Account,
      isProtected: true,
      allowedRoles: defaultAllowedRoles,
      featureEnabled:
        flags['ng-2585-wdio-account-summary'] &&
        flags['ng-2585-wdio-account-summary'].length > 0,
    },
    {
      name: routeConfig.UserSummary.name,
      path: routeConfig.UserSummary.path,
      View: UserSummary,
      isProtected: true,
      allowedRoles: [RoleType.staff],
      featureEnabled: true,
    },
    {
      name: routeConfig.AgronomyNavigationTest.name,
      path: routeConfig.AgronomyNavigationTest.path,
      View: AgronomyNavigationTest,
      isProtected: true,
      allowedRoles: [RoleType.staff, RoleType.grower],
      featureEnabled: flags['FD-257-agronomy-navigation-test'],
    },
    {
      name: routeConfig.CreateUser.name,
      path: routeConfig.CreateUser.path,
      View: CreateUser,
      isProtected: true,
      allowedRoles: [RoleType.staff],
      featureEnabled: flags['ng-2144-staff-creates-new-user'],
    },
    {
      name: routeConfig.EditUser.name,
      path: routeConfig.EditUser.path,
      View: EditUser,
      isProtected: true,
      allowedRoles: [RoleType.staff],
      featureEnabled: flags['ng-2812-staff-edits-user'],
    },
    {
      name: routeConfig.FarmVisit.name,
      path: routeConfig.FarmVisit.path,
      View: FarmVisitSummary,
      isProtected: true,
      allowedRoles: [RoleType.sales_rep, RoleType.grower, RoleType.agronomist],
      featureEnabled: true,
    },
    {
      name: routeConfig.FarmVisitRecommendations.name,
      path: routeConfig.FarmVisitRecommendations.path,
      View: FarmVisitRecommendations,
      isProtected: true,
      allowedRoles: [RoleType.sales_rep, RoleType.grower, RoleType.agronomist],
      featureEnabled: true,
    },
    {
      name: routeConfig.FarmRecommendation.name,
      path: routeConfig.FarmRecommendation.path,
      View: Recommendation,
      isProtected: true,
      allowedRoles: [RoleType.sales_rep, RoleType.grower, RoleType.agronomist],
      featureEnabled: true,
    },
    {
      name: routeConfig.FarmVisitRecommendation.name,
      path: routeConfig.FarmVisitRecommendation.path,
      View: Recommendation,
      isProtected: true,
      allowedRoles: [RoleType.sales_rep, RoleType.grower, RoleType.agronomist],
      featureEnabled: true,
    },
    /**
     * Maintain support for old recommendation URL.
     * Support for the redirect is in the component itself
     */
    {
      name: routeConfig.OldFarmRecommendation.name,
      path: routeConfig.OldFarmRecommendation.path,
      View: Recommendation,
      isProtected: true,
      allowedRoles: [RoleType.sales_rep, RoleType.grower, RoleType.agronomist],
      featureEnabled: true,
    },
    {
      name: routeConfig.CustomerSelfRegistration.name,
      path: routeConfig.CustomerSelfRegistration.path,
      View: CustomerSelfRegistration,
      isProtected: false,
      featureEnabled: flags['NG-3564-customer-self-registration'],
    },

    // TODO
    // Add not found page
  ];

  if (roles.length <= 0) {
    return routes.filter((route) => route.featureEnabled);
  }

  /**
   * Once we reach here, a user is authenticated and we can determine
   * which routes to show based on the authenticated users roles.
   */
  const filteredRoutes = routes
    .filter((route) => {
      // allow all access if no allowedRoles defined in routes
      if (!route.allowedRoles || route.allowedRoles.length <= 0) {
        return true;
      }
      /**
       * allow access if ANY of the user roles is defined the allowedRoles
       */
      return route.allowedRoles.some((r) => {
        return roles.includes(r);
      });
    })
    .map((route) => {
      return route;
    });

  return filteredRoutes.filter((route) => route.featureEnabled);
};

export default GetRoutesByRoles;
