import { Box, Text } from '@nutrien/bonsai-core';
import React from 'react';

interface Props {
  account?: string | JSX.Element;
  type: string | JSX.Element;
  name?: string | null | JSX.Element;
}

const AccBoxInformationMobile = ({
  account,
  type,
  name,
}: Props): JSX.Element => {
  return (
    <Box pt={4} width="100%">
      <Text testID="accBoxInfoMobile-acc">{account}</Text>
      <Text
        testID="accBoxInfoMobile-name"
        bodyBold
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {name || '-'}
      </Text>
      <Text
        testID="accBoxInfoMobile-type"
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {type}
      </Text>
    </Box>
  );
};

export default AccBoxInformationMobile;
