import React from 'react';
import { Box, ActivityIndicator } from '@nutrien/bonsai-core';
import { UseUserDetailsFormProps } from '.';
import InputField from '../InputField';
import { Field } from 'formik';

interface LoadingSearchIconProps {
  loading: boolean;
}

interface SearchBranchInputProps extends Partial<UseUserDetailsFormProps> {
  saveChangesLoading: boolean;
  errorMessage?: string;
  mandatory?: boolean;
}

const LoadingIcon = ({ loading }: LoadingSearchIconProps): JSX.Element => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="row"
  >
    {loading && (
      <Box mr={1}>
        <ActivityIndicator testID="search-loading" />
      </Box>
    )}
  </Box>
);

const SearchBranchInput = ({
  handleSearchChange,
  searchBranchLoading,
  searchBranchError,
  setHideSearchResults,
  searchTerm,
  errorMessage,
  saveChangesLoading,
  onKeyDown,
  mandatory,
}: SearchBranchInputProps): JSX.Element => {
  const handleInputFocus = () => {
    setHideSearchResults && setHideSearchResults(false);
  };
  const handleInputBlur = () => {
    setHideSearchResults && setHideSearchResults(true);
  };

  return (
    <Field
      component={InputField}
      label={`Onboarded Branch${mandatory ? '*' : ''}`}
      fieldName="onboardedBranch"
      value={searchTerm}
      errorMessage={errorMessage || searchBranchError?.message}
      onChangeText={handleSearchChange}
      testID="onboardedBranch-input"
      disabled={saveChangesLoading}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      right={<LoadingIcon loading={searchBranchLoading || false} />}
      {...(onKeyDown && { onKeyPress: onKeyDown })}
    />
  );
};

export default SearchBranchInput;
