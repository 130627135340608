import { AccountType } from 'components/AccountOverview/useAccountOverview';
import { isAfter } from 'date-fns';
import { GroupedTransactionsType } from '.';
import {
  Transaction,
  TransactionLineItem,
} from '../../components/Transaction/transactions.types';
import { TRANSACTIONS_FILTER, TxSortOptions } from '../../constants';
import { CustomerAccountWithFinance_sords_customer_account as AccountData } from '../../types/generated/CustomerAccountWithFinance';
import { CustomerAccounts_sords_customer_account as CustomerData } from '../../types/generated/CustomerAccounts';
import {
  GetLotDetails_getLotDetails_result,
  GetLotDetails_getLotDetails_result_LotDetails as LotDetailsResult,
  GetLotDetails_getLotDetails_result_LotFeeDetails as LotFeeDetailsResult,
} from '../../types/generated/GetLotDetails';
import { DocumentAccountType } from '../../types/generated/globalTypes';
import {
  SearchTransactions,
  SearchTransactions_getLineItems,
} from '../../types/generated/SearchTransactions';
import {
  getCurrentAndNextMonthDueDate,
  isDueNextMonth,
} from '../../utils/objectHelpers/date.helper';

type SortFields = 'due' | 'issued';

type TransactionFormatter = (transaction: {
  transaction: SearchTransactions_getLineItems;
  item?: Partial<TransactionLineItem>;
}) => Transaction;

type LivestockFormatter = (
  transaction: SearchTransactions_getLineItems
) => Transaction[];

export interface SubItems {
  list?: LotDetailsResult[] | LotFeeDetailsResult[] | null;
  total?: number | null;
}

export interface LotDetailRow {
  lotDetails: {
    subItems: SubItems;
  } | null;
  lotFeeDetails: {
    description: { value: string };
    category: { value?: string | null };
    subItems: SubItems;
  } | null;
}
export interface TransactionGroup {
  documentId: string | null;
  documentType: string;
  title: string;
  total: number;
  issueDate: string;
  dueDate: string;
  items: Transaction[];
  hasPdf: boolean;
  ruralcoAccNumber?: string;
  invoiceNumber?: number;
  documentAccountType?: DocumentAccountType;
}

/**
 * Flatten and format transaction line items
 *
 * @param transactionsData The data returned from the hasura query getLineItems
 * @param format how to format the transaction line items
 * @returns Flatted and formatted transaction line items
 */
export const formatTransactions = (
  transactionsData: SearchTransactions | undefined,
  format: TransactionFormatter
): Transaction[] => {
  if (!transactionsData || !transactionsData.getLineItems) {
    return [];
  }

  const flattenTransactions = transactionsData.getLineItems.reduce(
    (acc, transaction) => {
      return transaction?.Items.length === 0
        ? acc.concat(format({ transaction }))
        : acc.concat(
            transaction?.Items.map((item: TransactionLineItem) =>
              format({ transaction, item })
            )
          );
    },
    [] as Transaction[]
  );
  return flattenTransactions;
};

/**
 * Flatten and format transaction line items and attached additional livestock information
 *
 * @remarks
 * livestock's `transaction.Items` only include `Category` and Items.length ===
 * 1, so livestock transactions will be flatted to a single line include
 * additional information from plasma
 *
 * @param transactionsData The data returned from the hasura query getLineItems
 * @param formatter Format the transaction line items and attach additional
 * livestock information, controlled by flag `NG-3135-plasma-lot-details`
 * @returns Flatted and formatted transaction line items
 */
export const formatTransactionsWithLivestock = (
  transactionsData: SearchTransactions | undefined,
  formatter: LivestockFormatter
): Transaction[] => {
  if (!transactionsData || !transactionsData.getLineItems) {
    return [];
  }

  const flattenTransactions = transactionsData.getLineItems.reduce(
    (previousValue, transaction) => {
      if (transaction) {
        return previousValue.concat(formatter(transaction));
      }
      return previousValue;
    },
    [] as Transaction[]
  );
  return flattenTransactions;
};

export const sortTransactions = (
  sortBy: TxSortOptions,
  transactions?: Array<Transaction>
): Array<Transaction> => {
  const sortValues = sortBy.split(':');
  if (!transactions) {
    return [];
  }

  // slice - do not mutate original array
  return transactions.slice().sort((a: Transaction, b: Transaction) => {
    const first =
      sortValues[1] === 'ASC'
        ? a[sortValues[0] as SortFields]?.value
        : b[sortValues[0] as SortFields]?.value;
    const second =
      sortValues[1] === 'ASC'
        ? b[sortValues[0] as SortFields]?.value
        : a[sortValues[0] as SortFields]?.value;

    return isAfter(new Date(first as string), new Date(second as string))
      ? 1
      : -1;
  });
};

const calculateTotalFees = (
  lotDetails: (LotFeeDetailsResult | LotDetailsResult)[]
) => {
  return (
    lotDetails &&
    lotDetails.reduce((prev, current) => prev + Number(current.Fee), 0)
  );
};

export const getLotDetailItems = (
  lotData?: GetLotDetails_getLotDetails_result | null
): LotDetailRow => {
  const lotDetails = lotData?.LotDetails;
  const lotFeeDetails = lotData?.LotFeeDetails;
  return {
    lotDetails:
      lotDetails && lotDetails.length
        ? {
            subItems: {
              list: lotDetails,
              total: calculateTotalFees(lotDetails),
            },
          }
        : null,
    lotFeeDetails:
      lotFeeDetails && lotFeeDetails.length
        ? {
            description: {
              value: 'Fees and charges for total purchase',
            },
            category: {
              value: lotFeeDetails?.[0]?.Category,
            },
            subItems: {
              list: lotFeeDetails,
              total: calculateTotalFees(lotFeeDetails),
            },
          }
        : null,
  };
};

export const groupTransactions = (
  lineItems: Transaction[]
): GroupedTransactionsType => {
  return lineItems.reduce((entryMap, e) => {
    const {
      documentId,
      documentType,
      ruralcoAccNumber,
      hasPdf,
      invoiceNumber,
      issued,
      price,
      totalAmount,
      due,
      groupBy,
      groupTitle,
    } = e;
    const title = hasPdf ? invoiceNumber : groupTitle;
    const prev = entryMap.get(groupBy);
    const total = prev
      ? hasPdf
        ? prev.total
        : prev.total + price.value
      : totalAmount.value;
    return entryMap.set(groupBy, {
      documentId,
      documentType,
      title,
      issueDate: issued.displayValue,
      items: prev ? [...prev.items, e] : [e],
      dueDate: due.displayValue,
      total,
      hasPdf,
      ruralcoAccNumber,
      invoiceNumber,
      documentAccountType: getDocumentAccountType(documentType),
    });
  }, new Map());
};

export const isLivestock = (itemGroup: TransactionGroup): boolean => {
  return itemGroup.hasPdf && itemGroup.documentType === 'Z8';
};

export const getDocumentAccountType = (
  documentType: string | null
): DocumentAccountType | undefined => {
  if (documentType) {
    return documentType === 'Z8'
      ? DocumentAccountType.PLASMA
      : DocumentAccountType.STOREDOCS;
  }
};

export const getAccountType = (
  accountData?: AccountData | CustomerData
): AccountType => {
  if (accountData?.name1?.startsWith('AIO/')) {
    return 'finance';
  }
  return accountData?.account_type?.toLowerCase() as AccountType;
};

export const filterNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

/**
 * if any transaction's clearing document number is the same clearing document number as a document
 * with doc type Z8, ZL or ZA as long as the clearing document number is not shared with any other
 * documents that aren't of type Z8, ZL, ZA then it should be considered a livestock transaction
 * (and shown in the livestock view only)
 *
 * @param transactionsData  SearchTransactions | undefined,
 * @param transactionsFilter TRANSACTIONS_FILTER
 * @returns SearchTransactions | undefined
 */
export const filterTransactions = (
  transactionsData: SearchTransactions | undefined,
  transactionsFilter?: TRANSACTIONS_FILTER
): SearchTransactions | undefined => {
  if (!transactionsFilter) return transactionsData;

  /**
   * Transaction type determination base on Ticket link:
   * {@link https://agrium.atlassian.net/browse/NG-3509}
   * {@link https://agrium.atlassian.net/browse/NG-3935}
   */
  const potentialLivestockDocTypes = ['DA', 'DZ', 'DY'];
  const livestockDocTypes = ['Z8', 'ZA', 'ZL'];
  if (transactionsData?.getLineItems) {
    const lineItems = transactionsData.getLineItems.filter(filterNullable);

    // Bundle the ones we know are livestock into one list.
    const livestockItems = lineItems.filter((item) => {
      return item.DocumentType && livestockDocTypes.includes(item.DocumentType);
    });

    // Get `ClearingDocNo` from livestock list.
    const livestockClearingDocNumbers = livestockItems
      .map((item) => item.ClearingDocNo)
      .filter(filterNullable);

    // Bundle the ones we know are merchandise into one list.
    const merchandiseItems = lineItems.filter((item) => {
      return (
        item.DocumentType &&
        !livestockDocTypes.includes(item.DocumentType) &&
        !potentialLivestockDocTypes.includes(item.DocumentType)
      );
    });

    // Get `ClearingDocNo` from merchandise list.
    const merchandiseClearingDocNumbers = merchandiseItems
      .map((item) => item.ClearingDocNo)
      .filter(filterNullable);

    // Bundle the ones we don't know into one list.
    const potentialLivestockItems = lineItems.filter((item) => {
      return (
        item.DocumentType &&
        potentialLivestockDocTypes.includes(item.DocumentType)
      );
    });

    potentialLivestockItems.forEach((item) => {
      // If it shares a `ClearingDocNo` with items from the merchandise list
      // then put it into the merchandise list.
      if (
        item.ClearingDocNo &&
        merchandiseClearingDocNumbers.includes(item.ClearingDocNo)
      ) {
        merchandiseItems.push(item);
        return;
      }
      // If it shares a `ClearingDocNo` with items from the livestock list
      // then put it into the livestock list.
      if (
        item.ClearingDocNo &&
        livestockClearingDocNumbers.includes(item.ClearingDocNo)
      ) {
        livestockItems.push(item);
        return;
      }
      merchandiseItems.push(item);
    });

    return {
      ...transactionsData,
      getLineItems:
        transactionsFilter === TRANSACTIONS_FILTER.LIVESTOCK
          ? livestockItems
          : merchandiseItems,
    };
  }
};

/**
 * https://agrium.atlassian.net/browse/NG-3636
 *
 * Credits = sum of all documents at the top level, that have a negative amount and where the due date is the 25th of the month (or next month if current date > 25th)
 *           EXCEPT [where the doc type = (Z* - where * is a wildcard or AB) with posting key = (15 or 05) OR where the doc type = DZ with posting key = 15]
 *
 * Expenses = sum of all documents at the top level, that have a positive amount and where the due date is the 25th of the month (or next month if current date > 25th)
 *
 *  @param transactionsData: SearchTransactions | undefined
 *  @returns { credits: number | null, expenses: number | null}
 */
export const calcExpensesAndCredits = (
  transactionsData: SearchTransactions | undefined
): { credits?: number | null; expenses?: number | null } => {
  if (
    !transactionsData?.getLineItems ||
    !transactionsData?.getLineItems.length
  ) {
    return { credits: null, expenses: null };
  }

  const filterByDocumentTypeAndPostingKey = ({
    DocumentType,
    PostingKey,
  }: SearchTransactions_getLineItems): boolean => {
    if (
      (DocumentType?.startsWith('Z') || DocumentType === 'AB') &&
      ['05', '15'].includes(PostingKey || '')
    ) {
      return false;
    }
    if (DocumentType === 'DZ' && PostingKey === '15') return false;

    return true;
  };

  const { currentMonthDueDate, nextMonthDueDate } =
    getCurrentAndNextMonthDueDate();

  const nextDueDate = isDueNextMonth(25)
    ? nextMonthDueDate
    : currentMonthDueDate;

  const creditTransactions = transactionsData.getLineItems
    .filter(filterNullable)
    .filter(({ Amount }) => Amount && Amount < 0)
    .filter(({ DueDate }) => DueDate === nextDueDate);

  const expenseTransactions = transactionsData.getLineItems
    .filter(filterNullable)
    .filter(({ Amount }) => Amount && Amount > 0)
    .filter(({ DueDate }) => DueDate === nextDueDate);

  const calculateTotal = (amounts: (number | null)[]): number => {
    return amounts
      .filter(filterNullable)
      .reduce((amount, prev) => amount + prev, 0);
  };

  const creditAmounts = creditTransactions
    .filter(filterByDocumentTypeAndPostingKey)
    .map(({ Amount }) => Amount);

  const expenseAmounts = expenseTransactions.map(({ Amount }) => Amount);

  return {
    credits: calculateTotal(creditAmounts),
    expenses: calculateTotal(expenseAmounts),
  };
};
