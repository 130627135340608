import { makeVar, ReactiveVar } from '@apollo/client';

/**
 * BreadCrumbPath information.
 */
export interface BreadCrumbPath {
  originBreadcrumb: string[];
  destBreadcrumb: string[];
}
/**
 * BreadCrumb information.
 */
export interface BreadCrumb {
  breadCrumbPath: BreadCrumbPath;
}

/**
 * BreadCrumb initial values.
 */
const breadCrumbPathInitialValue: BreadCrumb = {
  breadCrumbPath: {
    originBreadcrumb: [],
    destBreadcrumb: [],
  },
};

export const breadCrumbVar: ReactiveVar<BreadCrumb> = makeVar<BreadCrumb>(
  breadCrumbPathInitialValue
);
