import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useStyles, useDeviceType } from '@nutrien/bonsai-core';
import { useReactiveVar } from '@apollo/client';
import { DocumentList } from '../Document';
import TransactionTabs, {
  TableViewProps,
} from '../Transaction/TransactionTabs';
import Tabs from '../Tabs';
import { TransactionFilters } from '../Transaction';
import { useTransaction } from '../../context/TransactionContext';
import { selectedAccountMutations } from '../../reactiveVariables';
import {
  AccountTabs,
  selectedAccountVar,
} from '../../reactiveVariables/variables/selectedAccountVar';
import { useSegment } from '../../hooks';
import { trackEvent } from '../../constants/segment';

const AccountDetailTabs = (props: TableViewProps): JSX.Element => {
  const tabs: AccountTabs[] = ['Transactions', 'Statements'];
  const [viewIndex, setViewIndex] = useState<number>(0);
  const { isDesktop } = useDeviceType();
  const { resetFilters, setQueryTypeIndex } = useTransaction();
  const { editAccountView } = selectedAccountMutations;
  const segment = useSegment();
  const { account_id } = useReactiveVar(selectedAccountVar);

  const styles = useStyles((theme) => ({
    tabViewWrapper: {
      paddingTop: theme.spacing(2),
      paddingHorizontal: isDesktop ? theme.spacing(2) : 0,
      backgroundColor: theme.auColors.neutral[100],
    },
    tabContainer: {
      paddingHorizontal: isDesktop ? 0 : theme.spacing(2),
    },
    filterContainerStyle: {
      width: isDesktop ? '40%' : '100%',
      alignSelf: 'flex-end',
      marginTop: theme.spacing(2),
    },
  }));

  useEffect(() => {
    editAccountView({
      selected_tab: tabs[viewIndex as number],
      ...(viewIndex === 1 && {
        display_view: undefined,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewIndex]);

  const onTabChange = (index: number): void => {
    // track segment event only when switching to different tab
    if (index !== viewIndex) {
      segment?.track(trackEvent.TAB_CHANGED, {
        origin_tab: tabs[viewIndex as number],
        destination_tab: tabs[index as number],
        account_id,
      });
    }
    setQueryTypeIndex(index);
    setViewIndex(index);
    resetFilters();
  };

  return (
    <View style={styles.tabViewWrapper}>
      <Tabs
        containerStyle={styles.tabContainer}
        testID={viewIndex === 0 ? 'transactions' : 'statements'}
        onPress={onTabChange}
        selectedIndex={viewIndex}
        tabs={tabs}
      />
      {viewIndex === 0 ? (
        <TransactionTabs {...props} />
      ) : (
        <>
          <View style={styles.filterContainerStyle}>
            <TransactionFilters showTabs={false} />
          </View>
          <DocumentList {...props} />
        </>
      )}
    </View>
  );
};

export default AccountDetailTabs;
