import React, { FC } from 'react';
import { ViewStyle } from 'react-native';
import { useStyles, Box } from '@nutrien/bonsai-core';

interface WhitePanelWrapperProps {
  testID?: string;
}

const WhitePanelWrapper: FC<WhitePanelWrapperProps> = ({
  testID,
  children,
}): JSX.Element => {
  const styles = useStyles((theme) => ({
    whiteWrapper: {
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    } as ViewStyle,
  }));
  return (
    <Box
      minHeight={115}
      flexGrow={1}
      bg="white"
      style={styles.whiteWrapper}
      p={2}
      mb={4}
      testID={testID}
    >
      {children}
    </Box>
  );
};

export default WhitePanelWrapper;
