import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { AccountType } from 'components/AccountOverview/useAccountOverview';
import React from 'react';
import { CustomerAccountsByNominatedAccountId } from 'types/generated/CustomerAccountsByNominatedAccountId';
import { CustomerProfileByCustomerId_customer_profile_customer_accounts } from 'types/generated/CustomerProfileByCustomerId';
import { useCustomerAccountsByNominatedAccountIdQuery } from '../../hooks/gql/queries/useCustomerAccountsQuery';
import { tableColumns } from '../AccountInformationPanel/AccountInformationPanel';
import { TableData } from '.';
import IconTable from '../IconTableList/IconTableList';
import { ColumnsDataProps } from '../IconTableList';
import QueryCard from '../QueryCard';
import AccBoxInformationMobile from './AccBoxInformationMobile';
import AccountTableBalance from './AccountTableBalance';
import AccountTypeRender from '../AccountTypeRender';

type props = {
  nominated_account_id: string;
  setSelectedAccountIds: (value: string[]) => void;
  insertCustomerAccountsLoading: boolean;
  customerProfileByCustomerIdLoading: boolean;
};

const AccountTableLinkAcc = ({
  nominated_account_id,
  setSelectedAccountIds,
  insertCustomerAccountsLoading,
  customerProfileByCustomerIdLoading,
}: props): JSX.Element => {
  const { isDesktop } = useDeviceType();
  const theme = useTheme();

  const styles = {
    tableHeader: {
      height: theme.spacing(5),
      borderBottomWidth: 1,
      backgroundColor: theme.auColors.neutral[200],
      borderBottomColor: theme.auColors.neutral[400],
    },
    tableCell: {
      height: theme.spacing(1),
    },
    row: {
      height: theme.spacing(3),
      borderBottomWidth: 1,
      borderBottomColor: theme.auColors.neutral[500],
    },
  };

  const { data, loading, error, refetch } =
    useCustomerAccountsByNominatedAccountIdQuery({
      nominated_account_id,
    });

  const buildData = (
    dataCustomer: CustomerAccountsByNominatedAccountId | undefined
  ) => {
    const tableData =
      dataCustomer?.sords_customer_account?.map((sordsAcc): TableData => {
        const accType = sordsAcc.account_type?.toLowerCase() as AccountType;

        return isDesktop
          ? {
              account: sordsAcc.account_id_trimmed || '-',
              accountId: sordsAcc.account_id || '',
              type: (
                <AccountTypeRender
                  accType={accType}
                  accName={sordsAcc?.name1}
                />
              ),
              name: (
                <Text
                  testID="accBoxInfo-name"
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  {sordsAcc?.name1 || '-'}
                </Text>
              ),
              balance: (
                <AccountTableBalance
                  customer_account={
                    sordsAcc as unknown as CustomerProfileByCustomerId_customer_profile_customer_accounts
                  }
                />
              ),
            }
          : {
              account: (
                <AccBoxInformationMobile
                  account={sordsAcc.account_id_trimmed || '-'}
                  type={
                    <AccountTypeRender
                      accType={accType}
                      accName={sordsAcc.name1 || '-'}
                    />
                  }
                  name={sordsAcc.name1}
                />
              ),
              accountId: sordsAcc.account_id || '',
            };
      }) ?? [];
    return tableData;
  };

  return (
    <QueryCard
      loading={loading}
      handleReload={refetch}
      error={error}
      wrapper={Box}
    >
      <Text h3 testID="add-account-search-results-accounts-title">
        Search Results
      </Text>
      <Text
        testID="add-account-select-accounts-title"
        style={{ paddingTop: theme.spacing(1) }}
      >
        Select the account you wish to link
      </Text>
      <Box py={2}>
        <IconTable
          tableColumns={tableColumns(isDesktop)}
          columnsData={buildData(data) as unknown as ColumnsDataProps}
          checkable={true}
          headerStyle={styles.tableHeader}
          cellStyle={styles.tableCell}
          rowStyle={styles.row}
          testID="add-account-icon-table-accounts-edit-user-id"
          initialState={{ hiddenColumns: ['accountId'] }}
          handleRowSelect={setSelectedAccountIds}
          loading={
            insertCustomerAccountsLoading || customerProfileByCustomerIdLoading
          }
        />
      </Box>
      <Text testID="add-account-owner-permission-text">
        Please ensure you have the owner&#39;s permission before proceeding
      </Text>
    </QueryCard>
  );
};

export default AccountTableLinkAcc;
