import React from 'react';
import { NamedStyles } from '@nutrien/bonsai-core';
import { CustomerAccountsSummary_getAccountsSummary } from '../../../types/generated/CustomerAccountsSummary';
import { CustomerAccountsSummarySplitUp_getAccountSummarySplitUp as AccountSummarySplitUp } from '../../../types/generated/CustomerAccountsSummarySplitUp';
import WhitePanelWrapper from '../WhitePanelWrapper';
import { FinanceEntireAccountPanel } from './FinanceEntireAccountPanel';
import { FinancePanel } from './FinancePanel';

interface FinanceOneSectionProps {
  accountSummary: CustomerAccountsSummary_getAccountsSummary | null;
  accountSummarySplitUp: AccountSummarySplitUp | null;
  panelStyles: NamedStyles<Record<string, undefined>>;
}

export const FinanceOneSection = ({
  accountSummary,
  accountSummarySplitUp,
  panelStyles,
}: FinanceOneSectionProps): JSX.Element => {
  return (
    <>
      <WhitePanelWrapper>
        <FinanceEntireAccountPanel
          summary={accountSummary}
          panelStyles={panelStyles}
        />
      </WhitePanelWrapper>
      <WhitePanelWrapper>
        <FinancePanel
          summary={accountSummary}
          panelStyles={panelStyles}
          summarySplitUp={accountSummarySplitUp}
        />
      </WhitePanelWrapper>
    </>
  );
};
