import React, { memo } from 'react';
import { View, ScrollView, TextStyle, ViewStyle } from 'react-native';
import theme from '../../theme/theme';
import { useNavigation } from '../../hooks';

import { useDeviceType, Text, Box, Icon } from '@nutrien/bonsai-core';
import { Chip } from 'react-native-paper';
import { useFlags } from 'launchdarkly-react-client-sdk';

import RegisteredUsersTable from './RegisteredUsersTable';
import { Button } from '../Button';
import Select from '../Select';
import useCustomerList from './useCustomerList';
import useCustomerListNew from './useCustomerListNew';
import { useStaff } from '../../context/StaffContext';
import { customerListConstants } from '../../constants';
import { routeConfig } from '../../routes/shared';

const CustomerList = (): JSX.Element => {
  const { isDesktop } = useDeviceType();
  const flags = useFlags();
  const {
    preferredBranchesList,
    branchesLoading,
    branchesError,
    customersRefetch,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = flags['NG-2644-party-model'] ? useCustomerListNew() : useCustomerList();
  const {
    state: { branchFilter, registrationStatusFilter },
    dispatch,
  } = useStaff();
  const { navigate } = useNavigation();

  const desktopError = isDesktop && branchesError;

  const styles = {
    filterHeading: {
      marginBottom: 3,
      fontSize: theme.typography.fontSize.small,
      fontWeight: '600',
    },
    branchFilterWrapper: {
      marginBottom: isDesktop ? theme.spacing(3) : theme.spacing(2),
    },
    defaultBranchFilterContainer: {
      width: isDesktop ? 184 : 'auto',
      flex: isDesktop ? 'none' : 1,
    },
    branchFilterBorder: {
      borderColor: theme.auColors.neutral[500],
    },
    chip: {
      borderRadius: theme.shape.borderRadiusXLarge,
      marginRight: 4,
      padding: 0,
    },
    chipText: {
      fontSize: theme.typography.fontSize.medium,
      fontFamily: theme.typography.fontFamily.semiBold,
      width: isDesktop ? 116 : 'auto',
      height: 29,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.auColors.neutral[600],
    },
    activeChip: {
      backgroundColor: theme.auColors.primary.default,
      borderColor: theme.auColors.primary.default,
    },
    activeChipText: {
      color: theme.auColors.neutral[100],
    },
    addUserButtonStyle: {
      paddingRight: 0,
      borderColor: theme.auColors.neutral[500],
      height: 42, // this might be changed later
      backgroundColor: theme.auColors.neutral[100], // this might be changed later
      top: desktopError ? 2 : 0,
    },
    chipTextNoMargin: {
      marginLeft: 0,
      marginRight: 0,
    },
  };

  const handleClickAddUser = () => {
    navigate(routeConfig.CreateUser.getNavigateProps());
  };

  return (
    <View>
      <Box
        flexDirection={isDesktop ? 'row' : 'column'}
        justifyContent="space-between"
        px={2}
      >
        <View style={styles.branchFilterWrapper}>
          <Text style={styles.filterHeading as TextStyle}>Branch</Text>
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <Select
              name="branch-filter"
              loading={branchesLoading}
              error={branchesError ? 'Error loading options.' : ''}
              handleSelect={(value) => {
                dispatch({ type: 'setBranchFilter', value });
                customersRefetch();
              }}
              value={branchFilter}
              options={preferredBranchesList}
              containerStyle={
                [
                  styles.defaultBranchFilterContainer,
                  !branchesError ? styles.branchFilterBorder : {},
                ] as ViewStyle
              }
            />
            {flags['ng-2144-staff-creates-new-user'] && (
              <Box width={125} ml={isDesktop ? 1.5 : 1}>
                <Button
                  type="outline"
                  title="Add user"
                  testID="add-user-button"
                  iconLeft={
                    <Icon
                      name="person-add-alt-1"
                      testID="add-user-icon"
                      type="material"
                    />
                  }
                  buttonStyle={styles.addUserButtonStyle}
                  onPress={handleClickAddUser}
                />
              </Box>
            )}
          </Box>
        </View>
        <Box mr={isDesktop ? 2 : 0}>
          <Text style={styles.filterHeading as TextStyle}>Filter by</Text>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <Box
              flexDirection="row"
              alignItems="flex-start"
              mb={isDesktop ? 3 : 2}
              mt={isDesktop ? 0 : 1}
            >
              {customerListConstants.registrationStatusFilters.map(
                ({ value, label }) => (
                  <Chip
                    key={value}
                    mode="outlined"
                    style={[
                      styles.chip,
                      registrationStatusFilter === value && styles.activeChip,
                    ]}
                    textStyle={[
                      styles.chipText,
                      registrationStatusFilter === value &&
                        styles.activeChipText,
                      isDesktop && styles.chipTextNoMargin,
                    ]}
                    testID={`${value}_filter`}
                    onPress={() =>
                      dispatch({ type: 'setRegistrationStatusFilter', value })
                    }
                  >
                    {label}
                  </Chip>
                )
              )}
            </Box>
          </ScrollView>
        </Box>
      </Box>
      <RegisteredUsersTable />
    </View>
  );
};

export default memo(CustomerList);
