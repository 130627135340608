import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRoute from '../useRoutes/index.web';

/**
 * Given a from and to path (i.e from: /old/route/:id - to: /farm/:id/recommendations)
 * if the from path matches the current path, redirect to the `to` path & pass all params
 * from the current route through to the new `to` route.
 *
 * This is to be utilised for web only and will do nothing on native.
 */
const useRedirect = ({ from, to }: { from: string; to: string }) => {
  const navigate = useNavigate();
  const route = useRoute();

  useEffect(() => {
    if (route.pathName === from) {
      navigate(to);
    }
  });
};

export default useRedirect;
