import React, { FunctionComponent, useEffect } from 'react';
import { Box, Text, useDeviceType, useTheme } from '@nutrien/bonsai-core';
import { Formik } from 'formik';
import { CustomerProfileByCustomerId_customer_profile } from 'types/generated/CustomerProfileByCustomerId';
import UserDetailsForm from '../UserDetailsForm';
import BulletText from '../BulletText';
import { useStaff, CustomerDetail } from '../../context/StaffContext';
import {
  userDetailsEditFormValidationSchema,
  userDetailsFormValidationSchema,
} from '../../utils/formValidation';
import {
  useNavigation,
  useSegment,
  RefetchByCustomerId,
  RefetchByPersonId,
} from '../../hooks';
import useContactDetailsPanel from './useContactDetailsPanel';
import { trackEvent } from '../../constants/segment';
import useLogin from '../Login/useLogin';
import { routeConfig } from '../../routes/shared';
import { PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details } from '../../types/generated/PartyGetPersonDetailsWithAccountsById';
import { ApolloError } from '@apollo/client';
import QueryCard from '../../components/QueryCard';

export type ContactDetailsPanelType = 'create' | 'edit';

interface ContactDetailsPanelProps {
  wrapper?: FunctionComponent;
  customer?:
    | CustomerProfileByCustomerId_customer_profile
    | PartyGetPersonDetailsWithAccountsById_party_vw_get_person_details
    | undefined;
  type: ContactDetailsPanelType;
  customerProfileByCustomerIdRefetch?: RefetchByCustomerId | RefetchByPersonId;
  handleReload?: () => void;
  error?: ApolloError;
  loading?: boolean;
}

const ContactDetailsPanel = ({
  customer,
  type,
  customerProfileByCustomerIdRefetch,
  error,
  loading,
  handleReload,
}: ContactDetailsPanelProps): JSX.Element => {
  const theme = useTheme();
  const {
    dispatch,
    state: { selectedCustomer },
  } = useStaff();
  const { isDesktop, isHandset } = useDeviceType();
  const { navigate } = useNavigation();
  const segment = useSegment();

  const {
    userState: { employeeId },
  } = useLogin();

  const styles = {
    card: {
      flex: 1,
    },
    text: {
      marginBottom: theme.spacing(2),
    },
  };

  const {
    handleSubmit,
    insertProfileData,
    insertProfileError,
    insertProfileLoading,
    updateProfileData,
    updateProfileLoading,
    updateProfileError,
  } = useContactDetailsPanel(type);

  // handle create user
  useEffect(() => {
    const userInsertProfile = insertProfileData?.insertProfile;
    if (insertProfileData && userInsertProfile) {
      const newCustomerId = userInsertProfile?.id;
      const customerTrait = {
        party_type: 'person',
        user_type: 'customer',
        onboarded_branch_code:
          customer &&
          ('onboarded_branch_code' in customer
            ? customer.onboarded_branch_code
            : customer.onboarded_by_branch_code),
      };

      const employeeTrait = {
        party_type: 'person',
        user_type: 'Nutrien employee',
      };

      newCustomerId &&
        processSegmentAnalytics(newCustomerId, employeeTrait, customerTrait);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertProfileData]);

  // handle edit user
  useEffect(() => {
    const userUpdatedProfile = updateProfileData?.updateProfile;
    if (updateProfileData && userUpdatedProfile) {
      dispatch({
        type: 'setSelectedCustomer',
        value: {
          ...selectedCustomer,
          customerName: userUpdatedProfile?.full_name,
          onboardedBranchCode: userUpdatedProfile?.onboarded_branch_code,
        } as CustomerDetail,
      });
      dispatch({
        type: 'setShowSaveUserSuccess',
        value: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileData]);

  useEffect(() => {
    const hasInsertProfileData =
      insertProfileData &&
      insertProfileData?.insertProfile?.onboarded_branch_code ===
        selectedCustomer?.onboardedBranchCode;

    const hasUpdateProfileData =
      updateProfileData &&
      updateProfileData?.updateProfile?.onboarded_branch_code ===
        selectedCustomer?.onboardedBranchCode;

    const shouldRefetchCustomerData =
      hasInsertProfileData || hasUpdateProfileData;

    if (shouldRefetchCustomerData) {
      customerProfileByCustomerIdRefetch &&
        customerProfileByCustomerIdRefetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  const processSegmentAnalytics = async (
    customerId: string,
    employeeTrait: object,
    customerTrait: object
  ) => {
    await userCreatedAnalytics(customerId, customerTrait, employeeTrait);
    dispatch({
      type: 'setShowSaveUserSuccess',
      value: true,
    });
    navigate(
      routeConfig.EditUser.getNavigateProps({
        customerId,
      })
    );
  };

  const userCreatedAnalytics = async (
    customerId: string,
    customerTrait: object,
    employeeTrait: object
  ) => {
    if (segment) {
      try {
        // identify new customer
        segment.reset();
        await segment.identify(customerId, customerTrait);
        // user created - customer user’s analytics
        await segment.track(trackEvent.USER_CREATED, {
          userID: customerId,
        });
        // re-identify employee
        segment.reset();
        await segment.identify(employeeId as string, employeeTrait);

        // user creation - employee analytics
        await segment.track(trackEvent.USER_CREATED, {
          userID: employeeId,
          person_id: customerId,
        });
      } catch (err) {
        throw new Error(err as string | undefined);
      }
    }
  };

  return (
    <Box
      style={styles.card}
      pl={isDesktop ? '4' : '0'}
      pt={isDesktop ? '0' : '4'}
    >
      <Box
        style={styles.card}
        pt={4}
        pb={isDesktop ? 9 : 6}
        pl={isDesktop ? 6 : 4}
        pr={4}
        background={theme.auColors.neutral[100]}
      >
        <Text h2 testID="contact-details-heading">
          Contact Details
        </Text>
        <QueryCard
          wrapper={Box}
          loading={loading}
          error={error}
          handleReload={handleReload}
        >
          <>
            <Box pt={isHandset ? 1 : 4} mb={3}>
              <Text style={styles.text}>
                Please ensure the user you are about to register is:
              </Text>
              <Box>
                <BulletText text="over 18 years of age" />
                <BulletText text="the account owner or has the consent of the account owner" />
              </Box>
            </Box>

            <Formik
              onSubmit={handleSubmit}
              validationSchema={
                type === 'create'
                  ? userDetailsFormValidationSchema
                  : userDetailsEditFormValidationSchema
              }
              initialValues={{
                givenName: customer?.given_name || '',
                familyName: customer?.family_name || '',
                preferredName: customer?.preferred_name || '',
                mobileNumber:
                  (customer &&
                    ('mobile_phone' in customer
                      ? `0${customer.mobile_phone?.slice(3)}`
                      : `0${customer.mobile_number?.slice(3)}`)) ||
                  '',
                emailAddress: customer?.email || '',
                onboardedBranch:
                  (customer &&
                    ('onboarded_branch_code' in customer
                      ? customer.onboarded_branch_code
                      : customer.onboarded_by_branch_code)) ||
                  '',
              }}
            >
              {(props) => (
                <UserDetailsForm
                  {...props}
                  saveChangesLoading={
                    type === 'create'
                      ? insertProfileLoading
                      : updateProfileLoading
                  }
                  graphQLError={
                    type === 'create' ? insertProfileError : updateProfileError
                  }
                  type={type}
                />
              )}
            </Formik>
          </>
        </QueryCard>
      </Box>
    </Box>
  );
};

export default ContactDetailsPanel;
