import * as Segment from 'expo-analytics-segment';

export const segmentReactNativeInit = () => {
  if (process.env.SEGMENT_REACT_NATIVE_WRITE_KEY) {
    try {
      Segment.initialize({
        iosWriteKey: process.env.SEGMENT_REACT_NATIVE_WRITE_KEY,
        androidWriteKey: process.env.SEGMENT_REACT_NATIVE_WRITE_KEY,
      });
    } catch (err) {
      throw new Error(err as string | undefined);
    }
  }
};

export const getPathFromPathName = (path: string) => path.split('/')[1];
